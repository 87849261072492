import React, { useEffect, memo } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from '../../Layouts/Container';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import PageNotFound from '../../pages/404';
import IncomingTaxForm from '../../components/DeclarationForms/Incoming_Tax_Social_Pay_Form';
import DeclarationReturnAmount from '../../components/DeclarationReturnAmount';
import { MobileIncomingTax, MobileSrDeclaration } from '../../components/MobileView';
import styles from './styles.module.scss';
import { RootState } from '../../store';
import { useNavigate } from '../../hook';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { tins } from "./tins";

export const path: string = 'declaration/type/:type/:id?/:declStatus?';

const DeclarationFormSwitchPage = memo(() => {
    const { type } = useParams();
    const auth: any = useSelector((state: RootState) => state.auth);
    const utils = useSelector((state: RootState) => state.utils);
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    useEffect(() => {
        if (!auth?.tin || (auth?.tin && !utils.tinIsValid)) {
            navigate('/dashboard');
        }
    }, [auth?.tin, utils.tinIsValid]);

    let form = null;
    const containerSize: "small" | "middle" | "large" | "x-large" = 'large';
    const isDesktop = utils.device?.mode === "desktop";
    switch (type) {
        case 'sd':
            form = isDesktop ? <IncomingTaxForm /> : <MobileIncomingTax />;
            break;
        case 'sr':
            const _tins = tins.split('\n');
            if (_tins.includes(auth?.tin)) {
                toast.error(t('FAILURE'), { position: 'top-right', theme: 'colored' });
                form = <Navigate to={`/${i18n.language}/dashboard`} />;
            } else {
                form = isDesktop ? <DeclarationReturnAmount /> : <MobileSrDeclaration />;
            }
            break;
        default:
            return <PageNotFound />
    }

    return (
        <DashboardLayout className={styles.DeclarationFormSwitch}>
            <Container containerSize={containerSize} className={styles.DeclarationFormSwitchContainer} withoutPadding>
                {form}
            </Container>
        </DashboardLayout>
    );
});
export default DeclarationFormSwitchPage;
