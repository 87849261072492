import React, { useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Button } from "../../../Button";
import { classnames } from "../../../../utils";
import * as Icons from "../../../Icons";

interface RenderingWrapperTitleProps {
    translantionKey: string;
    excludeTranslationKeys?: string[];
    mapperDeclarationHelperLinks?: { [key: string]: string };
    formId?: string
}

export const RenderingWrapperTitle: React.FC<RenderingWrapperTitleProps> = (props) => {
    const { translantionKey, excludeTranslationKeys, mapperDeclarationHelperLinks, formId } = props;
    const { t} = useTranslation();
    const [tKey, setTkey] = useState("show_more");

    const notCropped = useMemo(() => excludeTranslationKeys ? excludeTranslationKeys.includes(translantionKey) : false, [translantionKey, excludeTranslationKeys]);

    useLayoutEffect(() => {
        const croppedElement = document.querySelector(`#${translantionKey}`);
        croppedElement?.parentElement?.classList.remove("ShowMore");
        setTkey("show_more");
        const cropped = (croppedElement?.scrollWidth ?? 0) > (croppedElement?.clientWidth ?? 0)
        const togglerBtn = document.querySelector(`#toggler-${translantionKey}`);
        if(!cropped) {
            togglerBtn?.classList?.add("hide");
        }
        else {
            togglerBtn?.classList?.remove("hide");
        }
    }, [translantionKey]);

    const handleToglerShow = () => {
        const croppedElement = document.querySelector(`#${translantionKey}`);
        const cropped = (croppedElement?.scrollWidth ?? 0) > (croppedElement?.clientWidth ?? 0);
        if(cropped) {
            croppedElement?.parentElement?.classList?.add("ShowMore");
            setTkey("show_less");
        }
        else {
            croppedElement?.parentElement?.classList?.remove("ShowMore");
            setTkey("show_more");
        }
    };

    return (
        <div className={styles.RenderWrapperTitle}>
            <span>
                <span className={classnames({[styles.NotCropped]: notCropped })} id={translantionKey}>{t(translantionKey)}</span>
                <Button 
                    variant='link' 
                    className={styles.More} 
                    onClick={handleToglerShow}
                    id={`toggler-${translantionKey}`}
                >
                    {t(tKey)}
                </Button>
            </span>
            {mapperDeclarationHelperLinks && (
                <span>
                    <a
                        href={mapperDeclarationHelperLinks[translantionKey as keyof typeof mapperDeclarationHelperLinks] ?? mapperDeclarationHelperLinks[formId as keyof typeof mapperDeclarationHelperLinks]}
                        target="_blank"
                    >
                        <Icons.InfoOutline />
                    </a>
                </span>
            )}
        </div>
    )
}
