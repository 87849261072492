import React from 'react';
import { useTranslation } from 'react-i18next';
import { classnames } from '../../utils';
import styles from './styles.module.scss';

export interface TableHead {
    key: string;
    title: any;
    colSpan?: number;
    colSpanColumns?: string[];
    colType?: "action" | "view";
    colActionArgs?: any;
    actionRow?: Function;
    align?: string;
    className?: string;
    titleByIndex?: string;
    titleIndexes?: (number | undefined)[];
}

interface TableProps {
    theads: TableHead[];
    data?: any[];
    externalData?: any;
    caption?: string | React.ReactNode;
    rowActions?: (rowData: any, args: any) => React.ReactNode;
    className?: string;
    name?: string;
    verticalSize?: "middle";
}

export const Table:React.FC<TableProps>  = (props) => {
    const { t } = useTranslation();
    const { theads, data, externalData, caption, className, rowActions, name, verticalSize } = props;

    const cs = classnames({
        [styles.Table]: true,
        [className as string]: !!className,
        [styles[verticalSize ?? ""]]: !!verticalSize
    });
    const dataLength = data?.length || 0;

    const checkRowHeight = (index: number) => {
        const row = document.querySelector(name ? `[data-list-name="${name}"] [data-table-row="${index}"]` : `[data-table-row="${index}"]`);
        if (row?.clientHeight && +row?.clientHeight > 34) {
            return "0.5em";
        }
        return "4px";
    };

    return (
        <div className={styles.Wrapper}>
            {caption && dataLength > 0 && (
                <div className={styles.Caption}>
                    <span>{caption}</span>
                </div>
            )}
            {dataLength > 0 || externalData ? (
                <table className={cs} data-list-name={name}>
                    <thead>
                        <tr>
                            {theads.map((col) => (
                                <th colSpan={col.colSpan} key={col.key} className={col.className || ''}>
                                    {col.title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((row, index) => (
                            <tr key={index} data-table-row={index} style={!!verticalSize ? { "--tdPadding": checkRowHeight(index)} as {[key: string]: string | number } : {} }>
                                {theads.map((col) => {
                                    let titleTD = '';
                                    if (col.titleIndexes && col.titleIndexes.includes(index) && col.titleByIndex) {
                                        titleTD = col.titleByIndex;
                                    }
                                    if (col.colSpanColumns) {
                                        return col.colSpanColumns.map(item => (
                                            <td key={item} colSpan={1} title={titleTD}>
                                                {row[item]}
                                            </td>
                                        ));
                                    }
                                    return (
                                        <td key={col.key} colSpan={col.colSpan} className={col.align || col.className || ''} title={titleTD}>
                                            {(() => {
                                                if (col.colType !== 'action') return row[col.key];
                                                const actionFunc = col.actionRow || rowActions;
                                                if (actionFunc) return actionFunc(row, col.colActionArgs);
                                                return null;
                                            })()}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                        {externalData}
                    </tbody>
                </table>
            ) : (
                <table className={cs}>
                    <thead>
                        <tr>
                            <th>{t('dataNotFound')}</th>
                        </tr>
                    </thead>
                </table>
            )}
        </div>
    );
};