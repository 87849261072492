import React from 'react';
import loadingImage from '../../assets/images/loadingImg.gif';
import './styles.scss';

export default function Loading() {
    return (
        <div className="loadingImageContainer">
            <img src={loadingImage} width={50} />
        </div>
    );
}
