import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { Container } from '../../Layouts/Container';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import { Wrapper } from '../../Layouts/Wrapper';
import * as Icons from '../../components/Icons';
import { Table, TableHead } from '../../components/Table';
import { RootState } from '../../store';
import { useNavigate } from '../../hook';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { FileUploadDialog } from '../../components/FileUploadDialog';
import {
    deleteDeclSDSubmitByID,
    formatDate,
    getDeclSDSubmitCount,
    getDeclSDSubmitList,
    getDeclSubmitDownloadPDF,
    submittedDeclUploadPDF,
    submittedDeclSignWithMID,
    submittedDeclSignCheckStatus,
    submittedDeclSignStopPending,
    submittedDeclSend,
    checkCosignCertificate,
    uploadCosignCertificateForSign,
    signDocumentTypes,
    signTypes,
    signTypesNonResident,
    classnames,
    isResidentUser,
    translationKeysByErrorCodes,
    isNotResidentUser,
    OS,
    getSearchParams
} from '../../utils';
import { Signature } from '../../components/Signature';
import styles from './styles.module.scss';
import { changePage as changePageAction, initializeRows, initializeTotal } from '../../store/declarationFilledSlice';
import Loading from "../../components/Loading";
import { Pagination } from '../../components/Pagination';
import { DeclarationFilledCard } from '../../components/Card';
import type { DeclarationFilledCardProps } from '../../components/Card';
import { Empty } from '../../components/Empty';
import { Tooltip } from '../../components/Tooltip';
import { NotificationDialog } from '../../components/NotificationDialog';
import * as BridgeActions from "../../utils/bridge_actions";

export const path: string = 'declaration/filled';
const timerForSignDefault = 150; // 2m 30s

const renderRowActions = (rowData: any, args: any) => {
    const editDeclaration = () => {
        args.navigation(`/declaration/type/${rowData.docCode}/${rowData.id}/filled`);
    };

    const deleteDeclaration = () => {
        args.confirmDeleteAction(rowData);
    }

    const selectForSignDeclaration = () => {
        args.setSelectedRow(rowData);
        args.openSignDocumentTypesDialog();
    }

    const forSign = rowData.status === 0 || rowData.status === 1;

    return (
        <div className={styles.ActionGroup}>
            <Tooltip
                title={args.editMsg}
                display='inline'
                type='info'
                followTheCursor
            >
                <span onClick={editDeclaration} className='action-item'>
                    <Icons.Edit />
                </span>
            </Tooltip>
            <Tooltip
                title={args.removeMsg}
                display='inline'
                type='info'
                followTheCursor
            >
                <span onClick={deleteDeclaration} className='action-item'>
                    <Icons.Trash />
                </span>
            </Tooltip>
            <Tooltip
                title={args.openDeclarationMsg}
                display='inline'
                type='info'
                followTheCursor
            >
                <span onClick={() => args.downloadPDFforSign(rowData)} className='action-item'>
                    <Icons.PDF />
                </span>
            </Tooltip>
            
            {forSign && (
                <Tooltip
                    title={args.signingMsg}
                    display='inline'
                    type='info'
                    followTheCursor
                >
                    <span onClick={selectForSignDeclaration} className='action-item'>
                        <Icons.SignDocumentPen />
                    </span>
                </Tooltip>
                
            )}
            {forSign && (
                <Tooltip
                    title={args.uploadFileMsg}
                    display='inline'
                    type='info'
                    followTheCursor
                >
                    <span onClick={() => args.openUploadSignedPDFDialog(rowData)} className='action-item'>
                        <Icons.AttachFile />
                    </span>
                </Tooltip>
            )}
        </div>
    );
};

const renderPEKActions = (rowData: any, args: any) => {
    const isDisabled = rowData.status === 0 || rowData.status === 1;
    return (
        <div className={styles.ActionGroup}>
            <Tooltip
                title={isDisabled ? args.textDisabledMsg : args.textActiveMsg}
                display='inline'
                type='info'
                followTheCursor
            >
                <span className={classnames({ [styles.SentToPekDisabled]: isDisabled, 'action-item': true })} onClick={isDisabled ? () => null : () => args.sendToPekAction(rowData)}>
                    <Icons.Submit />
                </span>
            </Tooltip>
        </div>
    );
}

const DeclarationFilledPage = () => {
    const [sentPEKDialog, setSentPEKDialog] = useState<{ isOpen: boolean, data: any }>({ isOpen: false, data: null });
    const [deleteDialog, setDeleteDialog] = useState<{ isOpen: boolean, data: any }>({ isOpen: false, data: null });
    const [uploadSignedDialog, setUploadSignedDialog] = useState<{ isOpen: boolean, data: any }>({ isOpen: false, data: null });
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [signDocument, setSignDocument] = useState<signDocumentTypes>(signDocumentTypes.CLOSED);
    const [signType, setSignType] = useState<{ label: string, value: string } | null>(null);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [mIDNumber, setMIDNumber] = useState<string>('');
    const [mIDNumberSignError, setMIDNumberSignError] = useState<string>('');
    const [timerForSign, setTimerForSign] = useState<number>(0);
    const timerForSignInterval = useRef<any>(null);
    const signByMIDRequestCheckStatus = useRef<boolean>(false);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState<boolean>(false);
    const [cancelProcess, setCancelProcess] = useState<{ processing: boolean, cancelProcess: false }>({ processing: false, cancelProcess: false });
    const declarationFilled = useSelector((state: RootState) => state.declarationFilled);
    const { t, i18n } = useTranslation();
    const utils = useSelector((state: any) => state.utils);
    const auth: any = useSelector((state: any) => state.auth);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstOpen, setFirstOpen] = useState<boolean>(true);
    const [backendValidationError, setBackendValidationError] = useState<string[]>([]);
    const [openInfoForNoResident, setOpenInfoForNoResident] = useState<null | string>(null);

    const handleSignType = useCallback((val: any) => {
        setSignType(val);
    }, []);

    const downloadDocumentForSign = async (data: any) => {
        const { mobileView, os} = getSearchParams();
        if(!!mobileView && os === OS.android) {
            return BridgeActions.onAction({...BridgeActions.DOWNOALD_PDF, payload: { apiPath: `/api/decl/submitted/downloadPdf/${data.id}` }});
        }
        return getDeclSubmitDownloadPDF(data.id)
            .then(res => {
                const fileName = res.headers['content-disposition'].replace('attachment; filename=', '').replace(/"/g, '');
                const url = window.URL.createObjectURL(res.data);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.log('downloadDocumentForSign >>>', err);
                toast.error(t(err.response?.data?.message || err.message), { position: 'top-right', theme: 'colored' });
            });

    }

    const choosedSignType = async (type?: any) => {
        setButtonLoading(true);
        switch (type?.value ?? signType?.value) {
            case 'MID':
                setButtonLoading(false);
                setSignDocument(signDocumentTypes.MID);
                break;
            case 'ID_CARD':
            case 'CO_SIGN_NON_RESIDENT':
                downloadDocumentForSign(selectedRow)
                    .then(() => {
                        setButtonLoading(false);
                        setSignDocument(signDocumentTypes.CLOSED);
                    })
                    .catch(err => {
                        console.log('err', err);
                    });
                break;
            case 'CO_SIGN':
                checkCosignCertificate()
                    .then(() => {
                        // setSignDocument(signDocumentTypes.COSIGN_LOGIN_PASSWORD); // let this line here, don't delete
                        downloadDocumentForSign(selectedRow);
                        setSignDocument(signDocumentTypes.CLOSED);
                        setSignType(null);
                    })
                    .catch(err => {
                        const errorMessageKey = translationKeysByErrorCodes(err);
                        switch (errorMessageKey) {
                            case 'CERTIFICATE_MISSING':
                            case 'CERTIFICATE_EXPIRED':
                                setSignDocument(signDocumentTypes.COSIGN_CERTIFICATE);
                                if (errorMessageKey === 'CERTIFICATE_EXPIRED') {
                                    toast.error(t(errorMessageKey), { position: 'top-right', theme: 'colored' });
                                }
                                break;
                            default:
                                toast.error(t(errorMessageKey), { position: 'top-right', theme: 'colored' });
                        }
                    })
                    .finally(() => {
                        setButtonLoading(false);
                    });
                break;
            default:
        }
    }

    const confirmSignatureByMID = async () => {
        if (selectedRow?.id) {
            const ID = selectedRow?.id;
            setButtonLoading(true);
            const send = {
                phone: `+374${mIDNumber}`
            };
            setTimerForSign(timerForSignDefault);
            let life = timerForSignDefault;
            timerForSignInterval.current = setInterval(() => {
                if (life < 1) {
                    window.clearInterval(timerForSignInterval.current);
                    signByMIDRequestCheckStatus.current = false;
                    setTimerForSign(0);
                    setButtonLoading(false);
                    setMIDNumberSignError(t('SIGN_REQUEST_ERROR'));
                    return;
                }
                setTimerForSign(prev => prev - 1);
                life -= 1;
            }, 1000);
            signByMIDRequestCheckStatus.current = true;
            setCloseButtonDisabled(true);
            setCancelProcess(prev => ({ ...prev, processing: true }));
            submittedDeclSignWithMID(ID, send)
                .then(() => {
                    setCloseButtonDisabled(false);
                    (async () => {
                        while(true) {
                            try {
                                const res = await submittedDeclSignCheckStatus(ID);
                                if (res.data?.correct) {
                                    setButtonLoading(false);
                                    setSignDocument(signDocumentTypes.CLOSED);
                                    window.clearInterval(timerForSignInterval.current);
                                    const messageMatrix = t('signed_successfuly').split("\n");
                                    toast.success(
                                        <span>
                                            <span style={{ display: "inline-block"}}>{messageMatrix[0]}</span>
                                            <span style={{ display: "inline-block"}}>{messageMatrix[1]}</span>
                                        </span>, 
                                        { position: 'top-right', theme: 'colored', autoClose: 10000 }
                                    );
                                    setTimerForSign(0);
                                    setCancelProcess({ cancelProcess: false, processing: false });
                                    onSearch();
                                    break;
                                } else if (life < 1 || !signByMIDRequestCheckStatus.current) {
                                    if (!signByMIDRequestCheckStatus.current) {
                                        await submittedDeclSignStopPending(ID)
                                            .catch(() => {})
                                            .finally(() => {
                                                setSignDocument(signDocumentTypes.SIGN_TYPES);
                                                setButtonLoading(false);
                                                setMIDNumberSignError('');
                                                setMIDNumber('');
                                                setTimerForSign(0);
                                                window.clearInterval(timerForSignInterval.current);
                                                setCancelProcess({ cancelProcess: false, processing: false });
                                            });
                                    }
                                    break;
                                }
                                await new Promise((resolve) => {
                                    window.setTimeout(() => {
                                        resolve(true);
                                    }, 5000);
                                });
                            } catch (err: any) {
                                setCancelProcess({ cancelProcess: false, processing: false });
                                signByMIDRequestCheckStatus.current = false;
                                window.clearInterval(timerForSignInterval.current);
                                setTimerForSign(0);
                                const errorKey = translationKeysByErrorCodes(err);
                                setMIDNumberSignError(t(errorKey));
                                setButtonLoading(false);
                                break;
                            }
                        }
                    })();
                })
                .catch(ER => {
                    setCancelProcess({ cancelProcess: false, processing: false });
                    setCloseButtonDisabled(false);
                    const errorKey = translationKeysByErrorCodes(ER);
                    setMIDNumberSignError(t(errorKey));
                    setButtonLoading(false);
                    if (timerForSignInterval.current) {
                        setTimerForSign(0);
                        window.clearInterval(timerForSignInterval.current);
                    }
                });
        }
    }

    const toggleDeleteDialog = () => setDeleteDialog(prevState => ({ ...prevState, isOpen: !prevState.isOpen }));

    const toggleUploadSignedDialog = () => setUploadSignedDialog(prevState => ({ ...prevState, isOpen: !prevState.isOpen, data: null }));

    const confirmDeleteAction = (data: any) => setDeleteDialog(prevState => ({ ...prevState, isOpen: true, data }));

    const approveDeleteAction = (cb?: Function) => {
        if (deleteDialog.data?.id) {
            deleteDeclSDSubmitByID(deleteDialog.data?.id)
                .then(() => {
                    const p = declarationFilled.currentPage > 1 && declarationFilled.rows.length === 1 ? declarationFilled.currentPage - 1 : declarationFilled.currentPage;
                    onSearch(p);
                    toggleDeleteDialog();
                })
                .catch(err => {
                    toast.error(t(err.response?.data?.message || err.message), { position: 'top-right', theme: 'colored' });
                })
                .finally(() => {
                    cb?.();
                });
        }
    };

    const openUploadSignedPDFDialog = (data: any) => {
        setUploadSignedDialog({ isOpen: true, data: data });
    }

    const approveUploadSignedAction = async (file: File, cb?: Function) => {
        try {
            if (file.type !== 'application/pdf') {
                throw new Error('UPLOAD_FILE_IS_NOT_PDF');
            }
            const data = new FormData();
            data.set('file', file);
            const res: any = await submittedDeclUploadPDF(uploadSignedDialog.data.id, data);
            if (res.data.correct) {
                toggleUploadSignedDialog();
                onSearch();
            }
        } catch (err: any) {
            let errorKey = translationKeysByErrorCodes(err);
            if (!errorKey) {
                errorKey = err.message;
            }
            if (err.request?.status === 403) {
                errorKey = 'FORBIDDEN_REQUEST_FOR_UPLOAD_DECLARATION';
            }
            toast.error(t(errorKey), { position: 'top-right', theme: 'colored' });
        } finally {
            if (typeof cb === 'function') cb();
        }
    }

    const downloadPDFforSign = (data: any) => {
        downloadDocumentForSign(data);
    }

    const uploadCoSignCertificate = (file: any, cb?: Function) => {
        const data = new FormData();
        data.set('file', file);
        uploadCosignCertificateForSign(data)
            .then(res => {
                if (res.data.correct) {
                    downloadDocumentForSign(selectedRow);
                    setSignDocument(signDocumentTypes.CLOSED);
                    setSignType(null);
                }
            })
            .catch(err => {
                toast.error(t(err.response?.data?.message || err.message), { position: 'top-right', theme: 'colored' });
            })
            .finally(() => {
                // setSignDocument(signDocumentTypes.COSIGN_LOGIN_PASSWORD);
                if (typeof cb === 'function') cb();
            });
    }

    const signatureByCoSign = () => {
        console.log('signatureByCoSign');
        setSignDocument(signDocumentTypes.CLOSED);
    }

    const openSignDocumentTypesDialog = (data?: any) => {
        if(!!data) {
            setSelectedRow(data);
        }
        if (!cancelProcess.processing) {
            setSignDocument(signDocumentTypes.SIGN_TYPES);
            setMIDNumberSignError('');
            setMIDNumber('');
        }
        if (buttonLoading) {
            signByMIDRequestCheckStatus.current = false;
            setButtonLoading(false);
            setTimerForSign(0);
            timerForSignInterval.current && window.clearInterval(timerForSignInterval.current);
        }
    }

    const handleSignMobile = (data: any) => {
        if(isNotResidentUser(auth.role)) {
            setOpenInfoForNoResident("for_sign_need_to_web");
        }
        else {
            openSignDocumentTypesDialog(data);
        }
    }

    const sendToPekAction = (data: any) => {
        setSentPEKDialog({ data, isOpen: true });
    }

    const approveSentPEKAction = (saveToDraft: boolean, cb?: Function) => {
        if (sentPEKDialog.data.id) {
            setIsLoading(true);
            setBackendValidationError([]);
            toggleSentPEKDialog();
            submittedDeclSend(sentPEKDialog.data.id, { saveToDraft, lang: i18n.language })
                .then(res => {
                    if (res.data.correct) {
                        toast.success(t('SENT_TO_SRC'), { position: 'top-right', theme: 'colored' });
                        const p = declarationFilled.currentPage > 1 && declarationFilled.rows.length === 1 ? declarationFilled.currentPage - 1 : declarationFilled.currentPage;
                        onSearch(p);
                    }
                })
                .catch(err => {
                    const key = translationKeysByErrorCodes(err);
                    if (key === 'BACKEND_POST_ERROR' && err.response?.data?.errors) {
                        setBackendValidationError(err.response?.data?.errors);
                        window.scrollTo(0,0);
                    } else {
                        if (key) {
                            toast.error(t(key), { position: 'top-right', theme: 'colored' });
                        }
                        if (key === 'INVALID_SUBMIT_ID') {
                            onSearch();
                        }
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                    cb?.();
                });
        }
    }

    const toggleSentPEKDialog = () => {
        setSentPEKDialog(prev => ({ ...prev, isOpen: !prev.isOpen }));
    }

    const onSearch = async (page?: number) => {
        setIsLoading(true);
        try {
            const filterForList = {
                currentPage: page || declarationFilled.currentPage,
                pageSize: declarationFilled.pageSize
            }
            const [list, total] = await Promise.all([getDeclSDSubmitList(filterForList), getDeclSDSubmitCount()]);
            dispatch(initializeRows(list.data));
            dispatch(initializeTotal(total.data.value || 0));
            setFirstOpen(false);
        } catch (err: any) {
            console.log('onSearch >>>', err);
            toast.error(t(err.response?.data?.message || err.message), { position: 'top-right', theme: 'colored' });
        } finally {
            setIsLoading(false);
        }
    }

    const changePage = (page: number) => {
        dispatch(changePageAction(page));
        onSearch(page);
    }

    useEffect(() => {
        if (!auth?.tin || (auth?.tin && !utils.tinIsValid)) {
            navigation('/dashboard');
        }
    }, [auth?.tin, utils.tinIsValid]);

    useEffect(() => {
        onSearch();
    }, []);

    const callActionsDetails = {
        navigation,
        confirmDeleteAction,
        openUploadSignedPDFDialog,
        downloadPDFforSign,
        openSignDocumentTypesDialog,
        setSelectedRow,
        editMsg: t('edit'),
        removeMsg: t('remove'),
        uploadFileMsg: t('upload_file'),
        openDeclarationMsg: t('open_declaration'),
        signingMsg: t('signing')
    };

    const callSendPEKDetails = {
        sendToPekAction,
        textActiveMsg: t('sendPEKActiveMsg'),
        textDisabledMsg: t('sendPEKDisabledMsg')
    }

    const tableHead: TableHead[] = useMemo(() => ([
        { key: "declaration", title: t("declaration"), colSpan: 1 },
        { key: "createdDate", title: t("createdDate"), colSpan: 1 },
        { key: "reportingPeriod", title: t("reportingPeriod"), colSpan: 1 },
        { key: "signedType", title: t("status"), colSpan: 1 },
        { key: "operations", title: t("operations"), colSpan: 1, colType: "action", colActionArgs: callActionsDetails },
        { key: "sendToPEK", title: t("sendToPEK"), colSpan: 1, colType: "action", actionRow: renderPEKActions, colActionArgs: callSendPEKDetails }
    ]), [t]);

    let listView = null;
    const ROWS = declarationFilled.rows.map((item: any) => ({
        declaration: item[`declarationName_${i18n.language}`],
        signedType: item[`statusName_${i18n.language}`],
        createdDate: formatDate('DD.MM.YYYY HH:mm', new Date(item.createDate)),
        updatedDate: item.modifyDate ? formatDate('DD.MM.YYYY HH:mm', new Date(item.modifyDate)) : '',
        reportingPeriod: item.taxYear,
        docCode: item.docCode,
        status: item.status,
        id: item.id
    }));

    switch (utils.device.mode) {
        case 'desktop':
            listView = (
                <Table
                    data={ROWS}
                    theads={tableHead}
                    rowActions={renderRowActions}
                    verticalSize='middle'
                />
            );
            break;
        default:
            listView = ROWS.length ? ROWS.map((item: DeclarationFilledCardProps) => (
                <DeclarationFilledCard
                    {...item}
                    key={`${item.id}/${item.updatedDate}`}
                    sentToPek={sendToPekAction}
                    onRemove={confirmDeleteAction}
                    onDownload={downloadPDFforSign}
                    onSign={handleSignMobile}
                />
            )) : <Empty title={t("dataNotFound")} />;
    }

    return (
        <>
            <DashboardLayout className={styles.DeclarationDraft}>
                <Container containerSize="large" className={styles.DeclarationDraftContainer} withoutPadding>
                    <Wrapper title={t("declarationFilled")}>
                        {backendValidationError.length > 0 && (
                            <div className={styles.errorMessageContainer}>
                                {backendValidationError.map((errorMsg: string) => (
                                    <div key={errorMsg} className={styles.errorMessage}>{errorMsg}</div>
                                ))}
                            </div>
                        )}
                        <div className={styles.TableSection}>
                            {firstOpen && !ROWS.length ? null : listView}
                            {!!ROWS?.length && (
                                <Pagination
                                    pageSize={declarationFilled.pageSize}
                                    total={declarationFilled.total}
                                    currentPage={declarationFilled.currentPage}
                                    onChange={changePage}
                                />
                            )}
                        </div>
                    </Wrapper>
                    {isLoading && <Loading />}
                    <ConfirmDialog isOpen={deleteDialog.isOpen} close={toggleDeleteDialog} approve={approveDeleteAction} questionMessageKey="removeDeclaration" />
                    <FileUploadDialog isOpen={uploadSignedDialog.isOpen} close={toggleUploadSignedDialog} approve={approveUploadSignedAction} />
                    <ConfirmDialog
                        isOpen={sentPEKDialog.isOpen}
                        close={toggleSentPEKDialog}
                        onCloseAction={() => approveSentPEKAction(false)}
                        approve={(cb?: Function) => approveSentPEKAction(true, cb)}
                        questionMessageKey="SENT_TO_SRC_SAVE_DRAFT"
                    />
                </Container>
            </DashboardLayout>
            <Signature
                isOpenSignType={signDocument === signDocumentTypes.SIGN_TYPES}
                toggleIsOpenSignType={() => setSignDocument(signDocumentTypes.CLOSED)}
                loading={buttonLoading}
                signTypeButtonDisabled={!signType || buttonLoading}
                signTypeButtonConfirmed={choosedSignType}
                signTypeOptions={isResidentUser(auth.role) ? signTypes : signTypesNonResident}
                signTypeSelected={signType}
                signTypeHandle={handleSignType}
                isOpenMID={signDocument === signDocumentTypes.MID}
                toggleIsOpenMID={openSignDocumentTypesDialog}
                openMIDButtonConfirmed={confirmSignatureByMID}
                closeButtonDisabled={closeButtonDisabled}
                cancelProcess={cancelProcess.cancelProcess}
                processingCancelBtn={cancelProcess.processing}
                handleCancelProcess={() => {
                    setCancelProcess((prev: any) => ({...prev, cancelProcess: true}));
                    signByMIDRequestCheckStatus.current = false;
                }}
                openMIDSignError={mIDNumberSignError}
                closeMIDSignError={() => setMIDNumberSignError("")}
                mIDNumber={mIDNumber}
                setMIDNumber={setMIDNumber}
                timerForSign={timerForSign}
                isOpenCoSignCertificate={signDocument === signDocumentTypes.COSIGN_CERTIFICATE}
                toggleIsOpenCoSignCertificate={openSignDocumentTypesDialog}
                uploadCoSignCertificate={uploadCoSignCertificate}
                isOpenCoSign={signDocument === signDocumentTypes.COSIGN_LOGIN_PASSWORD}
                toggleIsOpenCoSign={openSignDocumentTypesDialog}
                cosignButtonDisabled={buttonLoading}
                cosignButtonConfirmed={signatureByCoSign}
                onlyMIDFlow={["mobile", "tablet"]}
            />
            <NotificationDialog
                type='info'
                isOpen={!!openInfoForNoResident}
                close={() => setOpenInfoForNoResident(null)}
                messageTranslationKey={openInfoForNoResident}
            />
        </>
    );
};
export default DeclarationFilledPage;
