import React from "react";
import { useTranslation } from 'react-i18next';

const policy = {
    information_security_policy: [
        { index: 1, sub: [], translationKey: "policy1"},
        { index: 2, sub: [], translationKey: "policy2"},
        { index: 3, sub: [], translationKey: "policy3"},
        { index: 4, sub: [], translationKey: "policy4"},
        { index: 5, sub: [], translationKey: "policy5"},
    ],
    goals_objectives_implementation: [
        { 
            index: 6, 
            sub: [
                { translationKey: "policy6_1" },
                { translationKey: "policy6_2" },
                { translationKey: "policy6_3" },
                { translationKey: "policy6_4" },
                { translationKey: "policy6_5" },
                { translationKey: "policy6_6" },
            ], 
            translationKey: "policy6"
        },
        { 
            index: 7, 
            sub: [
                { translationKey: "policy7_1" },
                { translationKey: "policy7_2" },
                { translationKey: "policy7_3" },
                { translationKey: "policy7_4" },
                { translationKey: "policy7_5" },
                { translationKey: "policy7_6" },
                { translationKey: "policy7_7" },
                { translationKey: "policy7_8" },
                { translationKey: "policy7_9" },
                { translationKey: "policy7_10" },
                { translationKey: "policy7_11" },
            ], 
            translationKey: "policy7"
        },
        { index: 8, sub: [], translationKey: "policy8"},
        { index: 9, sub: [], translationKey: "policy9"},
        { index: 10, sub: [], translationKey: "policy10"},
    ]
}

const Policy = (props) => {
    const { t } = useTranslation()
    const { languageModule } = props;

    return (
        <div className="terms-main">
            <div className="terms-main privacy">
                {/* 1 */}
                <section className="title">
                    <strong>1. {!!languageModule ? languageModule.introduction : t(`policyPage.introduction`)}</strong>
                </section>
                <p>{!!languageModule ? languageModule.introduction_hint : t(`policyPage.introduction_hint`)}</p>
                {/* 2 */}
                <section className="title">
                    <strong>2. {!!languageModule ? languageModule.entity_responsible_app : t(`policyPage.entity_responsible_app`)}</strong>
                </section>
                <p>{!!languageModule ? languageModule.entity_responsible_app_hint : t(`policyPage.entity_responsible_app_hint`)}</p>
                <ul className="list parent-list privacy-list style-inside">
                    <li>
                        {!!languageModule ? languageModule.entity_responsible_app_hint_1 : t(`policyPage.entity_responsible_app_hint_1`)}
                    </li>
                    <li>
                        {!!languageModule ? languageModule.entity_responsible_app_hint_2 : t(`policyPage.entity_responsible_app_hint_2`)}
                    </li>
                </ul>
                {/* 3 */}
                <section className="title">
                    <strong>3. {!!languageModule ? languageModule.data_collect : t(`policyPage.data_collect`)}</strong>
                </section>
                <p>{!!languageModule ? languageModule.data_collect_hint : t(`policyPage.data_collect_hint`)}</p>
                <ul className="list parent-list privacy-list style-inside">
                    <li>
                        {!!languageModule ? languageModule.data_collect_hint_1 : t(`policyPage.data_collect_hint_1`)}
                    </li>
                    <li>
                        {!!languageModule ? languageModule.data_collect_hint_2 : t(`policyPage.data_collect_hint_2`)}
                    </li>
                </ul>
                {/* 4 */}
                <section className="title">
                    <strong>4. {!!languageModule ? languageModule.how_use_data : t(`policyPage.how_use_data`)}</strong>
                </section>
                <p>{!!languageModule ? languageModule.how_use_data_hint : t(`policyPage.how_use_data_hint`)}</p>
                <ul className="list parent-list privacy-list style-inside">
                    {Array(6).fill(0).map((_, index) => (
                        <li key={`how_use_data_hint_${index + 1}`}>
                            {!!languageModule ? languageModule[`how_use_data_hint_${index + 1}`] : t(`policyPage.how_use_data_hint_${index + 1}`)}
                        </li>
                    ))}
                </ul>
                {/* 5 */}
                <section className="title">
                    <strong>5. {!!languageModule ? languageModule.data_sharing : t(`policyPage.data_sharing`)}</strong>
                </section>
                <p>{!!languageModule ? languageModule.data_sharing_hint : t(`policyPage.data_sharing_hint`)}</p>
                <ul className="list parent-list privacy-list style-inside">
                    {Array(2).fill(0).map((_, index) => (
                        <li key={`data_sharing_hint_${index + 1}`}>
                            {!!languageModule ? languageModule[`data_sharing_hint_${index + 1}`] : t(`policyPage.data_sharing_hint_${index + 1}`)}
                        </li>
                    ))}
                </ul>
                {/* 6 */}
                <section className="title">
                    <strong>6. {!!languageModule ? languageModule.security_of_data : t(`policyPage.security_of_data`)}</strong>
                </section>
                <p>{!!languageModule ? languageModule.security_of_data_hint : t(`policyPage.security_of_data_hint`)}</p>
                {/* 7 */}
                <section className="title">
                    <strong>7. {!!languageModule ? languageModule.your_rights : t(`policyPage.your_rights`)}</strong>
                </section>
                <p>{!!languageModule ? languageModule.your_rights_hint : t(`policyPage.your_rights_hint`)}</p>
                <ul className="list parent-list privacy-list style-inside">
                    {Array(4).fill(0).map((_, index) => (
                        <li key={`your_rights_hint_${index + 1}`}>
                            {!!languageModule ? languageModule[`your_rights_hint_${index + 1}`] : t(`policyPage.your_rights_hint_${index + 1}`)}
                        </li>
                    ))}
                </ul>
                <p>{!!languageModule ? languageModule.your_rights_hint_end : t(`policyPage.your_rights_hint_end`)}</p>
                {/* 8 */}
                <section className="title">
                    <strong>8. {!!languageModule ? languageModule.changes_privacy_policy : t(`policyPage.changes_privacy_policy`)}</strong>
                </section>
                <p>{!!languageModule ? languageModule.changes_privacy_policy_hint : t(`policyPage.changes_privacy_policy_hint`)}</p>
                {/* 9 */}
                <section className="title">
                    <strong>9. {!!languageModule ? languageModule.contact_us : t(`policyPage.contact_us`)}</strong>
                </section>
                <p>{!!languageModule ? languageModule.contact_us_hint : t(`policyPage.contact_us_hint`)}</p>
                <ul className="list parent-list privacy-list style-inside">
                    {Array(2).fill(0).map((_, index) => (
                        <li key={`contact_us_hint_${index + 1}`}>
                            {!!languageModule ? languageModule[`contact_us_hint_${index + 1}`] : t(`policyPage.contact_us_hint_${index + 1}`)}
                        </li>
                    ))}
                </ul>
            </div>
            <ul className="list parent-list">
                {Object.entries(policy).map(([key, val]) => (
                    <React.Fragment key={key}>
                        <li>
                            <strong>{!!languageModule ? languageModule[key] : t(`policyPage.${key}`)}</strong>
                        </li>
                        {key === "information_security_policy" && (
                            <section className="command">
                                <div style={{ whiteSpace: 'pre-line' }}>
                                    {!!languageModule ? languageModule.command : t("policyPage.command")}
                                </div>
                            </section>
                        )}
                        {!!val.length && (
                            <li className="sub">
                                <ul className="list list-level-1">
                                    {val.map((subVal) => (
                                        <React.Fragment key={subVal.translationKey}>
                                            <li>
                                                {`${subVal.index}. ${!!languageModule ? languageModule[subVal.translationKey] : t(`policyPage.${subVal.translationKey}`)}`}
                                            </li>
                                            {!!subVal.sub.length && (
                                                <li className="sub">
                                                    <ul className="list list-level-2">
                                                        {subVal.sub.map((val, index) => (
                                                            <li key={val.translationKey}>
                                                                {`${index + 1}) ${!!languageModule ? languageModule[val.translationKey] : t(`policyPage.${val.translationKey}`)}`}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </ul>
                            </li>
                        )}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    )
}

export default Policy;