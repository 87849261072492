import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MobileIncomeTaxContext } from "../mobile_income_tax.context";
import { generateForm, getRootDataStore, getFormatedSection7_2, generateSection1_2RootForm, getFormatedSection7_1, generateSection1_1RootForm, section1Helpers, setRootDataStore } from "../utils";
import { mapperFieldLabels, mapperFormGroupTitle } from "../constants";
import { Accordion } from "../../../Accordion";
import styles from "./styles.module.scss"
import { Form, FormDataTypes } from "./Form";
import { Button } from "../../../Button";
import { Checkbox } from "../../../Checkbox";
import { EmployerForm } from "./EmployerForm";
import { Question } from "./Question";
import * as Icons from "../../../Icons";
import { Input } from "../../../Input";
import { classnames, fieldValidator, formValidator, formatNumberWithCommas, section1Head1TypesOfIncome } from "../../../../utils";
import { NotificationDialog } from "../../../NotificationDialog";
import { ConfirmDialog } from "../../../ConfirmDialog";

export const FromTaxAgentForm: React.FC<{ onSubmit: CallableFunction, next: CallableFunction }> = (props) => {
    const root = getRootDataStore().root;
    const { totalTax, ...cleanCodes } = root.section1_2;
    const { onSubmit, next } = props;
    const { t, i18n } = useTranslation();
    const [formDataHeadOne, setFormDataHeadOne] = useState<any[]>([]);
    const [formDataHeadTwo, setFormDataHeadTwo] = useState<any>({});
    const [section7_1, setSection7_1] = useState<{ dont_agree: IDataTypes<any>, new_income: IDataTypes<any> }>({ dont_agree: {}, new_income: {} });
    const [section7_2, setSection7_2] = useState<{ dont_agree: IDataTypes<any>, new_income: IDataTypes<any> }>({ dont_agree: {}, new_income: {} });
    const section7_1Ref = useRef({ data: section7_1, isValid: true, totalTax: 0 })
    const section7_2Ref = useRef({ data: section7_2, isValid: true, totalTax: 0 })
    const context = useContext(MobileIncomeTaxContext);
    const [firstQuestion, setFirstQuestion] = useState({ section1_1: { isOpen: false, asked: false }, section1_2: { isOpen: false, asked: false } });
    const [confirmDialog, setConfirmDialog] = useState<{ isOpen: boolean, approveCallback?: CallableFunction, message?: string }>({ isOpen: false, approveCallback: undefined, message: "" });

    const handleDontAgree = useCallback((key: string, callback = setSection7_1, type = "section1_1") => () => {
        callback((prev) => {
            if(prev.dont_agree[key]) {
                delete prev.dont_agree[key];
            }
            else {
                if(type === "section1_1") {
                    const findedData = getRootDataStore()?.root?.section1_1?.employerDataList.find((employer: any) => employer.tin === key);
                    if(!firstQuestion.section1_1.asked) {
                        setFirstQuestion((prev) => {
                            prev.section1_1.isOpen = true;
                            prev.section1_1.asked = true;
                            return { ...prev }
                        });
                    }
                    prev.dont_agree[key] = { ...findedData }
                }
                else if (type === "section1_2") {
                    const section1_2 = getRootDataStore()?.root?.section1_2;
                    const checkDontAgree = !!Object.keys(prev.dont_agree).length;
                    if(!checkDontAgree) {
                        if(!firstQuestion.section1_2.asked) {
                            setFirstQuestion((prev) => {
                                prev.section1_2.isOpen = true;
                                prev.section1_2.asked = true;
                                return { ...prev }
                            });
                        }
                        const dontAgree = Object.entries(section1_2).reduce((acc, [incomeKey, employeers]) => {
                            if(incomeKey !== "totalTax") {
                                acc[incomeKey] = ["code082", "code072"].includes(incomeKey) ? [] : (employeers as any[]).reduce((accEmp, employeer) => {
                                    accEmp[employeer.tin] = employeer;
                                    return accEmp;
                                }, {});
                            }       
                            return acc;   
                        }, {} as any);
                        prev.dont_agree = dontAgree;
                    }
                    else {
                        prev.dont_agree = {};
                    }
                    
                }
            }
            return { ...prev }
        })
    }, []);

    const headOneValidator = useMemo(() => {
        const validList = [] as Boolean[];
        const employeers = formDataHeadOne.reduce((acc, form) => {
            const validationResult = formValidator(form);
            if (!validationResult.isValid) {
                acc[form.tin.value] = validationResult.fieldsErrorTrigger;
            }
            validList.push(validationResult.isValid);
            return acc;
        }, {});
        const isValid = validList.every((item) => !!item);
        section7_1Ref.current.isValid = isValid;
        return { fieldsErrorTrigger: employeers, isValid }
    }, [formDataHeadOne]);

    const headTwoValidator = useMemo(() => {
        const validList = [] as Boolean[];
        const codes = Object.entries((formDataHeadTwo)).reduce((acc, [code, employeers]) => {
            Object.entries(employeers ?? {}).map(([tin, { new_income, ...form }]) => {
                Object.entries(form ?? {})
                    .map(([key, formGroup] : [string, any]) => {
                        const edit_field = formGroup?.fields?.[`${key}_edit`] 
                        if(edit_field && !!edit_field.error) {
                            if (!acc[code]) {
                                acc[code] = {}
                            }
                            acc[code][tin] = { ...(acc[code][tin] ?? {}), [`${key}_edit`]: edit_field.error };
                            validList.push(false);
                        }
                    })
            })
            return acc;
        }, {} as any);
        const isValid = validList.every((item) => !!item);
        section7_2Ref.current.isValid = isValid;
        return { isValid, fieldsErrorTrigger: codes }
    }, [formDataHeadTwo])

    const calculate7_1TotalTax = useMemo(() => {
        const list = Object.values(section7_1.dont_agree ?? {}).concat(Object.values(section7_1.new_income ?? {}));
        const totalTax = list.reduce((acc, income) => acc+= +income?.totalIncomeTax ?? 0, 0);
        section7_1Ref.current.totalTax = totalTax;
        return { list, totalTax };
    }, [JSON.stringify(section7_1)]);

    const calculate7_2TotalTax = useMemo(() => {
        const list = 
            Object.values(section7_2.dont_agree ?? {})
                .reduce((acc, item) => acc.concat(Object.values(item)), [])
                .concat(
                    Object.values(section7_2.new_income ?? {})
                        .reduce((acc, item) => acc.concat(Object.values(item)), [])
                )
        const totalTax = list.reduce((acc: number, income: { taxAmount?: number}) => acc+= +(income?.taxAmount ?? 0), 0);
        section7_2Ref.current.totalTax = totalTax;
        return { list, totalTax}
    }, [JSON.stringify(section7_2)]);

    const initSection1_1 = () => {
        setSection7_1(getFormatedSection7_1());
        setFormDataHeadOne(generateSection1_1RootForm());
    };

    const initSection1_2 = () => {
        setSection7_2(getFormatedSection7_2());
        setFormDataHeadTwo(generateSection1_2RootForm());
    };

    const handleSubmit = (section7_1: any, section7_2: any) => {
        const passDataSection7_1 = Object.values(section7_1.dont_agree).concat(Object.values(section7_1.new_income));
        const section7_2Keys = new Set([ ...Object.keys(section7_2.dont_agree), ...Object.keys(section7_2.new_income)]);
        const section7_2_added_new_items = !!Object.values(section7_2.new_income ?? {}).length;
        const passDataSection7_2 = section7_2Keys.size > 0 ? 
            Array.from(section7_2Keys).reduce((acc: any, code: string) => {
                const new_income = section7_2.new_income[code] ? Object.values(section7_2.new_income[code]) : [];
                const dont_agree = section7_2.dont_agree[code] ? Object.values(section7_2.dont_agree[code]) : [];
                acc[code] = [ ...new_income, ...dont_agree ];
                return acc
            }, { totalTax: section7_2Ref.current.totalTax })
            : { ...section1Helpers.emptySection7_2(root.section1_2), totalTax: 0 };
        onSubmit({ section7_1: { employerDataList: passDataSection7_1, totalTax: section7_1Ref.current.totalTax }, section7_2: passDataSection7_2, section7_2_added_new_items }, !(section7_1Ref.current.isValid && section7_2Ref.current.isValid));
    };

    useEffect(() => {
        initSection1_1();
        initSection1_2();
        if(!getRootDataStore()?.fromTaxAgent?.completed) {
            context?.setOutOfSteps?.((prev: any) => {
                if(!prev) {
                    prev = {}
                }
                prev.children = (
                    <Question
                        question={"have_income_from_tax_agent"}
                        yes={() => context?.setOutOfSteps?.(null)} 
                        no={() => {
                            context?.setOutOfSteps?.(null);
                            next();
                        }}
                    />
                )
                return { ...prev }
            })
        }

        return () => {
            if (window.location.pathname.includes("declaration/type/sd")) {
                handleSubmit(section7_1Ref.current.data, section7_2Ref.current.data);
            }
        }
    }, []);
    // calculate and validate section1_1 with section7_1;
    useEffect(() => {
        section7_1Ref.current.data = section7_1;
        if(getRootDataStore().root) {
            const { dont_agree, new_income } = section7_1;
            const formDataListNewIncome = generateForm(Object.values(new_income), mapperFormGroupTitle, true, mapperFieldLabels);
            setFormDataHeadOne(() => {
                const prevWithoutNewIncome = generateForm(Object.values(getRootDataStore().root?.section1_1?.employerDataList), mapperFormGroupTitle, false, mapperFieldLabels)
                Object.entries(dont_agree).map(([employeerKey, employerData]) => {
                    const findedForm = prevWithoutNewIncome.find((form: any) => form.tin.value === employeerKey);
                    if (findedForm) {
                        Object.entries(findedForm)
                            .filter(([_, value]) => (value as FormGroup).type === "form_group")
                            .map(([key, formGroup]) => {
                                const tempValue = formGroup as FormGroup;
                                const newField =  { ...tempValue.fields[tempValue.key as string] };
                                let validation: Validation = { disabled: false, isRequired: false };
                                const { salary, civilSalary, allBenefits, amountReduce, otherTaxIncome } = employerData;
                                const emptyTotalIncome = salary ?? civilSalary ?? allBenefits ?? otherTaxIncome ?? amountReduce;
                                const calculateTotalIncome = Math.trunc(
                                    (salary ?? 0) + (civilSalary ?? 0) + (allBenefits ?? 0) + (otherTaxIncome ?? 0) - (amountReduce ?? 0)
                                );

                                if (key === "amountReduce" && amountReduce !== undefined) {
                                    const sum = (salary ?? 0) + (civilSalary ?? 0) + (allBenefits ?? 0) + (otherTaxIncome ?? 0);
                                    const maxAmountReduce = sum - sum / 1.05;
                                    validation.max = maxAmountReduce;
                                    validation.maxError= "error_message_in_1_1_9";
                                }

                                const edit_field = { ...newField, validation, value: employerData[key] };

                                if(key === "totalIncome") {
                                    edit_field.withoutLabel = true;
                                    validation.disabled = true;
                                    validation.isRequired = true;
                                }

                                const { triggerError } = fieldValidator(edit_field);
                                if(triggerError.type !== "none") {
                                    edit_field.error = triggerError.message ?? triggerError.errorKey
                                }
                                else {
                                    edit_field.error = undefined
                                }

                                tempValue.fields[`${tempValue.key}_edit`] = edit_field;
                            });
                    }
                })
                return [...prevWithoutNewIncome, ...formDataListNewIncome]
            });
        }
    }, [JSON.stringify(section7_1)]);

    // calculate and validate section1_2 with section7_2;
    useEffect(() => {
        section7_2Ref.current.data = section7_2;
        const formatedNewIncome = Object.entries(section7_2.new_income).reduce((acc:any, [key, employeersList]) => {
            acc[key] = Object.values(employeersList)
            return acc;
        }, {});
        const newIncomesSection1_2 = generateSection1_2RootForm(formatedNewIncome, true);
        const rootIncomesSection1_2 = generateSection1_2RootForm();
        const entries = Object.entries(rootIncomesSection1_2).map(([incomeCode, employeers]: [string, any]) => {
            const checkDontAgree = section7_2.dont_agree[incomeCode];
            if(!!checkDontAgree) {
                Object.entries(employeers).map(([employeerKey, formData]) => {
                    Object.entries(formData as any).map(([fieldKey, formGroup]: [string, any]) => {
                        if(fieldKey !== "tin" && fieldKey !== "taxpayerName" && fieldKey !== "new_income") {
                            const edit_field = { 
                                ...formGroup.fields[fieldKey], 
                                value: checkDontAgree[employeerKey]?.[fieldKey]
                            };
                            const validation: Validation = { disabled: false, isRequired: true };
                            // check key validate here
                            edit_field.validation = validation;
                            const { triggerError } = fieldValidator(edit_field);
                            if(triggerError.type !== "none") {
                                edit_field.error = triggerError.message ?? triggerError.errorKey;
                            }
                            else {
                                edit_field.error = undefined;
                            }
                            formGroup.fields[`${fieldKey}_edit`] = edit_field;
                        }
                    }) 
                })
            }
            return [incomeCode, { ...employeers, ...newIncomesSection1_2[incomeCode] }]
        });
        const updatedForm = Object.fromEntries(entries);
        setFormDataHeadTwo(updatedForm);

    }, [JSON.stringify(section7_2)]);

    const handleChange = useCallback((tin: string, code?: string, callback = setSection7_1) => {
        return (data: any) => {
            const { value, key } = data;
            const fieldKey =  key.replace("_edit", "");
            callback((prev) => {
                if(!code) {
                    if(prev.dont_agree[tin]) {
                        prev.dont_agree[tin][fieldKey] = value;
                        const { salary, civilSalary, allBenefits, amountReduce, otherTaxIncome } = prev.dont_agree[tin];
                        const calculateTotalIncome = Math.trunc(
                            (salary ?? 0) + (civilSalary ?? 0) + (allBenefits ?? 0) + (otherTaxIncome ?? 0) - (amountReduce ?? 0)
                        );
                        prev.dont_agree[tin].totalIncome = calculateTotalIncome;
                    }
                }
                else {
                    prev.dont_agree[code][tin][fieldKey] = value
                }
                return { ...prev }
            })
        }
    }, []);

    const submitAddEmployer = useCallback((tin?: string, callback = setSection7_1, code?: string) => {
        return (data: any) => {
            if(!!code && ["code082", "code072"].includes(code)) {
                const store = getRootDataStore();
                const prevCodesData = store.root?.section1_2?.[code] ?? [];
                const findEmployerIndex = prevCodesData.findIndex((employee: any) => employee.tin === data.tin);
                if(findEmployerIndex === -1) {
                    store.root?.section1_2?.[code].push(data)
                }
                else {
                    store.root?.section1_2?.[code].splice(findEmployerIndex, 1, data)
                };
                const { totalTax, ...codes } = store.root?.section1_2
                const calculateTotalTax = Object.values(codes).flat().reduce((acc: number, employee: any) => acc += (employee.taxAmount ?? 0), 0);
                store.root.section1_2.totalTax = Math.trunc(calculateTotalTax);
                setRootDataStore(store);
                initSection1_2();
            }
            else {
                callback((prev) => {
                    if (!code) {
                        if(tin && tin !== data.tin) {
                            delete prev.new_income[tin]
                        }
                        prev.new_income[data.tin] = data;
                    }
                    else {
                        if(!prev.new_income[code]) {
                            prev.new_income[code] = {}
                        }
                        if(tin && tin !== data.tin && prev.new_income[code]?.[tin]) {
                            delete prev.new_income[code][tin]
                        }
                        prev.new_income[code][data.tin] = data;
                    }
                    return { ...prev }
                })
            }
            context?.setOutOfSteps?.(null)
        }
    }, [])

    const handleAddedEmployer = (tin?: string, formFieldKeys?: string[], type = "section1_1", code?: string) => {
        const callback = type === "section1_1" ? setSection7_1 : setSection7_2
        context.setOutOfSteps?.((prev: any) => {
            if (!prev) {
                let data;
                if(tin && type == "section1_1") {
                    data = section7_1.new_income[tin];
                }
                else if (tin && type == "section1_2" && code) {
                    const store = getRootDataStore()
                    data = ["code082", "code072"].includes(code) ? store.root.section1_2[code].find((employee: any) => employee.tin === tin) : section7_2.new_income[code][tin];
                }
                return { children: (
                    <EmployerForm
                        onSubmit={submitAddEmployer(tin, callback, code )}
                        data={data}
                        mode={tin ? "edit" : "create"}
                        formFieldKeys={formFieldKeys}
                        code={code}
                    /> 
                )}
            }
            return prev
        })
    };

    const handleRemoveEmployer = (tin: string, callback = setSection7_1, code?: string) => {
        return () => {
            const call = () => {
                callback((prev) => {
                    if (prev.new_income[tin] && !code) {
                        delete prev.new_income[tin];
                    }
                    else if (!!code && prev.new_income[code]?.[tin]) {
                        delete prev.new_income[code]?.[tin];
                    }
                    else if(!!code && ["code082", "code072"].includes(code)) {
                        const store = getRootDataStore();
                        const prevCodesData = store.root?.section1_2?.[code] ?? [];
                        const findEmployerIndex = prevCodesData.findIndex((employee: any) => employee.tin === tin);
                        if(findEmployerIndex !== -1) {
                            store.root?.section1_2?.[code].splice(findEmployerIndex, 1);
                            const { totalTax, ...codes } = store.root?.section1_2
                            const calculateTotalTax = Object.values(codes).flat().reduce((acc: number, employee: any) => acc += (employee.taxAmount ?? 0), 0);
                            store.root.section1_2.totalTax = Math.trunc(calculateTotalTax);
                            setRootDataStore(store);
                            initSection1_2();
                        }
                    }
                    return { ...prev };
                })
                setConfirmDialog({ isOpen: false, approveCallback: undefined, message: "" })
            };
            setConfirmDialog({ isOpen: true, approveCallback: call, message: "remove_employeer_details"});
        }
    };

    const handleLabelRewriter = (head: "headTwo" | "headOne") => {
        return (translationKey: string) => {
            if(head === "headTwo") {
                const suffixes = {
                    tin: '[1.2.1]',
                    taxpayerName: '[1.2.2]'
                };
                const suffix = suffixes[translationKey as keyof typeof suffixes] || '';
                return `${t(translationKey)} ${suffix}`;
            }
            if(translationKey === "tr_1_1_4") {
                return t("totalIncomeTax");
            }
            const findedField = section1Head1TypesOfIncome.find((item) => item.translation_key === translationKey);
            return findedField ? `${t(translationKey)} [${findedField.number}]` : t(translationKey);
        };
    }

    return (
        <div className={styles.FromTaxAgent}>
            <div className={styles.HeadOne}>
                <Accordion
                    header={(
                        <span className={styles.Section1AccHeader}>
                            <span>{t('form1_section1_1')}</span>
                            <span>
                            <a href={`/${i18n.language}/declaration-sd-help?section=1_1`} target="_blank">
                                <Icons.InfoOutline />
                            </a>
                            </span>
                        </span>
                    )}
                    isOpened className={styles.Accordion}
                >
                    {formDataHeadOne.map(({ new_income, ...formData}, index) => (
                        <Accordion 
                            className={classnames({[styles.FormItem as string]: true, [styles.Error as string]: !!headOneValidator.fieldsErrorTrigger[formData.tin.value] })} 
                            key={index}
                            header={(
                                <span className={styles.AccordionHeaderContent}>
                                    <span>{`${formData.taxpayerName.value} ( ${t("tin")} ${formData.tin.value} )`}</span>
                                    {(section7_1.dont_agree[formData.tin.value] || new_income) && <span><Icons.Bookmark /></span>}
                                </span>
                            )}
                        >
                            <Form 
                                data={formData} 
                                onChange={handleChange(formData.tin.value)}
                                labelRewriter={handleLabelRewriter("headOne")}
                            />
                            {!new_income && ( 
                                <Checkbox
                                    boxSize="small" 
                                    label={t("dont_agree_with_info")} 
                                    className={styles.DontAgree} 
                                    onChange={handleDontAgree(formData.tin.value)}
                                    checked={section7_1.dont_agree[formData.tin.value]}
                                />
                            )}
                            {new_income && (
                                <div className={styles.EmployerActionsSection}>
                                    <Button variant="text" onClick={() => handleAddedEmployer(formData.tin.value)}>
                                        <Icons.Edit />
                                    </Button>
                                    <Button variant="text" className={styles.Trash} onClick={handleRemoveEmployer(formData.tin.value)}>
                                        <Icons.Trash />
                                    </Button>
                                </div>
                            )}
                        </Accordion>
                    ))}
                    <div className={styles.Total}>
                        <p>{t("total")} [1.1.11]</p>
                        <Input
                            disabled
                            label={t("totalIncomeTax")}
                            value={formatNumberWithCommas(getRootDataStore().root?.section1_1?.totalTax)}
                            
                        />
                        {!!calculate7_1TotalTax.list?.length && (
                            <Input
                                disabled
                                value={formatNumberWithCommas(calculate7_1TotalTax.totalTax)}
                            />
                        )}
                    </div>
                    <Button variant='outlined' color='green' onClick={() => handleAddedEmployer()}>{t("added_employer")}</Button>
                </Accordion>
            </div>
            <div className={styles.HeadTwo}>
                <Accordion
                    header={(
                        <span className={styles.Section1AccHeader}>
                            <span>{t('form1_section1_2')}</span>
                            <span>
                            <a href={`/${i18n.language}/declaration-sd-help?section=1_2`} target="_blank">
                                <Icons.InfoOutline />
                            </a>
                            </span>
                        </span>
                    )}
                    isOpened
                    className={styles.Accordion}
                >
                    {Object.entries(formDataHeadTwo).map(([incomeKey, employeers]: [string, any]) => {
                        const haveEmployers = Object.values(employeers as any);

                        return (
                            <div className={styles.IncomeType} key={`${incomeKey}_${haveEmployers.length}`}>
                                <Accordion 
                                    header={(
                                        <span className={styles.AccordionHeaderContent}>
                                            <span>{t(`sec_1_2_${incomeKey}`)}</span>
                                            {(!!haveEmployers.length && (!!Object.keys(section7_2.dont_agree).length || !!Object.keys(section7_2.new_income[incomeKey] ?? {}).length || ["code082", "code072"].includes(incomeKey)))&& <span><Icons.Bookmark /></span>}
                                        </span>
                                    )} 
                                    className={classnames({ [styles.IncomeAccordion]: true, [styles.Error]: !!headTwoValidator.fieldsErrorTrigger[incomeKey] })} 
                                    isOpened={!!haveEmployers.length || !!section7_2.dont_agree[incomeKey]}
                                >
                                    {!!haveEmployers.length && (
                                        <div className={styles.IncomeItem}>
                                            {Object.entries(employeers as any).map(([key, { new_income, ...formData }] : [string, any]) => (
                                                <Accordion 
                                                    className={classnames({ [styles.IncomeItemAccordion]: true, [styles.Error]: !!headTwoValidator.fieldsErrorTrigger[incomeKey]?.[key] })} 
                                                    header={(
                                                        <span className={styles.AccordionHeaderContent}>
                                                            <span>{`${formData?.taxpayerName?.fields?.taxpayerName?.value} ( ${t("tin")} ${formData?.tin?.fields?.tin?.value} )`}</span>
                                                            {(new_income || ["code082", "code072"].includes(incomeKey) || !!Object.keys(section7_2.dont_agree).length) && <span><Icons.Bookmark /></span>}
                                                        </span>
                                                    )} 
                                                    key={`${key}_${!!section7_2.dont_agree[incomeKey]}`}
                                                    isOpened={!!section7_2.dont_agree[incomeKey]}
                                                >
                                                    <Form data={formData as FormDataTypes} onChange={handleChange(formData.tin.fields.tin.value, incomeKey, setSection7_2)} labelRewriter={handleLabelRewriter("headTwo")} />
                                                    {(new_income || ["code082", "code072"].includes(incomeKey)) && (
                                                        <div className={styles.EmployerActionsSection}>
                                                            <Button 
                                                                variant="text" 
                                                                onClick={() => handleAddedEmployer(formData.tin.fields.tin.value, ["tin", "taxpayerName", "taxAmount", "incomeAmount"], "section1_2", incomeKey)}
                                                            >
                                                                <Icons.Edit />
                                                            </Button>
                                                            <Button 
                                                                variant="text" 
                                                                className={styles.Trash} 
                                                                onClick={handleRemoveEmployer(formData.tin.fields.tin.value, setSection7_2, incomeKey)}
                                                            >
                                                                <Icons.Trash />
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Accordion>
                                            ))}
                                        </div>
                                    )}
                                    <div className={styles.ActionsGroup}>
                                        <Button 
                                            variant='outlined' 
                                            color='green' 
                                            onClick={() => handleAddedEmployer(undefined, ["tin", "taxpayerName", "taxAmount", "incomeAmount"], "section1_2", incomeKey)}
                                        >
                                            {t("added_employer")}
                                        </Button>
                                    </div>
                                </Accordion>
                            </div>
                        )
                    })}
                    <div className={styles.HeadTwoDontAgreeSection}>
                        {!!Object.values(cleanCodes ?? {}).flat().length && (
                            <Checkbox
                                boxSize="small" 
                                label={t("dont_agree_with_info")} 
                                className={styles.DontAgree} 
                                onChange={handleDontAgree("incomeKey", setSection7_2, "section1_2")}
                                checked={!!Object.keys(section7_2.dont_agree).length}
                            />
                        )}
                    </div>
                    <div className={styles.Total}>
                        <p>{t("total")} [1.2.17]</p>
                        <Input
                            disabled
                            label={`${t("totalIncomeTax")}`}
                            value={formatNumberWithCommas(getRootDataStore().root?.section1_2?.totalTax)}
                        />
                        {!!calculate7_2TotalTax.list?.length && (
                            <Input
                                disabled
                                value={formatNumberWithCommas(calculate7_2TotalTax.totalTax)}
                            />
                        )}
                    </div>
                </Accordion>
            </div>
            <NotificationDialog
                isOpen={firstQuestion.section1_1.isOpen || firstQuestion.section1_2.isOpen}
                close={() => {
                    setFirstQuestion((prev) => {
                        prev.section1_1.isOpen = false;
                        prev.section1_2.isOpen = false;
                        return { ...prev };
                    })
                }}
                messageTranslationKey="TYPED_DATA_ONLY_FOR_INFO"
                type='info'
                head={t("attention")}
            />
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                approve={confirmDialog.approveCallback ?? (() => {})}
                close={() => setConfirmDialog({ isOpen: false, approveCallback: undefined, message: "" })}
                questionMessageKey={confirmDialog.message}
                withoutHandleProcess={true}
                className={styles.ConfirmModal}
            />
            <div className={styles.BTNSection}>
                <Button onClick={() => next()}>{t("next")}</Button>
            </div>
        </div>
    )
}