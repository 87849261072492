export interface IError {
    generalError: string;
}

export interface IRegistrationFieldsErrorsDefault extends IError{
    tin: string;
    username: string;
    password: string;
    confirmPassword: string;
}

export interface IResetFieldsErrorsDefault extends IError{
    username: string;
    password: string;
    confirmPassword: string;
}

export interface IRegistrationFormData {
    verificationTin: string;
    tin: string;
    username: string;
    password: string;
    confirmPassword: string;
}

export interface IRegistrationDTO {
    tin: string;
    username: string;
    password: string;
    confirmationCode: string;
}

export interface IResetPasswordFormData {
    username: string;
    password: string;
    confirmPassword: string;
}

export interface IResetPasswordDTO {
    username: string;
    email: string;
}

export interface IResetPasswordChangedDTO {
    username: string;
    newPassword: string;
    key: string;
}

export interface IPasswordChangedDTO {
    newPassword: string;
    oldPassword: string;
}

export enum Role {
    ROLE_RESIDENT = "ROLE_RESIDENT",
    ROLE_NO_RESIDENT = "ROLE_NO_RESIDENT"
}

export enum OS {
    android = 'android',
    ios='ios'
}

export interface NavigationItem {
    translateKey: string; 
    path: string; 
    subMenu: NavigationItem[];
    roles?: Role[];
    disableMenuForWithoutTin?: boolean;
}

export enum ExceptionType {
    frontend = 'frontend',
    backend = 'backend'
}

export interface IProfileDetailsDTO {
    bankAccount: string;
    email: string;
    phone: string;
}

export interface IReceiveTinData {
    psn: string;
    firstName: string;
    lastName: string;
    surName: string;
    documentType?: number;
    documentNumber: string;
    region?: number;
    community?: number;
    residence?: number;
    street: string;
    buildingHouse: string;
    house: string;
    phoneNumber: string;
    postalCode: string;
    personId: number;
    draftId?: number;
    dateOfBirth: string;
    selectedResidenceRegion?: number;
    selectedResidenceCommunity?: number;
    selectedResidenceArea?: number;
    selectedResidenceStreet: string;
    selectedResidenceHouse: string;
    selectedResidenceApartment: string;
}

export class Exception extends Error {
    from: string;

    constructor(message: string, from: ExceptionType = ExceptionType.backend) {
        super(message);
        this.from = from;
    }

    getObjectMessage() {
        try {
            return JSON.parse(this.message);
        } catch (er) {
            return {};
        }
    }
}

export interface I_section1_1_employerDataList {
    tin: Field<string>,
    taxpayerName: Field<string>,
    salary: Field<string | number>,
    civilSalary: Field<string | number>,
    allBenefits: Field<string | number>,
    otherTaxIncome: Field<string | number>,
    amountReduce: Field<string | number>,
    totalIncome: Field<string | number>,
    totalIncomeTax: Field<string | number>
}

export interface I_incoming_tax_social_payment {
    adjustment: boolean,
    showAdjustment: boolean,
    taxYear?: Field<Date | undefined>,
    taxpayerName?: Field<string>,
    psn?: Field<string>,
    tin?: Field<string>,
    physSocParticipationType?: Field<string>,
    taxpayerAddress?: Field<string>,
    taxpayerPhone?: Field<string>,
    section1_1?: {
        employerDataList: I_section1_1_employerDataList[],
        totalIncome: Field<string | number>,
        totalTax: Field<string | number>
    },
    section7_1?: {
        employerDataList: I_section1_1_employerDataList[],
        totalIncome: Field<string | number>,
        totalTax: Field<string | number>
    },
    section1_2?: {
        [key: string]: ({ tin: Field<string>, taxpayerName: Field<string>, incomeAmount: Field<string | number | undefined>, taxAmount: Field<string | number | undefined> }[]) | Field<string | number>
    },
    section2?: {
        f2_1: Field<number | string>,
        f2_2: Field<number | string>,
        f2_3: Field<number | string>,
        f2_4: Field<number | string>,
        f2_5: Field<number | string>,
        f2_6: Field<number | string>,
        f2_7: Field<number | string>,
        f2_8: Field<number | string>,
        f2_9: Field<number | string>,
        f2_10: Field<number | string>,
        f2_11: Field<number | string>,
        f2_12: Field<number | string>,
        f2_13: Field<number | string>,
        f2_14: Field<number | string>,
        f2_15: Field<number | string>,
        f2_16: Field<number | string>,
        f2_17: Field<number | string>,
        f2_18: Field<number | string>,
        f2_19: Field<number | string>,
        f2_20: Field<number | string>,
        f2_21: Field<number | string>,
        f2_22: Field<number | string>,
        f2_23: Field<number | string>,
        f2_24: Field<number | string>,
        f2_25: Field<number | string>,
        f2_26: Field<number | string>,
        f2_27: Field<number | string>,
    },
    section3: {
        salary: Field<number | string>,
        civilContractsSalary: Field<number | string>,
        salaryTaxableIncomeReduce: Field<number | string>,
        salaryTaxableIncome: Field<number | string>,
        salaryTaxableRate: Field<number | string>,
        royaltyIncome: Field<number | string>,
        royaltyRate: Field<number | string>,
        percent10Income: Field<number | string>,
        percent10Rate: Field<number | string>,
        percent20Income: Field<number | string>,
        percent20Rate: Field<number | string>,
    },
    section4?: {
        educationalCostAmount: Field<number | string>,
        educationalReturnAmount?: Field<number | string>,
        healthEnsuranceAmount: Field<number | string>,
        healthEnsuranceReturnAmount?: Field<number | string>,
        totalCostAmount?: Field<number | string>,
        totalReturnAmount?: Field<number | string>,
    },
    section6?: {
        selfEmployeeIncome: Field<number | string>,
        selfEmployeeSocRate: Field<number | string>,
        selfEmployeeSocPayment: Field<number | string>
    }
}
