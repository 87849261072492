import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface IDeclaration {
    rows: any[];
    pageSize: number;
    currentPage: number;
    total: number;
}

const initialState: IDeclaration = {
    rows: [],
    pageSize: 20,
    currentPage: 1,
    total: 0
};

export const declarationSubmittedSlice = createSlice({
    name: 'declarationSubmitted',
    initialState,
    reducers: {
        initializeRows: (_state, action: PayloadAction<any>) => {
            return { ..._state, rows: action.payload }
        },
        initializeTotal: (_state, action: PayloadAction<any>) => {
            return { ..._state, total: action.payload }
        },
        changePage: (_state, action: PayloadAction<any>) => {
            return { ..._state, currentPage: action.payload }
        }
    },
})

export const { initializeRows, initializeTotal, changePage } = declarationSubmittedSlice.actions;
export default declarationSubmittedSlice.reducer;
