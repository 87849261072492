import React from "react";
import { createBrowserRouter } from "react-router-dom";
import * as Pages from '../pages';
import DeclarationHelp from '../components/declaration-help';
import DeclarationSRHelp from '../components/declaration-sr-help';
import { AuthController } from './auth-controller';
import GlobalLayout from '../Layouts/GlobalLayout';
import { isNotResidentUser } from '../utils';
// import { Actions } from "../pages/Actions";

interface IPath {
    path: string;
    element: React.ReactElement
}

const privatePages = [
    Pages.OfficePage,
    Pages.Profile,
    Pages.ExpensesPage,
    Pages.RevenuesTaxAgent,
    Pages.RevenuesTaxAgentOther,
    Pages.RevenuesOtherSource,
    Pages.ReceiveTinPage,
    Pages.DeclarationPage,
    Pages.DeclarationDraftPage,
    Pages.DeclarationFilledPage,
    Pages.DeclarationSubmittedPage,
    Pages.DeclarationFormSwitchPage,
    Pages.PaymentsPage,
    Pages.PaymentOrderForm
];
let authPaths: IPath[] = [];

for (const Page of privatePages) {
    authPaths.push({
        path: Page.path,
        element: <Page.default />
    });
}

export default function(authData: any, _navigator: Navigator) {
    const isLogin = !!authData?.role;
    const paths = [];
    if (!isLogin) {
        // Not Authorized
        paths.push(
            {
                path: Pages.LoginPage.path,
                element: <Pages.LoginPage.default />
            },
            {
                path: Pages.RegistrationPage.path,
                element: <Pages.RegistrationPage.default />
            },
            {
                path: Pages.ResetPassword.path,
                element: <Pages.ResetPassword.default />
            },
            ...(privatePages.map(Page => ({
                path: Page.path,
                element: <AuthController redirectTo={Pages.LoginPage.path} />
            }))),
            {
                path: '',
                element: <AuthController redirectTo={Pages.LoginPage.path} withoutRedirectPath />
            },
            {
                path: '*',
                element: <Pages.PageNotFound.default />
            }
        );
    } else {
        if (isNotResidentUser(authData.role)) {
            const noneAvailablePathsForNotResident = [Pages.ExpensesPage.path, Pages.RevenuesTaxAgent.path, Pages.RevenuesTaxAgentOther.path, Pages.ReceiveTinPage.path];
            authPaths = authPaths.filter(item => !noneAvailablePathsForNotResident.includes(item.path));
        }
        // Authorized
        paths.push(...authPaths);
        paths.push(
            {
                path: Pages.LoginPage.path,
                element: (
                    <AuthController redirectTo={Pages.OfficePage.path} withLocation authData={authData} />
                )
            },
            {
                path: Pages.RegistrationPage.path,
                element: <Pages.RegistrationPage.default />
            },
            {
                path: Pages.ResetPassword.path,
                element: <Pages.ResetPassword.default />
            },
            {
                path: '',
                element: <AuthController redirectTo={Pages.OfficePage.path} withoutRedirectPath />
            },
            {
                path: '*',
                element: <Pages.PageNotFound.default />
            }
        );
    }

    return createBrowserRouter([
        // TODO REMOVE page then
        // {
        //     path: '/controller',
        //     element: <Actions />
        // },
        {
            path: '/:lngCode?/declaration-sd-help',
            element: <DeclarationHelp />
        },
        {
            path: '/:lngCode?/declaration-sr-help',
            element: <DeclarationSRHelp />
        },
        {
            path: '/:lngCode?/',
            element: <GlobalLayout />,
            children: paths
        }
    ]);
}
