import axios from "../api/axios";
import {
    IPasswordChangedDTO,
    IProfileDetailsDTO,
    IRegistrationDTO,
    IResetPasswordChangedDTO,
    IResetPasswordDTO
} from "./types";

export const checkAuthToken = async (arg: { cbYesEm?: Function } = {}) => {
    const unauthorized = { data: null };
    await arg.cbYesEm?.();
    if (!localStorage.getItem('token') && !localStorage.getItem('refreshToken')) return unauthorized;
    return axios.get('/api/auth/checkToken').then(res => res).catch(() => unauthorized);
};

export const getProfile = (query?: any) => axios.get('/api/profile', { params: { ...query } });

export const signin = async (payload: { username: string, tin: string, password: string }) => {
    return await axios.post("/api/auth/login", payload).then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        return res
    })
};

export const requestToLoginNIDAuth = (data: { sessionId: string | null, sessionVerifier: string | null }) => axios.post('/api/auth/nidLogin', data);

export const requestNIDAuthInfo = (frontendPath: string, language?: string) => axios.get(`/api/auth/nidAuthInfo?frontendPath=${frontendPath}${language ? `&language=${language}` : ''}`);

export const sentForResetPass = (send: IResetPasswordDTO) => axios.post("/api/auth/requestPasswordReset", send);

export const checkConfirmationCode = (verificationToken: string) => axios.get("/api/register/checkConfirmationCode", { params: { c: verificationToken } });

export const checkResetPasswordUsername = (verificationToken: string) => axios.get("/api/auth/getResetPasswordUsername", { params: { k: verificationToken } });

export const registerUser = (send: IRegistrationDTO) => axios.post("/api/register/user", send);

export const resetUserPasswordChanged = (send: IResetPasswordChangedDTO) => axios.post("/api/auth/resetPassword", send);

export const passwordChanged = (send: IPasswordChangedDTO) => axios.post("/api/profile/password", send);

export const getBanksNames = () => axios.get("/api/dict/banks");

export const updateProfileDetails = (send: Partial<IProfileDetailsDTO>) => axios.post("/api/profile/details", send);

export const updateDefaultLanguage = (lang: string) => axios.post("/api/profile/defaultLanguage", { value: lang });

export const receiveTinResidentPersonInfo = () => axios.get("/api/profile/residentPersonInfoPolice");

export const getRegions = () => axios.get("/api/dict/regions");

export const getCommunities = (regionId: number) => axios.get("/api/dict/communities", { params: { regionId } });

export const getResidences = (communityId: number) => axios.get("/api/dict/areas", { params: { communityId } });

export const getPostalCodes = () => axios.get("/api/dict/postalToDistrictMapping");

export const getDocumentTypes = (params: any = {}) => axios.get("/api/dict/documentTypes", { params });

export const getInvoiceStatusTypes = () => axios.get("/api/dict/invoiceStatusTypes");

export const getSocialCostTypes = () => axios.get("/api/dict/socialCostTypes");

export const getTaxpayers = (prefix: string) => axios.get("/api/dict/lookupTaxpayer", { params: { prefix } });

export const tinRequestCreateDraft = (data: any) => axios.post("/api/tinrequest/createSEForm", data);

export const tinRequestDownloadPDF = (draftId: number) => axios.get("/api/tinrequest/getSEFormPdf", { params: { draftId }, responseType: 'blob' });

export const tinRequestUploadPDF = (draftId: number, formData: FormData) => axios.post(`/api/tinrequest/uploadSEFormSignedPdf?draftId=${draftId}`, formData);

export const tinRequestSubmitToPEK = (draftId: number) => axios.post(`/api/tinrequest/submitSEForm?draftId=${draftId}`);

export const tinRequestSignWithMID = (data: { draftId: number, phone: string }) => axios.post('/api/tinrequest/signSEFormWithMobile', data);

export const tinRequestCheckStatusByMID = (draftId: number) => axios.post(`/api/tinrequest/checkSignStatus?draftId=${draftId}`);

export const tinRequestStopStatusByMID = (draftId: number) => axios.post(`/api/tinrequest/stopPendingSign?draftId=${draftId}`);

export const getSupplierList = () => axios.get('/api/invoices/supplierList');

export const getEmployeesMonthlyTinList = () => axios.get('/api/income/employeeMonthlyTinList');

export const getEmployeesYearlyTinList = () => axios.get('/api/income/employeeYearlyTinList');

export const getSocialCostList = (data: any) => axios.get('/api/invoices/invoiceList', { params: { ...data } });

export const getSocialCostTotal = (data: any) => axios.get('/api/invoices/invoiceCount', { params: { ...data } });

export const getSocialCostPDF = (serialNo: string) => axios.get('/api/invoices/getInvoicePdf', { params: { serialNo }, responseType: 'blob' });

export const getSalaryIncomeList = (data: any) => axios.get('/api/income/salaryIncomeList', { params: { ...data } });

export const getSalaryIncomeTotal = (data: any) => axios.get('/api/income/salaryIncomeCount', { params: { ...data } });

export const getYearlyIncomeList = (data: any) => axios.get('/api/income/yearlyIncomeList', { params: { ...data } });

export const getYearlyIncomeTotal = (data: any) => axios.get('/api/income/yearlyIncomeCount', { params: { ...data } });

export const getEmployeeIncomeTypes = () => axios.get('/api/dict/employeeIncomeTypes');

export const getEmployeeOtherSourceIncomeTypes = () => axios.get('/api/dict/otherSourceIncomeTypes');

export const getOtherSourceIncomeList = (data: any) => axios.get('/api/income/otherSourceIncomeList', { params: { ...data } });

export const getOtherSourceIncomeTotal = (data: any) => axios.get('/api/income/otherSourceIncomeCount', { params: { ...data } });

export type otherSourceIncomeCreateUpdate = {
    incomeAmount: number;
    incomeTypeCode: string;
    month: string;
    sourceInfo: string;
    taxAmount: number;
    taxRate: number;
    year: string;
}

export const createOtherSourceIncome = (data: otherSourceIncomeCreateUpdate) => axios.post('/api/income/otherSourceIncome/', data);

export const updateOtherSourceIncome = (id: number, data: otherSourceIncomeCreateUpdate) => axios.put(`/api/income/otherSourceIncome/${id}`, data);

export const getByIdOtherSourceIncome = (id: number) => axios.get(`/api/income/otherSourceIncome/${id}`);

export const deleteByIdOtherSourceIncome = (id: number) => axios.delete(`/api/income/otherSourceIncome/${id}`);

export const getSection1Head1 = (year: string | number) => axios.get('/api/income/salaryIncomeSummary', { params: { year } });

export const getSection1Head2 = (year: string | number) => axios.get('/api/income/yearlyIncomeSummary', { params: { year } });

export const getSection3 = (year: string | number) => axios.get('/api/income/otherSourceIncomeSummary', { params: { year } });

export const getSection4 = (supplyYear: string | number) => axios.get('/api/invoices/invoiceSummary', { params: { supplyYear } });

export const getSection5Point5_3 = (year: string | number) => axios.get('/api/income/hypotecReturnedAmount', { params: { year } });

export const getSection5Point5_4And5_5 = (year: string | number) => axios.get('/api/income/selfIncome2023', { params: { year } });

export const getDeclarationTypes = () => axios.get('/api/dict/declTypes');

export const createDeclSDDraft = (data: any) => axios.post('/api/decl/selfdecl/draft/', data);

export const updateDeclSDDraftByID = (id: string, data: any) => axios.put(`/api/decl/selfdecl/draft/${id}`, data);

export const getDeclSDDraftByID = (id: string) => axios.get(`/api/decl/selfdecl/draft/${id}`);

export const deleteDeclSDDraftByID = (id: string) => axios.delete(`/api/decl/draft/${id}`);

export const getDeclSDDraftList = (params: any) => axios.get('/api/decl/draft/list', { params });

export const getDeclSDDraftCount = () => axios.get('/api/decl/draft/count');

export const getReasonTypes = (taxYear: string | number) => axios.get('/api/dict/declReasonTypes', { params: { taxYear } });

export const createDeclSDSubmit = (lang: string, draftId: string | undefined, data: any) => axios.post(`/api/decl/selfdecl/submit/${lang}${draftId ? `?draftId=${draftId}` : ''}`, data);

export const getDeclSDSubmitByID = (id: string) => axios.get(`/api/decl/selfdecl/submit/${id}`);

export const deleteDeclSDSubmitByID = (id: string) => axios.delete(`/api/decl/submitted/${id}`);

export const getDeclSDSubmitList = (params: any) => axios.get('/api/decl/submitted/list', { params });

export const getDeclSDSubmitCount = () => axios.get('/api/decl/submitted/count');

export const getDeclSubmitDownloadPDF = (id: string) => axios.get(`/api/decl/submitted/downloadPdf/${id}`, { responseType: 'blob' });

export const submittedDeclUploadPDF = (id: number, formData: FormData) => axios.put(`/api/decl/submitted/uploadPdf/${id}`, formData);

export const submittedDeclSignWithMID = (id: number, data: { phone: string }) => axios.put(`/api/decl/submitted/signWithMobile/${id}`, {}, { params: data });

export const submittedDeclSignCheckStatus = (id: number) => axios.put(`/api/decl/submitted/checkMobileSignStatus/${id}`);

export const submittedDeclSignStopPending = (id: number) => axios.put(`/api/decl/submitted/stopMobilePendingSign/${id}`);

export const submittedDeclSend = (id: number, data: { saveToDraft: boolean, lang: string }) => axios.put(`/api/decl/submitted/send/${id}`, {}, { params: data });

export const getDeclSDSentList = (params: any) => axios.get('/api/decl/sent/list', { params });

export const getDeclSDSentCount = (params: any) => axios.get('/api/decl/sent/count', { params });

export const getDeclSentDownloadPDF = (dln: string) => axios.get(`/api/decl/sent/downloadPdf/${dln}`, { responseType: 'blob' });

export const getDeclSentDownloadReceipt = (dln: string, data?: { lang: string }) => axios.get(`/api/decl/sent/downloadReceipt/${dln}`, { responseType: 'blob', params: data });

export const getDeclSentStatusTypes = () => axios.get('/api/dict/declSentStatusTypes');

export const previewSDDeclPDF = (arg: { lng: string, payload: any}) => axios.post(`/api/decl/selfdecl/previewPdf/${arg.lng}`, { ...(arg.payload ?? {})}, { responseType: "blob" });

export const checkCosignCertificate = () => axios.get('/api/profile/validCosignCertificate');

export const uploadCosignCertificateForSign = (formData: FormData) => axios.post('/api/profile/uploadCosignCertificate', formData);

export const checkTinIsValid = () => axios.get('/api/profile/tinIsValid');

export const getPaymentsList = (data: any) => axios.get('/api/epayments/paymentList', { params: { ...data } });

export const getPaymentsTotal = (data: any) => axios.get('/api/epayments/paymentCount', { params: { ...data } });

export const getPaymentStatusTypes = () => axios.get('/api/dict/paymentStatusTypes');

export const getPaymentCurrentDetails = () => axios.get('/api/epayments/paymentInfo');

export const updateProfileEmail = (email: string) => axios.post("/api/profile/email", { email });

export const sendPayment = (amount: string | number, lang: string) => axios.post("/api/epayments/payment/", { amount, lang });

export const paymentProcess = (token: string) => axios.put(`/api/epayments/payment/${token}/process`);

export const getPaymentByID = (id: string | number) => axios.get(`/api/epayments/payment/${id}`);

export const downloadPayment = (id: string | number, lang: string) => axios.get(`/api/epayments/payment/${id}/downloadPdf/${lang}`, { responseType: "blob" });

export const createDeclSRDraft = (data: any) => axios.post('/api/decl/returndecl/draft/', data);

export const updateDeclSRDraftByID = (id: string, data: any) => axios.put(`/api/decl/returndecl/draft/${id}`, data);

export const getDeclSRDraftByID = (id: string) => axios.get(`/api/decl/returndecl/draft/${id}`);

export const createDeclSRSubmit = (lang: string, draftId: string | undefined, data: any) => axios.post(`/api/decl/returndecl/submit/${lang}${draftId ? `?draftId=${draftId}` : ''}`, data);

export const getDeclSRSubmitByID = (id: string) => axios.get(`/api/decl/returndecl/submit/${id}`);

export const getTreasuryTransferForSR = (dln: string) => axios.get(`/api/treasuryTransfer/status/${dln}`);
