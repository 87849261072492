import React, { useCallback, useState, useEffect, useRef } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    requestNIDAuthInfo,
    signin,
    resetDataDefault,
    sentForResetPass,
    Exception,
    ExceptionType,
    loginFieldsDefault,
    fieldValidator,
    formValidator,
    translationKeysByErrorCodes,
    dispatchActionsEvent
} from '../../utils';
import { checkAuth } from '../../store/authSlice';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Container } from '../../Layouts/Container';
import { SectionCutter } from '../../components/SectionCutter';
import { Input } from '../../components/Input';
import { Modal } from '../../components/Modal';
import { Captcha } from '../../components/Captcha';
import * as Icons from "../../components/Icons";
import styles from "./styles.module.scss";
import Privacy from '../../components/Terms/Privacy';
import Policy from '../../components/Terms/Policy';
import LoginInfo from '../../components/Terms/LoginInfo';
import { RootState } from '../../store';
import { useKeyDown } from '../../hook';
import { Tooltip } from '../../components/Tooltip';

export const path: string = 'sign-in/:status?';

export default function Login() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const [data, setData] = useState(loginFieldsDefault);
    const [validCaptcha, setValidCaptcha] = useState(false);
    const [resetData, setResetData] = useState(resetDataDefault);
    const [termsOpen, setTermsOpen] = useState<null | "privacy" | "policy">(null);
    const [showPass, setShowPass] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
    const [showAppInfo, setShowAppInfo] = useState(false);
    const utils = useSelector((state: RootState) => state.utils);
    const loginRef = useRef<HTMLElement>(null);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setData((prevState) => {
            const newFieldData = { ...prevState[name as keyof typeof prevState], value };
            const { triggerError } = fieldValidator(newFieldData);
            if (triggerError.type === "none" ) {
                newFieldData.isValid = true;
                newFieldData.error = undefined
            }
            return { ...prevState, [name]: { ...newFieldData }}
        });
    }, []);

    const checkDisableSignInBtn = () => {
        return !privacyPolicy || loading || !validCaptcha || !formValidator(data).isValid;
    };

    const handleBlured = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;
        const { triggerError } = fieldValidator(data[name as keyof typeof data]);
        const checkValue = Object.values(data).some((field) => !!field.value);

        if(triggerError.type !== "none" && checkValue) {
            return setData({ ...data, [name]: { ...data[name as keyof typeof data], error: triggerError.message || triggerError.errorKey, isValid: false }})
        }
        return setData((prev) => {
            const fields = Object.entries(prev);
            const allEmpty = fields.every(([_, field]) => !field.value);
            if (allEmpty) {
                fields.map(([key, field]) => key !== "globalError" ? field.error = undefined : null);
            }
            return { ...prev, [name]: { ...data[name as keyof typeof data], error: undefined, isValid: true }}
        });
    }, [data]);

    const handleResetInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setResetData((prevState) => ({ ...prevState, [name]: { ...prevState[name as keyof typeof prevState], value }}))
    }, []);

    const sentForResetPassword = async () => {
        try {
            setResetData(prev => ({
                ...prev,
                request: { ...prev.request, errorMessage: '' },
                reset_username: { ...prev.reset_username, error: '' },
                reset_email: { ...prev.reset_email, error: '' }
            }));
            if (!resetData.reset_username.value) {
                setResetData(prev => ({ ...prev, reset_username: { ...prev.reset_username, error: t('FIELD_NOT_SET') } }));
            }
            if (!resetData.reset_email.value) {
                setResetData(prev => ({ ...prev, reset_email: { ...prev.reset_email, error: t('FIELD_NOT_SET') } }));
            }
            if (!resetData.reset_username.value || !resetData.reset_email.value) {
                throw new Error('');
            }
            const sent = {
                username: resetData.reset_username.value,
                email: resetData.reset_email.value
            }
            await sentForResetPass(sent)
                .then((res) => {
                    if (res.data.passwordResetCode) {
                        setResetData(prev => ({ ...prev, request: { ...prev.request, isSent: true } }));
                    }
                })
                .catch(err => {
                    throw new Error(translationKeysByErrorCodes(err));
                });
        } catch (er: any) {
            if (er instanceof Error && er.message) {
                setResetData(prev => ({ ...prev, request: { ...prev.request, errorMessage: t(er.message) } }));
            }
        }
    }

    const toggleOpenForgotPasswordDialog = useCallback(() => {
        setOpenForgotPasswordDialog(prev => !prev);
        setResetData(resetDataDefault);
    }, []);

    const LoginWithYesEm = useCallback(() => {
        const { REACT_APP_YES_EM_FRONTEND_SUCCESS: frontendSuccessUrl } = process.env;
        if (frontendSuccessUrl) {
            const frontendPath: string = encodeURI(frontendSuccessUrl + (location.search || ''));
            requestNIDAuthInfo(frontendPath, i18n.language)
                .then(res => {
                    localStorage.setItem('nid_session_id', res.data.sessionId);
                    localStorage.setItem('nid_session_verifier', res.data.sessionVerifier);
                    window.location.href = res.data.authLink;
                })
                .catch(err => console.log('requestNIDAuthInfo', err));
        }
    }, [location.search, i18n.language]);

    const signIn = async () => {
        if(checkDisableSignInBtn()) {
            return;
        }
        try {
            setLoading(true);
            await signin({ tin: data.tin.value as string, password: data.password.value as string, username: data.username.value as string })
            .then((res) => {
                const { token, refreshToken, ...rest } = res.data;
                dispatch(checkAuth({ ...rest, defaultLanguage: rest?.defaultLanguage || 'AM' }));
            })
            .catch(err => {
                const errorKey = err?.response?.data?.message || err?.message || 'FAILURE';
                throw new Exception(JSON.stringify({ [errorKey === 'TAXPAYER_NOT_ACTIVE' || err?.response?.status !== 400 ? 'message' : 'password']: errorKey }), ExceptionType.backend);
            });
        } catch (err) {
            if (err instanceof Exception) {
                const msgs = err.getObjectMessage();
                setData(prev => ({
                    ...prev,
                    globalError: { ...prev.globalError, value: msgs.message },
                    tin: { ...prev.tin, error: msgs.tin },
                    username: { ...prev.username, error: msgs.username },
                    password: { ...prev.password, error: msgs.password }
                }));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleTogglerAppInfo = useCallback(() => {
        setShowAppInfo((prev) => !prev);
    }, []);

    useEffect(() => {
        setData(prev => ({
            ...prev,
            globalError: { ...prev.globalError, value: utils.globalError }
        }));
    }, [utils.globalError]);

    useKeyDown("Enter", () => {
        if(!checkDisableSignInBtn()) {
            signIn();
        }
    }, [!checkDisableSignInBtn()], loginRef)

    return (
        <section className={styles.LoginPageWrapper} ref={loginRef}>
            <div className={styles.SeeGuidelinesSection}>
                <Button 
                    variant='link'
                    className={styles.SeeGuidelinesBtn}
                    onClick={() => dispatchActionsEvent({ type: "guidelines_completing_declarations", payload: "guidelines_completing_declarations" })}
                >
                    <span>
                        <Icons.Play />
                    </span>
                    <span>{t("guidelines_completing_declarations")}</span>
                </Button>
            </div>
            <Container 
                title={t("appName")}
                containerSize='middle' 
                className={styles.LoginPage}
            >
                <div className={styles.LoginContent}>
                    {data.globalError.value && <span className="text-center errorMessage">{t(data.globalError.value)}</span>}
                    <div className={styles.AlternativeLoginGroup}>
                        <Tooltip 
                            title={t("tooltip_esem_btn")}
                            type='info'
                            withoutTooltip={privacyPolicy || loading}
                            position='bottom-right'
                        >
                            <Button 
                                type="button" 
                                onClick={(!privacyPolicy || loading) ? () => null : LoginWithYesEm}
                                disabled={!privacyPolicy || loading}
                                size="middle"
                            >
                                {t("loginWithYesEm")}
                            </Button>
                        </Tooltip>
                        <div className={styles.InfoSection}>
                            <Button variant='link' onClick={handleTogglerAppInfo}>
                                <Icons.InfoOutlineCircle />
                            </Button>
                        </div>
                    </div>
                    <SectionCutter title={t("logInWithPassword")} />
                    <div className={styles.Form}>
                        <Input 
                            placeholder={t("tin")} 
                            inputSize='middle'
                            disabled={loading}
                            value={data.tin.value}
                            errorMessage={data.tin.error ? t(data.tin.error) : ''}
                            name='tin'
                            onChange={handleChange}
                            onBlur={handleBlured}
                        />
                        <Input
                            placeholder={t("username")}
                            inputSize='middle'
                            disabled={loading}
                            value={data.username.value}
                            errorMessage={data.username.error ? t(data.username.error) : ''}
                            name='username'
                            onChange={handleChange}
                            onBlur={handleBlured}
                        />
                        <Input
                            placeholder={t("password")}
                            inputSize='middle'
                            disabled={loading}
                            value={data.password.value}
                            errorMessage={data.password.error ? t(data.password.error) : ''}
                            name='password'
                            onChange={handleChange}
                            type={showPass ? 'text' : 'password'}
                            onBlur={handleBlured}
                            iconPrefix={<span className={styles.ShowPass}>{showPass ? <Icons.EyeOff/> : <Icons.EyeOn />}</span>}
                            onClickPrefix={() => setShowPass(!showPass)}
                        />
                        <Button className={styles.ForgotPassword} variant='link' onClick={toggleOpenForgotPasswordDialog} size='small'>
                            {t('forgotPasswordLink')}
                        </Button>
                        <Checkbox
                            label={
                                <span className={styles.PrivacyPolicy}>
                                    {t("iAgree")}{"\t"}
                                    <Button variant='link' onClick={() => setTermsOpen("privacy")}>{t("userAgreement")}</Button>{"\t"}{t("and")}{"\t"}
                                    <Button variant='link' onClick={() => setTermsOpen("policy")}>{t("policy")}</Button>
                                </span>
                            }
                            checked={privacyPolicy}
                            onChange={() => setPrivacyPolicy(!privacyPolicy)}
                        />
                        {(!!data.password.value || !!data.tin.value || !!data.username.value) && ( 
                            <Captcha 
                                inputSize="middle"
                                check={setValidCaptcha}
                            />
                        )}
                        <Button 
                            disabled={checkDisableSignInBtn()}
                            className={styles.LoginBTN}
                            onClick={signIn}
                            size="middle"
                        >
                            {t('login')}
                        </Button>
                        <Modal
                            title={t(resetData.request.isSent ? 'checkEmail' : 'forgotPassword')}
                            isOpen={openForgotPasswordDialog}
                            onClose={toggleOpenForgotPasswordDialog}
                            size="s-fit-content"
                            footerExtraComponents={(
                                <>
                                    {!resetData.request.isSent && <Button size="s" color="primary" onClick={sentForResetPassword}>{t('reset')}</Button>}
                                </>
                            )}
                            type={resetData.request.isSent ? "info" : undefined}
                            footerExists
                            mode='head-colored'
                            className={styles.ForgotPasswordModal}
                        >
                            <>
                                {resetData.request.isSent ? (
                                    <div className={`text-center ${styles.CheckEmailLink}`}>
                                        <h4>{t('checkEmailLink')}</h4>
                                    </div>
                                ) : (
                                    <div className={styles.ForgotPasswordFormWrapper}>
                                        <Input
                                            placeholder={t("enter_your_username")}
                                            inputSize='small'
                                            name='reset_username'
                                            value={resetData.reset_username.value}
                                            errorMessage={resetData.reset_username.error}
                                            onChange={handleResetInput}
                                        />
                                        <Input
                                            placeholder={t("enter_your_email")}
                                            inputSize='small'
                                            name='reset_email'
                                            value={resetData.reset_email.value}
                                            errorMessage={resetData.reset_email.error}
                                            onChange={handleResetInput}
                                        />
                                        {resetData.request.errorMessage && <div className="text-center errorMessage">{resetData.request.errorMessage}</div>}
                                    </div>
                                )}
                            </>
                        </Modal>
                    </div>
                </div>
            </Container>
            <Modal 
                isOpen={!!termsOpen} 
                onClose={() => setTermsOpen(null)}
                size='fit-content'
                title={(
                    <div className={styles.Head}>
                        <span className={styles.logo}>
                            <Icons.InfoOutlineCircle />
                        </span>
                        <span className={styles.Title}>
                            {t(termsOpen === "privacy" ? "profile_user_agreement" : "profile_policy")}
                        </span>
                    </div>
                )}
                className={styles.TermsModal}
            >
                <>
                    {termsOpen === "privacy" && <Privacy />}
                    {termsOpen === "policy" && <Policy />}                                    
                </>
            </Modal>
            <Modal 
                isOpen={showAppInfo} 
                onClose={handleTogglerAppInfo}
                title={(
                    <div className={styles.Head}>
                        <span className={styles.logo}>
                        <Icons.InfoOutlineCircle />
                        </span>
                        <span className={styles.Title}>
                            {t("app_sign_in_info.html_title")}
                        </span>
                    </div>
                )}
                className={styles.AppInfoModal}
                size='fit-content'
            >
                <LoginInfo />
            </Modal>
        </section>
    );
}
