import React from "react";
import { useTranslation } from "react-i18next";

const privacy = [
    { translationKey: "privacy1" },
    { translationKey: "privacy2" },
    { translationKey: "privacy3" },
    { translationKey: "privacy4" },
    { translationKey: "privacy5" },
]

const Privacy = (props) => {
    const { t } = useTranslation();
    const { languageModule } = props;

    return (
        <div className="terms-main privacy">
            <section className="title">
                <strong>{!!languageModule ? languageModule.section1_title : t(`privacyPage.section1_title`)}</strong>
            </section>
            <p>{!!languageModule ? languageModule.recommends : t(`privacyPage.recommends`)}</p>
            <ul className="list parent-list privacy-list">
                {privacy.map((val, index) => (
                    <li key={val.translationKey}>
                        {`${index + 1}. ${!!languageModule ? languageModule[val.translationKey] : t(`privacyPage.${val.translationKey}`)}`}
                    </li>
                ))}
            </ul>
            <p>{!!languageModule ? languageModule.risk_of_data_loss : t(`privacyPage.risk_of_data_loss`)}</p>
            <p>{!!languageModule ? languageModule.deny_access : t(`privacyPage.deny_access`)}</p>
        </div>
    )
}

export default Privacy