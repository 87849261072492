import { useParams, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { data } from './data';
import styles from './style.module.scss';

const currentSections = ['personal', '1', '1_1', '1_2', '2', '3', '4', '5', '6'];

const DeclarationHelp = () => {
    const params = useParams();
    const location = useLocation();
    let contentData = data[(params.lngCode || 'am') as keyof typeof data];
    const search: any = qs.parse(location.search);
    if (search.section && currentSections.includes(search.section)) {
        contentData.rows = contentData.rows.filter(row => row.section === search.section);
    }
    return (
        <div className={styles.Content}>
            <div className={styles.Wrapper}>
                <div className={styles.Header}>
                    {contentData.title}
                </div>
                <div className={styles.Body}>
                    <ol>
                        {contentData.rows.map(row => {
                            const title = typeof row === 'object' ? row.title : row;
                            return (
                                <li key={title}>
                                    {row.index}. {title}
                                    {typeof row === 'object' && row.rows ? (
                                        <ol>
                                            {row.rows.map(row2 => {
                                                const title2 = typeof row2 === 'object' ? row2.title : row2;
                                                return (
                                                    <li key={title2}>
                                                        {title2}
                                                        {typeof row2 === 'object' && row2.rows ? (
                                                            <ol>
                                                                {row2.rows.map(row3 => {
                                                                    const title3 = row3;
                                                                    return (
                                                                        <li key={title3}>{title3}</li>
                                                                    );
                                                                })}
                                                            </ol>
                                                        ) : null}
                                                    </li>
                                                );
                                            })}
                                        </ol>
                                    ) : null}
                                </li>
                            );
                        })}
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default DeclarationHelp;