import { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from '../../hook';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import logo from "../../assets/images/logo.png";
import { useTranslation } from 'react-i18next';
import { Languages } from '../Languages';
import { classnames, dispatchActionsEvent, helps } from '../../utils';
import * as Icons from "../Icons"
import { Button } from '../Button';
import { logoutAuth } from '../../store/authSlice';
import { MobileMenu } from './MobileMenu';
import { Dropdown } from '../Dropdown';

const helpIconsMapper = {
    guidelines_completing_declarations: <Icons.Play />
};

export const Navbar = memo(() => {
    const { t } = useTranslation();
    const [ openMobileMenu, setOpenMobileMenu] = useState(false);
    const [animationClosed, setAnimationClosed] = useState(false);
    const navigate = useNavigate();
    const utils = useSelector((state: any) => state.utils);
    const auth = useSelector((state: any) => state.auth);
    const { device } = utils;
    const dispatch = useDispatch();
    const location = useLocation();

    const active_navbar = useMemo(() => (!!auth?.username && !location.pathname?.includes("/registration/") && !location.pathname?.includes("/reset-password/")), [location.pathname, auth?.username]);

    const handleClick = () => {
        if(!active_navbar && !auth?.username) {
            navigate(`/sign-in`)
        }
        else {
            navigate(`/dashboard`)
        }
    };

    const handleLogOut = useCallback((ev: any) => {
        ev.preventDefault();
        onClose();
        dispatch(logoutAuth());
    }, []);

    const handleToggleMenu = useCallback(() => {
        setOpenMobileMenu((prev) => !prev);
        setAnimationClosed((prev) => {
            if(!prev) {
                document.body.classList.add('body-scroll-lock');
            }
            return !prev
        });
    }, [])

    const cs = classnames({
        [styles.Header]: true,
        [styles.Authed]: active_navbar
    });

    const onClose = useCallback(() => {
        setAnimationClosed(false);
        document.body.classList.remove('body-scroll-lock');
        setTimeout(() => {
            setOpenMobileMenu(false)
        }, 500)
    }, []);

    const goToProfile = () => {
        navigate("/profile")
    }

    return (
        <header className={cs}>
            <nav>
                <div className={styles.brand} onClick={handleClick}>
                    <div className={styles.logo}>
                        <img src={logo} alt='logo'/>
                    </div>
                    {device.mode !== "mobile" && <h4><span>{t("brandName")}</span></h4>}
                    {device.mode === "mobile" && <h4><span>{t("brandNameMobile")}</span></h4>}
                </div>
                <div className={styles.right}>
                    {(!!active_navbar && device?.mode === "desktop") && (
                        <Dropdown
                            label="help"
                            xPosition='left'
                            list={helps.map((item) => (
                                {
                                    label: (
                                        <div className={styles.HelpItem}>
                                            <span className={styles.HelpItemIcon}>{helpIconsMapper[item.translationKey as keyof typeof helpIconsMapper]}</span>
                                            <span>{t(item.translationKey)}</span>
                                        </div>
                                    ),
                                    key: item.translationKey
                                }
                            ))}
                            onClick={(key: string) => dispatchActionsEvent({ type: key, payload: key })}
                        />
                    )}
                    {(!active_navbar || device?.mode === "desktop") && <Languages />}
                    {device.mode === "desktop" && (
                        <>
                            {active_navbar && (
                                <>
                                    <div className={styles.User} onClick={goToProfile}>
                                        <span className={styles.UserIcon}><Icons.User /></span>
                                        <span>{auth.username}</span>
                                    </div>
                                    <Button
                                        onClick={handleLogOut}
                                        className={styles.logOutBTN}
                                        variant='text'
                                        size='sm'
                                    >
                                        {t("exit")}
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                    {active_navbar && device.mode !== "desktop" && (
                        <>
                            <Button variant='text' className={styles.BurgerBTN} onClick={handleToggleMenu}>
                                <Icons.Burger />
                            </Button>
                            {openMobileMenu && <MobileMenu onClose={onClose} isOpen={animationClosed} logOut={handleLogOut} />}
                        </>
                    )}
                </div>
                <div className={styles.NavBorder} />
            </nav>
        </header>
    );
});