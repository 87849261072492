import React, { useCallback, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { checkTinIsValid } from '../../utils/requests';
import { setTinIsValid, toggleNotifyTinIsInValid } from '../../store/utilsSlice';
import styles from './styles.module.scss';
import { navigations } from '../../utils';
import { path as dashboardPath } from "../../pages/Office";

export const InvalidTinModal: React.FC = memo(({}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const auth: any = useSelector((state: RootState) => state.auth);
    const utils: any = useSelector((state: RootState) => state.utils);

    const closeNotifyTinIsInValid = useCallback(() => dispatch(toggleNotifyTinIsInValid(false)), []);

    useEffect(() => {
        if (auth?.tin) {
            checkTinIsValid()
                .then(res => {
                    if (!res.data.value) {
                        dispatch(setTinIsValid(false));
                        if (
                            !!navigations.filter((nav) => nav.disableMenuForWithoutTin).find((item) => window.location.pathname.includes(item.path)) || 
                            window.location.pathname.includes(dashboardPath)
                        ) {
                            dispatch(toggleNotifyTinIsInValid(true));
                        };
                    }
                })
                .catch(err => console.log('checkTinIsValid: ', err));
        }
    }, [auth?.tin]);

    return (
        <Modal
            title={t('attention')}
            size="s-fit-content"
            isOpen={utils.notifyTinIsInValid}
            onClose={closeNotifyTinIsInValid}
            type="error"
            mode="head-colored"
            className={styles.attention}
        >
            <div className={styles.messages}>
                <div className={styles.title}>
                    {t('invalid_tin_title')}
                </div>
                <div className={styles.description}>
                    {t('invalid_tin_description')}
                </div>
            </div>
        </Modal>
    );
});
