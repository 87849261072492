import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

export const useBasePath = () => {
    const [basePath, setBasePath] = useState("/");
    const params = useParams();
    const location = useLocation();
    const { pathname, search } = location;
    const { lngCode } = params;

    useEffect(() => {
        let path = pathname;
        if (lngCode) {
            path = pathname.replace(`/${lngCode}`, "")
        }
        
        setBasePath(path);
    }, [lngCode, pathname]);

    return { basePath, search }
}