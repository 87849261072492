import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { getReasonTypes } from '../../utils';
import styles from './styles.module.scss';
import { Checkbox } from '../Checkbox';
import Loading from '../Loading';

interface ResidentReasonTypesDialogProps {
    isOpen: boolean;
    close: Function;
    approve: Function;
    year?: string | number | undefined;
    reasonCodes: string[];
    reasonPresent?: boolean;
}

export const ResidentReasonTypesDialog: React.FC<ResidentReasonTypesDialogProps> = (props) => {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState<{ initialized: boolean, data: any[] }>({ initialized: false, data: [] });
    const [selected, setSelected] = useState<string[]>([]);

    const existsNonePresent = !!state.data.find((d: any) => !d.reasonPresent && selected.includes(d.code));
    const existsPresent = !!state.data.find((d: any) => d.reasonPresent && selected.includes(d.code));

    const handleReason = (reason: string) => () => {
        setSelected(prev => {
            if (prev.includes(reason)) return prev.filter(item => item !== reason);
            return [...prev, reason];
        });
    }

    const approveAction = () => {
        props.approve({ selected: year >= 2025 ? [] : selected, existsNonePresent });
    }

    const year = parseInt((props.year || '').toString());

    useEffect(() => {
        if (props.isOpen) {
            if (year >= 2025) {
                let selected: string[] = [];
                switch (props.reasonPresent) {
                    case true:
                        selected = ['reason'];
                        break;
                    case false:
                        selected = ['none-reason'];
                        break;
                }
                setSelected(selected);
            } else {
                setSelected(props.reasonCodes || []);
            }
        }
    }, [props.reasonCodes, props.isOpen]);

    useEffect(() => {
        if (props.isOpen && props.year) {
            if (year >= 2025) {
                setState({
                    initialized: true,
                    data: [
                        { reasonPresent: true, code: 'reason', tr: 'residentReasonTypes_tr_5' },
                        { reasonPresent: false, code: 'none-reason', tr: 'residentReasonTypes_tr_6' }
                    ]
                });
            } else {
                getReasonTypes(props.year)
                    .then((res: any) => {
                        setState({ initialized: true, data: res.data });
                    })
                    .catch(err => console.log('getReasonTypes >>>', err));
            }
        }
        return () => {
            setState({ initialized: false, data: [] });
        }
    }, [props.isOpen, props.year]);

    if (!state.initialized) {
        if (props.isOpen) {
            return <Loading />;
        }
        return null;
    }

    const list1 = state.data.filter((item: any) => item.reasonPresent);
    const list2 = state.data.filter((item: any) => !item.reasonPresent);

    let nameKey = 'name';
    switch (i18n.language) {
        case 'en':
            nameKey = 'nameEn';
            break;
        case 'ru':
            nameKey = 'nameRu';
            break;
    }

    return (
        <Modal
            title={t('residentReasonTypes_tr_1')}
            className={styles.ResidentReasonTypesDialog}
            isOpen={props.isOpen}
            onClose={props.close}
            size="fit-content"
            mode="head-colored"
            footerExtraComponents={(
                <Button
                    size="s"
                    color="primary"
                    disabled={selected.length === 0}
                    onClick={selected.length === 0 ? () => {} : approveAction}
                >
                    {t('residentReasonTypes_tr_4')}
                </Button>
            )}
            footerExists
        >
            <div className={styles.Content}>
                {year >= 2025 ? (
                    <div className={styles.Reasons}>
                        {state.data.map(item => (
                            <div key={item.code}>
                                <Checkbox
                                    checked={selected.includes(item.code)}
                                    disabled={(existsNonePresent && item.code === 'reason') || (existsPresent && item.code === 'none-reason')}
                                    id={`residentReasonType-${item.code}`}
                                    onChange={((existsNonePresent && item.code === 'reason') || (existsPresent && item.code === 'none-reason')) ? () => {} : handleReason(item.code)}
                                    boxSize='x-small'
                                    label={t(item.tr)}
                                    verticalAlign='top'
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <>
                        <div>
                            <div className={styles.Caption}>{t('residentReasonTypes_tr_2')}</div>
                            <div className={styles.Reasons}>
                                {list1.map(item => (
                                    <div key={item.code}>
                                        <Checkbox
                                            checked={selected.includes(item.code)}
                                            disabled={existsNonePresent}
                                            id={`residentReasonType-${item.code}`}
                                            onChange={existsNonePresent ? () => {} : handleReason(item.code)}
                                            boxSize='x-small'
                                            label={item[nameKey]}
                                            verticalAlign='top'
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className={styles.Caption}>{t('residentReasonTypes_tr_3')}</div>
                            <div className={styles.Reasons}>
                                {list2.map(item => (
                                    <div key={item.code}>
                                        <Checkbox
                                            checked={selected.includes(item.code)}
                                            disabled={existsPresent}
                                            id={`residentReasonType-${item.code}`}
                                            onChange={existsPresent ? () => {} : handleReason(item.code)}
                                            boxSize='x-small'
                                            label={item[nameKey]}
                                            verticalAlign='top'
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};
