import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { DatePicker } from "../../../DatePicker";
import { Select } from "../../../Select";
import { Input } from "../../../Input";
import { formatNumberWithCommas } from "../../../../utils";

export type FormDataTypes = { [key: string]: Field<DataType> | FormGroup };

interface FormProps {
    data: FormDataTypes;
    onChange?: CallableFunction;
    prefix?: ( fieldKey: string, fieldType: DataType | "default" ) => any;
    children?: (arg: { key: string, formGroupKey?: string}) => React.ReactNode | null;
    onBlur?: CallableFunction;
    labelRewriter?: (key: string) => string;
};

export const Form: React.FC<FormProps> = (props) => {
    const { data, onChange, prefix, children, onBlur, labelRewriter } = props;
    const { t } = useTranslation();

    const handleChange = (key: string, withComma: boolean, formGroupKey?: string, type?: "selectOnInputChange") => (event: React.ChangeEvent<HTMLInputElement>, callback?: CallableFunction) => {
        if (type === "selectOnInputChange") {
            if (!event) return
            return onChange?.({ key, value: event, formGroupKey, type: "onInputChange" });
        }
        const { value } = event.target;
        if(withComma) {
            if(!isNaN(value.replaceAll(",", "") as any) && value.replaceAll(",", "").length <= 13) {
                onChange?.({ key, value: value === "" ? undefined : +value.replaceAll(",", ""), formGroupKey });
                callback?.();
            }
        }
        else {
            onChange?.({ key, value, formGroupKey })
        }
    }

    const handleBlur = (key: string, formGroupKey?: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        onBlur?.({ key, formGroupKey });
    };

    return (
        <div className={styles.Form}>
            {Object.entries(data).map(([key, val]) => {
                if((val as Field<any>).visibility !== "hidden") {
                    switch(val.type) {
                        case "form_group":
                            return (
                                <div className={`${styles.FormGroup} form-group-wrapper ${key}`} key={key}>
                                    {(val as FormGroup).title && <div className={styles.Title}>{labelRewriter?.((val as FormGroup).title as string) ?? t((val as FormGroup).title as string)}</div>}
                                    <div className={styles.Fields}>
                                        {Object.entries((val as FormGroup).fields).map(([fieldKey, fieldFormGroup]) => {
                                            if((fieldFormGroup as Field<any>).visibility !== "hidden") {
                                                switch(fieldFormGroup.type) {
                                                    case "date":
                                                        return (
                                                            <React.Fragment key={`${key}_${fieldKey}`}>
                                                                {children?.({ formGroupKey: key, key: fieldKey })}
                                                                <DatePicker
                                                                    label={t(fieldKey)} 
                                                                    mode={(fieldFormGroup as Field<Date>).mode as "yyyy" ?? "dd/MM/yyyy"}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    default:
                                                        return (
                                                            <React.Fragment key={`${key}_${fieldKey}`}>
                                                                {children?.({ formGroupKey: key, key: fieldKey })}
                                                                <Input 
                                                                    label={fieldFormGroup.withoutLabel ?? labelRewriter?.((fieldFormGroup as Field<any>).label as string ?? key) ?? t((fieldFormGroup as Field<any>).label as string ?? key)} 
                                                                    disabled={(fieldFormGroup as Field<any>).validation?.disabled} 
                                                                    withMobileCode={(fieldFormGroup as Field<any>).type === "phone"} 
                                                                    value={(fieldFormGroup as Field<any>).value && (fieldFormGroup as Field<any>).withComma ? formatNumberWithCommas((fieldFormGroup as Field<any>).value) : (fieldFormGroup as Field<any>).value ?? ""}
                                                                    name={(fieldFormGroup as Field<any>).key}
                                                                    onChange={handleChange(fieldKey, !!(fieldFormGroup as Field<any>).withComma, key)}
                                                                    errorMessage={t(fieldFormGroup.error as string)}
                                                                    warningMessage={t(fieldFormGroup.warning as string)}
                                                                    iconPrefix={(fieldFormGroup as Field<any>).prefix ?? prefix?.((fieldFormGroup as Field<any>).key, "default")}
                                                                    prefixProps={{ className: "input-prefix" }}
                                                                    onBlur={handleBlur(fieldKey, key)}
                                                                />
                                                            </React.Fragment>
                                                    )
                                                }
                                            }
                                        })}
                                    </div>
                                </div>
                            );
                        case "date":
                            return (
                                <React.Fragment key={key}>
                                    {children?.({ key })}
                                    <DatePicker 
                                        label={labelRewriter?.((val as Field<any>).label as string ?? key) ?? t((val as Field<any>).label as string ?? key)} 
                                        mode={(val as Field<Date>).mode as "yyyy" ?? "dd/MM/yyyy"} 
                                        value={(val as Field<Date>).value}
                                        onChange={(data) => onChange?.({ key, value: data })}
                                        minDate={(val as Field<Date>).validation?.min as Date}
                                        maxDate={(val as Field<Date>).validation?.max as Date}
                                        config={{ start: { disabled: (val as Field<Option>).validation?.disabled } }}
                                        errorMessage={t((val as Field<any>).error as string)}
                                    />
                                </React.Fragment>
                            )
                        case "select":
                            return (
                                <React.Fragment key={key}>
                                    {children?.({ key })}
                                    <Select
                                        options={(val as Field<Option>).options ?? []}
                                        value={(val as Field<Option>).value}
                                        onChange={(option: any) => handleChange(key, !!(val as Field<any>).withComma)({ target: { value: option }} as React.ChangeEvent<HTMLInputElement>)}
                                        onInputChange={handleChange(key, !!(val as Field<any>).withComma, undefined, "selectOnInputChange")}
                                        label={labelRewriter?.((val as Field<any>).label as string ?? key) ?? t((val as Field<any>).label as string ?? key)} 
                                        isDisabled={(val as Field<Option>).validation?.disabled}
                                        errorMessage={t((val as Field<any>).error as string)}
                                    />
                                </React.Fragment>
                            )
                        default:
                            return (
                                <React.Fragment key={key}>
                                    {children?.({ key })}
                                    <Input 
                                        label={labelRewriter?.((val as Field<any>).label as string ?? key) ?? t((val as Field<any>).label as string ?? key)} 
                                        disabled={(val as Field<any>).validation?.disabled} 
                                        withMobileCode={(val as Field<any>).type === "phone"} 
                                        value={(val as Field<any>).value && (val as Field<any>).withComma ? formatNumberWithCommas((val as Field<any>).value) : (val as Field<any>).value ?? ""}
                                        name={(val as Field<any>).key}
                                        onChange={handleChange(key, !!(val as Field<any>).withComma)}
                                        errorMessage={t((val as Field<any>).error as string)}
                                        warningMessage={t((val as Field<any>).warning as string)}
                                        iconPrefix={(val as Field<any>).prefix ?? prefix?.((val as Field<any>).key, "default")}
                                        prefixProps={{ className: "input-prefix" }}
                                        onBlur={handleBlur(key)}
                                    />
                                </React.Fragment>
                            )
                    };
                }
            })}
        </div>
    )
}