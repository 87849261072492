import { useTranslation } from 'react-i18next';
import { useNavigate  } from '../../hook';
import notFound from "../../assets/images/404.svg";
import { Button } from '../../components/Button';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import * as BRIDGE_ACTIONS from "../../utils/bridge_actions";

export default function PageNotFound() {
    const { t } = useTranslation();
    const auth: any = useSelector((state: RootState) => state.auth);
    const utils = useSelector((state: RootState) => state.utils);
    const navigate = useNavigate();

    const goBack = (ev: any) => {
        ev.preventDefault();
        navigate(-1);
    }

    const goLandingPage = () => {
        navigate(auth?.username ? `/dashboard` : "/sign-in");
    };

    const goLandingPageWebView = () => {
        BRIDGE_ACTIONS.onAction(BRIDGE_ACTIONS.GO_TO_MAIN_PAGE);
    };

    return (
        <div className={styles.page404}>
            <div className={styles.NotFoundImg}>
                <img src={notFound} alt='page-not-found'/>
            </div>
            <h1>404 {t('page404')}</h1>
            <div className={styles.BTNGroup}>
                {(!!auth?.username && !utils.mobileView) && <Button variant='outlined' size='sm' onClick={goBack}>&lt;&lt; {t('back')}</Button>}
                <Button 
                    size='sm' 
                    onClick={!utils.mobileView ? goLandingPage : goLandingPageWebView}
                >
                    {!!utils.mobileView ? t("main_page") : !!auth.username ? t("main_page") : t("login")}
                </Button>
            </div>
        </div>
    );
}
