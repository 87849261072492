import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Modal } from '../Modal';
import styles from './styles.module.scss';

interface NotificationDialogProps {
    isOpen: boolean;
    close: Function;
    messageTranslationKey?: React.ReactNode | string;
    head?: string;
    type?: "error" | "warning" | "info" | "success";
    size?: 'sm' | 'md' | 'lg' | 'full-screen' | 'fit-content' | "s-fit-content",
    withoutCloseButton?: boolean;
}

export const NotificationDialog: React.FC<NotificationDialogProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Modal
            className={styles.NotificationDialog}
            isOpen={props.isOpen}
            closeMessage={props.withoutCloseButton ? '' : t('close')}
            onClose={props.close}
            size={props.size || 's-fit-content'}
            footerExists
            mode='head-colored'
            title={props.head}
            type={props.type}
        >
            <div className={styles.Message}>
                {typeof props.messageTranslationKey === 'string' ? t(props.messageTranslationKey) : props.messageTranslationKey}
            </div>
        </Modal>
    );
};
