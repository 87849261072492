import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Form } from './Form';
import { Button } from '../../../Button';
import { generateField, generateFormGroup, getRootDataStore } from '../utils';
import { Question } from './Question';
import { MobileIncomeTaxContext } from '../mobile_income_tax.context';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { section5Rows } from '../../../../utils';

export const Compensation: React.FC<{ onSubmit: CallableFunction, next: CallableFunction }> = (props) => {
    const { onSubmit, next } = props;
    const auth: any = useSelector((state: RootState) => state.auth);
    const root = getRootDataStore().root;
    const [formData, setFormData] = useState<IDataTypes<FormGroup>>({
        employerPaidTax: generateFormGroup("employerPaidTax", "sec_5_tr_1", {
            employerPaidTax: generateField<number>(
                "employerPaidTax", 
                root?.section5?.employerPaidTax, 
                { type: "string", isValid: false, validation: { isRequired: true, disabled: true }, label: `form1_section5_income_title`, withComma: true }
            ),
        }),
        hypotecReturnAmount: generateFormGroup("hypotecReturnAmount", "sec_5_tr_2", {
            hypotecReturnAmount: generateField<number>("hypotecReturnAmount", root?.section5?.hypotecReturnAmount, { type: "string", isValid: false, validation: { isRequired: false, disabled: true }, label: `form1_section5_income_title`, withComma: true }),
        }),
        tuitionFeeReturnAmount: generateFormGroup("tuitionFeeReturnAmount", "sec_5_tr_3", {
            tuitionFeeReturnAmount: generateField<number>("tuitionFeeReturnAmount", root?.section5?.tuitionFeeReturnAmount, { type: "string", isValid: false, validation: { isRequired: false, disabled: true }, label: `form1_section5_income_title`, withComma: true }),
        }),
        investmentReturnAmount: generateFormGroup("investmentReturnAmount", "sec_5_tr_4", {
            investmentReturnAmount: generateField<number>("investmentReturnAmount", root?.section5?.investmentReturnAmount, { type: "string", isValid: false, validation: { isRequired: false, disabled: true  }, label: `form1_section5_income_title`, withComma: true }),
        }),
        employerReduceAmount: generateFormGroup("employerReduceAmount", "sec_5_tr_5", {
            employerReduceAmount: generateField<number>("employerReduceAmount", root?.section5?.employerReduceAmount, { type: "string", isValid: false, validation: { isRequired: false, disabled: true }, label: `form1_section5_income_title`, withComma: true }),
        }),
        yearlyCalculatedDifference: generateFormGroup("yearlyCalculatedDifference", "sec_5_tr_6", {
            yearlyCalculatedDifference: generateField<number>("yearlyCalculatedDifference", root?.section5?.yearlyCalculatedDifference, { type: "string", isValid: false, validation: { isRequired: false, disabled: true }, label: `form1_section5_income_title`, withComma: true }),
        }),
        invoiceReturnAmount: generateFormGroup("invoiceReturnAmount", "sec_5_tr_7", {
            invoiceReturnAmount: generateField<number>("invoiceReturnAmount", root?.section5?.invoiceReturnAmount, { type: "string", isValid: false, validation: { isRequired: false, disabled: true }, label: `form1_section5_income_title`, withComma: true }),
        }),
        taxToPaid: generateFormGroup("taxToPaid", "sec_5_tr_8", {
            taxToPaid: generateField<number>("taxToPaid", root?.section5?.taxToPaid, { type: "string", isValid: false, validation: { isRequired: false, disabled: true }, label: `form1_section5_income_title`, withComma: true }),
        }),
        taxToReturn: generateFormGroup("taxToReturn", "sec_5_tr_9", {
            taxToReturn: generateField<number>("taxToReturn", root?.section5?.taxToReturn, { type: "string", isValid: false, validation: { isRequired: false, disabled: true }, label: `form1_section5_income_title`, withComma: true }),
        })
    });
    const formDataRef = useRef(formData);
    const { t } = useTranslation();
    const context = useContext(MobileIncomeTaxContext);


    const handleSubmit = () => {
        const passData = Object.values(formDataRef.current).reduce((acc, formGroup) => {
            Object.values(formGroup.fields).map((field) => {
                acc[field.key] = field.value as number
            })
            return acc
        }, {} as IDataTypes<number>);
        onSubmit?.(passData);
        context?.setOutOfSteps?.(null);
    };

    useEffect(() => {
        formDataRef.current = formData;
    }, [JSON.stringify(formData)]);

    useEffect(() => {
        const { section5 } = getRootDataStore().root;
        if(section5) {
            setFormData((prev) => {
                Object.entries(section5).map(([key, value]: [string, any]) => {
                    if(prev[key] && prev[key].fields[key]) {
                        prev[key].fields[key].value = !isNaN(value) ? value ?? 0 : undefined
                    }
                })
                return { ...prev }
            })
        }
    }, [JSON.stringify(getRootDataStore())])

    useEffect(() => {
        if(!getRootDataStore()?.compensation?.completed) {
            context?.setOutOfSteps?.((prev: any) => {
                if(!prev) {
                    prev = {}
                }
                prev.children = <Question question={"have_income_tax_refundable"} yes={() => context?.setOutOfSteps?.(null)} no={handleSubmit} />
                return { ...prev }
            })
        }

        return () => {
            if (window.location.pathname.includes("declaration/type/sd")) {
                handleSubmit();
            }
        };
    }, []);

    const handleLabelRewriter = (trKey: string) => {
        const findedRow = section5Rows.find((item) => item.translation_key === trKey);
        return findedRow ? `${t(trKey)} [${findedRow.number}]` : t(trKey);
    }

    return (
        <div className={styles.Compensation}>
            <Form 
                data={formData}
                prefix={() => <span>{t("dram")}</span>}
                labelRewriter={handleLabelRewriter}
            />
            <Button className={styles.NextBtn} onClick={() => next()}>{t("next")}</Button>
        </div>
    );
};