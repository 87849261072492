import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigation } from '../../components/Navigation';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { TinAttentionDialog } from '../../components/TinAttentionDialog';
import axios from '../../api/axios';
import { useChatBot } from '../../hook';

interface IDashboardLayout {
    children: React.ReactElement,
    showNavigation?: boolean;
    className?: string
}

export const DashboardLayout: React.FC<IDashboardLayout> = (props) => {
    const { children, showNavigation = true, className } = props;
    const utils = useSelector((state: RootState) => state.utils);
    const auth: any = useSelector((state: RootState) => state.auth);
    const location = useLocation();

    useEffect(() => {
        axios.get('/appBuild.json')
            .then(res => {
                if (res.data.buildTime) {
                    let existsBuildTime: string | number = localStorage.getItem('buildTime') || '';
                    existsBuildTime = parseInt(existsBuildTime) || 0;
                    if (existsBuildTime !== res.data.buildTime) {
                        localStorage.setItem('buildTime', res.data.buildTime);
                        window.location.reload();
                    }
                }
            })
            .catch(err => console.log('appBuild.json', err));
    }, [location.pathname]);

    useChatBot();

    return (
        <section className={className}>
            {showNavigation && utils.device?.mode === 'desktop' && (
                <Navigation />
            )}
            {children}
            {auth?.username && <TinAttentionDialog />}
        </section>
    )
};