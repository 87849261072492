import React from "react";
import { usePagination } from "../../hook";
import { classnames } from "../../utils";
import styles from "./styles.module.scss"
import { Button } from "../Button";
import * as Icons from "../Icons"

export const Pagination: React.FC<{pageSize: number; currentPage: number; total: number; siblingCount?: number; onChange?: CallableFunction }> = (props) => {
    const { pageSize, currentPage, total, siblingCount = 1, onChange} = props;
    const totalPageCount = pageSize > 0 ? Math.ceil(total / pageSize) : 1;
    const { paginationRange, DOTS } = usePagination({ pageSize, currentPage, totalPageCount, siblingCount });
    if (paginationRange?.length === 0) return null;
    const lastPage = parseInt(paginationRange?.[(paginationRange?.length || 0) - 1] as string) || 1;

    if(total <= pageSize) {
        return null
    }

    return (
        <ul className={styles.Pagination}>
            <li className={styles.BtnsGroup}>
                <ul>
                    <li onClick={() => onChange?.(1)}>
                        <Button variant="link" className={styles.StartPage}><Icons.DoubleArrowRight /></Button>
                    </li>
                    <li onClick={() => currentPage - 1 > 0 ? onChange?.(currentPage - 1) : null}>
                        <Button variant="link" className={styles.PrevPage}><Icons.ArrowDown/></Button>
                    </li>
                </ul>
            </li>
            <li className={styles.Pages}>
                <ul className={styles.List}>
                    {paginationRange?.map((pagNumber: any, index: number) => (
                        pagNumber == DOTS 
                            ? <li key={index}>&#8230;</li>
                            : (
                                <li 
                                    key={`${pagNumber}/${index}`} 
                                    className={classnames({ [styles.Item]: true, [styles.Selected]: pagNumber === currentPage })} 
                                    onClick={() => onChange?.(pagNumber)}
                                >      
                                    {pagNumber}
                                </li>
                            )
                    ))}
                </ul>
            </li>
            <li className={styles.BtnsGroup}>
                <ul>
                    <li onClick={() => currentPage + 1 <= lastPage ? onChange?.(currentPage + 1) : null}>
                        <Button variant="link" className={styles.NextPage}><Icons.ArrowDown/></Button>
                    </li>
                    <li onClick={() => onChange?.(lastPage)}>
                        <Button variant="link" className={styles.EndPage}><Icons.DoubleArrowRight /></Button>
                    </li>
                </ul>
            </li>
        </ul>
    )
};
