import axios from "axios";
import { store } from "../store";
import { checkAuth } from "../store/authSlice";
import * as BridgeActions from "../utils/bridge_actions";
import { getSearchParams } from "../utils";
import { mobile_decl_storage_keys } from "../components/MobileView/components";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});
api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if ((error.response?.status === 401) && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const refreshToken = localStorage.getItem('refreshToken');
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/refreshToken`, { headers: { "Refresh-Token": refreshToken }});
          const { token } = response.data;
          localStorage.setItem('token', token);
          originalRequest.headers.Authorization = token;
          return axios(originalRequest);
        } 
        catch (err) {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            setTimeout(() => mobile_decl_storage_keys.map((key) => sessionStorage.removeItem(key)), 100);
            if(!!getSearchParams().mobileView) {
              BridgeActions.onAction(BridgeActions.TOKEN_ERROR);
            }
            store.dispatch(checkAuth(null));
            return Promise.reject(error)
        }
      }
  
      return Promise.reject(error);
    }
);

export default api;
