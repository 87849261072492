import React, { useMemo } from 'react';
import { Button } from '../Button';
import * as Icons from "../Icons";
import { classnames } from '../../utils';
import styles from './styles.module.scss';

interface Step {
    id: number;
    translationKey?: string;
}

interface StepperProps {
    steps: Step[];
    changeStep?: CallableFunction;
    maxLimit?: number;
    step: number;
    className?: string;
    stepsError?: (string | number)[];
    unCompletedList?: (string | number)[];
    highlightUncompleted?: boolean
}

export const Stepper: React.FC<StepperProps> = (props) => {
    const { steps, changeStep, step, className, stepsError, unCompletedList, highlightUncompleted } = props;

    const progressPercent = useMemo(() => (
        step * 100 / steps.length 
    ), [step, steps]);
    
    const cs = classnames({
        [styles.Stepper]: true,
        [className as string]: !!className
    });

    return (
        <div className={cs}>
            <div className={styles.StepsHead}>
                <div className={styles.Prev} onClick={() => changeStep?.({ value: step <= 1 ? 1 : step - 1, type: "previous"})}>
                    <Button variant='link' disabled={step <= 1}>
                        <Icons.ArrowDown />
                    </Button>
                </div>
                <div className={styles.Steps} style={{ "--contentWidth": `${progressPercent}%`} as {[key: string]: string | number }}>
                    {steps.map((st: Step, index: number) => (
                        <div 
                            className={classnames({
                                [styles.Step]: true,
                                [styles.Completed]: highlightUncompleted ? !unCompletedList?.includes(st.id) : (index < step && !unCompletedList?.includes(st.id)),
                                [styles.Error]: stepsError?.includes?.(st.id) ?? false,
                                [styles.CurrentStep]: st.id === step
                            })}
                            key={st.id}
                            onClick={() => changeStep?.({ value: index + 1, type: "circle" })}
                        >
                            {index + 1}
                        </div>
                    ))}
                </div>
                <div className={styles.Next} onClick={() => changeStep?.({ value: step >= steps.length ? steps.length : step + 1, type: "next" })}>
                    <Button variant='link' disabled={step >= steps.length}>
                        <Icons.ArrowDown />
                    </Button>
                </div>
            </div> 
        </div>
    );
};