import { useNavigate } from "react-router-dom";
import { useBasePath } from "./useBasePath";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { ILanguage, languages } from "../utils";

export const useTranslate = () => {
    const { basePath, search } = useBasePath();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    const defaultLng = useMemo(() => (
        languages.find((lng) => lng.isDefault)
    ), [languages]);

    const currentLang = useMemo(() => languages.find((lng: ILanguage) => lng.code === i18n.language), [i18n.language]);

    const changeLanguage = useCallback((lng: any) => {
        const newURL = lng.toLowerCase() == defaultLng?.code ? `${basePath}${search}` : `/${lng.toLowerCase()}${basePath}${search}`;
        navigate(newURL);
    }, [basePath, navigate]);

    return { changeLanguage, i18n, languages, defaultLng, currentLang, t };
}