import React from 'react';
import styles from './styles.module.scss';
import { Button } from '../../../Button';
import { useTranslation } from 'react-i18next';
        
export const Question: React.FC<{ question: string, yes?: CallableFunction, no?: CallableFunction }> = (props) => {
    const { question, yes, no } = props;
    const { t } = useTranslation();
    
    return (
        <div className={styles.Question}>
            <div className={styles.QuestionText}>{t(question)}</div>
            <div className={styles.QuestionBtnSection}>
                <Button onClick={() => yes?.()}>{t("Yes")}</Button>
                <Button variant='outlined' onClick={() => no?.()}>{t("No")}</Button>
            </div>
        </div>
    );
};