export const GO_TO_MAIN_PAGE = { type: "GO_TO_MAIN_PAGE" };
export const TOKEN_ERROR = { type: "TOKEN_ERROR" };
export const GO_TO_PROFILE_PAGE = { type: "GO_TO_PROFILE_PAGE" };
export const TOGGLE_CHAT_OPENING = { type: "TOGGLE_CHAT_OPENING" };
export const DOWNOALD_PDF = { type: "DOWNOALD_PDF" };
export const VIEW_PDF = { type: "VIEW_PDF" };

export const onAction = (arg: BridgeActionArgument) => {
    return window.Bridge?.onAction?.(JSON.stringify(arg));
};

export const onCheckTokens = () => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("token");
    const result =  window.Bridge?.onCheckTokens?.();
    return new Promise((resolve, reject) => {
        if (!result) {
            reject(new Error("unauthenticated"));
        }
        else if(result instanceof Promise || result.then || result.catch) {
            result
                .then((res: string) => {
                    return resolve(res)
                })
                .catch((err: Error) => reject(err));
        }
        else {
            resolve(result);
        };
    });
};