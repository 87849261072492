import React, {useMemo, useState, useEffect, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Container } from '../../Layouts/Container';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import { Wrapper } from '../../Layouts/Wrapper';
import * as Icons from "../../components/Icons";
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Table, TableHead } from '../../components/Table';
import { RootState } from '../../store';
import { useNavigate } from '../../hook';
import {
    getDeclSDSentList,
    getDeclSDSentCount,
    getDeclSentDownloadPDF,
    getDeclSentDownloadReceipt,
    getDeclSentStatusTypes,
    getTreasuryTransferForSR,
    classnames,
    formatDate,
    OS,
    getSearchParams
} from '../../utils';
import { changePage as changePageAction, initializeRows, initializeTotal } from '../../store/declarationSubmittedSlice';
import { Pagination } from "../../components/Pagination";
import { FilterBox } from '../../components/FilterBox';
import Loading from "../../components/Loading";
import { Empty } from '../../components/Empty';
import { Tooltip } from '../../components/Tooltip';
import { DeclarationSubmittedCard } from '../../components/Card';
import type { DeclarationSubmittedCardProps } from '../../components/Card';
import * as BridgeActions from "../../utils/bridge_actions";
import { ProcessingStatusDialog } from '../../components/ProcessingStatusDialog';

export const path: string = 'declaration/submitted';

const renderDeclarationActions = (rowData: any, args: any) => {
    return (
        <div className={styles.ActionGroup}>
            <Tooltip
                title={args.title}
                type='info'
                display='inline'
                followTheCursor
            >
                <span onClick={() => args.downloadDeclaration(rowData)} className='action-item'>
                    <Icons.PDF />
                </span>
            </Tooltip>
        </div>
    );
};

const renderReceivedActions = (rowData: any, args: any) => {
    return (
        <div className={styles.ActionGroup}>
            <Tooltip 
                title={args.title}
                type='info'
                display='inline'
                followTheCursor
            >
                <span onClick={() => args.downloadReceived(rowData)} className='action-item'>
                    <Icons.PDF />
                </span>
            </Tooltip>
        </div>
    );
}

const DeclarationSubmittedPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstOpen, setFirstOpen] = useState<boolean>(true);
    const [processingStatusDialog, setProcessingStatusDialog] = useState<{ isOpen: boolean, data: any }>({ isOpen: false, data: null });
    const [statusTypes, setStatusTypes] = useState<any[]>([]);
    const declarationSubmitted = useSelector((state: RootState) => state.declarationSubmitted);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { language } = i18n;
    const utils = useSelector((state: any) => state.utils);
    const [filterBoxOpen, setFilterBoxOpen] = useState(false);
    const auth: any = useSelector((state: any) => state.auth);
    const navigate = useNavigate();

    const handleSelectChange = useCallback(
        (data: { label: string, value: string }, opt: { action: string, name: string }) =>
            setFilterFields(prev => ({ ...prev, [opt.name]: { ...prev[opt.name as keyof typeof prev], value: data } })),
        []
    );

    const handleReceiptDateRange = useCallback((value: any) => setFilterFields(prev => ({ ...prev, receiptDate: { ...prev.receiptDate, value } })), []);

    const onPeriodYearChange = (year: any) => {
        setFilterFields(prev => ({ ...prev, taxYear: { ...prev.taxYear, year, month: year ? prev.taxYear.month : undefined } }));
    }

    const onPeriodMonthChange = (month: any) => {
        setFilterFields(prev => ({ ...prev, taxYear: { ...prev.taxYear, month } }));
    }

    const [reset, setReset] = useState(false);
    const [filterFields, setFilterFields] = useState({
        status: {
            value: null as any,
            type: 'select',
            onChange: handleSelectChange,
            onInputChange: () => {},
            options: [] as any,
            isClearable: true,
            placeholder: 'any'
        },
        receiptDate: {
            label: 'deliveryDate',
            value: {
                startDate: undefined as (Date | undefined),
                endDate: undefined as (Date | undefined)
            },
            type: 'dateRange',
            onChange: handleReceiptDateRange
        },
        taxYear: {
            year: undefined as (undefined | Date),
            month: undefined as (undefined | { label: string, value: string }),
            min: new Date(2023, 0),
            max: new Date(),
            type: 'yearMonth',
            onYearChange: onPeriodYearChange,
            onMonthChange: onPeriodMonthChange,
            withoutMonth: true,
            isClearable: true,
            placeholder: 'month',
            label: 'deliveryPeriod'
        },
    });

    const downloadDeclaration = (data: any) => {
        const { mobileView, os} = getSearchParams();
        if(!!mobileView && os === OS.android) {
            return BridgeActions.onAction({...BridgeActions.DOWNOALD_PDF, payload: { apiPath: `/api/decl/sent/downloadPdf/${data.dln}` }});
        }
        getDeclSentDownloadPDF(data.dln)
            .then(res => {
                const fileName = res.headers['content-disposition'].replace('attachment; filename=', '').replace(/"/g, '');
                const url = window.URL.createObjectURL(res.data);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.log('downloadDocumentForSign >>>', err);
                toast.error(t(err.response?.data?.message || err.message), { position: 'top-right', theme: 'colored' });
            });
    }

    const downloadReceived = (data: any) => {
        const { mobileView, os} = getSearchParams();
        if(!!mobileView && os === OS.android) {
            return BridgeActions.onAction({...BridgeActions.DOWNOALD_PDF, payload: { apiPath: `/api/decl/sent/downloadReceipt/${data.dln}?lang=${i18n.language}` }});
        }
        getDeclSentDownloadReceipt(data.dln, { lang: i18n.language })
            .then(res => {
                const fileName = res.headers['content-disposition'].replace('attachment; filename=', '').replace(/"/g, '');
                const url = window.URL.createObjectURL(res.data);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.log('downloadDocumentForSign >>>', err);
                toast.error(t(err.response?.data?.message || err.message), { position: 'top-right', theme: 'colored' });
            });
    }

    const onSearch = async (page?: number) => {
        setIsLoading(true);
        try {
            const filter = {
                status: filterFields.status.value ? filterFields.status.value.value : undefined,
                receiptDateStart: filterFields.receiptDate.value.startDate ? formatDate('YYYY-MM-DD', filterFields.receiptDate.value.startDate) : undefined,
                receiptDateEnd: filterFields.receiptDate.value.endDate ? formatDate('YYYY-MM-DD', filterFields.receiptDate.value.endDate) : undefined,
                taxYear: filterFields.taxYear.year ? filterFields.taxYear.year.getFullYear() : undefined
            }
            const filterForList = {
                ...filter,
                currentPage: page || declarationSubmitted.currentPage,
                pageSize: declarationSubmitted.pageSize,
            }
            const [list, total] = await Promise.all([getDeclSDSentList(filterForList), getDeclSDSentCount(filter)]);
            dispatch(initializeRows(list.data));
            dispatch(initializeTotal(total.data.value || 0));
            setFirstOpen(false);
        } catch (err: any) {
            console.log('onSearch >>>', err);
            toast.error(t(err.response?.data?.message || err.message), { position: 'top-right', theme: 'colored' });
        } finally {
            setIsLoading(false);
        }
    }

    const onNewSearch = () => {
        setFilterFields(prev => ({
            ...prev,
            status: { ...prev.status, value: [] },
            receiptDate: { ...prev.receiptDate, value: { startDate: undefined, endDate: undefined } },
            taxYear: { ...prev.taxYear, year: undefined, month: undefined },
        }));
        setReset(true);
    }

    const changePage = (page: number) => {
        dispatch(changePageAction(page));
        onSearch(page);
    }

    const showMoreSR = (data: any) => () => {
        setIsLoading(true);
        getTreasuryTransferForSR(data.dln)
            .then(res => {
                setProcessingStatusDialog({ isOpen: true, data: res.data });
            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false));
    }

    const closeProcessingStatusDialog = () => setProcessingStatusDialog({ isOpen: false, data: null })

    useEffect(() => {
        if (!auth?.tin || (auth?.tin && !utils.tinIsValid)) {
            navigate('/dashboard');
        }
    }, [auth?.tin, utils.tinIsValid]);

    useEffect(() => {
        onSearch();
        getDeclSentStatusTypes()
            .then((res) => {
                setStatusTypes(res.data);
            })
            .catch(err => console.log('getDeclSentStatusTypes', err));
    }, []);

    useEffect(() => {
        if (reset) {
            changePage(1);
            setReset(false);
        }
    }, [reset]);

    useEffect(() => {
        setFilterFields(prev => ({
            ...prev,
            status: {
                ...prev.status,
                options: statusTypes.map((item: any) => ({
                    label: item[`name_${language}`],
                    value: item.id
                }))
            }
        }));
    }, [statusTypes, language]);

    useEffect(() => {
        if (filterFields.status.value && statusTypes && language) {
            const status = statusTypes.find(item => item.id === filterFields.status.value.value);
            if (status) {
                setFilterFields(prev => ({ ...prev, status: { ...prev.status, value: { value: status.id, label: status[`name_${language}`] } } }));
            }
        }
    }, [filterFields.status.value?.label, language, statusTypes])

    const callDeclarationDetails = {
        downloadDeclaration,
        title: t('open_declaration')
    };

    const callReceivedDetails = {
        downloadReceived,
        title: t('open_receipt')
    }

    const csSearchBox = classnames({
        [styles.SearchBox]: true,
        [styles.Open]: utils.device.mode !== "desktop" && filterBoxOpen,
        [styles.Close]: utils.device.mode !== "desktop" && !filterBoxOpen,
    });

    const tableHead: TableHead[] = useMemo(() => ([
        { key: "title", title: t("declaration"), colSpan: 1 },
        { key: "receipt", title: t("deliveryDate"), colSpan: 1 },
        { key: "taxYear", title: t("deliveryPeriod"), colSpan: 1, className: 'fixTdCol200' },
        { key: "statusName", title: t("status"), colSpan: 1 },
        { key: "processingStatus", title: t("PROCESSING_STATUS"), colSpan: 1 },
        { key: "declaration", title: t("declaration"), colSpan: 1, colType: "action", actionRow: renderDeclarationActions, colActionArgs: callDeclarationDetails },
        { key: "received", title: t("Received"), colSpan: 1, colType: "action", actionRow: renderReceivedActions, colActionArgs: callReceivedDetails }
    ]), [t]);

    const ROWS = declarationSubmitted.rows.map((row: any) => ({
        ...row,
        title: row[`declarationName_${language}`],
        statusName: row[`statusName_${language}`],
        processingStatus: ((r) => {
            switch (r.docCode) {
                case 'sr':
                    return <div className={styles.showMoreSR} onClick={showMoreSR(r)}>{t('show_more')}</div>;
                default:
                    return null;
            }
        })(row),
        receipt: formatDate('DD.MM.YYYY', new Date(row.receipt))
    }));

    let listView = null;
    switch (utils.device.mode) {
        case 'desktop':
            listView = (
                <Table
                    data={ROWS}
                    theads={tableHead}
                    caption={
                        <>
                            <div className="mb-3">
                                {t("total_data_count")}
                                &nbsp;
                                <span className={styles.Caption}>{declarationSubmitted.total}</span>
                            </div>
                        </>
                    }
                    verticalSize='middle'
                />
            );
            break;
        default:
            listView = ROWS.length ? (
                <>
                    <div className={styles.mobileViewCardTotal}>
                        {t("total_data_count")}
                        &nbsp;&nbsp;
                        <span className={styles.Caption}>{declarationSubmitted.total}</span>
                    </div>
                    {ROWS.map((item: DeclarationSubmittedCardProps) => (
                        <DeclarationSubmittedCard
                            key={item.dln}
                            title={item.title}
                            taxYear={item.taxYear}
                            receipt={item.receipt}
                            statusName={item.statusName}
                            status={item.status}
                            dln={item.dln}
                            downloadDeclaration={downloadDeclaration}
                            downloadReceived={downloadReceived}
                            showMoreSR={showMoreSR(item)}
                            docCode={item.docCode}
                        />
                    ))}
                </>
            ) : <Empty title={t("dataNotFound")} />;

    }

    return (
        <DashboardLayout className={styles.DeclarationSubmitted}>
            <Container containerSize="large" className={styles.DeclarationSubmittedContainer} withoutPadding>
                <Wrapper title={t("declarationSubmitted")}>
                    <FilterBox
                        mainClass={styles.SearchSection}
                        captionClass={styles.SearchSectionCaption}
                        actionsClass={styles.FilterActionsGroup}
                        bodyClass={csSearchBox}
                        filterBoxOpen={filterBoxOpen}
                        filterBoxToggle={() => setFilterBoxOpen(!filterBoxOpen)}
                        fields={filterFields}
                        onSearch={() => changePage(1)}
                        onNewSearch={onNewSearch}
                        isLoading={isLoading}
                    />
                    <div className={styles.TableSection}>
                        {firstOpen && !ROWS.length ? null : listView}
                        {!!ROWS.length && (
                            <Pagination
                                pageSize={declarationSubmitted.pageSize}
                                total={declarationSubmitted.total}
                                currentPage={declarationSubmitted.currentPage}
                                onChange={changePage}
                            />
                        )}
                    </div>
                </Wrapper>
                {isLoading && <Loading />}
                <ProcessingStatusDialog
                    isOpen={processingStatusDialog.isOpen}
                    close={closeProcessingStatusDialog}
                    data={processingStatusDialog.data}
                />
            </Container>
        </DashboardLayout>
    );
};
export default DeclarationSubmittedPage;
