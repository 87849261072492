import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Container } from '../../Layouts/Container';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import { Wrapper } from '../../Layouts/Wrapper';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { Table, TableHead } from '../../components/Table';
import { Pagination } from '../../components/Pagination';
import {
    classnames,
    formatDate,
    getPaymentsList,
    getPaymentsTotal,
    formatNumberWithCommas,
    getPaymentStatusTypes,
    getPaymentCurrentDetails,
    downloadPayment,
    paymentProcess,
    OS,
    getSearchParams
} from '../../utils';
import { initializePaymentsRows, initializePaymentsTotal, changePaymentsPage, initializePaymentInfo } from '../../store/paymentsSlice';
import { FilterBox } from '../../components/FilterBox';
import { RootState } from '../../store';
import { Button } from '../../components/Button';
import { useNavigate } from '../../hook';
import * as Icons from '../../components/Icons';
import Loading from '../../components/Loading';
import { PaymentCard } from '../../components/Card';
import { Empty } from '../../components/Empty';
import { Tooltip } from '../../components/Tooltip';
import { PaymentSaveEmailDialog } from '../../components/PaymentSaveEmailDialog';
import { NotificationDialog } from '../../components/NotificationDialog';
import * as BridgeActions from "../../utils/bridge_actions";

export const path: string = 'payments/process?/:token?';

const renderRowActions = (rowData: any, params: any) => {
    return (
        <div className={styles.ActionGroup}>
            <Tooltip
                title={params.title}
                type='info'
                display='inline'
                followTheCursor
            >
                <span onClick={() => params?.downloadPDF?.(rowData)} className='action-item'>
                    <Icons.PDF />
                </span>
            </Tooltip>
        </div>
    );
};

const Payments = () => {
    const navigation = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const [statusTypes, setStatusTypes] = useState<any[]>([]);
    const auth: any = useSelector((state: RootState) => state.auth);
    const utils = useSelector((state: any) => state.utils);
    const payments = useSelector((state: RootState) => state.payments);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstOpen, setFirstOpen] = useState<boolean>(true);
    const [paymentFinishMessage, setPaymentFinishMessage] = useState<{ message: string, isSuccess: undefined | boolean }>({ message: '', isSuccess: undefined });
    const [isOpenEmailDialog, setIsOpenEmailDialog] = useState<boolean>(false);
    const [isOpenStatusDescriptionDialog, setIsOpenStatusDescriptionDialog] = useState<boolean>(false);
    const { i18n, t } = useTranslation();
    const { language } = i18n;
    const [searchParams] = useSearchParams();

    const toggleIsOpenEmailDialog = useCallback(() => setIsOpenEmailDialog(p => !p), []);

    const toggleIsOpenStatusDescriptionDialog = useCallback(() => setIsOpenStatusDescriptionDialog(p => !p), []);

    const closePaymentFinishDialog = useCallback(() => setPaymentFinishMessage({ message: '', isSuccess: undefined }), []);

    const handleAmountChange = (key: 'startProps' | 'endProps') => (ev: React.ChangeEvent<HTMLInputElement>) => {
        const value = ev.target.value.replace(/,/g, '');
        if (value && (!value.match(/\d+/) || value.length > 13)) return;
        setFilterFields((prev: any) => ({ ...prev, amount: { ...prev.amount, [key]: { ...prev.amount[key], value: value ? formatNumberWithCommas(value) : '' } } }));
    };

    const handleSelectChange = useCallback(
        (data: { label: string, value: string }, opt: { action: string, name: string }) =>
            setFilterFields(prev => ({ ...prev, [opt.name]: { ...prev[opt.name as keyof typeof prev], value: data } })),
        []
    );

    const handleCreatedDateRange = useCallback((value: any) => setFilterFields(prev => ({ ...prev, createdDate: { ...prev.createdDate, value } })), []);

    const [reset, setReset] = useState(false);
    const [filterFields, setFilterFields] = useState({
        createdDate: {
            label: 'PAYMENTS_PAYMENT_DATE',
            value: {
                startDate: undefined as (Date | undefined),
                endDate: undefined as (Date | undefined)
            },
            type: 'dateRange',
            onChange: handleCreatedDateRange,
            componentStyle: utils.device.mode !== 'desktop' ? {} : { flexBasis: 'calc(32% - 2em)', minWidth: 'unset' }
        },
        amount: {
            label: 'PAYMENTS_AMOUNT',
            type: 'numberRange',
            startProps: {
                value: '',
                onChange: handleAmountChange('startProps'),
            },
            endProps: {
                value: '',
                onChange: handleAmountChange('endProps'),
            },
            componentStyle: utils.device.mode !== 'desktop' ? {} : { flexBasis: 'calc(32% - 2em)', minWidth: 'unset' }
        },
        status: {
            label: 'PAYMENTS_STATUS',
            labelIcon: (tr: Function) => (
                <Tooltip title={tr('help')} type="info" position="bottom-right" className="paymentStatusHelp">
                    <span onClick={toggleIsOpenStatusDescriptionDialog}><Icons.InfoOutline /></span>
                </Tooltip>
            ),
            value: '' as any,
            type: 'select',
            onChange: handleSelectChange,
            onInputChange: () => {},
            options: [] as any,
            isClearable: true,
            placeholder: 'any',
            componentStyle: utils.device.mode !== 'desktop' ? {} : { flexBasis: 'calc(32% - 2em)', minWidth: 'unset' }
        }
    });
    const [filterBoxOpen, setFilterBoxOpen] = useState(false);

    const onSearch = async (page?: number) => {
        setIsLoading(true);
        try {
            const filter = {
                startDate: filterFields.createdDate.value?.startDate ? formatDate('YYYY-MM-DD', filterFields.createdDate.value?.startDate) : undefined,
                endDate: filterFields.createdDate.value?.endDate ? formatDate('YYYY-MM-DD', filterFields.createdDate.value?.endDate) : undefined,
                amountMax: filterFields.amount.endProps.value ? filterFields.amount.endProps.value.replace(/,/g, '') : undefined,
                amountMin: filterFields.amount.startProps.value ? filterFields.amount.startProps.value.replace(/,/g, '') : undefined,
                status: filterFields.status.value?.value || undefined
            };
            const filterForList = {
                ...filter,
                currentPage: page || payments.currentPage,
                pageSize: payments.pageSize
            }
            const filterForTotal = { ...filter };
            const [list, total] = await Promise.all([getPaymentsList(filterForList), getPaymentsTotal(filterForTotal)]);
            dispatch(initializePaymentsRows(list.data));
            dispatch(initializePaymentsTotal(total.data.value || 0));
            setFirstOpen(false);
        } catch (err) {
            console.log('onSearch >>>', err);
        } finally {
            setIsLoading(false);
        }
    }

    const onNewSearch = () => {
        setFilterFields(prev => ({
            ...prev,
            status: { ...prev.status, value: null },
            createdDate: { ...prev.createdDate, value: { startDate: undefined, endDate: undefined } },
            amount: { ...prev.amount, startProps: { ...prev.amount.startProps, value: '' }, endProps: { ...prev.amount.endProps, value: '' } }
        }));
        setReset(true);
    }

    const changePage = (page: number) => {
        dispatch(changePaymentsPage(page));
        onSearch(page);
    }

    const addNewPayment = () => {
        setIsLoading(true);
        getPaymentCurrentDetails()
            .then(res => {
                const info = {
                    initialized: true,
                    email: res.data.email,
                    tin: res.data.tin,
                    psn: res.data.psn,
                    payerName: res.data.payerName,
                    accountNumber: res.data.accountNumber,
                    socialPayment: res.data.socialPayment,
                    taxPayment: res.data.taxPayment,
                    amountForPay: (parseInt(res.data.socialPayment) || 0) + (parseInt(res.data.taxPayment) || 0)
                }
                dispatch(initializePaymentInfo(info));
                const emailExists = !!res.data.email;
                if (emailExists) {
                    approveEmailDialog();
                } else {
                    setIsOpenEmailDialog(true);
                }
            })
            .catch(err => console.log('addNewPayment.getPaymentCurrentDetails >>> ', err))
            .finally(() => setIsLoading(false));
    }

    const approveEmailDialog = () => {
        navigation('/payment-order-form');
    }

    const downloadPDF = (rowData: any) => {
        const { mobileView, os} = getSearchParams();
        if(!!mobileView && os === OS.android) {
            return BridgeActions.onAction({...BridgeActions.DOWNOALD_PDF, payload: { apiPath: `/api/epayments/payment/${rowData.id}/downloadPdf/${language}` }});
        }
        downloadPayment(rowData.id, language)
            .then((res: any) => {
                const fileName = res.headers['content-disposition'].replace('attachment; filename=', '').replace(/"/g, '');
                const url = window.URL.createObjectURL(res.data);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.log('downloadPDF >>>', err);
                toast.error(t(err.message), { position: 'top-right', theme: 'colored' });
            });
    }

    const showPaymentNotification = (data: any) => {
        if (data.message === 'NOT_FOUND') return;
        if (data.correct) {
            setPaymentFinishMessage( { message: 'PAYMENT_ORDER_FORM_SENT_DIALOG_MSG_SUCCESS', isSuccess: true });
        } else {
            setPaymentFinishMessage({ message: 'PAYMENT_ORDER_FORM_SENT_DIALOG_MSG_FAIL', isSuccess: false });
        }
    }

    useEffect(() => {
        if (reset) {
            onSearch();
            setReset(false);
        }
    }, [reset, onSearch]);

    useEffect(() => {
        getPaymentStatusTypes()
            .then(res => {
                setStatusTypes(res.data);
            })
            .catch(err => console.log('getEmployeeIncomeTypes', err));
        if (params.token) {
            setIsLoading(true);
            paymentProcess(params.token)
                .then(res => {
                    showPaymentNotification(res.data);
                })
                .catch(err => {
                    console.log('paymentProcess error: ', err);
                    showPaymentNotification(err.response.data);
                })
                .finally(() => {
                    searchParams.delete("order_number");
                    navigation('/payments');
                    onSearch();
                });
        } else {
            onSearch();
        }
    }, []);

    useEffect(() => {
        if (!auth?.tin || (auth?.tin && !utils.tinIsValid)) {
            navigation('/dashboard');
        }
    }, [auth?.tin, utils.tinIsValid]);

    useEffect(() => {
        setFilterFields(prev => {
            const selected = statusTypes.find(item => item.id === prev.status.value?.value);
            const newState = {
                ...prev,
                status: {
                    ...prev.status,
                    options: statusTypes.map((item: any) => ({
                        label: item[`name_${language}`],
                        value: item.id
                    })),
                    value: selected ? { label: selected[`name_${language}`], value: selected.id } : null
                }
            };
            return newState;
        });
    }, [statusTypes, language]);

    const csSearchBox = classnames({
        [styles.SearchBox]: true,
        [styles.Open]: utils.device.mode !== "desktop" && filterBoxOpen,
        [styles.Close]: utils.device.mode !== "desktop" && !filterBoxOpen,
    });

    const callActionsDetails = {
        downloadPDF,
        title: t("PAYMENTS_PDF_VIEW")
    };

    const tableHead: TableHead[] = useMemo(() => ([
        { key: "orderNumber", title: t("PAYMENTS_ORDER_PAYMENT"), className: 'fixTdCol200' },
        { key: "createDate", title: t("PAYMENTS_PAYMENT_DATE") },
        { key: "pin", title: t("PAYMENTS_PIN"), className: 'fixTdCol150' },
        { key: "amount", title: t("PAYMENTS_AMOUNT"), className: 'fixTdCol150' },
        { key: "statusName", title: t("PAYMENTS_STATUS"), className: 'fixTdCol150' },
        { key: "descriptionName", title: t("PAYMENTS_DESCRIPTION") },
        { key: "operations", title: t("PAYMENT_ORDER_FORM_ACCOUNT_FOR_RECEIPT"), colType: "action", colActionArgs: callActionsDetails, className: 'fixTdCol100' },
    ]), [t]);

    let ROWS = payments.rows.map(row => ({
        ...row,
        createDate: row.createDate ? formatDate('DD.MM.YYYY HH:mm:ss', new Date(row.createDate)) : '',
        paymentTypeName: row[`paymentTypeName_${language}`],
        amount: !Number.isNaN(parseInt(row.amount)) ? formatNumberWithCommas(row.amount) : '',
        statusName: row[`statusName_${language}`],
        descriptionName: row[`description_${language}`]
    }));
    let listView = null;
    switch (utils.device.mode) {
        case 'desktop':
            listView = (
                <Table
                    data={ROWS}
                    theads={tableHead}
                    caption={
                        <>
                            {t("total_data_count")}
                            &nbsp;
                            <span className={styles.Caption}>{payments.total}</span>
                        </>
                    }
                    rowActions={renderRowActions}
                    verticalSize='middle'
                />
            );
            break;
        default:
            listView = (
                <div className={styles.PaymentsCards}>
                    {ROWS.length ? (
                        <div className={styles.mobileViewCardTotal}>
                            {t("total_data_count")}
                            &nbsp;&nbsp;
                            <span className={styles.Caption}>{payments.total}</span>
                        </div>
                    ) : null}
                    {ROWS.map((item: any, index: number) => (
                        <PaymentCard { ...item } downloadPDF={downloadPDF} key={index} />
                    ))}
                    {!ROWS.length && <Empty title={t("dataNotFound")} />}
                </div>
            );
    }

    return (
        <DashboardLayout className={styles.Payments}>
            <Container containerSize='large' className={styles.PaymentsContainer} withoutPadding>
                <Wrapper title={t("PAYMENTS_WRAPPER_TITLE")}>
                    <FilterBox
                        mainClass={styles.SearchSection}
                        captionClass={styles.SearchSectionCaption}
                        actionsClass={styles.FilterActionsGroup}
                        bodyClass={csSearchBox}
                        filterBoxOpen={filterBoxOpen}
                        filterBoxToggle={() => setFilterBoxOpen(!filterBoxOpen)}
                        fields={filterFields}
                        onSearch={() => changePage(1)}
                        onNewSearch={onNewSearch}
                        isLoading={isLoading}
                    />
                    <div className={styles.TableSection}>
                        <Button
                            size="small"
                            className={styles.addNewPayment}
                            onClick={addNewPayment}
                            style={{ marginBottom: ROWS.length ? 0 : 20 }}
                        >
                            {t('PAYMENTS_CREATE_NEW')}
                        </Button>
                        {firstOpen && !ROWS?.length ? null : listView}
                        {!!ROWS?.length && <Pagination pageSize={payments.pageSize} total={payments.total} currentPage={payments.currentPage} onChange={changePage} />}
                    </div>
                </Wrapper>
                {isLoading && <Loading />}
                <PaymentSaveEmailDialog
                    isOpen={isOpenEmailDialog}
                    close={toggleIsOpenEmailDialog}
                    callback={approveEmailDialog}
                />
                <NotificationDialog
                    isOpen={!!paymentFinishMessage.message}
                    close={closePaymentFinishDialog}
                    head={t('PAYMENT_ORDER_FORM_SENT_DIALOG_TITLE')}
                    messageTranslationKey={paymentFinishMessage.message || undefined}
                    type={paymentFinishMessage.isSuccess ? 'success' : 'error'}
                />
                <NotificationDialog
                    isOpen={isOpenStatusDescriptionDialog}
                    close={toggleIsOpenStatusDescriptionDialog}
                    head={t('PAYMENT_DESCRIPTION_TITLE')}
                    size="fit-content"
                    messageTranslationKey={(
                        <ul className={styles.paymentDescriptions}>
                            {new Array(3).fill(null).map((_, i) => (
                                <li className="mb-3" key={i}>{t(`PAYMENT_DESCRIPTION_POINT_${i + 1}`)}</li>
                            ))}
                        </ul>
                    )}
                />
            </Container>
        </DashboardLayout>
    );
};
export default Payments;
