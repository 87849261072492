import React, { forwardRef, useMemo, useRef, useState } from "react";
import DatePick from "react-datepicker";
import { Input } from "../Input";
import { useOutsideClick, useTranslate } from "../../hook";
import * as Icons from "../Icons";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.scss"
import { classnames } from "../../utils";
import { months, weekDaysName } from "../../utils";
import { Tooltip } from '../Tooltip';

export enum DateMode {
    year = "yyyy",
    month_year = "MM.yyyy",
    month = "MM",
    full = "dd.MM.yyyy"
}
export type DateModeType = "yyyy" | "MM.yyyy" | "dd.MM.yyyy" | "MM";


interface DatePickerProps {
    label?: string | React.ReactNode;
    type?: "range" | "single";
    value?: Date | { startDate?: Date, endDate?: Date };
    onChange?: (arg: Date | { startDate?: Date, endDate?: Date }) => any;
    placeholder?: string;
    placeholderEnd?: string;
    iconprefixstyle?: object;
    mode?: DateModeType;
    config?: { start?: any, end?: any},
    minDate?: Date;
    maxDate?: Date;
    componentStyle?: any;
    className?: string
    errorMessage?: string;
    errorWithTooltip?: boolean;
}

const MonthPicker = (props: any) => {
    const { month, language = "am" } = props;
    const currentMonth = months[month]

    return <span >{currentMonth[language as keyof typeof currentMonth].short}</span>;
}

const PickerHeader = (props: any) => {
    const { language = "am", mode, date, changeYear, maxDate, minDate, ...rest} = props;
    const selectedMonth = months[date.getMonth()];
    const monthLng = selectedMonth[language as keyof typeof selectedMonth].long;
    const yearRef = useRef(null);
    const [showAllYears, setShowallYears] = useState(false);
    const yearArray = useMemo(() => {
        const maxYear = maxDate ? new Date(maxDate).getFullYear() : new Date().getFullYear() + 5;
        const minYear = minDate ? new Date(minDate).getFullYear() : 1900;
        const years = []
        for (let i = maxYear; i >= minYear; i--) {
            years.push(i);
        }
        return years;
    }, [maxDate, minDate]);

    useOutsideClick(yearRef, () => {
        setShowallYears(!setShowallYears)
    });

    const handleClick = (e: any) => {
        if(mode === DateMode.full) {
            e.preventDefault();
            setShowallYears(!showAllYears);
        }
    };
    const handleChangeYear = (event: any, year: number) => {
        event.stopPropagation();
        changeYear(year);
        setShowallYears(false);
    }

    return (
        <div className={styles.PickerHeader}>
            <span>
                {mode !== DateMode.month && (
                    <button type="button" className={styles.Left} onClick={mode !== DateMode.full ? rest.decreaseYear : rest.decreaseMonth}>
                        <Icons.ArrowDown />
                    </button>
                )}
            </span>
            <span className={styles.HeadValue}>
                {mode !== DateMode.year && <span>{monthLng}</span>}
                {mode !== DateMode.month && (
                    <span className={styles.PickHeaderYearWrapper} ref={yearRef} onClick={handleClick}>
                        <span>
                            <span>{date.getFullYear()}</span>
                            {mode === DateMode.full && <span className={classnames({ [styles.Open]: showAllYears })}><Icons.ArrowDown /> </span>}
                        </span>
                        {showAllYears && (
                            <ul>
                                {yearArray.map((year) => <li key={year} className={classnames({[styles.Selected]: year === date.getFullYear()})} onClick={(e: any) => handleChangeYear(e, year)}>{year}</li>)}
                            </ul>
                        )}
                    </span>
                )}
            </span>
            <span>
                {mode !== DateMode.month && (
                    <button type="button" className={styles.Right} onClick={mode !== DateMode.full ? rest.increaseYear : rest.increaseMonth}>
                        <Icons.ArrowDown />
                    </button>
                )}
            </span>
        </div>
    )
}

const Inp = forwardRef<HTMLInputElement, any>((props, ref) => {
    const { mode, value, language, calendar, ...rest } = props;

    const val = useMemo(() => {
        if(mode !== DateMode.month || !value) {
            return value
        }
        else {
            const selectedMonth = months.find((month) => month.en.long.toLowerCase() === value.toLowerCase());
            return selectedMonth?.[language as keyof typeof selectedMonth]?.long
        }
    }, [mode, value, language]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !!calendar) {
            const { handleSelect, state } = calendar ;
            const { preSelection } = state;
            if (!!event.currentTarget.value) {
                handleSelect(preSelection)
            }
            else {
                handleSelect(null);
            }
        }
    }

    return (
        <Input
            {...rest}
            ref={ref} 
            value={val}
            onKeyDown={handleKeyDown} 
            onChange={() => null}
            readOnly
        />
    )
});

export const DatePicker: React.FC<DatePickerProps> = (props) => {
    const { label, type, value, onChange, errorMessage, errorWithTooltip, placeholder, placeholderEnd, mode = DateMode.full, config, minDate, maxDate, componentStyle, className } = props;
    const { i18n } = useTranslate();
    const { language } = i18n;
    const startRef = useRef<DatePick>(null);
    const endRef = useRef<DatePick>(null);

    const currentValueStart = !(value instanceof Date) ? value?.startDate : value;
    const currentValueEnd = !(value instanceof Date) ? value?.endDate : value;

    const csPicker = classnames({
        [styles.SinglePicker]: true,
        [styles.RangePicker]: type === "range"
    });

    const csError = classnames({
        errorMessage: true,
        [styles.ErrorMessage]: !!errorMessage,
    });

    const handleChange = (key: "startDate" | "endDate") => {
        return (date?: Date | null, event?: any) => {
            const d = { startDate: currentValueStart, endDate: currentValueEnd };
            d[key] = date as Date;
            if (type === "range") {
                return onChange?.(d)
            }
            return onChange?.(d.startDate as Date)
        }
    };

    const handleClear = (key: "startDate" | "endDate", isClear: boolean) => {
        return (event: any) => {
            if(isClear) {
                event.preventDefault();
                handleChange(key)(undefined);
            }
        }
    }

    return (
        <div className={classnames({[styles.DatePickerWrapper]: true, [className ?? ""]: !!className })} style={componentStyle || {}}>
            {label && <span className={styles.Label}>{label}</span>}
            <div className={styles.DateInputWrapper}>
                <div className={csPicker}>
                    <DatePick
                        ref={startRef}
                        formatWeekDay={(nameOfDay) => {
                            const currentDay = weekDaysName[nameOfDay.toLowerCase() as keyof typeof weekDaysName];
                            const data = currentDay[language as keyof typeof currentDay]
                            return data.short;
                        }}
                        dateFormat={mode === DateMode.month ? "MMMM" : mode}
                        customInput={
                            <Inp
                                iconPrefix={!currentValueStart || config?.start?.disabled ? <Icons.Calendar /> : <Icons.CloseOutline />}
                                onClickPrefix={handleClear("startDate", !!currentValueStart)}
                                calendar={startRef.current}
                                mode={mode}
                                language={language}
                                iconprefixstyle={props.iconprefixstyle}
                            />
                        }
                        selected={currentValueStart} 
                        onSelect={handleChange("startDate")}
                        selectsStart={type === "range"}
                        endDate={type === "range" ? currentValueEnd : undefined}
                        placeholderText={placeholder}
                        minDate={minDate}
                        maxDate={maxDate}
                        showYearPicker={mode === DateMode.year}
                        showMonthYearPicker={mode === DateMode.month_year || mode === DateMode.month}
                        renderMonthContent={(month) => <MonthPicker month={month} language={language} />}
                        calendarStartDay={1}
                        renderCustomHeader={(props) => <PickerHeader {...props} language={language} mode={mode} maxDate={maxDate} minDate={minDate} />}
                        {...config?.start}
                    />
                    {errorMessage && (
                        <div className={csError}>
                            {!errorWithTooltip && (
                                <>
                                    <span className={styles.Icon}><Icons.Error /></span>
                                    <span>{errorMessage}</span>
                                </>
                            )}
                            {!!errorWithTooltip && (
                                <Tooltip title={errorMessage} type='error'>
                                    <span className={styles.Icon}><Icons.Error /></span>
                                </Tooltip>
                            )}
                        </div>
                    )}
                </div>
                {type === "range" && (
                    <>
                        <div className={styles.RangeLine} />
                        <div className={csPicker}>
                            <DatePick
                                ref={endRef}
                                formatWeekDay={(nameOfDay) => {
                                    const currentDay = weekDaysName[nameOfDay.toLowerCase() as keyof typeof weekDaysName];
                                    const data = currentDay[language as keyof typeof currentDay]
                                    return data.short
                                }}
                                dateFormat={mode === DateMode.month ? "MMMM" : mode}
                                customInput={
                                    <Inp 
                                        iconPrefix={!currentValueEnd || config?.end?.disabled ? <Icons.Calendar /> : <Icons.CloseOutline />}
                                        onClickPrefix={handleClear("endDate", !!currentValueEnd)}
                                        calendar={endRef.current}
                                        mode={mode}
                                        language={language}
                                    />
                                } 
                                selected={currentValueEnd}
                                onSelect={handleChange("endDate")}
                                selectsEnd
                                startDate={currentValueStart}
                                minDate={currentValueStart ?? minDate}
                                maxDate={maxDate}
                                placeholderText={placeholderEnd}
                                showYearPicker={mode === DateMode.year}
                                showMonthYearPicker={mode === DateMode.month_year || mode === DateMode.month}
                                renderMonthContent={(month) => <MonthPicker month={month} language={language} />}
                                calendarStartDay={1}
                                renderCustomHeader={(props) => <PickerHeader {...props} language={language} mode={mode} />}
                                {...config?.end}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}