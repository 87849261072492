import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '../Button';
import { Select } from '../Select';
import { Input, InputRange } from '../Input';
import { DatePicker, DateModeType } from '../DatePicker';
import * as Icons from '../Icons';
import { months, IMonthType } from '../../utils';
import styles from "./styles.module.scss";

type datePicketValue = { startDate?: Date, endDate?: Date };
type IFieldValue = string | Date | datePicketValue | { label: string, value: string };
type datePicketHandler = (arg: Date | datePicketValue) => any;

export const FilterBox: React.FC<{
    isLoading?: boolean,
    mainClass?: string,
    captionClass?: string,
    bodyClass?: string,
    actionsClass?: string,
    filterBoxOpen: boolean,
    filterBoxToggle: React.MouseEventHandler<HTMLButtonElement>,
    fields: {
        [key: string]: {
            value?: IFieldValue,
            onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement> | Function | datePicketHandler,
            onInputChange?: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement> | Function | datePicketHandler,
            type: string,
            label?: string,
            labelIcon?: (t: Function) => React.ReactNode,
            noOptionsMessageKey?: string,
            isMulti?: boolean,
            options?: Array<{ label: string, value: string }>,
            mode?: DateModeType;
            placeholder?: string;
            maxLength?: number;
            pattern?: string;
            isClearable?: boolean;
            noDropdown?: boolean;
            selectDuringOnBlur?: boolean;
            selectDuringOnBlurOptions?: any;
            startProps?: {
                value?: string,
                onChange?: React.ChangeEventHandler<HTMLInputElement>
                mode?: DateModeType;
                placeholder?: string;
            };
            endProps?: {
                value?: string,
                onChange?: React.ChangeEventHandler<HTMLInputElement>;
                mode?: DateModeType;
                placeholder?: string;
            };
            year?: Date;
            month?: { label: string, value:string };
            min?: Date;
            max?: Date;
            onYearChange?: (arg: Date | { startDate?: Date, endDate?: Date }) => any;
            onMonthChange?: Function;
            withoutMonth?: boolean;
            componentStyle?: any;
            componentSelectStyle?: any;
            errorMessage?: string;
            anyValue?: boolean;
        }
    },
    onSearch?: Function,
    onNewSearch?: React.MouseEventHandler<HTMLButtonElement>
}> = (props) => {
    const { t, i18n } = useTranslation();
    const { device } = useSelector((state: any) => state.utils);
    const fieldsList = Object.entries(props.fields);

    const submitForm = (ev: React.FormEvent) => {
        ev.preventDefault();
        if (props.isLoading) return;
        props.onSearch?.();
    };

    return (
        <form onSubmit={submitForm}>
            <div className={props.mainClass}>
                <div className={props.captionClass}>
                    <h3>{t('searchTitle')}</h3>
                    {device.mode !== 'desktop' && (
                        <Button type="button" variant='link' onClick={props.filterBoxToggle}>
                            {!props.filterBoxOpen ? <Icons.Filter /> : <Icons.Close />}
                        </Button>
                    )}
                </div>
                <div className={props.bodyClass}>
                    {fieldsList.map(([key, fieldData]) => {
                        let component = null;
                        const fieldLabel = t(fieldData.label || key);
                        switch (fieldData.type) {
                            case 'select':
                                component = (
                                    <Select
                                        label={fieldLabel}
                                        labelIcon={fieldData.labelIcon}
                                        name={key}
                                        value={fieldData.value}
                                        onChange={fieldData.onChange || (() => null)}
                                        onInputChange={fieldData.onInputChange || (() => null)}
                                        options={fieldData.options || []}
                                        isMulti={fieldData.isMulti || false}
                                        isClearable={fieldData.isClearable || false}
                                        selectDuringOnBlur={fieldData.selectDuringOnBlur || false}
                                        selectDuringOnBlurOptions={fieldData.selectDuringOnBlurOptions || undefined}
                                        placeholder={fieldData.placeholder ? t(fieldData.placeholder) : undefined}
                                        noOptionsMessageKey={fieldData.noOptionsMessageKey}
                                        noDropdown={fieldData.noDropdown}
                                        componentStyle={fieldData.componentStyle}
                                        anyValue={fieldData.anyValue}
                                        errorMessage={fieldData.errorMessage ? t(fieldData.errorMessage) : ''}
                                    />
                                );
                                break;
                            case 'text':
                                component = (
                                    <Input
                                        label={fieldLabel}
                                        value={fieldData.value as string}
                                        name={key}
                                        onChange={(fieldData.onChange || (() => null)) as React.ChangeEventHandler<HTMLInputElement>}
                                        maxLength={fieldData.maxLength}
                                        pattern={fieldData.pattern}
                                        componentStyle={fieldData.componentStyle}
                                    />
                                );
                                break;
                            case 'numberRange':
                                component = (
                                    <InputRange
                                        label={fieldLabel}
                                        startProps={fieldData.startProps}
                                        endProps={fieldData.endProps}
                                        componentStyle={fieldData.componentStyle}
                                    />
                                );
                                break;
                            case 'dateRange':
                                component = (
                                    <DatePicker
                                        label={fieldLabel}
                                        type="range"
                                        onChange={(fieldData.onChange || (() => null)) as datePicketHandler}
                                        value={fieldData.value as datePicketValue}
                                        placeholder={''}
                                        placeholderEnd={''}
                                        componentStyle={fieldData.componentStyle}
                                    />
                                );
                                break;
                            case 'date':
                                component = (
                                    <DatePicker
                                        label={fieldLabel}
                                        onChange={(fieldData.onChange || (() => null)) as datePicketHandler}
                                        value={fieldData.value as datePicketValue}
                                        placeholder={fieldData.placeholder ? t(fieldData.placeholder) : undefined}
                                        mode={fieldData.mode}
                                        minDate={fieldData.min as Date}
                                        maxDate={fieldData.max as Date}
                                        componentStyle={fieldData.componentStyle}
                                    />
                                );
                                break;
                            case 'yearMonth':
                                component = (
                                    <>
                                        <DatePicker
                                            label={fieldLabel}
                                            mode="yyyy"
                                            value={fieldData.year}
                                            minDate={fieldData.min as Date}
                                            maxDate={fieldData.max as Date}
                                            onChange={(fieldData.onYearChange || (() => null)) as datePicketHandler}
                                            componentStyle={fieldData.componentStyle}
                                            placeholder={t('year')}
                                            className={styles.YearMonthPicker}
                                        />
                                        {!fieldData.withoutMonth && (
                                            <Select
                                                label={fieldData.placeholder ? t(fieldData.placeholder) : ''}
                                                value={fieldData.month || null}
                                                onChange={fieldData.onMonthChange || (() => null)}
                                                onInputChange={fieldData.onInputChange || (() => {})}
                                                options={months.map((month, i) => ({
                                                    label: month[i18n.language as keyof IMonthType]?.long,
                                                    value: `${i + 1 < 10 ? `0${i + 1}` : i + 1}`
                                                }))}
                                                isClearable={fieldData.isClearable || false}
                                                placeholder={t('month')}
                                                isDisabled={!fieldData.year}
                                                componentStyle={fieldData.componentSelectStyle || fieldData.componentStyle}
                                                labelVisibilityHidden
                                                selectDuringOnBlur
                                                fieldType="month"
                                            />
                                        )}
                                    </>
                                );
                                break;
                            default:
                        }
                        return (
                            <React.Fragment key={key}>
                                {component}
                            </React.Fragment>
                        );
                    })}
                    <div className={props.actionsClass}>
                        <Button size="small" type="submit" disabled={!!props.isLoading}>
                            {t('search')}
                        </Button>
                        <Button size="small" type="button" variant="outlined" onClick={props.onNewSearch || (() => null)} disabled={!!props.isLoading}>
                            {t('new_search')}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
}
