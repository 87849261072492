import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form } from './Form';
import { generateField, getRootDataStore } from '../utils';
import styles from './styles.module.scss';
import { Button } from '../../../Button';
import { useTranslation } from 'react-i18next';
import { MobileIncomeTaxContext } from '../mobile_income_tax.context';
import { Question } from './Question';
import { formValidator } from '../../../../utils';
import { section2Rows } from '../../../../utils';
        
export const NonTaxable: React.FC<{ onSubmit: CallableFunction, next: CallableFunction }> = (props) => {
    const { onSubmit, next } = props;
    const [formData, setFormData] = useState<IDataTypes<Field<any>>>({});
    const formDataRef = useRef({ data: formData, isValid: true });
    const { t } = useTranslation();
    const context = useContext(MobileIncomeTaxContext);

    const handleSubmit = () => {
        const newFormatedData = Object.entries(formDataRef.current.data).reduce((acc, [key, field]: [string, Field<any>]) => {
            if(!!field.value) {
                acc[field.key] = field.value;
            }
            if(key === "f2_27") {
                acc.f2_27 = field.value ?? 0
            }
            return acc
        }, {} as IDataTypes<any>);
        onSubmit?.(newFormatedData, !formDataRef.current.isValid);
    }

    useEffect(() => {
        const rootData = getRootDataStore().root?.section2;
        const newFormData = new Array(27).fill(0).reduce((acc: IDataTypes<Field<any>>, _: any, index: number) => {
            acc[`f2_${index + 1}`] = generateField<number>(`f2_${index + 1}`, rootData?.[`f2_${index + 1}`], { type: "string", isValid: true, validation: { isRequired: false, disabled: index + 1 === 27 }, label: `sec_2_tr_${index + 1}`, withComma: true });
            return acc
        }, {});
        setFormData(newFormData);
        if(!getRootDataStore()?.nonTaxable?.completed) {
            context?.setOutOfSteps?.((prev: any) => {
                if(!prev) {
                    prev = {}
                }
                prev.children = (
                    <Question
                        question={"have_non_taxable_income"}
                        yes={() => context?.setOutOfSteps?.(null)}
                        no={() => {
                            context?.setOutOfSteps?.(null);
                            next();
                        }}
                    />
                )
                return { ...prev }
            })
        }

        return () => {
            if (window.location.pathname.includes("declaration/type/sd")) {
                handleSubmit();
            }
        }
    }, []);

    useEffect(() => {
        const validator = formValidator(formData);
        formDataRef.current.data = formData;
        formDataRef.current.isValid = validator.isValid;
    }, [JSON.stringify(formData)])

    const handleChange = (data: any) => {
        const { key, value } = data;
        setFormData((prev) => {
            if(!isNaN(value) && !prev[key].validation?.disabled) {
                prev[key].value = +value;          
            }
            if(!value) {
                prev[key].value = undefined;
            }
            // calculate f2_27 total
            prev.f2_27.value = Object.entries(prev).reduce((acc, [incomeKey, income]) => {
                if(incomeKey !== "f2_27" && !!income.value && !isNaN(income.value)) {
                    acc += Math.trunc(income.value);
                }
                return acc;
            }, 0);
            return { ...prev }
        })
    };

    const handleLabelRewriter = (trKey: string) => {
        const findedRow = section2Rows.find((item) => item.translation_key === trKey);
        return findedRow ? `${t(trKey)} [${findedRow.number}]` : t(trKey);
    }

    return (
        <div className={styles.NonTaxable}>
            <Form 
                data={formData}
                prefix={() => <span>{t("dram")}</span>}
                onChange={handleChange}
                labelRewriter={handleLabelRewriter}
            />
            <Button className={styles.NextBtn} onClick={() => next()}>{t("next")}</Button>
        </div>
    );
};