import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { languages, ILanguage } from '../../utils/languages';
import PageNotFound from "../../pages/404";
import { Navbar } from '../../components/Nav';
import { useSelector } from 'react-redux';
import { Button } from '../../components/Button';
import styles from "./styles.module.scss"
import { RootState } from '../../store';
import { useNavigate } from '../../hook';
import * as Icons from "../../components/Icons";
import * as BridgeActions from "../../utils/bridge_actions";
import { getSearchParams } from '../../utils';

export default function GlobalLayout() {
    const { i18n, t } = useTranslation();
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const params = useParams();
    const auth = useSelector((state: any) => state.auth);
    const utils = useSelector((state: RootState) => state.utils);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const currentLanguage: ILanguage | undefined = languages.find(lang => params.lngCode ? lang.code === params.lngCode : lang.isDefault);
        if (currentLanguage?.code) {
            i18n.changeLanguage(currentLanguage.code);
        }
        window.onmessage = (event: any) => {
            if(event.data?.includes?.("UN_READ_COUNT")) {
                const count = event.data.split("/")[1];
                setUnreadMessageCount(+count);
            }
        }
    }, [i18n, params.lngCode]);

    let view = <Outlet />;
    if (params.lngCode) {
        const langIndex = languages.findIndex(lang => lang.code === params.lngCode);
        if (langIndex === -1) {
            view = <PageNotFound />;
        }
    };

    const handleNavigate = (path: string) => {
        return () => {
            if(!!getSearchParams().mobileView) {
                const actionsMapper = {
                    "/dashboard": BridgeActions.GO_TO_MAIN_PAGE,
                    "/profile": BridgeActions.GO_TO_PROFILE_PAGE
                }
                return BridgeActions.onAction(actionsMapper[path as keyof typeof actionsMapper]);
            }
            navigate(path);
        };
    };

    const handleOpenChat = () => {
        if(!!getSearchParams().mobileView) {
            BridgeActions.onAction(BridgeActions.TOGGLE_CHAT_OPENING);
        }
        else {
            const iframe: HTMLIFrameElement | null = document.querySelector(".ts-chatbot-wrapper iframe");
            const chatWrapper: HTMLDivElement | null = document.querySelector(".ts-chatbot-wrapper");
            if(utils.device?.mode !== "desktop" && iframe) {
                iframe.contentWindow?.postMessage("hideChatButton", "*");
            }
            if(chatWrapper) {
                chatWrapper.style.visibility = "visible";
                if(iframe?.contentWindow) {
                    if(chatWrapper.classList.contains("opened")) {
                        iframe.contentWindow.postMessage('closeChat', '*');
                    }
                    else {
                        iframe.contentWindow.postMessage('openChat', '*');
                    }
                }
            }
        }
    };
    const active_toolbar = useMemo(() => (
        !!auth?.username && !location.pathname?.includes("/registration/") && !location.pathname?.includes("/reset-password/") && utils.device?.mode !== "desktop" && !utils.mobileView
    ), [location.pathname, auth?.username, utils.device?.mode, utils.mobileView]);


    return (
        <main className={styles.Main}>
            <div className={styles.viewContent}>
                {!utils.mobileView && <Navbar />}
                {view}
            </div>
            {active_toolbar && (
                <div className={styles.QuickButtons}>
                    <div className={styles.ButtonsGroup}>
                        <Button variant='link' onClick={handleNavigate("/dashboard")}>
                            <Icons.Home />
                        </Button>
                        <Button variant='link' onClick={handleNavigate("/profile")}>
                            <Icons.User />
                        </Button>
                        <Button variant='link' onClick={handleOpenChat}>
                            <span>
                                <Icons.Chat />
                                {!!unreadMessageCount && <span>{unreadMessageCount}</span>}
                            </span>
                        </Button>
                    </div>
                </div>
            )}
            {!utils.mobileView && (
                <Footer className={styles.Footer}>
                    {t("producedBy").toString().replace('[YEAR]', new Date(2024, 0).getFullYear().toString()).replace('[COPYRIGHT]', '©')}
                </Footer>
            )}
        </main>
    );
}

type IFooter = {
    children: React.ReactNode;
    className: string;
}

const Footer: React.FC<IFooter> = (props) => <footer className={props.className} />;
