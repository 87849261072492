import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icons from "../Icons";
import { Button } from "../Button";
import { classnames, navigations, helps, dispatchActionsEvent } from "../../utils";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "../../hook";
import { Languages } from "../Languages";
import { toggleDisableOpenMenu, toggleNotifyTinIsInValid } from '../../store/utilsSlice';

interface MobileMenuProps {
    onClose: React.MouseEventHandler<HTMLButtonElement>;
    isOpen: boolean;
    logOut?: (e: any) => void
}

interface MobileMenuItemProps {
    children?: React.ReactNode;
    title: string;
    icon?: React.ReactNode;
    onNavigate?: React.MouseEventHandler<HTMLDivElement>
}

const MobileMenuItem: React.FC<MobileMenuItemProps> = (props) => {
    const { children, title, icon, onNavigate } = props;
    const [openSubMenu, setOpenSubMenu] = useState(false);

    const cs = classnames({
        [styles.Arrow]: true,
        [styles.Open]: openSubMenu
    });
    
    const csItem = classnames({
        [styles.MobileMenuItem]: true,
        [styles.MobileMenuItemOpen]: openSubMenu
    })
    const handleOpenChild = useCallback((event: React.MouseEvent<HTMLLIElement>) => {
        if (!!children) {
            setOpenSubMenu((prev) => !prev)
        }
        else {
            onNavigate?.(event as unknown as React.MouseEvent<HTMLDivElement>)
        }
    }, [children]);

    return (
        <>
            <li className={csItem} onClick={handleOpenChild}>
                <div className={styles.Row}>
                    <div className={styles.Left} onClick={onNavigate}>
                        <span>{icon}</span>
                        <span>{title}</span>
                    </div>
                    {children && (
                        <div className={cs}>
                            <Icons.ArrowDown />
                        </div>
                    )}
                </div>
            </li>
            {openSubMenu && <li>{children}</li>}
        </>
    )
}

export const MobileMenu: React.FC<MobileMenuProps> = (props) => {
    const { onClose, isOpen, logOut } = props;
    const { auth } = useSelector((state: any) => state);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const utils = useSelector((state: any) => state.utils);

    const cs = classnames({
        [styles.MobileMenu]: true,
        [styles.Open]: isOpen,
        [styles.Closed]: !isOpen
    });

    const iconMapper = useMemo(() => ({
        profile: <Icons.ProfileCard />,
        declarations: <Icons.Decl />,
        revenues: <Icons.Income />,
        expenses: <Icons.WalletExpenses />,
        payments: <Icons.CreditCard />,
        notifications: <Icons.Notification />
    }), []);

    const handleNavigate = (path: string, disableMenuForWithoutTin?: boolean) => {
        return (event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            onClose?.(event as unknown as React.MouseEvent<HTMLButtonElement>);
            if (disableMenuForWithoutTin && !auth.tin) {
                dispatch(toggleDisableOpenMenu(true));
                return;
            }
            if (disableMenuForWithoutTin && auth.tin && !utils.tinIsValid) {
                dispatch(toggleNotifyTinIsInValid(true));
                return;
            }
            navigate(path);
        }
    };

    const navigation = useMemo(() => navigations.filter((nav) => nav.roles?.includes(auth.role)), [auth.role]);
    
    return (
        <div className={cs}>
            <div className={styles.User} onClick={handleNavigate("/profile")}>
                <span className={styles.UserIcon}><Icons.User /></span>
                <span>{auth?.username}</span>
            </div>
            <Button 
                className={styles.CloseBtn} 
                variant="text"
                onClick={onClose}
            >
                <Icons.Close />
            </Button>
            <ul className={styles.NavListMobile}>
                {navigation.map((item) => (
                    <MobileMenuItem 
                        title={t(item.translateKey)} 
                        icon={iconMapper[item.translateKey as keyof typeof iconMapper]} 
                        key={item.translateKey}
                        onNavigate={handleNavigate(item.path, item.disableMenuForWithoutTin)}
                    >
                        {!!item.subMenu.length && (
                            <ul className={styles.SubList}>
                                {item.subMenu.map((subMenu) => (
                                    <MobileMenuItem 
                                        onNavigate={handleNavigate(subMenu.path, subMenu.disableMenuForWithoutTin)}
                                        key={subMenu.translateKey} 
                                        title={t(subMenu.translateKey)}
                                    />
                                ))}
                            </ul>
                        )}
                    </MobileMenuItem>
                ))}
            </ul>
            <ul className={styles.NavListMobile}>
                <MobileMenuItem 
                    title={t("help")} 
                    icon={<Icons.Help />}
                >
                    <ul className={styles.SubList}>
                        {helps.map((helpItem) => (
                            <MobileMenuItem 
                                key={helpItem.translationKey} 
                                title={t(helpItem.translationKey)}
                                onNavigate={(event) => {
                                    onClose?.(event as unknown as React.MouseEvent<HTMLButtonElement>);
                                    dispatchActionsEvent({ type: helpItem.translationKey, payload: helpItem.translationKey });
                                }}
                            />
                        ))} 
                    </ul>
                </MobileMenuItem>
            </ul>
            <div className={styles.Language}>
                <Languages mode="linear"/>
            </div>
            <Button variant="link" className={styles.LogOut} onClick={logOut}>
                <span>
                    <Icons.LogOut />
                </span>
                <span>{t("exit")}</span>
            </Button>
        </div>
    )
}