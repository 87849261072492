import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Container } from '../../Layouts/Container';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import { Wrapper } from '../../Layouts/Wrapper';
import * as Icons from "../../components/Icons";
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Table, TableHead } from '../../components/Table';
import { Pagination } from '../../components/Pagination';
import {
    classnames,
    formatDate,
    getSocialCostTypes,
    getInvoiceStatusTypes,
    getSocialCostList,
    getSocialCostTotal,
    getSocialCostPDF,
    getSupplierList,
    formatNumberWithCommas,
    OS,
    getSearchParams
} from '../../utils';
import { ExpensesCard } from '../../components/Card';
import { FilterBox } from '../../components/FilterBox';
import { RootState } from '../../store';
import { DateMode } from '../../components/DatePicker';
import Loading from '../../components/Loading';
import { Empty } from '../../components/Empty';
import { initializeRows, initializeTotal, changePage as changePageAction } from '../../store/expensesSlice';
import { Tooltip } from '../../components/Tooltip';
import * as BridgeActions from "../../utils/bridge_actions";

export const path: string = 'expenses';

const renderRowActions = (rowData: any, params: any) => {
    return (
        <div className={styles.ActionGroup}>
            <Tooltip
                title={params.title}
                type='info'
                display='inline'
                followTheCursor
            >
                <span onClick={() => params?.downloadPDF?.(rowData)} className='action-item'>
                    <Icons.PDF />
                </span>
            </Tooltip>
        </div>
    );
};

const ExpensesPage = () => {
    const { t, i18n } = useTranslation();
    const { rows, total, currentPage, pageSize } = useSelector((state: RootState) => state.expenses);
    const [supplierTins, setSupplierTins] = useState<{ tin: string, name: string }[]>([]);
    const dispatch = useDispatch();
    const handleInputChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, pattern } = ev.target;
        switch (name) {
            case 'invoiceNumber':
                if (value && pattern && !(new RegExp(pattern).test(value))) {
                    return;
                }
                break;
            default:
        }
        setFilterFields(prev => ({ ...prev, [name]: { ...prev[name as keyof typeof prev], value } }));
    }, []);

    const handleSelectChange = useCallback(
        (data: { label: string, value: string }, opt: { action: string, name: string }) =>
                    setFilterFields(prev => ({ ...prev, [opt.name]: { ...prev[opt.name as keyof typeof prev], value: data } })),
        []
    );

    const handleIssuedDateRange = useCallback((value: any) => setFilterFields(prev => ({ ...prev, supplyYear: { ...prev.supplyYear, value } })), []);

    const supplierTinInputChange = (tin: string, opt: any, tinList: any) => {
        if (tin) {
            const r = new RegExp(filterFields.supplierTin.pattern);
            if (!r.test(tin) || tin.length > filterFields.supplierTin.maxLength) {
                return opt.prevInputValue;
            }
        }
        setFilterFields((prevState: any) => ({
            ...prevState,
            supplierTin: {
                ...prevState.supplierTin,
                options: tin.length >= 2 ? tinList : [],
                noOptionsMessageKey: tin.length >= 2 ? 'dataNotFound' : '-',
                errorMessage: tin ? prevState.supplierTin.errorMessage : ''
            }
        }));
        return tin;
    }

    const supplierTinValueChange = (data: { label: string, value: string }, opt: { action: string, name: string }) => {
        setFilterFields(prevState => {
            const newState = {
                ...prevState,
                supplierTin: {
                    ...prevState.supplierTin,
                    value: data
                }
            };
            if (opt.name === 'supplierTin' && !data?.value) {
                // @ts-ignore
                newState.supplierTin.errorMessage = '';
            }
            return newState;
        });
    }

    const [socialCostTypes, setSocialCostTypes] = useState<any[]>([]);
    const [docStatusTypes, setDocStatusTypes] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstOpen, setFirstOpen] = useState<boolean>(true);
    const [reset, setReset] = useState(false);
    const [filterFields, setFilterFields] = useState({
        socialCostType: {
            value: '' as any,
            type: 'select',
            onChange: handleSelectChange,
            onInputChange: () => {},
            options: [],
            isClearable: true,
            placeholder: 'any'
        },
        supplierTin: {
            value: '' as any,
            type: 'select',
            onChange: supplierTinValueChange,
            onInputChange: (t: string, o: any) => supplierTinInputChange(t, o, supplierTins),
            maxLength: 8,
            pattern: '^[0-9]+$',
            options: [],
            isClearable: true,
            noDropdown: true,
            noOptionsMessageKey: '-',
            selectDuringOnBlur: true,
            selectDuringOnBlurOptions: { name: 'supplierTin' },
            anyValue: true
        },
        buyerPsn: {
            value: '',
            type: 'text',
            onChange: handleInputChange,
            maxLength: 10
        },
        invoiceNumber: { value: '', type: 'text', onChange: handleInputChange, maxLength: 11, pattern: '^([A-Z]{1})([0-9]{0,})$' },
        supplyYear: {
            value: {} as any,
            type: 'date',
            onChange: handleIssuedDateRange,
            mode: DateMode.year,
            placeholder: 'year',
            min: new Date(2023, 0),
            max: new Date()
        },
        supplierName: { value: '', type: 'text', onChange: handleInputChange, maxLength: 100 },
        buyerName: { value: '', type: 'text', onChange: handleInputChange, maxLength: 512 },
        status: {
            value: '' as any,
            type: 'select',
            label: 'docStatus',
            onChange: handleSelectChange,
            onInputChange: () => {},
            options: [],
            isClearable: true,
            placeholder: 'any'
        }
    });
    const [filterBoxOpen, setFilterBoxOpen] = useState(false);
    const utils = useSelector((state: any) => state.utils);

    const onSearch = async (page?: number) => {
        setIsLoading(true);
        try {
            const filter = {
                socialCostType: filterFields.socialCostType.value?.value || undefined,
                supplierTin: filterFields.supplierTin.value?.value || undefined,
                buyerPsn: filterFields.buyerPsn.value || undefined,
                invoiceNumber: filterFields.invoiceNumber.value || undefined,
                supplyYear: filterFields.supplyYear.value?.getFullYear?.().toString() || undefined,
                supplierName: filterFields.supplierName.value || undefined,
                buyerName: filterFields.buyerName.value || undefined,
                status: filterFields.status.value?.value || undefined
            };
            setFilterFields(prev => ({ ...prev, supplierTin: { ...prev.supplierTin, errorMessage: '' } }));
            if (
                !firstOpen &&
                filterFields.supplierTin.value?.value &&
                filterFields.supplierTin.pattern &&
                !(new RegExp('^[0-9]{8}$').test(filterFields.supplierTin.value?.value))
            ) {
                setFilterFields(prev => ({ ...prev, supplierTin: { ...prev.supplierTin, errorMessage: 'INVALID_INPUT' } }));
            }
            const filterForList = {
                ...filter,
                currentPage: page || currentPage,
                pageSize
            }
            const filterForTotal = { ...filter };
            const [list, total] = await Promise.all([getSocialCostList(filterForList), getSocialCostTotal(filterForTotal)]);
            dispatch(initializeRows(list.data));
            dispatch(initializeTotal(total.data.value || 0));
            setFirstOpen(false);
        } catch (err: any) {
            console.log('onSearch >>>', err);
            toast.error(t(err.message), { position: 'top-right', theme: 'colored' });
        } finally {
            setIsLoading(false);
        }
    }

    const onNewSearch = () => {
        setFilterFields(prev => ({
            ...prev,
            socialCostType: { ...prev.socialCostType, value: '' },
            supplierTin: { ...prev.supplierTin, value: '' },
            buyerPsn: { ...prev.buyerPsn, value: '' },
            invoiceNumber: { ...prev.invoiceNumber, value: '' },
            supplyYear: { ...prev.supplyYear, value: {} },
            supplierName: { ...prev.supplierName, value: '' },
            buyerName: { ...prev.buyerName, value: '' },
            status: { ...prev.status, value: '' }
        }));
        setReset(true);
    }

    const changePage = (page: number) => {
        dispatch(changePageAction(page));
        onSearch(page);
    }

    const downloadPDF = (rowData: any) => {
        const { mobileView, os} = getSearchParams();
        if(!!mobileView && os === OS.android) {
            return BridgeActions.onAction({...BridgeActions.DOWNOALD_PDF, payload: { apiPath: `/api/invoices/getInvoicePdf?serialNo=${rowData.invoiceNumber}` }});
        }
        getSocialCostPDF(rowData.invoiceNumber)
            .then((res: any) => {
                const fileName = res.headers['content-disposition'].replace('attachment; filename=', '').replace(/"/g, '');
                const url = window.URL.createObjectURL(res.data);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.log('downloadPDF >>>', err);
                toast.error(err.response.status === 404 ? t('INVOICE_PDF_NOT_FOUND') : err.message, { position: 'top-right', theme: 'colored' });
            });
    }

    useEffect(() => {
        if (reset) {
            changePage(1);
            setReset(false);
        }
    }, [reset]);

    useEffect(() => {
        Promise.all([getSocialCostTypes(), getInvoiceStatusTypes()])
            .then(([types1, types2]) => {
                setSocialCostTypes(types1.data);
                setDocStatusTypes(types2.data);
            })
            .catch(err => console.log('getSocialCostTypes, getInvoiceStatusTypes => ', err));
    }, []);

    useEffect(() => {
        onSearch();
        getSupplierList()
            .then(res => {
                const OPTIONS = res.data.filter((item: any, i: number, arr: any[]) => arr.findIndex((a : any) => a.tin === item.tin) === i);
                setSupplierTins(OPTIONS);
                const options = OPTIONS.map((item: any) => ({ label: item.tin, value: item.tin }));
                setFilterFields(prevState => ({
                    ...prevState,
                    supplierTin: {
                        ...prevState.supplierTin,
                        onInputChange: (t: string, o: any) => supplierTinInputChange(t, o, options)
                    }
                }));
            })
            .catch(err => console.log('getSupplierList >>>', err));
    }, []);

    useEffect(() => {
        setFilterFields((prev) => {
            const newSocCostTypeOptions = socialCostTypes.map((type: any) => ({ value: type.code, label: type[`name_${i18n.language}`] }));
            const newDocStatusTypesOptions = docStatusTypes.map((type: any) => ({ value: type.id, label: type[`name_${i18n.language}`] }))
            prev.socialCostType.options = newSocCostTypeOptions as typeof prev.socialCostType.options;
            prev.socialCostType.value = newSocCostTypeOptions.find((option) => option.value === prev.socialCostType.value?.value) ?? "";
            prev.status.options = newDocStatusTypesOptions as typeof prev.socialCostType.options;
            prev.status.value = newDocStatusTypesOptions.find((option) => option.value === prev.status.value?.value) ?? "";

            return { ...prev };
        });
    }, [i18n.language, socialCostTypes, docStatusTypes]);

    const csSearchBox = classnames({
        [styles.SearchBox]: true,
        [styles.Open]: utils.device.mode !== "desktop" && filterBoxOpen,
        [styles.Close]: utils.device.mode !== "desktop" && !filterBoxOpen,
    })

    const tableHead: TableHead[] = useMemo(() => ([
        { key: "supplierTin", title: t("supplierTin"), colSpan: 1, className: 'fixTdCol100' },
        { key: "supplierName", title: t("supplierName"), colSpan: 2, className: 'fixTdCol300' },
        { key: "socialCostType", title: t("socialCostType"), colSpan: 2, className: 'fixTdCol200' },
        { key: "buyerPsn", title: t("buyerPsn"), colSpan: 1, className: 'fixTdCol100' },
        { key: "buyerName", title: t("buyerName"), colSpan: 1, className: 'fixTdCol300' },
        { key: "supplyDate", title: t("dateOfService"), colSpan: 1, className: 'fixTdCol100' },
        {
            key: "invoiceNumberCol",
            title: t("invoiceNumber"),
            colSpan: 1,
            className: 'fixTdCol100'
        },
        { key: "status", title: t("docStatus"), colSpan: 2, className: 'fixTdCol200' },
        { key: "money", title: t("sum"), colSpan: 1, className: 'fixTdCol150' },
        { key: "view", title: t("view"), colSpan: 1, colType: "action", colActionArgs: { downloadPDF, title: t("view") }, className: 'fixTdCol50' },
    ]), [t, rows]);

    let listView = null;
    const ROWS = rows.map(row => ({
        ...row,
        socialCostType: row[`socialCostName_${i18n.language}`],
        statusId: row.status,
        status: row[`statusName_${i18n.language}`],
        supplyDate: formatDate('DD.MM.YYYY', new Date(row.supplyDate)),
        invoiceNumberCol: row.adjustment ? <Tooltip title={t('corrective_settlement_document')} type='info'><span style={{ fontStyle: 'italic' }}>{row.invoiceNumber}</span></Tooltip> : row.invoiceNumber,
        money: !Number.isNaN(parseFloat(row.price)) ? formatNumberWithCommas(row.price) : row.price
    }));
    if (
        utils.device.mode === 'desktop'
    ) {
        listView = (
            <Table
                data={ROWS}
                theads={tableHead}
                caption={
                    <>
                        {t("total_data_count")}
                        &nbsp;
                        <span className={styles.Caption}>{total}</span>
                    </>
                }
                rowActions={renderRowActions}
                verticalSize='middle'
            />
        );
    } else {
        listView = ROWS.length ? (
            <>
                <div className={styles.mobileViewCardTotal}>
                    {t("total_data_count")}
                    &nbsp;
                    <span className={styles.Caption}>{total}</span>
                </div>
                {ROWS.map((socialCost: any, index:number) => (
                    <ExpensesCard
                        adjustment={socialCost.adjustment}
                        title={socialCost.socialCostType}
                        date={socialCost.supplyDate}
                        status={socialCost.status}
                        statusId={socialCost.statusId}
                        key={index}
                        price={socialCost.price}
                        taxpayerName={socialCost.supplierName}
                        taxpayerTin={socialCost.supplierTin}
                        psn={socialCost.buyerPsn}
                        serviceRecipient={socialCost.buyerName}
                        serialNo={socialCost.invoiceNumber}
                        downloadDocument={() => downloadPDF(socialCost)}
                    />
                ))}
            </>
        ) : <Empty title={t("dataNotFound")} />
    }

    return (
        <DashboardLayout className={styles.Expenses}>
            <Container containerSize='large' className={styles.ExpensesContainer} withoutPadding>
                <Wrapper title={t("expenses")}>
                    <FilterBox
                        mainClass={styles.SearchSection}
                        captionClass={styles.SearchSectionCaption}
                        actionsClass={styles.FilterActionsGroup}
                        bodyClass={csSearchBox}
                        filterBoxOpen={filterBoxOpen}
                        filterBoxToggle={() => setFilterBoxOpen(p => !p)}
                        fields={filterFields}
                        onSearch={() => changePage(1)}
                        onNewSearch={onNewSearch}
                        isLoading={isLoading}
                    />
                    <div className={styles.TableSection}>
                        {firstOpen && !ROWS.length ? null : listView}
                        <Pagination pageSize={pageSize} total={total} currentPage={currentPage} onChange={changePage} />
                    </div>
                </Wrapper>
                {isLoading && <Loading />}
            </Container>
        </DashboardLayout>
    );
};
export default ExpensesPage;