import { Role } from "../../../utils";

export const steps = {
    1: { id: 1, translationKey: "decl_return_amount", formId: "personalData", roles: [ Role.ROLE_NO_RESIDENT, Role.ROLE_RESIDENT ]},
    2: { id: 2, translationKey: "sr_pasport_data_step_title", formId: "taxpayer_info", roles: [ Role.ROLE_NO_RESIDENT, Role.ROLE_RESIDENT ] },
    3: { id: 3, translationKey: "sr_bank_details_step_title", formId: "bank_details", roles: [ Role.ROLE_NO_RESIDENT, Role.ROLE_RESIDENT ]  },
};

export const mapperDeclarationHelperLinks = (lng: string) => (
    {
        personalData: `/${lng}/declaration-sr-help`,
        taxpayer_info: `/${lng}/declaration-sr-help`,
        bank_details: `/${lng}/declaration-sr-help`,
        register: `/${lng}/declaration-sr-help`,
    }
)