import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from '../../hook';
import { Container } from '../../Layouts/Container';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import ReceiveTinForm from '../../components/ReceiveTinForm';
import styles from './styles.module.scss';
import * as BridgeActions from "../../utils/bridge_actions";
import { getSearchParams } from '../../utils';

export const path: string = 'receive-tin';

export default function ReceiveTin() {
    const { tin } = useSelector((state: any) => state.auth);
    const navigate = useNavigate();

    useLayoutEffect(() => {
        if (tin) {
            if(!!getSearchParams().mobileView) {
                BridgeActions.onAction(BridgeActions.GO_TO_PROFILE_PAGE);
            }
            else {
                setTimeout(() => {
                    navigate('/profile');
                }, 200);
            }
        }
    }, []);

    return (
        <DashboardLayout>
            <Container containerSize="large" className={styles.receiveTinContainer}>
                <ReceiveTinForm />
            </Container>
        </DashboardLayout>
    );
}
