import React from "react";
import * as Icons from "../Icons";
import styles from "./styles.module.scss";

export const Empty: React.FC<{ title?: string; description?: string}> = (props) => {
    const { title } = props
    return (
        <div className={styles.Empty}>
            <div className={styles.Icon}>
                <Icons.Empty />
            </div>
            {title && <div className={styles.Title}>{title}</div>}
        </div>
    )
};