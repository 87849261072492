import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Wrapper } from '../../../Layouts/Wrapper';
import { Stepper } from '../../Stepper';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import {
    Role,
    classnames,
    createDeclSDDraft,
    previewSDDeclPDF,
    getSection1Head1,
    getSection1Head2,
    getSection3,
    getSection4,
    getSection5Point5_3,
    isNotResidentUser,
    getDeclSDSubmitByID,
    getDeclSDDraftByID,
    getSection5Point5_4And5_5,
    createDeclSDSubmit,
    translationKeysByErrorCodes,
    updateDeclSDDraftByID,
    OS,
    getSearchParams
} from '../../../utils';
import { Button } from '../../Button';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { MobileIncomeTaxContext } from "./mobile_income_tax.context";
import { steps } from './constants';
import { getRootDataStore, setRootDataStore, section3Helpers, section5Helpers, section1Helpers } from './utils';
import { PersonalDataForm } from './components/PersonalDataForm';
import { FromTaxAgentForm } from './components/FromTaxAgentForm';
import { NonTaxable } from './components/NonTaxable';
import type { FormDataTypes } from "./components/Form";
import { fetchEmployeeIncomeTypes } from '../../../store/dictionarySlice';
import { FromNonTaxAgent } from './components/FromNonTaxAgent';
import { SocialCosts } from './components/SocialCosts';
import { Compensation } from './components/Compensation';
import { EndQuestion, SelfEmployee } from './components/SelfEmployee';
import { AxiosResponse } from 'axios';
import Loading from '../../Loading';
import { toast } from 'react-toastify';
import { useNavigate } from '../../../hook';
import * as Icons from "../../Icons";
import { useParams } from 'react-router-dom';
import { Question } from './components/Question';
import * as BridgeActions from "../../../utils/bridge_actions";

const calls = [
    { call: getSection1Head1, role: [ Role.ROLE_RESIDENT ], reasonPresent: [true]},
    { call: getSection1Head2, role: [ Role.ROLE_RESIDENT ], reasonPresent: [true]},
    { call: getSection3, role: [ Role.ROLE_NO_RESIDENT, Role.ROLE_RESIDENT ], reasonPresent: [true, false] },
    { call: getSection4, role: [ Role.ROLE_RESIDENT ], reasonPresent: [true] },
    { call: getSection5Point5_3, role: [ Role.ROLE_RESIDENT ], reasonPresent: [true] },
    { call: getSection5Point5_4And5_5, role: [ Role.ROLE_RESIDENT ], reasonPresent: [true] }
];

const RenderingWrapperTitle = (props: any) => {
    const { translantionKey } = props;
    const { t, i18n } = useTranslation();
    const [tKey, setTkey] = useState("show_more");

    const notCropped = useMemo(() => translantionKey === "incoming_tax_social_payment", [translantionKey]);

    useLayoutEffect(() => {
            const croppedElement = document.querySelector(`#${translantionKey}`);
            croppedElement?.parentElement?.classList.remove("ShowMore");
            setTkey("show_more");
            const cropped = (croppedElement?.scrollWidth ?? 0) > (croppedElement?.clientWidth ?? 0)
            const togglerBtn = document.querySelector(`#toggler-${translantionKey}`);
            if(!cropped) {
                togglerBtn?.classList?.add("hide");
            }
            else {
                togglerBtn?.classList?.remove("hide");
            }
    }, [translantionKey]);

    const handleToglerShow = () => {
        const croppedElement = document.querySelector(`#${translantionKey}`);
        const cropped = (croppedElement?.scrollWidth ?? 0) > (croppedElement?.clientWidth ?? 0);
        if(cropped) {
            croppedElement?.parentElement?.classList?.add("ShowMore");
            setTkey("show_less");
        }
        else {
            croppedElement?.parentElement?.classList?.remove("ShowMore");
            setTkey("show_more");
        }
    };

    const mapperDeclarationHelperLinks = useMemo(() => ({
        incoming_tax_social_payment: `/${i18n.language}/declaration-sd-help?section=personal`,
        form1_section1: `/${i18n.language}/declaration-sd-help?section=1`,
        form1_section2: `/${i18n.language}/declaration-sd-help?section=2`,
        form1_section3: `/${i18n.language}/declaration-sd-help?section=3`,
        form1_section4: `/${i18n.language}/declaration-sd-help?section=4`,
        form1_section5: `/${i18n.language}/declaration-sd-help?section=5`,
        form1_section6: `/${i18n.language}/declaration-sd-help?section=6`,
        register_declaration_wrapper: `/${i18n.language}/declaration-sd-help`
    }), [i18n.language]);

    return (
        <div className={styles.PersonalDataWrapperHead}>
            <span>
                <span className={classnames({[styles.NotCropped]: notCropped })} id={translantionKey}>{t(translantionKey)}</span>
                <Button 
                    variant='link' 
                    className={styles.More} 
                    onClick={handleToglerShow}
                    id={`toggler-${translantionKey}`}
                >
                    {t(tKey)}
                </Button>
            </span>
            <span>
                <a href={mapperDeclarationHelperLinks[translantionKey as keyof typeof mapperDeclarationHelperLinks]} target="_blank">
                    <Icons.InfoOutline />
                </a>
            </span>
        </div>
    )
}

export const MobileIncomingTax: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { id, type, declStatus } = useParams();
    const [step, setStep] = useState(1);
    const [outOfSteps, setOutOfSteps] = useState<any>(null);
    const [filteredSteps, setFilteredSteps] = useState(steps);
    const utils = useSelector((state: RootState) => state.utils);
    const auth: any = useSelector((state: RootState) => state.auth);
    const currentStep = useMemo(() => (filteredSteps[step as keyof typeof steps]), [step, filteredSteps]);
    const dispatch = useDispatch();
    const [dataLoading, setDataLoading] = useState(!!id);
    const [hideSteps, setHideSteps] = useState(false);
    const [isValidForSave, setIsValidForSave] = useState(!!getRootDataStore()?.root?.taxYear || !!getRootDataStore()?.root?.reasonCodes?.length);
    const navigate = useNavigate();
    const [stepsConfig, setStepsConfig] = useState({
        errors: Object.values(filteredSteps).filter((item) => getRootDataStore()?.[item.formId]?.error).map((item) => item.id),
        unCompleted: Object.values(filteredSteps).filter((item) => !(getRootDataStore()?.[item.formId]?.completed)).map((step) => step.id),
    });

    const handleChangeStep = (action: { value: number, type: string }) => {
        if(!!getRootDataStore().root) {
            setStep(action.value);
            setRootDataStore({ ...getRootDataStore(), step: action.value });
        }
    };

    const handleSubmit = useCallback((key: string) => {
        return (formData: FormDataTypes, error?: boolean) => {
            const root = getRootDataStore().root ?? {};
            let data: any = {};
            const reasonPresent = (formData.reasonPresent as Field<boolean>)?.value ?? root.reasonPresent;
            switch (key) {
                case "personalData":
                    data = Object.entries(formData).reduce((acc, [_, val]) => {
                        const { key, value } = val as Field<any>;
                        acc[key] = value;
                        return acc
                    }, {} as { [key: string]: DataType | undefined });
                    if(!data.taxYear) {
                        return
                    }
                    break;
                case "fromTaxAgent":
                    root.section7_1 = formData.section7_1;
                    root.section7_2 = formData.section7_2;
                    root.section7_2_added_new_items = formData?.section7_2_added_new_items ?? false;
                    break;
                case "nonTaxable":
                    root.section2 = { ...formData };
                    break;
                case "fromNonTaxAgent":
                    root.section3 =  { ...formData };
                    if(!root.section5) {
                        root.section5 = {};
                    }
                    const employerReduceAmount = root.section5.employerReduceAmount ?? 0; // 5_6;
                    const yearlyCalculatedDifference = section5Helpers.calculate5_7(root.section3.totalTaxToPaid, employerReduceAmount); // 5_7;
                    const calculateTotal = section5Helpers.calculate5_9(employerReduceAmount, yearlyCalculatedDifference, root.section4?.totalReturnAmount ?? 0); // 5_9 or 5_10;
                    root.section5.yearlyCalculatedDifference = yearlyCalculatedDifference; // 5_7
                    root.section5.taxToPaid = calculateTotal > 0 ? calculateTotal : 0; // 5_9;
                    const filed_3_23 = root.section3?.totalTaxToPaid ?? 0; // 3_23;
                    const field_5_8 = root.section4?.totalReturnAmount ?? 0; // 5_8;
                    const calculateTaxToReturn = section5Helpers.calculate5_10(filed_3_23, employerReduceAmount, field_5_8);
                    root.section5.taxToReturn = calculateTotal < 0 && filed_3_23 <= field_5_8 ? calculateTaxToReturn : 0; // 5_10
                    break;
                case "socialCosts":
                    root.section4 =  { ...formData };
                    break;
                case "compensation":
                    root.section5 =  { ...formData };
                    break;
                case "selfEmployee": 
                    root.section6 = { ...formData };
                    break;
            };
            // recalculate section5
            if(!root.section5) {
                root.section5 = {};
            }
            const employerPaidTax = section5Helpers.calculate5_2(root.section1_1?.totalTax ?? 0, root.section1_2?.totalTax ?? 0); //5_2;
            const employerReduceAmount = section5Helpers.calculate5_6(
                employerPaidTax ?? 0, root.section5?.hypotecReturnAmount ?? 0, root.section5?.tuitionFeeReturnAmount ?? 0, root.section5?.investmentReturnAmount ?? 0
            ); // 5_6;
            const yearlyCalculatedDifference = section5Helpers.calculate5_7(root.section3?.totalTaxToPaid ?? 0, employerReduceAmount); //5_7;
            const invoiceReturnAmount = root.section4?.totalReturnAmount ?? 0; //5_8;
            const calculateTotal = section5Helpers.calculate5_9(employerReduceAmount, yearlyCalculatedDifference, invoiceReturnAmount);
            const taxToPaid = calculateTotal > 0 ? calculateTotal : 0; // 5_9;
            const filed_3_23 = root.section3?.totalTaxToPaid ?? 0; // 3_23;
            const calculateTaxToReturn = section5Helpers.calculate5_10(filed_3_23, employerReduceAmount, invoiceReturnAmount);
            const taxToReturn = calculateTotal < 0 && filed_3_23 <= invoiceReturnAmount ? calculateTaxToReturn : 0; // 5_10;
            root.section5 = {
                ...root.section5,
                employerReduceAmount: !reasonPresent || isNotResidentUser(auth.role) ? undefined : employerReduceAmount,
                yearlyCalculatedDifference,
                invoiceReturnAmount,
                taxToPaid,
                taxToReturn,
                employerPaidTax: !reasonPresent || isNotResidentUser(auth.role) ? undefined : employerPaidTax
            };

            const store = { ...getRootDataStore(), [key]: { completed: true, error }, root: { ...root, ...data } };
            setRootDataStore(store);

            setStepsConfig((prev) => {
                prev.unCompleted = Object.values(filteredSteps).filter((item) => !(store[item.formId]?.completed)).map((step) => step.id);
                prev.errors = Object.values(filteredSteps).filter((item) => store[item.formId]?.error).map((item) => item.id);
                return { ...prev };
            });
        }
    }, [filteredSteps, auth.role]);

    useEffect(() => {
        if(id && !getRootDataStore()?.step) {
            let action;
            switch (declStatus) {
                case 'filled':
                    action = getDeclSDSubmitByID;
                    break;
                default:
                    action = getDeclSDDraftByID;
            }
            action(id)
                .then((response: any) => {
                    const { data } = response;
                    setFilteredSteps((prev) => {
                        return Object.entries(prev)
                            .filter(([_, currentStep]) => currentStep.reasonPresent.includes(data.reasonPresent))
                            .reduce((acc, [_, step], index) => {
                                const temp = { ...step };
                                temp.id = index + 1
                                acc[temp.id] = temp
                                return acc
                            }, {} as IDataTypes<any>) as typeof prev;
                    })
                    setRootDataStore({ root: { ...data }, personalData: { completed: true } });
                })
                .finally(() => {
                    setDataLoading(false);
                }) 
        }
        else if(id) {
            setDataLoading(false);
        }
        if(getRootDataStore()?.step) {
            setStep(getRootDataStore().step)
        }
        dispatch(fetchEmployeeIncomeTypes());
        return () => {
            if (!window.location.pathname.includes("declaration/type/sd")) {
                sessionStorage.removeItem("mobile_income_tax");
            }
        }
    }, []);

    const initData = (year: Date, reasonPresent: boolean, personalInfo?: any) => {
        sessionStorage.removeItem("mobile_income_tax");
        if(year) {
            setDataLoading(true);
            Promise
                .all(calls.filter((call) => auth.role === Role.ROLE_NO_RESIDENT ? call.role.includes(auth.role) : call.role.includes(auth.role) && call.reasonPresent.includes(reasonPresent))
                .map((call) => call?.call?.(year.getFullYear())))
                .then((responses: AxiosResponse<any, any>[]) => {
                    const data: any = { section7_2_added_new_items: false };
                    responses.map((response: AxiosResponse<any, any>, index) => {
                        if(response.request.responseURL.includes("salaryIncomeSummary")) {
                            const formatedData = response.data?.map?.((employeer: any) => ({
                                ...employeer,
                                totalIncome: employeer.totalIncome ?? (employeer.salary ?? 0) + (employeer.civilSalary ?? 0) + (employeer.allBenefits ?? 0) + (employeer.otherTaxIncome ?? 0) - (employeer.amountReduce ?? 0) 
                            }))
                            data.section1_1 = { employerDataList: formatedData, totalTax: response?.data?.reduce?.((acc: number, salary: any) => acc+= +salary?.totalIncomeTax ?? 0, 0) };
                            if(!data.section5) {
                                data.section5 = {};
                            };
                            data.section5.employerPaidTax = (data.section5.employerPaidTax ?? 0) + data.section1_1.totalTax;
                        }
                        if(response.request.responseURL.includes("yearlyIncomeSummary")) {
                            data.section1_2 = { code082: [], code072: [], ...response.data };
                            data.section1_2.totalTax = Object.values(response?.data ?? {}).flat()?.reduce?.((acc: number, income: any) => acc += +income.taxAmount, 0);
                            if(!data.section5) {
                                data.section5 = {};
                            };
                            data.section5.employerPaidTax = (data.section5.employerPaidTax ?? 0) + data.section1_2.totalTax; // [5.2]
                        }

                        // section 3 init
                        if(response.request.responseURL.includes("otherSourceIncomeSummary")) {
                            const { 
                                salary,
                                civilContractsSalary,
                                salaryTaxableIncomeReduce,
                                salaryTaxableIncome,
                                salaryTax,
                                ...rest
                            } = response.data;

                            const newSalaryTaxableIncome = salaryTaxableIncome ?? section3Helpers.calculate3_7(salary, civilContractsSalary, salaryTaxableIncomeReduce);
                            const newSalaryTax = salaryTax ?? section3Helpers.calculateTax(newSalaryTaxableIncome)
                            let totalCalculatedTax = newSalaryTax;
                            const otherCountriesReduceTax = response.data.otherCountriesReduceTax ?? 0; // 3_22
                            const newData = Object.entries(rest).reduce((acc, [fieldKey, value]) => {
                                let rate = fieldKey.match(/\d+/g)?.[0] ?? undefined;
                                if (
                                    fieldKey === "feeIncome" ||
                                    fieldKey === "realEstateRentIncome" ||
                                    fieldKey === "propertySaleIncome" ||
                                    fieldKey === "shareInvestPropSaleIncome" ||
                                    fieldKey === "royaltyIncome"
                                ) {
                                    rate = "10";
                                }
                                const newFiledKey = fieldKey?.includes?.("Income") ? fieldKey.replace("Income", "") : fieldKey;
                                const calculate = section3Helpers.calclulator(newFiledKey, value as number, rate ? +rate  : undefined);
                                if(response.data[`${newFiledKey}Rate`]) {
                                    calculate[`${newFiledKey}Rate`] = response.data[`${newFiledKey}Rate`];
                                }
                                if(response.data[`${newFiledKey}Tax`]) {
                                    calculate[`${newFiledKey}Tax`] = response.data[`${newFiledKey}Tax`];
                                }
                                totalCalculatedTax += calculate[`${newFiledKey}Tax`];
                                return { ...acc, ...calculate };
                            }, {});

                            const totalTaxToPaid = section3Helpers.calculate3_23(totalCalculatedTax, otherCountriesReduceTax)

                            data.section3 = { ...newData, ...response.data, salaryTax: newSalaryTax, salaryTaxableIncome: newSalaryTaxableIncome, totalTaxToPaid, totalCalculatedTax };
                        }

                        // section 4 init
                        if(response.request.responseURL.includes("invoiceSummary")) {
                            const { educationalCostAmount, healthEnsuranceAmount } = response.data;
                            const totalCostAmount = healthEnsuranceAmount + educationalCostAmount;
                            const healthEnsuranceReturnAmount = Math.min(healthEnsuranceAmount, 50000);
                            const educationalReturnAmount = Math.min(educationalCostAmount, 100000);
                            const totalReturnAmount = Math.min(Math.trunc(healthEnsuranceReturnAmount + educationalReturnAmount), 100000);
                            data.section4 = { educationalCostAmount, educationalReturnAmount, healthEnsuranceAmount, healthEnsuranceReturnAmount, totalCostAmount, totalReturnAmount };
                        }

                        //  section 5 init
                        if(response.request.responseURL.includes("hypotecReturnedAmount")) {
                            if(!data.section5) {
                                data.section5 = {};
                            };
                            const employerPaidTax = section5Helpers.calculate5_2(data.section1_1?.totalTax ?? 0, data.section1_2?.totalTax ?? 0)
                            const hypotecReturnAmount =  response?.data?.value ?? 0;
                            data.section5 = { hypotecReturnAmount, employerPaidTax };
                        }
                        if(response.request.responseURL.includes("selfIncome2023")) {
                            if(!data.section5) {
                                data.section5 = {};
                            };
                            const { studentSum, dividendSum } = response.data;
                            const employerReduceAmount = section5Helpers.calculate5_6(data.section5.employerPaidTax, data.section5.hypotecReturnAmount, studentSum, dividendSum); // 5_6;
                            const yearlyCalculatedDifference = section5Helpers.calculate5_7(data.section3.totalTaxToPaid, employerReduceAmount); //5_7;
                            const invoiceReturnAmount = data.section4?.totalReturnAmount ?? 0; //5_8;
                            const calculateTotal = section5Helpers.calculate5_9(employerReduceAmount, yearlyCalculatedDifference, invoiceReturnAmount);
                            const taxToPaid = calculateTotal > 0 ? calculateTotal : 0; // 5_9;
                            const filed_3_23 = data.section3.totalTaxToPaid ?? 0; // 3_23;
                            const calculateTaxToReturn = section5Helpers.calculate5_10(filed_3_23, employerReduceAmount, invoiceReturnAmount);
                            const taxToReturn = calculateTotal < 0 && filed_3_23 <= invoiceReturnAmount ? calculateTaxToReturn : 0; // 5_10;
                            data.section5 = { ...data.section5, tuitionFeeReturnAmount: studentSum ?? undefined, investmentReturnAmount: dividendSum ?? undefined, employerReduceAmount, yearlyCalculatedDifference, invoiceReturnAmount, taxToPaid, taxToReturn };

                        }
                        // init for none resident not reasonPresent
                        if((!reasonPresent || auth.role === Role.ROLE_NO_RESIDENT) && responses.length - 1 === index ) {
                            data.section1_1 = { employerDataList: [], totalTax: 0 };
                            const emptyData7_2 = section1Helpers.emptySection7_2(data.section1_2)
                            data.section1_2 = { ...emptyData7_2, totalTax: 0 };
                            if(!data.section5) {
                                data.section5 = {};
                            };
                            data.section4 = { educationalCostAmount: 0, educationalReturnAmount: 0, healthEnsuranceAmount: 0, healthEnsuranceReturnAmount: 0, totalCostAmount: 0, totalReturnAmount: 0 };
                            data.section5.yearlyCalculatedDifference = section5Helpers.calculate5_7(data.section3.totalTaxToPaid, data.section5.employerReduceAmount ?? 0);
                            data.section5.invoiceReturnAmount = 0;
                            const invoiceReturnAmount = data.section4?.totalReturnAmount ?? 0; //5_8;
                            const calculateTotal = section5Helpers.calculate5_9(data.section5?.employerReduceAmount ?? 0, data.section5?.yearlyCalculatedDifference ?? 0, data.section5?.invoiceReturnAmount ?? 0);
                            const taxToPaid = calculateTotal > 0 ? calculateTotal : 0;   // 5_9;
                            const filed_3_23 = data.section3?.totalTaxToPaid ?? 0; // 3_23;
                            const calculateTaxToReturn = section5Helpers.calculate5_10(filed_3_23, data.section5?.employerReduceAmount ?? 0, invoiceReturnAmount);
                            const taxToReturn = calculateTotal < 0 && filed_3_23 <= invoiceReturnAmount ? calculateTaxToReturn : 0; // 5_10;
                            data.section5.taxToPaid = taxToPaid; // 5_9;
                            data.section5.taxToReturn = taxToReturn; // 5_10;
                        }

                        if(auth.role === Role.ROLE_RESIDENT && responses.length - 1 === index) {
                                setFilteredSteps((prev) => {
                                    return Object.entries(steps)
                                        .filter(([_, currentStep]) => currentStep.reasonPresent.includes(reasonPresent))
                                        .reduce((acc, [_, step], index) => {
                                            const temp = { ...step };
                                            temp.id = index + 1
                                            acc[temp.id] = temp
                                            return acc
                                        }, {} as IDataTypes<any>) as typeof prev;
                                })
                        }

                        if(responses.length - 1 === index) {
                            data.section2 = {
                                f2_27: 0
                            };
                            data.section6 = { selfEmployeeSocRate: 5, selfEmployeeSocPayment: 0 };
                            data.section7_1 = { employerDataList: [], totalTax: 0 }
                            const emptyData7_2 = section1Helpers.emptySection7_2(data.section1_2)
                            data.section7_2 = { ...emptyData7_2, totalTax: 0 };
                        }
                    });
                    setRootDataStore({ root: { ...(personalInfo ?? {}), ...data } });
                })
                .finally(() => setDataLoading(false))
        }
        else {
            sessionStorage.removeItem("mobile_income_tax");
            if(auth.role === Role.ROLE_RESIDENT && Object.keys(steps).length !== Object.keys(filteredSteps).length) {
                setFilteredSteps((prev) => {
                    return Object.entries(steps)
                        .filter(([_, currentStep]) => currentStep.roles.includes(auth.role))
                        .reduce((acc, [_, step], index) => {
                            const temp = { ...step };
                            temp.id = index + 1
                            acc[temp.id] = temp
                            return acc
                        }, {} as IDataTypes<any>) as typeof prev;
                })
            }
        }
    };

    useEffect(() => {
        if(isNotResidentUser(auth.role)) {
            setFilteredSteps((prev) => {
                return Object.entries(prev)
                    .filter(([_, currentStep]) => currentStep.roles.includes(auth.role))
                    .reduce((acc, [_, step], index) => {
                        const temp = { ...step };
                        temp.id = index + 1
                        acc[temp.id] = temp
                        return acc
                    }, {} as IDataTypes<any>) as typeof prev;
            })
        }
        else if (!!getRootDataStore().root?.reasonCodes?.length) {
            setFilteredSteps((prev) => {
                return Object.entries(prev)
                    .filter(([_, currentStep]) => currentStep.reasonPresent.includes(getRootDataStore().root?.reasonPresent))
                    .reduce((acc, [_, step], index) => {
                        const temp = { ...step };
                        temp.id = index + 1
                        acc[temp.id] = temp
                        return acc
                    }, {} as IDataTypes<any>) as typeof prev;
            })
        }
    }, [auth.role])

    const csWrapper = classnames({
        [styles.MobileIncomingTaxWrapper]: true,
        [styles.FromTaxAgentWrapper]: currentStep.formId === "fromTaxAgent",
    });

    const csIncomingTax = classnames({
        [styles.MobileIncomingTax]: true,
        [styles.MobileView]: !!utils.mobileView,
        [styles.Hidden]: !!outOfSteps?.children,
        [styles.BluredLoading]: dataLoading
    });

    const saveDraft = () => {
        if(isValidForSave) {
            setHideSteps(true);
            setDataLoading(true);
            setTimeout(() => {
                const { pensionSystemParticipationStatus, taxYear, ...data} = getRootDataStore().root;
                const passData = { ...data, taxYear: new Date(taxYear).getFullYear() };
                let request = null;
                if(id && declStatus !== 'filled') {
                    request = updateDeclSDDraftByID(id, passData);
                }
                else {
                    request = createDeclSDDraft(passData);
                }
                request
                    .then(() => {
                        navigate('/declaration/draft');
                        return
                    })
                    .catch((err) => toast.error(t(err.message), { position: 'top-right', theme: 'colored' }))
                    .finally(() => {
                        setDataLoading(false);
                        setHideSteps(false)
                    });   
            }, 200);
        };
    };

    const onView = () => {
        const { pensionSystemParticipationStatus, ...root } = getRootDataStore().root;
        const { mobileView, os} = getSearchParams();
        if(!!mobileView && os === OS.android) {
            return BridgeActions.onAction({...BridgeActions.VIEW_PDF, payload: { apiPath: `/api/decl/selfdecl/previewPdf/${i18n.language}`, data: { ...root, taxYear: new Date(root.taxYear).getFullYear() } } });
        }
        previewSDDeclPDF({ lng: i18n.language, payload: { ...root, taxYear: new Date(root.taxYear).getFullYear() } })
            .then((response: AxiosResponse<any, any>) => {
                const url = URL.createObjectURL(response.data);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'preview.pdf';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
    };

    const onRegister = (adjustment?: boolean) => {
        const { taxYear, pensionSystemParticipationStatus, ...root } = getRootDataStore().root;
        setHideSteps(true);
        setDataLoading(true);
        createDeclSDSubmit(i18n.language, declStatus !== 'filled' ? id : undefined, { 
            ...root, 
            adjustment: !!adjustment, 
            taxYear: new Date(taxYear).getFullYear(),
        })
            .then((res) => {
                if (res.data.correct) {
                    if (res.data.message === 'REQUIRES_ADJUSTMENT_CONFIRMATION') {
                        setOutOfSteps(() => {
                            const newData: any = {};
                            newData.children = (
                                <Question 
                                    question={"REQUIRES_ADJUSTMENT_QUESTION"}
                                    no={() => setOutOfSteps(null)}
                                    yes={() => {
                                        setOutOfSteps(null);
                                        onRegister(true);
                                    }}
                                />
                            )
                            return newData;
                        })
                    } else {
                        navigate('/declaration/filled');
                    }
                }
            })
            .catch((err) => {
                const key = translationKeysByErrorCodes(err);
                if(key === 'BACKEND_VALIDATION_ERROR' && err.response?.data?.errors) {
                    const shows = (
                        <>
                            {err.response?.data?.errors.map((message: string) => (
                                <>
                                    <span>
                                        ● {message}
                                        <br/>
                                        <br/> 
                                    </span>
                                </>
                            ))}
                        </>
                    );
                    return toast.error(shows, { position: 'top-center', theme: 'colored', className: "toast--multiple--message" });
                }
                toast.error(t(key), { position: 'top-center', theme: 'colored' });
            })
            .finally(() => {
                setHideSteps(false);
                setDataLoading(false);
            });


    }

    const handleNext = () => {
        handleChangeStep({ value: step + 1, type: "next" });
    };

    return (
        <MobileIncomeTaxContext.Provider value={{ outOfSteps, setOutOfSteps }}>
            {!hideSteps && (
                <div className={csIncomingTax}>
                    <Stepper 
                        steps={Object.values(filteredSteps)}
                        step={step}
                        className={styles.Stepper}
                        changeStep={handleChangeStep}
                        stepsError={stepsConfig.errors}
                        unCompletedList={stepsConfig.unCompleted}
                        highlightUncompleted={true}
                    />
                    <Wrapper className={csWrapper} title={<RenderingWrapperTitle translantionKey={currentStep.translationKey} />}>
                        {
                            {
                                "personalData": (
                                    <PersonalDataForm
                                        onSubmit={handleSubmit(steps[1].formId)}
                                        initData={initData}
                                        next={handleNext}
                                        dataLoading={dataLoading}
                                        getValidSave={setIsValidForSave}
                                    />
                                ),
                                "fromTaxAgent": <FromTaxAgentForm onSubmit={handleSubmit(steps[2].formId)} next={handleNext} />,
                                "nonTaxable": <NonTaxable onSubmit={handleSubmit(steps[3].formId)} next={handleNext} />,
                                "fromNonTaxAgent": <FromNonTaxAgent onSubmit={handleSubmit(steps[4].formId)} next={handleNext} />,
                                "socialCosts": <SocialCosts onSubmit={handleSubmit(steps[5].formId)} next={handleNext} />,
                                "compensation": <Compensation onSubmit={handleSubmit(steps[6].formId)} next={handleNext} />,
                                "selfEmployee": <SelfEmployee onSubmit={handleSubmit(steps[7].formId)} next={handleNext} />,
                                "register": <EndQuestion onView={onView} onSave={saveDraft} onRegister={onRegister}/>,
                            }[currentStep?.formId]
                        }
                        <div className={styles.BTNSection}>
                            {currentStep.formId !== "register" && (
                                <Button 
                                    variant='outlined' 
                                    className={styles.Remember} 
                                    onClick={saveDraft} 
                                    disabled={!isValidForSave}
                                >
                                    {t("remember_temporarily")}
                                </Button>
                            )}
                        </div>
                    </Wrapper>
                </div>
            )}
            {dataLoading && <Loading />}
            {!!outOfSteps?.children && (
                <div className={classnames({[outOfSteps?.className]: !!outOfSteps?.className, [styles.OutOfSteps]: true })}>
                    {outOfSteps?.children}
                </div>
            )}
        </MobileIncomeTaxContext.Provider>
    );
};