import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { generateField, generateFormGroup, getRootDataStore, section3Helpers } from '../utils';
import { Accordion } from '../../../Accordion';
import { useTranslation } from 'react-i18next';
import { Form } from './Form';
import { Button } from '../../../Button';
import { MobileIncomeTaxContext } from '../mobile_income_tax.context';
import { Question } from './Question';
import { fieldValidator, formValidator } from '../../../../utils';

const mapper = {
    sec_3_tr_row_6: { 10: ["royaltyIncome", "royaltyTax", "royaltyRate" ]},
    sec_3_tr_row_7: { 
        10: ["percent10Income", "percent10Tax", "percent10Rate" ],
        20: ["percent20Income", "percent20Tax", "percent20Rate" ],
    },
    sec_3_tr_row_8: { 10: ["feeIncome", "feeTax", "feeRate" ]},
    sec_3_tr_row_9: { 10: ["realEstateRentIncome", "realEstateRentTax", "realEstateRentRate" ]},
    sec_3_tr_row_10: { 
        5: ["divident5Income", "divident5Tax", "divident5Rate" ],
        10: ["divident10Income", "divident10Tax", "divident10Rate" ],
    },
    sec_3_tr_row_11: { 
        10: ["propertySaleIncome", "propertySaleTax", "propertySaleRate" ],
    },
    sec_3_tr_row_12: { 
        20: ["propertyDeveloperSaleIncome", "propertyDeveloperSaleTax", "propertyDeveloperSaleRate" ],
    },
    sec_3_tr_row_13: { 
        10: ["shareInvestPropSaleIncome", "shareInvestPropSaleTax", "shareInvestPropSaleRate" ],
    },
    sec_3_tr_row_14: { 
        20: ["awardsIncome", "awardsTax", "awardsRate" ],
    },
    sec_3_tr_row_15: { 
        20: ["giftIncome", "giftTax", "giftRate" ],
    },
    sec_3_tr_row_16: { 
        5: ["unpaidPassive5Income", "unpaidPassive5Tax", "unpaidPassive5Rate" ],
        10: ["unpaidPassive10Income", "unpaidPassive10Tax", "unpaidPassive10Rate" ],
        20: ["unpaidPassive20Income", "unpaidPassive20Tax", "unpaidPassive20Rate" ],
    },
    sec_3_tr_row_17: { 
        20: ["otherTaxableIncome", "otherTaxableTax", "otherTaxableRate" ],
    }
}

export const FromNonTaxAgent: React.FC<{ onSubmit: CallableFunction, next: CallableFunction }> = (props) => {
    const { onSubmit, next } = props;
    const rootSection3 = getRootDataStore().root?.section3 ?? {};
    const { t } = useTranslation();
    const [formData, setFormData] = useState<IDataTypes<FormGroup>>({});
    const [salaryTaxableIncome, setSalaryTaxableIncome] = useState<IDataTypes<Field<any> | FormGroup>>({
        salary: generateField<string>("salary", rootSection3?.salary, { label: "sec_3_tr_row_2", withComma: true }),
        civilContractsSalary: generateField<string>("civilContractsSalary", rootSection3?.civilContractsSalary, { label: "sec_3_tr_row_3", withComma: true }),
        salaryTaxableIncomeReduce: generateField<string>(
            "salaryTaxableIncomeReduce",
            rootSection3?.salaryTaxableIncomeReduce,
            { 
                label: "sec_3_tr_row_4",
                withComma: true,
                validation: { max: section3Helpers.maxValidator3_6(rootSection3?.salary, rootSection3?.civilContractsSalary), maxError: "error_message_in_3_6" }
            }
        ),
        total: generateFormGroup("total", t("sec_3_tr_row_5"), { 
            salaryTaxableIncome: generateField<number>("salaryTaxableIncome", rootSection3?.salaryTaxableIncome, { label: "form1_section2_income", withComma: true, validation: { disabled: true } }),
            salaryTax: generateField<number>("salaryTax", rootSection3?.salaryTax, { prefix: "20%", label: "totalIncomeTax", withComma: true, validation: { disabled: true } }),
        })
    });
    const [total, setTotal] = useState<IDataTypes<Field<number>>>({
        totalCalculatedTax: generateField<number>(`totalCalculatedTax`, rootSection3?.totalCalculatedTax, { type: "string", isValid: false, validation: { isRequired: true, disabled: true }, label: `sec_3_tr_row_18`, withComma: true }),
        otherCountriesPaidTax: generateField<number>(`otherCountriesPaidTax`, rootSection3?.otherCountriesPaidTax, { type: "string", isValid: false, validation: { isRequired: false }, label: `sec_3_tr_row_19`, withComma: true }),
        otherCountriesReduceTax: generateField<number>(
            `otherCountriesReduceTax`,
            rootSection3?.otherCountriesReduceTax,
            { 
                type: "string", 
                isValid: false, 
                validation: { 
                    isRequired: false, 
                    maxError: "error_message_in_3_22", 
                    max: Math.trunc(rootSection3?.totalCalculatedTax)
                }, 
                label: `sec_3_tr_row_20`, 
                withComma: true 
            }
        ),
        totalTaxToPaid: generateField<number>(`totalTaxToPaid`, rootSection3?.totalTaxToPaid, { type: "string", isValid: false, validation: { isRequired: true, disabled: true }, label: `sec_3_tr_row_21`, withComma: true })
    });
    const context = useContext(MobileIncomeTaxContext);
    const refData = useRef({ 
        total: { data: total, isValid: true },
        salaryTaxableIncome: { data: salaryTaxableIncome, isValid: true },
        formData: { data: formData, isValid: true }
    })

    const handleSubmit = () => {
        const cleanTotalData = Object.entries(refData.current.total.data).reduce((acc, [_, field]) => {
            acc[field.key] = field.value
            return acc
        }, {} as IDataTypes<any>);

        const cleanFormData = Object.entries(refData.current.formData.data).reduce((acc, [_, percents]) => {
            Object.values(percents).map((formGroup: FormGroup) => {
                Object.entries(formGroup.fields).map(([_, field]) => {
                    acc[field.key] = field.value
                })
            })
            return acc
        }, {} as IDataTypes<any>);

        const cleanSalaryTaxableIncome = Object.values(refData.current.salaryTaxableIncome.data).reduce((acc, field: Field<any> | FormGroup) => {
            if(field.type !== "form_group") {
                acc[(field as Field<any>).key] = (field as Field<any>).value
            }
            else {
                Object.values((field as FormGroup).fields).map((groupField: Field<any>) => {
                    acc[groupField.key] = groupField.value
                })
            }
            return acc
        }, {} as IDataTypes<any>);
        onSubmit?.({ ...cleanTotalData, ...cleanFormData, ...cleanSalaryTaxableIncome }, !(refData.current.formData.isValid && refData.current.salaryTaxableIncome.isValid && refData.current.total.isValid));
    };

    useEffect(() => {
        const newFormData = new Array(12).fill(0).reduce((acc: IDataTypes<FormGroup>, _: any, index: number) => {
            const key = `sec_3_tr_row_${index + 6}`;
            let temp = {} as any;
            if(mapper[key as keyof typeof mapper]) {
                temp = Object.entries(mapper[key as keyof typeof mapper]).reduce((acc, [prefix, fieldKeys]) => {
                    const formGroups = generateFormGroup(prefix);
                    fieldKeys.map((key) => {
                        formGroups.fields[key] = generateField<number>(
                            key, 
                            key?.toLowerCase().includes("rate") ? +prefix : rootSection3?.[key], 
                            { 
                                type: "string", 
                                isValid: true, 
                                validation: { isRequired: true, disabled: key !== "otherTaxableIncome" && key.toLowerCase().includes("tax") }, 
                                label: key !== "otherTaxableIncome" && key.toLowerCase().includes("tax") ? "totalIncomeTax" : "form1_section2_income", 
                                withComma: true,
                                prefix: key !== "otherTaxableIncome" && key.toLowerCase().includes("tax") ? `${prefix}%` : undefined,
                                visibility: key?.toLowerCase().includes("rate") ? "hidden" : "show"
                            });
                    })
                    acc[prefix] = formGroups
                    return acc
                }, {} as any);
            }
            acc[key] = temp as FormGroup
            return acc
        }, {});

        setFormData(newFormData);

        if(!getRootDataStore()?.fromNonTaxAgent?.completed) {
            context?.setOutOfSteps?.((prev: any) => {
                if(!prev) {
                    prev = {}
                }
                prev.children = (
                    <Question
                        question={"have_non_tax_income"}
                        yes={() => context?.setOutOfSteps?.(null)}
                        no={() => {
                            next();
                            context?.setOutOfSteps?.(null);
                        }}
                    />
                )
                return { ...prev }
            })
        }

        return () => {
            if (window.location.pathname.includes("declaration/type/sd")) {
                handleSubmit();
            }
        }
    }, []);

    const handleChange = (sectionKey: any) => {
        return (arg: any) => {
            setFormData((prev) => {
                const { formGroupKey, key, value } = arg;
                const taxKey = key.replace("Income", "Tax");
                const section = prev[sectionKey];
                const formGroup = section?.[formGroupKey as keyof typeof section] as unknown as FormGroup;
                if (formGroup) {
                    formGroup.fields[key].value = value;
                    formGroup.fields[taxKey].value = section3Helpers.calculateTax(value, formGroupKey);

                }
                return { ...prev }
            })
        }
    };

    const handleSalaryTaxableChange = (arg: any) => {
        const { key, value, formGroupKey } = arg;
        if(!formGroupKey) {
            setSalaryTaxableIncome((prev) => {
                (prev[key] as Field<any>).value = value;
                const salaryTaxableIncome = section3Helpers.calculate3_7(
                    (prev.salary as Field<number>).value,
                    (prev.civilContractsSalary as Field<any>).value,
                    (prev.salaryTaxableIncomeReduce as Field<any>).value
                );
                ((prev.salaryTaxableIncomeReduce as Field<number>).validation as any).max = section3Helpers.maxValidator3_6(
                    (prev.salary as Field<number>).value,
                    (prev.civilContractsSalary as Field<any>).value
                );
                const { fieldsErrorTrigger } = formValidator(prev);
                Object.entries(prev).map(([key, value]) => {
                    if(key !== "total") {
                        (value as Field<number>).error = fieldsErrorTrigger[key]?.message || fieldsErrorTrigger[key]?.errorKey;
                    };
                });

                (prev.total as FormGroup).fields.salaryTaxableIncome.value = salaryTaxableIncome;
                (prev.total as FormGroup).fields.salaryTax.value = section3Helpers.calculateTax(salaryTaxableIncome);
                return { ...prev }
            })
        }
    };
    const handleChangeTotal = (arg: any) => {
        const { key, value } = arg;
        if(!total[key].validation?.disabled) {
            setTotal((prev) => {
                if(!isNaN(value) || value === undefined) {
                    prev[key].value = value ? +value : undefined;
                }
                const totalCalculatedTax = prev.totalCalculatedTax.value ?? 0;
                const otherCountriesReduceTax = prev.otherCountriesReduceTax.value ?? 0;
                (prev.otherCountriesReduceTax.validation as any).max = totalCalculatedTax;
                Object.entries(prev).map(([_, field]) => {
                    const fielValidator = fieldValidator(field);
                    field.error = fielValidator.triggerError.message ?? fielValidator.triggerError.errorKey;
                })
                prev.totalTaxToPaid.value = section3Helpers.calculate3_23(totalCalculatedTax, otherCountriesReduceTax); // 3_23;
                
                const { isValid } = formValidator(prev);
                refData.current.total.data = prev;
                refData.current.total.isValid = !isValid;

                return { ...prev }
            })
        }
    };

    useEffect(() => {
        const totalTaxList = Object.values(formData)
            .reduce((acc, income) => {
                Object.values(income).map((form_group: FormGroup) => {
                    Object.values(form_group.fields)
                        .map((field: Field<any>) => {
                            if(field.key.includes("Tax") && field.visibility !== "hidden" && field.validation?.disabled) {
                                acc[field.key as string] = field.value ?? 0
                            }
                        })
                })
                return acc
            }, { salaryTax: (salaryTaxableIncome.total as FormGroup).fields.salaryTax.value ?? 0 } as any);

        setTotal((prev) => {
            prev.totalCalculatedTax.value = section3Helpers.calculate3_20Total_Tax(totalTaxList); // calculate 3_20
            const otherCountriesReduceTax = prev.otherCountriesReduceTax.value ?? 0; // 3_22
            (prev.otherCountriesReduceTax.validation as any).max = prev.totalCalculatedTax.value;

            Object.entries(prev).map(([_, field]) => {
                const fielValidator = fieldValidator(field);
                field.error = fielValidator.triggerError.message ?? fielValidator.triggerError.errorKey;
            });
            
            prev.totalTaxToPaid.value = section3Helpers.calculate3_23(prev.totalCalculatedTax.value, otherCountriesReduceTax); // 3_23;
            const { isValid } = formValidator(prev);
            refData.current.total.data = prev;
            refData.current.total.isValid = isValid;
            return { ...prev }
        });

        const salaryTaxableIncomeValidator = formValidator(salaryTaxableIncome);
        refData.current.formData.data = formData;
        refData.current.salaryTaxableIncome = { data: salaryTaxableIncome, isValid: salaryTaxableIncomeValidator.isValid };

    }, [JSON.stringify(formData), JSON.stringify(salaryTaxableIncome)])

    return (
        <div className={styles.FromNonTaxAgent}>
            <div className={styles.AccordionWrapper}>
                <h5 className={styles.Section1Title}>{t("sec_3_tr_row_1")}</h5>
                <Accordion header={`1-${t("sec_3_tr_row_5")}`} className={styles.Accordion}>
                    <Form 
                        data={salaryTaxableIncome}
                        onChange={handleSalaryTaxableChange}
                        prefix={(key: any) => <>{key !== "salaryTax" && <span>{t("dram")}</span>}</>}
                    />
                </Accordion>
                {Object.entries(formData).map(([sectionKey, sectionData]) => (
                    <Accordion header={t(sectionKey)} key={sectionKey} className={styles.Accordion}>
                        <Form data={sectionData as any} onChange={handleChange(sectionKey)}/>
                    </Accordion>
                ))}
            </div>
            <div className={styles.Total}>
                <Form data={total} onChange={handleChangeTotal}/>
            </div>
            <Button className={styles.NextBtn} onClick={() => next()}>{t("next")}</Button>
        </div>
    );
};