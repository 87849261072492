import { headOneOrdering, mapperFieldLabels, mapperFormGroupTitle, headTwoCodeOrdering } from "./constants";

export const getRootDataStore: any = () => JSON.parse(sessionStorage.getItem("mobile_income_tax") ?? `{}`);
export const setRootDataStore: any = (data: any) => sessionStorage.setItem("mobile_income_tax", JSON.stringify(data ?? {}));

export const generateField = <T>(
        key: string, value: T | undefined,
        config: { 
            type?: string, 
            mode?: string, 
            validation?: Validation, 
            isValid?: boolean, 
            withoutLabel?: boolean, 
            label?: string, 
            options?: any[], 
            error?: string,
            warning?: string,
            prefix?: any,
            withComma?: boolean,
            visibility? : "hidden" | "show"
        }) => (
    { 
        value,
        isValid: config.isValid ?? false,
        key, 
        type: config.type ?? "string", 
        mode: config.mode, 
        validation: config.validation ?? {}, 
        withoutLabel: config.withoutLabel, 
        label: config?.label, 
        options: config?.options ?? [],
        error: config.error,
        warning: config.warning,
        prefix: config.prefix,
        withComma: config.withComma,
        visibility: config.visibility
    } as Field<T>
  );

export const generateFormGroup = (key: string, title?: string, fields = {}) => (
    { title, type: "form_group", fields, key } as FormGroup
);

export const generateForm = (data: any, mapperFormGroupTitle: any, new_income = false, mapperFieldLabels = {}) => {
    return data.map((employer: any) => {
        return Object.entries(employer)
            .sort(([key1], [key2]) => headOneOrdering[key1 as keyof typeof headOneOrdering] - headOneOrdering[key2 as keyof typeof headOneOrdering])
            .reduce((acc, [key, value]) => {
                const field = generateField<string>(key, value as string, { validation: { disabled: true }, label: mapperFieldLabels[key as keyof typeof mapperFieldLabels] });
                switch (key) {
                    case "tin":
                    case "taxpayerName":
                        acc[key] = field;
                        break;
                    default :
                        const form_group = acc[key] ? acc[key] as FormGroup : generateFormGroup(key, mapperFormGroupTitle[key as keyof typeof mapperFormGroupTitle]);
                        form_group.fields[key] = { ...field, withoutLabel: true, withComma: true };
                        if (key === "totalIncome") {
                            form_group.fields[key].withoutLabel = undefined;
                            form_group.fields[key].label = mapperFormGroupTitle[key as keyof typeof mapperFormGroupTitle];
                            form_group.title = "section1Head1TypesOfIncome_6"
                        }
                        acc[key] = form_group;
                        break
                }
                return acc
            }, { new_income } as IDataTypes<Field<DataType> | FormGroup | boolean>)
    });
};

export const getFormatedSection7_2 = () => {
    return Object.entries(getRootDataStore()?.root?.section7_2 ?? {}).reduce((acc: any, [incomeKey, employeers]: [string, any]) => {
        if(incomeKey.includes("code")) {
            employeers?.map((employeer: any) => {
                const checkDontAgree = getRootDataStore()?.root?.section1_2[incomeKey]?.find((emp: any) => emp.tin === employeer.tin);
                if(!!checkDontAgree) {
                    if(!acc.dont_agree[incomeKey]) {
                        acc.dont_agree[incomeKey] = {}
                    };
                    acc.dont_agree[incomeKey][employeer.tin] = employeer
                }
                else {
                    if(!acc.new_income[incomeKey]) {
                        acc.new_income[incomeKey] = {}
                    };
                    acc.new_income[incomeKey][employeer.tin] = employeer
                }
                return employeer
            })
        }
        return acc
    }, { dont_agree: {}, new_income: {}});
};

export const generateSection1_2RootForm = (data?: any, isNew = false) => {
    return (
        Object.entries(data ?? getRootDataStore()?.root?.section1_2 ?? {})
                .filter(([key, _]) => key?.includes("code"))
                .sort(([key1], [key2]) => (
                    (headTwoCodeOrdering[key1 as keyof typeof headTwoCodeOrdering] ?? 0) - (headTwoCodeOrdering[key2 as keyof typeof headTwoCodeOrdering] ?? 0 )
                ))
                .reduce((acc: any, [key, incomes]) => {
                    if (!acc[key as keyof typeof acc]) {
                        acc[key] = {}
                    }
                    (incomes as any)?.map?.((employer: any) => {
                        const formData = Object.entries(employer).reduce((acc: any, [key, data]) => {
                            const formGroup =  generateFormGroup(key, mapperFormGroupTitle[key as keyof typeof mapperFormGroupTitle] ?? key);
                            const field = generateField<string>(key, data as string, { validation: { isRequired: true, disabled: true }, withoutLabel: true, withComma: key == "incomeAmount" || key === "taxAmount"});
                            formGroup.fields[key] = field;
                            acc[key] = formGroup
                            return acc;
                        }, { new_income: isNew });
                        acc[key][employer.tin] = formData;
                        return formData             
                    });
                    return acc
                }, {})
    )
};

export const getFormatedSection7_1 = () => {
    return (
        (getRootDataStore()?.root?.section7_1?.employerDataList ?? [])?.reduce((acc: any, item: any) => {
            const checkDontAgree = getRootDataStore()?.root?.section1_1?.employerDataList?.find((employer: any) => employer.tin === item.tin);
            if(!!checkDontAgree) {
                acc.dont_agree[checkDontAgree.tin] = item;
            }
            else {
                acc.new_income[item.tin] = item;
            }
            return acc
        }, { dont_agree: {}, new_income: {}})
    )
};

export const generateSection1_1RootForm = () => {
    return generateForm(getRootDataStore()?.root?.section1_1?.employerDataList ?? [], mapperFormGroupTitle, false, mapperFieldLabels);
};

export const section1Helpers = {
    emptySection7_2: (section1_2: any) => {
        return Object.keys(section1_2 ?? headTwoCodeOrdering).reduce((acc, code) => {
            if(code.includes("code")) {
                acc[code] = []
            }
            return acc
        }, {} as any);
    }
}

export const section3Helpers = {
    calclulator: (fieldKey = "royalty", num: number, rate = 20) => (
        { [`${fieldKey}Income`]: num, [`${fieldKey}Tax`]: Math.trunc((num ?? 0) * rate / 100), [`${fieldKey}Rate`]: rate }
    ),
    maxValidator3_6: (d3_4: any, d3_5: any) => { //  հարկման բազայի նվազեցման գումար (կամավոր կենսաթոշակային վճարների գումար)
        const number_d3_4 = !isNaN(d3_4) ? Math.trunc(d3_4) : 0;
        const number_d3_5 = !isNaN(d3_5) ? Math.trunc(d3_5) : 0;
        const sum = number_d3_4 + number_d3_5;
        // ((տող [3.4] + տող [3.5]) - ([3.4] + [3.5]) / 1,05) formula
        return Math.trunc(sum - sum / 1.05);
    },
    calculate3_7: (d3_4?: any, d3_5?: any, d3_6?: any) => { // հարկվող եկամուտ
        const number_d3_4 = !isNaN(d3_4) ? Math.trunc(d3_4) : 0;
        const number_d3_5 = !isNaN(d3_5) ? Math.trunc(d3_5) : 0;
        const number_d3_6 = !isNaN(d3_6) ? Math.trunc(d3_6) : 0;
        return Math.trunc(number_d3_4 + number_d3_5 - number_d3_6);
    },
    calculateTax: (value?: any, rate = 20) => {
        const number_Val = !isNaN(value) ? Math.trunc(value) : 0;
        const number_Rate = !isNaN(rate) ? Math.trunc(rate) : 0;
        return Math.trunc(number_Val * number_Rate / 100)
    },
    calculate3_20Total_Tax: (taxList: { [key: string]: number }) => {
        const totalTax = Object.values(taxList).reduce((acc, item) => acc+= item, 0);
        return Math.trunc(totalTax);
    },
    calculate3_23: (d3_20?: any, d3_22?: any) => {
        const number_d3_20 = !isNaN(d3_20) ? Math.trunc(d3_20) : 0;
        const number_d3_22 = !isNaN(d3_22) ? Math.trunc(d3_22) : 0;
        return Math.trunc(number_d3_20 - number_d3_22);
    },
    calculateTotal_Income: (section3: any) => {
        const d_3_4 = section3?.salary ?? 0;
        const d_3_5 = section3?.civilContractsSalary ?? 0; 
        const d_3_8 = section3?.royaltyIncome ?? 0;
        const d_3_9_1 = section3?.percent10Income ?? 0;
        const d_3_9_2 = section3?.percent20Income ?? 0;
        const d_3_10 = section3?.feeIncome ?? 0;
        const d_3_11 = section3?.realEstateRentIncome ?? 0;
        const d_3_12_1 = section3?.divident5Income ?? 0;
        const d_3_12_2 = section3?.divident10Income ?? 0;
        const d_3_13 = section3?.propertySaleIncome ?? 0;
        const d_3_14 = section3?.propertyDeveloperSaleIncome ?? 0;
        const d_3_15 = section3?.shareInvestPropSaleIncome ?? 0;
        const d_3_16 = section3?.awardsIncome ?? 0;
        const d_3_17 = section3?.giftIncome ?? 0;
        const d_3_18_1 = section3?.unpaidPassive5Income ?? 0;
        const d_3_18_2 = section3?.unpaidPassive10Income ?? 0;
        const d_3_18_3 = section3?.unpaidPassive20Income ?? 0;
        const d_3_19 = section3?.otherTaxableIncome ?? 0;
        return Math.trunc(d_3_4 + d_3_5 + d_3_8 + d_3_9_1 + d_3_9_2 + d_3_10 + d_3_11 + d_3_12_1 + d_3_12_2 + d_3_13 + d_3_14 + d_3_15 + d_3_16 + d_3_17 + d_3_18_1 + d_3_18_2 + d_3_18_3 + d_3_19)
    }
};

export const section5Helpers = {
    calculate5_2: (d_1_1_11: any, d_1_2_17: any) => {
        const digits_d_1_1_11 = !isNaN(d_1_1_11) ? +d_1_1_11 : 0;
        const digits_d_1_2_17 = !isNaN(d_1_2_17) ? +d_1_2_17 : 0;
        return Math.trunc(digits_d_1_1_11 + digits_d_1_2_17); 
    },
    calculate5_6: (d5_2: any, d5_3: any, d5_4: any, d5_5: any) => {
        const digits_5_2 = !isNaN(d5_2) ? +d5_2 : 0;
        const digits_5_3 = !isNaN(d5_3) ? +d5_3 : 0;
        const digits_5_4 = !isNaN(d5_4) ? +d5_4 : 0;
        const digits_5_5 = !isNaN(d5_5) ? +d5_5 : 0;
        const sum = Math.trunc(digits_5_2 - digits_5_3 - digits_5_4 - digits_5_5)
        return sum < 0 ? 0 : sum;
    },
    calculate5_7: (d3_23: any, d5_6:any) => {
        const digits_3_23 = !isNaN(d3_23) ? +d3_23 : 0;
        const digits_5_6 = !isNaN(d5_6) ? +d5_6 < 0 ? 0 : +d5_6 : 0;

        return Math.trunc(digits_3_23 + digits_5_6);
    },
    calculate5_9: (d5_6: any, d5_7: any, d5_8: any) => {
        const digit_5_6 = !isNaN(d5_6) ? +d5_6 < 0 ? 0 : +d5_6 : 0;
        const digit_5_7 = !isNaN(d5_7) ? +d5_7 : 0;
        const digit_5_8 = !isNaN(d5_8) ? +d5_8 : 0;

        return Math.trunc(digit_5_7 - digit_5_8 - digit_5_6)
    },
    calculate5_10: (filed_3_23: any, field_5_6: any, field_5_8: any) => {
        const digit_3_23 = !isNaN(filed_3_23) ? +filed_3_23 : 0;
        const digit_5_6 = !isNaN(field_5_6) ? +field_5_6 : 0;
        const digit_5_8 = !isNaN(field_5_8) ? +field_5_8 : 0;
        return (
            Math.trunc(
                Math.min(digit_5_8 - digit_3_23, digit_5_6)
            )
        )
    },
};

export const section6Helpers = {
    section6_1Validator: (section3: any, section2: any, value?: any) => {
        const calculateSection3TotalIncome = section3Helpers.calculateTotal_Income(section3); 
        const sec2_f2_27 = section2?.f2_27 ?? 0;
        const sum = Math.trunc(calculateSection3TotalIncome + sec2_f2_27);
        return { sum, isValid: value ? Math.trunc(value) < sum : undefined };
    }
};