import React, { useRef } from 'react';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import * as Icons from "../Icons";

interface SelectProps {
    options: { label: string, value: any }[];
    onChange?: any;
    onInputChange?: any;
    value?: any;
    name?: string;
    placeholder?: any;
    label?: string | React.ReactNode;
    labelIcon?: (t: Function) => React.ReactNode;
    noOptionsMessageKey?: string;
    errorMessage?: string;
    isSearchable?: boolean;
    isDisabled?: boolean;
    isClearable?: boolean;
    isMulti?: boolean;
    noDropdown?: boolean;
    styles?: any;
    labelVisibilityHidden?: boolean;
    selectDuringOnBlur?: boolean;
    selectDuringOnBlurOptions?: any;
    fieldType?: string;
    componentStyle?: any;
    maxMenuHeight?: number;
    anyValue?: boolean;
}

export const Select: React.FC<SelectProps> = (props) => {
    const { t } = useTranslation();
    const onChangeTriggered = useRef(false);
    const onChangeSelectBox = (...args: any[]) => {
        onChangeTriggered.current = true;
        props.onChange?.(...args);
        window.setTimeout(() => {
            onChangeTriggered.current = false;
        }, 10);
    }
    return (
        <div className={styles.Select} style={props.componentStyle || {}}>
            {props.label && (
                <span className={`${styles.Label} ${props.labelVisibilityHidden ? styles.labelVisibilityHidden : ''}`}>
                    {props.label} {props.labelIcon?.(t)}
                </span>
            )}
            <ReactSelect
                onChange={onChangeSelectBox}
                onInputChange={props.onInputChange || (() => null)}
                options={props.options}
                placeholder={props.placeholder || ''}
                value={props.value}
                name={props.name}
                maxMenuHeight={props.maxMenuHeight}
                noOptionsMessage={() => props.noOptionsMessageKey === '-' ? null : t(props.noOptionsMessageKey || 'isEmpty')}
                components={
                    props.noDropdown ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : {}
                }
                isSearchable={typeof props.isSearchable === 'boolean' ? props.isSearchable : true}
                isDisabled={props.isDisabled || false}
                isClearable={props.isClearable || false}
                isMulti={props.isMulti}
                menuPortalTarget={document.body}
                styles={{
                    control: (s: any, state) => ({
                        ...s,
                        fontSize: 14,
                        border: state.isFocused ? "1px solid var(--oliveGreen)" : "1px solid var(--grayBorder)",
                        boxShadow: state.isFocused ? "0px 0px 5px 0px rgba(0, 0, 0, 0.2509803922)" : "none",
                        transition: "none",
                        ":hover": {
                            ...s[":hover"],
                            borderColor: state.isFocused ? "var(--oliveGreen)" : "var(--grayBorder)",
                        }
                    }),
                    menuPortal: base => ({ ...base, zIndex: 3 }), ...(props.styles || {}),
                    multiValue: (base) => ({ ...base, color: !props.isDisabled ? "var(--dark)" : "var(--gray30)", backgroundColor: "var(--blue50-transparent)" }),
                    singleValue: (base) => ({ ...base, color: !props.isDisabled ? "var(--dark)" : "var(--gray30)", fontSize: 14 }),
                    placeholder: (base) => ({ ...base, fontSize: 14, color: "var(--gray50)" }),
                    input: (base) => ({ ...base, color: !props.isDisabled ? "var(--dark)" : "var(--gray30)", fontSize: 14, margin: 0, paddingTop: 0, paddingBottom: 0 }),
                    option: ((base, state) => ({
                        ...base,
                        backgroundColor: state.isSelected ? "var(--lightBlue)" : "white",
                        fontSize: 14,
                        letterSpacing: 0.4,
                        color: state.isSelected ? "white" : "var(--dark)",
                        ":hover": {
                            ...base[":hover"],
                            backgroundColor: state.isSelected ? "var(--lightBlue)" : "var(--blueTableRow)",
                            cursor: "pointer"
                        }
                    }))
                }}
                onBlur={(ev: any) => {
                    if (props.selectDuringOnBlur) {
                        if (onChangeTriggered.current) return;
                        const typedValue: string = ev.target.value || '';
                        if (!typedValue) return;
                        let opts = props.options?.filter((item: any) => item.value === typedValue) || [];
                        if (props.fieldType === 'month') {
                            opts = props.options?.filter((item: any) => item.label.toString().toLowerCase().match(typedValue.toLowerCase()));
                        }
                        if (props.fieldType === 'month') {
                            if (opts.length === 1 && typeof props.onChange === 'function') {
                                props.onChange(opts[0], props.selectDuringOnBlurOptions);
                            }
                        } else if (props.anyValue && typeof props.onChange === 'function') {
                            props.onChange(opts[0] || { value: typedValue, label: typedValue }, props.selectDuringOnBlurOptions);
                        } else if (typedValue.match(/^[0-9]{8}$/) && opts.length === 1 && typeof props.onChange === 'function') {
                            props.onChange(opts[0], props.selectDuringOnBlurOptions);
                        }
                    }
                }}
            />
            {props.errorMessage && (
                <div className={styles.errorMessageContainer}>
                    <span className={styles.Icon}><Icons.Error /></span>
                    <span className={styles.errorMessage}>{props.errorMessage}</span>
                </div>
            )}
        </div>
    );
};
