import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getSearchParams } from '../utils';

export interface UtilsState {
    device?: { mode: "mobile" | "tablet" | "desktop", width: number, height: number };
    mobileView?: boolean;
    disableOpenMenu: boolean;
    tinIsValid: boolean;
    notifyTinIsInValid: boolean;
    globalError?: string;
}

const initialState: UtilsState = {
    device: undefined,
    mobileView: false,
    disableOpenMenu: false,
    tinIsValid: true,
    notifyTinIsInValid: false,
    globalError: ''
} as UtilsState;

export const utilsSlice = createSlice({
    name: 'utils',
    initialState,
    reducers: {
        detectDevice: (_state, action: PayloadAction<any>) => {
            return { ..._state, device: action.payload }
        },
        checkMobileView: (_state) => {
            return { ..._state, mobileView: !!getSearchParams().mobileView };
        },
        toggleDisableOpenMenu: (_state, action: PayloadAction<any>) => {
            return { ..._state, disableOpenMenu: action.payload }
        },
        setGlobalError: (_state, action: PayloadAction<string>) => {
            return { ..._state, globalError: action.payload }
        },
        setTinIsValid: (_state, action: PayloadAction<boolean>) => {
            return { ..._state, tinIsValid: action.payload }
        },
        toggleNotifyTinIsInValid: (_state, action: PayloadAction<boolean>) => {
            return { ..._state, notifyTinIsInValid: action.payload }
        }
    },
})

export const { detectDevice, checkMobileView, toggleDisableOpenMenu, setGlobalError, setTinIsValid, toggleNotifyTinIsInValid } = utilsSlice.actions;
export default utilsSlice.reducer;