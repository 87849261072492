import React from 'react';
import styles from './styles.module.scss';
import * as Icons from "../Icons";
import { useTranslation } from 'react-i18next';

export interface CardProps {
    title?: string;
    docCode: string;
    name_am: string;
    name_en: string; 
    name_ru: string;
    validityPeriod: string;
    startDate: string;
    order?: number;
    onClick?: CallableFunction
}

export const CardDeclaration: React.FC<CardProps> = (props) => {
    const { order, docCode, validityPeriod, onClick, ...rest } = props;
    const { t, i18n } = useTranslation();


    return (
        <div className={styles.CardDeclaration} onClick={() => onClick?.()}>
            <div className={styles.Title}>
                <span>
                    <Icons.DeclTypeDoc />
                </span>
                <span>
                    <span>{order}.</span>
                    <span>{props[`name_${i18n.language}` as keyof typeof rest]}</span>
                </span>
            </div>
            <div className={styles.CardFooter}>
                <span>
                    <span>{t("declarationCode")}</span>
                    <span>{docCode}</span>
                </span>
                <span>
                    <span>{t("validity_period")}</span>
                    <span>{validityPeriod}</span>
                </span>
            </div>
        </div>
    );
};