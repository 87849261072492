import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { mobile_decl_storage_keys } from '../components/MobileView/components';

export interface AuthState {
    personId: number;
    role: string;
    username: string;
    defaultLanguage: string;
}

type authType = any;
// null - unauthorized
// '' - not know yet
// AuthState - authorized
const initialState: AuthState | '' | null = '';

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logoutAuth: (_state): any => {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            setTimeout(() => mobile_decl_storage_keys.map((key) => sessionStorage.removeItem(key)), 100);
            return null;
        },
        checkAuth: (_state, action: PayloadAction<authType>) => {
            return { ...action.payload, defaultLanguage: (action?.payload?.defaultLanguage ?? "AM")?.toLowerCase() };
        },
        setAuthTin: (_state: any, action: PayloadAction<authType>) => {
            return { ..._state, tin: action.payload };
        }
    },
})

export const { checkAuth, setAuthTin, logoutAuth } = authSlice.actions;
export default authSlice.reducer;
