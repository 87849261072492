import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ProfileState {
    [key: string]: any;
}

type profileType = any;
const initialState: ProfileState = {};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        initProfile: (_state, action: PayloadAction<profileType>) => {
            return { ...action.payload };
        },
        setProfileTin: (_state: any, action: PayloadAction<profileType>) => {
            return { ..._state, tin: action.payload };
        }
    },
})

export const { initProfile, setProfileTin } = profileSlice.actions;
export default profileSlice.reducer;