import queryString from "query-string";
import { Role } from "./types";
export const classnames = (classes: { [key: string]: boolean }) => (
    Object.entries(classes)
      .filter(([_, value]) => value)
      .map(([key]) => key)
      .join(' ')
  );

type dateFormat = "YYYY-MM-DD" | "DD MMM YYYY" | 'MMMM DD, YYYY' | 'MM/DD/YYYY' | 'DD/MM/YYYY' | 'DD.MM.YYYY' | 'DD/MM/YYYY HH:mm' | 'DD.MM.YYYY HH:mm' | 'DD.MM.YYYY HH:mm:ss';

 export const formatDate = (format: dateFormat, date: Date | string) => {
  const currentDate = new Date(date)
  if (format === 'YYYY-MM-DD') {
    const options: any = { year: 'numeric', month: '2-digit', day: '2-digit'}
    return currentDate.toLocaleDateString('en-GB', options).split('/').reverse().join('-')
  }

  if (format === 'DD MMM YYYY') {
    const options: any = { day: '2-digit', month: 'short', year: 'numeric' }
    const formattedDate = currentDate.toLocaleDateString('en-US', options)
    const [m, d, y] = formattedDate.split(' ')
    return `${d} ${m.toUpperCase()} ${y}`
  }

  if (format === 'MMMM DD, YYYY') {
    const options: any = { month: 'long', day: 'numeric', year: 'numeric' }
    return currentDate.toLocaleDateString(undefined, options)
  }

  if (format === 'MM/DD/YYYY') {
    const options: any = { month: '2-digit', day: '2-digit', year: 'numeric' }
    return currentDate.toLocaleDateString('en-US', options)
  }

  if (format === 'DD/MM/YYYY') {
    return currentDate.toLocaleDateString('en-GB')
  }

   if (format === 'DD.MM.YYYY') {
     return `${currentDate.getDate() < 10 ? '0' : ''}${currentDate.getDate()}.${currentDate.getMonth() + 1 < 10 ? '0' : ''}${currentDate.getMonth() + 1}.${currentDate.getFullYear()}`;
   }

  if (format === 'DD/MM/YYYY HH:mm') {
    return `${currentDate.getDate() < 10 ? '0' : ''}${currentDate.getDate()}/${currentDate.getMonth() + 1 < 10 ? '0' : ''}${currentDate.getMonth() + 1}/${currentDate.getFullYear()} ${currentDate.getHours() < 10 ? '0' : ''}${currentDate.getHours()}:${currentDate.getMinutes() < 10 ? '0' : ''}${currentDate.getMinutes()}`;
  }

   if (format === 'DD.MM.YYYY HH:mm') {
     return `${currentDate.getDate() < 10 ? '0' : ''}${currentDate.getDate()}.${currentDate.getMonth() + 1 < 10 ? '0' : ''}${currentDate.getMonth() + 1}.${currentDate.getFullYear()} ${currentDate.getHours() < 10 ? '0' : ''}${currentDate.getHours()}:${currentDate.getMinutes() < 10 ? '0' : ''}${currentDate.getMinutes()}`;
   }

   if (format === 'DD.MM.YYYY HH:mm:ss') {
     return `${currentDate.getDate() < 10 ? '0' : ''}${currentDate.getDate()}.${currentDate.getMonth() + 1 < 10 ? '0' : ''}${currentDate.getMonth() + 1}.${currentDate.getFullYear()} ${currentDate.getHours() < 10 ? '0' : ''}${currentDate.getHours()}:${currentDate.getMinutes() < 10 ? '0' : ''}${currentDate.getMinutes()}:${currentDate.getSeconds() < 10 ? '0' : ''}${currentDate.getSeconds()}`;
   }

  return '⛔️ No valid format'
}

export const randomRGBColor = (A = 0.2) => {
  const R = Math.random() * 256;
  const G = Math.random() * 256;
  const B = Math.random() * 256;
  return `rgba(${R}, ${G}, ${B}, ${A})`;
};

export const isResidentUser = (role: string) => role === Role.ROLE_RESIDENT;

export const isNotResidentUser = (role: string) => role === Role.ROLE_NO_RESIDENT;

export const compareDate = (date1: Date, date2: Date) => {
  const milliseconds1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const milliseconds2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
  if(milliseconds1 > milliseconds2) {
    return 1;
  }
  else if(milliseconds1 == milliseconds2) {
    return 0;
  }
  else return -1;
}

class TriggerError {
  key?: string;
  errorKey?: string;
  message?: string;
  type?: string;

  constructor(arg?: { key: string, errorKey?: string, message?: string, errType?: string }) {
    this.key = arg?.key;
    this.errorKey = arg?.errorKey;
    this.message = arg?.message;
    this.type = arg?.errType;
  }
}

export const fieldValidator = (field: Field<any>) => {
  const { value = "", validation, key } = field;
  const { isRequired, pattern, patternError, minLength, maxLength, minLengthError, maxLengthError, min, minError, max, maxError, toEqual, toEqualError } = validation ?? {}
  if (value != "0" && !value && !!isRequired) {
    return { ...field, triggerError: new TriggerError({key, errorKey: "FIELD_NOT_SET", errType: "isRequired"}) };
  }
  if (value && typeof value === "string" && pattern instanceof RegExp && !pattern.test(value)) {
    return { ...field, triggerError: new TriggerError({key, errorKey: "INVALID_INPUT", message: patternError, errType: "pattern" }) };
  }
  if (typeof value === "string" && typeof pattern === "string" && !(new RegExp(pattern).test(value))) {
    return { ...field, triggerError: new TriggerError({ key, errorKey: "INVALID_INPUT", message: patternError, errType: "pattern" }) };
  }
  if (maxLength && typeof value === "string" && value.length > maxLength) {
    return { ...field, triggerError: new TriggerError({ key, errorKey: "field_length_exceed_characters", message: maxLengthError, errType: "maxLength" }) };
  }
  if (minLength && typeof value === "string" && value.length < minLength) {
    return { ...field, triggerError: new TriggerError({ key, errorKey: "field_length_must_exceed_characters", message: minLengthError, errType: "minLength" }) };
  }
  if (value instanceof Date) {
    if(min instanceof Date && compareDate(min, value) === 1) {
      return { ...field, triggerError: new TriggerError({key, errorKey: "field_must_greater_than", message: minError, errType: "min"}) };
    }
    if(max instanceof Date && compareDate(value, max ) === 1) {
      return { ...field, triggerError: new TriggerError({key, errorKey: "field_must_less_than", message: maxError, errType: "max" }) };
    }
  }
  if(typeof min === "number" && (typeof value === "number" || !isNaN(value)) && +value < min) {
    return { ...field, triggerError: new TriggerError({ key, errorKey: "field_must_greater_than", message: minError, errType: "min" }) };
  }
  if(typeof max === "number" && (typeof value === "number" || !isNaN(value)) && +value > max) {
    return { ...field, triggerError: new TriggerError({key, errorKey: "field_must_less_than", message: maxError, errType: "max"}) };
  }
  if(!!toEqual?.length && !toEqual.some((item) => item === value)) {
    return { ...field, triggerError: new TriggerError({key, errorKey: "to_equal", message: toEqualError, errType: "toEqual"}) };
  }
  return { ...field, triggerError: new TriggerError({key, errType: "none" }) };
}

export const formValidator = (form: { [key: string]: Field<any> | FormGroup }) => {
  const fields = Object.entries(form).reduce((acc: any, [key, field]) => {
    if(field.type === "form_group") {
      Object.values((field as FormGroup).fields ?? {}).map((groupField: Field<any>) => {
        const { triggerError } = fieldValidator(groupField as Field<any>);
        if(triggerError.type !== "none") {
          acc[groupField.key] = { ...triggerError, form_group_key: field.key };
        }
      })
    }
    else {
      const { triggerError } = fieldValidator(field as Field<any>);
      if(triggerError.type !== "none") {
        acc[key] = triggerError;
      }
    }
    return acc
  }, {});
  const isValid = !Object.keys(fields).length;
  return { isValid, fieldsErrorTrigger: fields }
}

export const getDateValidation = () => {
  
  const getOneYearBefore = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    date.setFullYear(currentYear - 1);
    return date
  }
  const getThreeYearBefore = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    date.setFullYear(currentYear - 1);
    return date
  }

  const currentDate = () => {
    const date = new Date();
    return date
  }

  return {
    getOneYearBefore,
    getThreeYearBefore,
    currentDate
  }
}

export const setValueInTreeObject = (
    obj: any,
    key: string,
    value: any,
    isError: boolean = false,
    notVisible: boolean = false,
    changeErrorMessage: boolean = true
) => {
  const keys = key.split('.');
  let currentObj = obj;
  for (const currentKey of keys) {
    if (typeof currentObj[currentKey] === 'undefined') {
      return;
    }
    currentObj = currentObj[currentKey];
  }
  if (currentObj) {
    if (isError) {
      if (changeErrorMessage) {
        currentObj.errorMessage = value;
      }
      currentObj.notVisible = notVisible;
    } else {
      currentObj.value = value;
    }
  }
}

export interface IhandleArgs { id: string, maxLength?: number, pattern?: string, numeric?: boolean | undefined };

export const getSendData = (state: any, type: string) => {
  const send: any = {
    docCode: type,
    adjustment: state.adjustment,
    reasonCodes: state.reasonCodes,
    reasonPresent: state.reasonPresent,
    physSocParticipationType: state.physSocParticipationType?.value,
    psn: state.psn?.value,
    tin: state.tin?.value,
    taxYear: state.taxYear?.value?.getFullYear().toString(),
    taxpayerAddress: state.taxpayerAddress?.value,
    taxpayerName: state.taxpayerName?.value,
    taxpayerPhone: state.taxpayerPhone?.value ? '+(374)' + state.taxpayerPhone?.value : '',
    section1_1: {
      employerDataList: (state.section1_1?.employerDataList || [])
          .filter((item: any) => !!item.tin?.value)
          .map((item: any) => ({
              tin: item.tin?.value,
              taxpayerName: item.taxpayerName?.value,
              allBenefits: !Number.isNaN(parseInt(item.allBenefits?.value)) ? parseInt(item.allBenefits?.value) : undefined,
              amountReduce: !Number.isNaN(parseInt(item.amountReduce?.value)) ? parseInt(item.amountReduce?.value) : undefined,
              civilSalary: !Number.isNaN(parseInt(item.civilSalary?.value)) ? parseInt(item.civilSalary?.value) : undefined,
              otherTaxIncome: !Number.isNaN(parseInt(item.otherTaxIncome?.value)) ? parseInt(item.otherTaxIncome?.value) : undefined,
              salary: !Number.isNaN(parseInt(item.salary?.value)) ? parseInt(item.salary?.value) : undefined,
              totalIncome: !Number.isNaN(parseInt(item.totalIncome?.value)) ? parseInt(item.totalIncome?.value) : undefined,
              totalIncomeTax: !Number.isNaN(parseInt(item.totalIncomeTax?.value)) ? parseInt(item.totalIncomeTax?.value) : undefined,
          })),
      totalTax: parseInt(state.section1_1?.totalTax?.value) || 0
    },
    section7_1: {
      employerDataList: (state.section7_1?.employerDataList || [])
          .filter((item: any) => !!item.tin?.value)
          .map((item: any) => ({
              tin: item.tin?.value,
              taxpayerName: item.taxpayerName?.value,
              allBenefits: !Number.isNaN(parseInt(item.allBenefits?.value)) ? parseInt(item.allBenefits?.value) : undefined,
              amountReduce: !Number.isNaN(parseInt(item.amountReduce?.value)) ? parseInt(item.amountReduce?.value) : undefined,
              civilSalary: !Number.isNaN(parseInt(item.civilSalary?.value)) ? parseInt(item.civilSalary?.value) : undefined,
              otherTaxIncome: !Number.isNaN(parseInt(item.otherTaxIncome?.value)) ? parseInt(item.otherTaxIncome?.value) : undefined,
              salary: !Number.isNaN(parseInt(item.salary?.value)) ? parseInt(item.salary?.value) : undefined,
              totalIncome: !Number.isNaN(parseInt(item.totalIncome?.value)) ? parseInt(item.totalIncome?.value) : undefined,
              totalIncomeTax: !Number.isNaN(parseInt(item.totalIncomeTax?.value)) ? parseInt(item.totalIncomeTax?.value) : undefined,
      })),
      totalTax: parseInt(state.section7_1?.totalTax?.value) || 0
    },
    section1_2: {
      code001: (state.section1_2?.code001 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code002: (state.section1_2?.code002 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code003: (state.section1_2?.code003 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code004: (state.section1_2?.code004 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code005: (state.section1_2?.code005 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code007: (state.section1_2?.code007 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code008: (state.section1_2?.code008 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code009: (state.section1_2?.code009 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code010: (state.section1_2?.code010 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code011: (state.section1_2?.code011 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code012: (state.section1_2?.code012 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code072: (state.section1_2?.code072 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code082: (state.section1_2?.code082 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code099: (state.section1_2?.code099 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      totalTax: !Number.isNaN(parseInt(state.section1_2?.totalTax?.value)) ? parseInt(state.section1_2?.totalTax?.value) : undefined,
    },
    section7_2: {
      code001: (state.section7_2?.code001 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code002: (state.section7_2?.code002 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code003: (state.section7_2?.code003 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code004: (state.section7_2?.code004 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code005: (state.section7_2?.code005 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code007: (state.section7_2?.code007 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code008: (state.section7_2?.code008 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code009: (state.section7_2?.code009 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code010: (state.section7_2?.code010 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code011: (state.section7_2?.code011 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code012: (state.section7_2?.code012 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code072: (state.section7_2?.code072 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code082: (state.section7_2?.code082 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      code099: (state.section7_2?.code099 || []).filter((item: any) => !!item.tin?.value).map((item: any) => ({
        tin: item.tin?.value,
        taxpayerName: item.taxpayerName?.value,
        incomeAmount: !Number.isNaN(parseInt(item.incomeAmount?.value)) ? parseInt(item.incomeAmount?.value) : undefined,
        taxAmount: !Number.isNaN(parseInt(item.taxAmount?.value)) ? parseInt(item.taxAmount?.value) : undefined,
      })),
      totalTax: parseInt(state.section7_2?.totalTax?.value) || 0,
    },
    section7_2_added_new_items: state.section7_2_added_new_items || false,
    section2: {
      f2_1: !Number.isNaN(parseInt(state.section2?.f2_1?.value)) ? parseInt(state.section2?.f2_1?.value) : undefined,
      f2_2: !Number.isNaN(parseInt(state.section2?.f2_2?.value)) ? parseInt(state.section2?.f2_2?.value) : undefined,
      f2_3: !Number.isNaN(parseInt(state.section2?.f2_3?.value)) ? parseInt(state.section2?.f2_3?.value) : undefined,
      f2_4: !Number.isNaN(parseInt(state.section2?.f2_4?.value)) ? parseInt(state.section2?.f2_4?.value) : undefined,
      f2_5: !Number.isNaN(parseInt(state.section2?.f2_5?.value)) ? parseInt(state.section2?.f2_5?.value) : undefined,
      f2_6: !Number.isNaN(parseInt(state.section2?.f2_6?.value)) ? parseInt(state.section2?.f2_6?.value) : undefined,
      f2_7: !Number.isNaN(parseInt(state.section2?.f2_7?.value)) ? parseInt(state.section2?.f2_7?.value) : undefined,
      f2_8: !Number.isNaN(parseInt(state.section2?.f2_8?.value)) ? parseInt(state.section2?.f2_8?.value) : undefined,
      f2_9: !Number.isNaN(parseInt(state.section2?.f2_9?.value)) ? parseInt(state.section2?.f2_9?.value) : undefined,
      f2_10: !Number.isNaN(parseInt(state.section2?.f2_10?.value)) ? parseInt(state.section2?.f2_10?.value) : undefined,
      f2_11: !Number.isNaN(parseInt(state.section2?.f2_11?.value)) ? parseInt(state.section2?.f2_11?.value) : undefined,
      f2_12: !Number.isNaN(parseInt(state.section2?.f2_12?.value)) ? parseInt(state.section2?.f2_12?.value) : undefined,
      f2_13: !Number.isNaN(parseInt(state.section2?.f2_13?.value)) ? parseInt(state.section2?.f2_13?.value) : undefined,
      f2_14: !Number.isNaN(parseInt(state.section2?.f2_14?.value)) ? parseInt(state.section2?.f2_14?.value) : undefined,
      f2_15: !Number.isNaN(parseInt(state.section2?.f2_15?.value)) ? parseInt(state.section2?.f2_15?.value) : undefined,
      f2_16: !Number.isNaN(parseInt(state.section2?.f2_16?.value)) ? parseInt(state.section2?.f2_16?.value) : undefined,
      f2_17: !Number.isNaN(parseInt(state.section2?.f2_17?.value)) ? parseInt(state.section2?.f2_17?.value) : undefined,
      f2_18: !Number.isNaN(parseInt(state.section2?.f2_18?.value)) ? parseInt(state.section2?.f2_18?.value) : undefined,
      f2_19: !Number.isNaN(parseInt(state.section2?.f2_19?.value)) ? parseInt(state.section2?.f2_19?.value) : undefined,
      f2_20: !Number.isNaN(parseInt(state.section2?.f2_20?.value)) ? parseInt(state.section2?.f2_20?.value) : undefined,
      f2_21: !Number.isNaN(parseInt(state.section2?.f2_21?.value)) ? parseInt(state.section2?.f2_21?.value) : undefined,
      f2_22: !Number.isNaN(parseInt(state.section2?.f2_22?.value)) ? parseInt(state.section2?.f2_22?.value) : undefined,
      f2_23: !Number.isNaN(parseInt(state.section2?.f2_23?.value)) ? parseInt(state.section2?.f2_23?.value) : undefined,
      f2_24: !Number.isNaN(parseInt(state.section2?.f2_24?.value)) ? parseInt(state.section2?.f2_24?.value) : undefined,
      f2_25: !Number.isNaN(parseInt(state.section2?.f2_25?.value)) ? parseInt(state.section2?.f2_25?.value) : undefined,
      f2_26: !Number.isNaN(parseInt(state.section2?.f2_26?.value)) ? parseInt(state.section2?.f2_26?.value) : undefined,
      f2_27: !Number.isNaN(parseInt(state.section2?.f2_27?.value)) ? parseInt(state.section2?.f2_27?.value) : 0,
    },
    section3: {
      salary: !Number.isNaN(parseInt(state.section3?.salary?.value)) ? parseInt(state.section3?.salary?.value) : undefined,
      civilContractsSalary: !Number.isNaN(parseInt(state.section3?.civilContractsSalary?.value)) ? parseInt(state.section3?.civilContractsSalary?.value) : undefined,
      salaryTaxableIncomeReduce: !Number.isNaN(parseInt(state.section3?.salaryTaxableIncomeReduce?.value)) ? parseInt(state.section3?.salaryTaxableIncomeReduce?.value) : undefined,
      salaryTaxableIncome: !Number.isNaN(parseInt(state.section3?.salaryTaxableIncome?.value)) ? parseInt(state.section3?.salaryTaxableIncome?.value) : undefined,
      salaryTaxableRate: !Number.isNaN(parseInt(state.section3?.salaryTaxableRate?.value)) ? parseInt(state.section3?.salaryTaxableRate?.value) : undefined,
      salaryTax: !Number.isNaN(parseInt(state.section3?.salaryTax?.value)) ? parseInt(state.section3?.salaryTax?.value) : undefined,
      royaltyIncome: !Number.isNaN(parseInt(state.section3?.royaltyIncome?.value)) ? parseInt(state.section3?.royaltyIncome?.value) : undefined,
      royaltyRate: !Number.isNaN(parseInt(state.section3?.royaltyRate?.value)) ? parseInt(state.section3?.royaltyRate?.value) : undefined,
      royaltyTax: !Number.isNaN(parseInt(state.section3?.royaltyTax?.value)) ? parseInt(state.section3?.royaltyTax?.value) : undefined,
      percent10Income: !Number.isNaN(parseInt(state.section3?.percent10Income?.value)) ? parseInt(state.section3?.percent10Income?.value) : undefined,
      percent10Rate: !Number.isNaN(parseInt(state.section3?.percent10Rate?.value)) ? parseInt(state.section3?.percent10Rate?.value) : undefined,
      percent10Tax: !Number.isNaN(parseInt(state.section3?.percent10Tax?.value)) ? parseInt(state.section3?.percent10Tax?.value) : undefined,
      percent20Income: !Number.isNaN(parseInt(state.section3?.percent20Income?.value)) ? parseInt(state.section3?.percent20Income?.value) : undefined,
      percent20Rate: !Number.isNaN(parseInt(state.section3?.percent20Rate?.value)) ? parseInt(state.section3?.percent20Rate?.value) : undefined,
      percent20Tax: !Number.isNaN(parseInt(state.section3?.percent20Tax?.value)) ? parseInt(state.section3?.percent20Tax?.value) : undefined,
      feeIncome: !Number.isNaN(parseInt(state.section3?.feeIncome?.value)) ? parseInt(state.section3?.feeIncome?.value) : undefined,
      feeRate: !Number.isNaN(parseInt(state.section3?.feeRate?.value)) ? parseInt(state.section3?.feeRate?.value) : undefined,
      feeTax: !Number.isNaN(parseInt(state.section3?.feeTax?.value)) ? parseInt(state.section3?.feeTax?.value) : undefined,
      realEstateRentIncome: !Number.isNaN(parseInt(state.section3?.realEstateRentIncome?.value)) ? parseInt(state.section3?.realEstateRentIncome?.value) : undefined,
      realEstateRentRate: !Number.isNaN(parseInt(state.section3?.realEstateRentRate?.value)) ? parseInt(state.section3?.realEstateRentRate?.value) : undefined,
      realEstateRentTax: !Number.isNaN(parseInt(state.section3?.realEstateRentTax?.value)) ? parseInt(state.section3?.realEstateRentTax?.value) : undefined,
      divident5Income: !Number.isNaN(parseInt(state.section3?.divident5Income?.value)) ? parseInt(state.section3?.divident5Income?.value) : undefined,
      divident5Rate: !Number.isNaN(parseInt(state.section3?.divident5Rate?.value)) ? parseInt(state.section3?.divident5Rate?.value) : undefined,
      divident5Tax: !Number.isNaN(parseInt(state.section3?.divident5Tax?.value)) ? parseInt(state.section3?.divident5Tax?.value) : undefined,
      divident10Income: !Number.isNaN(parseInt(state.section3?.divident10Income?.value)) ? parseInt(state.section3?.divident10Income?.value) : undefined,
      divident10Rate: !Number.isNaN(parseInt(state.section3?.divident10Rate?.value)) ? parseInt(state.section3?.divident10Rate?.value) : undefined,
      divident10Tax: !Number.isNaN(parseInt(state.section3?.divident10Tax?.value)) ? parseInt(state.section3?.divident10Tax?.value) : undefined,
      propertySaleIncome: !Number.isNaN(parseInt(state.section3?.propertySaleIncome?.value)) ? parseInt(state.section3?.propertySaleIncome?.value) : undefined,
      propertySaleRate: !Number.isNaN(parseInt(state.section3?.propertySaleRate?.value)) ? parseInt(state.section3?.propertySaleRate?.value) : undefined,
      propertySaleTax: !Number.isNaN(parseInt(state.section3?.propertySaleTax?.value)) ? parseInt(state.section3?.propertySaleTax?.value) : undefined,
      propertyDeveloperSaleIncome: !Number.isNaN(parseInt(state.section3?.propertyDeveloperSaleIncome?.value)) ? parseInt(state.section3?.propertyDeveloperSaleIncome?.value) : undefined,
      propertyDeveloperSaleRate: !Number.isNaN(parseInt(state.section3?.propertyDeveloperSaleRate?.value)) ? parseInt(state.section3?.propertyDeveloperSaleRate?.value) : undefined,
      propertyDeveloperSaleTax: !Number.isNaN(parseInt(state.section3?.propertyDeveloperSaleTax?.value)) ? parseInt(state.section3?.propertyDeveloperSaleTax?.value) : undefined,
      shareInvestPropSaleIncome: !Number.isNaN(parseInt(state.section3?.shareInvestPropSaleIncome?.value)) ? parseInt(state.section3?.shareInvestPropSaleIncome?.value) : undefined,
      shareInvestPropSaleRate: !Number.isNaN(parseInt(state.section3?.shareInvestPropSaleRate?.value)) ? parseInt(state.section3?.shareInvestPropSaleRate?.value) : undefined,
      shareInvestPropSaleTax: !Number.isNaN(parseInt(state.section3?.shareInvestPropSaleTax?.value)) ? parseInt(state.section3?.shareInvestPropSaleTax?.value) : undefined,
      awardsIncome: !Number.isNaN(parseInt(state.section3?.awardsIncome?.value)) ? parseInt(state.section3?.awardsIncome?.value) : undefined,
      awardsRate: !Number.isNaN(parseInt(state.section3?.awardsRate?.value)) ? parseInt(state.section3?.awardsRate?.value) : undefined,
      awardsTax: !Number.isNaN(parseInt(state.section3?.awardsTax?.value)) ? parseInt(state.section3?.awardsTax?.value) : undefined,
      giftIncome: !Number.isNaN(parseInt(state.section3?.giftIncome?.value)) ? parseInt(state.section3?.giftIncome?.value) : undefined,
      giftRate: !Number.isNaN(parseInt(state.section3?.giftRate?.value)) ? parseInt(state.section3?.giftRate?.value) : undefined,
      giftTax: !Number.isNaN(parseInt(state.section3?.giftTax?.value)) ? parseInt(state.section3?.giftTax?.value) : undefined,
      unpaidPassive5Income: !Number.isNaN(parseInt(state.section3?.unpaidPassive5Income?.value)) ? parseInt(state.section3?.unpaidPassive5Income?.value) : undefined,
      unpaidPassive5Rate: !Number.isNaN(parseInt(state.section3?.unpaidPassive5Rate?.value)) ? parseInt(state.section3?.unpaidPassive5Rate?.value) : undefined,
      unpaidPassive5Tax: !Number.isNaN(parseInt(state.section3?.unpaidPassive5Tax?.value)) ? parseInt(state.section3?.unpaidPassive5Tax?.value) : undefined,
      unpaidPassive10Income: !Number.isNaN(parseInt(state.section3?.unpaidPassive10Income?.value)) ? parseInt(state.section3?.unpaidPassive10Income?.value) : undefined,
      unpaidPassive10Rate: !Number.isNaN(parseInt(state.section3?.unpaidPassive10Rate?.value)) ? parseInt(state.section3?.unpaidPassive10Rate?.value) : undefined,
      unpaidPassive10Tax: !Number.isNaN(parseInt(state.section3?.unpaidPassive10Tax?.value)) ? parseInt(state.section3?.unpaidPassive10Tax?.value) : undefined,
      unpaidPassive20Income: !Number.isNaN(parseInt(state.section3?.unpaidPassive20Income?.value)) ? parseInt(state.section3?.unpaidPassive20Income?.value) : undefined,
      unpaidPassive20Rate: !Number.isNaN(parseInt(state.section3?.unpaidPassive20Rate?.value)) ? parseInt(state.section3?.unpaidPassive20Rate?.value) : undefined,
      unpaidPassive20Tax: !Number.isNaN(parseInt(state.section3?.unpaidPassive20Tax?.value)) ? parseInt(state.section3?.unpaidPassive20Tax?.value) : undefined,
      otherTaxableIncome: !Number.isNaN(parseInt(state.section3?.otherTaxableIncome?.value)) ? parseInt(state.section3?.otherTaxableIncome?.value) : undefined,
      otherTaxableRate: !Number.isNaN(parseInt(state.section3?.otherTaxableRate?.value)) ? parseInt(state.section3?.otherTaxableRate?.value) : undefined,
      otherTaxableTax: !Number.isNaN(parseInt(state.section3?.otherTaxableTax?.value)) ? parseInt(state.section3?.otherTaxableTax?.value) : undefined,
      totalCalculatedTax: !Number.isNaN(parseInt(state.section3?.totalCalculatedTax?.value)) ? parseInt(state.section3?.totalCalculatedTax?.value) : undefined,
      otherCountriesPaidTax: !Number.isNaN(parseInt(state.section3?.otherCountriesPaidTax?.value)) ? parseInt(state.section3?.otherCountriesPaidTax?.value) : undefined,
      otherCountriesReduceTax: !Number.isNaN(parseInt(state.section3?.otherCountriesReduceTax?.value)) ? parseInt(state.section3?.otherCountriesReduceTax?.value) : undefined,
      totalTaxToPaid: !Number.isNaN(parseInt(state.section3?.totalTaxToPaid?.value)) ? parseInt(state.section3?.totalTaxToPaid?.value) : undefined,
    },
    section4: {
      educationalCostAmount: !Number.isNaN(parseInt(state.section4?.educationalCostAmount?.value)) ? parseInt(state.section4?.educationalCostAmount?.value) : 0,
      educationalReturnAmount: !Number.isNaN(parseInt(state.section4?.educationalReturnAmount?.value)) ? parseInt(state.section4?.educationalReturnAmount?.value) : 0,
      healthEnsuranceAmount: !Number.isNaN(parseInt(state.section4?.healthEnsuranceAmount?.value)) ? parseInt(state.section4?.healthEnsuranceAmount?.value) : 0,
      healthEnsuranceReturnAmount: !Number.isNaN(parseInt(state.section4?.healthEnsuranceReturnAmount?.value)) ? parseInt(state.section4?.healthEnsuranceReturnAmount?.value) : 0,
      totalCostAmount: !Number.isNaN(parseInt(state.section4?.totalCostAmount?.value)) ? parseInt(state.section4?.totalCostAmount?.value) : 0,
      totalReturnAmount: !Number.isNaN(parseInt(state.section4?.totalReturnAmount?.value)) ? parseInt(state.section4?.totalReturnAmount?.value) : 0
    },
    section5: {
      employerPaidTax: !Number.isNaN(parseInt(state.section5?.employerPaidTax?.value)) ? parseInt(state.section5?.employerPaidTax?.value) : undefined,
      hypotecReturnAmount: !Number.isNaN(parseInt(state.section5?.hypotecReturnAmount?.value)) ? parseInt(state.section5?.hypotecReturnAmount?.value) : undefined,
      tuitionFeeReturnAmount: !Number.isNaN(parseInt(state.section5?.tuitionFeeReturnAmount?.value)) ? parseInt(state.section5?.tuitionFeeReturnAmount?.value) : undefined,
      investmentReturnAmount: !Number.isNaN(parseInt(state.section5?.investmentReturnAmount?.value)) ? parseInt(state.section5?.investmentReturnAmount?.value) : undefined,
      employerReduceAmount: !Number.isNaN(parseInt(state.section5?.employerReduceAmount?.value)) ? parseInt(state.section5?.employerReduceAmount?.value) : undefined,
      yearlyCalculatedDifference: !Number.isNaN(parseInt(state.section5?.yearlyCalculatedDifference?.value)) ? parseInt(state.section5?.yearlyCalculatedDifference?.value) : undefined,
      invoiceReturnAmount: !Number.isNaN(parseInt(state.section5?.invoiceReturnAmount?.value)) ? parseInt(state.section5?.invoiceReturnAmount?.value) : undefined,
      taxToPaid: !Number.isNaN(parseInt(state.section5?.taxToPaid?.value)) ? parseInt(state.section5?.taxToPaid?.value) : undefined,
      taxToReturn: !Number.isNaN(parseInt(state.section5?.taxToReturn?.value)) ? parseInt(state.section5?.taxToReturn?.value) : undefined,
    },
    section6: {
      selfEmployeeIncome: !Number.isNaN(parseInt(state.section6?.selfEmployeeIncome?.value)) ? parseInt(state.section6?.selfEmployeeIncome?.value) : undefined,
      selfEmployeeSocRate: parseInt(state.section6?.selfEmployeeSocRate?.value) || 0,
      selfEmployeeSocPayment: !Number.isNaN(parseInt(state.section6?.selfEmployeeSocPayment?.value)) ? parseInt(state.section6?.selfEmployeeSocPayment?.value) : 0
    }
  };
  return send;
}

export const getSendSRData = (state: any, type: string, bankAccountCheck: boolean) => {
  const send: any = {
    docCode: type,
    firstName: state.firstName.value,
    lastName: state.lastName.value,
    middleName: state.middleName.value,
    taxpayerName: state.taxpayerName.value,
    tin: state.tin.value,
    psn: state.psn.value,
    taxpayerAddress: state.taxpayerAddress.value,
    distCode: state.distCode.value,
    birthDate: state.birthDate.value,
    documentType: state.documentType.value,
    documentNumber: state.documentNumber.value,
    documentIssueDate: state.documentIssueDate.value,
    documentIssuer: state.documentIssuer.value,
    bankAccount: state.bankAccount.value,
    bankCode: `AM${(state.bankAccount.value || '').slice(0, 5)}`,
    returnAmount: state.returnAmount.value,
    checkBankAccount: bankAccountCheck
  }
  return send;
}

export const initializeStateByIDData = (result: any, data: any) => {
  result.taxYear = { value: new Date(data.taxYear, 0), validation: { min: new Date(2023, 0), max: new Date(new Date().getFullYear() - 1, 0) } };
  result.reasonCodes = data.reasonCodes;
  result.reasonPresent = data.reasonPresent;
  result.section1_1 = {
    totalIncome: { value: 0 },
    totalTax: { value: 0 }
  }
  result.section1_1.employerDataList = (data.section1_1?.employerDataList || []).map((item: any) => {
    let { tin, taxpayerName, salary, civilSalary, allBenefits, otherTaxIncome, amountReduce, totalIncomeTax } = item;
    salary = !Number.isNaN(parseInt(salary)) ? parseInt(salary) : undefined;
    civilSalary = !Number.isNaN(parseInt(civilSalary)) ? parseInt(civilSalary) : undefined;
    allBenefits = !Number.isNaN(parseInt(allBenefits)) ? parseInt(allBenefits) : undefined;
    otherTaxIncome = !Number.isNaN(parseInt(otherTaxIncome)) ? parseInt(otherTaxIncome) : undefined;
    amountReduce = !Number.isNaN(parseInt(amountReduce)) ? parseInt(amountReduce) : undefined;
    const totalIncome = (salary || 0) + (civilSalary || 0) + (allBenefits || 0) + (otherTaxIncome || 0) - (amountReduce || 0);
    totalIncomeTax = !Number.isNaN(parseInt(totalIncomeTax)) ? parseInt(totalIncomeTax) : undefined;
    result.section1_1.totalIncome.value += totalIncome;
    result.section1_1.totalTax.value += totalIncomeTax || 0;
    return {
      tin: { value: tin },
      taxpayerName: { value: taxpayerName },
      salary: { value: salary },
      civilSalary: { value: civilSalary },
      allBenefits: { value: allBenefits },
      otherTaxIncome: { value: otherTaxIncome },
      amountReduce: { value: amountReduce },
      totalIncome: { value: totalIncome },
      totalIncomeTax: { value: totalIncomeTax },
    }
  });
  result.section7_1 = {
    employerDataList: (data.section7_1?.employerDataList || []).map((item: any) => ({
      tin: { value: item.tin },
      taxpayerName: { value: item.taxpayerName },
      salary: { value: !Number.isNaN(parseInt(item.salary)) ? parseInt(item.salary) : undefined },
      civilSalary: { value: !Number.isNaN(parseInt(item.civilSalary)) ? parseInt(item.civilSalary) : undefined },
      allBenefits: { value: !Number.isNaN(parseInt(item.allBenefits)) ? parseInt(item.allBenefits) : undefined },
      otherTaxIncome: { value: !Number.isNaN(parseInt(item.otherTaxIncome)) ? parseInt(item.otherTaxIncome) : undefined },
      amountReduce: { value: !Number.isNaN(parseInt(item.amountReduce)) ? parseInt(item.amountReduce) : undefined },
      totalIncome: { value: !Number.isNaN(parseInt(item.totalIncome)) ? parseInt(item.totalIncome) : undefined },
      totalIncomeTax: { value: !Number.isNaN(parseInt(item.totalIncomeTax)) ? parseInt(item.totalIncomeTax) : undefined },
    })),
    totalIncome: { value: !Number.isNaN(parseInt(data.section7_1?.totalIncome)) ? parseInt(data.section7_1?.totalIncome) : undefined },
    totalTax: { value: !Number.isNaN(parseInt(data.section7_1?.totalTax)) ? parseInt(data.section7_1?.totalTax) : undefined }
  };
  const { totalTax: totalTax1_2, totalIncome: totalIncome1_2, ...rest1_2 } = data.section1_2;
  const section1_2List = Object.entries(rest1_2 || {});
  let section1_2: any = {
    totalTax: { value: 0 },
    totalIncome: { value: 0 }
  };
  for (const [k, agentsList] of section1_2List) {
    section1_2[k] = [];
    // @ts-ignore
    for (const agentData of agentsList) {
      const taxAmount = !Number.isNaN(parseInt(agentData.taxAmount)) ? parseInt(agentData.taxAmount) : undefined;
      const incomeAmount = !Number.isNaN(parseInt(agentData.incomeAmount)) ? parseInt(agentData.incomeAmount) : undefined;
      const item: any = {
        tin: { value: agentData.tin },
        taxpayerName: { value: agentData.taxpayerName},
        incomeAmount: { value: incomeAmount },
        taxAmount: { value: taxAmount }
      };
      if (['code072', 'code082'].includes(k)) {
        item.isNew = true;
      }
      section1_2[k].push(item);
      section1_2.totalTax.value += taxAmount || 0;
      section1_2.totalIncome.value += incomeAmount || 0;
    }
  }
  result.section1_2 = section1_2;
  const { totalTax: totalTax7_2, ...rest7_2 } = data.section7_2;
  const section7_2List = Object.entries(rest7_2 || {});
  let section7_2: any = {
    totalTax: { value: !Number.isNaN(parseInt(data.section7_2.totalTax)) ? parseInt(data.section7_2.totalTax) : undefined },
    totalIncome: { value: !Number.isNaN(parseInt(data.section7_2.totalIncome)) ? parseInt(data.section7_2.totalIncome) : undefined }
  };
  for (const [k, agentsList] of section7_2List) {
    if (agentsList) {
      section7_2[k] = [];
      // @ts-ignore
      for (const agentData of agentsList) {
        section7_2[k].push({
          tin: { value: agentData.tin },
          taxpayerName: { value: agentData.taxpayerName},
          incomeAmount: { value: !Number.isNaN(parseInt(agentData.incomeAmount)) ? parseInt(agentData.incomeAmount) : undefined },
          taxAmount: { value: !Number.isNaN(parseInt(agentData.taxAmount)) ? parseInt(agentData.taxAmount) : undefined }
        });
      }
    }
  }
  result.section7_2 = section7_2;
  result.section7_2_added_new_items = data.section7_2_added_new_items || false;
  result.section2 = {
    f2_1: { value: !Number.isNaN(parseInt(data.section2?.f2_1)) ? parseInt(data.section2?.f2_1) : undefined },
    f2_2: { value: !Number.isNaN(parseInt(data.section2?.f2_2)) ? parseInt(data.section2?.f2_2) : undefined },
    f2_3: { value: !Number.isNaN(parseInt(data.section2?.f2_3)) ? parseInt(data.section2?.f2_3) : undefined },
    f2_4: { value: !Number.isNaN(parseInt(data.section2?.f2_4)) ? parseInt(data.section2?.f2_4) : undefined },
    f2_5: { value: !Number.isNaN(parseInt(data.section2?.f2_5)) ? parseInt(data.section2?.f2_5) : undefined },
    f2_6: { value: !Number.isNaN(parseInt(data.section2?.f2_6)) ? parseInt(data.section2?.f2_6) : undefined },
    f2_7: { value: !Number.isNaN(parseInt(data.section2?.f2_7)) ? parseInt(data.section2?.f2_7) : undefined },
    f2_8: { value: !Number.isNaN(parseInt(data.section2?.f2_8)) ? parseInt(data.section2?.f2_8) : undefined },
    f2_9: { value: !Number.isNaN(parseInt(data.section2?.f2_9)) ? parseInt(data.section2?.f2_9) : undefined },
    f2_10: { value: !Number.isNaN(parseInt(data.section2?.f2_10)) ? parseInt(data.section2?.f2_10) : undefined },
    f2_11: { value: !Number.isNaN(parseInt(data.section2?.f2_11)) ? parseInt(data.section2?.f2_11) : undefined },
    f2_12: { value: !Number.isNaN(parseInt(data.section2?.f2_12)) ? parseInt(data.section2?.f2_12) : undefined },
    f2_13: { value: !Number.isNaN(parseInt(data.section2?.f2_13)) ? parseInt(data.section2?.f2_13) : undefined },
    f2_14: { value: !Number.isNaN(parseInt(data.section2?.f2_14)) ? parseInt(data.section2?.f2_14) : undefined },
    f2_15: { value: !Number.isNaN(parseInt(data.section2?.f2_15)) ? parseInt(data.section2?.f2_15) : undefined },
    f2_16: { value: !Number.isNaN(parseInt(data.section2?.f2_16)) ? parseInt(data.section2?.f2_16) : undefined },
    f2_17: { value: !Number.isNaN(parseInt(data.section2?.f2_17)) ? parseInt(data.section2?.f2_17) : undefined },
    f2_18: { value: !Number.isNaN(parseInt(data.section2?.f2_18)) ? parseInt(data.section2?.f2_18) : undefined },
    f2_19: { value: !Number.isNaN(parseInt(data.section2?.f2_19)) ? parseInt(data.section2?.f2_19) : undefined },
    f2_20: { value: !Number.isNaN(parseInt(data.section2?.f2_20)) ? parseInt(data.section2?.f2_20) : undefined },
    f2_21: { value: !Number.isNaN(parseInt(data.section2?.f2_21)) ? parseInt(data.section2?.f2_21) : undefined },
    f2_22: { value: !Number.isNaN(parseInt(data.section2?.f2_22)) ? parseInt(data.section2?.f2_22) : undefined },
    f2_23: { value: !Number.isNaN(parseInt(data.section2?.f2_23)) ? parseInt(data.section2?.f2_23) : undefined },
    f2_24: { value: !Number.isNaN(parseInt(data.section2?.f2_24)) ? parseInt(data.section2?.f2_24) : undefined },
    f2_25: { value: !Number.isNaN(parseInt(data.section2?.f2_25)) ? parseInt(data.section2?.f2_25) : undefined },
    f2_26: { value: !Number.isNaN(parseInt(data.section2?.f2_26)) ? parseInt(data.section2?.f2_26) : undefined },
    f2_27: { value: !Number.isNaN(parseInt(data.section2?.f2_27)) ? parseInt(data.section2?.f2_27) : 0 },
  };
  result.section3 = {
    salary: { value: !Number.isNaN(parseInt(data.section3?.salary)) ? parseInt(data.section3?.salary) : undefined },
    civilContractsSalary: { value: !Number.isNaN(parseInt(data.section3?.civilContractsSalary)) ? parseInt(data.section3?.civilContractsSalary) : undefined },
    salaryTaxableIncomeReduce: { value: !Number.isNaN(parseInt(data.section3?.salaryTaxableIncomeReduce)) ? parseInt(data.section3?.salaryTaxableIncomeReduce) : undefined },
    salaryTaxableIncome: { value: !Number.isNaN(parseInt(data.section3?.salaryTaxableIncome)) ? parseInt(data.section3?.salaryTaxableIncome) : undefined },
    salaryTaxableRate: { value: !Number.isNaN(parseInt(data.section3?.salaryTaxableRate)) ? parseInt(data.section3?.salaryTaxableRate) : 20 },
    salaryTax: { value: !Number.isNaN(parseInt(data.section3?.salaryTax)) ? parseInt(data.section3?.salaryTax) : undefined },
    royaltyIncome: { value: !Number.isNaN(parseInt(data.section3?.royaltyIncome)) ? parseInt(data.section3?.royaltyIncome) : undefined },
    royaltyRate: { value: !Number.isNaN(parseInt(data.section3?.royaltyRate)) ? parseInt(data.section3?.royaltyRate) : 10 },
    royaltyTax: { value: !Number.isNaN(parseInt(data.section3?.royaltyTax)) ? parseInt(data.section3?.royaltyTax) : undefined },
    percent10Income: { value: !Number.isNaN(parseInt(data.section3?.percent10Income)) ? parseInt(data.section3?.percent10Income) : undefined },
    percent10Rate: { value: !Number.isNaN(parseInt(data.section3?.percent10Rate)) ? parseInt(data.section3?.percent10Rate) : 10 },
    percent10Tax: { value: !Number.isNaN(parseInt(data.section3?.percent10Tax)) ? parseInt(data.section3?.percent10Tax) : undefined },
    percent20Income: { value: !Number.isNaN(parseInt(data.section3?.percent20Income)) ? parseInt(data.section3?.percent20Income) : undefined },
    percent20Rate: { value: !Number.isNaN(parseInt(data.section3?.percent20Rate)) ? parseInt(data.section3?.percent20Rate) : 20 },
    percent20Tax: { value: !Number.isNaN(parseInt(data.section3?.percent20Tax)) ? parseInt(data.section3?.percent20Tax) : undefined },
    feeIncome: { value: !Number.isNaN(parseInt(data.section3?.feeIncome)) ? parseInt(data.section3?.feeIncome) : undefined },
    feeRate: { value: !Number.isNaN(parseInt(data.section3?.feeRate)) ? parseInt(data.section3?.feeRate) : 10 },
    feeTax: { value: !Number.isNaN(parseInt(data.section3?.feeTax)) ? parseInt(data.section3?.feeTax) : undefined },
    realEstateRentIncome: { value: !Number.isNaN(parseInt(data.section3?.realEstateRentIncome)) ? parseInt(data.section3?.realEstateRentIncome) : undefined },
    realEstateRentRate: { value: !Number.isNaN(parseInt(data.section3?.realEstateRentRate)) ? parseInt(data.section3?.realEstateRentRate) : 10 },
    realEstateRentTax: { value: !Number.isNaN(parseInt(data.section3?.realEstateRentTax)) ? parseInt(data.section3?.realEstateRentTax) : undefined },
    divident5Income: { value: !Number.isNaN(parseInt(data.section3?.divident5Income)) ? parseInt(data.section3?.divident5Income) : undefined },
    divident5Rate: { value: !Number.isNaN(parseInt(data.section3?.divident5Rate)) ? parseInt(data.section3?.divident5Rate) : 5 },
    divident5Tax: { value: !Number.isNaN(parseInt(data.section3?.divident5Tax)) ? parseInt(data.section3?.divident5Tax) : undefined },
    divident10Income: { value: !Number.isNaN(parseInt(data.section3?.divident10Income)) ? parseInt(data.section3?.divident10Income) : undefined },
    divident10Rate: { value: !Number.isNaN(parseInt(data.section3?.divident10Rate)) ? parseInt(data.section3?.divident10Rate) : 10 },
    divident10Tax: { value: !Number.isNaN(parseInt(data.section3?.divident10Tax)) ? parseInt(data.section3?.divident10Tax) : undefined },
    propertySaleIncome: { value: !Number.isNaN(parseInt(data.section3?.propertySaleIncome)) ? parseInt(data.section3?.propertySaleIncome) : undefined },
    propertySaleRate: { value: !Number.isNaN(parseInt(data.section3?.propertySaleRate)) ? parseInt(data.section3?.propertySaleRate) : 10 },
    propertySaleTax: { value: !Number.isNaN(parseInt(data.section3?.propertySaleTax)) ? parseInt(data.section3?.propertySaleTax) : undefined },
    propertyDeveloperSaleIncome: { value: !Number.isNaN(parseInt(data.section3?.propertyDeveloperSaleIncome)) ? parseInt(data.section3?.propertyDeveloperSaleIncome) : undefined },
    propertyDeveloperSaleRate: { value: !Number.isNaN(parseInt(data.section3?.propertyDeveloperSaleRate)) ? parseInt(data.section3?.propertyDeveloperSaleRate) : 20 },
    propertyDeveloperSaleTax: { value: !Number.isNaN(parseInt(data.section3?.propertyDeveloperSaleTax)) ? parseInt(data.section3?.propertyDeveloperSaleTax) : undefined },
    shareInvestPropSaleIncome: { value: !Number.isNaN(parseInt(data.section3?.shareInvestPropSaleIncome)) ? parseInt(data.section3?.shareInvestPropSaleIncome) : undefined },
    shareInvestPropSaleRate: { value: !Number.isNaN(parseInt(data.section3?.shareInvestPropSaleRate)) ? parseInt(data.section3?.shareInvestPropSaleRate) : 10 },
    shareInvestPropSaleTax: { value: !Number.isNaN(parseInt(data.section3?.shareInvestPropSaleTax)) ? parseInt(data.section3?.shareInvestPropSaleTax) : undefined },
    awardsIncome: { value: !Number.isNaN(parseInt(data.section3?.awardsIncome)) ? parseInt(data.section3?.awardsIncome) : undefined },
    awardsRate: { value: !Number.isNaN(parseInt(data.section3?.awardsRate)) ? parseInt(data.section3?.awardsRate) : 20 },
    awardsTax: { value: !Number.isNaN(parseInt(data.section3?.awardsTax)) ? parseInt(data.section3?.awardsTax) : undefined },
    giftIncome: { value: !Number.isNaN(parseInt(data.section3?.giftIncome)) ? parseInt(data.section3?.giftIncome) : undefined },
    giftRate: { value: !Number.isNaN(parseInt(data.section3?.giftRate)) ? parseInt(data.section3?.giftRate) : 20 },
    giftTax: { value: !Number.isNaN(parseInt(data.section3?.giftTax)) ? parseInt(data.section3?.giftTax) : undefined },
    unpaidPassive5Income: { value: !Number.isNaN(parseInt(data.section3?.unpaidPassive5Income)) ? parseInt(data.section3?.unpaidPassive5Income) : undefined },
    unpaidPassive5Rate: { value: !Number.isNaN(parseInt(data.section3?.unpaidPassive5Rate)) ? parseInt(data.section3?.unpaidPassive5Rate) : 5 },
    unpaidPassive5Tax: { value: !Number.isNaN(parseInt(data.section3?.unpaidPassive5Tax)) ? parseInt(data.section3?.unpaidPassive5Tax) : undefined },
    unpaidPassive10Income: { value: !Number.isNaN(parseInt(data.section3?.unpaidPassive10Income)) ? parseInt(data.section3?.unpaidPassive10Income) : undefined },
    unpaidPassive10Rate: { value: !Number.isNaN(parseInt(data.section3?.unpaidPassive10Rate)) ? parseInt(data.section3?.unpaidPassive10Rate) : 10 },
    unpaidPassive10Tax: { value: !Number.isNaN(parseInt(data.section3?.unpaidPassive10Tax)) ? parseInt(data.section3?.unpaidPassive10Tax) : undefined },
    unpaidPassive20Income: { value: !Number.isNaN(parseInt(data.section3?.unpaidPassive20Income)) ? parseInt(data.section3?.unpaidPassive20Income) : undefined },
    unpaidPassive20Rate: { value: !Number.isNaN(parseInt(data.section3?.unpaidPassive20Rate)) ? parseInt(data.section3?.unpaidPassive20Rate) : 20 },
    unpaidPassive20Tax: { value: !Number.isNaN(parseInt(data.section3?.unpaidPassive20Tax)) ? parseInt(data.section3?.unpaidPassive20Tax) : undefined },
    otherTaxableIncome: { value: !Number.isNaN(parseInt(data.section3?.otherTaxableIncome)) ? parseInt(data.section3?.otherTaxableIncome) : undefined },
    otherTaxableRate: { value: !Number.isNaN(parseInt(data.section3?.otherTaxableRate)) ? parseInt(data.section3?.otherTaxableRate) : 20 },
    otherTaxableTax: { value: !Number.isNaN(parseInt(data.section3?.otherTaxableTax)) ? parseInt(data.section3?.otherTaxableTax) : undefined },
    totalCalculatedTax: { value: !Number.isNaN(parseInt(data.section3?.totalCalculatedTax)) ? parseInt(data.section3?.totalCalculatedTax) : undefined },
    otherCountriesPaidTax: { value: !Number.isNaN(parseInt(data.section3?.otherCountriesPaidTax)) ? parseInt(data.section3?.otherCountriesPaidTax) : undefined },
    otherCountriesReduceTax: { value: !Number.isNaN(parseInt(data.section3?.otherCountriesReduceTax)) ? parseInt(data.section3?.otherCountriesReduceTax) : undefined },
    totalTaxToPaid: { value: !Number.isNaN(parseInt(data.section3?.totalTaxToPaid)) ? parseInt(data.section3?.totalTaxToPaid) : undefined },
  };
  result.section4 = {
    educationalCostAmount: { value: parseInt(data.section4?.educationalCostAmount) || 0 },
    educationalReturnAmount: { value: parseInt(data.section4?.educationalReturnAmount) || 0 },
    healthEnsuranceAmount: { value: parseInt(data.section4?.healthEnsuranceAmount) || 0 },
    healthEnsuranceReturnAmount: { value: parseInt(data.section4?.healthEnsuranceReturnAmount) || 0 },
    totalCostAmount: { value: parseInt(data.section4?.totalCostAmount) || 0 },
    totalReturnAmount: { value: parseInt(data.section4?.totalReturnAmount) || 0 }
  };
  result.section5 = {
    employerPaidTax: { value: !Number.isNaN(parseInt(data.section5?.employerPaidTax)) ? parseInt(data.section5?.employerPaidTax) : undefined },
    hypotecReturnAmount: { value: !Number.isNaN(parseInt(data.section5?.hypotecReturnAmount)) ? parseInt(data.section5?.hypotecReturnAmount) : undefined },
    tuitionFeeReturnAmount: { value: !Number.isNaN(parseInt(data.section5?.tuitionFeeReturnAmount)) ? parseInt(data.section5?.tuitionFeeReturnAmount) : undefined },
    investmentReturnAmount: { value: !Number.isNaN(parseInt(data.section5?.investmentReturnAmount)) ? parseInt(data.section5?.investmentReturnAmount) : undefined },
    employerReduceAmount: { value: !Number.isNaN(parseInt(data.section5?.employerReduceAmount)) ? parseInt(data.section5?.employerReduceAmount) : undefined },
    yearlyCalculatedDifference: { value: !Number.isNaN(parseInt(data.section5?.yearlyCalculatedDifference)) ? parseInt(data.section5?.yearlyCalculatedDifference) : undefined },
    invoiceReturnAmount: { value: !Number.isNaN(parseInt(data.section5?.invoiceReturnAmount)) ? parseInt(data.section5?.invoiceReturnAmount) : undefined },
    taxToPaid: { value: !Number.isNaN(parseInt(data.section5?.taxToPaid)) ? parseInt(data.section5?.taxToPaid) : undefined },
    taxToReturn: { value: !Number.isNaN(parseInt(data.section5?.taxToReturn)) ? parseInt(data.section5?.taxToReturn) : undefined },
  };
  result.section6 = {
    selfEmployeeIncome: { value: !Number.isNaN(parseInt(data.section6?.selfEmployeeIncome)) ? parseInt(data.section6?.selfEmployeeIncome) : undefined },
    selfEmployeeSocRate: { value: parseInt(data.section6?.selfEmployeeSocRate) || 0 },
    selfEmployeeSocPayment: { value: !Number.isNaN(parseInt(data.section6?.selfEmployeeSocPayment)) ? parseInt(data.section6?.selfEmployeeSocPayment) : undefined },
  };
}

export const formatNumberWithCommas = (value: number | string) => {
  const number = Number(value);
  if (isNaN(number)) return '';
  let valueStrParsed = value.toString();
  const hasMinusSymbol = !!valueStrParsed.match(/^-/);
  if (hasMinusSymbol) {
    valueStrParsed = valueStrParsed.slice(1);
  }
  const [val, dec] = valueStrParsed.split('.');
  const valueReversed = val.split('').reverse();
  const result = [];
  for (let i = 0, len = valueReversed.length; i < len; i++) {
    result.push(valueReversed[i]);
    if ((i + 1) % 3 === 0 && i + 1 < len) {
      result.push(',')
    }
  }
  let resultValue = result.reverse().join('');
  if (dec) {
    resultValue += `.${dec}`;
  }
  return (hasMinusSymbol ? '-' : '') + resultValue;
};

export const pipe = (input: any, ...funcs: CallableFunction[]) => (
  funcs.reduce((result, func) => func(result), input)
);

type translation_code = string;

interface IErrorMaps {
  [endpoint: string]: {
    [error_code: string]: translation_code
  }
}

export const translationKeysByErrorCodes = (err: any): string => {
  let responseURL = err?.request?.responseURL;
  if (!responseURL) return '';
  responseURL = responseURL.toString();
  let pathname = new URL(responseURL).pathname;
  if (pathname.match('/api/decl/selfdecl/submit')) {
    pathname = '/api/decl/selfdecl/submit';
  } else if (pathname.match('/api/decl/selfdecl/draft')) {
    pathname = '/api/decl/selfdecl/draft';
  } else if (pathname.match('/api/decl/selfdecl/submit') || pathname.match('/api/decl/submitted/send')) {
    pathname = '/api/decl/selfdecl/submit';
  } else if (pathname.match('/api/decl/submitted/signWithMobile')) {
    pathname = '/api/decl/submitted/signWithMobile';
  }
  const error_code = err.response?.data?.message || 'FAILURE';
  const errorsMap: IErrorMaps = {
    '/api/decl/selfdecl/submit': {
      INVALID_FORM_DATA: 'INVALID_FORM_DATA_DECL'
    },
    '/api/tinrequest/signSEFormWithMobile': {
      SIGN_REQUEST_ERROR: 'SIGN_REQUEST_ERROR_WithMobile',
      FORBIDDEN_REQUEST: 'FORBIDDEN_REQUEST_FOR_SIGN'
    },
    '/api/decl/submitted/signWithMobile': {
      SIGN_REQUEST_ERROR: 'SIGN_REQUEST_ERROR_WithMobile',
      FORBIDDEN_REQUEST: 'FORBIDDEN_REQUEST_FOR_SIGN'
    },
    '/api/profile/residentPersonInfoPolice': {
      INVALID_DOCUMENT: 'INVALID_DOCUMENT_RESIDENT_PERSON_INFO_POLICE'
    }
  }
  let translation_key = errorsMap[pathname]?.[error_code] || error_code;
  if (translation_key === 'Request failed with status code 503') {
    translation_key = 'ERROR_503';
  }
  return translation_key;
};

export const dispatchActionsEvent = (action: { type: string, payload: any }) => {
  const event = new CustomEvent('dispatchActionsEvent', {
    detail: action,
    bubbles: true,
    cancelable: true
  });

  document.dispatchEvent(event);
};

export const isValidDate = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
}

export const getSearchParams = () => {
  return { ...queryString.parse(window.location?.search), ...JSON.parse(sessionStorage.getItem("searchParams") ?? "{}") }
}