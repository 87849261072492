export const data = {
    am: {
        title: 'Հարկ վճարողին միասնական հաշվից գումարները վերադարձնելու դիմումի լրացման կարգը',
        rows: [
            {
                index: 1,
                title: '1-ին տողում լրացվում է հարկ վճարողի հաշվառման համարը (ՀՎՀՀ)'
            },
            {
                index: 2,
                title: '2-րդ տողում լրացվում է հարկ վճարողի անվանումը: Իրավաբանական անձի դեպքում լրացվում է իրավաբանական անձի անվանումը, իսկ անհատ ձեռնարկատիրոջ դեպքում՝ «ԱՁ» նշումը, անունը, ազգանունը, անհատ ձեռնարկատեր չհանդիսացող ֆիզիկական անձի դեպքում՝ ֆիզիկական անձի անունը, ազգանունը',
                rows: [
                    '2.1-ին տողում լրացվում է անհատ ձեռնարկատիրոջ կամ անհատ ձեռնարկատեր չհանդիսացող ֆիզիկական անձի ՀԾՀ-ն կամ ՀԾՀ չստանալու մասին տեղեկանքի համարը'
                ]
            },
            {
                index: 3,
                title: '3-րդ տողում լրացվում է հարկ վճարողի գտնվելու վայրը: Իրավաբանական անձի դեպքում լրացվում է իրավաբանական անձի գտնվելու վայրը, իսկ անհատ ձեռնարկատիրոջ դեպքում՝ բնակության վայրը'
            },
            {
                index: 4,
                title: '4-րդ տողում լրացվում է հարկ վճարողի հաշվառման վայրի հարկային մարմնի անվանումը',
                rows: [
                    "4․1․ Կազմակերպությունների վերակազմակերպման, կազմակերպությունների լուծարման, անհատ ձեռնարկատիրոջ կամ նոտարի մահվան դեպքերում դիմումի 1-4-րդ տողերում լրացվում են, համապատասխանաբար՝ իրավանախորդ կազմակերպության, լուծարված կազմակերպության, մահացած անհատ ձեռնարկատիրոջ կամ նոտարի տվյալները՝ սույն լրացման կարգի 1-4-րդ կետերին համապատասխան",
                ]
            },
            {
                index: 5,
                title: "Աղյուսակի 1-ին սյունակում լրացվում է տողի հերթական համարը"
            },
            {
                index: 6,
                title: "Աղյուսակի 2-րդ սյունակում լրացվում է այն բանկի անվանումը, որին պետք է փոխանցել հարկ վճարողին, ֆիզիկական անձին վերադարձման ենթակա գումարը"
            },
            {
                index: 7,
                title: "Աղյուսակի 3-րդ սյունակում լրացվում է այն բանկային հաշվի համարը, որին պետք է փոխանցել հարկ վճարողին, ֆիզիկական անձին վերադարձման ենթակա գումարը",
                rows: [
                    {
                        title: '7.1. Աղյուսակի 3.1-ին սյունակում լրացվում են այն ֆիզիկական անձի անձնագրային տվյալները, որին պետք է փոխանցվի միասնական հաշվից վերադարձման ենթակա գումարը: Սյունակը լրացվում է անհատ ձեռնարկատիրոջ կամ նոտարի մահվան դեպքում գումարները ժառանգին վերադարձնելու, բանկային հաշիվներ չունեցող հաշվառումից հանված անհատ ձեռնարկատերերին և պաշտոնից ազատված նոտարներին գումարները վերադարձնելու, բանկային հաշիվներ չունեցող՝ լուծարված կազմակերպության մասնակից ֆիզիկական անձին վերադարձնելու դեպքերում',
                        rows: [
                            '7.1.1. Աղյուսակի 3.1.1-ին ենթասյունակում լրացվում են ֆիզիկական անձի անունը, ազգանունը, հայրանունը',
                            '7.1.2. Աղյուսակի 3.1.2-րդ ենթասյունակում լրացվում է ֆիզիկական անձի ծննդյան ամսաթիվը՝ օրը, ամիսը, տարին',
                            '7.1.3. Աղյուսակի 3.1.3-րդ ենթասյունակում լրացվում է ֆիզիկական անձի անձնագրի սերիան և համարը (նույնականացման քարտի դեպքում՝ քարտի համարը)',
                            '7.1.4. Աղյուսակի 3.1.4-րդ ենթասյունակում լրացվում է ֆիզիկական անձի անձնագրի (նույնականացման քարտի) տրման ժամկետը և ծածկագիրը (ում կողմից)'
                        ]
                    },
                    {
                        title: '7․2․ Աղյուսակի 3.2-րդ սյունակում լրացվում են բանկային հաշիվներ չունեցող՝ վերակազմակերպված կազմակերպության իրավահաջորդ կազմակերպության տվյալները, որին պետք է փոխանցվի միասնական հաշվից վերադարձման ենթակա գումարը',
                        rows: [
                            '7․2․1․ Աղյուսակի 3.2.1-ին ենթասյունակում լրացվում է իրավահաջորդ կազմակերպության ՀՎՀՀ-ն',
                            '7․2․2․ Աղյուսակի 3.2.2-րդ ենթասյունակում լրացվում է իրավահաջորդ կազմակերպության անվանումը'
                        ]
                    },
                    {
                        title: '7․3․ Աղյուսակի 3.3-րդ սյունակում լրացվում են բանկային հաշիվներ չունեցող՝ լուծարված կազմակերպության մասնակից կազմակերպության տվյալները, որին պետք է փոխանցվի միասնական հաշվից վերադարձման ենթակա գումարը',
                        rows: [
                            '7․3․1․ Աղյուսակի 3.2.1-ին ենթասյունակում լրացվում է մասնակից կազմակերպության ՀՎՀՀ-ն',
                            '7․3․2․ Աղյուսակի 3.2.2-րդ ենթասյունակում լրացվում է մասնակից կազմակերպության անվանումը'
                        ]
                    }
                ]
            },
            {
                index: 8,
                title: "Աղյուսակի 4-րդ սյունակում լրացվում է հարկ վճարողին վերադարձման ենթակա գումարի չափը"
            },
            {
                index: 9,
                title: "Աղյուսակի «Ընդամենը» տողում լրացվում է աղյուսակի 4-րդ սյունակում լրացված գումարների հանրագումարը",
            },
            {
                index: 10,
                title: "Դիմումի «Պատասխանատու անձի» տողում լրացվում է դիմումը ներկայացնող հարկ վճարողի պատասխանատու անձի ստորագրությունը, անունը, ազգանունը",
            }
        ]
    },
    ru: {
        title: 'Порядок заполнения заявления о возврате сумм с единого счета налогоплательщику',
        rows: [
            {
                index: 1,
                title: ' В строке 1 заполняется учетный номер налогоплательщика (УНН);'
            },
            {
                index: 2,
                title: 'в строке 2 заполняется наименование налогоплательщика. В случае юридического лица заполняется наименование юридического лица, а в случае индивидуального предпринимателя — отметка "ИП", имя, фамилия, в случае физического лица, не являющегося индивидуальным предпринимателем, — имя, фамилия физического лица;',
                rows: [
                    '2.1. в строке 2.1 заполняется НЗОУ или номер справки о неполучении НЗОУ индивидуального предпринимателя или физического лица, не являющегося индивидуальным предпринимателем;'
                ]
            },
            {
                index: 3,
                title: 'в строке 3 заполняется место нахождения налогоплательщика. В случае юридического лица заполняется место нахождения юридического лица, а в случае индивидуального предпринимателя — место жительства;'
            },
            {
                index: 4,
                title: 'в строке 4 заполняется наименование налогового органа по месту учета налогоплательщика;',
                rows: [
                    "4.1. в случаях реорганизации организаций, ликвидации организаций, смерти индивидуального предпринимателя или нотариуса в строках 1-4 заявления соответственно заполняются данные организации-правопредшественника, ликвидированной организации, умершего индивидуального предпринимателя или нотариуса — в соответствии с пунктами 1-4 настоящего порядка заполнения;",
                ]
            },
            {
                index: 5,
                title: "в графе 1 таблицы заполняется порядковый номер строки;"
            },
            {
                index: 6,
                title: "в графе 2 таблицы заполняется наименование банка, в который должна быть перечислена сумма, подлежащая возврату налогоплательщику, физическому лицу;"
            },
            {
                index: 7,
                title: "в графе 3 таблицы заполняется номер банковского счета, на который должна быть перечислена сумма, подлежащая возврату налогоплательщику, физическому лицу;",
                rows: [
                    {
                        title: '7.1. в графе 3.1 таблицы заполняются паспортные данные физического лица, которому должна быть перечислена сумма, подлежащая возврату с единого счета. Графа заполняется в случаях возврата сумм наследнику в случае смерти индивидуального предпринимателя или нотариуса, возврата сумм не имеющим банковских счетов снятым с учета индивидуальным предпринимателям и освобожденным с должности нотариусам, не имеющему банковских счетов физическому лицу-участнику ликвидированной организации;',
                        rows: [
                            '7.1.1. в столбце 3.1.1 таблицы заполняются имя, фамилия, отчество физического лица;',
                            '7.1.2. в столбце 3.1.2 таблицы заполняется дата рождения физического лица — день, месяц, год;',
                            '7.1.3. в столбце 3.1.3 таблицы заполняются серия и номер паспорта (в случае идентификационной карты — номер карты) физического лица;',
                            '7.1.4. в столбце 3.1.4 таблицы заполняются дата выдачи и код (кем выдан) паспорта (идентификационной карты) физического лица;'
                        ]
                    },
                    {
                        title: '7.2. в графе 3.2 таблицы заполняются данные организации-правопреемника реорганизованной организации, не имеющей банковских счетов, которой должна быть перечислена сумма, подлежащая возврату с единого счета;',
                        rows: [
                            '7.2.1. в столбце 3.2.1 таблицы заполняется УНН организации-правопреемника;',
                            '7.2.2. в столбце 3.2.2 таблицы заполняется наименование организации-правопреемника;'
                        ]
                    },
                    {
                        title: '7.3. в графе 3.3 таблицы заполняются данные организации-участника ликвидированной организации, не имеющей банковских счетов, которой должна быть перечислена сумма, подлежащая возврату с единого счета;',
                        rows: [
                            '7.3.1. в столбце 3.2.1 таблицы заполняется УНН организации-участника;',
                            '7.3.2. в столбце 3.2.2 таблицы заполняется наименование организации-участника;'
                        ]
                    }
                ]
            },
            {
                index: 8,
                title: "В графе 4 таблицы заполняется размер суммы, подлежащей возврату налогоплательщику;"
            },
            {
                index: 9,
                title: "в строке \"Итого\" таблицы заполняется общая сумма заполненных в графе 4 таблицы сумм;",
            },
            {
                index: 10,
                title: "в строке \"Ответственное лицо\" заявления заполняется подпись, имя, фамилия ответственного лица налогоплательщика, представляющего заявление.",
            }
        ]
    },
    en: {
        title: 'Procedure for filling in the application for returning the amounts from the unified account to the taxpayer',
        rows: [
            {
                index: 1,
                title: 'The 1st line shall be filled in with the taxpayer identification number (TIN);'
            },
            {
                index: 2,
                title: 'The 2nd line shall be filled in with name of the taxpayer. In case of a legal person, the name of the legal person shall be filled in, and in case of an individual entrepreneur — the indication "IE", name, surname, in case of a natural person nor considered to be an individual entrepreneur — name, surname of the natural person shall be filled in;',
                rows: [
                    'The line 2.1 shall be filled in with the number of the PSN or the statement of information on not receiving a PSN of an individual entrepreneur or the natural person not considered to be an individual entrepreneur;'
                ]
            },
            {
                index: 3,
                title: 'The 3rd line shall be filled in with the location of the taxpayer. In case of a legal person, the location of the legal person shall be filled in, and in case of an individual entrepreneur — the place of residence;'
            },
            {
                index: 4,
                title: 'The 4th line shall be filled in with the name of the tax authority of the place of record-registration of a taxpayer;',
                rows: [
                    "4.1. In case of reorganisation of organisations, liquidation of organisations, death of an individual entrepreneur or a notary, the lines 1-4 shall be filled in with the following data respectively: data on the legal predecessor organisation, liquidated organisation, dead individual entrepreneur or a notary, in compliance with points 1-4 of this Procedure for filling in;",
                ]
            },
            {
                index: 5,
                title: "The 1st column of the Table shall be filled in with the serial number of the line;"
            },
            {
                index: 6,
                title: "The 2nd column of the Table shall be filled in with the name of the bank, whereto the amount subject to return to the taxpayer, natural person, must be transferred;"
            },
            {
                index: 7,
                title: "The 3rd column of the Table shall be filled in with the bank account number, whereto the amount subject to return to taxpayer, natural person, must be transferred;",
                rows: [
                    {
                        title: '7.1. Column 3.1 of the Table shall be filled in with the passport data of the natural person, whereto the amount subject to return from the unified account must be transferred. The column shall be filled in the cases of return of the amounts to the heir in case of death of an individual entrepreneur or a notary, return of amounts to individual entrepreneurs having no bank accounts, removed from record-registration and the dismissed notaries, return to natural persons having no bank accounts, being participants of a liquidated organisation;',
                        rows: [
                            '7.1.1. Sub-column 3.1.1 of the Table shall be filled in with the name, surname, father\'s name of the natural person;',
                            '7.1.2. Sub-column 3.1.2 of the Table shall be filled in with the date of birth — day, month, year — of the natural person;',
                            '7.1.3. Sub-column 3.1.3 of the Table shall be filled in with the series and number of the passport (in case of an identification number — number of the card) of the natural person;',
                            '7.1.4. Sub-column 3.1.4 of the Table shall be filled in with the date of issue and code (issuing authority) of passport (identification card) of the natural person;'
                        ]
                    },
                    {
                        title: '7․2․ Column 3.2 of the Table shall be filled in with the data of the successor organisation of the reorganised organisation having no bank accounts, whereto the amount subject to return from the unified account must be transferred;',
                        rows: [
                            '7.2.1. Sub-column 3.2.1 of the Table shall be filled in with the TIN of the successor organisation;',
                            '7.2.2. Sub-column 3.2.2 of the Table shall be filled in with the name of the successor organisation;'
                        ]
                    },
                    {
                        title: '7.3. Column 3.3 of the Table shall be filled in with the data of the participant organisation of the liquidated organisation having no bank accounts, whereto the amount subject to return from the unified account must be transferred;',
                        rows: [
                            '7.3.1. Sub-column 3.2.1 of the Table shall be filled in with the TIN of the participant organisation;',
                            '7.3.2. Sub-column 3.2.2 of the Table shall be filled in with the name of the participant organisation;'
                        ]
                    }
                ]
            },
            {
                index: 8,
                title: "Column 4 of the Table shall be filled in with the amount of money subject to return to the taxpayer;"
            },
            {
                index: 9,
                title: "Line \"Total\" of the Table shall be filled in with the total sum of the amounts filled in the 4th column of the Table;",
            },
            {
                index: 10,
                title: "Line \"Responsible person\" of the Application shall be filled in with signature, name and surname of the responsible person of the taxpayer submitting the application",
            }
        ]
    },
}