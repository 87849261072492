import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import profileReducer from './profileSlice';
import utilsReducer from "./utilsSlice";
import expensesReducer from "./expensesSlice";
import revenuesReducer from "./revenuesSlice";
import declarationDraftReducer from "./declarationDraftSlice";
import declarationFilledReducer from "./declarationFilledSlice";
import declarationSubmittedReducer from "./declarationSubmittedSlice";
import declarationReducer from "./declarationSlice";
import dictionaryReducer from "./dictionarySlice";
import paymentsReducer from "./paymentsSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        profile: profileReducer,
        utils: utilsReducer,
        expenses: expensesReducer,
        revenues: revenuesReducer,
        declaration: declarationReducer,
        declarationDraft: declarationDraftReducer,
        declarationFilled: declarationFilledReducer,
        declarationSubmitted: declarationSubmittedReducer,
        dictionary: dictionaryReducer,
        payments: paymentsReducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
