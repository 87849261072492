import React, { createContext } from "react";

export interface IMobileIncomeTaxContext {
    root?: any;
    setRoot?: CallableFunction;
    outOfSteps?: { children?: React.ReactNode, className?: string };
    setOutOfSteps?: CallableFunction
} 
export const MobileIncomeTaxContext = createContext<IMobileIncomeTaxContext>({
    // root: undefined,
    // setRoot: () => {},
    outOfSteps: undefined,
    setOutOfSteps: () => {}
});