import { useSelector } from "react-redux";
import styles from "./styles.module.scss"
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../../store";
import { useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../../MobileIncomingTax/components/Form";
import { generateField } from "../../../MobileIncomingTax/utils";
import { Checkbox } from "../../../../Checkbox";
import { dispatchActionsEvent, fieldValidator, formValidator, isResidentUser } from "../../../../../utils";
import { Button } from "../../../../Button";
import { toast } from "react-toastify";

const bankAccountPartSize = 5;

export const BankDetailsForm: React.FC<{ onSubmit: CallableFunction, getRootDataStore: () => any, formId: string }> = (props) => {
    const { onSubmit, getRootDataStore, formId } = props;
    const root = getRootDataStore().root;
    const { t, i18n } = useTranslation();
    const dictionary = useSelector((state: RootState) => state.dictionary);
    const auth: any = useSelector((state: RootState) => state.auth);
    const [formData, setFormData] = useState({
        bankAccount: generateField<number>("bankAccount", root?.bankAccount, {  validation: { isRequired: true, pattern: /^\d{8,16}$/, patternError: "DECL_SR_BANK_ACCOUNT_NUMBER_WRONG_FORMAT", maxLength: 16 }, label: "DECL_SR_BANK_ACCOUNT_NUMBER" }),
        bankName: generateField<string>("bankName", root?.[`bankName_${i18n.language}`], {  validation: { isRequired: true, disabled: true }, label: "DECL_SR_BANK_ACCOUNT_NAME" }),
        returnAmount: generateField<number>("returnAmount", root?.returnAmount, {  validation: { isRequired: true, maxLength: 13, min: 0.1, minError: "DECL_SR_BANK_ACCOUNT_RETURN_AMOUNT_WRONG_FORMAT" }, label: "DECL_SR_BANK_ACCOUNT_RETURN_AMOUNT", withComma: true }),
        checkBankAccount: generateField<boolean>("checkBankAccount", root?.checkBankAccount, { label: "DECL_SR_BANK_ACCOUNT_CHECK", visibility: "hidden" }),
        bankCode: generateField<string>("bankCode", root?.bankAccount? `AM${root.bankAccount?.slice(0, bankAccountPartSize)}` : undefined, {  visibility: "hidden" }),
    });
    const dataRef = useRef({ data: formData, error: false, triggerError: {} });

    const handleChange = (data: { key: string, value: any}) => {
        const { key, value } = data
        setFormData((prev) => {
            const field = prev[key as keyof typeof prev];
            if(value && field.validation?.maxLength && value?.length > field.validation.maxLength) {
                return { ...prev };
            }
            if(key === "bankAccount") {
                if (value.length >= bankAccountPartSize) {
                    const bankAccountPart = `AM${value.slice(0, bankAccountPartSize)}`;
                    const banksNames = dictionary.banksNames.data;
                    const selectedBank = banksNames[bankAccountPart];
                    prev.bankName.value = selectedBank?.[`name_${i18n.language}` as keyof typeof selectedBank];
                    prev.bankCode.value = bankAccountPart;
                }
                else {
                    prev.bankName.value = undefined;
                    prev.bankCode.value = undefined;
                }
                const { triggerError } = fieldValidator(prev.bankName);
                if(triggerError.type !== "none") {
                    prev.bankName.error = triggerError?.message ?? triggerError?.errorKey
                }
                else {
                    prev.bankName.error = undefined
                }
            }
            if(!field.validation?.disabled) {
                field.value = value;
            }
            const { triggerError } = fieldValidator(field);
            if(triggerError.type !== "none") {
                field.error = triggerError?.message ?? triggerError?.errorKey
            }
            else {
                field.error = undefined;
            }
            return { ...prev };
        })
    };

    useEffect(() => {
        if(root && !dictionary.isRequest) {
            setFormData((prev) => {
                const bankAccountPart = `AM${`${prev.bankAccount.value}`.slice(0, bankAccountPartSize)}`;
                const banksNames = dictionary.banksNames.data;
                const selectedBank = banksNames[bankAccountPart];
                prev.bankName.value = selectedBank?.[`name_${i18n.language}` as keyof typeof selectedBank];
                return { ...prev }
            })
        }
    }, [i18n.language, dictionary.isRequest]);

    useEffect(() => {
        const validator = formValidator(formData);
        dataRef.current = { data: formData, error: !validator.isValid, triggerError: validator.fieldsErrorTrigger };
    }, [JSON.stringify(formData)]);

    const handleSubmit = () => {
        const passData = Object.entries(dataRef.current?.data ?? {}).reduce((acc: any, entries: any) => {
            const [key, field] = entries;
            acc[key] = field.value
            return acc;
        }, {})
        onSubmit?.(passData, dataRef.current?.error);
    };

    useEffect(() => {
        if (getRootDataStore()[formId]?.error) {
            const { fieldsErrorTrigger } = formValidator(formData);
            setFormData((prev) => {
                Object.values(fieldsErrorTrigger).map((triggerError) => {
                    const error = triggerError as TriggerError;
                    const formField = prev[error.key as keyof typeof prev];
                    if(error && error.type !== "none" && formField) {
                        return formField.error = error.message ?? error.errorKey;
                    }
                })
                console.log(prev)
                return { ...prev }
            })
        }
        return () => {
            if (window.location.pathname.includes("declaration/type/sr")) {
                handleSubmit();
            };
        };
    }, []);

    const errorBeforeSteps = !!getRootDataStore().personalData?.error || !!getRootDataStore().taxpayer_info?.error || !getRootDataStore().personalData?.completed || !getRootDataStore().taxpayer_info?.completed;
    const formError = useMemo(() => {
        const { isValid } = formValidator(formData);
        return !isValid;
    }, [JSON.stringify(formData)]);

    const handleRememeberTemp = () => {
        dispatchActionsEvent({ type: `sr/remember_temporarily`, payload: null })
    };

    const handleRegister = () => {
        if(!errorBeforeSteps && !formError) {
            if(!formData.checkBankAccount.value && isResidentUser(auth.role)) {
                const hasToastifyAlert = !!window.document.querySelector('.Toastify > div[class*="Toastify"]');
                if (!hasToastifyAlert) {
                    toast.error(t('DECL_SR_NEED_BANK_ACCOUNT_CHECK'), { position: 'top-right', theme: 'colored' });
                }
            }
            else {
                dispatchActionsEvent({ type: `sr/register`, payload: null })
            }
        }
    }

    return (
        <div className={styles.BankDetailsForm}>
            <div className={styles.CheckBankAccountBlock}>
                <span>{t("DECL_SR_AMOUNT_RETURN_TITLE")}</span>
                {isResidentUser(auth.role) && (
                    <Checkbox
                        label={t(formData?.checkBankAccount?.label as string)}
                        boxSize="small" onChange={() => handleChange({ key: "checkBankAccount", value: !formData.checkBankAccount.value})}
                        checked={formData.checkBankAccount.value}
                    />
                )}
            </div>
            <Form data={formData} onChange={handleChange} />
            <div className={styles.BTNSection}>
                <Button disabled={errorBeforeSteps || formError} onClick={handleRegister}>{t("register")}</Button>
                <Button 
                    variant='outlined' 
                    className={styles.Remember}
                    onClick={handleRememeberTemp}
                >
                    {t("remember_temporarily")}
                </Button>
            </div>
        </div>
    )
};