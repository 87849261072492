import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../Button';
import { Modal } from '../Modal';
import styles from './styles.module.scss';
import { RootState } from '../../store';
import { toggleDisableOpenMenu } from '../../store/utilsSlice';
import { useNavigate } from '../../hook';

export const TinAttentionDialog: React.FC = () => {
    const { t } = useTranslation();
    const utils: any = useSelector((state: RootState) => state.utils);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const gotToTin = () => {
        navigate('/receive-tin');
        closeDialog();
    }

    const closeDialog = () => {
        dispatch(toggleDisableOpenMenu(false));
    }

    return (
        <Modal
            className={styles.TinAttentionDialog}
            title={t('attention')}
            size="fit-content"
            isOpen={utils.disableOpenMenu}
            closeMessage={t('close')}
            onClose={closeDialog}
            footerExtraComponents={(
                <Button size="s" color="primary" onClick={gotToTin}>{t('getTin')}</Button>
            )}
            footerExists
            mode='head-colored'
            type='error'
        >
            <div className={styles.questionMessage}>
                <span>{t('tinAttention')}</span>
                <span>{t('tinAttentionMessage')}</span>
            </div>
        </Modal>
    );
};
