import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '../../Layouts/Container';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import { Wrapper } from '../../Layouts/Wrapper';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { formatNumberWithCommas, getPaymentCurrentDetails } from '../../utils';
import { initializePaymentInfo, resetPaymentInfo } from '../../store/paymentsSlice';
import { RootState } from '../../store';
import { Button } from '../../components/Button';
import { useNavigate } from '../../hook';
import Loading from '../../components/Loading';
import { Input } from '../../components/Input';
import { PaymentApprovalDialog } from '../../components/PaymentApprovalDialog';

export const path: string = 'payment-order-form';

const PaymentOrderForm = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [amount, setAmount] = useState<string>('');
    const [amountError, setAmountError] = useState<string>('');
    const auth: any = useSelector((state: RootState) => state.auth);
    const payments = useSelector((state: RootState) => state.payments);
    const utils = useSelector((state: any) => state.utils);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { t } = useTranslation();

    const handleAmountChange = (ev: React.ChangeEvent<HTMLInputElement>, cursorFixator?: CallableFunction) => {
        let { value } = ev.target
        value = value.replace(/,/g, '');
        if (value && !value.match(/^\d+$/)) return;
        if (value.length > 9 && amount.length <= value.length) return;
        if (value.split('').filter(item => item === '0').length === value.length && value.length > 1) return;
        if (value) {
            value = parseInt(value).toString();
        }
        cursorFixator?.();
        setAmount(value);
        if (amountError && value.length <= 9) {
            setAmountError('');
        }
    };

    const toggleIsOpen = useCallback(() => setIsOpen(prev => !prev), []);

    const paymentCancel = () => {
        navigation('/payments');
    }

    useEffect(() => {
        if (!auth?.tin || (auth?.tin && !utils.tinIsValid)) {
            navigation('/dashboard');
        } else if (!payments.newPaymentInfo.initialized) {
            setIsLoading(true);
            getPaymentCurrentDetails()
                .then(res => {
                    const amountForPay = (parseInt(res.data.socialPayment) || 0) + (parseInt(res.data.taxPayment) || 0);
                    const info = {
                        initialized: true,
                        email: res.data.email,
                        tin: res.data.tin,
                        psn: res.data.psn,
                        payerName: res.data.payerName,
                        accountNumber: res.data.accountNumber,
                        socialPayment: res.data.socialPayment,
                        taxPayment: res.data.taxPayment,
                        amountForPay
                    };
                    if (amountForPay.toString().length > 9) {
                        setAmountError('PAYMENT_ORDER_FORM_ACCOUNT_ERROR_MESSAGE');
                    }
                    dispatch(initializePaymentInfo(info));
                    if (!res.data.email) {
                        navigation('/payments');
                    }
                })
                .catch(err => {
                    console.log('addNewPayment.getPaymentCurrentDetails >>> ', err);
                    navigation('/payments');
                })
                .finally(() => setIsLoading(false));
        } else if (payments.newPaymentInfo.amountForPay.toString().length > 9) {
            setAmountError('PAYMENT_ORDER_FORM_ACCOUNT_ERROR_MESSAGE');
        }
    }, [auth?.tin, payments.newPaymentInfo, utils.tinIsValid]);

    useEffect(() => {
        return () => {
            dispatch(resetPaymentInfo());
        };
    }, []);

    useEffect(() => {
        setAmount(payments.newPaymentInfo.amountForPay?.toString());
    }, [payments.newPaymentInfo.amountForPay]);

    const disableForm = !!amountError || !parseInt(amount) || isLoading;

    return (
        <DashboardLayout className={styles.PaymentOrderForm}>
            <Container containerSize='large' className={styles.PaymentOrderFormContainer} withoutPadding>
                <Wrapper title={t("PAYMENT_ORDER_WRAPPER_TITLE")} className={styles.PaymentOrderFormWrapper}>
                    <div className="row-items">
                        <div className="col-3 p-2">
                            <Input
                                label={t('PAYMENT_ORDER_FORM_TIN')}
                                defaultValue={payments.newPaymentInfo.tin}
                                disabled
                            />
                        </div>
                        <div className="col-3 p-2">
                            <Input
                                label={t('PAYMENT_ORDER_FORM_PSN')}
                                defaultValue={payments.newPaymentInfo.psn}
                                disabled
                            />
                        </div>
                        <div className="col-3 p-2">
                            <Input
                                label={t('PAYMENT_ORDER_FORM_PAYER_NAME')}
                                defaultValue={payments.newPaymentInfo.payerName}
                                disabled
                            />
                        </div>
                        <div className="col-3 p-2">
                            <Input
                                label={t('PAYMENT_ORDER_FORM_ACCOUNT_NUMBER')}
                                defaultValue={payments.newPaymentInfo.accountNumber}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row-items">
                        <div className="col-3 p-2">
                            <Input
                                label={t('PAYMENT_ORDER_FORM_SOCIAL_PAYMENT')}
                                defaultValue={payments.newPaymentInfo.socialPayment}
                                disabled
                            />
                        </div>
                        <div className="col-3 p-2">
                            <Input
                                label={t('PAYMENT_ORDER_FORM_TAX_PAYMENT')}
                                defaultValue={payments.newPaymentInfo.taxPayment}
                                disabled
                            />
                        </div>
                        <div className="col-3 p-2">
                            <Input
                                label={t('PAYMENT_ORDER_FORM_PAYMENT_TOTAL')}
                                defaultValue={payments.newPaymentInfo.amountForPay}
                                disabled
                            />
                        </div>
                        <div className="col-3 p-2">
                            <Input
                                label={t('PAYMENT_ORDER_FORM_ACCOUNT_FOR_PAY')}
                                value={amount ? formatNumberWithCommas(amount) : ''}
                                onChange={handleAmountChange}
                                errorMessage={amountError ? t(amountError) : ''}
                            />
                        </div>
                    </div>
                    <div className="row-items">
                        <div className={styles.buttonsContainer}>
                            <Button size="small" onClick={disableForm ? () => null : toggleIsOpen} disabled={disableForm}>
                                {t('PAYMENT_ORDER_FORM_CONTINUE')}
                            </Button>
                            <Button size="small" variant="outlined" onClick={paymentCancel}>
                                {t('cancel')}
                            </Button>
                        </div>
                    </div>
                </Wrapper>
                {isLoading && <Loading />}
                <PaymentApprovalDialog isOpen={isOpen} close={toggleIsOpen} amount={amount} />
            </Container>
        </DashboardLayout>
    );
};
export default PaymentOrderForm;
