import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from '../../hook';
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';
import { Button } from '../../components/Button';
import { AttachFile, Error } from '../../components/Icons';
import { Modal } from '../../components/Modal';
import { Signature } from '../../components/Signature';
import { Wrapper } from '../../Layouts/Wrapper';
import Loading from '../Loading';
import {
    receiveTinDefaultData,
    IReceiveTinData,
    signTypes,
    receiveTinResidentPersonInfo,
    getRegions,
    getCommunities,
    getResidences,
    getPostalCodes,
    getDocumentTypes,
    tinRequestCreateDraft,
    tinRequestDownloadPDF,
    tinRequestUploadPDF,
    tinRequestSubmitToPEK,
    tinRequestSignWithMID,
    tinRequestCheckStatusByMID,
    tinRequestStopStatusByMID,
    receiveTinDefaultErrors,
    phoneRegexp,
    armenianCharsStreetRegexp,
    armenianCharsBuildingHouseRegexp,
    armenianCharsRegexp,
    armenianCharsHouseRegexp,
    translationKeysByErrorCodes,
    OS,
    getSearchParams
} from '../../utils';
import { setAuthTin } from '../../store/authSlice';
import { setProfileTin } from '../../store/profileSlice';
import styles from './styles.module.scss';
import loadingImage from '../../assets/images/loadingImg.gif';
import * as BridgeActions from "../../utils/bridge_actions";

const timerForSignDefault = 150; // 2m 30s

export default function ReceiveTinForm() {
    const [receiveTinData, setReceiveTinData] = useState<IReceiveTinData>(receiveTinDefaultData);
    const [receiveTinErrors, setReceiveTinErrors] = useState(receiveTinDefaultErrors);
    const [signType, setSignType] = useState<{ label: string, value: string } | null>(null);
    const [isRegistered, setIsRegistered] = useState<boolean>(false);
    const [isOpenRegistrationForm, setIsOpenRegistrationForm] = useState<boolean>(false);
    const [isOpenMID, setIsOpenMID] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [regionList, setRegionList] = useState<any[]>([]);
    const [communityList, setCommunityList] = useState<any[]>([]);
    const [residenceList, setResidenceList] = useState<any[]>([]);
    const [postalCodeList, setPostalCodeList] = useState<any[]>([]);
    const [documentTypes, setDocumentTypes] = useState<any[]>([]);
    const [mIDNumber, setMIDNumber] = useState<string>('');
    const [mIDNumberSignError, setMIDNumberSignError] = useState<string>('');
    const [timerForSign, setTimerForSign] = useState<number>(0);
    const timerForSignInterval = useRef<any>(null);
    const { t, i18n } = useTranslation();
    const signDoc = useRef<HTMLInputElement>(null);
    const tinRequestCheckStatus = useRef<boolean>(false);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState<boolean>(false);
    const [cancelProcess, setCancelProcess] = useState<{ processing: boolean, cancelProcess: false }>({ processing: false, cancelProcess: false });
    const [signDocContent, setSignDocContent] = useState<any>(null);
    const [signDocContentError, setSignDocContentError] = useState<string>('');
    const [sentToPEK, setSentToPEK] = useState<{ tin: string, modalIsOpen: boolean }>({ tin: '', modalIsOpen: false });
    const [sentToPEKError, setSentToPEKError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [residentPersonInfoError, setResidentPersonInfoError] = useState<string>('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { device } = useSelector((state: any) => state.utils);

    useEffect(() => {
        getRegions()
            .then(res => {
                setRegionList(res.data);
            })
            .catch(err => console.log('getRegions >>>', err));
        getPostalCodes()
            .then(res => {
                setPostalCodeList(res.data);
            })
            .catch(err => console.log('getPostalCodes >>>', err));
        getDocumentTypes()
            .then(res => {
                setDocumentTypes(res.data);
            })
            .catch(err => console.log('getDocumentTypes >>>', err));
        setIsLoading(true);

        receiveTinResidentPersonInfo()
            .then(res => {
                setReceiveTinData({
                    psn: res?.data?.personInfo?.psn,
                    firstName: res?.data?.personInfo?.name,
                    lastName: res?.data?.personInfo?.lastName,
                    surName: res?.data?.personInfo?.middleName,
                    documentType: res?.data?.personInfo?.documentTypeId,
                    documentNumber: res?.data?.personInfo?.documentNumber,
                    region: res?.data?.personInfo?.regionId,
                    community: res?.data?.personInfo?.communityId,
                    residence: res?.data?.personInfo?.areaId,
                    street: res?.data?.personInfo?.street,
                    buildingHouse: res?.data?.personInfo?.houseNumber,
                    house: res?.data?.personInfo?.aptNumber,
                    phoneNumber: '',
                    postalCode: '',
                    personId: 0,
                    dateOfBirth: res?.data?.personInfo?.dateOfBirth,
                    selectedResidenceRegion: res?.data?.personInfo?.regionId,
                    selectedResidenceCommunity: res?.data?.personInfo?.communityId,
                    selectedResidenceArea: res?.data?.personInfo?.areaId,
                    selectedResidenceStreet: res?.data?.personInfo?.street,
                    selectedResidenceHouse: res?.data?.personInfo?.houseNumber,
                    selectedResidenceApartment: res?.data?.personInfo?.aptNumber,
                });
                if (res?.data?.message && !res?.data?.correct) {
                    const obj = {
                        ...res,
                        response: {
                            data: {
                                message: res?.data?.message
                            }
                        }
                    }
                    const errorKey = translationKeysByErrorCodes(obj);
                    toast.error(t(errorKey), { position: 'top-right', theme: 'colored' });
                    setResidentPersonInfoError(errorKey);
                }
            })
            .catch(err => {
                console.log('receiveTinResidentPersonInfo >>>', err);
                const errorKey = translationKeysByErrorCodes(err);
                toast.error(t(errorKey), { position: 'top-right', theme: 'colored' });
                setResidentPersonInfoError(errorKey);
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if (receiveTinData.region) {
            getCommunities(receiveTinData.region)
                .then(res => {
                    setCommunityList(res.data);
                })
                .catch((err) => console.log('getCommunities', err));
        }
    }, [receiveTinData.region]);

    useEffect(() => {
        if (receiveTinData.community) {
            getResidences(receiveTinData.community)
                .then(res => {
                    setResidenceList(res.data);
                })
                .catch((err) => console.log('getResidences', err));
        }
    }, [receiveTinData.community]);

    const toggleIsOpenRegistrationForm = useCallback(() => setIsOpenRegistrationForm(prev => !prev), []);

    const selectBoxHandler = useCallback(
        (key: string) =>
                    (item: { value: string, label: string }) => {
                        if (residentPersonInfoError) return;
                        setReceiveTinData(prev => {
                            const additional: any = {};
                            const resetAddress = (data: any) => {
                                data.street = undefined;
                                data.buildingHouse = undefined;
                                data.house = undefined;
                            }
                            if (key === 'region') {
                                additional.community = undefined;
                                additional.residence = undefined;
                                resetAddress(additional);
                            } else if (key === 'community') {
                                additional.residence = undefined;
                                resetAddress(additional);
                            }
                            const newState = { ...prev, [key]: item.value, ...additional };
                            handleErrors(newState, key);
                            return newState;
                        })
                    },
                [residentPersonInfoError]
    );

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (residentPersonInfoError) return;
        const { name, value } = event.target;
        setReceiveTinData((prevState) => {
            const newState = { ...prevState, [name]: value };
            handleErrors(newState, name);
            return newState;
        })
    }, [residentPersonInfoError]);

    const handleSignType = useCallback((val: any) => {
        setSignType(val);
        if (val?.value === 'MID') {
            setIsOpenMID(true);
        }
    }, []);

    const regionListMemo = useMemo(() => regionList.map((item: any) => ({ label: item[`name_${i18n.language}`], value: item.id })), [i18n.language, regionList]);

    const communityListMemo = useMemo(() => communityList.map((item: any) => ({ label: item[`name_${i18n.language}`], value: item.id })), [i18n.language, communityList]);

    const residenceListMemo = useMemo(() => residenceList.map((item: any) => ({ label: item[`name_${i18n.language}`], value: item.id })), [i18n.language, residenceList]);

    const postalCodeListMemo = useMemo(() => postalCodeList.map((item: any) => ({ label: item.postalCode, value: item.postalCode })), [postalCodeList]);

    const documentTypeLabel = useMemo(() => receiveTinData.documentType ?
                                documentTypes.find(item => item.id === receiveTinData.documentType)?.[`name_${i18n.language}`] : '',
        [receiveTinData.documentType, documentTypes, i18n.language]
    );

    const handleErrors = (state?: any, k?: string) => {
        if (!k) {
            setReceiveTinErrors(receiveTinDefaultErrors);
        } else {
            setReceiveTinErrors(prev => ({ ...prev, [k]: '' }));
        }
        const errorsKeys: any = k ? [k] : Object.keys(receiveTinErrors);
        const errors: any = {};
        let existsError = false;
        const formState = state || receiveTinData;
        for (const key of errorsKeys) {
            const val = formState[key as keyof typeof receiveTinData]?.toString();
            if (!val && key !== 'hasError' && key !== 'house') {
                errors[key] = 'FIELD_NOT_SET';
                existsError = true;
            } else {
                if (
                    (key === 'phoneNumber' && !val?.match(phoneRegexp)) ||
                    (key === 'street' && !val?.match(armenianCharsRegexp)) ||
                    (key === 'buildingHouse' && !val?.match(armenianCharsRegexp)) ||
                    (key === 'house' && val && !val?.match(armenianCharsRegexp))
                ) {
                    errors[key] = 'INVALID_INPUT';
                    existsError = true;
                }
                else if (
                    (key === 'street' && !val?.match(armenianCharsStreetRegexp)) ||
                    (key === 'buildingHouse' && !val?.match(armenianCharsBuildingHouseRegexp)) ||
                    (key === 'house' && val && !val?.match(armenianCharsHouseRegexp))
                ) {
                    errors[key] = "strLengthError";
                    existsError = true;
                };
            }
        }
        if (existsError) {
            setReceiveTinErrors(prev => ({ ...prev, ...errors, hasError: true }));
        }
        return existsError;
    }

    const registerInformation = () => {
        const existsError = handleErrors();
        if (!existsError) {
            if (device.mode !== 'mobile') {
                toggleIsOpenRegistrationForm();
            } else {
                handleSignType(signTypes.find(item => item.value === 'MID'));
                setIsOpenMID(true);
            }
        }
    }

    const createDraft = () => {
        const send = {
            lang: i18n.language,
            dateOfBirth: receiveTinData.dateOfBirth,
            firstName: receiveTinData.firstName,
            lastName: receiveTinData.lastName,
            middleName: receiveTinData.surName,
            passportIdentification: receiveTinData.documentType,
            passportSerialNumber: receiveTinData.documentNumber,
            personId: receiveTinData.personId || 1,
            phone: receiveTinData.phoneNumber ? `+(374)${receiveTinData.phoneNumber}` : '',
            postalCode: receiveTinData.postalCode,
            psn: receiveTinData.psn,
            selectedRegistrationApartment: receiveTinData.house,
            selectedRegistrationArea: receiveTinData.residence,
            selectedRegistrationCommunity: receiveTinData.community,
            selectedRegistrationHouse: receiveTinData.buildingHouse,
            selectedRegistrationRegion: receiveTinData.region,
            selectedRegistrationStreet: receiveTinData.street,
            selectedResidenceApartment: receiveTinData.selectedResidenceApartment,
            selectedResidenceArea: receiveTinData.selectedResidenceArea,
            selectedResidenceCommunity: receiveTinData.selectedResidenceCommunity,
            selectedResidenceHouse: receiveTinData.selectedResidenceHouse,
            selectedResidenceRegion: receiveTinData.selectedResidenceRegion,
            selectedResidenceStreet: receiveTinData.selectedResidenceStreet
        };
        return tinRequestCreateDraft(send);
    }

    const chooseRegisteredFile = () => {
        signDoc.current?.click();
    }

    const changeRegisteredFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setSignDocContentError('');
        const file = ev.target.files?.[0];
        if (!file || !file?.name.match(/.pdf$/)) {
            setSignDocContentError('isNotPdf')
            return;
        }
        const data = new FormData();
        data.set('file', file);
        tinRequestUploadPDF(receiveTinData.draftId as number, data)
            .then(res => {
                if (res.data.correct) {
                    setSignDocContent(file);
                }
            })
            .catch(err => {
                const errorKey = translationKeysByErrorCodes(err);
                console.log('err >>>', err);
                setSignDocContentError(errorKey);
            })
    }

    const closeMIDDialog = () => {
        if (!cancelProcess.processing) {
            setSignType(null);
            setIsOpenMID(false);
            setMIDNumberSignError('');
            setMIDNumber('');
        }
        if (buttonLoading) {
            tinRequestCheckStatus.current = false;
            setButtonLoading(false);
            setTimerForSign(0);
            timerForSignInterval.current && window.clearInterval(timerForSignInterval.current);
        }
    };

    const confirmRegisterInformation = async () => {
        // some action to backend download file, after that close dialog and change buttons container
        setButtonLoading(true);
        try {
            const res = await createDraft();
            const draftId = res.data.draftId;
            const { mobileView, os} = getSearchParams();
            if(!!mobileView && os === OS.android) {
                return BridgeActions.onAction({...BridgeActions.DOWNOALD_PDF, payload: { apiPath: `/api/tinrequest/getSEFormPdf?draftId=${draftId}` }});
            }
            setReceiveTinData(prev => ({ ...prev, draftId }));
            let pdf = await tinRequestDownloadPDF(draftId);
            const fileName = pdf.headers['content-disposition'].replace('attachment; filename=', '').replace(/"/g, '');
            const url = window.URL.createObjectURL(pdf.data);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            toggleIsOpenRegistrationForm();
            setIsRegistered(true);
        } catch (err: any) {
            console.log('error: ', err);
            const errorKey = translationKeysByErrorCodes(err);
            setMIDNumberSignError(errorKey);
        } finally {
            setButtonLoading(false);
        }
    }

    const confirmRegisterInformationMID = () => {
        // sign by MID
        setButtonLoading(true);
        setMIDNumberSignError('');
        setCloseButtonDisabled(true);
        setCancelProcess(prev => ({ ...prev, processing: true }));
        createDraft()
            .then(res => {
                const draftId = res.data.draftId;
                setReceiveTinData(prev => ({ ...prev, draftId }));
                const data = {
                    draftId,
                    phone: `+374${mIDNumber}`
                };
                setTimerForSign(timerForSignDefault);
                let life = timerForSignDefault;
                timerForSignInterval.current = setInterval(() => {
                    if (life < 1) {
                        tinRequestCheckStatus.current = false;
                        window.clearInterval(timerForSignInterval.current);
                        setTimerForSign(0);
                        setButtonLoading(false);
                        setMIDNumberSignError('SIGN_REQUEST_ERROR');
                        return;
                    }
                    setTimerForSign(prev => prev - 1);
                    life -= 1;
                }, 1000);
                tinRequestCheckStatus.current = true;
                tinRequestSignWithMID(data)
                    .then(async () => {
                        setCloseButtonDisabled(false);
                        while (true) {
                            try {
                                const res = await tinRequestCheckStatusByMID(draftId);
                                if (res.data?.correct) {
                                    window.clearInterval(timerForSignInterval.current);
                                    setTimerForSign(0);
                                    setCancelProcess({ cancelProcess: false, processing: false });
                                    if (device.mode !== 'mobile') {
                                        toggleIsOpenRegistrationForm();
                                    }
                                    setIsOpenMID(false);
                                    setIsRegistered(true);
                                    setButtonLoading(false);
                                    const messageMatrix = t('signed_successfuly').split("\n");
                                    toast.success(
                                        <span>
                                            <span style={{ display: "inline-block"}}>{messageMatrix[0]}</span>
                                            <span style={{ display: "inline-block"}}>{messageMatrix[1]}</span>
                                        </span>,
                                        { position: 'top-right', theme: 'colored', autoClose: 10000 }
                                    );
                                    break;
                                } else if (life < 1 || !tinRequestCheckStatus.current) {
                                    if (!tinRequestCheckStatus.current) {
                                        tinRequestStopStatusByMID(draftId)
                                            .catch(() => {})
                                            .finally(() => {
                                                if (device.mode !== 'mobile') {
                                                    setIsOpenRegistrationForm(true);
                                                }
                                                setSignType(null);
                                                setIsOpenMID(false);
                                                setButtonLoading(false);
                                                setMIDNumberSignError('');
                                                setMIDNumber('');
                                                setTimerForSign(0);
                                                window.clearInterval(timerForSignInterval.current);
                                                setCancelProcess({ cancelProcess: false, processing: false });
                                            });
                                    }
                                    break;
                                }
                                await new Promise((resolve) => {
                                    window.setTimeout(() => {
                                        resolve(true);
                                    }, 5000);
                                });
                            } catch (err: any) {
                                setCancelProcess({ cancelProcess: false, processing: false });
                                tinRequestCheckStatus.current = false;
                                window.clearInterval(timerForSignInterval.current);
                                setTimerForSign(0);
                                const errorKey = translationKeysByErrorCodes(err);
                                setMIDNumberSignError(errorKey);
                                setButtonLoading(false);
                                break;
                            }
                        }
                    })
                    .catch(ER => {
                        setCancelProcess({ cancelProcess: false, processing: false });
                        setCloseButtonDisabled(false);
                        tinRequestCheckStatus.current = false;
                        const errorKey = translationKeysByErrorCodes(ER);
                        setMIDNumberSignError(errorKey);
                        setButtonLoading(false);
                        if (timerForSignInterval.current) {
                            setTimerForSign(0);
                            window.clearInterval(timerForSignInterval.current);
                        }
                    });
            })
            .catch(error => {
                setCancelProcess({ cancelProcess: false, processing: false });
                const errorKey = translationKeysByErrorCodes(error);
                setMIDNumberSignError(errorKey);
                setButtonLoading(false);
                setCloseButtonDisabled(false);
            });
    }

    const sendToPEK = () => {
        setButtonLoading(true);
        setSentToPEKError('');
        // some action to backend, after that open confirm dialog
        tinRequestSubmitToPEK(receiveTinData.draftId as number)
            .then(res => {
                const generatedTin = res.data?.tpEntry?.tin;
                setSentToPEK({ tin: generatedTin, modalIsOpen: true });
                dispatch(setAuthTin(generatedTin));
                dispatch(setProfileTin(generatedTin));
            })
            .catch(err => {
                console.log('err', err);
                const errorKey = translationKeysByErrorCodes(err);
                setSentToPEKError(t(errorKey));
            })
            .finally(() => {
                setButtonLoading(false);
            });
    }

    const closeReceiveTinForm = () => {
        setSentToPEK({ tin: '', modalIsOpen: false });
        if(!!getSearchParams().mobileView) {
            return BridgeActions.onAction(BridgeActions.GO_TO_PROFILE_PAGE);
        }
        navigate('/profile');
    }

    return (
        <Wrapper title={t("receiveTinTitle")}>
            <div className={styles.receiveTinFormContainer}>
                <div className={styles.govDecision}>
                    <pre>{t('govDecision_receiveTin')}</pre>
                </div>
                <div className={styles.form}>
                    <div className={styles.rowItems}>
                        <div className={styles.formItem}>
                            <Input 
                                label={t("firstName")} 
                                value={receiveTinData.firstName} 
                                disabled
                                inputSize={"middle"}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <Input
                                label={t("lastName")}
                                value={receiveTinData.lastName}
                                disabled
                                inputSize={"middle"}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <Input
                                label={t("surName")}
                                value={receiveTinData.surName}
                                disabled
                                inputSize={"middle"}
                            />
                        </div>
                    </div>
                    <div className={styles.rowItems}>
                        <div className={styles.formItem}>
                            <Input
                                label={t("psn")}
                                value={receiveTinData.psn}
                                disabled
                                inputSize={"middle"}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <Input 
                                label={t("documentTypeId")}
                                value={documentTypeLabel}
                                disabled
                                inputSize={"middle"}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <Input
                                label={t("documentNumber")} 
                                value={receiveTinData.documentNumber}
                                disabled
                                inputSize={"middle"}
                            />
                        </div>
                    </div>

                    <div className={styles.formAddressLabel}>
                        <h4>{t('formAddressLabel')}</h4>
                    </div>

                    <div className={styles.rowItems}>
                        <div className={styles.formItem2}>
                            <Select
                                label={t("region")}
                                onChange={selectBoxHandler('region')}
                                onInputChange={() => {}}
                                options={regionListMemo}
                                value={regionListMemo.find(item => item.value === receiveTinData.region) || null}
                                errorMessage={receiveTinErrors.region ? t(receiveTinErrors.region) : ''}
                                isDisabled={isRegistered || !!residentPersonInfoError}
                                styles={{
                                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                    control: (s: any) => ({ ...s, minHeight: device.mode === "mobile" ? "40px" : '30px', height: device.mode === "mobile" ? "40px" : '30px' }),
                                    valueContainer: (p: any) => ({ ...p, padding: '0 6px', minHeight: device.mode === "mobile" ? "40px" : '30px' }),
                                    input: (p: any) => ({ ...p, margin: 0, padding: '0 6px' }),
                                    indicatorsContainer: (p: any) => ({ ...p, minHeight: device.mode === "mobile" ? "40px" : '30px', height: device.mode === "mobile" ? "40px" : '30px' })
                                }}
                            />
                        </div>
                        <div className={styles.formItem2}>
                            <Select
                                label={t("community")}
                                onChange={selectBoxHandler('community')}
                                onInputChange={() => {}}
                                options={communityListMemo}
                                value={communityListMemo.find(item => item.value === receiveTinData.community) || null}
                                errorMessage={receiveTinErrors.community ? t(receiveTinErrors.community) : ''}
                                isDisabled={isRegistered || !!residentPersonInfoError}
                                styles={{
                                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                    control: (s: any) => ({ ...s, minHeight: device.mode === "mobile" ? "40px" : '30px', height: device.mode === "mobile" ? "40px" : '30px' }),
                                    valueContainer: (p: any) => ({ ...p, padding: '0 6px', minHeight: device.mode === "mobile" ? "40px" : '30px' }),
                                    input: (p: any) => ({ ...p, margin: 0, padding: '0 6px' }),
                                    indicatorsContainer: (p: any) => ({ ...p, minHeight: device.mode === "mobile" ? "40px" : '30px', height: device.mode === "mobile" ? "40px" : '30px' })
                                }}
                            />
                        </div>
                        <div className={styles.formItem2}>
                            <Select
                                label={t("residence")}
                                onChange={selectBoxHandler('residence')}
                                onInputChange={() => {}}
                                options={residenceListMemo}
                                value={residenceListMemo.find(item => item.value === receiveTinData.residence) || null}
                                errorMessage={receiveTinErrors.residence ? t(receiveTinErrors.residence) : ''}
                                isDisabled={isRegistered || !!residentPersonInfoError}
                                styles={{
                                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                    control: (s: any) => ({ ...s, minHeight: device.mode === "mobile" ? "40px" : '30px', height: device.mode === "mobile" ? "40px" : '30px' }),
                                    valueContainer: (p: any) => ({ ...p, padding: '0 6px', minHeight: device.mode === "mobile" ? "40px" : '30px' }),
                                    input: (p: any) => ({ ...p, margin: 0, padding: '0 6px' }),
                                    indicatorsContainer: (p: any) => ({ ...p, minHeight: device.mode === "mobile" ? "40px" : '30px', height: device.mode === "mobile" ? "40px" : '30px' })
                                }}
                            />
                        </div>
                        <div className={styles.formItem2}>
                            <Input
                                label={t("street")}
                                value={receiveTinData.street || ''}
                                errorMessage={receiveTinErrors.street ? t(receiveTinErrors.street).toString().replace('[N]', '40') : ''}
                                onChange={handleChange}
                                name="street"
                                disabled={isRegistered || !!residentPersonInfoError}
                                autoComplete="off"
                                inputSize={"middle"}
                            />
                        </div>
                    </div>

                    <div className={styles.rowItems}>
                        <div className={styles.formItem2}>
                            <Input
                                label={t("buildingHouse")}
                                value={receiveTinData.buildingHouse || ''}
                                errorMessage={receiveTinErrors.buildingHouse ? t(receiveTinErrors.buildingHouse).replace('[N]', '10') : ''}
                                onChange={handleChange}
                                name="buildingHouse"
                                disabled={isRegistered || !!residentPersonInfoError}
                                autoComplete="off"
                                inputSize={"middle"}
                            />
                        </div>
                        <div className={styles.formItem2}>
                            <Input
                                label={t("house")}
                                value={receiveTinData.house || ''}
                                errorMessage={receiveTinErrors.house ? t(receiveTinErrors.house).toString().replace('[N]', '8') : ''}
                                onChange={handleChange}
                                name="house"
                                disabled={isRegistered || !!residentPersonInfoError}
                                autoComplete="off"
                                inputSize={"middle"}
                            />
                        </div>
                        <div className={styles.formItem2}>
                            <Input
                                label={t("phoneNumber")}
                                value={receiveTinData.phoneNumber}
                                errorMessage={receiveTinErrors.phoneNumber ? t(receiveTinErrors.phoneNumber) : ''}
                                onChange={handleChange}
                                name="phoneNumber"
                                disabled={isRegistered || !!residentPersonInfoError}
                                autoComplete="off"
                                withMobileCode
                                withmobilecodestyle={{ top: device.mode === "mobile" ? 11 : 7 }}
                                inputSize={"middle"}
                            />
                        </div>
                        <div className={styles.formItem2}>
                            <Select
                                label={t("postalCode")}
                                onChange={selectBoxHandler('postalCode')}
                                onInputChange={() => {}}
                                options={postalCodeListMemo}
                                value={postalCodeListMemo.find(item => item.value === receiveTinData.postalCode)}
                                errorMessage={receiveTinErrors.postalCode ? t(receiveTinErrors.postalCode) : ''}
                                isDisabled={isRegistered || !!residentPersonInfoError}
                                styles={{
                                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                    control: (s: any) => ({ ...s, minHeight: device.mode === "mobile" ? "40px" : '30px', height: device.mode === "mobile" ? "40px" : '30px' }),
                                    valueContainer: (p: any) => ({ ...p, padding: '0 6px', minHeight: device.mode === "mobile" ? "40px" : '30px' }),
                                    input: (p: any) => ({ ...p, margin: 0, padding: '0 6px' }),
                                    indicatorsContainer: (p: any) => ({ ...p, minHeight: device.mode === "mobile" ? "40px" : '30px', height: device.mode === "mobile" ? "40px" : '30px' })
                                }}
                            />
                        </div>
                    </div>

                    <div className={styles.formButtons}>
                        {isRegistered && signType?.value !== 'MID' && (
                            <div className={styles.formButtonsLeft}>
                                <span className={styles.attachRegisteredFile} onClick={chooseRegisteredFile}>
                                    <AttachFile />&nbsp;<span>{signDocContent?.name || t('signed_document_name')}</span>
                                </span>
                                {signDocContentError && (
                                    <div className={styles.signDocContentError}>
                                        <Error />
                                        <span className="errorMessage">{t(signDocContentError)}</span>
                                    </div>
                                )}
                                <input type="file" ref={signDoc} onChange={changeRegisteredFile} hidden />
                            </div>
                        )}
                        <div className={styles.formButtonsRight}>
                            {isRegistered ? (
                                <>
                                    <Button
                                        size="small"
                                        onClick={(signType?.value !== 'MID' && !signDocContent?.name) || buttonLoading ? () => null : sendToPEK}
                                        disabled={(signType?.value !== 'MID' && !signDocContent?.name) || buttonLoading}
                                    >
                                        {
                                            buttonLoading ? (
                                                    <>
                                                        {t('wait')} <img src={loadingImage} width={25} />
                                                    </>
                                                ) :
                                                t('sendToPEK')
                                        }
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    size="small"
                                    onClick={residentPersonInfoError ? () => null : registerInformation}
                                    disabled={!!residentPersonInfoError}
                                >
                                    {t('register')}
                                </Button>
                            )}
                        </div>
                    </div>
                    {sentToPEKError && (
                        <div className="text-right w-100 errorMessage">
                            {sentToPEKError}
                        </div>
                    )}
                </div>
                <Signature
                    isOpenSignType={isOpenRegistrationForm}
                    toggleIsOpenSignType={toggleIsOpenRegistrationForm}
                    loading={buttonLoading}
                    signTypeButtonDisabled={!signType || buttonLoading}
                    signTypeButtonConfirmed={confirmRegisterInformation}
                    signTypeOptions={signTypes.filter(item => item.value !== 'CO_SIGN')}
                    signTypeSelected={signType}
                    signTypeHandle={handleSignType}
                    isOpenMID={isOpenMID}
                    toggleIsOpenMID={closeMIDDialog}
                    openMIDButtonConfirmed={confirmRegisterInformationMID}
                    closeButtonDisabled={closeButtonDisabled}
                    cancelProcess={cancelProcess.cancelProcess}
                    processingCancelBtn={cancelProcess.processing}
                    handleCancelProcess={() => {
                        setCancelProcess((prev: any) => ({...prev, cancelProcess: true}));
                        tinRequestCheckStatus.current = false;
                    }}
                    openMIDSignError={mIDNumberSignError ? t(mIDNumberSignError) : ''}
                    closeMIDSignError={() => {
                        setMIDNumberSignError('');
                        if (mIDNumberSignError === 'PERSON_DATA_UPDATED') {
                            window.location.reload();
                        }
                    }}
                    mIDNumber={mIDNumber}
                    setMIDNumber={setMIDNumber}
                    timerForSign={timerForSign}
                />
                <Modal
                    closeMessage={t('exit')}
                    isOpen={sentToPEK.modalIsOpen}
                    onClose={closeReceiveTinForm}
                    size="s-fit-content"
                    footerExists
                    mode='head-colored'
                    type='success'
                >
                    <div className={styles.SentToPek}>
                        <h1>{t('sentPEKMsg1')}</h1>
                        <p>{t('sentPEKMsg2')}</p>
                        <p>{t('sentPEKMsg3')} {sentToPEK.tin}</p>
                    </div>
                </Modal>
                {isLoading && <Loading />}
            </div>
        </Wrapper>
    );
}
