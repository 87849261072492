import { useEffect } from 'react';

export const useKeyDown = (targetKey: string, callback: CallableFunction, deps: any[], elementRef?: any) => {
    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (event.key === targetKey) {
                callback(event);
            }
        }   

        (elementRef?.current ?? window).addEventListener('keydown', handleKeyDown);

        return () => {
            (elementRef?.current ?? window).removeEventListener('keydown', handleKeyDown);
        };
    }, [...(deps ?? [])]);
}