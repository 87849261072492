import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button } from '../Button';
import { Modal } from '../Modal';
import {
    classnames,
    formatNumberWithCommas,
    sendPayment
} from '../../utils';
import styles from './styles.module.scss';
import loadingImage from '../../assets/images/loadingImg.gif';

interface PaymentApprovalDialogProps {
    isOpen: boolean;
    close: Function;
    size?: 'sm' | 'md' | 'lg' | 'full-screen' | 'fit-content' | "s-fit-content";
    className?: string;
    amount: string | number;
}

export const PaymentApprovalDialog: React.FC<PaymentApprovalDialogProps> = (props) => {
    const [process, setProcess] = useState<boolean>(false);
    const { t, i18n } = useTranslation();

    const toggleProcess = useCallback(() => setProcess(prev => !prev), []);

    const approve = () => {
        if (process) return;
        toggleProcess();
        sendPayment(props.amount, i18n.language)
            .then(res => {
                if (res.data.paymentUrl) {
                    window.location.href = res.data.paymentUrl;
                }
            })
            .catch(() => toast.error(t('FAILURE'), { position: 'top-right', theme: 'colored' }))
            .finally(() => {
                toggleProcess();
            });
    }

    return (
        <Modal
            className={styles.PaymentApprovalDialog}
            title={t('attention')}
            size={props.size || "s-fit-content"}
            isOpen={props.isOpen}
            closeMessage={t('No')}
            onClose={process ? () => {} : props.close}
            footerExtraComponents={(
                <Button size="s" color="primary" onClick={approve} disabled={process}>
                    {
                        process ? (
                                <>
                                    {t('wait')} <img src={loadingImage} width={30} />
                                </>
                            ) :
                            t('Yes')
                    }
                </Button>
            )}
            footerExists
            mode='head-colored'
            type='info'
        >
            <div className={classnames({[styles.contentMessage]: true, [props.className as string]: !!props.className })}>
                <div className={styles.message}>
                    <div>{t('PAYMENT_ORDER_FORM_MSG_1').replace('[AMOUNT]', formatNumberWithCommas(props.amount))}</div>
                    <div>{t('PAYMENT_ORDER_FORM_MSG_2')}</div>
                </div>
            </div>
        </Modal>
    );
};
