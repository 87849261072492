import React from 'react';
import styles from './styles.module.scss';
import { classnames } from '../../utils';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string | React.ReactNode;
    boxSize?: "small" | "middle" | "large" | "x-small";
    htmlFor?: string;
    verticalAlign?: "center" | "top" | "bottom";
}

const mapperBoxSize = {
    'x-small': "15px",
    small: "20px",
    middle: "25px",
    large: "30px"
};

export const Checkbox: React.FC<CheckboxProps> = (props) => {
    const { label, boxSize="middle", className, htmlFor, verticalAlign, ...restProps } = props;

    const cs = classnames({
        [styles.Checkbox]: true,
        [styles[boxSize]]: !!boxSize,
        [styles.Checked]: !!restProps.checked,
        [className as string]: !!className,
        [styles[verticalAlign ?? ""] as string]: !!verticalAlign
    })

    return (
        <label className={cs}
               style={{ "--boxSize": mapperBoxSize[boxSize] } as {[key: string]: string | number }}
               htmlFor={htmlFor}
               aria-disabled={restProps.disabled || 'false'}
        >
            <input type='checkbox' {...restProps} />
            {label && <span>{label}</span>}
        </label>
    );
};