import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { generateField, generateFormGroup } from "../utils";
import { Form } from "./Form";
import { mapperFieldLabels, mapperFormGroupTitle } from "../constants";
import styles from "./styles.module.scss"
import { useTranslation } from "react-i18next";
import { Button } from "../../../Button";
import { MobileIncomeTaxContext } from "../mobile_income_tax.context";
import { formValidator, getTaxpayers } from "../../../../utils";
import { section1Head1TypesOfIncome, section1Head2TypesOfIncome } from "../../../../utils";

interface EmployerFormProps {
    mode?: "edit" | "create";
    onSubmit?: CallableFunction;
    data?: any;
    formFieldKeys?: string[];
    code?: string
};

const defaultKeysMapper = ["tin", "taxpayerName", "allBenefits", "amountReduce", "civilSalary", "otherTaxIncome", "salary", "total" ];

const getNumber = (value: any): number => {
    return isNaN(value) || !value ? 0 : +value
}

export const EmployerForm: React.FC<EmployerFormProps> = memo((props) => {
    const { mode = "create", onSubmit, data, formFieldKeys = defaultKeysMapper, code } = props;
    const { t } = useTranslation();
    const context = useContext(MobileIncomeTaxContext);
    const [formData, setFormData] = useState({
        tin: generateField<Option>("tin", data?.tin ? { label: data.tin, value: data.tin } : undefined, { type: "select", isValid: false, validation: { isRequired: true }, label: mapperFieldLabels.tin }),
        taxpayerName: generateField<string>("taxpayerName", data?.taxpayerName, { type: "string", isValid: false, validation: { isRequired: true, disabled: true }, label: mapperFieldLabels.taxpayerName }),
        salary: generateField<number>("salary", data?.salary, { type: "number", isValid: false, label: mapperFormGroupTitle.salary, withComma: true }),
        civilSalary: generateField<number>("civilSalary", data?.civilSalary, { type: "number", isValid: false, label: mapperFormGroupTitle.civilSalary, withComma: true }),
        allBenefits: generateField<number>("allBenefits", data?.allBenefits, { type: "number", isValid: false, label: mapperFormGroupTitle.allBenefits, withComma: true }),
        otherTaxIncome: generateField<number>("otherTaxIncome", data?.otherTaxIncome, { type: "number", isValid: false, validation: {}, label: mapperFormGroupTitle.otherTaxIncome,withComma: true }),
        amountReduce: generateField<number>("amountReduce", data?.amountReduce, { type: "number", isValid: false, label: mapperFormGroupTitle.amountReduce, withComma: true }),
        total: generateFormGroup("total", "section1Head1TypesOfIncome_6", {
            totalIncome: generateField<number>("totalIncome", data?.totalIncome, { type: "number", isValid: false, validation: { isRequired: true, disabled: true }, label: mapperFormGroupTitle.totalIncome, withComma: true }),
            totalIncomeTax: generateField<number>("totalIncomeTax", data?.totalIncomeTax, { type: "number", isValid: false, validation: { isRequired: true }, label: mapperFormGroupTitle.totalIncomeTax, withComma: true }),
        }),
        incomeAmount: generateField<number>("incomeAmount", data?.incomeAmount, { type: "number", isValid: false, label: mapperFormGroupTitle.incomeAmount, withComma: true, validation: { isRequired: true } }),
        taxAmount: generateField<number>("taxAmount", data?.taxAmount, { type: "number", isValid: false, label: mapperFormGroupTitle.taxAmount, withComma: true, validation: { isRequired: true, disabled:  code === "code072" } }),
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        const entries = Object.entries(formData).filter(([key, _]) => formFieldKeys.includes(key));
        const newFormData = Object.fromEntries(entries);
        setFormData({ ...newFormData as typeof formData });
    }, []);

    const handleClose = useCallback(() => {
        if(context?.setOutOfSteps) {
            context.setOutOfSteps(null);
        }
    }, []);

    const handleSubmit = () => {
        const { isValid, fieldsErrorTrigger} = formValidator(formData);
        const data = Object.entries(formData).reduce((acc: any, [_, field]) => {
            if(field.type !== "form_group") {
                const tempField = field as Field<any>;
                if(!fieldsErrorTrigger[field.key as keyof typeof fieldsErrorTrigger]) {
                    tempField.error = undefined
                }
                if(field.key === "tin") {
                    acc[field.key] = (tempField.value as Option)?.value
                }
                else {
                    acc[tempField.key] = tempField.value;
                }
            }
            else {
                const tempField = field as FormGroup;
                Object.values(tempField.fields).map((field: Field<any>) => {
                    if(!fieldsErrorTrigger[field.key as keyof typeof fieldsErrorTrigger]) {
                        field.error = undefined;
                    }
                    acc[field.key] = field.value;
                })
            }
            
            return acc
        }, {});

        if(isValid) {
            onSubmit?.(data);
        }
        else {
            Object.values(fieldsErrorTrigger as IDataTypes<TriggerError>).map((trigger: TriggerError) => {
                const { key, message, errorKey, form_group_key, type } = trigger;
                setFormData((prev) => {
                    if (!form_group_key) {
                        (prev[key as keyof typeof prev] as Field<any>).error = message ?? errorKey;
                    }
                    else {
                        const group = prev[form_group_key as keyof typeof prev] as FormGroup;
                        if(key === "totalIncome" && type === "isRequired") {
                            (group.fields[key as keyof typeof group] as Field<any>).error = "FIELD_NOT_SET_1_1_3";
                        }
                        else {
                            (group.fields[key as keyof typeof group] as Field<any>).error = message ?? errorKey;
                        }
                    }
                    return { ...prev }
                })
            })
        }
    };

    const handleChange = useCallback((event: { key: string, value: DataType, formGroupKey?: string, type?: string }) => {
        const { key, value, formGroupKey } = event;
        if(event.type === "onInputChange") {
            if (key === "tin" && (value as string)?.length < 2) {
                setFormData((prev) => {
                    const field = prev[key as keyof typeof prev] as Field<any>;
                    field.options = [];
                    return { ...prev }
                })
            }
            else if(key === "tin") {
                getTaxpayers(value as string)
                    .then((res) => {
                        const options = res.data?.map((item: any) => ({ value: item.tin, label: item.name }));
                        setFormData((prev) => {
                            const field = prev[key as keyof typeof prev] as Field<Option>;
                            prev.taxpayerName.error = undefined
                            field.options = options;
                            return { ...prev }
                        })
                    })
                    .catch(err => console.log('getTaxpayers.error >>>', err));
            }
        }
        setFormData((prev) => {
            if(!formGroupKey) {
                const field = prev[key as keyof typeof prev] as Field<any>;
                field.value = value as typeof field.value;

                if(!field.validation?.disabled) {
                    if(key === "tin") {
                        field.value = { ...(value as Option), label: (value as Option).value};
                        prev.taxpayerName.value = (value as Option).label
                        return { ...prev }
                    }
                    if (!!prev.amountReduce) {
                        const sum = (prev.salary.value ?? 0) + (prev.civilSalary.value ?? 0) + (prev.allBenefits.value ?? 0) + (prev.otherTaxIncome.value ?? 0);
                        const calculateMax = sum - sum / 1.05;
                        prev.amountReduce.validation = { ...(prev.amountReduce.validation ?? {}), max: Math.trunc(calculateMax), maxError: "error_message_in_1_1_9" }
                    }
                    if (key === "incomeAmount" && !!prev.taxAmount) {
                        if (code === "code072") { // ավանդի տոկոս
                            const percent10 = getNumber(value) * 10 / 100;
                            prev.taxAmount.value = Math.trunc(percent10);
                        }
                        if ( code === "code082") {
                            const percent20 = getNumber(value) * 20 / 100;
                            const percent10 = getNumber(value) * 10 / 100;
                            prev.taxAmount.validation = { ...(prev.taxAmount.validation ?? {}), toEqual: [Math.trunc(percent10), Math.trunc(percent20)], toEqualError: "error_message_10_20_percent"}
                        }
                    }
                }
                if (prev.total?.fields?.totalIncome) {
                    const emptyValue = prev.salary?.value ?? prev.civilSalary?.value ?? prev.allBenefits?.value ?? prev.otherTaxIncome?.value ?? prev.amountReduce?.value;
                    prev.total.fields.totalIncome.value = emptyValue === undefined ? undefined : Math.trunc(getNumber(prev.salary?.value) + getNumber(prev.civilSalary?.value) + getNumber(prev.allBenefits?.value) + getNumber(prev.otherTaxIncome?.value) - getNumber(prev.amountReduce?.value));
                }
            }
            if(!!formGroupKey) {
                const group = prev[formGroupKey as keyof typeof prev] as FormGroup;
                const groupField = group.fields[key as keyof typeof group];
                if(!groupField.validation?.disabled) {
                    groupField.value = value;
                }
            }
            return { ...prev }
        })
    }, [code]);

    const handleLabelRewriter = (translationKey: string) => {
        if (!code) {
            if(translationKey === "tr_1_1_4") {
                return t("totalIncomeTax");
            }
            const findedField = section1Head1TypesOfIncome.find((item) => item.translation_key === translationKey);
            return findedField ? `${t(translationKey)} [${findedField.number}]` : t(translationKey);
        }
        else {
            if(translationKey === "tr_1_1_1") {
                return t("tr_1_2_1");
            }
            if(translationKey === "tr_1_1_2") {
                return t("tr_1_2_2");
            }
            const findedField = section1Head2TypesOfIncome.find((item) => item.translation_key === translationKey);
            return findedField ? `${t(translationKey)} [${findedField.number}]` : t(translationKey);
        }
    }

    return (
        <div className={styles.EmployerForm}>
            <div className={styles.Title}>
                <p>{t(mode === "create" ? "added_employer" : "edit")}</p>
                {!!code && (
                    <p>{t(`sec_1_2_${code}`)}</p>
                )}
            </div>
            <div className={styles.FormWrapper}>
                <Form data={formData} onChange={handleChange} labelRewriter={handleLabelRewriter}/>
                <Button onClick={handleSubmit}>{t("update")}</Button>
                <Button variant="outlined" onClick={handleClose}>{t("close")}</Button>
            </div>
        </div>
    )
})