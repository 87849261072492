import React, { useCallback, useEffect, useState } from 'react';
import { steps, mapperDeclarationHelperLinks } from './constants';
import { DeclarationWrapper, getRootDataStore, setRootDataStore, clearRootDataStore } from '../components';
import { useTranslation } from 'react-i18next';
import { PersonalDataForm, TaxpayerDataForm, BankDetailsForm } from './components';
import { fetchDictionaries } from '../../../store/dictionarySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    createDeclSRDraft,
    createDeclSRSubmit,
    declReturnAmountDataIsIncompleteFields,
    getDeclSRDraftByID,
    getDeclSRSubmitByID,
    getProfile,
    isNotResidentUser,
    srSendDataKeys,
    translationKeysByErrorCodes,
    updateDeclSRDraftByID
} from '../../../utils';
import { useNavigate } from '../../../hook';
import { toast } from 'react-toastify';

const getRootDataFromStore = () => {
    return getRootDataStore("decl_sr");
};

const setRootDataFromStore = (data: any) => {
    return setRootDataStore(data, 'decl_sr');
};

const clearRootDataFromStore = () => {
    return clearRootDataStore('decl_sr')
}

interface RenderContentProps {
    setHideSteps: any;
    setStep: any;
    setDataLoading: any;
    step: any;
    handleChangeStep: any;
    currentStep: any;
    setChangeStepCondition: any;
    setStepsConfig: any;
};

const sendDataKeyMapper: any = {
    documentType: "documentTypeId",
    firstName: "name"
}

const RenderContent: React.FC<RenderContentProps> = (props) => {
    const { setDataLoading, currentStep, setChangeStepCondition, setStepsConfig, setHideSteps } = props;
    const { id, type, declStatus } = useParams();
    const { t, i18n } = useTranslation();
    const auth: any = useSelector((state: any) => state.auth);
    const navigate = useNavigate();

    const initData = (callback: CallableFunction) => {
        if(!getRootDataFromStore()?.step) {
            setChangeStepCondition(false);
            setDataLoading(true);
            callback({ isRequest: true });
            let declarationRequest = null;
            if (id) {
                let action;
                switch (declStatus) {
                    case 'filled':
                        action = getDeclSRSubmitByID;
                        break;
                    default:
                        action = getDeclSRDraftByID;
                }
                declarationRequest = action(id);
            } else {
                declarationRequest = getProfile({ withAddress: true });
            }
            declarationRequest
                .then(res => {
                    if (res.data.decl_was_updated) {
                        toast.error(t('data_was_changed'), { position: 'top-right', theme: 'colored' });
                    }
                    const data = res.data;
                    data.taxpayerAddress = data.businessAddress ?? data.address ?? data.taxpayerAddress;
                    data.documentType = data.documentTypeId ?? data.documentType;
                    data.docCode = data.docCode ?? type;
                    setRootDataFromStore({ root: { ...data } });
                    callback({ isRequest: false, data });
                    setChangeStepCondition(true);
                    const incompleteFields = declReturnAmountDataIsIncompleteFields[isNotResidentUser(auth.role) ? 'notResident' : 'resident'];
                    if (incompleteFields.some(field => !data[field])) {
                        toast.error(t('DECL_SR_HAS_INCOMPLETE_FIELD'), { position: 'top-right', theme: 'colored' });
                    }
                })
                .catch(err => {
                    if (id && err.response.status === 404) {
                        navigate('/dashboard');
                    } else {
                        const error = translationKeysByErrorCodes(err);
                        callback({ isRequest: false, error });
                        if (error) {
                            toast.error(t(error), { position: 'top-right', theme: 'colored' });
                        }
                    }
                })
                .finally(() => {
                    setDataLoading(false);
                });
        }
    };

    const handleSubmit = useCallback((key: string) => {
        return (formData: any, error?: boolean) => {
            const root = getRootDataFromStore().root;
            const store = { ...getRootDataFromStore(), [key]: { completed: true, error }, root: { ...root, ...formData } };
            setRootDataFromStore(store);
            setStepsConfig((prev: any) => {
                prev.unCompleted = Object.values(steps).filter((item) => !(store[item.formId]?.completed)).map((step) => step.id);
                prev.errors = Object.values(steps).filter((item) => store[item.formId]?.error).map((item) => item.id);
                return { ...prev };
            });
        }
    }, []);

    const saveDraft = () => {
        if(!!getRootDataFromStore().root) {
            setHideSteps(true);
            setDataLoading(true);
            setTimeout(() => {
                const send = srSendDataKeys.reduce((acc: any, dataKey: string) => {
                    acc[dataKey] = getRootDataFromStore().root[sendDataKeyMapper[dataKey] ?? dataKey] ?? getRootDataFromStore().root[dataKey]
                    return acc
                }, {});
                let request = null;
                if (id && declStatus !== 'filled') {
                    request = updateDeclSRDraftByID(id, send);
                } else {
                    request = createDeclSRDraft(send);
                }
                request
                    .then(() => {
                        navigate('/declaration/draft');
                    })
                    .catch(err => {
                        console.log(err);
                        const error = translationKeysByErrorCodes(err);
                        if (error) {
                            toast.error(t(error), { position: 'top-right', theme: 'colored' });
                        }
                    })
                    .finally(() => {
                        setHideSteps(false);
                        setDataLoading(false);
                    });  
            }, 200);
        };
    };

    const register = () => {
        if(!!getRootDataFromStore().root) {
            setHideSteps(true);
            setDataLoading(true);
            setTimeout(() => {
                const send = srSendDataKeys.reduce((acc: any, dataKey: string) => {
                    acc[dataKey] = getRootDataFromStore().root[sendDataKeyMapper[dataKey] ?? dataKey] ?? getRootDataFromStore().root[dataKey]
                    return acc
                }, {});
                createDeclSRSubmit(i18n.language, declStatus !== 'filled' ? id : undefined, send)
                    .then((res) => {
                        if (res.data.correct) {
                            navigate('/declaration/filled');
                        } else {
                            console.log('res.data', res.data);
                        }
                    })
                    .catch(err => {
                        console.log('registerDeclaration >>>', err);
                        const key = translationKeysByErrorCodes(err);
                        if(key === 'BACKEND_VALIDATION_ERROR' && err.response?.data?.errors) {
                            const shows = (
                                <>
                                    {err.response?.data?.errors.map((message: string) => (
                                        <>
                                            <span>
                                                ● {message}
                                                <br/>
                                                <br/> 
                                            </span>
                                        </>
                                    ))}
                                </>
                            );
                            return toast.error(shows, { position: 'top-center', theme: 'colored', className: "toast--multiple--message" });
                        }
                        toast.error(t(key), { position: 'top-center', theme: 'colored' });
                    })
                    .finally(() => {
                        setHideSteps(false);
                        setDataLoading(false);
                    }); 
            }, 200);
        };
    };

    useEffect(() => {
        if(getRootDataFromStore().root) {
            setDataLoading(false);
        }
        const handleActionEvenet = (event: any) => {
            const { detail = {} } = event;
            const { type } = detail;
            if(type === "sr/remember_temporarily") {
                saveDraft();
            }
            if(type === "sr/register") {
                register();
            }
        }

        document.addEventListener('dispatchActionsEvent', handleActionEvenet);

        return () => {
          document.removeEventListener('dispatchActionsEvent', handleActionEvenet);
        };
    }, []);

    return (
        <div>
            {
                {
                    "personalData": (
                        <PersonalDataForm
                            onSubmit={handleSubmit(steps[1].formId)}
                            formId={steps[1].formId}
                            getRootDataStore={getRootDataFromStore}
                            initData={initData}
                        />
                    ),
                    "taxpayer_info": (
                        <TaxpayerDataForm 
                            onSubmit={handleSubmit(steps[2].formId)}
                            formId={steps[2].formId}
                            getRootDataStore={getRootDataFromStore}
                        />
                    ),
                    "bank_details": (
                        <BankDetailsForm 
                            onSubmit={handleSubmit(steps[3].formId)}
                            formId={steps[3].formId}
                            getRootDataStore={getRootDataFromStore}
                        />
                    ),
                }[currentStep?.formId as string]
            }
        </div>
    )
}

export const MobileSrDeclaration: React.FC = () => {
    const [stepsConfig, setStepsConfig] = useState({
        errors: Object.values(steps).filter((item) => getRootDataFromStore()?.[item.formId]?.error).map((item) => item.id),
        unCompleted: Object.values(steps).filter((item) => !(getRootDataFromStore()?.[item.formId]?.completed)).map((step) => step.id),
    });
    const { i18n } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDictionaries({dictArray: ['banksNames', 'documentTypes'], params: { documentTypes: { visibleDocument: true } }}));
        return () => {
            if (!window.location.pathname.includes("declaration/type/sr")) {
                clearRootDataFromStore();
            }
        }
    }, []);

    return (
        <DeclarationWrapper
            steps={steps}
            stepsConfig={stepsConfig}
            mapperDeclarationHelperLinks={mapperDeclarationHelperLinks(i18n.language)}
            excludeTranslationKeys={["decl_return_amount", "sr_pasport_data_step_title", "sr_bank_details_step_title"]}
            setRootDataStore={setRootDataFromStore}
            getRootDataStore={getRootDataFromStore}
            hideBtnSectionWithStepsList={["bank_details"]}
            declCode={"sr"}
        >
            {({ setHideSteps, setStep, setDataLoading, step, handleChangeStep, currentStep, setChangeStepCondition }: any) => (
                <RenderContent
                    setHideSteps={setHideSteps}
                    setStep={setStep}
                    setDataLoading={setDataLoading}
                    step={step}
                    handleChangeStep={handleChangeStep}
                    currentStep={currentStep}
                    setChangeStepCondition={setChangeStepCondition}
                    setStepsConfig={setStepsConfig}
                />
            )}
        </DeclarationWrapper>
    );
};