import React from 'react';
import styles from './styles.module.scss';
import { classnames } from '../../utils';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    size?: Size;
    color?: "primary" | "green" | "gray";
    shadowEffect?: boolean;
    variant?: "text" | "contained" | "outlined" | "link" | "dashed";
    icon?: React.ReactNode;
    iconPosition?: "left" | "right"
}
        
export const Button: React.FC<ButtonProps> = (props) => {
    const { 
        children, 
        size = "middle", 
        color = "primary",
        shadowEffect,
        variant = "contained",
        icon,
        iconPosition = "right",
        className = "",
        ...btnProps 
    } = props;

    const cs = classnames({
        [styles.Button]: true,
        [styles[size]]: !!size,
        [styles[color]]: !!color,
        [styles.shadow]: !!shadowEffect,
        [styles[variant]]: !!variant,
        [styles[`${iconPosition}-icon`]]: !!iconPosition,
        [className]: !!className
    });

    return (
        <button 
            className={cs}
            {...btnProps}
        >
            <>
                {children}
                {icon && icon}
            </>
        </button>
    );
};