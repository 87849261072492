import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from '../../hook';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    checkResetPasswordUsername,
    IResetPasswordFormData,
    resetPasswordFormDataDefault,
    IResetPasswordChangedDTO,
    resetUserPasswordChanged,
    IResetFieldsErrorsDefault,
    resetFieldsErrorsDefault,
    Exception,
    ExceptionType,
    passwordRegexp
} from '../../utils';
import { Button } from '../../components/Button';
import { Container } from '../../Layouts/Container';
import { Input } from '../../components/Input';
import { logoutAuth } from '../../store/authSlice';
import styles from './styles.module.scss';
import { NotificationDialog } from '../../components/NotificationDialog';

export const path: string = 'reset-password/:verificationToken?';

export default function ResetPassword() {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState<IResetPasswordFormData>(resetPasswordFormDataDefault);
    const [verificationTokenError, setVerificationTokenError] = useState<string>('');
    const [fieldsErrorMessage, setFieldsErrorMessage] = useState<Partial<IResetFieldsErrorsDefault>>(resetFieldsErrorsDefault);
    const [successfullyMessage, setSuccessfullyMessage] = useState<string>('');
    const [isBusy, setIsBusy] = useState<boolean>(false);

    useEffect(() => {
        if (params.verificationToken) {
            checkResetPasswordUsername(params.verificationToken)
                .then(res => {
                    setFormData(prev => ({ ...prev, username: res.data?.login || '' }));
                })
                .catch(err => {
                    setVerificationTokenError(err.response?.data?.message || 'FAILURE');
                });
        }

    }, [params.verificationToken]);

    const handleInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>, key: keyof IResetPasswordFormData) => {
        setFormData(prev => ({ ...prev, [key]: ev.target.value }));
    }, []);

    const requestResetPassword = async () => {
        setIsBusy(true);
        setFieldsErrorMessage(resetFieldsErrorsDefault);
        setSuccessfullyMessage('');
        try {
            let errorFields: Partial<IResetFieldsErrorsDefault> = {};
            if (!formData.username || !formData.password || !formData.confirmPassword) {
                if (!formData.username) errorFields.username = 'FIELD_NOT_SET';
                if (!formData.password) errorFields.password = 'FIELD_NOT_SET';
                if (!formData.confirmPassword) errorFields.confirmPassword = 'FIELD_NOT_SET';
                throw new Exception(JSON.stringify(errorFields), ExceptionType.frontend);
            }
            if (!formData.password.match(passwordRegexp) || formData.password.match(' ')) {
                errorFields.password = 'INVALID_PASSWORD';
                throw new Exception(JSON.stringify(errorFields), ExceptionType.frontend);
            }
            if (formData.password !== formData.confirmPassword) {
                errorFields.password = 'PASSWORDS_NOT_EQUAL';
                throw new Exception(JSON.stringify(errorFields), ExceptionType.frontend);
            }
            if (!params.verificationToken) {
                errorFields.generalError = 'INVALID_CONFIRMATION_CODE';
                throw new Exception(JSON.stringify(errorFields), ExceptionType.frontend);
            }
            const sendData: IResetPasswordChangedDTO = {
                key: params.verificationToken,
                newPassword: formData.password,
                username: formData.username
            }
            await resetUserPasswordChanged(sendData).catch(err => {
                let msg = err.response?.data?.message || 'FAILURE';
                if (msg === 'SAME_PASSWORD') {
                    errorFields.password = msg;
                } else {
                    errorFields.generalError = msg;
                }
                throw new Exception(JSON.stringify(errorFields), ExceptionType.backend);
            });
            setFormData(prev => ({ ...prev, username: '', password: '', confirmPassword: '' }));
            setSuccessfullyMessage(t('resetSuccessfully'));
            dispatch(logoutAuth());
        } catch (err) {
            if (err instanceof Exception) {
                const msgs = err.getObjectMessage();
                setFieldsErrorMessage(msgs);
            }
        } finally {
            setIsBusy(false);
        }
    };

    const closeResetForm = () => navigate('/sign-in');

    const handleNavigateSignin = () => {
        setSuccessfullyMessage("");
        navigate("/sign-in");
    };

    if (!params.verificationToken) return null;

    return (
        <section className={styles.RegistrationPageWrapper}>
            <Container 
                title={t("appName")}
                containerSize='middle' 
                className={styles.RegistrationPage}
            >
                <div className={styles.RegistrationContent}>
                    <div className={styles.Form}>
                        {verificationTokenError ? (
                            <div className="alert-danger">{t(verificationTokenError)}</div>
                        ) : (
                            <>
                                <Input
                                    placeholder={t("username")}
                                    inputSize='large'
                                    value={formData.username}
                                    errorMessage={fieldsErrorMessage.username ? t(fieldsErrorMessage.username) : ''}
                                    readOnly
                                />
                                <Input
                                    type="password"
                                    placeholder={t("password")}
                                    inputSize='large'
                                    value={formData.password}
                                    onChange={ev => handleInput(ev, 'password')}
                                    errorMessage={fieldsErrorMessage.password ? t(fieldsErrorMessage.password) : ''}
                                    autoComplete='new-password'
                                />
                                <Input
                                    type="password"
                                    placeholder={t("confirmPassword")}
                                    inputSize='large'
                                    value={formData.confirmPassword}
                                    onChange={ev => handleInput(ev, 'confirmPassword')}
                                    errorMessage={fieldsErrorMessage.confirmPassword ? t(fieldsErrorMessage.confirmPassword) : ''}
                                    autoComplete='new-password'
                                />
                                <div className={styles.btnsGroup}>
                                    <Button
                                        disabled={isBusy}
                                        onClick={isBusy ? () => null : requestResetPassword}
                                        className={styles.RegistrationBTN}
                                    >
                                        {t('confirm')}
                                    </Button>
                                    <Button variant="outlined" onClick={closeResetForm}>
                                        {t('close')}
                                    </Button>
                                </div>
                                {fieldsErrorMessage.generalError && <div className="alert-danger">{t(fieldsErrorMessage.generalError)}</div>}
                                <NotificationDialog 
                                    type='success'
                                    isOpen={!!successfullyMessage}
                                    close={handleNavigateSignin}
                                    messageTranslationKey={successfullyMessage}
                                    head={t("confirmation")}
                                    withoutCloseButton
                                />
                            </>
                        )}
                    </div>
                </div>
            </Container>
        </section>
    );
}
