import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { Container } from '../../Layouts/Container';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import { Wrapper } from '../../Layouts/Wrapper';
import * as Icons from "../../components/Icons";
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Table, TableHead } from '../../components/Table';
import { RootState } from '../../store';
import { initializeRows, initializeTotal, changePage as changePageAction } from '../../store/declarationDraftSlice';
import { getDeclSDDraftList, getDeclSDDraftCount, deleteDeclSDDraftByID, formatDate } from '../../utils';
import { DeclarationDraftCard } from '../../components/Card';
import type { DeclarationDraftCardProps } from '../../components/Card';
import { useNavigate } from '../../hook';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { Pagination } from "../../components/Pagination";
import Loading from "../../components/Loading";
import { Empty } from '../../components/Empty';
import { Tooltip } from '../../components/Tooltip';

export const path: string = 'declaration/draft';

const renderRowActions = (rowData: any, args: any) => {
    const editDeclaration = () => {
        args.navigation(`/declaration/type/${rowData.docCode}/${rowData.id}`);
    };

    const deleteDeclaration = () => {
        args.confirmDeleteAction(rowData);
    }

    return (
        <div className={styles.ActionGroup}>
            <Tooltip 
                title={args.editMsg} 
                type='info' 
                display='inline'
                followTheCursor
            >
                <span onClick={editDeclaration} className='action-item'>
                    <Icons.Edit />
                </span>
            </Tooltip>
            <Tooltip
                title={args.removeMsg}
                type='info'
                display='inline'
                followTheCursor
            >
                <span onClick={deleteDeclaration} className='action-item'>
                    <Icons.Trash />
                </span>
            </Tooltip>
        </div>
    );
};

const DeclarationDraftPage = () => {
    const [deleteDialog, setDeleteDialog] = useState<{ isOpen: boolean, data: any }>({ isOpen: false, data: null });
    const declarationDraft = useSelector((state: RootState) => state.declarationDraft);
    const { t, i18n } = useTranslation();
    const utils = useSelector((state: any) => state.utils);
    const auth: any = useSelector((state: any) => state.auth);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstOpen, setFirstOpen] = useState<boolean>(true);

    const toggleDeleteDialog = () => setDeleteDialog(prevState => ({ ...prevState, isOpen: !prevState.isOpen }));

    const confirmDeleteAction = (data: any) => setDeleteDialog(prevState => ({ ...prevState, isOpen: true, data }));

    const approveDeleteAction = (cb?: Function) => {
        if (deleteDialog.data?.id) {
            deleteDeclSDDraftByID(deleteDialog.data?.id)
                .then(() => {
                    const p = declarationDraft.currentPage > 1 && declarationDraft.rows.length === 1 ? declarationDraft.currentPage - 1 : declarationDraft.currentPage;
                    onSearch(p);
                    toggleDeleteDialog();
                })
                .catch(err => {
                    toast.error(t(err.response?.data?.message || err.message), { position: 'top-right', theme: 'colored' });
                })
                .finally(() => {
                    cb?.();
                });
        }
    };

    const onSearch = async (page?: number) => {
        setIsLoading(true);
        try {
            const filterForList = {
                currentPage: page || declarationDraft.currentPage,
                pageSize: declarationDraft.pageSize
            }
            const [list, total] = await Promise.all([getDeclSDDraftList(filterForList), getDeclSDDraftCount()]);
            dispatch(initializeRows(list.data));
            dispatch(initializeTotal(total.data.value || 0));
            setFirstOpen(false);
        } catch (err: any) {
            console.log('onSearch >>>', err);
            toast.error(t(err.response?.data?.message || err.message), { position: 'top-right', theme: 'colored' });
        } finally {
            setIsLoading(false);
        }
    }

    const changePage = (page: number) => {
        dispatch(changePageAction(page));
        onSearch(page);
    }

    useEffect(() => {
        if (!auth?.tin || (auth?.tin && !utils.tinIsValid)) {
            navigation('/dashboard');
        }
    }, [auth?.tin, utils.tinIsValid]);

    useEffect(() => {
        onSearch();
    }, []);

    const callActionsDetails = {
        navigation,
        confirmDeleteAction,
        editMsg: t('edit'),
        removeMsg: t('remove')
    };

    const tableHead: TableHead[] = useMemo(() => ([
        { key: "declaration", title: t("declaration"), colSpan: 1 },
        { key: "createdDate", title: t("createdDate"), colSpan: 1 },
        { key: "updatedDate", title: t("updatedDate"), colSpan: 1 },
        { key: "reportingPeriod", title: t("reportingPeriod"), colSpan: 1 },
        { key: "operations", title: t("operations"), colSpan: 1, colType: "action", colActionArgs: callActionsDetails }
    ]), [t]);

    let listView = null;
    const ROWS = declarationDraft.rows.map((item: any) => ({
        declaration: item[`declarationName_${i18n.language}`],
        createdDate: formatDate('DD.MM.YYYY HH:mm', new Date(item.createDate)),
        updatedDate: item.modifyDate ? formatDate('DD.MM.YYYY HH:mm', new Date(item.modifyDate)) : '',
        reportingPeriod: item.taxYear,
        docCode: item.docCode,
        id: item.id
    }));
    switch (utils.device.mode) {
        case 'desktop':
            listView = (
                <Table
                    data={ROWS}
                    theads={tableHead}
                    rowActions={renderRowActions}
                    verticalSize='middle'
                />
            );
            break;
        default:
            listView = ROWS.length ? ROWS.map((item: DeclarationDraftCardProps) => (
                <DeclarationDraftCard { ...item } key={`${item.id}/${item.updatedDate}`} onRemove={confirmDeleteAction}/>
            )) : <Empty title={t("dataNotFound")} />;

    }

    return (
        <DashboardLayout className={styles.DeclarationDraft}>
            <Container containerSize='large' className={styles.DeclarationDraftContainer} withoutPadding>
                <Wrapper title={t("declarationDraft")}>
                    <div className={styles.TableSection}>
                        {firstOpen && !ROWS.length ? null : listView}
                        {!!ROWS?.length && (
                            <Pagination
                                pageSize={declarationDraft.pageSize}
                                total={declarationDraft.total}
                                currentPage={declarationDraft.currentPage}
                                onChange={changePage}
                            />
                        )}
                    </div>
                </Wrapper>
                {isLoading && <Loading />}
                <ConfirmDialog isOpen={deleteDialog.isOpen} close={toggleDeleteDialog} approve={approveDeleteAction} questionMessageKey="removeDeclaration" />
            </Container>
        </DashboardLayout>
    );
};
export default DeclarationDraftPage;
