import { Role } from "../../../utils";

export const steps = {
    1: { id: 1, translationKey: "incoming_tax_social_payment", formId: "personalData", roles: [ Role.ROLE_NO_RESIDENT, Role.ROLE_RESIDENT ], reasonPresent: [true, false] },
    2: { id: 2, translationKey: "form1_section1", formId: "fromTaxAgent", roles: [ Role.ROLE_RESIDENT ],  reasonPresent: [true] },
    3: { id: 3, translationKey: "form1_section2", formId: "nonTaxable", roles: [ Role.ROLE_RESIDENT ], reasonPresent: [true]  },
    4: { id: 4, translationKey: "form1_section3", formId: "fromNonTaxAgent", roles: [ Role.ROLE_NO_RESIDENT, Role.ROLE_RESIDENT ], reasonPresent: [true, false] },
    5: { id: 5, translationKey: "form1_section4", formId: "socialCosts", roles: [ Role.ROLE_RESIDENT ], reasonPresent: [true]  },
    6: { id: 6, translationKey: "form1_section5", formId: "compensation", roles: [ Role.ROLE_NO_RESIDENT, Role.ROLE_RESIDENT ], reasonPresent: [true, false]  },
    7: { id: 7, translationKey: "form1_section6", formId: "selfEmployee", roles: [ Role.ROLE_RESIDENT ], reasonPresent: [true, false]  },
    8: { id: 8, translationKey: "register_declaration_wrapper", formId: "register", roles: [ Role.ROLE_RESIDENT, Role.ROLE_NO_RESIDENT ], reasonPresent: [true, false]  },
};

export const mapperFormGroupTitle = {
    salary: "salaryAndOtherEquivalentPayments",
    civilSalary: "section1Head1TypesOfIncome_2",
    allBenefits:"section1Head1TypesOfIncome_3",
    otherTaxIncome: "section1Head1TypesOfIncome_4",
    amountReduce: "section1Head1TypesOfIncome_5",
    totalIncome: "totalIncome",
    totalIncomeTax: "tr_1_1_4",
    taxAmount: "tr_1_2_4",
    incomeAmount: "tr_1_2_3"
};

export const mapperFieldLabels = {
    tin: "tr_1_1_1",
    taxpayerName: "tr_1_1_2"
};

export const headOneOrdering = {
    tin: 1,
    taxpayerName: 2,
    salary: 3,
    civilSalary: 4,
    otherTaxIncome: 6,
    allBenefits: 5,
    amountReduce: 7,
    totalIncome: 8,
    totalIncomeTax: 9
};

export const headTwoCodeOrdering = {
    code001: 1, // Ռոյալթի
    code002: 2, // Տոկոս
    code072: 3, // Ավանդի տոկոս
    code082: 4, // Պարտքային արժեթղթերի տոկոս
    code003: 5, // Շահում
    code004: 6, // Մրցանակ
    code005: 7, // Նվիրատվություն
    code007: 8, // Գույքի (բացառությամբ սույն գլխի 7-րդ կետում նշված) օտարումից եկամուտ
    code008: 9, // Կառուցապատողի կողմից շենքի, դրա բնակարանների կամ այլ տարածքների օտարումից եկամուտ
    code009: 10, // Վարձավճար
    code010: 11, // Շահաբաժին
    code011: 12, // Ապահովագրավճար
    code012: 13, // Կամավոր կենսաթոշակ
    code099: 14, // Այլ եկամուտներ
}