export const tins = `20216338
20221406
23205116
23404826
23406899
23419332
23420735
23422139
23425377
26678918
26705545
26707447
26709056
26709365
26714321
26718743
26720917
26725223
26729869
26730755
26731654
26732372
26734026
26736434
26737325
26740156
26740894
26741596
26743146
26743902
27869597
28071377
28231255
28237219
28237672
28242791
28244136
28248739
28255432
28257497
28260913
35298487
35305816
35305944
35306731
35307244
35316606
37470684
40180606
40189153
40579039
43282156
48014622
48016146
48016154
48016884
48018666
48018975
48019437
48019762
48021017
48023063
48024556
48025945
48027442
48028325
48028437
49734352
49830793
49838307
49839393
49840066
49840865
49841679
54690927
54758432
54759254
54760244
54761857
54764381
54766869
56211318
57355502
57376149
57489693
57500701
57507908
67144044
67145399
71041478
71086051
71086765
71090391
71093562
71095267
73045373
73048792
73055067
73055703
73060151
73063099
73065259
73066286
73074147
73077411
73080844
75477176
75480041
75480462
75480627
76876772
76886604
76887141
76887871
76891473
77605024
77989923
77990037
77993301
77993946
77994741
77995142
78676558
78708498
78708902
78711733
78717455
84949557
86137998
88325696
73081056
25406606
49831564
23407273`;