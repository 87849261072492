import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDocumentTypes, getBanksNames, getEmployeeIncomeTypes } from '../utils';
import type { SerializedError } from '@reduxjs/toolkit';

const sliceName = "dictionary";

const dictionariesMaper = {
    documentTypes: getDocumentTypes,
    banksNames: getBanksNames,
    employeeIncomeTypes: getEmployeeIncomeTypes

};

const combineKeysMapper = {
    banksNames: "code",
    documentTypes: "id",
    employeeIncomeTypes: "code"
}

const combineData = (data: any[], combineKey: string) => {
    return data.reduce((acc: IDataTypes<DocumentTypes>, item: DocumentTypes) => {
        acc[item[combineKey as keyof typeof item]] = item;
        return acc;
    }, {});
}

type DictionariesMaperKeys = keyof typeof dictionariesMaper;


export const fetchDocumentTypes: any = createAsyncThunk(`${sliceName}/fetchDocumentTypes`, async () => {
    const response = await getDocumentTypes();
    return response.data;
});

export const fetchBanksNamesData: any = createAsyncThunk(
    `${sliceName}/getBanksNames`,
    async () => {
        const response = await getBanksNames();
        return response.data;
    }
);

export const fetchEmployeeIncomeTypes: any = createAsyncThunk(
    `${sliceName}/getEmployeeIncomeTypes`,
    async () => {
        const response = await getEmployeeIncomeTypes();
        return response.data;
    }
);

export const fetchDictionaries: any = createAsyncThunk(`${sliceName}/fetchDictionaries`, async ({ dictArray, params }: { dictArray: DictionariesMaperKeys[], params?: any }) => {
    const promisesArr = dictArray.map((key: DictionariesMaperKeys) => {
        switch (key) {
            case "documentTypes":
                return dictionariesMaper[key]?.(params?.[key])
            default:
                return dictionariesMaper[key]?.();
        }
    })
    return Promise.all(promisesArr).then((responses: any) => {
        return dictArray.reduce((acc: any, key: string, index: number) => {
            const data = responses[index].data;
            const combineKey = combineKeysMapper[key as keyof typeof combineKeysMapper];
            acc[key] = combineData(data, combineKey ?? "id");
            return acc
        }, {});
    })
});

interface IDictionaryState<T> {
    data: IDataTypes<T>;
    isRequest: boolean;
    error?: SerializedError | null;
};

interface IDictionary {
    documentTypes: IDictionaryState<DocumentTypes>;
    banksNames: IDictionaryState<BankTypes>;
    employeeIncomeTypes: IDictionaryState<IEmployeeIncomeTypes>;
    isRequest: boolean;
    error: SerializedError | null
};

const initialState: IDictionary = {
    documentTypes: { data: {}, isRequest: false, error: null },
    banksNames: { data: {}, isRequest: false, error: null },
    employeeIncomeTypes: { data: {}, isRequest: false, error: null },
    error: null,
    isRequest: false
};

export const dictionarySlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDocumentTypes.pending, (state) => {
                state.documentTypes.isRequest = true;
                state.documentTypes.error = null;
            })
            .addCase(fetchDocumentTypes.fulfilled, (state, action) => {
                state.documentTypes.isRequest = false;
                state.documentTypes.data = combineData(action.payload, combineKeysMapper.documentTypes)
            })
            .addCase(fetchDocumentTypes.rejected, (state, action) => {
                state.documentTypes.error = action.error;
                state.documentTypes.isRequest = false;
            })
            .addCase(fetchBanksNamesData.pending, (state) => {
                state.banksNames.isRequest = true;
                state.banksNames.error = null;
            })
            .addCase(fetchBanksNamesData.fulfilled, (state, action) => {
                state.banksNames.isRequest = false;
                state.banksNames.data = combineData(action.payload, combineKeysMapper.banksNames)
            })
            .addCase(fetchBanksNamesData.rejected, (state, action) => {
                state.banksNames.error = action.error;
                state.banksNames.isRequest = false;
            })
            .addCase(fetchEmployeeIncomeTypes.pending, (state) => {
                state.employeeIncomeTypes.isRequest = true;
                state.employeeIncomeTypes.error = null;
            })
            .addCase(fetchEmployeeIncomeTypes.fulfilled, (state, action) => {
                state.employeeIncomeTypes.isRequest = false;
                state.employeeIncomeTypes.data = combineData(action.payload, combineKeysMapper.banksNames)
            })
            .addCase(fetchEmployeeIncomeTypes.rejected, (state, action) => {
                state.employeeIncomeTypes.error = action.error;
                state.employeeIncomeTypes.isRequest = false;
            })
            .addCase(fetchDictionaries.pending, (state) => {
                state.isRequest = true;
                state.error = null;
            })
            .addCase(fetchDictionaries.fulfilled, (state, action) => {
                const { isRequest, error, ...rest } = state;
                Object.keys(rest).map((key: string) => {
                    const typedKey = key as keyof typeof rest
                    state[typedKey]!.data = action.payload[typedKey]
                })
                state.isRequest = false;
            })
            .addCase(fetchDictionaries.rejected, (state, action) => {
                state.isRequest = false;
                state.error = action.error;
            })
    }
})

export default dictionarySlice.reducer;
