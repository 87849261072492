import React, { forwardRef } from 'react';
import styles from './styles.module.scss';
import { classnames, formatNumberWithCommas } from '../../utils';
import * as Icons from "../Icons";
import { Tooltip } from '../Tooltip';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>  {
    label?: string | React.ReactNode;
    inputSize?: "small" | "middle" | "large";
    iconPrefix?: React.ReactNode;
    withMobileCode?: boolean;
    withmobilecodestyle?: object;
    iconprefixstyle?: object;
    errorMessage?: string | React.ReactNode;
    onClickPrefix?: (arg: any) => any;
    prefixProps?: { className?: string };
    withCircleBrackets?: boolean;
    componentStyle?: any;
    errorWithTooltip?: boolean;
    withInputLabelTooltip?: boolean;
    withInputLabelTooltipType?: "error" | "info" | "warning" | "white";
    withInputLabelTooltipPosition?: any;
    warningMessage?: string | React.ReactNode;
}
export const Input: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const {
        label,
        inputSize = "small",
        errorMessage,
        iconPrefix,
        onClickPrefix,
        prefixProps,
        withMobileCode,
        withCircleBrackets,
        componentStyle,
        errorWithTooltip,
        withInputLabelTooltip,
        withInputLabelTooltipType,
        withInputLabelTooltipPosition,
        warningMessage,
        ...restProps
    } = props;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cursorFixator = () => {
            let caret = e.target.selectionStart || 0;
            const element = e.target;
            const originalVal = e.target.value ?? "";
            const newVal = originalVal.replaceAll(",", "");
            const formattedNewVal = formatNumberWithCommas(newVal);

            const originalCommaCount = (originalVal.match(/,/g) || []).length;
            const newCommaCount = (formattedNewVal.match(/,/g) || []).length;

            if (newCommaCount > originalCommaCount && caret > 0) {
                caret += newCommaCount - originalCommaCount;
            } else if (newCommaCount < originalCommaCount && caret > 0) {
                caret -= originalCommaCount - newCommaCount;
            }

            caret = Math.max(0, Math.min(caret, formattedNewVal.length));

            window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
            });
        }
        // @ts-ignore
        props.onChange?.(e, cursorFixator);
        
    };

    const cs = classnames({
        [styles.Input]: true,
        [styles[inputSize]]: !!inputSize,
        [styles.Disabled]: !!restProps.disabled,
        [styles.Error]: !!errorMessage,
        [styles.Warning]: !!warningMessage,
        [styles.ErrorWithTooltip]: !!errorWithTooltip,
        [styles.WithBrackets]: !!withCircleBrackets,
        "cs_-input": true
    });

    const csError = classnames({
        errorMessage: true,
        [styles.ErrorMessage]: !!errorMessage,
        [styles.WarningMessage]: !!warningMessage,
        [styles.ErrorTooltip]: !!errorWithTooltip
    });

    const csPrefix = classnames({
        [styles.IconPrefix]: true,
        [prefixProps?.className as string]: !!prefixProps?.className
    });

    const csINPUT = classnames({
        [styles.InputFieldWrapper]: true,
        [styles.WithPrefix]: !!iconPrefix
    });


    return (
        <label className={cs} style={componentStyle || {}}>
            {label && <span className={styles.Label}>{label}</span>}
            <span className={csINPUT}>
                {withMobileCode && <span className={styles.withMobileCode} style={props.withmobilecodestyle || {}}>+(374)</span>}
                {withInputLabelTooltip ? (
                    <Tooltip title={restProps.value} type={withInputLabelTooltipType} position={withInputLabelTooltipPosition} followTheCursor>
                        <input {...restProps} ref={ref} />
                    </Tooltip>
                ) : <input {...restProps} ref={ref} onChange={handleInputChange} />}
                {iconPrefix && <span className={csPrefix} onClick={onClickPrefix} style={props.iconprefixstyle || {}}>{iconPrefix}</span>}
            </span>
            {(errorMessage || warningMessage) && (
                <div className={csError}>
                    {!errorWithTooltip && (
                        <>
                            <span className={styles.Icon}><Icons.Error /></span>
                            <span>{errorMessage || warningMessage}</span>
                        </>
                    )}
                    {!!errorWithTooltip && (
                        <Tooltip title={errorMessage} type='error'>
                            <span className={styles.Icon}><Icons.Error /></span>
                        </Tooltip>
                    )}
                </div>
            )}
        </label>
    );
});

interface InputRangeProps {
    startProps?: InputProps;
    endProps?: InputProps;
    label?: string;
    showOneLabel?: boolean;
    componentStyle?: any;
}

export const InputRange: React.FC<InputRangeProps> = (props) => {
    const { startProps, endProps, label, componentStyle, showOneLabel = true } = props;

    const cs = classnames({
        [styles.RangeInput]: true,
        [styles.OneLabel]: !!showOneLabel
    })

    return (
        <div className={styles.RangeInputWrapper} style={componentStyle || {}}>
            {(showOneLabel && !!label) && <span className={styles.Label}>{label}</span>}
            <div className={cs}>
                <Input {...startProps}  />
                <div className={styles.RangeLine} />
                <Input {...endProps} label={!showOneLabel ? undefined : endProps?.label} />
            </div>
        </div>
    )
}
