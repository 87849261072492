import React, {useCallback, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { classnames } from '../../utils';
import styles from './styles.module.scss';
import loadingImage from '../../assets/images/loadingImg.gif';

interface ConfirmDialogProps {
    isOpen: boolean;
    close: Function;
    onCloseAction?: Function;
    approve: Function;
    questionMessageKey?: string;
    size?: 'sm' | 'md' | 'lg' | 'full-screen' | 'fit-content' | "s-fit-content";
    className?: string;
    withoutHandleProcess?: boolean
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
    const [process, setProcess] = useState<boolean>(false);
    const { t } = useTranslation();

    const toggleProcess = useCallback(() => setProcess(prev => !prev), []);

    const approve = () => {
        if(!props.withoutHandleProcess) {
            toggleProcess();
        };
        props.approve(!props.withoutHandleProcess ? toggleProcess : () => {});
    }

    return (
        <Modal
            className={styles.ConfirmDialog}
            title={t('confirmTitle')}
            size={props.size ?? "s-fit-content"}
            isOpen={props.isOpen}
            closeMessage={t('No')}
            onClose={props.close}
            onCloseAction={props.onCloseAction}
            footerExtraComponents={(
                <Button size="s" color="primary" onClick={process ? () => {} : approve} disabled={process}>
                    {
                        !props.withoutHandleProcess && process ? (
                                <>
                                    {t('wait')} <img src={loadingImage} width={30} />
                                </>
                            ) :
                            t('Yes')
                    }
                </Button>
            )}
            footerExists
            mode='head-colored'
            type='info'
        >
            <div className={classnames({[styles.questionMessage]: true, [props.className as string]: !!props.className })}>{t(props.questionMessageKey || 'are_you_sure')}</div>
        </Modal>
    );
};
