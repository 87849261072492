import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../../store";
import { useEffect, useRef, useState } from "react";
import { Form } from "../../../MobileIncomingTax/components/Form";
import { generateField } from "../../../MobileIncomingTax/utils";
import { fieldValidator, formValidator, formatDate, isResidentUser, numberRegexp, regexpWithDocType, isValidDate } from "../../../../../utils";

export const TaxpayerDataForm: React.FC<{ onSubmit: CallableFunction, getRootDataStore: () => any, formId: string }> = (props) => {
    const { onSubmit, getRootDataStore, formId } = props;
    const root = getRootDataStore().root;
    const { i18n } = useTranslation();
    const auth: any = useSelector((state: RootState) => state.auth);
    const dictionary = useSelector((state: RootState) => state.dictionary);
    const [formData, setFormData] = useState({
        birthDate: 
            generateField<Date>(
                "birthDate",
                root?.birthDate ? new Date(root?.birthDate) : root?.birthDate,
                { 
                    type: "date",
                    mode: "dd.MM.yyyy",
                    validation: { 
                        isRequired: true,
                        disabled: isResidentUser(auth.role),
                        max: new Date(new Date().setFullYear(new Date().getFullYear() - 14)),
                    },
                    label: "DECL_SR_BIRTHDATE"
                }
            ),
        documentType: 
            generateField<Option>(
                "documentType",
                { label: root[`documentType_${i18n.language}`], value: root?.documentType },
                { 
                    type: "select",
                    validation: {
                        isRequired: true,
                        disabled: isResidentUser(auth.role)
                    },
                    label: "DECL_SR_DOCUMENT",
                    options: []
                }
            ),
        documentNumber:
            generateField<string>(
                "documentNumber",
                root?.documentNumber,
                {
                    validation: {
                        isRequired: true,
                        disabled: isResidentUser(auth.role),
                        patternError: "DECL_SR_INVALID_INPUT",
                        pattern: regexpWithDocType[+root?.documentType as keyof typeof regexpWithDocType],
                        maxLength: 20,
                    },
                    label: "DECL_SR_DOCUMENT_NUMBER"
                }
            ),
        documentIssueDate: 
            generateField<Date>(
                "documentIssueDate",
                root?.documentIssueDate ? new Date(root?.documentIssueDate) : root?.documentIssueDate,
                {
                    type: "date",
                    mode: "dd.MM.yyyy",
                    validation: {
                        isRequired: true,
                        disabled: isResidentUser(auth.role),
                        max: new Date()
                    },
                    label: "DECL_SR_ISSUE_DATE"
                }
            ),
        documentIssuer: 
            generateField<string>(
                "documentIssuer",
                root?.documentIssuer,
                {
                    validation: {
                        isRequired: true,
                        disabled: isResidentUser(auth.role),
                        maxLength: 5,
                        pattern: numberRegexp,
                        patternError: "DECL_SR_INVALID_INPUT"     
                    },
                    label: "DECL_SR_ISSUER"
                }
            ),
    });
    const dataRef = useRef({ data: formData, error: false });

    useEffect(() => {
        const options = dictionary.documentTypes.isRequest ? [] : Object.values(dictionary.documentTypes?.data ?? {}).map((item: any) => ({ label: item[`name_${i18n.language}`], value: item.id }));
        setFormData((prev) => ({ ...prev, documentType: { ...prev.documentType, options, value: options.find((item) => item.value === prev.documentType.value?.value ) }}));
    }, [dictionary.isRequest, i18n.language]);

    const handleSubmit = () => {
        const passData = Object.entries(dataRef.current?.data ?? {}).reduce((acc: any, entries: any) => {
            const [key, field] = entries;
            switch (key) {
                case "documentType":
                    acc[key] = field.value?.value;
                    break;
                case "documentIssueDate":
                case "birthDate":
                    acc[key] = field.value && isValidDate(field.value) ? formatDate("YYYY-MM-DD", field.value) : field.value;
                    break
                default:
                    acc[key] = field.value;
                    break;
            }
            return acc;
        }, {})
        onSubmit?.(passData, dataRef.current?.error);
    };
    
    useEffect(() => {
        if (getRootDataStore()[formId]?.error) {
            const { fieldsErrorTrigger } = formValidator(formData);
            setFormData((prev) => {
                Object.values(fieldsErrorTrigger).map((triggerError) => {
                    const error = triggerError as TriggerError;
                    const formField = prev[error.key as keyof typeof prev];
                    if(error && error.type !== "none" && formField) {
                        return formField.error = error.message ?? error.errorKey;
                    }
                })
                return { ...prev }
            })
        }
        return () => {
            if (window.location.pathname.includes("declaration/type/sr")) {
                handleSubmit();
            };
        };
    }, []);

    useEffect(() => {
        const validator = formValidator(formData);
        dataRef.current = { data: formData, error: !validator.isValid };
    }, [JSON.stringify(formData)]);

    const handleChange = (data: { key: string, value: any}) => {
        const { key, value } = data;
        setFormData((prev) => {
            const field = prev[key as keyof typeof prev];
            if(value && field.validation?.maxLength && value?.length > field.validation.maxLength) {
                return { ...prev };
            }
            if(!field.validation?.disabled) {
                field.value = value;
            }
            if(key === "documentType" && value) {
                (prev.documentNumber.validation as any).pattern = regexpWithDocType[+(value as Option).value as keyof typeof regexpWithDocType];
                const { triggerError } = fieldValidator(prev.documentNumber);
                if(triggerError.type !== "none") {
                    prev.documentNumber.error = triggerError?.message ?? triggerError?.errorKey
                }
                else {
                    prev.documentNumber.error = undefined
                }
            }
            const { triggerError } = fieldValidator(field);
            if(triggerError.type !== "none") {
                field.error = triggerError?.message ?? triggerError?.errorKey
            }
            else {
                field.error = undefined
            }
            return { ...prev };
        })
    };

    return (
        <div>
            <Form data={formData} onChange={handleChange} />
        </div>
    )
};