import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../store';
import { Role, classnames, dispatchActionsEvent } from '../../../../utils';
import { MobileIncomeTaxContext } from '../../MobileIncomingTax/mobile_income_tax.context';
import { Stepper } from '../../../Stepper';
import { RenderingWrapperTitle } from '../RenderWrapperTitle';
import { Wrapper } from '../../../../Layouts/Wrapper';
import Loading from '../../../Loading';
import { Button } from '../../../Button';
import { useTranslation } from 'react-i18next';

interface DeclarationWrapper {
    steps: {[ key: number]: { id: number, translationKey: string, formId: string, roles: Role[]}},
    children?: CallableFunction;
    getRootDataStore?: () => any;
    setRootDataStore?: CallableFunction;
    stepsConfig?: { errors: any[], unCompleted: any[] };
    excludeTranslationKeys?: string[];
    mapperDeclarationHelperLinks?: { [key: string ]: string };
    hideBtnSectionWithStepsList?: string[];
    initialChangeStepCondition?: boolean;
    declCode?: string;
}

export const DeclarationWrapper: React.FC<DeclarationWrapper> = (props) => {
    const { steps, children, getRootDataStore, setRootDataStore, stepsConfig, excludeTranslationKeys, mapperDeclarationHelperLinks, hideBtnSectionWithStepsList, initialChangeStepCondition = true, declCode } = props;
    const [outOfSteps, setOutOfSteps] = useState<any>(null);
    const [hideSteps, setHideSteps] = useState(false);
    const [step, setStep] = useState(getRootDataStore?.()?.step ?? 1);
    const { id } = useParams();
    const [dataLoading, setDataLoading] = useState(!!id);
    const utils = useSelector((state: RootState) => state.utils);
    const currentStep = useMemo(() => (steps[step as keyof typeof steps]), [step]);
    const { t } = useTranslation();
    const [changeStepCondition, setChangeStepCondition] = useState(initialChangeStepCondition);

    const csWrapper = classnames({
        [styles.DeclarationLayoutWrapper]: true,
    });

    const csIncomingTax = classnames({
        [styles.DeclarationLayout]: true,
        [styles.MobileView]: !!utils.mobileView,
        [styles.Hidden]: !!outOfSteps?.children,
        [styles.BluredLoading]: dataLoading
    });

    const handleChangeStep = (action: { value: number, type: string }) => {
        if(changeStepCondition) {
            setStep(action.value);
            setRootDataStore?.({ ...(getRootDataStore?.() ?? {}), step: action.value });
        }
    };

    const handleNext = () => {
        handleChangeStep({ value: step + 1, type: "next" });
    };

    useEffect(() => {
        if(getRootDataStore?.()?.step) {
            setStep(getRootDataStore().step)
        }
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [step]);

    const handleRememeberTemp = () => {
        dispatchActionsEvent({ type: declCode ? `${declCode}/remember_temporarily` : "remember_temporarily", payload: null });
    };

    return (
        <MobileIncomeTaxContext.Provider value={{ outOfSteps, setOutOfSteps }}>
            {!hideSteps && (
                <div className={csIncomingTax}>
                    <Stepper 
                        steps={Object.values(steps)}
                        step={step}
                        className={styles.Stepper}
                        changeStep={handleChangeStep}
                        stepsError={stepsConfig?.errors ?? []}
                        unCompletedList={stepsConfig?.unCompleted ?? []}
                        highlightUncompleted={true}
                    />
                    <Wrapper
                        className={csWrapper}
                        title={
                            <RenderingWrapperTitle
                                translantionKey={currentStep.translationKey}
                                excludeTranslationKeys={excludeTranslationKeys}
                                mapperDeclarationHelperLinks={mapperDeclarationHelperLinks}
                                formId={currentStep.formId}
                            />
                        }
                    >
                        {children?.({ setHideSteps, setStep, setDataLoading, step, handleChangeStep, currentStep, setChangeStepCondition })}
                        <div className={styles.BTNSection}>
                            {(currentStep.formId !== "register" && !hideBtnSectionWithStepsList?.includes(currentStep.formId)) && (
                                <>
                                    <Button onClick={handleNext}>{t("next")}</Button>
                                    <Button 
                                        variant='outlined' 
                                        className={styles.Remember}
                                        onClick={handleRememeberTemp}
                                    >
                                        {t("remember_temporarily")}
                                    </Button>
                                </>
                            )}
                        </div>
                    </Wrapper>
                </div>
            )}
            {dataLoading && <Loading />}
            {!!outOfSteps?.children && (
                <div className={classnames({[outOfSteps?.className]: !!outOfSteps?.className, [styles.OutOfSteps]: true })}>
                    {outOfSteps?.children}
                </div>
            )}
        </MobileIncomeTaxContext.Provider>
    );
};