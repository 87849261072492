import { useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import loadingImage from '../../assets/images/loadingImg.gif';
import { Select } from '../Select';
import { Modal } from "../Modal";
import { Input } from '../Input';
import { FileUploadDialog } from '../FileUploadDialog';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

type DeviceTypes = "desktop" | "mobile" | "tablet";

interface ISignature {
    isOpenSignType: boolean;
    toggleIsOpenSignType: Function;
    loading: boolean;
    signTypeButtonDisabled: boolean;
    signTypeButtonConfirmed: Function;
    signTypeOptions: { value: string }[];
    signTypeSelected: { value: string } | null;
    signTypeIsSearchable?: boolean;
    signTypeNoOptionsMessageKey?: string;
    signTypeNoDropdown?: boolean;
    signTypeHandle: Function;

    isOpenMID?: boolean;
    toggleIsOpenMID?: Function;
    openMIDButtonConfirmed?: Function;
    openMIDSignError?: string;
    closeMIDSignError?: CallableFunction;
    mIDNumber?: string;
    setMIDNumber?: (val: string) => void;
    timerForSign?: number;

    isOpenCoSignCertificate?: boolean;
    toggleIsOpenCoSignCertificate?: Function;
    uploadCoSignCertificate?: Function;

    isOpenCoSign?: boolean;
    toggleIsOpenCoSign?: Function;
    cosignButtonDisabled?: boolean;
    cosignButtonConfirmed?: Function;
    closeButtonDisabled?: boolean;
    cancelProcess?: boolean;
    processingCancelBtn?: boolean;
    handleCancelProcess?: Function;
    onlyMIDFlow?: DeviceTypes[];
}

export const Signature = (props: ISignature) => {
    const { t } = useTranslation();
    const utils = useSelector((state: RootState) => state.utils);
    const [isOpenErrorDialog, setIsOpenErrorDialog] = useState<boolean>(false);
    const midButtonDisabled = props.loading || !props.mIDNumber || !!(props.mIDNumber && !props.mIDNumber.match(/^[0-9]{8}$/));

    const handleMIDNumber = (ev: any) => {
        const number = ev.target.value;
        if (!number || (number && number.match(/^[0-9]{1,8}$/))) {
            props.setMIDNumber?.(number);
        }
    }
    const timeSecondsAll = props.timerForSign || 0;
    const timeMinute = Math.floor(timeSecondsAll / 60);
    const timeSecond = Math.floor(Math.floor(timeSecondsAll % 60));

    useEffect(() => {
        setIsOpenErrorDialog(!!props.openMIDSignError);
    }, [props.openMIDSignError]);

    const handleCloseSignType = () => {
        props.signTypeHandle?.(null);
        props.toggleIsOpenSignType?.();
    };

    useEffect(() => {
        if(props.isOpenSignType && props.onlyMIDFlow?.includes(utils.device?.mode as DeviceTypes)) {
            const mid = props.signTypeOptions.map(({ value }) => ({ value, label: t(value) })).find((item) => item.value === "MID");
            props.signTypeHandle?.(!mid ? null : mid);
            props.signTypeButtonConfirmed(!mid ? null : mid);
        }
    }, [props.isOpenSignType, props.signTypeOptions, utils.device?.mode, props.onlyMIDFlow]);

    return (
        <>
            <Modal
                title={t('signType')}
                closeMessage={t('close')}
                isOpen={props.isOpenSignType}
                onClose={handleCloseSignType}
                size="s-fit-content"
                footerExtraComponents={(
                    <Button
                        size="s"
                        onClick={props.signTypeButtonDisabled ? () => null : () => props.signTypeButtonConfirmed()}
                        disabled={props.signTypeButtonDisabled}
                    >
                        {
                            props.loading ? (
                                    <>
                                        {t('wait')} <img src={loadingImage} width={30} />
                                    </>
                                ) :
                                t('confirm')
                        }
                    </Button>
                )}
                footerExists
                mode='head-colored'
                className={styles.SelectSignTypeModal}
            >
                <div className={styles.signTypeSelect}>
                    <Select
                        options={props.signTypeOptions.map(({ value }) => ({ value, label: t(value) }))}
                        onChange={props.signTypeHandle}
                        onInputChange={() => {}}
                        value={props.signTypeSelected}
                        isSearchable={props.signTypeIsSearchable}
                        noOptionsMessageKey={props.signTypeNoOptionsMessageKey}
                        noDropdown={props.signTypeNoDropdown}
                        placeholder={t("select_type")}
                    />
                </div>
            </Modal>
            <Modal
                closeMessage={t(props.processingCancelBtn ? 'cancel' : 'close')}
                isOpen={props.isOpenMID || false}
                onClose={() => {
                    props.toggleIsOpenMID?.();
                    if(props.onlyMIDFlow?.includes(utils.device?.mode as DeviceTypes)) {
                        handleCloseSignType();
                    }
                }}
                size="fit-content"
                className={styles.SelectOperatorModal}
                closeButtonDisabled={props.closeButtonDisabled}
                cancelProcess={props.cancelProcess}
                processingCancelBtn={props.processingCancelBtn}
                handleCancelProcess={props.handleCancelProcess}
                footerExtraComponents={(
                    <Button
                        size="s"
                        onClick={midButtonDisabled ? () => null : () => props.openMIDButtonConfirmed?.()}
                        disabled={midButtonDisabled || props.cancelProcess}
                    >
                        {
                            props.loading && !props.cancelProcess ? (
                                    <>
                                        {t('wait')} <img src={loadingImage} width={30} />
                                    </>
                                ) :
                                t('confirm')
                        }
                    </Button>
                )}
                footerExists
                mode='head-colored'
                withoutCancelIcon
            >
                <div className={styles.SignMIdContainer}>
                    <h2 className={styles.mobileIDMessage}>{t('mobileIDMessage')}</h2>
                    <div className={styles.MIDNumber}>
                        <Input
                            value={props.mIDNumber}
                            onChange={handleMIDNumber}
                            disabled={props.loading}
                            withMobileCode
                        />
                        {timeSecondsAll > 0 && (
                            <div className={styles.timerForSign}>
                                {timeMinute > 0 ? `${timeMinute} ${t('minute_prefix')}` : ''}
                                &nbsp;
                                {timeSecond > 0 ? `${timeSecond} ${t('second_prefix')}` : ''}
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isOpenErrorDialog}
                onClose={() => {
                    props.closeMIDSignError?.();
                    setIsOpenErrorDialog(prev => !prev);
                }}
                closeMessage={t('close')}
                size="s-fit-content"
                footerExists
                mode='head-colored'
                type='error'
            >
                <div className={styles.openMIDSignErrorContainer}>
                    <h2 className={styles.openMIDSignError}>{props.openMIDSignError}</h2>
                </div>
            </Modal>
            <FileUploadDialog
                isOpen={props.isOpenCoSignCertificate || false}
                close={() => props.toggleIsOpenCoSignCertificate?.()}
                approve={(file: any, cb?: Function) => props.uploadCoSignCertificate?.(file, cb)}
                modalTitle="attachCertificate"
            />
            <Modal
                title={t('signatureByCoSignTitle')}
                closeMessage={t('close')}
                isOpen={props.isOpenCoSign || false}
                onClose={() => props.toggleIsOpenCoSign?.()}
                size="s-fit-content"
                mode='head-colored'
                footerExtraComponents={(
                    <Button
                        size="s"
                        onClick={props.cosignButtonDisabled ? () => null : () => props.cosignButtonConfirmed?.()}
                        disabled={!!props.cosignButtonDisabled}
                    >
                        {
                            props.loading ? (
                                    <>
                                        {t('wait')} <img src={loadingImage} width={30} />
                                    </>
                                ) :
                                t('signatureByCoSignConfirm')
                        }
                    </Button>
                )}
                footerExists
            >
                <div>
                    <Input
                        label={t("signatureByCoSignLogin")}
                        inputSize='middle'
                        className="mb-3"
                    />
                    <Input
                        type="password"
                        label={t("signatureByCoSignPassword")}
                        inputSize='middle'
                    />
                </div>
            </Modal>
        </>
    );
}
