import React from 'react';
import { useTranslation } from 'react-i18next';

const LoginInfo = (props) => {
    const { t } = useTranslation();
    const { languageModule } = props;

    return (
        <div className="login-info">
            <p>{!!languageModule ? languageModule.paragraph1 : t("app_sign_in_info.paragraph1")}</p>
            <p>{!!languageModule ? languageModule.paragraph2 : t("app_sign_in_info.paragraph2")}</p>
        </div>
    )
};

export default LoginInfo;