import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { classnames, isValidEmail, translationKeysByErrorCodes, updateProfileEmail } from '../../utils';
import { initializePaymentInfoEmail } from '../../store/paymentsSlice';
import styles from './styles.module.scss';
import loadingImage from '../../assets/images/loadingImg.gif';
import { Input } from '../Input';

interface PaymentSaveEmailDialogProps {
    isOpen: boolean;
    close: Function;
    callback: Function;
    questionMessageKey?: string;
    size?: 'sm' | 'md' | 'lg' | 'full-screen' | 'fit-content' | "s-fit-content";
    className?: string;
    withoutHandleProcess?: boolean
}

export const PaymentSaveEmailDialog: React.FC<PaymentSaveEmailDialogProps> = (props) => {
    const [process, setProcess] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const toggleProcess = useCallback(() => setProcess(prev => !prev), []);

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(ev.target.value);
        if (emailError) {
            setEmailError('');
        }
    };

    const handlerKeyup = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.code === 'Enter' && email) {
            approve();
        }
    }

    const approve = () => {
        setEmailError('');
        if (!isValidEmail(email)) {
            setEmailError('INVALID_INPUT');
            return;
        }
        toggleProcess();
        updateProfileEmail(email)
            .then(res => {
                if (res.data.correct) {
                    dispatch(initializePaymentInfoEmail(email));
                    props.callback();
                } else if (res.data.message) {
                    toast.error(t(res.data.message), { position: 'top-right', theme: 'colored' });
                }
            })
            .catch(err => {
                const error = translationKeysByErrorCodes(err);
                if (error) {
                    toast.error(t(error), { position: 'top-right', theme: 'colored' });
                }
            })
            .finally(() => toggleProcess());
    }

    return (
        <Modal
            className={styles.PaymentSaveEmailDialog}
            title={t('attention')}
            size={props.size || "s-fit-content"}
            isOpen={props.isOpen}
            closeMessage={t('close')}
            onClose={props.close}
            footerExtraComponents={(
                <Button size="s" color="primary" onClick={process || !email ? () => {} : approve} disabled={process || !email}>
                    {
                        process ? (
                                <>
                                    {t('wait')} <img src={loadingImage} width={30} />
                                </>
                            ) :
                            t('update')
                    }
                </Button>
            )}
            footerExists
            mode='head-colored'
            type='warning'
        >
            <div className={classnames({[styles.contentMessage]: true, [props.className as string]: !!props.className })}>
                <div className={styles.message}>{t('PAYMENTS_EMAIL_EMPTY_MSG')}</div>
                <Input
                    label={t('PAYMENTS_EMAIL_ADDR')}
                    value={email}
                    onChange={handleInputChange}
                    onKeyUp={handlerKeyup}
                    errorMessage={emailError ? t(emailError) : ''}
                />
            </div>
        </Modal>
    );
};
