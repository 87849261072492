import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './styles.module.scss';
import { classnames } from '../../utils';

interface TooltipProps {
    children?: React.ReactNode;
    title?: React.ReactNode | string;
    withoutTooltip?: boolean;
    position?: "top" | "right" | "bottom" | "left" | "bottom-right" | "top-right" | "center-left";
    type?: "error" | "info" | "warning" | "white";
    className?: string;
    display?: "inline" | "block";
    classNameTooltip?: string;
    followTheCursor?: boolean;
}

function getElementPosition(element: any) {
    const rect = element.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft
    };
}
// TODO positions
export const Tooltip: React.FC<TooltipProps> = (props) => {
    const { children, title, position = "top-right", withoutTooltip, type, className, display, classNameTooltip, followTheCursor } = props;
    const refWrapper = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState<{ x: number, y: number } | null>(null);


    const calculateTooltipPosition = (tooltip: HTMLDivElement) => {
        const parentRect = refWrapper.current?.getBoundingClientRect();
        if (!tooltip || !parentRect) return;
        const parentPosition = getElementPosition(refWrapper.current);
        if(!!followTheCursor) {
            tooltip.style.top = `${(show?.y ?? 0) + 25}px`;
            tooltip.style.left = `${(show?.x ?? 0) - 5}px`;
            return
        }
        switch (position) {
            case 'top-right':
                tooltip.style.top = `${parentPosition.top - tooltip.offsetHeight - 10 }px`;
                tooltip.style.left = `${parentPosition.left - tooltip.offsetWidth + (refWrapper.current?.offsetWidth ?? 0) + 15}px`;
                break;
            case 'bottom':
                tooltip.style.top = `${parentRect.bottom + 10}px`;
                tooltip.style.left = `${parentRect.left + parentRect.width / 2 - tooltip.offsetWidth / 2}px`;
                break;
            case "bottom-right": 
                tooltip.style.top = `${parentPosition.top + parentRect.height + 10}px`;
                tooltip.style.left = `${parentPosition.left - tooltip.offsetWidth + (refWrapper.current?.offsetWidth ?? 0) + 15}px`;
                break;
            case "center-left":
                tooltip.style.top = `${parentPosition.top}px`;
                tooltip.style.left = `${parentPosition.left - tooltip.offsetWidth - 10}px`;
                break;
            default:
                break;
        }
    };

    const cstooltip = classnames({
        [styles.Tooltip]: true,
        [styles[type ?? ""]]: !!type,
        [styles[position]]: !!position,
        [classNameTooltip as string]: !!classNameTooltip,
        [styles.FollowTheCursor]: !!followTheCursor
    });

    const csWrapper = classnames({
        [styles.TooltipWrapper]: true,
        [className ?? ""]: !!className,
        [styles.WithoutTooltip]: !!withoutTooltip,
        [styles[display ?? ""]]: !!display 
    })

    const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
        var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setShow({ x: event.clientX + scrollLeft, y: event.clientY + scrollTop });
    };

    const handleMouseLeave = () => {
        setShow(null);
    }
    
    useEffect(() => {
        if(followTheCursor) {
            const handleScroll = () => {
                setShow(null);
            }
            document.addEventListener("scroll", handleScroll);
    
            return () => {
                document.removeEventListener("scroll", handleScroll);
            }
        }
    }, []);

    return (
        <div
            className={csWrapper}
            ref={refWrapper}
            onMouseMove={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {(!withoutTooltip && !!show) &&
                createPortal(
                    <div className={cstooltip} data-tooltip={true} ref={calculateTooltipPosition}>
                        {title}
                    </div>
                , document.body
            )}
        </div>
    );
};