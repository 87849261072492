import React, { memo, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { Button } from "../Button";
import { useSelector } from "react-redux";
import { classnames } from "../../utils";
import { useTranslation } from "react-i18next";
import * as Icons from "../Icons";
import { Input } from "../Input";

interface CaptchaProps {
    inputSize?: "large" | "middle" | "small";
    check?: CallableFunction
}

const generateCaptcha = () => {
    let c = []
    for (let i = 0; i < 6; i++) {
        const random = Math.floor(Math.random() * 1000);
        if (random % 2 === 0) {
            let charcode = String.fromCharCode(Math.floor(Math.random() * 26 + 65))
            charcode = Math.floor(Math.random() * 1000) % 2 !== 0 ? charcode.toUpperCase() : charcode.toLowerCase();
            c.push(charcode)
            continue
        }
        c.push(Math.floor(Math.random() * 10));
    }
    return c.join(" ")
}

export const Captcha: React.FC<CaptchaProps> = memo((props) => {
    const { inputSize = "middle", check } = props;
    const { t } = useTranslation();
    const [captcha, setCaptcha] = useState(generateCaptcha());
    const [captchaValue, setCaptchaValue] = useState<undefined | string>("");
    const [error, setError] = useState<undefined | string>(undefined)
    const utils = useSelector((state: any) => state.utils);
    const [reloadRotate, setReloadRotate] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const drawCanvas = () => {
        if(canvasRef.current) {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            const fontSize = 25;
            if(context) {
                context.clearRect(0, 0, context.canvas.width, context.canvas.height);
                context.beginPath();
                context.font = `${fontSize}px Georgia`;
                context.textAlign = "center";
                context.textBaseline = "middle";
                context.strokeStyle = "#244564";
                const centerX = canvas.width / 2;
                const centerY = canvas.height / 2;
                context.strokeText(captcha, centerX, centerY);
                context.fillStyle = "#244564";
                context.fillText(captcha, centerX, centerY);
            }
        };
    };

    const handleCheckCaptcha = (triggerError = false) => {
        if(!captchaValue) {
            triggerError && setError("FIELD_NOT_SET");
            return false;
        }
        else if (captchaValue !== captcha.replace(/\s/g, '')) {
            triggerError && setError("enterCodeCorrectly");
            return false;
        }
        setError(undefined);
        return true;
    };

    useEffect(() => {
        drawCanvas();
    }, [captcha, utils.device.mode]);

    useEffect(() => {
        check?.(handleCheckCaptcha());
    }, [captchaValue]);

    const reloadCaptcha = () => {
        setCaptcha(generateCaptcha());
        setCaptchaValue("");
        setReloadRotate(true);
        setTimeout(() => {
            setReloadRotate(false);
        }, 500);
    };

    const getCavnasHeight = (mode: "tablet" | "mobile" | "device") => {
        switch(mode) {
            case "mobile":
                return 40;
            case "tablet":
                return 44;
            default:
                return 44
        };
    };

    const csReload = classnames({
        [styles.ReloadRotate]: reloadRotate
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(undefined);
        setCaptchaValue(event.target.value);
    };

    return (
        <div className={styles.Captcha}>
            <div className={styles.ValueSection}>
                <div className={styles.CanvasContainer}>
                    <canvas id="captcha" ref={canvasRef} height={getCavnasHeight(utils.device.mode)} />
                </div>
                <Button variant="link" onClick={reloadCaptcha} className={csReload}>
                    <Icons.Reload />
                </Button>
            </div>
            <Input 
                inputSize={inputSize} 
                className={styles.CaptchaInput}
                onChange={handleChange}
                value={captchaValue}
                errorMessage={error ? t(error) : error}
                onBlur={() => handleCheckCaptcha(true)}
                placeholder={t("security_code")}
            />
        </div>
    )
})