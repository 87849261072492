import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface INewPaymentInfo {
    initialized: boolean;
    email: string;
    tin: string;
    psn: string;
    payerName: string;
    accountNumber: string;
    socialPayment: number | string;
    taxPayment: number | string;
    amountForPay: number | string;
}

interface IPayments {
    rows: any[];
    pageSize: number;
    currentPage: number;
    total: number;
    newPaymentInfo: INewPaymentInfo
}

const newPaymentInfoDefault: INewPaymentInfo = {
    initialized: false,
    email: '',
    tin: '',
    psn: '',
    payerName: '',
    accountNumber: '',
    socialPayment: '',
    taxPayment: '',
    amountForPay: ''
}

const initialState: IPayments = {
    rows: [],
    pageSize: 20,
    currentPage: 1,
    total: 0,
    newPaymentInfo: newPaymentInfoDefault
};

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        initializePaymentsRows: (_state, action: PayloadAction<any>) => {
            return { ..._state, rows: action.payload }
        },
        initializePaymentsTotal: (_state, action: PayloadAction<any>) => {
            return { ..._state, total: action.payload }
        },
        changePaymentsPage: (_state, action: PayloadAction<any>) => {
            return { ..._state, currentPage: action.payload }
        },
        initializePaymentInfo: (_state, action: PayloadAction<any>) => {
            return { ..._state, newPaymentInfo: action.payload }
        },
        initializePaymentInfoEmail: (_state, action: PayloadAction<string>) => {
            return { ..._state, newPaymentInfo: { ..._state.newPaymentInfo, email: action.payload } }
        },
        resetPaymentInfo: (_state) => {
            return { ..._state, newPaymentInfo: newPaymentInfoDefault }
        }
    },
})

export const {
    initializePaymentsRows,
    initializePaymentsTotal,
    changePaymentsPage,
    initializePaymentInfo,
    initializePaymentInfoEmail,
    resetPaymentInfo
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
