import React, { useEffect, useLayoutEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import queryString from 'query-string';
import 'react-toastify/dist/ReactToastify.css';
import type { RootState } from './store';
import { checkAuth } from './store/authSlice';
import { setGlobalError } from './store/utilsSlice';
import routing from './router';
import Loading from './components/Loading';
import { checkAuthToken, getSearchParams, requestToLoginNIDAuth } from './utils';
import { useWindowSize } from './hook';
import * as BridgeActions from "./utils/bridge_actions";
import { InvalidTinModal } from './components/InvalidTinModal';
import { Modal } from './components/Modal';
import { useTranslation } from 'react-i18next';

function App() {
  const [seeVide, setSeeVideo] = useState(false);
  const { t } = useTranslation();
  const auth: any = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();


  useLayoutEffect(() => {
    if(!!queryString.parse(window.location.search).mobileView) {
      sessionStorage.setItem("searchParams", JSON.stringify(queryString.parse(window.location.search)));
    }
  }, []);

  useEffect(() => {
    const profileParams: { cbYesEm?: Function } = {};
    if (!!getSearchParams().mobileView && window.Bridge?.onCheckTokens) {
      BridgeActions.onCheckTokens()
        .then((res) => {
          const { token, refreshToken }: { token: string, refreshToken: string } = JSON.parse(res as string);
          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', refreshToken);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          checkAuthToken(profileParams).then((res: any) => {
            dispatch(checkAuth(res.data));
          }).catch(() => null);
        })
      
    }
    else {
      if (window.location.href.match(/sign-in\/success/)) {
        profileParams.cbYesEm = async () => {
          if (localStorage.getItem('nid_session_id') && localStorage.getItem('nid_session_verifier')) {
            try {
              const send = {
                sessionId: localStorage.getItem('nid_session_id'),
                sessionVerifier: localStorage.getItem('nid_session_verifier')
              };
              const nidLoginData = await requestToLoginNIDAuth(send);
              localStorage.setItem('token', nidLoginData.data.token);
              localStorage.setItem('refreshToken', nidLoginData.data.refreshToken);
              localStorage.removeItem('nid_session_id');
              localStorage.removeItem('nid_session_verifier');
            } catch (err: any) {
              console.log('err', err);
              const errorKey = err.response?.data?.message || err.message;
              dispatch(setGlobalError(errorKey));
            }
          }
        }
      }
      checkAuthToken(profileParams).then((res: any) => {
        dispatch(checkAuth(res.data));
      }).catch(() => null);
    }

    const handleStorage = (event: any) => {
      if(event.key === "refreshToken") {
        window.location.reload();
      }
    };

    const handleActionEvenet = (event: any) => {
      if (event?.detail?.type === "guidelines_completing_declarations") {
        setSeeVideo(true);
      }
    };

    if(!getSearchParams().mobileView) {
      window.addEventListener("storage", handleStorage);
    };
    document.addEventListener('dispatchActionsEvent', handleActionEvenet);

    return () => {
      if(!getSearchParams().mobileView) {
        window.removeEventListener("storage", handleStorage);
      };
      document.removeEventListener('dispatchActionsEvent', handleActionEvenet);
    };
  }, []);

  useWindowSize();

  if (auth === '') {
    return <Loading />;
  };

  return (
      <div className="App">
        <RouterProvider router={routing(auth, navigator)} />
        <ToastContainer />
        <InvalidTinModal />
        <Modal
          size='fit-content'
          isOpen={seeVide}
          onClose={() => setSeeVideo(false)}
          mode='head-colored'
          className="see--video--modal"
          title={t("guidelines_completing_declarations")}
        >
          <div className="see--video--iframe--wrapper">
            <iframe
              src='https://www.youtube.com/embed/gic1Yx54Efg?si=IfCeTjTcPGaekLSr&autoplay=1&cc_load_policy=1'
              frameBorder="0"
              allow="autoplay"
              allowFullScreen
              title={t("guidelines_completing_declarations")}
            />
          </div>
        </Modal>
      </div>
  );
}

export default App;
