import { useEffect } from 'react';
import { detectDevice, checkMobileView } from '../store/utilsSlice';
import { useDispatch } from 'react-redux'


const getMode = (width: number) => {
    switch (!!width) {
        case width <= 767:
            return "mobile"
        case width > 767 && width < 1024:
            return "tablet";
        default :
            return "desktop"
    }
}

export const useWindowSize = () => {
 
  const dispatch = useDispatch()

  const handleResize = () => {
    dispatch(detectDevice({ width: window.innerWidth, height: window.innerHeight, mode: getMode(window.innerWidth) }));
    dispatch(checkMobileView());
  };

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return null

};
