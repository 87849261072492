import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Container } from '../../Layouts/Container';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import { Wrapper } from '../../Layouts/Wrapper';
import styles from './styles.module.scss';
import { Table, TableHead } from '../../components/Table';
import { Pagination } from '../../components/Pagination';
import {
    classnames,
    formatDate,
    getYearlyIncomeList,
    getYearlyIncomeTotal,
    getEmployeesYearlyTinList,
    getEmployeeIncomeTypes,
    months,
    IMonthType,
    formatNumberWithCommas
} from '../../utils';
import { FilterBox } from '../../components/FilterBox';
import { RootState } from '../../store';
import {
    changeTaxOtherPage,
    initializeOtherRows,
    initializeOtherTotal
} from "../../store/revenuesSlice";
import Loading from "../../components/Loading";
import { OtherRevenuesTaxAgentCard } from '../../components/Card';
import { Empty } from '../../components/Empty';

export const path: string = 'revenues-other';

const OtherRevenues: React.FC<{ data: OtherRevenuesTaxAgent[], total: number }> = (props) => {
    const { data, total } = props;
    const { t } = useTranslation();
    return (
        <div className={styles.RevenuesOther}>
            <div className={styles.mobileViewCardTotal}>
                {t("total_data_count")}
                &nbsp;&nbsp;
                <span className={styles.Caption}>{total}</span>
            </div>
            <div className={styles.RevenuesCards}>
                {data.map((revenue, index) => (
                    <OtherRevenuesTaxAgentCard { ...revenue } key={`${index}/${revenue.tin}`} />
                ))}
                {!data.length && <Empty title={t("dataNotFound")} />}
            </div>
        </div>
    )
}

const RevenuesTaxAgentPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstOpen, setFirstOpen] = useState<boolean>(true);
    const [employeesTins, setEmployeesTins] = useState<{ tin: string, name: string }[]>([]);
    const [employeeIncomeTypes, setEmployeeIncomeTypes] = useState<any[]>([]);
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const { language } = i18n;
    const { tax, taxOther } = useSelector((state: RootState) => state.revenues);
    const [filterBoxOpen, setFilterBoxOpen] = useState(false);
    const utils = useSelector((state: any) => state.utils);

    const handleInputChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = ev.target;
        setFilterFields(prev => ({ ...prev, [name]: { ...prev[name as keyof typeof prev], value } }));
    }, []);

    const handleSelectChange = useCallback(
        (data: { label: string, value: string }, opt: { action: string, name: string }) =>
            setFilterFields(prev => ({ ...prev, [opt.name]: { ...prev[opt.name as keyof typeof prev], value: data } })),
        []
    );

    const onPeriodYearChange = (year: any) => {
        setFilterFields(prev => ({ ...prev, period: { ...prev.period, year, month: year ? prev.period.month : undefined } }));
    }

    const onPeriodMonthChange = (month: any) => {
        setFilterFields(prev => ({ ...prev, period: { ...prev.period, month } }));
    }

    const handleTinChange = useCallback(
        (data: { label: string, value: string }, opt: { action: string, name: string }) => {
            setFilterFields(prev => {
                const newState = { ...prev, [opt.name]: { ...prev[opt.name as keyof typeof prev], value: data } };
                if (opt.name === 'tin' && !data?.value) {
                    //@ts-ignore
                    newState.tin.errorMessage = '';
                }
                return newState;
            });
        },
        []
    );

    const handleTinTypingChange = (tin: string, opt: any, tinList: any) => {
        if (tin) {
            const r = new RegExp(filterFields.tin.pattern);
            if (!r.test(tin) || tin.length > filterFields.tin.maxLength) {
                return opt.prevInputValue;
            }
        }
        setFilterFields((prevState: any) => ({
            ...prevState,
            tin: {
                ...prevState.tin,
                options: tin.length >= 2 ? tinList : [],
                noOptionsMessageKey: tin.length >= 2 ? 'dataNotFound' : '-',
                errorMessage: tin ? prevState.tin.errorMessage : ''
            }
        }));
        return tin;
    }

    const [reset, setReset] = useState(false);
    const [filterFields, setFilterFields] = useState({
        revenuesType: {
            value: [] as any,
            type: 'select',
            isMulti: true,
            onChange: handleSelectChange,
            onInputChange: () => {},
            options: [] as any,
            isClearable: true,
            placeholder: 'any',
            componentStyle: utils.device.mode !== 'desktop' ? {} : { flexBasis: 'calc(30% - 2em)', minWidth: 'unset' }
        },
        period: {
            year: undefined as (undefined | Date),
            month: undefined as (undefined | { label: string, value: string }),
            min: new Date(2023, 0),
            max: new Date(),
            type: 'yearMonth',
            onYearChange: onPeriodYearChange,
            onMonthChange: onPeriodMonthChange,
            isClearable: true,
            placeholder: 'month',
            componentStyle: utils.device.mode !== 'desktop' ? {} : { flexBasis: 'calc(12% - 2em)', minWidth: 'unset' },
            componentSelectStyle: utils.device.mode !== 'desktop' ? {} : { flexBasis: 'calc(20% - 2em)', minWidth: 'unset' }
        },
        tin: {
            value: '' as any,
            type: 'select',
            onChange: handleTinChange,
            options: [],
            onInputChange: (t: string, o: any) => handleTinTypingChange(t, o, employeesTins),
            maxLength: 8,
            pattern: '^[0-9]+$',
            isClearable: true,
            noDropdown: true,
            selectDuringOnBlur: true,
            selectDuringOnBlurOptions: { name: 'tin' },
            noOptionsMessageKey: '-',
            componentStyle: utils.device.mode !== 'desktop' ? {} : { flexBasis: 'calc(12% - 2em)', minWidth: 'unset' },
            anyValue: true
        },
        taxpayerName: {
            value: '',
            type: 'text',
            onChange:
            handleInputChange,
            maxLength: 100,
            componentStyle: utils.device.mode !== 'desktop' ? {} : { flexBasis: 'calc(22% - 2em)', minWidth: 'unset', maxWidth: 'calc(40%)' }
        }
    });

    const initializeTaxOtherGrid = async (filterList: any, filterTotal: any) => {
        const [list, total] = await Promise.all([getYearlyIncomeList(filterList), getYearlyIncomeTotal(filterTotal)]);
        dispatch(initializeOtherRows(list.data));
        dispatch(initializeOtherTotal(total.data.value || 0));
    }

    const onSearch = async (page?: number) => {
        setIsLoading(true);
        try {
            const selectedIncomeTypes = filterFields.revenuesType.value.map((item: any) => item.value);
            const filter = {
                employerTin: filterFields.tin.value?.value || undefined,
                employerName: filterFields.taxpayerName.value || undefined,
                year: filterFields.period.year?.getFullYear?.(),
                month: filterFields.period.month?.value || undefined,
                incomeTypes: selectedIncomeTypes.join(',')
            };
            setFilterFields(prev => ({ ...prev, tin: { ...prev.tin, errorMessage: '' } }));
            if (
                !firstOpen &&
                filterFields.tin.value?.value &&
                filterFields.tin.pattern &&
                !(new RegExp('^[0-9]{8}$').test(filterFields.tin.value?.value))
            ) {
                setFilterFields(prev => ({ ...prev, tin: { ...prev.tin, errorMessage: 'INVALID_INPUT' } }));
            }
            const filterForList = {
                ...filter,
                currentPage: page || tax.currentPage,
                pageSize: tax.pageSize
            }
            const filterForTotal = { ...filter };
            await initializeTaxOtherGrid(filterForList, filterForTotal);
            setFirstOpen(false);
        } catch (err: any) {
            console.log('onSearch >>>', err);
            toast.error(t(err.message), { position: 'top-right', theme: 'colored' });
        } finally {
            setIsLoading(false);
        }
    }

    const onNewSearch = () => {
        setFilterFields(prev => ({
            ...prev,
            revenuesType: { ...prev.revenuesType, value: [] },
            period: { ...prev.period, year: undefined, month: undefined },
            tin: { ...prev.tin, value: '' },
            taxpayerName: { ...prev.taxpayerName, value: '' },
        }));
        setReset(true);
    }

    const changePage = (page: number) => {
        dispatch(changeTaxOtherPage(page));
        onSearch(page);
    }

    useEffect(() => {
        if (reset) {
            changePage(1);
            setReset(false);
        }
    }, [reset]);

    useEffect(() => {
        onSearch();
        getEmployeesYearlyTinList()
            .then(res => {
                const OPTIONS = res.data.filter((item: any, i: number, arr: any[]) => arr.findIndex((a : any) => a.tin === item.tin) === i);
                setEmployeesTins(OPTIONS);
                const options = OPTIONS.map((item: any) => ({ label: item.tin, value: item.tin }));
                setFilterFields(prevState => ({
                    ...prevState,
                    tin: {
                        ...prevState.tin,
                        onInputChange: (t: string, o: any) => handleTinTypingChange(t, o, options)
                    }
                }));
            })
            .catch(err => console.log('getSupplierList >>>', err));
        getEmployeeIncomeTypes()
            .then(res => {
                setEmployeeIncomeTypes(res.data?.filter((item: any) => !item.specialTypes002 && !item.salaryRelated));
            })
            .catch(err => console.log('getEmployeeIncomeTypes', err));
    }, []);

    useEffect(() => {
        setFilterFields(prev => ({
            ...prev,
            revenuesType: {
                ...prev.revenuesType,
                options: employeeIncomeTypes.map((item: any) => ({
                    label: item[`name_${language}`],
                    value: item.code
                })),
                value: prev.revenuesType.value.map((item: any) => ({
                    label: employeeIncomeTypes.find((e: any) => e.code === item.value)?.[`name_${language}`],
                    value: item.value
                }))
            }
        }));
    }, [employeeIncomeTypes, language]);

    useEffect(() => {
        setFilterFields(prev => ({
            ...prev,
            period: {
                ...prev.period,
                month: filterFields.period.month?.value ? { label: months[parseInt(filterFields.period.month?.value) - 1][language as keyof IMonthType].long, value: filterFields.period.month?.value } : undefined
            }
        }));
    }, [filterFields.period.month?.value, language]);

    const csSearchBox = classnames({
        [styles.SearchBox]: true,
        [styles.Open]: utils.device.mode !== "desktop" && filterBoxOpen,
        [styles.Close]: utils.device.mode !== "desktop" && !filterBoxOpen,
    });

    const tableHead: TableHead[] = useMemo(() => ([
        { key: "tin", title: t("tin"), colSpan: 1, className: 'fixTdCol100' },
        { key: "taxpayerName", title: t("taxpayerName"), colSpan: 1, className: 'fixTdCol200' },
        { key: "incomeRateCode", title: t("revenuesType"), colSpan: 1, className: 'fixTdCol300' },
        { key: "incomeAmount", title: t("revenuesSize"), colSpan: 1, className: 'fixTdCol100' },
        { key: "taxAmount", title: t("revenueTax"), colSpan: 1, className: 'fixTdCol100' },
        { key: "year", title: t("period"), colSpan: 1, className: 'fixTdCol100' },
        { key: "receiptDate", title: t("registrationDate"), colSpan: 1, className: 'fixTdCol100' }
    ]), [t]);

    const taxOtherRows = taxOther.rows.map((row: any) => ({
        ...row,
        receiptDate: formatDate('DD.MM.YYYY', new Date(row.receiptDate)),
        incomeRateCode: employeeIncomeTypes.find(item => item.code === row.incomeRateCode)?.[`name_${language}`],
        incomeAmount: !Number.isNaN(parseInt(row.incomeAmount)) ? formatNumberWithCommas(row.incomeAmount) : '',
        taxAmount: !Number.isNaN(parseInt(row.taxAmount)) ? formatNumberWithCommas(row.taxAmount) : ''
    }));

    let listView = null;
    switch (utils.device.mode) {
        case 'desktop':
            listView = (
                <>
                    <Table
                        data={taxOtherRows}
                        theads={tableHead}
                        caption={
                            <>
                                <div className="mb-3">
                                    {t("total_data_count")}
                                    &nbsp;
                                    <span className={styles.Caption}>{taxOther.total}</span>
                                </div>
                            </>
                        }
                        verticalSize='middle'
                    />
                    <Pagination pageSize={taxOther.pageSize} total={taxOther.total} currentPage={taxOther.currentPage} onChange={changePage} />
                </>
            );
            break;
        default:
            listView = (
                <>
                    {!!taxOtherRows?.length ? (
                        <>
                            <OtherRevenues data={taxOtherRows} total={taxOther.total} />
                            {!!taxOtherRows?.length && <Pagination pageSize={taxOther.pageSize} total={taxOther.total} currentPage={taxOther.currentPage} onChange={changePage} />}
                        </>
                    ) : <Empty title={t("dataNotFound")} />}
                </>
            );
    }

    return (
        <DashboardLayout className={styles.Revenues}>
            <Container containerSize='large' className={styles.RevenuesContainer} withoutPadding>
                <Wrapper title={t("revenuesTaxAgentOther")}>
                    <FilterBox
                        mainClass={styles.SearchSection}
                        captionClass={styles.SearchSectionCaption}
                        actionsClass={styles.FilterActionsGroup}
                        bodyClass={csSearchBox}
                        filterBoxOpen={filterBoxOpen}
                        filterBoxToggle={() => setFilterBoxOpen(!filterBoxOpen)}
                        fields={filterFields}
                        onSearch={() => changePage(1)}
                        onNewSearch={onNewSearch}
                        isLoading={isLoading}
                    />
                    <div className={styles.TableSection}>
                        {firstOpen && !taxOtherRows?.length ? null : listView}
                    </div>
                </Wrapper>
                {isLoading && <Loading />}
            </Container>
        </DashboardLayout>
    );
};
export default RevenuesTaxAgentPage;
