import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Container } from '../../Layouts/Container';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import { Wrapper } from '../../Layouts/Wrapper';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Table, TableHead } from '../../components/Table';
import { Pagination } from '../../components/Pagination';
import {
    classnames,
    formatDate,
    getEmployeeOtherSourceIncomeTypes,
    getOtherSourceIncomeList,
    getOtherSourceIncomeTotal,
    deleteByIdOtherSourceIncome,
    IMonthType,
    months,
    formatNumberWithCommas
} from '../../utils';
import {
    changeTaxOtherPage,
    initializeOtherSourceRows,
    initializeOtherSourceTotal
} from '../../store/revenuesSlice';
import { FilterBox } from '../../components/FilterBox';
import { RootState } from '../../store';
import { Button } from '../../components/Button';
import { RevenuesOtherSourceAddEditDialog } from '../../components/RevenuesOtherSourceAddEditDialog';
import { useNavigate } from '../../hook';
import * as Icons from '../../components/Icons';
import Loading from '../../components/Loading';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { OtherSourceRevenuesCard } from '../../components/Card';
import { Empty } from '../../components/Empty';
import { Tooltip } from '../../components/Tooltip';

export const path: string = 'revenues/other-source';

const renderRowActions = (rowData: any, args: any) => {
    const editRevenue = () => args.openUpdateDialog(rowData.id);

    const confirmDeleteAction = () => args.openDeleteDialog(rowData);

    return (
        <div className={styles.ActionGroup}>
            <Tooltip 
                title={args.editMsg}
                type='info'
                display='inline'
                followTheCursor
            >
                <span onClick={editRevenue} className='action-item'>
                    <Icons.Edit />
                </span>
            </Tooltip>
            <Tooltip
                title={args.removeMsg}
                type='info'
                display='inline'
                followTheCursor
            >
                <span onClick={confirmDeleteAction} className='action-item'>
                    <Icons.Trash />
                </span>
            </Tooltip>
        </div>
    );
};

const RevenuesOtherSource = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [employeeOtherSourceIncomeTypes, setEmployeeOtherSourceIncomeTypes] = useState<any[]>([]);
    const [deleteDialog, setDeleteDialog] = useState<{ isOpen: boolean, data: any }>({ isOpen: false, data: null });
    const [updateDialog, setUpdateDialog] = useState<{ isOpen: boolean, data: any }>({ isOpen: false, data: null });
    const auth: any = useSelector((state: RootState) => state.auth);
    const utils = useSelector((state: any) => state.utils);
    const { otherSources } = useSelector((state: RootState) => state.revenues);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstOpen, setFirstOpen] = useState<boolean>(true);
    const { i18n, t } = useTranslation();
    const { language } = i18n;

    const closeUpdateDialog = () => setUpdateDialog(prevState => ({ ...prevState, isOpen: false, data: null }));

    const openUpdateDialog = (data?: any) => {
        setUpdateDialog(prevState => ({ ...prevState, isOpen: true, data }));
        setIsLoading(true);
    };

    const closeDeleteDialog = () => setDeleteDialog(prevState => ({ ...prevState, isOpen: false, data: null }));

    const openDeleteDialog = (data: any) => setDeleteDialog(prevState => ({ ...prevState, isOpen: true, data }));

    const approveDeleteAction = (cb?: Function) => {
        deleteByIdOtherSourceIncome(deleteDialog.data.id)
            .then(() => {
                onSearch();
                closeDeleteDialog();
            })
            .catch(err => {
                toast.error(t(err.message), { position: 'top-right', theme: 'colored' });
            })
            .finally(() => {
                cb?.();
            });
    };

    const handleSelectChange = useCallback(
        (data: { label: string, value: string }, opt: { action: string, name: string }) =>
            setFilterFields(prev => ({ ...prev, [opt.name]: { ...prev[opt.name as keyof typeof prev], value: data } })),
        []
    );

    const onPeriodYearChange = (year: any) => {
        setFilterFields(prev => ({ ...prev, period: { ...prev.period, year, month: year ? prev.period.month : undefined } }));
    }

    const onPeriodMonthChange = (month: any) => {
        setFilterFields(prev => ({ ...prev, period: { ...prev.period, month } }));
    }

    const handleRegistrationDateRange = useCallback((value: any) => setFilterFields(prev => ({ ...prev, registrationDate: { ...prev.registrationDate, value } })), []);

    const [reset, setReset] = useState(false);
    const [filterFields, setFilterFields] = useState({
        revenuesType: {
            label: 'revenues_over_source_type',
            value: [] as any,
            type: 'select',
            isMulti: true,
            onChange: handleSelectChange,
            onInputChange: () => {},
            options: [] as any,
            isClearable: true,
            placeholder: 'any',
            componentStyle: utils.device.mode !== 'desktop' ? {} : { flexBasis: 'calc(35% - 2em)', minWidth: 'unset' }
        },
        period: {
            year: undefined as (undefined | Date),
            month: undefined as (undefined | { label: string, value: string }),
            min: new Date(2023, 0),
            max: new Date(),
            type: 'yearMonth',
            onYearChange: onPeriodYearChange,
            onMonthChange: onPeriodMonthChange,
            isClearable: true,
            placeholder: 'month',
            componentStyle: utils.device.mode !== 'desktop' ? {} : { flexBasis: 'calc(15% - 2em)', minWidth: 'unset' },
            componentSelectStyle: utils.device.mode !== 'desktop' ? {} : { flexBasis: 'calc(20% - 2em)', minWidth: 'unset' }
        },
        registrationDate: {
            label: 'revenues_over_registration_date',
            value: {
                startDate: undefined as (Date | undefined),
                endDate: undefined as (Date | undefined)
            },
            type: 'dateRange',
            onChange: handleRegistrationDateRange,
            componentStyle: utils.device.mode !== 'desktop' ? {} : { flexBasis: 'calc(30% - 2em)', minWidth: 'unset' }
        }
    });
    const [filterBoxOpen, setFilterBoxOpen] = useState(false);

    const onSearch = async (page?: number) => {
        setIsLoading(true);
        try {
            const selectedIncomeTypes = filterFields.revenuesType.value.map((item: any) => item.value);
            const filter = {
                incomeTypes: selectedIncomeTypes.join(',') || undefined,
                year: filterFields.period.year?.getFullYear?.(),
                month: filterFields.period.month?.value || undefined,
                startDate: filterFields.registrationDate.value?.startDate ? formatDate('YYYY-MM-DD', filterFields.registrationDate.value?.startDate) : undefined,
                endDate: filterFields.registrationDate.value?.endDate ? formatDate('YYYY-MM-DD', filterFields.registrationDate.value?.endDate) : undefined
            };
            const filterForList = {
                ...filter,
                currentPage: page || otherSources.currentPage,
                pageSize: otherSources.pageSize
            }
            const filterForTotal = { ...filter };
            const [list, total] = await Promise.all([getOtherSourceIncomeList(filterForList), getOtherSourceIncomeTotal(filterForTotal)]);
            dispatch(initializeOtherSourceRows(list.data));
            dispatch(initializeOtherSourceTotal(total.data.value || 0));
            setFirstOpen(false);
        } catch (err) {
            console.log('onSearch >>>', err);
        } finally {
            setIsLoading(false);
        }
    }

    const onNewSearch = () => {
        setFilterFields(prev => ({
            ...prev,
            revenuesType: { ...prev.revenuesType, value: [] },
            period: { ...prev.period, year: undefined, month: undefined },
            registrationDate: { ...prev.registrationDate, value: { startDate: undefined, endDate: undefined } }
        }));
        setReset(true);
    }

    const changePage = (page: number) => {
        dispatch(changeTaxOtherPage(page));
        onSearch(page);
    }

    const addNewRevenuePage = () => {
        openUpdateDialog();
    }

    useEffect(() => {
        if (reset) {
            onSearch();
            setReset(false);
        }
    }, [reset, onSearch]);

    useEffect(() => {
        onSearch();
        getEmployeeOtherSourceIncomeTypes()
            .then(res => {
                setEmployeeOtherSourceIncomeTypes(res.data);
            })
            .catch(err => console.log('getEmployeeIncomeTypes', err));
    }, []);

    useEffect(() => {
        if (!auth?.tin || (auth?.tin && !utils.tinIsValid)) {
            navigation('/dashboard');
        }
    }, [auth?.tin, utils.tinIsValid]);

    useEffect(() => {
        setFilterFields(prev => ({
            ...prev,
            revenuesType: {
                ...prev.revenuesType,
                options: employeeOtherSourceIncomeTypes.map((item: any) => ({
                    label: item[`name_${language}`],
                    value: item.code
                })),
                value: prev.revenuesType.value.map((item: any) => ({
                    label: employeeOtherSourceIncomeTypes.find((e: any) => e.code === item.value)?.[`name_${language}`],
                    value: item.value
                }))
            }
        }));
    }, [employeeOtherSourceIncomeTypes, language]);

    useEffect(() => {
        setFilterFields(prev => ({
            ...prev,
            period: {
                ...prev.period,
                month: filterFields.period.month?.value ? { label: months[parseInt(filterFields.period.month?.value) - 1][language as keyof IMonthType].long, value: filterFields.period.month?.value } : undefined
            }
        }));
    }, [filterFields.period.month?.value, language]);

    const csSearchBox = classnames({
        [styles.SearchBox]: true,
        [styles.Open]: utils.device.mode !== "desktop" && filterBoxOpen,
        [styles.Close]: utils.device.mode !== "desktop" && !filterBoxOpen,
    });

    const callActionsDetails = {
        navigation,
        openDeleteDialog,
        openUpdateDialog,
        editMsg: t('edit'),
        removeMsg: t('remove')
    };

    const tableHead: TableHead[] = useMemo(() => ([
        { key: "revenuesType", title: t("revenues_over_source_type"), colSpan: 1, className: 'fixTdCol300' },
        { key: "sourceInfo", title: t("incomeRevenuePaidUser"), colSpan: 1, className: 'fixTdCol200' },
        { key: "incomeAmount", title: t("revenuesSize"), colSpan: 1, className: 'fixTdCol100' },
        { key: "taxRate", title: t("rateSize"), colSpan: 1, className: 'fixTdCol100' },
        { key: "taxAmount", title: t("revenueTax"), colSpan: 1, className: 'fixTdCol100' },
        { key: "year", title: t("period"), colSpan: 2, colSpanColumns: ['year', 'month'], className: 'fixTdCol100' },
        { key: "registrationDate", title: t("registrationDate"), colSpan: 1, className: 'fixTdCol100' },
        { key: "view", title: t("view"), colSpan: 1, colType: "action", colActionArgs: callActionsDetails, className: 'fixTdCol50' },
    ]), [t]);

    let ROWS = otherSources.rows.map(row => ({
        ...row,
        revenuesType: employeeOtherSourceIncomeTypes.find(item => item.code === row.incomeTypeCode)?.[`name_${language}`],
        month: (months[parseInt(row.month, 10) - 1] as IMonthType)?.[language as keyof IMonthType]?.long,
        monthShort: (months[parseInt(row.month, 10) - 1] as IMonthType)?.[language as keyof IMonthType]?.short,
        registrationDate: formatDate('DD.MM.YYYY', new Date(row.registerDate)),
        incomeAmount: !Number.isNaN(parseInt(row.incomeAmount)) ? formatNumberWithCommas(row.incomeAmount) : '',
        taxAmount: !Number.isNaN(parseInt(row.taxAmount)) ? formatNumberWithCommas(row.taxAmount) : ''
    }));
    let listView = null;
    switch (utils.device.mode) {
        case 'desktop':
            listView = (
                <Table
                    data={ROWS}
                    theads={tableHead}
                    caption={
                        <>
                            {t("total_data_count")}
                            &nbsp;
                            <span className={styles.Caption}>{otherSources.total}</span>
                        </>
                    }
                    rowActions={renderRowActions}
                    verticalSize='middle'
                />
            );
            break;
        default:
            listView = (
                <div className={styles.OtherSourceCards}>
                    {ROWS.length ? (
                        <div className={styles.mobileViewCardTotal}>
                            {t("total_data_count")}
                            &nbsp;&nbsp;
                            <span className={styles.Caption}>{otherSources.total}</span>
                        </div>
                    ) : null}
                    {ROWS.map((revenue: OtherSourceRevenues, index) => (
                        <OtherSourceRevenuesCard { ...revenue } edit={openUpdateDialog} remove={openDeleteDialog} key={`${index}/${revenue.id}`} />
                    ))}
                    {!ROWS.length && <Empty title={t("dataNotFound")} />}
                </div>
            );
    }

    return (
        <DashboardLayout className={styles.Revenues}>
            <Container containerSize='large' className={styles.RevenuesContainer} withoutPadding>
                <Wrapper title={t("revenuesOtherSource")}>
                    <FilterBox
                        mainClass={styles.SearchSection}
                        captionClass={styles.SearchSectionCaption}
                        actionsClass={styles.FilterActionsGroup}
                        bodyClass={csSearchBox}
                        filterBoxOpen={filterBoxOpen}
                        filterBoxToggle={() => setFilterBoxOpen(!filterBoxOpen)}
                        fields={filterFields}
                        onSearch={() => changePage(1)}
                        onNewSearch={onNewSearch}
                        isLoading={isLoading}
                    />
                    <div className={styles.TableSection}>
                        <Button
                            size="small"
                            className={styles.addNewRevenue}
                            onClick={addNewRevenuePage}
                            style={{ marginBottom: ROWS.length ? 0 : 20 }}
                        >
                            {t('addNewRevenue')}
                        </Button>
                        {firstOpen && !ROWS?.length ? null : listView}
                        {!!ROWS?.length && <Pagination pageSize={otherSources.pageSize} total={otherSources.total} currentPage={otherSources.currentPage} onChange={changePage} />}
                    </div>
                </Wrapper>
                {isLoading && <Loading />}
                <ConfirmDialog isOpen={deleteDialog.isOpen} close={closeDeleteDialog} approve={approveDeleteAction} questionMessageKey="removeOtherRevenues" />
                <RevenuesOtherSourceAddEditDialog
                    isOpen={updateDialog.isOpen}
                    id={updateDialog.data}
                    close={closeUpdateDialog}
                    types={employeeOtherSourceIncomeTypes}
                    updateRows={() => changePage(1)}
                    resetLoading={() => setIsLoading(false)}
                />
            </Container>
        </DashboardLayout>
    );
};
export default RevenuesOtherSource;
