import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from '../../hook';
import styles from './styles.module.scss';
import { RootState } from "../../store";
import {toggleDisableOpenMenu, toggleNotifyTinIsInValid} from "../../store/utilsSlice";
import { classnames } from '../../utils';

interface LandingCardProps {
    icon?: React.ReactNode;
    title?: string,
    path?: string,
    className?: string,
    size?: "small" | "middle"
}

const pathsWithoutTin = ['/declaration', '/payments']

export const LandingCard: React.FC<LandingCardProps> = (props) => {
    const { icon, title, path, className, size } = props;
    const navigate = useNavigate();
    const auth: any = useSelector((state: RootState) => state.auth);
    const utils: any = useSelector((state: RootState) => state.utils);
    const dispatch = useDispatch();

    const handleClick = () => {
        if (!auth.tin && pathsWithoutTin.includes(path as string)) {
            dispatch(toggleDisableOpenMenu(true));
        } else {
            if (auth.tin && !utils.tinIsValid && ['/declaration', '/revenues/other-source', '/payments'].includes(path as string)) {
                dispatch(toggleNotifyTinIsInValid(true));
                return;
            }
            path && navigate(path);
        }
    };

    const cs = classnames({
        [styles.LandingCard]: true,
        [className as string]: !!className,
        [styles[size ?? ""]]: !!size
    });

    return (
        <div className={cs} onClick={handleClick}>
            {icon && <div className={styles.Icon}>{icon}</div>}
            {title && <p className={styles.Title}>{title}</p>}
        </div>
    );
};
