import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface IDeclarationTypes {
    rows: any[];
}

const initialState: IDeclarationTypes = {
    rows: [],
};

export const declarationTypesSlice = createSlice({
    name: 'declarationTypes',
    initialState,
    reducers: {
        initializeRows: (_state, action: PayloadAction<any>) => {
            return { ..._state, rows: action.payload }
        }
    },
})

export const { initializeRows } = declarationTypesSlice.actions;
export default declarationTypesSlice.reducer;
