import React, { useState, useCallback } from "react";
import { classnames } from "../../utils";
import * as Icons from "../Icons"
import styles from "./styles.module.scss";

interface AccordionProps {
    children?: React.ReactNode;
    className?: string;
    header?: React.ReactNode | string;
    headerRightIcon?: React.ReactNode | string;
    mode?: string;
    isOpened?: boolean;
    isDisabled?: boolean;
    deleteAccordionHandler?: Function;
    accordionId?: string;
}

export const Accordion: React.FC<AccordionProps> = (props) => {
    const { children, className, header, headerRightIcon, mode, isOpened = false, isDisabled = false, deleteAccordionHandler } = props;
    const [isOpen, setIsOpen] = useState(isOpened);

    const toggleAccordion = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, []);

    const csAccordion = classnames({
        [styles.Accordion]: true,
        [styles.isDisabled]: isDisabled,
        [className as string]: !!className,
        [styles.Active]: isOpen,
        "open": isOpen
    });
    const isCustomHeader = mode === 'custom-header';

    return (
        <div className={csAccordion} data-isopen={isDisabled ? null : isOpen} data-accordion-id={isDisabled ? null : props.accordionId}>
            <div className={!isCustomHeader ? styles.AccordionHeader : ''} onClick={!isCustomHeader && !isDisabled ? toggleAccordion : () => {}}>
                {
                    isCustomHeader ? (
                        <div className={styles.customHeader}>
                            <div className={styles.customHeaderContent}>
                                {header}
                            </div>
                            <div onClick={toggleAccordion}>
                                {isOpen ? <Icons.ArrowUpIcon /> : <Icons.ArrowDownIcon />}
                            </div>
                            {deleteAccordionHandler && (
                                <div onClick={() => deleteAccordionHandler()}>
                                    <Icons.Trash />
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            {!isDisabled && headerRightIcon ? headerRightIcon : null}
                            <div className={styles.headerText}>
                                {header}
                            </div>
                            {!isDisabled && (
                                <>
                                    <span className={isOpen ? styles.up : styles.down}><Icons.ArrowDoubleRight /></span>
                                </>
                            )}
                        </>
                    )
                }
            </div>
            <div className={styles.AccordionBody}>
                {children}
            </div>
        </div>
    )
}
