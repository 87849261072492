import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { useEffect, useMemo, useRef, useState } from "react";
import { generateField, getRootDataStore } from "../utils";
import { Role, classnames, formValidator, getProfile, isNotResidentUser, isResidentUser, phoneRegexp } from "../../../../utils";
import { toast } from "react-toastify";
import styles from "./styles.module.scss"
import { Form } from "./Form";
import { Button } from "../../../Button";
import { Modal } from "../../../Modal";
import { ResidentReasonTypesDialog } from "../../../ResidentReasonTypesDialog";
import { useParams } from "react-router-dom";
import Loading from "../../../Loading";

export const PersonalDataForm: React.FC<{ onSubmit: CallableFunction, initData: CallableFunction, next: CallableFunction, dataLoading: boolean, getValidSave: CallableFunction }> = (props) => {
    const root = getRootDataStore().root;
    const { onSubmit, initData, next, dataLoading, getValidSave } = props;
    const { t, i18n } = useTranslation();
    const auth: any = useSelector((state: RootState) => state.auth);
    const { id } = useParams();
    const [userDetails, setUserDetails] = useState<any>({});
    const [formData, setFormData] = useState({
        year: generateField<Date>("taxYear", root?.taxYear ? new Date(root.taxYear) : undefined, { isValid: false, type: "date", mode: "yyyy", validation: { min: new Date(2023, 0), max: new Date(new Date().getFullYear() - 1, 0), isRequired: true } }),
        fullName: generateField<string>("taxpayerName", root?.taxpayerName, {  validation: { isRequired: true, disabled: true } }),
        incoming_tax_psn: generateField<string>("psn", root?.psn, {  validation: { disabled: true, isRequired: auth.role === Role.ROLE_RESIDENT } }),
        incoming_tax_tin: generateField<string>("tin", root?.tin, {  validation: { isRequired: true, disabled: true } }),
        physSocParticipationTypeName_am: generateField<string>("physSocParticipationTypeName_am", root?.physSocParticipationTypeName_am, { type: "string", validation: { isRequired: false, disabled: false }, visibility: "hidden" }),
        physSocParticipationTypeName_ru: generateField<string>("physSocParticipationTypeName_ru", root?.physSocParticipationTypeName_ru, { type: "string", validation: { isRequired: false, disabled: false }, visibility: "hidden" }),
        physSocParticipationTypeName_en: generateField<string>("physSocParticipationTypeName_en", root?.physSocParticipationTypeName_en, { type: "string", validation: { isRequired: false, disabled: false }, visibility: "hidden" }),
        pensionSystemParticipationStatus: generateField<string>("pensionSystemParticipationStatus", root?.[`physSocParticipationTypeName_${i18n.language}`], {  validation: { disabled: true } }),
        address: generateField<string>("taxpayerAddress", root?.taxpayerAddress, {  validation: { disabled: true, isRequired: true }, label: "livingAddress" }),
        phoneNumber:generateField<string>("taxpayerPhone", root?.taxpayerPhone?.replace?.("+(374)", ""), { type: "phone", validation: { disabled: false, pattern: phoneRegexp, patternError: "INVALID_INPUT" } }),
        docCode: generateField<string>("docCode", "sd", { type: "string", validation: { isRequired: true, disabled: true }, visibility: "hidden" }),
        socParticipationType: generateField<string>("physSocParticipationType", root?.physSocParticipationType, { type: "string", validation: { disabled: true }, visibility: "hidden" }),
        reasonCodes: generateField<any[]>("reasonCodes", root?.reasonCodes ?? [], { type: "array", visibility: "hidden" }),
        reasonPresent: generateField<boolean>("reasonPresent", root?.reasonPresent ?? true, { type: "boolean", visibility: "hidden" })
    });
    const formDataRef = useRef({ data: formData, isValid: false });
    const [approvalYear, setApprovalYear] = useState({ isOpen: false, selectedYear: undefined });
    const [approvalReasonTypes, setApprovalReasonTypes] = useState<{ isOpen: boolean, data?: any }>({ isOpen: false, data: undefined });
    const [openReasonTypes, setOpenReasonTypes] = useState(false);
    const [initialLoadingPersonatData, setInitialLoadingPersonalData] = useState(false);
    const validForm = useMemo(() => {
        const validator = formValidator(formData);
        formDataRef.current.isValid = validator.isValid;
        return validator
    }, [JSON.stringify(formData)]);

    const cleanData = useMemo(() => {
        const data = Object.values(formData).reduce((acc, field) => {
            acc[field.key] = field.value;
            return acc;
        }, {} as any);
        getValidSave?.(isNotResidentUser(auth.role) ? data.taxYear : !!data.taxYear && !!data.reasonCodes.length);
        return data;
    }, [JSON.stringify(formData)])

    const handleApprovalYear = (val?: any) => {
        setFormData((prev) => {
            prev.year.value = val ?? approvalYear.selectedYear;
            if(auth.role === Role.ROLE_RESIDENT && (!val ?? !prev.year.value)) {
                prev.reasonCodes.value = [];
                prev.reasonPresent.value = true
            }
            return {...prev }
        });
        if((val ?? approvalYear.selectedYear) && auth.role === Role.ROLE_RESIDENT) {
            setOpenReasonTypes(true);
        }
        else {
            initData?.(
                auth.role === Role.ROLE_NO_RESIDENT ? (val ?? approvalYear.selectedYear) : undefined, 
                auth.role === Role.ROLE_NO_RESIDENT ? undefined : formData.reasonPresent.value,
                { ...cleanData, taxYear: val ?? approvalYear.selectedYear }
            );
        };
        setApprovalYear({isOpen: false, selectedYear: undefined });
    };

    const handleApprovalReasonType = (data: any) => {
        if(formData.reasonPresent.value !== (!data.existsNonePresent ?? false) && !!formData.reasonCodes.value?.length) {
            return setApprovalReasonTypes({ isOpen: true, data });
        }
        if(!formData.reasonCodes.value?.length) {
            initData?.(formData.year.value, !data.existsNonePresent, { ...cleanData, reasonPresent: !data?.existsNonePresent, reasonCodes: data?.selected ?? []});
        }
        handleChange({ key: "reasonCodes", value: { reasonCodes: data?.selected ?? [], reasonPresent: !data.existsNonePresent ?? false } });
        setOpenReasonTypes(false);
    };

    const handleApproveReasonTypes = () => {
        handleChange({ key: "reasonCodes", value: { reasonCodes: approvalReasonTypes?.data?.selected ?? [], reasonPresent: !approvalReasonTypes?.data?.existsNonePresent ?? false } });
        initData?.(
            formData.year.value,
            !approvalReasonTypes?.data?.existsNonePresent, 
            { ...cleanData, reasonPresent: !approvalReasonTypes?.data?.existsNonePresent, reasonCodes: approvalReasonTypes?.data?.selected ?? []}
        );
        setOpenReasonTypes(false);
        setApprovalReasonTypes({ isOpen: false, data: undefined });
    };

    const handleChange = (arg: { key: keyof typeof formData, value: any }) => {
        const { key, value } = arg;
        setFormData((prevState) => {
            if(key === "year") {
                setApprovalYear({ isOpen: !!prevState.year.value, selectedYear: value });
                if(!prevState.year.value) {
                    handleApprovalYear(value);
                }
                return prevState
            }
            if(key === "reasonCodes") {
                prevState.reasonCodes.value = value?.reasonCodes ?? [];
                prevState.reasonPresent.value = value?.reasonPresent ?? false;
                return { ...prevState };
            }
            prevState[key].value = value;
            const { fieldsErrorTrigger } = formValidator(prevState);
            Object.entries(prevState).map(([key, field]) => {
                if(fieldsErrorTrigger[key]) {
                    field.error = fieldsErrorTrigger[key].message ?? fieldsErrorTrigger[key].errorKey
                }
                else {
                    field.error = undefined
                }
            })
            return { ...prevState }
        });
    };

    useEffect(() => {
        if(auth.role === Role.ROLE_RESIDENT) {
            setFormData((prev) => {
                prev.pensionSystemParticipationStatus.value = !id ? userDetails[`socParticipationTypeName_${i18n.language}` as keyof typeof userDetails] ?? root?.[`physSocParticipationTypeName_${i18n.language}`] : root?.[`physSocParticipationTypeName_${i18n.language}`];
                return { ...prev }
            })
        }
    }, [i18n.language, userDetails, id]);

    const changeDataMapper = (data: any, init = false) => {
        setFormData((prevState) => {
            if(isNotResidentUser(auth.role)) {
                // @ts-ignore
                delete prevState.pensionSystemParticipationStatus;
                // @ts-ignore
                delete prevState.socParticipationType;
                // @ts-ignore
                delete prevState.reasonCodes;
                // @ts-ignore
                delete prevState.reasonPresent;
                // @ts-ignore
                delete prevState.physSocParticipationTypeName_am;
                // @ts-ignore
                delete prevState.physSocParticipationTypeName_ru;
                // @ts-ignore
                delete prevState.physSocParticipationTypeName_en;
            }
            Object.entries(prevState).map(([_, field]) => {
                switch (field.key) {
                    case "taxpayerName":
                        const fullName = [data.lastName, data.name, data.middleName].filter((item) => !!item).join(" ");
                        return field.value = init ? fullName : data.taxpayerName ?? fullName;
                    case "taxYear":
                        return field.value = data[field.key] ? new Date(data[field.key]) : undefined;
                    case "taxpayerPhone":
                        return field.value = data.taxpayerPhone?.replace?.("+(374)", "") ?? data.phone?.replace?.("+(374)", "");
                    case "taxpayerAddress":
                        return field.value = data.taxpayerAddress ?? data.address;
                    case "physSocParticipationType":
                        return field.value = data.physSocParticipationType ?? data.socParticipationType;
                    case "pensionSystemParticipationStatus":
                        return field.value = data?.pensionSystemParticipationStatus ?? data[`socParticipationTypeName_${i18n.language}`] ?? data[`physSocParticipationTypeName_${i18n.language}`];
                    case "physSocParticipationTypeName_am":
                        return field.value = data?.pensionSystemParticipationStatus ?? data.socParticipationTypeName_am ?? data.physSocParticipationTypeName_am;
                    case "physSocParticipationTypeName_ru":
                        return field.value = data?.pensionSystemParticipationStatus ?? data.socParticipationTypeName_ru ?? data.physSocParticipationTypeName_ru;
                    case "physSocParticipationTypeName_en":
                        return field.value = data?.pensionSystemParticipationStatus ?? data.socParticipationTypeName_en ?? data.physSocParticipationTypeName_en;
                    case "psn": {
                        field.value = data?.psn ?? data.incoming_tax_psn ?? data.noResidentPsn;
                        break;
                    }
                    case "reasonCodes":
                        if(isResidentUser(auth.role)) {
                            return field.value = data?.reasonCodes ?? [];
                        }
                        break;
                    case "reasonPresent":
                        if(isResidentUser(auth.role)) {
                            return field.value = data?.reasonPresent ?? true;
                        }
                        break;
                    case "docCode":
                        break;
                    default :
                        return field.value = data[field.key]
                }
            });
            const { fieldsErrorTrigger } = formValidator(prevState);
            Object.entries(prevState).map(([key, field]) => {
                if(key !== "year" && fieldsErrorTrigger[key]) {
                    field.error = fieldsErrorTrigger[key].message ?? fieldsErrorTrigger[key].errorKey
                }
                else {
                    field.error = undefined
                }
            })
            return { ...prevState }
        })
    };

    useEffect(() => {
        formDataRef.current.data = formData;
    }, [JSON.stringify(formData)]);

    useEffect(() => {
        if(!dataLoading && id) {
            changeDataMapper(getRootDataStore().root);
        }
    }, [dataLoading]);

    useEffect(() => {
        if(!id && !getRootDataStore()?.personalData?.completed) {
            setInitialLoadingPersonalData(true);
            getProfile({ withAddress: true })
            .then((res) => {
                const { data } = res;
                setUserDetails(data);
                changeDataMapper(data, true);
            })
            .catch(err => {
                console.log('err >>>', err);
                toast.error(t(err.message), { position: 'top-right', theme: 'colored' });
            })
            .finally(() => setInitialLoadingPersonalData(false));
        }
        else {
            setFormData((prevState) => {
                const { fieldsErrorTrigger } = formValidator(prevState);
                Object.entries(prevState).map(([key, field]) => {
                    if(fieldsErrorTrigger[key]) {
                        field.error = fieldsErrorTrigger[key].message ?? fieldsErrorTrigger[key].errorKey
                    }
                    else {
                        field.error = undefined
                    }
                })
                return { ...prevState }
            });
        }
        return () => {
            if (window.location.pathname.includes("declaration/type/sd")) {
                const { pensionSystemParticipationStatus, ...rest } = formDataRef.current.data ?? {};
                rest.phoneNumber.value = rest.phoneNumber.value ? `+(374)${rest.phoneNumber.value}` : '';
                onSubmit(rest, !formDataRef.current.isValid);
            }
        }
    }, []);

    const csDecision = classnames({
        [styles.Decision]: true,
    });

    return (
        <div className={styles.PersonalData}>
            <div className={csDecision}>
                <p>{t('govDecision4Mobile')}</p>
            </div>
            {(formData.year.value && auth.role === Role.ROLE_RESIDENT) && (
                <div className={styles.ChangeReasonTypes}>
                    <Button onClick={() => setOpenReasonTypes(true)} variant="outlined" size="s" color="primary">{t('residentReasonTypes_tr_1')}</Button>
                </div>
            )} 
            <Form data={formData} onChange={handleChange} />
            <Button onClick={() => next()} disabled={!validForm.isValid}>{t("next")}</Button>
            <Modal
                className={`${styles.attentionModal} ${styles.ChangeYear}`}
                title={t('attention')}
                size="s-fit-content"
                isOpen={approvalYear.isOpen}
                closeMessage={t('No')}
                onClose={() => setApprovalYear({ isOpen: false, selectedYear: undefined })}
                footerExtraComponents={(
                    <Button size="s" color="primary" onClick={() => handleApprovalYear()}>{t('Yes')}</Button>
                )}
                footerExists
                type='warning'
                mode='head-colored'
            >
                <div className={styles.ChangeYearAsk}>
                    <div className={styles.msg}>
                        <p>{t('change_date_year')}</p>
                    </div>
                    <div className={styles.msg}>{t('are_you_sure_incoming_tax')}</div>
                </div>
            </Modal>
            <Modal
                className={`${styles.attentionModal} ${styles.ChangeYear}`}
                title={t('attention')}
                size="s-fit-content"
                isOpen={approvalReasonTypes.isOpen}
                closeMessage={t('No')}
                onClose={() => setApprovalReasonTypes({ isOpen: false, data: undefined })}
                footerExtraComponents={(
                    <Button size="s" color="primary" onClick={handleApproveReasonTypes}>{t('Yes')}</Button>
                )}
                footerExists
                type='warning'
                mode='head-colored'
            >
                <div className={styles.ChangeYearAsk}>
                    <div className={styles.msg}>
                        <p>{t('change_reason_type_attention')}</p>
                    </div>
                    <div className={styles.msg}>{t('are_you_sure_incoming_tax')}</div>
                </div>
            </Modal>
            {auth.role === Role.ROLE_RESIDENT && (
                <ResidentReasonTypesDialog
                    isOpen={openReasonTypes && !approvalReasonTypes.isOpen}
                    close={() => {
                        if(!formData.reasonCodes.value?.length) {
                            setFormData((prev) => {
                                prev.year.value = undefined;
                                return {...prev}
                            })
                        }
                        setOpenReasonTypes(false);
                    }}
                    approve={handleApprovalReasonType}
                    year={formData?.year?.value ? new Date(formData?.year?.value).getFullYear() : undefined}
                    reasonCodes={formData?.reasonCodes?.value as string[]}
                    reasonPresent={formData.reasonPresent.value}
                />
            )}
            {initialLoadingPersonatData && <Loading />}
        </div>
    )
};