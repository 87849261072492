import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { languages, ILanguage } from '../utils/languages';
import translationAM from './translation/am.json';
import translationRU from './translation/ru.json';
import translationEN from './translation/en.json';

const defaultLang: ILanguage | undefined = languages.find(lang => lang.isDefault);

const resources = {
    am: {
        translation: translationAM
    },
    ru: {
        translation: translationRU
    },
    en: {
        translation: translationEN
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: defaultLang?.code,
        debug: true,
        interpolation: {
            escapeValue: false
        }
    });


export default i18n;