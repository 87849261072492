export interface IData {
    [key: string]: {
       title: string;
       rows: string[]
   }
}

export const data = {
    am: {
        title: 'Հաշվարկ (հայտարարագիր) եկամտային հարկի և սոցիալական վճարի լրացման կարգ',
        rows: [
            {
                index: 1,
                title: 'Հաշվարկի (հայտարարագրի) առաջին մասում լրացվում են ֆիզիկական անձի նույնականացման տվյալները: Ընդ որում՝',
                rows: [
                    "1-ին կետում լրացվում է ֆիզիկական անձի ազգանունը, անունը, հայրանունը։ Հաշվարկի (հայտարարագրի) 2-րդ կետը լրացնելու դեպքում 1-ին կետը լրացվում է ինքնաշխատ,",
                    "2-րդ կետում լրացվում է ֆիզիկական անձի հանրային ծառայությունների համարանիշը կամ հանրային ծառայությունների համարանիշ չունենալու մասին տեղեկանքի համարը (սոցիալական ապահովության քարտի համարը կամ սոցիալական ապահովության քարտ չունենալու մասին տեղեկանքի համարը),",
                    "3-րդ կետում լրացվում է ֆիզիկական անձի հարկ վճարողի հաշվառման համարը (ՀՎՀՀ-ն),",
                    "4-րդ կետում լրացվում է ֆիզիկական անձի կուտակային կենսաթոշակային համակարգում մասնակցության կոդը (կոդը 1` եթե ֆիզիկական անձը սոցիալական վճար կատարող չէ, կոդը 2՝ եթե ֆիզիկական անձը սոցիալական վճար կատարող է, կոդը 3՝ եթե ֆիզիկական անձը դիմումի հիման վրա սոցիալական վճար կատարող է),",
                    "5-րդ կետում լրացվում է ֆիզիկական անձի բնակության (հաշվառման) հասցեն,",
                    "6-րդ կետում լրացվում է ֆիզիկական անձի հեռախոսահամարը (առկայության դեպքում)։"
                ],
                section: 'personal'
            },
            {
                index: 2,
                title: 'Հաշվարկի (հայտարարագրի) 1-ին բաժնում՝ անձնավորված հաշվառման տեղեկատվական համակարգից ինքնաշխատ լրացվում են հաշվետու տարում հարկային գործակալից ստացվող (ստացված) եկամուտները, հարկային գործակալի պարտականություններից ազատված գործատուից աշխատանքային պայմանագրի, ծառայությունների մատուցման (աշխատանքների կատարման) քաղաքացիաիրավական պայմանագրի շրջանակներում ստացվող (ստացված) եկամուտները, այդ եկամուտներից եկամտային հարկը։',
                section: '1'
            },
            {
                index: 3,
                title: 'Հաշվարկի (հայտարարագրի) 1-ին բաժնի 1-ին գլխում ինքնաշխատ լրացվում են աշխատանքային պայմանագրերի, ծառայությունների մատուցման (աշխատանքների կատարման) քաղաքացիաիրավական պայմանագրերի շրջանակներում հարկային գործակալից կամ հարկային գործակալի պարտականություններից ազատված գործատուից ստացվող (ստացված) եկամուտները, ժամանակավոր անաշխատունակության և մայրության նպաստները, այլ եկամուտները և եկամտային հարկը՝ ըստ հարկային գործակալների (գործատուների)։ Ընդ որում,',
                rows: [
                    "[1.1.1] սյունակում լրացվում է հարկային գործակալի (գործատուի) հարկ վճարողի հաշվառման համարը (ՀՎՀՀ-ն),",
                    "[1.1.2] սյունակում լրացվում է հարկային գործակալի (գործատուի) անվանումը,",
                    "[1.1.5] տողում լրացվում է աշխատավարձը և դրան հավասարեցված այլ վճարումները,",
                    "[1.1.6] տողում լրացվում է քաղաքացիաիրավական պայմանագրով աշխատանքի (ծառայության) դիմաց վարձատրությունը,",
                    "[1.1.7] տողում լրացվում է ժամանակավոր անաշխատունակության, մայրության նպաստները,",
                    "[1.1.8] տողում լրացվում է աշխատողի ստացված այլ եկամուտները,",
                    "[1.1.9] տողում լրացվում է աշխատողի հարկման բազայի նվազեցման գումարը (կամավոր կենսաթոշակային վճարների գումարը, ոչ ավելի, քան (տող [1.1.5] + տող [1.1.6] + տող [1.1.7] + տող [1.1.8]) - (տող [1.1.5] + տող [1.1.6] + տող [1.1.7] + տող [1.1.8]) / 1,05),",
                    "[1.1.10] տողի [1.1.3] սյունակում լրացվում է յուրաքանչյուր հարկային գործակալից (գործատուից) ստացվող (ստացված) հարկվող եկամուտը, իսկ նույն տողի [1.1.4] սյունակում լրացվում է այդ հարկվող եկամտից եկամտային հարկի գումարը,",
                    "[1.1.11] տողում լրացվում է հարկային գործակալներից (գործատուներից) ստացված (ստացվող) հարկվող եկամտից եկամտային հարկի գումարների հանրագումարը։",
                ],
                section: '1_1'
            },
            {
                index: 4,
                title: 'Հաշվարկի (հայտարարագրի) 1-ին բաժնի 2-րդ գլխում ներառվում են ֆիզիկական անձի ստացված այլ եկամուտները և եկամտային հարկը՝ ըստ հարկային գործակալների։ Ընդ որում,',
                rows: [
                    "[1.2.1] սյունակում լրացվում է հարկային գործակալի հարկ վճարողի հաշվառման համարը (ՀՎՀՀ-ն),",
                    "[1.2.2] սյունակում լրացվում է հարկային գործակալի անվանումը,",
                    {
                        title: "[1.2.3] սյունակում լրացվում են տվյալ հարկային գործակալից ստացված եկամուտների չափը ըստ եկամտի տեսակների՝",
                        rows: [
                            "ա․ ռոյալթի (տող [1.2.5]),",
                            "բ․ տոկոսներ (տող [1.2.6]),",
                            "գ․ վարձավճար (տող [1.2.7]),",
                            "դ․ շահաբաժին (տող [1.2.8]),",
                            "ե․ նվիրատվության կարգով ստացվող գույք և (կամ) դրամական միջոցներ (տող [1.2.9]),",
                            "զ․ գույքի (բացառությամբ սույն գլխի 7-րդ կետում նշվածի) օտարումից եկամուտ (տող [1.2.10]),",
                            "է․ կառուցապատողի կողմից շենքի, դրա բնակարանների կամ այլ տարածքների օտարումից եկամուտ (տող [1.2.11]),",
                            "ը․ Հայաստանի Հանրապետության օրենսդրությամբ սահմանված կարգով և պայմաններով իրականացվող՝ գովազդային վիճակախաղերի մասնակիցների դրամական և իրային շահումներ՝ յուրաքանչյուր շահման դեպքում 50 հազար դրամը գերազանցող չափով (տող [1.2.12]),",
                            "թ․ մրցույթներում ստացվող դրամական մրցանակներ յուրաքանչյուր մրցանակի դեպքում 50 հազար դրամը գերազանցող չափով (տող [1.2.13]),",
                            "ժ․ հարկային գործակալների կողմից ֆիզիկական անձանց համար կատարվող ապահովագրավճարներ (տող [1.2.14]),",
                            "ժա․ կամավոր կենսաթոշակային բաղադրիչին մասնակցության շրջանակներում ստացվող կենսաթոշակներ (տող [1.2.15]),",
                            "ժբ․ 1-ին բաժնի 2-րդ գլխի 1-11-րդ կետերում չնշված այլ եկամուտներ (տող [1.2.16]),",
                        ]
                    },
                    "[1.2.4] սյունակում լրացվում են [1.2.3] սյունակում նշված եկամուտներից եկամտային հարկի գումարները,",
                    "[1.2.17] տողում (սյունակ [1.2.4]) լրացվում է [1.2.5]-ից [1.2.16] տողերում լրացված եկամուտներից եկամտային հարկի գումարների հանրագումարը։",
                ],
                section: '1_2'
            },
            {
                index: 5,
                title: "Հաշվարկի (հայտարարագրի) 2-րդ բաժնում լրացվում են Հայաստանի Հանրապետության հարկային օրենսգրքի 147-րդ հոդվածի 1-ին մասով սահմանված՝ տվյալ հարկային տարվա ընթացքում ֆիզիկական անձի ստացած նվազեցվող (չհարկվող) եկամուտները։ Սույն բաժնում լրացվող եկամուտների վերաբերյալ հարկային մարմնի տեղեկատվական բազայում տվյալների առկայության դեպքում, այդ եկամուտները լրացվում են ինքնաշխատ։",
                section: '2'
            },
            {
                index: 6,
                title: "Հաշվարկի (հայտարարագրի) 2-րդ բաժնի՝",
                rows: [
                    "[2.1] տողում լրացվում են Հայաստանի Հանրապետության օրենսդրության համաձայն ֆիզիկական անձանց ստացվող նպաստների գումարները, բացառությամբ «Ժամանակավոր անաշխատունակության և մայրության նպաստների մասին» Հայաստանի Հանրապետության օրենքով սահմանված` ժամանակավոր անաշխատունակության նպաստների և վարձու աշխատողների ու ինքնուրույնաբար իրենց աշխատանքով ապահոված ֆիզիկական անձանց մայրության նպաստների գումարների,",
                    "[2.2] տողում լրացվում են բոլոր տեսակի կենսաթոշակները (այդ թվում` «Կուտակային կենսաթոշակների մասին» Հայաստանի Հանրապետության օրենքով կուտակային բաղադրիչի շրջանակներում ստացվող կուտակային կենսաթոշակները, այլ պետությունների օրենսդրության շրջանակներում ստացվող կենսաթոշակները և այլ պետությունների կենսաթոշակային ապահովության համակարգի շրջանակներում ստացվող համանման վճարները), բացառությամբ սահմանված կարգով կամավոր կենսաթոշակային բաղադրիչին մասնակցության շրջանակներում ստացվող կենսաթոշակների,",
                    "[2.3] տողում լրացվում են ապահովագրական հատուցումները, բացառությամբ Հայաստանի Հանրապետության օրենսդրությամբ սահմանված կարգով կամավոր կենսաթոշակային բաղադրիչի շրջանակներում ֆիզիկական անձի կողմից իր համար և (կամ) ֆիզիկական անձի համար երրորդ անձի (այդ թվում` գործատուի) կողմից կատարվող կամավոր կենսաթոշակային վճարների հաշվին սահմանված կարգով ստացվող հատուցումների (այդ թվում՝ կենսաթոշակների),",
                    "[2.4] տողում լրացվում են բանկերի կամ վարկային կազմակերպությունների միջոցով ստացված գումարները, որոնք բանկերը կամ վարկային կազմակերպություններն ստացել են որպես ապահովագրական հատուցում, որոնց մասով բանկը կամ վարկային կազմակերպությունը հանդիսանում է շահառու՝ այն մասով, որ մասով այդ գումարները, ապահովագրության պայմանագրի համաձայն, բանկերի կամ վարկային կազմակերպությունների կողմից տրամադրվում են վարկառու կամ գրավատու ֆիզիկական անձին կամ ուղղվում են վարկառու ֆիզիկական անձի վարկի մարմանը,",
                    "[2.5] տողում լրացվում են «Կուտակային կենսաթոշակների մասին» Հայաստանի Հանրապետության օրենքով կուտակային կենսաթոշակային բաղադրիչի շրջանակներում ֆիզիկական անձի համար (օգտին) Հայաստանի Հանրապետության պետական բյուջեից հատկացվող լրացուցիչ միջոցները,",
                    "[2.6] տողում լրացվում են Հայաստանի Հանրապետության օրենսդրության համաձայն՝ սոցիալական պաշտպանության համակարգի շրջանակներում ստացվող պատվովճարները, դրամական օգնություններն ու օժանդակությունները,",
                    "[2.7] տողում լրացվում են Հայաստանի Հանրապետության օրենսդրության համաձայն ստացվող ալիմենտները (ապրուստավճարները),",
                    "[2.8] տողում լրացվում են արյուն ու կրծքի կաթ հանձնելու և դոնորության այլ տեսակների համար ստացվող եկամուտները,",
                    "[2.9] տողում լրացվում են Հայաստանի Հանրապետության օրենսդրությամբ սահմանված նորմաների (չափերի) սահմաններում աշխատանքային և կամավոր աշխատանքի մասին պայմանագրերի շրջանակներում աշխատանքների կատարման հետ կապված աշխատողների (կամավորների) ստացված փոխհատուցման վճարները (այդ թվում` դիվանագիտական ծառայողների փոխհատուցման վճարները), բացառությամբ աշխատանքից ազատվելու դեպքում չօգտագործված արձակուրդի փոխհատուցման վճարների,",
                    "[2.10] տողում լրացվում են Հայաստանի Հանրապետության օրենսդրության համաձայն՝ ժառանգության և (կամ) նվիրատվության կարգով ֆիզիկական անձանցից ստացվող գույքը և (կամ) դրամական միջոցները, բացառությամբ Հայաստանի Հանրապետության հարկային օրենսգրքի 145-րդ հոդվածի 1-ին մասի 1-ին կետով սահմանված՝ բազմաբնակարան (այդ թվում՝ բազմաֆունկցիոնալ) շենքի, ստորաբաժանված շենքի կառուցապատող համարվող ֆիզիկական անձից նվիրատվության կարգով ստացվող գույքի,",
                    "[2.11] տողում լրացվում են ոչ առևտրային կազմակերպություններից, Հայաստանի Հանրապետության պետական և տեղական ինքնակառավարման մարմինների, օրենքով ստեղծված մշտապես գործող մարմինների որոշումների հիման վրա, ինչպես նաև օտարերկրյա պետություններից և միջազգային, միջպետական (միջկառավարական) կազմակերպություններից անհատույց ստացված ակտիվները, աշխատանքները, ծառայությունները, սույն կետում նշված մարմինների ու միջազգային, միջպետական (միջկառավարական) կազմակերպությունների կողմից ֆիզիկական անձանց համար (օգտին) կատարված վճարումները, այլ անձանցից անհատույց ստացված եկամուտները, օգնությունները։",
                    {
                        title: "[2.12] տողում լրացվում են ֆիզիկական անձի կողմից գույքի օտարումից ստացված նվազեցվող եկամուտները։ Մասնավորապես, սույն կետում լրացվում են՝",
                        rows: [
                            "ա. գույքի օտարումից անհատ ձեռնարկատեր և նոտար չհանդիսացող այլ ֆիզիկական անձանցից ստացվող եկամուտները, բացառությամբ՝ Հայաստանի Հանրապետության հարկային օրենսգրքի 145-րդ հոդվածի 1-ին մասի 1-ին կետով սահմանված` բազմաբնակարան (այդ թվում` բազմաֆունկցիոնալ) շենքի, ստորաբաժանված շենքի կառուցապատող համարվող (համարված) ֆիզիկական անձի կողմից շենքի, դրա բնակարանների, շինությունների (այդ թվում` անավարտ, կիսակառույց) կամ այլ տարածքների (առանց ընդհանուր բաժնային սեփականություն հանդիսացող ոչ բնակելի տարածքների) օտարումից ստացվող եկամուտների,",
                            "բ. պետության կամ համայնքների կարիքների համար «Հանրության գերակա շահերի ապահովման նպատակով սեփականության օտարման մասին» Հայաստանի Հանրապետության օրենքով սահմանված կարգով ֆիզիկական անձանց պատկանող անշարժ գույքը վերցնելու դիմաց այդ ֆիզիկական անձանց, ինչպես նաև այդ անշարժ գույքում հաշվառված (գրանցված) ֆիզիկական անձանց վճարվող գումարները,",
                            "գ. հողամասի (անկախ հողամասի նպատակային նշանակությունից) օտարումից ստացվող եկամուտները,",
                            "դ. առևտրի իրականացման վայրերում (ոսկու շուկաներում) վաճառասեղանների կամ վաճառակետերի միջոցով թանկարժեք մետաղների, թանկարժեք մետաղներից պատրաստված իրերի կամ թանկարժեք քարերի առք ու վաճառքի գործունեություն իրականացնող հարկային գործակալին օտարվող ոսկու և թանկարժեք քարերի դիմաց ստացվող եկամուտները,",
                            "ե. սեփականության իրավունքով իրեն պատկանող ավտոտրանսպորտային միջոցի (այդ թվում՝ գյուղատնտեսական տեխնիկայի) օտարումից ավտոտրանսպորտային միջոցի (այդ թվում՝ գյուղատնտեսական տեխնիկայի) ձեռքբերման թրեյդ-ին առևտրի իրականացման լիցենզիա ունեցող՝ ԱԱՀ վճարող համարվող կազմակերպությունից ստացվող եկամուտները,.",
                            "զ. սեփականության իրավունքով իրեն պատկանող գույքի օտարումից բանկերից և վարկային կազմակերպություններից ստացվող եկամուտները, եթե հետագայում այդ գույքը նույն բանկի կամ վարկային կազմակերպության կողմից անհատ ձեռնարկատեր և նոտար չհանդիսացող ֆիզիկական անձանց տրամադրվում է լիզինգով (տարատեսակներով)։",
                        ]
                    },
                    "[2.13] տողում լրացվում են բարձրագույն ուսումնական հաստատությունների ուսանողների, ասպիրանտների, միջնակարգ-մասնագիտական ու պրոֆեսիոնալ-տեխնիկական ուսումնական հաստատությունների սովորողների, հոգևոր ուսումնական հաստատությունների ունկնդիրների՝ պետությունից ստացվող կրթաթոշակները, ինչպես նաև այն կրթաթոշակները, որոնք նրանց համար նշանակում են այդ ուսումնական հաստատությունները կամ ոչ առևտրային կազմակերպությունները կամ օտարերկրյա պետությունները, միջազգային, միջպետական (միջկառավարական) կազմակերպությունները։",
                    "[2.14] տողում լրացվում են այն գումարները, որոնք օրենքով սահմանված կարգով ստացվում են որպես պատճառված վնասի փոխհատուցում, բացառությամբ բաց թողնված եկամտի փոխհատուցման գումարների։",
                    "[2.15] տողում լրացվում են ստացվող վարկերի և փոխառությունների գումարները, բացառությամբ պարտատիրոջ կողմից վարկի կամ փոխառության գումարների զիջման կամ որևէ այլ ձևով այդ գումարները չվերադարձնելու մասին պարտատիրոջ հետ համաձայնության դեպքերի (այդ թվում` օրենքով սահմանված հայցային վաղեմության ժամկետը լրանալու պահին)։",
                    "[2.16] տողում լրացվում են Հայաստանի Հանրապետության օրենսդրությամբ սահմանված կարգով և պայմաններով իրականացվող՝ գովազդային վիճակախաղերի մասնակիցների դրամական և իրային շահումները՝ յուրաքանչյուր շահման դեպքում 50 հազար դրամը չգերազանցող չափով, խաղարկությունով, ոչ խաղարկությունով և համակցված վիճակախաղերի, ինչպես նաև տոտալիզատորի մասնակիցների դրամական և իրային շահումները կամ խաղատներում, շահումով խաղերում և (կամ) ինտերնետ շահումով խաղերում ստացված շահումները,",
                    "[2.17] տողում լրացվում է մրցույթներում ստացվող դրամական և իրային մրցանակների արժեքը` յուրաքանչյուր մրցանակի դեպքում 50 հազար դրամը չգերազանցող չափով։",
                    "[2.18] տողում լրացվում են «Ֆիզիկական անձանց բանկային ավանդների հատուցումը երաշխավորելու մասին» Հայաստանի Հանրապետության օրենքով սահմանված կարգով Ավանդների հատուցումը երաշխավորող հիմնադրամից հատուցվող գումարները, բացառությամբ ավանդի գումարին հաշվեգրվող և հատուցվող տոկոսների։",
                    "[2.19] տողում լրացվում են ԽՍՀՄ խնայբանկի ՀԽՍՀ հանրապետական բանկում մինչև 1993 թվականի հունիսի 10-ը ներդրած դրամական ավանդների դիմաց փոխհատուցվող գումարները։",
                    "[2.20] տողում լրացվում են միասնական հաշվին առկա գումարների վերադարձն Հայաստանի Հանրապետության հարկային օրենսգրքի 327-րդ հոդվածով սահմանված ժամկետից 30 օրվանից ավելի ուշացնելու դեպքում այդ ժամկետին հաջորդող յուրաքանչյուր ուշացված օրվա համար ֆիզիկական անձի օգտին հաշվեգրվող տույժերը։",
                    "[2.21] տողում լրացվում են «Ավտոմոբիլային տրանսպորտի մասին» Հայաստանի Հանրապետության օրենքի 17-րդ հոդվածի 1-ին մասի երկրորդ պարբերությամբ սահմանված ֆիզիկական անձանց կողմից մեկ մարդատար տաքսի ավտոմոբիլով փոխադրումներ իրականացնելու գործունեությունից ստացվող եկամուտները։",
                    "[2.22] տողում լրացվում են վերականգնվող էներգետիկ ռեսուրսներ օգտագործող ինքնավար էներգաարտադրողի՝ էլեկտրական էներգիայի բաշխման լիցենզիա ունեցող անձից ստացվող հատուցման գումարները, ինչպես նաև հավասար փոխհոսքերի դեպքում ինքնավար էներգաարտադրողի կողմից էլեկտրական էներգիայի բաշխման լիցենզիա ունեցող անձին մատակարարված էլեկտրական էներգիայի դիմաց էլեկտրական էներգիայի տեսքով ստացվող փոխհատուցումները։",
                    "[2.23] տողում լրացվում են գյուղատնտեսական արտադրանք արտադրող ֆիզիկական անձանց՝ գյուղատնտեսական արտադրանքի մատակարարումից (իրացումից) ստացվող եկամուտները։",
                    "[2.24] տողում լրացվում են արժեթղթերից ստացվող նվազեցվող եկամուտները՝ համաձայն Հայաստանի Հանրապետության հարկային օրենսգրքի 149-րդ հոդվածի։",
                    "[2.25] տողում լրացվում են «Հայաստանի Հանրապետության պաշտպանության ժամանակ զինծառայողների կյանքին կամ առողջությանը պատճառված վնասների հատուցման մասին» Հայաստանի Հանրապետության օրենքի հիման վրա ֆիզիկական անձանց վճարվող հատուցման գումարները։",
                    "[2.26] տողում լրացվում են սույն բաժնի [2.1]-ից [2.25] տողերում չնշված՝ Հայաստանի Հանրապետության օրենսգրքի 147-րդ հոդվածի 1-ին մասով սահմանված այլ նվազեցվող եկամուտները։",
                    "[2.27] տողում լրացվում են հաշվետու հարկային տարվա ընթացքում ֆիզիկական անձանց ստացված նվազեցվող եկամուտների հանրագումարը։",
                ],
                section: '2'
            },
            {
                index: 7,
                title: "Հաշվարկի (հայտարարագրի) 3-րդ բաժնում լրացվում են հաշվետու տարվա ընթացքում հարկային գործակալ չհանդիսացողներից (բացառությամբ տող [3.11]-ի) ստացվող (ստացված) եկամուտները և այդ եկամուտներից եկամտային հարկի հաշվարկը։",
                section: '3'
            },
            {
                index: 8,
                title: "Հաշվարկի (հայտարարագրի) [3.1] սյունակում լրացվում են՝",
                rows: [
                    "հարկային գործակալ չհանդիսացողներից աշխատանքային պայմանագրի շրջանակներում ստացվող (ստացված) եկամուտը (տող [3.4]),",
                    "հարկային գործակալ չհանդիսացողներից քաղաքացիաիրավական պայմանագրով աշխատանքի կատարման (ծառայության մատուցման) դիմաց ստացված եկամուտը (տող [3.5]),",
                    "հարկման բազայից նվազեցվող կամավոր կենսաթոշակային վճարների նվազեցվող գումարը ((տող [3.4] + տող [3.5]) - ([3.4] + [3.5]) / 1,05) (տող [3.6]),",
                    "աշխատանքային և ծառայությունների մատուցման (աշխատանքների կատարման) քաղաքացիաիրավական պայմանագրերի շրջանակներում հարկային գործակալ չհանդիսացողներից ստացվող (ստացված) հարկվող եկամուտը (տող [3.4] + տող [3.5]) + տող [3.6]) (տող [3.7]),",
                    "ռոյալթին (տող [3.8]),",
                    "տոկոսը (տող [3.9]),",
                    "վարձավճարը (տող [3.10]),",
                    "հարկային տարվա ընթացքում ստացված (այդ թվում հարկային գործակալներից) վարձակալական վճարների հանրագումարի 60 միլիոն դրամը գերազանցող գումարը (տող [3.11]),",
                    "շահաբաժինը (տող [3.12]),",
                    "գույքի (բացառությամբ 1-ին կետի 11-րդ ենթակետում նշվածի) օտարման դիմաց եկամուտը (տող [3.13]),",
                    "կառուցապատողի կողմից շենքի, դրա բնակարանների կամ այլ տարածքների օտարումից եկամուտը (տող [3.14]),",
                    "կազմակերպության կանոնադրական կամ բաժնեհավաք կապիտալում շենքի, բնակարանի, առանձնատան կամ այլ շինության (այդ թվում` անավարտ (կիսակառույց) ձևով մասնակցության (բաժնետոմսի, բաժնեմասի, փայաբաժնի) կամ ներդրում վկայող այլ արժեթղթերի օտարումից եկամուտները, եթե այդ օտարումը կատարվում է կանոնադրական կամ բաժնեհավաք կապիտալում այդ մասնակցությունը (բաժնետոմսը, բաժնեմասը, փայաբաժինը) ձեռք բերելու օրը ներառող հարկային տարվա կամ դրան հաջորդող երեք հարկային տարիների ընթացքում (տող [3.15]),",
                    "մրցույթներում ստացվող դրամական և իրային մրցանակների՝ յուրաքանչյուր մրցանակի դեպքում 50 հազար դրամը գերազանցող չափը (տող [3.16]),",
                    "նվիրատվությամբ ստացվող գույքը (այդ թվում՝ դրամական միջոցները) (տող [3.17]),",
                    "եկամուտների ստացման իրավունքը ձեռք բերելու հարկային տարվան հաջորդող 12-ամսյա ժամանակահատվածում չվճարված պասիվ եկամուտները (տող [3.18]),",
                    "ստացվող (ստացված) այլ եկամուտները (տող [3.19])։",
                ],
                section: '3'
            },
            {
                index: 9,
                title: "[3.2] սյունակում լրացվում են եկամտային հարկի դրույքաչափերը և [3.3] սյունակում լրացվում են [3.1] սյունակում նշված եկամուտների համար հաշվարկված եկամտային հարկի գումարները:",
                section: '3'
            },
            {
                index: 10,
                title: "[3.20] տողում ինքնաշխատ լրացվում է հաշվետու հարկային տարվա համար հաշվարկված եկամտային հարկի գումարը, որը որոշվում է [3.1] սյունակում ներառված եկամուտների համար հաշվարկված եկամտային հարկի գումարների հանրագումարի չափով։",
                section: '3'
            },
            {
                index: 11,
                title: "[3.21] և [3.22] տողերում լրացվում են այլ պետություններում՝ դրանց օրենսդրությանը համապատասխան պահված (գանձված) հարկը (օտարերկրյա պետության հարկային մարմնի կողմից վավերացված տեղեկանքի հիման վրա) և հարկի այն գումարը, որի չափով Հայաստանի Հանրապետության օրենսդրությանը համապատասխան ենթակա է նվազեցման տարեկան եկամտային հարկը:",
                section: '3'
            },
            {
                index: 12,
                title: "[3.23] տողում լրացվում է պետական բյուջե վճարման ենթակա եկամտային հարկը (տող [3.23]=տող [3.20]+տող [3.22]):",
                section: '3'
            },
            {
                index: 13,
                title: "Հաշվարկի (հայտարարագրի) 4-րդ բաժնում ինքնաշխատ լրացվում են հաշվետու տարվա ընթացքում ֆիզիկական անձի կողմից՝ իր և իր ընտանիքի անդամների համար կատարված և հարկային հաշիվներով կամ հաշիվ վարվերագրերով հիմնավորված սոցիալական ծախսերը, ինչպես նաև այդ ծախսերի առավելագույն չափերը չգերազանցող՝ փոխհատուցման ենթակա գումարները։ Ընդ որում, Հաշվարկի (հայտարարագրի)՝",
                rows: [
                    "[4.1] սյունակում ([4.3] և [4.4] տողեր) լրացվում են հաշվետու տարվա ընթացքում ֆիզիկական անձի կատարած սոցիալական ծախսերի գումարները՝ ըստ առանձին ուղղությունների։ Մասնավորապես, առողջապահության գծով սոցիալական ծախսեր են համարվում Հայաստանի Հանրապետությունում գործող ապահովագրական ընկերություններից առողջության ապահովագրական փաթեթի ձեռքբերման ծախսերը, իսկ կրթության գծով սոցիալական ծախսեր են համարվում Հայաստանի Հանրապետությունում իրականացվող նախադպրոցական, տարրական (ընդհանուր, մասնագիտացված, հատուկ), հիմնական (ընդհանուր, մասնագիտացված, հատուկ), միջնակարգ (ընդհանուր, մասնագիտացված, հատուկ), նախնական մասնագիտական (արհեստագործական), միջին մասնագիտական, բարձրագույն մասնագիտական և հետբուհական մասնագիտական կրթական ծրագրերի մասով կատարված ուսման վճարները,",
                    "[4.2] սյունակում ([4.3] և [4.4] տողեր) լրացվում են սոցիալական ծախսերի փոխհատուցման ենթակա գումարները, որոնք չեն կարող գերազանցել Կառավարության սահմանած առավելագույն չափերը,",
                    "[4.5] տողի [4.2] սյունակում լրացվում է ֆիզիկական անձի՝ իր և իր ընտանիքի անդամների համար կատարված բոլոր ուղղություններով սոցիալական ծախսերի փոխհատուցման ենթակա գումարների հանրագումարը, որը չի կարող գերազանցել Կառավարության սահմանած առավելագույն չափը։",
                ],
                section: '4'
            },
            {
                index: 14,
                title: "Հաշվարկի (հայտարարագրի) 5-րդ բաժնում ինքնաշխատ լրացվում են հաշվետու հարկային տարվա ընթացքում ֆիզիկական անձանց վճարման կամ փոխհատուցման (վերադարձման) ենթակա եկամտային հարկի հաշվարկը։ Ընդ որում, սույն բաժնի՝",
                rows: [
                    "[5.2] տողում լրացվում է ֆիզիկական անձի՝ հարկային գործակալից (գործատուից) ստացվող (ստացված) եկամուտներից հաշվարկված և վճարված եկամտային հարկի գումարները (տող [1.1.11] + տող [1.2.17]),",
                    "[5.3] տողում լրացվում է բնակարան կամ անհատական բնակելի տուն ձեռք բերելու կամ անհատական բնակելի տուն կառուցելու նպատակով ֆիզիկական անձի ստացած հիպոտեկային վարկի սպասարկման համար վճարված տոկոսների փոխհատուցման նպատակով ֆիզիկական անձի աշխատավարձի և դրան հավասարեցված վճարումների մասով հաշվարկված և վճարված եկամտային հարկի վերադարձված գումարները,",
                    "[5.4] տողում լրացվում է Հայաստանի Հանրապետությունում հավատարմագրված բարձրագույն ուսումնական հաստատությունների՝ Կառավարության հաստատած մասնագիտություններով առկա ուսուցմամբ մագիստրատուրայում, ասպիրանտուրայում և օրդինատուրայում սովորող (ուսանող) վարձու աշխատողի ուսման վարձը փոխհատուցված (եկամտային հարկի վերադարձված) գումարը,",
                    "[5.5] տողում լրացվում է ֆիզիկական անձի՝ կանոնադրական կամ բաժնեհավաք կապիտալում ներդրված շահաբաժնի գծով փոխհատուցված (եկամտային հարկի վերադարձված) գումարը,",
                    "[5.6] տողում լրացվում է հարկային գործակալից (գործատուից) ստացվող (ստացված) եկամուտներից հաշվարկված և վճարված եկամտային հարկի նվազեցման ենթակա գումարը ([5.2] - [5.3] - [5.4] - [5.5]),",
                    "[5.7] տողում լրացվում է հաշվարկված և պետական բյուջե վճարման ենթակա եկամտային հարկի և հարկային գործակալի կողմից պահված եկամտային հարկի նվազեցման ենթակա գումարի հանրագումարը ([3.23] տող + [5.6] տող),",
                    "[5.8] տողում լրացվում է ֆիզիկական անձի կատարած սոցիալական ծախսերի փոխհատուցման գումարը՝ հաշվի առած Կառավարության սահմանած առավելագույն չափը (բաժին 4-ի [4.5] տողի [4.2] սյունակ),",
                    "[5.9] տողում լրացվում է պետական բյուջե վճարման ենթակա եկամտային հարկի գումարը ([5.7] տող - [5.8] տող - [5.6] տող, եթե այն > 0-ից),",
                    "[5.10] տողում լրացվում է պետական բյուջեից փոխհատուցման ենթակա եկամտային հարկի գումարը ([5.7] տող - [5.8] տող - [5.6] տող, եթե այն < 0-ից):",
                ],
                section: '5'
            },
            {
                index: 15,
                title: "Հաշվարկի (հայտարարագրի) 6-րդ բաժնում լրացվում են ֆիզիկական անձի ինքնազբաղվածությունից ստացված եկամուտներից սոցիալական վճարի հաշվարկը:",
                section: '6'
            },
            {
                index: 16,
                title: "[6.1] տողում լրացվում է ֆիզիկական անձի՝ որպես ինքնազբաղված անձ ստացված եկամտի գումարը, [6.2] տողում լրացվում է ինքնազբաղվածությունից ստացված եկամտի գծով սոցիալական վճարի դրույքաչափը (5%) և [6.3] տողում լրացվում է ֆիզիկական անձի՝ որպես ինքնազբաղված անձ ստացված եկամտից հաշվարկված սոցիալական վճարի գումարը ([6.1] x [6.2]):",
                section: '6'
            },
            {
                index: 17,
                title: "Հայտարարատու հանդիսացող ֆիզիկական անձի կողմից Հաշվարկի (հայտարարագրի) 1-ին բաժնի 1-ին գլխում ինքնաշխատ լրացված տվյալների վերաբերյալ այլ տեղեկատվության առկայության դեպքում, ֆիզիկական անձը կատարում է համապատասխան նշում և Հաշվարկի (հայտարարագրի) 7-րդ բաժնի 1-ին գլխի համապատասխան տողում լրացնում է տեղեկատվությունը։",
                section: '1_1'
            },
            {
                index: 18,
                title: "Հայտարարատու հանդիսացող ֆիզիկական անձի կողմից Հաշվարկի (հայտարարագրի) 1-ին բաժնի 2-րդ գլխում լրացված տվյալների վերաբերյալ այլ տեղեկատվության առկայության դեպքում ֆիզիկական անձը կատարում է համապատասխան նշում և Հաշվարկի (հայտարարագրի) 7-րդ բաժնի 2-րդ գլխի համապատասխան տողում լրացնում է տեղեկատվությունը։",
                section: '1_2'
            }
        ]
    },
    ru: {
        title: 'Порядок заполнения исчисление (декларация) подоходного налога и социального взноса',
        rows: [
            {
                index: 1,
                title: "В первой части исчисления (декларации) заполняются идентификационные данные физического лица. При этом:",
                rows: [
                    "в пункте 1 заполняются фамилия, имя, отчество. При заполнении пункта 2 исчисления (декларации) пункт 1 заполняется автоматически;",
                    "в пункте 2 заполняется номерной знак общественных услуг физического лица либо номер справки об отсутствии номерного знака общественных услуг (номер карты социального обеспечения либо номер справки об отсутствии карты социального обеспечения);",
                    "в пункте 3 заполняется учетный номер налогоплательщика (УНН) физического лица;",
                    "в пункте 4 заполняется код участия физического лица в накопительной пенсионной системе (код 1 — если физическое лицо не уплачивает социальный взнос, код 2 — если физическое лицо уплачивает социальный взнос, код 3 — если физическое лицо уплачивает социальный взнос на основании заявления);",
                    "в пункте 5 заполняется адрес места жительства (учета) физического лица;",
                    "в пункте 6 заполняется номер телефона физического лица (при наличии).",
                ],
                section: 'personal'
            },
            {
                index: 2,
                title: "В разделе 1 исчисления (декларации) из информационной системы персонифицированного учета автоматически заполняются доходы, получаемые (полученные) от налогового агента в течение отчетного года, доходы, получаемые (полученные) в рамках трудового договора, гражданско-правового договора о предоставлении услуг (выполнении работ) от работодателя, освобожденного от обязанностей налогового агента, подоходный налог по части этих доходов.",
                section: '1'
            },
            {
                index: 3,
                title: "В главе 1 части 1 исчисления (декларации) автоматически заполняются доходы, получаемые (полученные) в рамках трудового договора, гражданско-правового договора о предоставлении услуг (выполнении работ) от налогового агента или работодателя, освобожденного от обязанностей налогового агента, пособия по временной нетрудоспособности и по материнству, прочие доходы и подоходный налог — по налоговым агентам (работодателям). При этом:",
                rows: [
                    "в графе [1.1.1] заполняется учетный номер налогоплательщика (УНН) налогового агента (работодателя);",
                    "в графе [1.1.2] заполняется наименование налогового агента (работодателя);",
                    "в строке [1.1.5] заполняется заработная плата и приравненные к ней прочие выплаты;",
                    "в строке [1.1.6] заполняется оплата за работу (услуги) по гражданско-правовому договору;",
                    "в строке [1.1.7] заполняются пособия по временной нетрудоспособности, по материнству;",
                    "в строке [1.1.8] заполняются полученные работником прочие доходы;",
                    "в строке [1.1.9] заполняется сумма уменьшения налоговой базы работника (сумма добровольных пенсионных выплат, не более, чем (строка [1.1.5] + строка [1.1.6] + строка [1.1.7] + строка [1.1.8]) - (строка [1.1.5] + строка [1.1.6] + строка [1.1.7] + строка [1.1.8]) / 1,05));",
                    "в графе [1.1.3] строки [1.1.10] заполняется получаемый (полученный) от каждого налогового агента (работодателя) налогооблагаемый доход, а в графе [1.1.4] той же строки заполняется сумма подоходного налога по части этого налогооблагаемого дохода;",
                    "в строке [1.1.11] заполняется общая сумма подоходного налога по части налогооблагаемого дохода, полученного (получаемого) от налоговых агентов (работодателей).",
                ],
                section: '1_1'
            },
            {
                index: 4,
                title: "В главу 2 раздела 1 исчисления (декларации) включаются полученные физическим лицом прочие доходы и подоходный налог по налоговым агентам. При этом:",
                rows: [
                    "в графе [1.2.1] заполняется учетный номер налогоплательщика (УНН) налогового агента;",
                    "в графе [1.2.2] заполняется наименование налогового агента;",
                    {
                        title: "в графе [1.2.3] заполняется размер доходов, полученных от данного налогового агента, по видам доходов:",
                        rows: [
                            "а. роялти (строка [1.2.5]),",
                            "б. проценты (строка [1.2.6]),",
                            "в. арендная плата (строка [1.2.7]),",
                            "г. дивиденд (строка [1.2.8]),",
                            "д. получаемые в порядке дарения имущество и (или) денежные средства (строка [1.2.9]),",
                            "е. доход от отчуждения имущества (за исключением указанного в пункте 7 настоящей главы) (строка [1.2.10]),",
                            "ж. доход от отчуждения застройщиком здания, его квартир или других помещений (строка [1.2.11]),",
                            "и. денежные и материальные выигрыши участников рекламных лотерей, осуществляемых в порядке и на условиях, установленных законодательством Республики Армения, в размере, не превышающем 50 тысяч драмов при каждом выигрыше (строка [1.2.12]),",
                            "к. получаемые на конкурсах денежные призы в размере, превышающем 50 тысяч драмов, при каждом призе (строка [1.2.13]),",
                            "л. осуществляемые налоговыми агентами для физических лиц страховые взносы (строка [1.2.14]),",
                            "м. пенсии, получаемые в рамках участия в добровольной пенсионной составляющей (строка [1.2.15]),",
                            "н. прочие доходы, не указанные в пунктах 1-11 главы 2 раздела 1 (строка [1.2.16]);",
                        ]
                    },
                    "в графе [1.2.4] заполняются суммы подоходного налога по части доходов, указанных в графе [1.2.3];",
                    "в строке [1.2.17] (графа [1.2.17]) заполняется общая сумма сумм подоходного налога по части доходов, заполненных в строках [1.2.5] - [1.2.16].",
                ],
                section: '1_2'
            },
            {
                index: 5,
                title: "В разделе 2 исчисления (декларации) заполняются установленные частью 1 статьи 147 Налогового кодекса Республики Армения полученные физическим лицом в течение данного налогового года уменьшаемые (не облагаемые налогом) доходы. При наличии данных относительно заполняемых в данном разделе доходов в информационной базе налогового органа, эти доходы заполняются автоматически.",
                section: '2'
            },
            {
                index: 6,
                title: "В разделе 2 исчисления (декларации):",
                rows: [
                    "в строке [2.1] заполняются суммы пособий, получаемых физическими лицами согласно законодательству Республики Армения, за исключением установленных законом Республики Армения \"О пособиях по временной нетрудоспособности и пособиях по материнству\" сумм пособий по временной нетрудоспособности и пособий по материнству наемных работников и физических лиц, самостоятельно обеспечивающих себя работой;",
                    "в строке [2.2] заполняются все виды пенсий (в том числе, накопительные пенсии, получаемые в рамках накопительной составляющей по Закону Республики Армения \"О накопительных пенсиях\", пенсии, получаемые в рамках законодательства иных государств, и аналогичные платежи, получаемые в рамках системы пенсионного обеспечения иных государств), за исключением пенсий, получаемых в рамках участия в установленном порядке в добровольной пенсионной составляющей;",
                    "в строке [2.3] заполняются страховые возмещения, за исключением возмещений (в том числе пенсий), в установленном порядке получаемых за счет добровольных пенсионных взносов, осуществляемых физическим лицом в рамках добровольной пенсионной составляющей за себя и (или) осуществляемых третьим лицом (в том числе работодателем) для физического лица в порядке, установленном законодательством Республики Армения;",
                    "в строке [2.4] заполняются суммы, полученные посредством банков или кредитных организаций, которые банки или кредитные организации получили в качестве страхового возмещения, по части которого банк или кредитная организация являются бенефициарами в той части, в которой эти суммы, согласно договору страхования, банками или кредитными организациями предоставляются физическому лицу-заемщику или залогодателю, или направляются на погашение кредита физического лица;",
                    "в строке [2.5] заполняются дополнительные средства, выделяемые из государственного бюджета Республики Армения для (в пользу) физического лица в рамках накопительной пенсионной составляющей по закону Республики Армения \"О накопительных пенсиях\";",
                    "в строке [2.6] заполняются получаемые согласно законодательству Республики Армения в рамках системы социальной защиты почетные выплаты, денежная помощь и поддержка;",
                    "в строке [2.7] заполняются получаемые согласно законодательству Республики Армения алименты (средства для проживания);",
                    "в строке [2.8] заполняются доходы, получаемые за сдачу крови и грудного молока и иные виды донорства;",
                    "в строке [2.9] заполняются компенсационные выплаты, полученные работниками (волонтерами) в связи с выполнением работ в рамках трудовых договоров и договоров относительно волонтерской работы в пределах норм (размеров), установленных законодательством Республики Армения (в том числе, компенсационные выплаты дипломатических служащих), за исключением компенсационных выплат за неиспользованный отпуск при увольнении с работы;",
                    "в строке [2.10] заполняются получаемые согласно законодательству Республики Армения от физических лиц в порядке наследования и (или) дарения имущество и (или) денежные средства, за исключением установленного пунктом 1 части 1 статьи 145 Налогового кодекса Республики Армения имущества, получаемого в порядке дарения от физического лица, являющегося застройщиком многоквартирного (в том числе, многофункционального) здания, подразделенного здания;",
                    "в строке [2.11] заполняются активы, работы, услуги, безвозмездно полученные от некоммерческих организаций, на основании решений государственных и местных органов самоуправления Республики Армения, созданных по закону постоянно действующих органов, а также от иностранных государств и международных, межгосударственных (межправительственных) организаций, выплаты, произведенные для (в пользу) физических лиц отмеченными в настоящем пункте органами и межгосударственными (межправительственными) организациями, доходы, помощь, безвозмездно полученные от других лиц;",
                    {
                        title: "в строке [2.12] заполняются полученные физическим лицом от отчуждения имущества уменьшаемые доходы. В частности, в настоящем пункте заполняются:",
                        rows: [
                            "а. доходы, вследствие отчуждения имущества получаемые от других физических лиц, не являющихся индивидуальными предпринимателями и нотариусами, за исключением установленных пунктом 1 части 1 статьи 145 Налогового кодекса Республики Армения доходов, получаемых от отчуждения здания, его квартир, строений (в том числе, незавершенных, недостроенных) или иных помещений (без нежилых помещений, являющихся общей долевой собственностью) в случае их отчуждения физическим лицом, считающимся застройщиком многоквартирного (в том числе многофункционального) здания, подразделенного здания,",
                            "б. суммы, выплачиваемые физическим лицам за изъятие недвижимого имущества, принадлежащего этим физическим лицам, для нужд государства или общин в порядке, установленном Законом Республики Армения \"Об отчуждении собственности в целях обеспечения высших общественных интересов\", а также суммы, выплачиваемые физическим лицам, поставленным на учет (прописанным) в этом недвижимом имуществе,",
                            "в. доходы, получаемые от отчуждения земельного участка (независимо от целевого назначения земельного участка),",
                            "г. доходы, получаемые за золото и драгоценные камни, отчуждаемые налоговому агенту, осуществляющему деятельность по купле-продаже драгоценных металлов, изделий, изготовленных из драгоценных металлов, или драгоценных камней в местах осуществления торговли (на рынках золота) через прилавки или торговые точки,",
                            "д. получаемые от организации, имеющей лицензию на осуществление трейд-ин торговли автотранспортным средством (в том числе — сельскохозяйственной техникой), и считающейся плательщиком НДС, доходы от отчуждения принадлежащего ей на праве собственности автотранспортного средства (в том числе — сельскохозяйственной техники),",
                            "e. доходы, получаемые от банков и кредитных организаций, вследствие отчуждения принадлежащего ему на праве собственности имущества, если в дальнейшем это имущество тем же банком или кредитной организацией предоставляется по лизингу (разновидностями) индивидуальным предпринимателям и физическим лицам, не являющимся нотариусом;",
                        ]
                    },
                    "в строке [2.13] заполняются получаемые от государства стипендии студентов, аспирантов высших учебных заведений, учащихся средних специальных и профессионально-технических учебных заведений, слушателей духовных учебных заведений, а также стипендии, назначаемые для них этими учебными заведениями или некоммерческими организациями, или иностранными государствами, международными, межгосударственными (межправительственными) организациями;",
                    "в строке [2.14] заполняются суммы, получаемые в порядке, установленном законом, в качестве возмещения причиненного ущерба, за исключением сумм возмещения упущенного дохода;",
                    "в строке [2.15] заполняются суммы получаемых кредитов и займов, за исключением случаев уступки кредитором сумм кредита или займа, или случаев соглашения с кредитором о невозврате этих сумм каким-либо иным способом (в том числе в момент истечения установленного законом срока исковой давности);",
                    "в строке [2.16] заполняются денежные и материальные выигрыши участников рекламных лотерей, осуществляемых в порядке и на условиях, установленных законодательством Республики Армения, в размере, не превышающем 50 тысяч драмов при каждом выигрыше, денежные и материальные выигрыши участников лотерей с розыгрышем, без розыгрыша и комбинированными розыгрышами, а также тотализаторов или выигрыши, полученные в казино, в играх с выигрышем и (или) в играх с интернет выигрышем;",
                    "в строке [2.17] заполняются стоимость получаемых на конкурсах денежных и материальных призов в размере, не превышающем 50 тысяч драмов при каждом призе;",
                    "в строке [2.18] заполняются суммы, возмещаемые из Фонда гарантирования возмещения вкладов в порядке, установленном законом Республики Армения \"О гарантировании возмещения банковских вкладов физических лиц\", за исключением начисляемых на суммы и возмещаемых процентов;",
                    "в строке [2.19] заполняются суммы, возмещаемые по денежным вкладам, внесенным в Республиканский банк АрмССР Сбербанка СССР до 10 июня 1993 года;",
                    "в строке [2.20] заполняются пени, начисляемые в пользу физического лица за каждый день просрочки, следующий за сроком просрочки, в случае задержки возврата имеющихся на едином счете сумм более чем на 90 дней со срока, установленного статьей 327 Кодекса;",
                    "в строке [2.21] заполняются доходы, получаемые за деятельность по осуществлению перевозок на одном легковом автомобиле-такси физическими лицами, установленными в абзаце втором части 1 статьи 17 закона Республики Армения \"Об автомобильном транспорте\";",
                    "в строке [2.22] заполняются суммы возмещения, получаемые от использующего восстанавливаемые энергетические ресурсы автономного энергопроизводителя-лица, имеющего лицензию на распределение электрической энергии, а также возмещения, получаемые в виде электрической энергии за электрическую энергию, поставленную в случае равномерного перетока автономным энергопроизводителем лицу, имеющему лицензию на распределение электрической энергии;",
                    "в строке [2.23] заполняются доходы, получаемые физическими лицами-производителями сельскохозяйственной продукции от поставки (реализации) сельскохозяйственной продукции;",
                    "в строке [2.24] заполняются получаемые от акций уменьшаемые доходы — согласно статье 149 Налогового кодекса Республики Армения;",
                    "в строке [2.25] заполняются суммы компенсации, выплачиваемые физическим лицам на основании Закона Республики Армения \"О возмещении вреда, причиненного жизни или здоровью военнослужащих при защите Республики Армения\";",
                    "в строке [2.26] заполняются установленные частью 1 статьи 147 Кодекса Республики Армения прочие уменьшаемые доходы, не указанные в строках [2.1]- [2.25] настоящего раздела;",
                    "в строке [2.27] заполняется общая сумма уменьшаемых доходов, полученных физическими лицами в течение отчетного налогового года.",
                ],
                section: '2'
            },
            {
                index: 7,
                title: "В разделе 3 исчисления (декларации) заполняются доходы, получаемые (полученные) в течение отчетного года от лиц, не являющихся налоговыми агентами, (за исключением строки [3.11]), и исчисление подоходного налога по части этих доходов.",
                section: '3'
            },
            {
                index: 8,
                title: "В графе [3.1] исчисления (декларации) заполняются:",
                rows: [
                    "доходы, в рамках трудового договора получаемые (полученные) от лиц, не являющихся налоговыми агентами (строка [3.4]);",
                    "доход за исполнение работ (предоставление услуг) по гражданско-правовому договору, полученный от лиц, не являющихся налоговыми агентами (строка [3.5]);",
                    "уменьшаемая сумма добровольных пенсионных выплат, уменьшаемых из базы налогообложения ((строка [3.4] + строка [3.5]) - ([3.4] + [3.5]) / 1,05) (строка[3.6]);",
                    "налогооблагаемый доход, получаемый (полученный) в рамках трудового договора и гражданско-правового договора о предоставлении услуг (выполнении работ) от лиц, не являющихся налоговыми агентами (строка [3.4] + строка [3.5]) + строка [3.6]) (строка [3.7]);",
                    "роялти (строка [3.8]);",
                    "процент (строка [3.9]);",
                    "арендная плата (строка [3.10]);",
                    "сумма, превышающая 60 млн драмов общей суммы арендных плат, полученных за отчетный год (в том числе и от налоговых агентов) (строка [3.11]);",
                    "дивиденд (строка [3.12]);",
                    "доход за отчуждение имущества (за исключением указанного в подпункте 11 пункта 1) (строка [3.13]);",
                    "доход от отчуждения застройщиком здания, его квартир или других помещений (строка [3.14]);",
                    "доходы от отчуждения участия (акции, доли, пая) в уставном или складочном капитале организации в форме здания, квартиры, особняка или иного строения (в том числе незавершенного (недостроенного)) или иных ценных бумаг, свидетельствующих об инвестиции, если это отчуждение совершается в течение налогового года, включающего день приобретения этого участия (акции, доли, пая) в уставном или складочном капитале, или следующих за ним трех налоговых лет (строка [3.15]);",
                    "размер, превышающий 50 тысяч драмов при каждом призе из получаемых на конкурсах денежных и материальных призов (строка [3.16]);",
                    "получаемое в дар имущество (в том числе — денежные средства) (строка [3.17]);",
                    "пассивные доходы, не выплаченные за 12-месячный период, следующий за налоговым годом приобретения права на получение доходов (строка [3.18]);",
                    "получаемые (полученные) прочие доходы (строка [3.19]).",
                ],
                section: '3'
            },
            {
                index: 9,
                title: "В графе [3.2] заполняются ставки подоходного налога и в графе [3.3] заполняются суммы подоходного налога, исчисленные для доходов, указанных в графе [3.1].",
                section: '3'
            },
            {
                index: 10,
                title: "В строке [3.20] автоматически заполняется сумма подоходного налога, исчисленная за отчетный налоговый год, которая определяется в размере общей суммы сумм подоходного налога, исчисленных по части доходов, включенных в графу [3.1].",
                section: '3'
            },
            {
                index: 11,
                title: "В строках [3.21] и [3.22] заполняется удержанный (взысканный) в других государствах согласно их законодательству налог (на основании справки, заверенной налоговым органом иностранного государства) и сумма налога, в размере которой годовой подоходный налог подлежит уменьшению — согласно законодательству Республики Армения.",
                section: '3'
            },
            {
                index: 12,
                title: "В строке [3.23] заполняется подоходный налог, подлежащий уплате в государственный бюджет (строка [3.23] = строка [3.20]+строка [3.22]).",
                section: '3'
            },
            {
                index: 13,
                title: "В разделе 4 исчисления (декларации) автоматически заполняются осуществленные физическим лицом за отчетный год за себя и за членов своей семьи и обоснованные по налоговым счетам или счетам-фактурам социальные расходы, а также не превышающие максимальные размеры этих расходов суммы, подлежащие возмещению. При этом в Исчислении (декларации):",
                rows: [
                    "в графе [4.1] (строки [4.3] и [4.4]) заполняются суммы социальных расходов, осуществленных физическим лицом за отчетный год, по отдельным направлениям. В частности, социальными расходами по линии здравоохранения считаются расходы на приобретение пакета страхования здоровья, а социальными расходами по линии образования считаются платежи за обучение, осуществленные в Республике Армения по части дошкольной, начальной (общее, специализированное, специальное), основной (общее, специализированное, специальное), средней (общее, специализированное, специальное), начальной профессиональной (ремесленное), средней профессиональной, высшей профессиональной и послевузовской профессиональной образовательных программ;",
                    "в графе [4.2] (строки [4.3] и [4.4]) заполняются подлежащие возмещению суммы социальных расходов, которые не могут превышать установленные Правительством максимальные размеры;",
                    "в графе [4.2] строки [4.5] заполняется общая сумма подлежащих возмещению сумм социальных расходов по всем направлениям, осуществленных физическим лицом для себя и членов его семьи, которая не может превышать установленный Правительством максимальный размер.",
                ],
                section: '4'
            },
            {
                index: 14,
                title: "В разделе 5 исчисления (декларации) автоматически заполняется исчисление подоходного налога, подлежащего уплате или возмещению (возврату) физическим лицам в течение отчетного года. При этом, в настоящем разделе:",
                rows: [
                    "в строке [5.2] заполняются суммы подоходного налога физического лица, исчисленные и выплаченные из доходов, получаемых (полученных) от налогового агента (работодателя) (строка [1.1.11] + строка [1.2.17]);",
                    "в строке [5.3] заполняются возвращенные суммы подоходного налога, выплаченные и исчисленные по части заработной платы и приравненных к ней выплат физического лица с целью возмещения процентов, уплаченных за обслуживание ипотечного кредита физического лица, полученного с целью приобретения квартиры или индивидуального жилого дома или строительства индивидуального жилого дома;",
                    "в строке [5.4] заполняется возмещенная (возвращенная из подоходного налога) сумма платы за обучение наемного работника, учащегося (студента) по очной форме в магистратуре, аспирантуре и ординатуре по утвержденным Правительством специальностям в аккредитованных в Республике Армения высших учебных заведениях;",
                    "в строке [5.5] заполняется возмещенная (возвращенная из подоходного налога) сумма по части инвестированного в уставной или складочный капитал дивиденда физического лица;",
                    "в строке [5.6] заполняется подлежащая уменьшению сумма подоходного налога физического лица, исчисленная и выплаченная из доходов, получаемых (полученных) от налогового агента (работодателя) ([5.2] - [5.3] - [5.4] - [5.5]);",
                    "в строке [5.7] заполняется общая сумма подлежащей уменьшению суммы исчисленного и подлежащего уплате в государственный бюджет подоходного налога и удержанного налоговым агентом подоходного налога (строка [3.23] + строка [5.6]);",
                    "в строке [5.8] заполняется сумма возмещения осуществленных физическим лицом расходов, с учетом установленного Правительством максимального размера (графа [4.2] строки [4.5] раздела 4);",
                    "в строке [5.9] заполняется подлежащая уплате в государственный бюджет сумма подоходного налога (строка [5.7] - строка [5.8] - строка [5.6], если она > 0);",
                    "в строке [5.10] заполняется подлежащая возмещению из государственного бюджета сумма подоходного налога (строка [5.7] - строка [5.8] - строка [5.6], если она < 0).",
                ],
                section: '5'
            },
            {
                index: 15,
                title: "В разделе 6 исчисления (декларации) заполняется исчисление социального взноса от доходов, полученных от самозанятости физического лица.",
                section: '6'
            },
            {
                index: 16,
                title: "В строке [6.1] заполняется полученная в качестве самозанятого лица сумма дохода физического лица, в строке [6.2] заполняется ставка социального взноса по части дохода, полученного от самозанятости (5%), и в строке [6.3] заполняется сумма социального взноса физического лица от дохода, полученного в качестве самозанятого лица ([6.1] x [6.2]).",
                section: '6'
            },
            {
                index: 17,
                title: "При наличии прочей информации относительно автоматически заполненных являющимся декларантом физическим лицом данных в главе 1 раздела 1 Исчисления (декларации), физическое лицо производит соответствующую отметку и заполняет информацию в соответствующей строке главы 1 раздела 7 Исчисления (декларации).",
                section: '1_1'
            },
            {
                index: 18,
                title: "При наличии прочей информации относительно автоматически заполненных являющимся декларантом физическим лицом данных в главе 2 раздела 1 Исчисления (декларации), физическое лицо производит соответствующую отметку и заполняет информацию в соответствующей строке главы 2 раздела 7 Исчисления (декларации).",
                section: '1_2'
            }
        ]
    },
    en: {
        title: 'Procedure of filling calculation report (declaration) of income tax and social contribution',
        rows: [
            {
                index: 1,
                title: "The identification data of the natural person are completed in the first section of Calculation report (Declaration). Moreover:",
                rows: [
                    "the surname, name and father's name of the natural person are completed in point 1. Where point 2 of the Calculation report (Declaration) is completed, point 1 is completed automatically;",
                    "the natural person's social services number or number of the statement of information on not having a social services number (social security card number or number of the statement of information on not having a social security card number) shall be completed in point 2;",
                    "the taxpayer identification number (TIN) of the natural person shall be completed in point 3;",
                    "the participation code of the natural person in funded pension system shall be completed in point 4 (code 1, where the natural person is not a person paying a social contribution, code 2, where the natural person is a person paying a social contribution, code 3, where the natural person is a person paying a social contribution on the basis of an application);",
                    "the address of the place of residence (record-registration) of the natural person shall be completed in point 5;",
                    "the telephone number of the natural person (where available) shall be completed in point 6.",
                ],
                section: 'personal'
            },
            {
                index: 2,
                title: "Income being received (received) from the tax agent during the reporting year, income being received (received) from the employer exempted from the duties of a tax agent within the framework of an employment contract, civil law contracts on provision of services (performance of works), income tax from that income shall be automatically completed in Section 1 of the Calculation report (Declaration) from the personalised record-keeping information system.",
                section: '1'
            },
            {
                index: 3,
                title: "Income being received (received) from the tax agent or an employer exempted from the duties of a tax agent within the framework of an employment contracts, civil law contracts on provision of services (performance of works), temporary incapacity and maternity benefits, other income and income tax, according to tax agents (employers) shall be completed automatically in Chapter 1 of Section 1 of the Calculation report (Declaration) . Moreover,",
                rows: [
                    "the taxpayer identification number (TIN) of the tax agent (employer) shall be completed in column [1.1.1];",
                    "the name of the tax agent (employer) shall be completed in column [1.1.2] ;",
                    "salary and other equivalent payments shall be completed in line [1.1.5];",
                    "remuneration for a work (service) under the civil law contract shall be completed in line [1.1.6];",
                    "temporary incapacity and maternity benefits shall be completed in line [1.1.7];",
                    "other income received by employee shall be completed in line [1.1.8];",
                    "the employee's tax base reduction amount shall be completed in line [1.1.9] (amount of voluntary pension contributions in an amount not more than (line [1.1.5] + line [1.1.6] + line [1.1.7] + line [1.1.8]) - (line [1.1.5] + line [1.1.6] + line [1.1.7] + line [1.1.8]) / 1,05));",
                    "The taxable income received from each tax agent (employer) shall be completed in column [1.1.3] of line [1.1.10], and the amount of income tax from the taxable income shall be completed in column [1.1.4] of the same line;",
                    "the sum total of income tax from taxable income received (being received) from tax agents (employers) shall be completed in line [1.1.11]. ",
                ],
                section: '1_1'
            },
            {
                index: 4,
                title: "Other income received from a natural person and income tax, according to tax agents shall be completed in Chapter 2 of Section 1 of the Calculation report (Declaration). Moreover,",
                rows: [
                    "the taxpayer identification number (TIN) of the tax agent shall be completed in column [1.2.1];",
                    "the name of the tax agent shall be completed in column [1.2.2];",
                    {
                        title: "the amount of incomes received from the given tax agent, according to types of income, shall be completed in column [1.2.3]:  ",
                        rows: [
                            "a. royalty (line [1.2.5]);",
                            "b. interest (line [1.2.6]);",
                            "c. rental (line [1.2.7]);",
                            "d. dividend (line [1.2.8]);",
                            "e. property and/or funds being received as a gift (line [1.2.9]);  ",
                            "f. Income from the alienation of property (except for the property specified in point 7 of this Chapter) (line [1.2.10]);",
                            "g. Income received from the alienation of a building, its apartments or other premises by a constructor(line [1.2.11]);",
                            "h. the monetary and material prizes of the participants of trade promotion lotteries carried out in the manner and conditions prescribed by the legislation of the Republic of Armenia, in an amount exceeding AMD 50 000 in case of each winning (line [1.2.12]);",
                            "i. the monetary prizes received in competitions in an amount exceeding AMD 50 000 in case of each prize (line [1.2.13]);",
                            "j. insurance premiums paid for natural persons by tax agents (line [1.2.14]);",
                            "k. pension for contributing to the voluntary pension component (line [1.2.15]);",
                            "l. other incomes not specified in points 1-11 of Chapter 2 of Section 1 (line [1.2.16]);",
                        ]
                    },
                    "the amounts of income tax from incomes specified in column [1.2.3] shall be completed in column [1.2.4];",
                    "the sum total of income tax amounts from incomes completed in lines [1.2.5]-[1.2.16] shall be completed in line [1.2.17] (column [1.2.4]).",
                ],
                section: '1_2'
            },
            {
                index: 5,
                title: "The deducted (non-taxable) income received by a natural person during the given tax year as prescribed by part 1 of Article 147 of the Tax Code of the Republic of Armenia shall be completed in Section 2 of Calculation report (Declaration). Where data are available in the information base of the tax authority on income being completed in this Section, that income is completed automatically.",
                section: '2'
            },
            {
                index: 6,
                title: "In Section 2 of calculation report (declaration):",
                rows: [
                    "the amounts of the benefits received by natural persons according to the legislation of the Republic of Armenia, except for the amounts of temporary incapacity benefits and of maternity benefits of hired employees and self-employed natural persons prescribed by the Law of the Republic of Armenia “On temporary incapacity and maternity benefits” shall be completed in line (1) [2.1];",
                    "all types of pensions shall be completed in line [2.2] (including the funded pensions received within the framework of the funded component according to the Law of the Republic of Armenia “On funded pensions”, pensions received under the legislation of other states and similar fees received within the scope of pension system of other states), except for the pensions received in the prescribed manner within the framework of the contribution to the voluntary pension component;",
                    "insurance compensations shall be completed in line [2.3], except for the compensations (including pensions) received in the prescribed manner at the expense of the voluntary pension contributions made within the framework of the voluntary pension component according to the legislation of the Republic of Armenia — by natural persons for themselves and/or those made by a third party (including employer) for a natural person;",
                    "amounts received through banks or credit organisations shall be completed in line [2.4], which the banks or credit organisations have received as an insurance compensation, with regard whereof the bank or the credit organisation is a beneficiary to the extent that those amounts are — upon the insurance contract — provided by banks or credit organisations to the natural person who is a borrower or a pledger or are directed to the repayment of the credit of the natural person who is a borrower;",
                    "additional funds allocated from the State Budget of the Republic of Armenia for (in favour of) a natural person according to the Law of the Republic of Armenia \"On funded pensions\" within the framework of the funded pension component shall be completed in line [2.5];",
                    "honorariums, financial support and assistance received according to the legislation of the Republic Armenia within the framework of the social protection system shall be completed in line [2.6];",
                    "alimonies (maintenance fees) received according to the legislation of the Republic of Armenia shall be completed in line [2.7] ;",
                    "incomes received for donated blood, breast milk and other types of donations shall be completed in line [2.8];",
                    "compensation fees (including compensation fees of diplomatic officers) received by employees (volunteers) related to the performance of works within the framework of employment contracts and contracts on volunteer work according to the standards (limits) prescribed by the legislation of the Republic of Armenia shall be completed in line [2.9], except for the compensation fees for the unused leave in case of dismissal from work;",
                    "the property and/or funds received from natural persons as a succession and/or as a gift according to the legislation of the Republic of Armenia shall be completed in line [2.10], except for the property prescribed by point 1 of part 1 of Article 145 of the Tax Code of the Republic of Armenia received as a gift from a natural person considered a constructor of a multi-apartment building (including multifunctional), subdivided building;  ",
                    "assets, works and services received from non-commercial organisations, the assets, works and services received based on the decisions of state and local self-government bodies of the Republic of Armenia, of bodies established by law and operating on a permanent basis, as well as those received from foreign states and international, interstate (intergovernmental) organisations without compensation, the fees made by the bodies and international, interstate (intergovernmental) organisations, referred to in this point, for (in favour of) natural persons, incomes, support received from other persons without compensation shall be completed in line [2.11];  ",
                    {
                        title: "Deducted income received from the alienation of property by the natural person shall be completed in line [2.12]. Particularly, the following shall be completed in this point:",
                        rows: [
                            "а. incomes received from other natural persons not deemed as individual entrepreneurs and notaries due to the alienation of the property, except for the income received from alienation of a building, the apartments and constructions (including unfinished and semi-constructed) thereof or other premises (without non-residential premises considered common shared ownership) by a natural person being considered (considered) a constructor of a multi-apartment (including multifunctional) building, subdivided building as prescribed by point 1 of part 1 of Article 145 of Tax Code of the Republic of Armenia;  ",
                            "b. amounts paid to natural persons for taking the immovable property belonging to them as prescribed by the law of the Republic of Armenia \"On alienation of property for ensuring overriding public interests\" for state or community needs, as well as to natural persons record-registered (registered) in that immovable property;",
                            "c. incomes derived from alienation of land (irrespective of the designated purpose of the land);",
                            "d. incomes derived in respect of the gold and precious stones to be alienated to the tax agent carrying out activities of purchase and sale of precious metals, items made from precious metals or precious stones through stalls or points of sale in trading venues (gold markets);",
                            "e. incomes received from an organisation considered a VAT payer and having a licence for carrying out trade-in for purchase of a vehicle (including agricultural machinery) from alienation of a vehicle (including agricultural machinery) belonging thereto by the right of ownership;",
                            "f. incomes received from banks and credit organisations due to alienation of the property belonging thereto by the right of ownership, where the given property is further provided for leasing (types thereof) to natural persons who are not individual entrepreneurs and notaries by the same bank or credit organisation.",
                        ]
                    },
                    "scholarships received from the State by the students of higher education institutions, Ph.D. students, students of specialised secondary and vocational educational institutions, attendants of religious educational institutions, as well as the scholarships granted thereto by educational institutions or non-commercial organisations or foreign states, international, interstate (intergovernmental) organisations shall be completed in line [2.13].",
                    "the amounts, which are received as a compensation for the caused damage as prescribed by law, shall be completed in line [2.14], except for the compensations for lost income;",
                    "the amounts of received loans and borrowings shall be completed in line [2.15], except for cases of assigning the amounts of a loan or a borrowing by the debtor or agreeing with the creditor on the non-refund of those amounts in any other form (including at the moment of the expiration of the term for the statute of limitation prescribed by law);",
                    "monetary and material prizes of the participants of trade promotion lotteries carried out in the manner and conditions prescribed by the legislation of the Republic of Armenia, in an amount not exceeding AMD 50,000 in case of each winning, monetary and material prizes of the participants of drawing, instant or combined lottery, as well as betting or winnings in casinos, games of chance and/or on-line games of chance shall be completed in line [2.16];",
                    "the amount of monetary and material prizes received in competitions in an amount not exceeding AMD 50,000 in case of each prize shall be completed in line [2.17];",
                    "the amounts compensated by the Deposit Guarantee Fund as prescribed by the Law of the Republic of Armenia \"On guaranteeing compensation of bank deposits of natural persons\" shall be completed in line [2.18], except for the amount accrued on the deposit and the reimbursed interests;",
                    "compensations for the monetary deposits made in the ASSR republican bank of the USSR State Savings Bank until 10 June 1993 shall be completed in line [2.19];",
                    "penalties accrued in favour of a natural person for each delayed day following the term prescribed by Article 327 of the Tax Code of the Republic of Armenia in case of delaying the return of the amounts on the unified account for more than 30 days, shall be completed in line [2.20].",
                    "incomes received from the activity of carrying out transportations with one passenger taxi motor vehicle by the natural persons prescribed by paragraph two of part 1 of Article 17 of the Law of the Republic of Armenia \"On motor vehicle transport\" shall be completed in line [2.21];",
                    "the reimbursement amounts received from persons having an electrical power distribution licence of an autonomous energy producer using renewable energy resources, as well as the compensations received in the form of electrical power for the electrical power supplied to the electrical power distribution licensee by an autonomous energy producer in case of equal mutual flows, shall be completed in line [2.22].",
                    "incomes received from supply (sale) of agricultural products of natural persons that produce agricultural products shall be completed in line [2.23].",
                    "deducted income received from securities in accordance with Article 149 of the Tax Code of the Republic of Armenia shall be completed in line [2.24].",
                    "compensation amounts paid to natural persons on the basis of the law of the Republic of Armenia \"On compensation for the damages caused to life or health of the servicemen while defending the Republic of Armenia\" shall be completed in line [2.25].",
                    "other deducted incomes defined by part 1 of Article 147 of the Code of the Republic of Armenia and not specified in lines [2.1]-[2.25] of this Section shall be completed in line [2.26].",
                    "the sum total of deducted incomes received from natural persons during the reporting tax year shall be completed in line [2.27]. ",
                ],
                section: '2'
            },
            {
                index: 7,
                title: "Incomes being received (received) from persons not deemed as tax agents (except for line [3.11]) during the reporting year and the calculation report of income tax generated from those incomes shall be completed in Section 3 of the Calculation report (Declaration).",
                section: '3'
            },
            {
                index: 8,
                title: "In column [3.1] of the Calculation report (Declaration) the following shall be completed:",
                rows: [
                    "income being received (received) from persons not deemed as tax agents within the scope of employment contract (line [3.4]);",
                    "income received from persons not deemed as tax agents for performance of work (provision of service) under civil law contract (line [3.5]); ",
                    "deducted amount of voluntary pension contributions deducted from tax base ((line [3.4]+line [3.5])-([3.4]+[3.5])/ 1.05) (line [3.6]);",
                    "taxable income being received (received) from persons not deemed as tax agents within the framework of employment contracts and civil law contracts on provision of services (performance of works) (line [3.4] + line [3.5]) + line [3.6]) (line [3.7]);",
                    "royalty (line [3.8]);",
                    "interest (line [3.9]);",
                    "rental (line [3.10]);",
                    "amount exceeding 60 million drams of the sum total of rentals received during the tax year (including from tax agents) (line [3.11]);",
                    "dividends (line [3.12]);",
                    "income from the alienation of the property (except for the property specified in sub-point 11 of point 1(line [3.13]);",
                    "income received from the alienation of a building, its apartments or other premises by a constructor(line [3.14]);",
                    "incomes received from the alienation of the participation (stock, share, unit) in the authorised or share capital of the organisation through building, apartment, private house or other construction (including unfinished (semi-constructed)) or other securities proving investment, where that alienation is carried out within the tax year or within three tax years following the tax year that includes the day of acquiring that participation (stock, share, unit) in the authorised or share capital (line [3.15];",
                    "the value of monetary and material awards received in competitions in an amount not exceeding AMD 50,000 in case of each award (line [3.16];",
                    "property received as a gift (including monetary funds) (line [3.17]);",
                    "passive incomes not paid during the 12- month period following the tax year of acquisition of the right to receive the incomes (line [3.18]);",
                    "other incomes being received (received) (line [3.19]).",
                ],
                section: '3'
            },
            {
                index: 9,
                title: "Income tax rates are completed in column [3.2] and income tax amounts calculated for incomes mentioned in column [3.1] shall be completed in column [3.3].",
                section: '3'
            },
            {
                index: 10,
                title: "The amount of income tax calculated for the reporting tax year, which should be equal to the sum total of income tax amounts calculated for incomes included in column [3.1], shall be completed automatically in line [3.20].",
                section: '3'
            },
            {
                index: 11,
                title: "Tax withheld (levied) in other states in accordance with the legislation thereof (based on the statement of reference certified by the tax authority of a foreign state) and the amount of tax in the amount whereof the annual income tax shall be subject to deduction according to the legislation of the Republic of Armenia shall be completed in lines [3.21] and [3.22].",
                section: '3'
            },
            {
                index: 12,
                title: "Income tax to be paid to the State Budget (line[3.23]=line[3.20]+line[3.22]) shall be completed in line [3.23].",
                section: '3'
            },
            {
                index: 13,
                title: "The social expenses made by a natural person for him or her and his or her family members during the reporting year and substantiated by tax invoices or invoices, as well as amounts to be compensated not exceeding the maximum limits for expenses shall be completed automatically in Section 4 of Calculation report (Declaration). Moreover, in the Calculation report (Declaration):",
                rows: [
                    "the amounts of social expenses made by a natural person during the reporting year, according to separate sectors shall be completed in column [4.1] (lines [4.3] and [4.4]). In particular, social expenses with regard to healthcare shall be the expenses for acquiring health insurance package from insurance companies operating in the Republic of Armenia, and social expenses with regard to education shall be the tuition fees made with regard to pre-school (general, specialised special), basic (general, specialised, special), secondary (general, specialised, special), primary vocational (handicraft), secondary vocational, higher vocational and post-graduate vocational education programmes implemented in the Republic of Armenia;",
                    "social expense amounts to be compensated, which may not exceed the maximum limits prescribed by the Government shall be completed in column [4.2] (lines [4.3] and [4.4]);",
                    "the sum total of amounts of social expenses subject to compensation made by the natural person for him or her and his or her family members across all sectors, which should not exceed the maximum limit prescribed by the Government shall be completed in column [4.2] of line [4.5]."
                ],
                section: '4'
            },
            {
                index: 14,
                title: "The calculation of the income tax subject to payment or compensation (refund) to natural persons during the reporting tax year shall be automatically completed in Section 5 of the Calculation report (Declaration). Moreover, in this Section:",
                rows: [
                    "the income tax amounts calculated and paid from incomes of the natural person being received (received) from the tax agent (employer) shall be completed in line [5.2] (line [1.1.11] + line [1.2.17]);  ",
                    "refunded income tax amounts calculated and paid with regard to the salary and other equivalent payments of the natural person for the purpose of compensating interests paid for servicing a mortgage loan received by a natural person for acquiring a an apartment or an individual residential house or building an individual residential house shall be completed in line [5.3];",
                    "the compensation amount (the refunded income tax amount) of tuition fee of a hired worker enrolled in (student of) a masters course, PhD course and residency program at higher education institutions accredited in the Republic of Armenia, with specialisations approved by the Government, shall be completed in line [5.4];",
                    "the compensation amount (the refunded income tax amount) with regard to dividends invested in the authorised or share capital of the natural person shall be completed in line [5.5];",
                    "the income tax amount subject to deduction, calculated and paid from the incomes being received (received) from the tax agent (employer) ([5.2]-[5.3]-[5.4]-[5.5]) shall be completed in line [5.6];",
                    "the sum total of the income tax to be calculated and paid to State Budget and the income tax amount subject to deduction withheld by the tax agent ([3.23] line + [5.6] line) shall be completed in line [5.7];  ",
                    "the compensation amount of social expenses made by a natural person, taking into account the maximum limit prescribed by the Government (column [4.2] of line [4.5] of Section 4) shall be completed in line [5.8];",
                    "the income tax amount to be paid to State Budget ([5.7] line - [5.8] line - [5.6] line, if it is > 0) shall be completed in line [5.9];",
                    "the income tax amount to be compensated from State Budget ([5.7] line - [5.8] line - [5.6] line, if it is < 0) shall be completed in line [5.10].",
                ],
                section: '5'
            },
            {
                index: 15,
                title: "The calculated amount of social contribution from incomes received by a natural person from self-employment shall be completed in Section 6 of the Calculation report (Declaration).",
                section: '6'
            },
            {
                index: 16,
                title: "The amount of income received by a natural person acting as a self-employed person shall be completed in line [6.1], the (5%) rate of social contribution from the income received from self-employment shall be completed in line [6.2] and the amount of social contribution calculated from the income received by a natural person acting as a self-employed person ([6.1] x [6.2]) shall be completed in line [6.3].",
                section: '6'
            },
            {
                index: 17,
                title: "In the case of availability of other information regarding data completed automatically by a natural person, deemed as a declarant, in Chapter 1 of Section 1 of the Calculation Report (Declaration), the natural person shall make a corresponding note and complete the information in the relevant line of Chapter 1 of Section 7 of the Calculation Report (Declaration).",
                section: '1_1'
            },
            {
                index: 18,
                title: "In the case of availability of other information regarding data completed by a natural person, deemed as a declarant, in Chapter 2 of Section 1 of the Calculation Report (Declaration), the natural person shall make a corresponding note and complete the information in the relevant line of Chapter 2 of Section 7 of the Calculation Report (Declaration).",
                section: '1_2'
            }
        ]
    }
}