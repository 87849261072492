import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import { classnames, formatNumberWithCommas } from '../../utils';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { RootState } from '../../store';

interface ProcessingStatusDialogProps {
    isOpen: boolean;
    close: Function;
    data: any;
    className?: string;
};

const ProcesingStatusCardMoobile: React.FC<any> = (props) => {
    const { t, i18n } = useTranslation();

    const csStatus = classnames({
        [styles.Status]: true,
        [styles[props.processingStatus as string]]: !!props.processingStatus

    });

    return (
        <div className={styles.ProcesingStatusCardMoobile}>
            <div className={styles.Title}>
                <span>{t('DECL_SR_BANK_ACCOUNT_NUMBER')}</span>
                <span className={csStatus}>{props[`processingStatusName_${i18n.language}`]}</span>
            </div>
            <div className={styles.AccountNumber}>
                <span>{props.accountNumTarget}</span>
                <span>{props.dateMode}</span>
            </div>
            <div className={styles.Amount}>
                <span>{t('DECL_SR_BANK_ACCOUNT_RETURN_AMOUNT')}</span>
                <span>{props.sentAmount ? formatNumberWithCommas(props.sentAmount) : null}</span>
            </div>
            <div className={styles.Amount}>
                <span>{t('DECL_SR_BANK_ACCOUNT_RETURNED_AMOUNT')}</span>
                <span>{props.receviedAmount ? formatNumberWithCommas(props.receviedAmount) : null}</span>
            </div>
        </div>
    )
}

export const ProcessingStatusDialog: React.FC<ProcessingStatusDialogProps> = (props) => {
    const { t, i18n } = useTranslation();
    const utils = useSelector((state: RootState) => state.utils);

    if (!props.data?.length && props.isOpen) return null;
    const classes = classnames({[styles.container]: true, [props.className as string]: !!props.className });

    return (
        <Modal
            className={styles.ProcessingStatusDialog}
            title={t('PROCESSING_STATUS')}
            size={utils.device?.mode ===  "desktop" ? "md" : "s-fit-content"}
            isOpen={props.isOpen}
            onClose={props.close}
            mode="head-colored"
        >
            <div className={classes}>
                {props.data?.map?.((item: any) => (
                    <React.Fragment key={item.rowNum}>
                        {utils.device?.mode ===  "desktop" ? (
                            <div  className={styles.row}>
                                <div className={styles.rowItem}>
                                    <div className={styles.label}>{t('DECL_SR_BANK_ACCOUNT_NUMBER')}</div>
                                    <div className={styles.value}>{item.accountNumTarget}</div>
                                    <div className={styles.label}>{t('PROCESSING_STATUS')}</div>
                                    <div className={styles.value}>{item[`processingStatusName_${i18n.language}`]}</div>
                                </div>
                                <div className={styles.rowItem}>
                                    <div className={styles.label}>{t('DECL_SR_BANK_ACCOUNT_RETURN_AMOUNT')}</div>
                                    <div className={styles.value}>{item.sentAmount ? formatNumberWithCommas(item.sentAmount) : null}</div>
                                    <div className={styles.label}>{t('DECL_SR_DATE')}</div>
                                    <div className={styles.value}>{item.dateMode}</div>
                                </div>
                                <div className={styles.rowItem}>
                                    <div className={styles.label}>{t('DECL_SR_BANK_ACCOUNT_RETURNED_AMOUNT')}</div>
                                    <div className={styles.value}>{item.receviedAmount ? formatNumberWithCommas(item.receviedAmount) : null}</div>
                                    <div className={styles.label} />
                                    <div className={styles.value} />
                                </div>
                            </div>
                        ) : (
                            <ProcesingStatusCardMoobile { ...item } />
                        )}
                    </React.Fragment>
                ))}
            </div>
        </Modal>
    );
};
