import { useNavigate as useNav, useLocation, To, NavigateOptions } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getSearchParams } from "../utils";

interface NavOptions extends NavigateOptions {
    withoutSearch?: boolean
}

export const useNavigate = () => {
    const nav = useNav();
    const { search } = useLocation();
    const { i18n } = useTranslation();
    return (path: number | string, option?: NavOptions) => {
        const { withoutSearch, ...rest } = option ?? {};
        let temp: To  = "";
        if (typeof path === "number") {
            temp = path as To;
        }
        else if(i18n.language === 'am') {
            temp = `${path}`;
        }
        else {
            temp = `/${i18n.language}${path}`;
        }
        if(!withoutSearch && typeof path !== "number") {
            temp +=search;
        }
        nav(temp, { replace: !!getSearchParams().mobileView, ...rest });
    }
}