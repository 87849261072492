import React from 'react';
import { classnames, getSearchParams } from '../../utils';
import styles from './styles.module.scss';

interface WrapperProps {
    title?: string | React.ReactNode;
    children?: React.ReactNode;
    className?: string;
    headerRightIcon?: React.ReactNode
}

export const Wrapper: React.FC<WrapperProps> = (props) => {
    const { title, children, className, headerRightIcon } = props;

    const cs = classnames({
        [styles.Wrapper]: true,
        [className as string]: !!className,
        [styles.MobileView]: !!getSearchParams().mobileView
    })
    return (
        <div className={cs}>
            <div className={styles.Header}>
                {title} {headerRightIcon}
            </div>
            <div className={styles.Body}>
                {children}
            </div>
        </div>
    );
};