import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface IRevenues {
    tax: {
        rows: any[];
        pageSize: number;
        currentPage: number;
        total: number;
    },
    taxOther: {
        rows: any[];
        pageSize: number;
        currentPage: number;
        total: number;
    },
    otherSources: {
        rows: any[];
        pageSize: number;
        currentPage: number;
        total: number;
    }
}

const initialState: IRevenues = {
    tax: {
        rows: [],
        pageSize: 20,
        currentPage: 1,
        total: 0,
    },
    taxOther: {
        rows: [],
        pageSize: 20,
        currentPage: 1,
        total: 0,
    },
    otherSources: {
        rows: [],
        pageSize: 20,
        currentPage: 1,
        total: 0,
    }
};

export const revenuesSlice = createSlice({
    name: 'revenues',
    initialState,
    reducers: {
        initializeTaxRows: (_state, action: PayloadAction<any>) => {
            return { ..._state, tax: { ..._state.tax, rows: action.payload } }
        },
        initializeTaxTotal: (_state, action: PayloadAction<any>) => {
            return { ..._state, tax: { ..._state.tax, total: action.payload } }
        },
        initializeOtherRows: (_state, action: PayloadAction<any>) => {
            return { ..._state, taxOther: { ..._state.taxOther, rows: action.payload } }
        },
        initializeOtherTotal: (_state, action: PayloadAction<any>) => {
            return { ..._state, taxOther: { ..._state.taxOther, total: action.payload } }
        },
        changeTaxPage: (_state, action: PayloadAction<any>) => {
            return { ..._state, tax: { ..._state.tax, currentPage: action.payload } }
        },
        changeTaxOtherPage: (_state, action: PayloadAction<any>) => {
            return { ..._state, taxOther: { ..._state.taxOther, currentPage: action.payload } }
        },
        initializeOtherSourceRows: (_state, action: PayloadAction<any>) => {
            return { ..._state, otherSources: { ..._state.otherSources, rows: action.payload } }
        },
        initializeOtherSourceRowVirtual: (_state, action: PayloadAction<any>) => {
            const virtual = { ...action.payload, editableRow: true };
            return { ..._state, otherSources: { ..._state.otherSources, rows: [..._state.otherSources.rows, virtual] } }
        },
        removeOtherSourceRowVirtual: (_state, action: PayloadAction<any>) => {
            return { ..._state, otherSources: { ..._state.otherSources, rows: [..._state.otherSources.rows.filter(item => !item.editableRow)] } }
        },
        initializeOtherSourceTotal: (_state, action: PayloadAction<any>) => {
            return { ..._state, otherSources: { ..._state.otherSources, total: action.payload } }
        },
        changeOtherSourcePage: (_state, action: PayloadAction<any>) => {
            return { ..._state, otherSources: { ..._state.otherSources, currentPage: action.payload } }
        },
    },
})

export const {
    initializeTaxRows,
    initializeTaxTotal,
    initializeOtherRows,
    initializeOtherTotal,
    changeTaxPage,
    changeTaxOtherPage,
    initializeOtherSourceRows,
    initializeOtherSourceRowVirtual,
    removeOtherSourceRowVirtual,
    initializeOtherSourceTotal
} = revenuesSlice.actions;
export default revenuesSlice.reducer;
