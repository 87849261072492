import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styles from './styles.module.scss';
import { classnames } from '../../utils';
import { Button } from "../Button";
import * as Icons from "../Icons";
import loadingImage from "../../assets/images/loadingImg.gif";

interface IProps {
    children?: React.ReactElement | string,
    isOpen: boolean,
    onClose: Function,
    onCloseAction?: Function,
    title?: React.ReactElement | string,
    size?: 'sm' | 'md' | 'lg' | 'full-screen' | 'fit-content' | "s-fit-content",
    closeMessage?: string,
    closeBtnSize?: Size,
    errorMessage?: string,
    footerExists?: boolean,
    withoutHeader?: boolean,
    withoutBody?: boolean,
    footerExtraComponents?: React.ReactElement | string,
    className?: string;
    mode?: "head-colored";
    type?: "error" | "warning" | "info" | "success";
    closeButtonDisabled?: boolean;
    processingCancelBtn?: boolean;
    handleCancelProcess?: Function;
    cancelProcess?: boolean;
    withoutCancelIcon?: boolean;
};

export const Modal: React.FC<IProps> = ({
    isOpen,
    onClose,
    onCloseAction,
    title,
    children,
    size,
    closeMessage,
    footerExists = false,
    withoutHeader = false,
    withoutBody = false,
    footerExtraComponents,
    errorMessage,
    closeBtnSize = "s",
    className,
    mode,
    type,
    closeButtonDisabled,
    processingCancelBtn,
    handleCancelProcess,
    cancelProcess,
    withoutCancelIcon
}) => {
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if(isOpen) {
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }
    }, [isOpen]);

    if (!isOpen) return null;

    const modalContentStyle = classnames({
        [styles['modal-content']]: true,
        [styles[size || '']]: !!size,
        [className as string]: !!className,
        [styles.IsClosing]: isClosing,
        [styles[mode as string]]: !!mode
    });

    const csModalType = classnames({
        [styles[type as string]]: !!type
    });

    const handleClosing = () => {
        if (closeButtonDisabled) return;
        if (processingCancelBtn && handleCancelProcess) {
            handleCancelProcess();
            return;
        }
        if(size === "fit-content") {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false)
                onCloseAction ? onCloseAction?.() : onClose?.();
            }, 150);
        }
        else {
            onCloseAction ? onCloseAction?.() : onClose?.();
        }
    };

    return createPortal((
        <div className={`${styles.modal} modal`}>
            <div className={modalContentStyle}>
                <div className={styles.Header}>
                    {!withoutHeader && (
                        <div className={styles['modal-header']}>
                            <div className={styles['modal-title']}>{title}</div>
                        </div>
                    )}
                    <div className={styles.CloseBtnWrapper}>
                        {!withoutCancelIcon && (
                            <Button
                                className={styles['modal-close']}
                                onClick={cancelProcess ? () => null : handleClosing}
                                variant="link"
                            >
                                <Icons.Close />
                            </Button>
                        )}
                    </div>
                </div>
                {!withoutBody && (
                    <div className={`${styles['modal-body']} modal-body`}>
                        <div className={csModalType}>
                            {!!type && (
                                <div>
                                    {
                                        {
                                            "error": <Icons.ErrorOutlineCircle />,
                                            "warning": <Icons.WarningOutlineCircle />,
                                            "info": <Icons.InfoOutlineCircle />,
                                            "success": <Icons.SuccessOutline />
                                        }[type as string]
                                    }
                                </div>
                            )}
                            <div>
                                {children}
                            </div>
                        </div>
                    </div>
                )}
                {footerExists && (
                    <div className={styles['modal-footer']}>
                        <div className={styles['buttons-control']}>
                            {footerExtraComponents}
                            {closeMessage && (
                                <Button
                                    size={closeBtnSize}
                                    variant="outlined"
                                    onClick={cancelProcess ? () => null : handleClosing}
                                    disabled={cancelProcess}
                                >
                                    {closeMessage} {cancelProcess ? <img src={loadingImage} width={30} /> : null}
                                </Button>
                            )}
                        </div>
                        {errorMessage && <div className="text-right errorMessage">{errorMessage}</div>}
                    </div>
                )}
            </div>
        </div>
    ), document.body);
}
