import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '../../Layouts/Container';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import { Wrapper } from '../../Layouts/Wrapper';
import * as Icons from "../../components/Icons";
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Table, TableHead } from '../../components/Table';
import {formatDate, getDeclarationTypes} from '../../utils';
import { initializeRows } from '../../store/declarationSlice';
import { CardDeclaration } from '../../components/CardDeclaration';
import { RootState } from '../../store';
import { useNavigate } from '../../hook';
import type { CardProps } from '../../components/CardDeclaration';

export const path: string = 'declaration';

const DeclarationPage = () => {
    const { rows } = useSelector((state: RootState) => state.declaration);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const utils = useSelector((state: any) => state.utils);
    const auth: any = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const [firstOpen, setFirstOpen] = useState<boolean>(true);

    useEffect(() => {
        if (!auth?.tin || (auth?.tin && !utils.tinIsValid)) {
            navigate('/dashboard');
        }
    }, [auth?.tin, utils.tinIsValid]);

    useEffect(() => {
        getDeclarationTypes()
            .then(res => {
                dispatch(initializeRows(res.data));
                setFirstOpen(false);
            })
            .catch(err => console.log('getDeclarationTypes >>>', err));
    }, []);

    const tableHead: TableHead[] = useMemo(() => ([
        { key: "title", title: t("declarationName"), colSpan: 1, align: 'text-left' },
        { key: "docCode", title: t("declarationCode"), colSpan: 1 },
        { key: "validityPeriod", title: t("validity_period"), colSpan: 1 },
    ]), [t]);

    const openDeclarationType = (code: string) => {
        return () => {
            navigate(`/declaration/type/${code}`);
        }
    }

    let listView = null;
    const ROWS = rows.map((item: any, i: number) => {
        const titleText = item[`name_${i18n.language}`] || '';
        return ({
            ...item,
            title: (
                <div className={styles.DeclarationTypeItem} onClick={openDeclarationType(item.docCode)}>
                    <span><Icons.DeclTypeDoc /></span> 
                    <span>{i + 1}.{titleText}</span>
                </div>
            ),
            validityPeriod: `${item.startDate ? t('validity_period_format_start').replace('[[START_DATE]]', formatDate('DD.MM.YYYY', item.startDate)) : ''} ${item.endDate ? formatDate('DD.MM.YYYY', item.endDate) : ''}`
        });
    });
    switch (utils.device.mode) {
        case 'desktop':
            listView = (
                <Table
                    data={ROWS}
                    theads={tableHead}
                    verticalSize='middle'
                />
            );
            break;
        default:
            listView = ROWS.map((row: CardProps, index) => (
                <CardDeclaration key={row.docCode} order={index + 1} onClick={openDeclarationType(row.docCode)} { ...row } />
            ));

    }

    return (
        <DashboardLayout className={styles.Declaration}>
            <Container containerSize="large" className={styles.DeclarationContainer} withoutPadding>
                <Wrapper title={t("declarationTypes")}>
                    <div className={styles.TableSection}>
                        {firstOpen && !ROWS.length ? null : listView}
                    </div>
                </Wrapper>
            </Container>
        </DashboardLayout>
    );
};
export default DeclarationPage;
