import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import { LandingCard } from '../../components/LandingCard';
import { useTranslation } from 'react-i18next';
import { Container } from '../../Layouts/Container';
import { classnames, navigations } from '../../utils';
import * as Icons from "../../components/Icons";
import styles from "./styles.module.scss";

export const path: string = 'dashboard';

export default function Office() {
    const { t } = useTranslation();
    const auth= useSelector((state: any) => state.auth);
    
    const nav = useMemo(() => navigations.filter((nav) => nav.roles?.includes(auth.role)), [auth.role]);

    const iconMapper = useMemo(() => ({
        profile: <Icons.ProfileCard />,
        declarations: <Icons.Decl />,
        revenues: <Icons.Income />,
        expenses: <Icons.WalletExpenses />,
        payments: <Icons.CreditCard />,
        notifications: <Icons.Notification />
    }), []);

    const cardSize = () => {
        if (nav.length <= 2 || nav.length === 4) {
            return "middle";
        }
        if (nav.length >=3 && nav.length !== 4) {
            return "small";
        }
    };

    const csWrapper = classnames({
        [styles.CardsWrapper]: true,
        [styles.Middle]: cardSize() === "small",
        [styles.Small]: cardSize() === "middle"
    });

    return (
        <DashboardLayout showNavigation={false} className={styles.Office}>
            <Container
                title={t("appName")} 
                containerSize='large'
                className={styles.OfficeContainer}
            >
                <div className={csWrapper}>
                    {nav.map((nav, i) => (
                        <LandingCard 
                            title={t(nav.translateKey)} 
                            icon={iconMapper[nav.translateKey as keyof typeof iconMapper]} 
                            key={i}
                            path={nav.path}
                            size={cardSize()}
                        />
                    ))}
                </div>
            </Container>
        </DashboardLayout>
    );
}
