import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Wrapper } from '../../Layouts/Wrapper';
import { Input } from '../Input';
import { Button } from '../Button';
import { DatePicker } from '../DatePicker';
import {
    classnames,
    isNotResidentUser,
    setValueInTreeObject,
    IhandleArgs,
    decl_return_amount_fields_default,
    declReturnAmountDataIsIncompleteFields,
    regexpWithDocType,
    getProfile,
    translationKeysByErrorCodes,
    formatDate,
    getDocumentTypes,
    formatNumberWithCommas,
    getSendSRData,
    updateDeclSRDraftByID,
    createDeclSRDraft,
    getDeclSRSubmitByID,
    getDeclSRDraftByID,
    createDeclSRSubmit
} from '../../utils';
import styles from './styles.module.scss';
import { Select } from "../Select";
import Loading from '../Loading';
import * as Icons from "../Icons";
import { useNavigate } from '../../hook';
import { Checkbox } from '../Checkbox';
import { Tooltip } from '../Tooltip';
import { fetchDictionaries } from '../../store/dictionarySlice';

const bankAccountPartSize = 5;

const DeclarationReturnAmount: React.FC = () => {
    const { t , i18n} = useTranslation();
    const auth: any = useSelector((state: any) => state.auth);
    const utils = useSelector((state: any) => state.utils);
    const dictionary = useSelector((state: any) => state.dictionary);
    const { banksNames } = dictionary;
    const dispatch = useDispatch();
    const { id, type, declStatus } = useParams();
    const navigation = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [saveProcess, setSaveProcess] = useState<boolean>(false);
    const [invalidForm, setInvalidForm] = useState<boolean>(false);
    const [state, setState] = useState<any>(decl_return_amount_fields_default);
    const [docTypes, setDocTypes] = useState<any[]>([]);
    const [backendValidationError, setBackendValidationError] = useState<string[]>([]);
    const [bankAccountCheck, setBankAccountCheck] = useState<boolean>(false);

    const isNotResident = useMemo(() => isNotResidentUser(auth.role), [auth.role]);

    const documentTypeSelected = useMemo(() => (
        docTypes
            .filter(item => item.id === state.documentType.value)
            .map(item => ({ label: item[`name_${i18n.language}`], value: item.id }))[0] || null
    ), [docTypes, state.documentType.value, i18n.language]);

    const documentTypesOptions = useMemo(() => (
        docTypes.map(item => ({ label: item[`name_${i18n.language}`], value: item.id }))
    ), [docTypes, i18n.language]);

    const documentTypeStyles = useMemo(() => ({
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        control: (s: any) => ({ ...s, minHeight: '30px', height: '30px' }),
        valueContainer: (p: any) => ({ ...p, padding: '0 6px', height: '30px' }),
        input: (p: any) => ({ ...p, margin: 0 }),
        indicatorsContainer: (p: any) => ({ ...p, height: '30px' })
    }), []);

    const getBankName = (_s: any) => {
        let bankAccountPart = (_s.bankAccount.value || '').slice(0, bankAccountPartSize) || [];
        if (bankAccountPart.length === bankAccountPartSize) {
            bankAccountPart = `AM${bankAccountPart}`;
            return banksNames.data?.[bankAccountPart]?.[`name_${i18n.language}`];
        }
        return '';
    };

    const bankName = getBankName(state);

    const handleError = (_state: any, notVisible = true) => {
        let hasError = false;
        const skipStateKeys = ['distName', 'psn', 'firstName', 'lastName', 'middleName'];
        const fields = Object.keys(_state).filter(field => !skipStateKeys.includes(field));
        for (const field of fields) {
            const err = validateField(_state, field, notVisible);
            if (err) {
                hasError = err;
            }
        }
        return { _state, hasError };
    }

    useEffect(() => {
        if (!auth?.tin || (auth?.tin && !utils.tinIsValid)) {
            navigation('/dashboard');
        }
    }, [auth?.tin, utils.tinIsValid]);

    useEffect(() => {
        setIsLoading(true);
        getDocumentTypes({ visibleDocument: true }).then(res => setDocTypes(res.data)).catch(err => console.log('error.getDocumentTypes >', err));
        dispatch(fetchDictionaries({ dictArray: ['banksNames'] }));
        let declarationRequest = null;
        if (id) {
            let action;
            switch (declStatus) {
                case 'filled':
                    action = getDeclSRSubmitByID;
                    break;
                default:
                    action = getDeclSRDraftByID;
            }
            declarationRequest = action(id);
        } else {
            declarationRequest = getProfile({ withAddress: true });
        }
        declarationRequest
            .then(res => {
                if (id) {
                    setBankAccountCheck(res.data.checkBankAccount);
                    if (res.data.decl_was_updated) {
                        toast.error(t('data_was_changed'), { position: 'top-right', theme: 'colored' });
                    }
                }
                setState((prev: any) => {
                    let newState = {
                        ...prev,
                        tin: { ...prev.tin, value: res.data.tin },
                        taxpayerName: { ...prev.taxpayerName, value: res.data.taxpayerName },
                        firstName: { ...prev.firstName, value: res.data[id ? 'firstName' : 'name'] },
                        lastName: { ...prev.lastName, value: res.data.lastName },
                        middleName: { ...prev.firstName, value: res.data.middleName },
                        psn: { ...prev.psn, value: res.data[isNotResident ? 'noResidentPsn' : 'psn'] },
                        taxpayerAddress: { ...prev.taxpayerAddress, value: res.data[id ? 'taxpayerAddress' : 'businessAddress'] },
                        distName: { ...prev.distName, value: { am: res.data.distName_am, ru: res.data.distName_ru, en: res.data.distName_en } },
                        distCode: { ...prev.distCode, value: res.data.distCode },
                        birthDate: { ...prev.birthDate, value: res.data.birthDate },
                        documentType: { ...prev.documentType, value: res.data[id ? 'documentType' : 'documentTypeId'] },
                        documentNumber: { ...prev.documentNumber, value: res.data.documentNumber },
                        documentIssueDate: { ...prev.documentIssueDate, value: res.data.documentIssueDate },
                        documentIssuer: { ...prev.documentIssuer, value: res.data.documentIssuer },
                        bankAccount: { ...prev.bankAccount, value: res.data.bankAccount },
                        returnAmount: { ...prev.returnAmount, value: id ? (res.data.returnAmount || '').toString() : '' }
                    };
                    newState = handleError(newState)._state;
                    const incompleteFields = declReturnAmountDataIsIncompleteFields[isNotResident ? 'notResident' : 'resident'];
                    if (incompleteFields.some(field => !newState[field].value)) {
                        toast.error(t('DECL_SR_HAS_INCOMPLETE_FIELD'), { position: 'top-right', theme: 'colored' });
                        setInvalidForm(true);
                    }
                    return newState;
                });
            })
            .catch(err => {
                console.log('err >>>', err);
                if (id && err.response.status === 404) {
                    navigation('/dashboard');
                } else {
                    const error = translationKeysByErrorCodes(err);
                    if (error) {
                        toast.error(t(error), { position: 'top-right', theme: 'colored' });
                    }
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const validateField = (_state: any, id: string, notVisible: boolean = true) => {
        const { value } = _state[id];
        let hasError: boolean = false;
        switch (id) {
            case 'bankAccount':
                if (!value) {
                    setValueInTreeObject(_state, id, 'FIELD_NOT_SET', true, notVisible);
                    hasError = true;
                } else if (value && value.length < 8) {
                    setValueInTreeObject(_state, id, 'DECL_SR_BANK_ACCOUNT_NUMBER_WRONG_FORMAT', true, notVisible);
                    hasError = true;
                } else {
                    setValueInTreeObject(_state, id, '', true, notVisible);
                }
                if (value && value.length >= 5 && Object.keys(banksNames).length) {
                    const bName = getBankName(_state);
                    if (!bName) {
                        setValueInTreeObject(_state, id, 'DECL_SR_BANK_NAME_NOT_EXISTS', true, notVisible);
                        hasError = true;
                    }
                }
                break;
            case 'returnAmount':
                if (!value) {
                    setValueInTreeObject(_state, id, 'FIELD_NOT_SET', true, notVisible);
                    hasError = true;
                } else if (value === '0') {
                    setValueInTreeObject(_state, id, 'DECL_SR_BANK_ACCOUNT_RETURN_AMOUNT_WRONG_FORMAT', true, notVisible);
                    hasError = true;
                } else {
                    setValueInTreeObject(_state, id, '', true, notVisible);
                }
                break;
            case 'documentType':
                if (!value) {
                    setValueInTreeObject(_state, id, 'FIELD_NOT_SET', true, notVisible);
                    hasError = true;
                } else {
                    // @ts-ignore
                    const regexp = regexpWithDocType[_state.documentType.value];
                    if (regexp) {
                        if (_state['documentNumber'].value) {
                            if (!new RegExp(regexp).test(_state['documentNumber'].value)) {
                                setValueInTreeObject(_state, 'documentNumber', 'DECL_SR_INVALID_INPUT', true, notVisible);
                                hasError = true;
                            } else {
                                setValueInTreeObject(_state, 'documentNumber', '', true, notVisible);
                            }
                        } else {
                            setValueInTreeObject(_state, 'documentNumber', 'FIELD_NOT_SET', true, notVisible);
                            hasError = true;
                        }
                    }
                }
                break;
            case 'documentNumber':
                if (!value) {
                    setValueInTreeObject(_state, id, 'FIELD_NOT_SET', true, notVisible);
                    hasError = true;
                } else {
                    // @ts-ignore
                    const regexp = regexpWithDocType[_state.documentType.value];
                    if (regexp) {
                        if (!new RegExp(regexp).test(value)) {
                            setValueInTreeObject(_state, id, 'DECL_SR_INVALID_INPUT', true, notVisible);
                            hasError = true;
                        } else {
                            setValueInTreeObject(_state, id, '', true, notVisible);
                        }
                    }
                }
                break;
            case 'documentIssuer':
                if (!value) {
                    setValueInTreeObject(_state, id, 'FIELD_NOT_SET', true, notVisible);
                    hasError = true;
                } else {
                    setValueInTreeObject(_state, id, '', true, notVisible);
                }
                break;
            default:
                if (!value) {
                    setValueInTreeObject(_state, id, 'FIELD_NOT_SET', true, notVisible);
                    hasError = true;
                } else {
                    setValueInTreeObject(_state, id, '', true, notVisible);
                }
        }
        return hasError;
    }

    const handleInputChange = (args: IhandleArgs) => (ev: React.ChangeEvent<HTMLInputElement>, cursorFixator?: CallableFunction) => {
        let { value } = ev.target;
        if (args.numeric) {
            value = value.replace(/,/g, '');
            cursorFixator?.();
            if (value.length > 0) {
                value = parseFloat(value).toString();
            }
        }
        const maxLengthValidation = !!(args.maxLength && value.length > args.maxLength);
        const patternValidation = !!(value && args.pattern && !(new RegExp(args.pattern).test(value)));
        if (maxLengthValidation || patternValidation) return;

        setState((prevState: any) => {
            setValueInTreeObject(prevState, args.id, value);
            validateField(prevState, args.id, false);
            return ({
                ...prevState,
            });
        });
    };

    const handleDatePicker = (key: string) => (val: any) => {
        if (!isNotResident) return;
        setState((prev: any) => {
            const newState = {
                ...prev,
                [key]: { ...prev[key], value: val ? formatDate('YYYY-MM-DD', val) : '' }
            };
            validateField(newState, key, false);
            return newState;
        });
    }

    const handleDocumentType = (option: any) => {
        if (!isNotResident) return;
        setState((prev: any) => {
            const newState = {
                ...prev,
                documentType: { ...prev.documentType, value: option.value }
            }
            validateField(newState, 'documentType', false);
            return newState;
        });
    }

    const classDeclarationForm = classnames({
        [styles.declarationReturnAmountContainer]: true,
        'mb-5': true
    });

    const buttonsDisabled = saveProcess || invalidForm;

    const issuerClass = classnames({ [styles.colItem]: true, [styles.colItemFlex]: true });

    const rememberTemporarily = () => {
        setIsLoading(true);
        setSaveProcess(true);
        const send = getSendSRData(state, type as string, bankAccountCheck);
        let request = null;
        if (id && declStatus !== 'filled') {
            request = updateDeclSRDraftByID(id, send);
        } else {
            request = createDeclSRDraft(send);
        }
        request
            .then(() => {
                navigation('/declaration/draft');
            })
            .catch(err => {
                console.log(err);
                const error = translationKeysByErrorCodes(err);
                if (error) {
                    toast.error(t(error), { position: 'top-right', theme: 'colored' });
                }
            })
            .finally(() => {
                setSaveProcess(false);
                setIsLoading(false);
            });
    }

    const hasErrorForRegistration = () => {
        const { hasError, _state } = handleError(state, false);
        let hasErrorRegistration = false;
        if (!bankAccountCheck && !isNotResident) {
            const hasToastifyAlert = !!window.document.querySelector('.Toastify > div[class*="Toastify"]');
            if (!hasToastifyAlert) {
                toast.error(t('DECL_SR_NEED_BANK_ACCOUNT_CHECK'), { position: 'top-right', theme: 'colored' });
            }
            hasErrorRegistration = true;
        }
        if (hasError) {
            setState({ ..._state });
            hasErrorRegistration = true;
        }
        return hasErrorRegistration;
    }

    const registerDeclaration = () => {
        if (hasErrorForRegistration()) return;
        setSaveProcess(true);
        setIsLoading(true);
        setBackendValidationError([]);
        const send = getSendSRData(state, type as string, bankAccountCheck);
        createDeclSRSubmit(i18n.language, declStatus !== 'filled' ? id : undefined, send)
            .then((res) => {
                if (res.data.correct) {
                    navigation('/declaration/filled');
                } else {
                    console.log('res.data', res.data);
                }
            })
            .catch(err => {
                console.log('registerDeclaration >>>', err);
                const key = translationKeysByErrorCodes(err);
                if (key) {
                    if (key === 'BACKEND_VALIDATION_ERROR' && err.response?.data?.errors) {
                        setBackendValidationError(err.response?.data?.errors);
                        window.scrollTo(0,0);
                    } else {
                        toast.error(t(key), { position: 'top-right', theme: 'colored' });
                    }
                }
            })
            .finally(() => {
                setSaveProcess(false);
                setIsLoading(false);
            });
    }

    return (
        <Wrapper
            title={t("decl_return_amount")}
            className={classDeclarationForm}
            headerRightIcon={(
                <Tooltip title={t('sr_help_icon')} type='info' className={styles.headerRightIcon} position='bottom-right'>
                    <a href={`/${i18n.language}/declaration-sr-help`} target="_blank">
                        <Icons.InfoOutline />
                    </a>
                </Tooltip>
            )}
        >
            <div className={styles.govDecisionContainer}>
                <div className={styles.errorMessageContainer}>
                    {backendValidationError.map((errorMsg: string) => (
                        <div key={errorMsg} className={styles.errorMessage}>{errorMsg}</div>
                    ))}
                </div>
                <div className={styles.govDecision}>
                    <pre>{t('DECL_SR_GOV_DECISION')}</pre>
                </div>
            </div>
            <div className={styles.container}>
                <div className={`${styles.rowItems} user-details`}>
                    <div className={styles.colItem}>
                        <Input
                            label={t('DECL_SR_TIN')}
                            id="tin"
                            defaultValue={state.tin.value}
                            errorMessage={state.tin?.errorMessage && !state.tin.notVisible ? t(state.tin?.errorMessage) : ''}
                            disabled
                        />
                    </div>
                    <div className={styles.colItem}>
                        <Input
                            label={t('DECL_SR_TAXPAYER_NAME')}
                            id="taxpayerName"
                            defaultValue={state.taxpayerName.value}
                            errorMessage={state.taxpayerName.errorMessage && !state.taxpayerName.notVisible ? t(state.taxpayerName.errorMessage) : ''}
                            disabled
                        />
                    </div>
                    <div className={styles.colItem}>
                        <Input
                            label={t('DECL_SR_PSN')}
                            id="psn"
                            defaultValue={state.psn.value}
                            errorMessage={state.psn.errorMessage && !state.psn.notVisible ? t(state.psn.errorMessage) : ''}
                            disabled
                        />
                    </div>
                    <div className={styles.colItem}>
                        <Input
                            label={t('DECL_SR_TAXPAYER_ADDRESS')}
                            id="taxpayerAddress"
                            defaultValue={state.taxpayerAddress.value}
                            errorMessage={state.taxpayerAddress.errorMessage && !state.taxpayerAddress.notVisible ? t(state.taxpayerAddress.errorMessage) : ''}
                            disabled
                        />
                    </div>
                    <div className={styles.colItem}>
                        <Input
                            label={t('DECL_SR_DIST_NAME')}
                            id="distName"
                            defaultValue={state.distName.value?.[i18n.language]}
                            errorMessage={state.distCode.errorMessage && !state.distCode.notVisible ? t(state.distCode.errorMessage) : ''}
                            disabled
                        />
                    </div>
                    <div className={styles.colItem}>
                        <DatePicker
                            label={t('DECL_SR_BIRTHDATE')}
                            errorMessage={state.birthDate.errorMessage && !state.birthDate.notVisible ? t(state.birthDate?.errorMessage) : ''}
                            value={state.birthDate.value ? new Date(state.birthDate.value) : undefined}
                            onChange={handleDatePicker('birthDate')}
                            iconprefixstyle={{ height: '30px' }}
                            config={{ start: { disabled: !isNotResident } }}
                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 14))}
                        />
                    </div>
                    <div className={styles.colItem}>
                        <Select
                            label={t('DECL_SR_DOCUMENT')}
                            options={documentTypesOptions}
                            value={documentTypeSelected}
                            errorMessage={state.documentType.errorMessage && !state.documentType.notVisible ? t(state.documentType.errorMessage) : ''}
                            onChange={handleDocumentType}
                            onInputChange={() => {}}
                            isDisabled={!isNotResident}
                            styles={documentTypeStyles}
                        />
                    </div>
                    <div className={styles.colItem}>
                        <Input
                            label={t('DECL_SR_DOCUMENT_NUMBER')}
                            id="documentNumber"
                            value={state.documentNumber.value || ''}
                            errorMessage={state.documentNumber.errorMessage && !state.documentNumber.notVisible ? t(state.documentNumber.errorMessage) : ''}
                            onChange={handleInputChange({ id: 'documentNumber', maxLength: 20 })}
                            disabled={!isNotResident}
                        />
                    </div>
                    <div className={issuerClass}>
                        <DatePicker
                            label={t('DECL_SR_ISSUE_DATE')}
                            errorMessage={state.documentIssueDate.errorMessage && !state.documentIssueDate.notVisible ? t(state.documentIssueDate?.errorMessage) : ''}
                            value={state.documentIssueDate.value ? new Date(state.documentIssueDate.value) : undefined}
                            onChange={handleDatePicker('documentIssueDate')}
                            iconprefixstyle={{ height: '30px' }}
                            config={{ start: { disabled: !isNotResident } }}
                            maxDate={new Date()}
                        />
                        <Input
                            label={t('DECL_SR_ISSUER')}
                            id="documentIssuer"
                            value={state.documentIssuer.value || ''}
                            errorMessage={state.documentIssuer.errorMessage && !state.documentIssuer.notVisible ? t(state.documentIssuer.errorMessage) : ''}
                            onChange={handleInputChange({ id: 'documentIssuer', maxLength: 5, pattern: '^[0-9]+$' })}
                            disabled={!isNotResident}
                        />
                    </div>
                </div>
                <div>
                    <div className={styles.returnAmountTitle}>{t('DECL_SR_AMOUNT_RETURN_TITLE')}</div>
                    {!isNotResident && (
                        <div className={styles.bankAccountCheck}>
                            <Checkbox
                                label={<label htmlFor="bankAccountCheck">{t('DECL_SR_BANK_ACCOUNT_CHECK')}</label>}
                                checked={bankAccountCheck}
                                onChange={() => setBankAccountCheck(prev => !prev)}
                                boxSize={'x-small'}
                                id="bankAccountCheck"
                            />
                        </div>
                    )}
                    <div className={styles.bankAccountBody}>
                        <div className={styles.colItem}>
                            <Input
                                label={t('DECL_SR_BANK_ACCOUNT_NUMBER')}
                                id="bankAccount"
                                value={state.bankAccount.value || ''}
                                onChange={handleInputChange({ id: 'bankAccount', maxLength: 16, pattern: '^[0-9]+$' })}
                                errorMessage={state.bankAccount.errorMessage && !state.bankAccount.notVisible ? t(state.bankAccount.errorMessage) : ''}
                            />
                        </div>
                        <div className={styles.colItem}>
                            <Input
                                label={t('DECL_SR_BANK_ACCOUNT_NAME')}
                                id="bankAccountName"
                                defaultValue={bankName}
                                disabled
                            />
                        </div>
                        <div className={styles.colItem}>
                            <Input
                                label={t('DECL_SR_BANK_ACCOUNT_RETURN_AMOUNT')}
                                id="returnAmount"
                                value={state.returnAmount.value ? formatNumberWithCommas(state.returnAmount.value) : ''}
                                onChange={handleInputChange({ id: 'returnAmount', maxLength: 13, pattern: '^[0-9]+$', numeric: true })}
                                errorMessage={state.returnAmount.errorMessage && !state.returnAmount.notVisible ? t(state.returnAmount.errorMessage) : ''}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.rowItems}>
                    <div className={styles.formButtons}>
                        <Button size="small" disabled={buttonsDisabled} onClick={!buttonsDisabled ? rememberTemporarily : () => null}>
                            {t('remember_temporarily')}
                        </Button>
                        <Button size="small" disabled={buttonsDisabled} onClick={!buttonsDisabled ? registerDeclaration: () => null}>
                            {t('register_declaration')}
                        </Button>
                    </div>
                </div>
            </div>
            {isLoading && <Loading />}
        </Wrapper>
    );
};

export default DeclarationReturnAmount;
