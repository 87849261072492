import { useSelector } from "react-redux";
import Loading from "../../../../Loading";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../../store";
import { useEffect, useRef, useState } from "react";
import { Form } from "../../../MobileIncomingTax/components/Form";
import { generateField } from "../../../MobileIncomingTax/utils";
import styles from "./styles.module.scss";
import { formValidator } from "../../../../../utils";

export const PersonalDataForm: React.FC<{ onSubmit: CallableFunction, getRootDataStore: () => any, initData: CallableFunction, formId: string }> = (props) => {
    const { onSubmit, getRootDataStore, initData, formId } = props;
    const root = getRootDataStore().root;
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        tin: generateField<string>("tin", root?.tin, {  validation: { isRequired: true, disabled: true }, label: "DECL_SR_TIN" }),
        taxpayerName: generateField<string>("taxpayerName", root?.taxpayerName, {  validation: { isRequired: true, disabled: true }, label: "DECL_SR_TAXPAYER_NAME" }),
        psn: generateField<string>("psn", root?.psn ?? root?.noResidentPsn, {  validation: { disabled: true }, label: "DECL_SR_PSN" }),
        taxpayerAddress: generateField<string>("taxpayerAddress", root?.taxpayerAddress, {  validation: { isRequired: true, disabled: true }, label: "DECL_SR_TAXPAYER_ADDRESS" }),
        distName: generateField<string>("distName", root?.distName, {  validation: { isRequired: true, disabled: true }, label: "DECL_SR_DIST_NAME" }),
    });
    const dataRef = useRef({ data: formData, error: false });

    const handleSubmit = () => {
        const passData = Object.entries(dataRef.current.data).reduce((acc: any, entries: any) => {
            const [key, field] = entries;
            acc[key] = field.value
            return acc;
        }, {})
        onSubmit?.(passData, dataRef.current.error);
    };

    useEffect(() => {
        setFormData((prev) => ({ ...prev, distName: { ...prev.distName, value: getRootDataStore().root?.[`distName_${i18n.language}`] }}))
    }, [i18n.language]);

    useEffect(() => {
        const validator = formValidator(formData);
        dataRef.current = { data: formData, error: !validator.isValid };
    }, [JSON.stringify(formData)]);

    useEffect(() => {
        if (getRootDataStore()[formId]?.error) {
            const { fieldsErrorTrigger } = formValidator(formData);
            setFormData((prev) => {
                Object.values(fieldsErrorTrigger).map((triggerError) => {
                    const error = triggerError as TriggerError;
                    const formField = prev[error.key as keyof typeof prev];
                    if(error && error.type !== "none" && formField) {
                        return formField.error = error.message ?? error.errorKey;
                    }
                })
                return { ...prev }
            })
        }
        return () => {
            if (window.location.pathname.includes("declaration/type/sr")) {
                handleSubmit();
            };
        };
    }, []);

    useEffect(() => {
        initData((action: any) => {
            const { data } = action;
            if(data) {
                setFormData((prev) => {
                    const temp = { ...prev };
                    Object.entries(temp).map(([fieldKey, field]) => {
                        switch (fieldKey) {
                            case "psn":
                                return field.value = data.psn ?? data.noResidentPsn;

                            case "distName":
                                return field.value = data[`distName_${i18n.language}`];
                            default:
                                return field.value = data[fieldKey];
                        }
                    })
                    return temp;
                })
            }
        })
    }, [])

    return (
        <div>
            <div className={styles.Decision}>
                <p>{t('DECL_SR_GOV_DECISION_MOBILE')}</p>
            </div>
            <Form data={formData} onChange={() => {}} />
        </div>
    )
};