import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Modal } from '../Modal';
import styles from './styles.module.scss';
import loadingImage from '../../assets/images/loadingImg.gif';

interface FileUploadDialogProps {
    modalTitle?: string;
    isOpen: boolean;
    close: Function;
    approve: Function;
}

export const FileUploadDialog: React.FC<FileUploadDialogProps> = (props) => {
    const [file, setFile] = useState<any>(null);
    const [process, setProcess] = useState<boolean>(false);
    const { t } = useTranslation();
    const fileRef = useRef<any>(null);

    const selectFile = useCallback(() => {
        fileRef.current?.click();
    }, [fileRef.current]);

    const onChangeFile = useCallback((ev: any) => {
        setFile(ev.target.files?.[0]);
    }, []);

    useEffect(() => {
        if (!props.isOpen && file) {
            setFile(null);
        }
    }, [props.isOpen]);

    const toggleProcess = useCallback(() => setProcess(prev => !prev), []);

    const uploading = () => {
        if (!file?.name) return;
        toggleProcess();
        props.approve(file, toggleProcess);
    }

    return (
        <Modal
            className={styles.FileUploadDialog}
            title={t(props.modalTitle || 'upload_file')}
            size="s-fit-content"
            isOpen={props.isOpen}
            closeMessage={t('close')}
            onClose={props.close}
            footerExtraComponents={(
                <Button
                    size="s"
                    color="primary"
                    onClick={process ? () => {} : uploading}
                    disabled={!file?.name || process}
                >
                    {
                        process ? (
                                <>
                                    {t('wait')} <img src={loadingImage} width={30} />
                                </>
                            ) :
                            t('uploading')
                    }
                </Button>
            )}
            footerExists
            mode='head-colored'
        >
            <>
                <div className={styles.clickChooseFileMessage}>
                    <div className={styles.title}>{t('clickChooseFile')}</div>
                    <Button
                        size="sm"
                        color="primary"
                        onClick={selectFile}
                        style={file?.name ? { fontSize: 13 } : {} }
                    >
                        {file?.name || t('chooseFile')}
                    </Button>
                    <input ref={fileRef} type="file" onChange={onChangeFile} />
                </div>
            </>
        </Modal>
    );
};
