import React from 'react';
import styles from './styles.module.scss';

interface SectionCutterProps {
    title?: string
}

export const SectionCutter:React.FC<SectionCutterProps> = (props) => {
    const { title } = props

    return (
        <div className={styles.SectionCutter}>
            <div></div>
            {title && <div className={styles.fitContentItem}>{title}</div>}
            <div></div>
        </div>
    );
};