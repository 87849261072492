import { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { path } from '../pages/Login';
import queryString from 'query-string';
import * as BridgeActions from "../utils/bridge_actions";
import { getSearchParams } from '../utils';

type Props = {
    redirectTo: string;
    remember?: string;
    withLocation?: boolean,
    withoutRedirectPath?: boolean,
    authData?: any
};

export const AuthController = ({ redirectTo, withLocation, withoutRedirectPath, authData }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { lngCode = '' } = params;
    const rTo = redirectTo.replace(':status?', '');
    const redirect = `/${lngCode ? `${lngCode}/${rTo}` : rTo}`;
    const { defaultLanguage = "" } = authData ?? {};

    useEffect(() => {
        if (withLocation) {
            const parsed = queryString.parse(location.search);
            const path = `/${defaultLanguage}${parsed.r as string ?? redirect}`.replace("/am", "");
            navigate(path);
        } 
        else if (redirectTo === path && !!getSearchParams().mobileView) {
            BridgeActions.onAction(BridgeActions.TOKEN_ERROR);
        }
        else {
            navigate(`${redirect}${!withoutRedirectPath ? `?r=${lngCode ? location.pathname.replace(`/${lngCode}`, "") : location.pathname}` : ''}`);
        }
    }, []);
    
    return null;
}