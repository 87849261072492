import React, { memo } from 'react';
import { languages } from '../../utils/languages';
import { classnames } from '../../utils';
import { useTranslate } from '../../hook';
import styles from "./styles.module.scss";
import { Dropdown } from '../Dropdown';

interface LanguagesProps {
    mode?: "toggler" | "linear";
};

const LanguageGroup: React.FC<{flag: any, title?: string, className?: string}> = (props) => (
    <div className={`${styles.langGroup} ${props.className ?? ""}`}>
        <span className={styles.flag}>
            <img src={props.flag} alt='flag' />
        </span>
        <span>{props?.title}</span>
    </div>
);

export const Languages: React.FC<LanguagesProps> = memo((props) => {
    const { mode = "toggler" } = props;
    const { changeLanguage, currentLang } = useTranslate();

    const handleChangeLanguage = (code: string) => {
        if(code !== currentLang?.code) {
            changeLanguage(code);
        }
    }

    if (mode === "toggler") {
        return (
            <Dropdown
                label={<LanguageGroup flag={currentLang?.flag} title={currentLang?.shortTitle} />}
                list={languages.map((lng) => ({
                    label: (
                        <LanguageGroup
                            flag={lng?.flag}
                            title={lng?.shortTitle}
                            className={classnames({[styles.Sub]: true, [styles.Disabled]: currentLang?.code === lng.code })} 
                        />
                    ),
                    key: lng.code
                }))}
                onClick={handleChangeLanguage}
                xPosition='left'
            />
        )
    }

    return (
        <ul className={styles.languagesList}>
            {languages.map(language => (
                <li key={language.code} className={styles.languageItem} onClick={() => handleChangeLanguage(language.code)}>
                    <LanguageGroup
                        flag={language.flag}
                        title={language.shortTitle}
                        className={classnames({[styles.Disabled]: currentLang?.code === language.code })}
                    />
                </li>
            ))}
        </ul>
    );
})

