import {
    IReceiveTinData,
    IRegistrationFieldsErrorsDefault,
    IRegistrationFormData,
    IResetFieldsErrorsDefault,
    IResetPasswordFormData,
    NavigationItem,
    Role,
    I_incoming_tax_social_payment
} from './types';

export const tinRegexp = /^[0-9]{8}$/;
export const usernameRegexp = /^[a-zA-Z0-9-]{3,20}$/;
export const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,100}$/;
export const bankAccountRegexp = /^\d{8,16}$/;
export const emailRegexp = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const phoneRegexp = /^\d{8}$/;
export const phoneRegexpWithCountryCode = /^\+\(374\)\d{8}$/;
export const armenianCharsRegexp = /^[ա-ֆԱ-Ֆ0-9\s./-]+$/;
export const armenianCharsStreetRegexp = /^[ա-ֆԱ-Ֆ0-9\s./-]{1,40}$/;
export const armenianCharsBuildingHouseRegexp = /^[ա-ֆԱ-Ֆ0-9\s./-]{1,10}$/;
export const armenianCharsHouseRegexp = /^[ա-ֆԱ-Ֆ0-9\s./-]{1,8}$/;
export const numberRegexp = /^\d+$/;

export const isValidEmail = (email: string) => {
    if (!email.match(emailRegexp)) {
        return false;
    }
    const [username, domain = ''] = email.split('@');
    if (username.length > 1 && username.length <= 64 && domain.length > 1 && domain.length <= 190) {
        return true;
    }
    return false;
}

export const loginFieldsDefault = {
    globalError: { value: '' } as Field<string>,
    tin: { value: '', error: '', validation: { isRequired: true }, isValid: false, key: "tin" } as Field<string>,
    username: { value: '', error: '', validation: { isRequired: true }, isValid: false, key: "username" } as Field<string>,
    password: { value: '', error: '', validation: { isRequired: true }, isValid: false, key: "password" } as Field<string>
}

export const incoming_tax_social_payment: I_incoming_tax_social_payment = {
    adjustment: false,
    showAdjustment: false,
    taxYear: { value: undefined, error: '', isValid: true, key: "taxYear", validation: { min: new Date(2023, 0), max: new Date(new Date().getFullYear() - 1, 0) } } as Field<Date | undefined>,
    taxpayerName: { value: '', error: '', key: "taxpayerName", isValid: false } as Field<string>,
    psn: { value: '', error: '', key: "psn", isValid: false } as Field<string>,
    tin: { value: '', error: '', key: "tin", isValid: false } as Field<string>,
    physSocParticipationType: { value: '', error: '', key: "physSocParticipationType", isValid: false } as Field<string>,
    taxpayerAddress: { value: '', error: '', key: "taxpayerAddress", isValid: false } as Field<string>,
    taxpayerPhone: { value: '', error: '', key: "taxpayerPhone", isValid: false } as Field<string>,
    section1_1: {
        employerDataList: [
            {
                tin: {
                    value: '',
                    isValid: true,
                    key: 'section1_1.employerDataList.0.tin'
                },
                taxpayerName: {
                    value: '',
                    isValid: true,
                    key: 'section1_1.employerDataList.0.taxpayerName'
                },
                salary: {
                    value: '',
                    isValid: true,
                    key: 'section1_1.employerDataList.0.salary'
                },
                civilSalary: {
                    value: '',
                    isValid: true,
                    key: 'section1_1.employerDataList.0.civilSalary'
                },
                allBenefits: {
                    value: '',
                    isValid: true,
                    key: 'section1_1.employerDataList.0.allBenefits'
                },
                otherTaxIncome: {
                    value: '',
                    isValid: true,
                    key: 'section1_1.employerDataList.0.otherTaxIncome'
                },
                amountReduce: {
                    value: '',
                    isValid: true,
                    key: 'section1_1.employerDataList.0.amountReduce'
                },
                totalIncome: {
                    value: '',
                    isValid: true,
                    key: 'section1_1.employerDataList.0.totalIncome'
                },
                totalIncomeTax: {
                    value: '',
                    isValid: true,
                    key: 'section1_1.employerDataList.0.totalIncomeTax'
                }
            }
        ],
        totalIncome: { value: '', isValid: true, key: 'section1_1_totalIncome' },
        totalTax: { value: '', isValid: true, key: 'section1_1_totalTax' }
    },
    section1_2: {
        code001: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } },
        ],
        code002: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } }
        ],
        code003: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } }
        ],
        code004: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } }
        ],
        code005: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } }
        ],
        code007: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } }
        ],
        code008: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } }
        ],
        code009: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } }
        ],
        code010: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } }
        ],
        code011: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } }
        ],
        code012: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } },
        ],
        code072: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } }
        ],
        code082: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } }
        ],
        code099: [
            { tin: { value: '', isValid: true, key: '1' }, taxpayerName: { value: '', isValid: true, key: '1' }, incomeAmount: { value: '', isValid: true, key: '1' }, taxAmount: { value: '', isValid: true, key: '1' } }
        ],
        totalIncome: { value: '', isValid: true, key: '1' },
        totalTax: { value: '', isValid: true, key: '1' },
    },
    section2: {
        f2_1: { value: '', isValid: true, key: '1' },
        f2_2: { value: '', isValid: true, key: '1' },
        f2_3: { value: '', isValid: true, key: '1' },
        f2_4: { value: '', isValid: true, key: '1' },
        f2_5: { value: '', isValid: true, key: '1' },
        f2_6: { value: '', isValid: true, key: '1' },
        f2_7: { value: '', isValid: true, key: '1' },
        f2_8: { value: '', isValid: true, key: '1' },
        f2_9: { value: '', isValid: true, key: '1' },
        f2_10: { value: '', isValid: true, key: '1' },
        f2_11: { value: '', isValid: true, key: '1' },
        f2_12: { value: '', isValid: true, key: '1' },
        f2_13: { value: '', isValid: true, key: '1' },
        f2_14: { value: '', isValid: true, key: '1' },
        f2_15: { value: '', isValid: true, key: '1' },
        f2_16: { value: '', isValid: true, key: '1' },
        f2_17: { value: '', isValid: true, key: '1' },
        f2_18: { value: '', isValid: true, key: '1' },
        f2_19: { value: '', isValid: true, key: '1' },
        f2_20: { value: '', isValid: true, key: '1' },
        f2_21: { value: '', isValid: true, key: '1' },
        f2_22: { value: '', isValid: true, key: '1' },
        f2_23: { value: '', isValid: true, key: '1' },
        f2_24: { value: '', isValid: true, key: '1' },
        f2_25: { value: '', isValid: true, key: '1' },
        f2_26: { value: '', isValid: true, key: '1' },
        f2_27: { value: '', isValid: true, key: '1' },
    },
    section3: {
        salary: { value: '', isValid: true, key: '1' },
        civilContractsSalary: { value: '', isValid: true, key: '1' },
        salaryTaxableIncomeReduce: { value: '', isValid: true, key: '1' },
        salaryTaxableIncome: { value: '', isValid: true, key: '1' },
        salaryTaxableRate: { value: '', isValid: true, key: '1' },
        royaltyIncome: { value: '', isValid: true, key: '1' },
        royaltyRate: { value: '', isValid: true, key: '1' },
        percent10Income: { value: '', isValid: true, key: '1' },
        percent10Rate: { value: '', isValid: true, key: '1' },
        percent20Income: { value: '', isValid: true, key: '1' },
        percent20Rate: { value: '', isValid: true, key: '1' },
    },
    section4: {
        educationalCostAmount: { value: '', isValid: true, key: '1' },
        healthEnsuranceAmount: { value: '', isValid: true, key: '1' },
    },
    section6: {
        selfEmployeeIncome: { value: '', isValid: true, key: '1' },
        selfEmployeeSocRate: { value: '', isValid: true, key: '1' },
        selfEmployeeSocPayment: { value: '', isValid: true, key: '1' }
    }
}

export const registrationFieldsErrorsDefault: IRegistrationFieldsErrorsDefault = {
    tin: '',
    username: '',
    password: '',
    confirmPassword: '',
    generalError: ''
}

export const resetFieldsErrorsDefault: IResetFieldsErrorsDefault = {
    username: '',
    password: '',
    confirmPassword: '',
    generalError: ''
}

export const registrationFormDataDefault: IRegistrationFormData = {
    verificationTin: '',
    tin: '',
    username: '',
    password: '',
    confirmPassword: ''
}

export const resetPasswordFormDataDefault: IResetPasswordFormData = {
    username: '',
    password: '',
    confirmPassword: ''
}

export const navigations: NavigationItem[] = [
    {
        translateKey: "profile",
        path: "/profile",
        subMenu: [],
        roles: [Role.ROLE_NO_RESIDENT, Role.ROLE_RESIDENT]
    },
    {
        translateKey: "declarations",
        path: "/declaration",
        disableMenuForWithoutTin: true,
        subMenu: [
            {
                translateKey: "declarationTypes",
                path: "/declaration",
                subMenu: [],
                disableMenuForWithoutTin: true,
            },
            {
                translateKey: "declarationDraft",
                path: "/declaration/draft",
                subMenu: [],
                disableMenuForWithoutTin: true,
            },
            {
                translateKey: "declarationFilled",
                path: "/declaration/filled",
                subMenu: [],
                disableMenuForWithoutTin: true,
            },
            {
                translateKey: "declarationSubmitted",
                path: "/declaration/submitted",
                subMenu: [],
                disableMenuForWithoutTin: true,
            },
        ],
        roles: [Role.ROLE_NO_RESIDENT, Role.ROLE_RESIDENT]
    },
    {
        translateKey: "revenues",
        path: "/revenues",
        subMenu: [
            {
                translateKey: "revenuesTaxAgentMain",
                path: "/revenues",
                subMenu: []
            },
            {
                translateKey: "revenuesTaxAgentOther",
                path: "/revenues-other",
                subMenu: []
            },
            {
                translateKey: "revenuesOtherSource",
                path: "/revenues/other-source",
                subMenu: [],
                disableMenuForWithoutTin: true,
            }
        ],
        roles: [Role.ROLE_RESIDENT]
    },
    {
        translateKey: "revenues",
        path: "/revenues/other-source",
        subMenu: [],
        roles: [Role.ROLE_NO_RESIDENT],
        disableMenuForWithoutTin: true,
    },
    {
        translateKey: "expenses",
        path: "/expenses",
        subMenu: [],
        roles: [Role.ROLE_RESIDENT]

    },
    // {
    //     translateKey: "notifications",
    //     path: "/notifications",
    //     subMenu: [],
    //     roles: [Role.ROLE_NO_RESIDENT, Role.ROLE_RESIDENT],
    // },
    {
        translateKey: "payments",
        path: "/payments",
        subMenu: [],
        roles: [Role.ROLE_NO_RESIDENT, Role.ROLE_RESIDENT],
        disableMenuForWithoutTin: true
    }
];

export const resetDataDefault = {
    request: { isSent: false, errorMessage: "" },
    reset_username: { value: "", error: "" },
    reset_email: { value: "", error: "" }
};

export const changePasswordDataDefault = {
    request: { isSent: false, errorMessage: '' },
    currentPassword: { value: '', error: '' },
    newPassword: { value: '', error: '' },
    confirmNewPassword: { value: '', error: '' }
};

export const profileBankDetailsDefault = {
    request: { successMessage: '', errorMessage: '' },
    bankName: { value: '', error: '' },
    bankAccount: { value: '', error: '' },
    email: { value: '', error: '' },
    phone: { value: '', error: '' }
}

export const receiveTinDefaultData: IReceiveTinData = {
    psn: '',
    firstName: '',
    lastName: '',
    surName: '',
    documentNumber: '',
    street: '',
    buildingHouse: '',
    house: '',
    phoneNumber: '',
    postalCode: '',
    personId: 0,
    draftId: undefined,
    dateOfBirth: '',
    selectedResidenceStreet: '',
    selectedResidenceHouse: '',
    selectedResidenceApartment: ''
};

export const receiveTinDefaultErrors = {
    region: '',
    community: '',
    residence: '',
    street: '',
    buildingHouse: '',
    house: '',
    phoneNumber: '',
    postalCode: '',
    hasError: false
};

export const signTypes = [
    {
        value: 'ID_CARD'
    },
    {
        value: 'CO_SIGN'
    },
    {
        value: 'MID'
    },
];

export const signTypesNonResident = [
    {
        value: 'CO_SIGN'
    },
    {
        value: 'CO_SIGN_NON_RESIDENT'
    },
];

export enum signDocumentTypes {
    CLOSED,
    SIGN_TYPES,
    COSIGN_CERTIFICATE,
    COSIGN_LOGIN_PASSWORD,
    MID
}

export const mIDTypes = [
    {
        label: 'mID_type_team',
        image: 'teamTelecomArmenia.png',
        value: 'team'
    },
    {
        label: 'mID_type_ucom',
        image: 'ucomArmenia.png',
        value: 'ucom'
    },
    {
        label: 'mID_type_viva',
        image: 'vivacellMTC.png',
        value: 'viva'
    }
];

export interface IMonthType {
    am: {
        short: string;
        long: string;
    },
    ru: {
        short: string;
        long: string;
    },
    en: {
        short: string;
        long: string;
    }
}

export const months: IMonthType[] = [
    { am: { short: "Հնվ", long: "Հունվար"}, ru: { short: "Янв", long: "Январь"}, en: { short: "Jan", long: "January"} },
    { am: { short: "Փետ", long: "Փետրվար"}, ru: { short: "Фев", long: "Февраль"}, en: { short: "Feb", long: "February"} },
    { am: { short: "Մար", long: "Մարտ"}, ru: { short: "Мар", long: "Март"}, en: { short: "Mar", long: "March"} },
    { am: { short: "Ապր", long: "Ապրիլ"}, ru: { short: "Апр", long: "Апрель"}, en: { short: "Apr", long: "April"} },
    { am: { short: "Մայ", long: "Մայիս"}, ru: { short: "Май", long: "Май"}, en: { short: "May", long: "May"} },
    { am: { short: "Հուն", long: "Հունիս"}, ru: { short: "Июн", long: "Июнь"}, en: { short: "Jun", long: "June"} },
    { am: { short: "Հուլ", long: "Հուլիս"}, ru: { short: "Июл", long: "Июль"}, en: { short: "Jul", long: "July"} },
    { am: { short: "Օգս", long: "Օգոստոս"}, ru: { short: "Авг", long: "Август"}, en: { short: "Aug", long: "August"} },
    { am: { short: "Սեպ", long: "Սեպտեմբեր"}, ru: { short: "Сен", long: "Сентябрь"}, en: { short: "Sep", long: "September"} },
    { am: { short: "Հոկ", long: "Հոկտեմբեր"}, ru: { short: "Окт", long: "Октябрь"}, en: { short: "Oct", long: "October"} },
    { am: { short: "Նոյ", long: "Նոյեմբեր"}, ru: { short: "Ноя", long: "Ноябрь"}, en: { short: "Nov", long: "November"} },
    { am: { short: "Դեկ", long: "Դեկտեմբեր"}, ru: { short: "Дек", long: "Декабрь"}, en: { short: "Dec", long: "December"} },
]

export const weekDaysName = {
    monday: { am: { short: "երկ", long: "երկուշաբթի" }, ru: { short: "пнд", long: "понедельник" }, en: { short: "mo", long: "monday" } },
    tuesday: { am: { short: "երք", long: "երեքշաբթի" }, ru: { short: "втр", long: "вторник" }, en: { short: "tu", long: "tuesday" } },
    wednesday: { am: { short: "չրք", long: "չորեքշաբթի" }, ru: { short: "срд", long: "среда" }, en: { short: "we", long: "wednesday" } },
    thursday: { am: { short: "հնգ", long: "հինգշաբթի" }, ru: { short: "чтв", long: "четверг" }, en: { short: "th", long: "thursday" } },
    friday: { am: { short: "ուրբ", long: "ուրբաթ" }, ru: { short: "птн", long: "пятница" }, en: { short: "fr", long: "friday" } },
    saturday: { am: { short: "շբթ", long: "շաբաթ" }, ru: { short: "сбт", long: "суббота" }, en: { short: "sa", long: "saturday" } },
    sunday: { am: { short: "կրկ", long: "կիրակի" }, ru: { short: "вск", long: "воскресенье" }, en: { short: "su", long: "sunday" } },
};

export const section1Head1TypesOfIncome = [
    { translation_key: 'salaryAndOtherEquivalentPayments', number: '1.1.5', value_income_key: 'salary' },
    { translation_key: 'section1Head1TypesOfIncome_2', number: '1.1.6', value_income_key: 'civilSalary' },
    { translation_key: 'section1Head1TypesOfIncome_3', number: '1.1.7', value_income_key: 'allBenefits' },
    { translation_key: 'section1Head1TypesOfIncome_4', number: '1.1.8', value_income_key: 'otherTaxIncome' },
    { translation_key: 'section1Head1TypesOfIncome_5', number: '1.1.9', value_income_key: 'amountReduce', dedicationAmount: true },
    { translation_key: 'section1Head1TypesOfIncome_6', number: '1.1.10', value_income_key: 'totalIncome', value_tax_key: 'totalIncomeTax' },
];

export const section1Head2TypesOfIncome = [
    { indexNum: 1, translation_key: 'sec_1_2_code001', number: '1․2.5', income_type_code: 'code001' },
    { indexNum: 2, translation_key: 'sec_1_2_code002', number: '1․2.6', numberRowSpan: 3, income_type_code: 'code002' },
    { translation_key: 'sec_1_2_code072', income_type_code: 'code072' },
    { translation_key: 'sec_1_2_code082', income_type_code: 'code082' },
    { indexNum: 3, translation_key: 'sec_1_2_code009', number: '1․2.7', income_type_code: 'code009' },
    { indexNum: 4, translation_key: 'sec_1_2_code010', number: '1․2.8', income_type_code: 'code010' },
    { indexNum: 5, translation_key: 'sec_1_2_code005', number: '1․2.9', income_type_code: 'code005' },
    { indexNum: 6, translation_key: 'sec_1_2_code007', number: '1․2.10', income_type_code: 'code007' },
    { indexNum: 7, translation_key: 'sec_1_2_code008', number: '1․2.11', income_type_code: 'code008' },
    { indexNum: 8, translation_key: 'sec_1_2_code003', number: '1․2.12', income_type_code: 'code003' },
    { indexNum: 9, translation_key: 'sec_1_2_code004', number: '1․2.13', income_type_code: 'code004' },
    { indexNum: 10, translation_key: 'sec_1_2_code011', number: '1․2.14', income_type_code: 'code011' },
    { indexNum: 11, translation_key: 'sec_1_2_code012', number: '1․2.15', income_type_code: 'code012' },
    { indexNum: 12, translation_key: 'sec_1_2_code099', number: '1․2.16', income_type_code: 'code099' },
    { indexNum: 13, translation_key: 'total', number: '1․2.17', income_type_code: 'total' },
];

export const section2Rows = [
    { indexNum: 1, translation_key: 'sec_2_tr_1', number: '2.1', value_key: 'f2_1' },
    { indexNum: 2, translation_key: 'sec_2_tr_2', number: '2.2', value_key: 'f2_2' },
    { indexNum: 3, translation_key: 'sec_2_tr_3', number: '2.3', value_key: 'f2_3' },
    { indexNum: 4, translation_key: 'sec_2_tr_4', number: '2.4', value_key: 'f2_4' },
    { indexNum: 5, translation_key: 'sec_2_tr_5', number: '2.5', value_key: 'f2_5' },
    { indexNum: 6, translation_key: 'sec_2_tr_6', number: '2.6', value_key: 'f2_6' },
    { indexNum: 7, translation_key: 'sec_2_tr_7', number: '2.7', value_key: 'f2_7' },
    { indexNum: 8, translation_key: 'sec_2_tr_8', number: '2.8', value_key: 'f2_8' },
    { indexNum: 9, translation_key: 'sec_2_tr_9', number: '2.9', value_key: 'f2_9' },
    { indexNum: 10, translation_key: 'sec_2_tr_10', number: '2.10', value_key: 'f2_10' },
    { indexNum: 11, translation_key: 'sec_2_tr_11', number: '2.11', value_key: 'f2_11' },
    { indexNum: 12, translation_key: 'sec_2_tr_12', number: '2.12', value_key: 'f2_12' },
    { indexNum: 13, translation_key: 'sec_2_tr_13', number: '2.13', value_key: 'f2_13' },
    { indexNum: 14, translation_key: 'sec_2_tr_14', number: '2.14', value_key: 'f2_14' },
    { indexNum: 15, translation_key: 'sec_2_tr_15', number: '2.15', value_key: 'f2_15' },
    { indexNum: 16, translation_key: 'sec_2_tr_16', number: '2.16', value_key: 'f2_16' },
    { indexNum: 17, translation_key: 'sec_2_tr_17', number: '2.17', value_key: 'f2_17' },
    { indexNum: 18, translation_key: 'sec_2_tr_18', number: '2.18', value_key: 'f2_18' },
    { indexNum: 19, translation_key: 'sec_2_tr_19', number: '2.19', value_key: 'f2_19' },
    { indexNum: 20, translation_key: 'sec_2_tr_20', number: '2.20', value_key: 'f2_20' },
    { indexNum: 21, translation_key: 'sec_2_tr_21', number: '2.21', value_key: 'f2_21' },
    { indexNum: 22, translation_key: 'sec_2_tr_22', number: '2.22', value_key: 'f2_22' },
    { indexNum: 23, translation_key: 'sec_2_tr_23', number: '2.23', value_key: 'f2_23' },
    { indexNum: 24, translation_key: 'sec_2_tr_24', number: '2.24', value_key: 'f2_24' },
    { indexNum: 25, translation_key: 'sec_2_tr_25', number: '2.25', value_key: 'f2_25' },
    { indexNum: 26, translation_key: 'sec_2_tr_26', number: '2.26', value_key: 'f2_26' },
    { indexNum: 27, translation_key: 'sec_2_tr_27', number: '2.27', value_key: 'f2_27', disabled: true },
];

export const section3Rows = [
    { translation_key: 'sec_3_tr_row_2', rows: [{ income: 'salary', rate: 'X', incomeTax: 'X' }] },
    { translation_key: 'sec_3_tr_row_3', rows: [{ income: 'civilContractsSalary', rate: 'X', incomeTax: 'X' }] },
    { translation_key: 'sec_3_tr_row_4', rows: [{ income: 'salaryTaxableIncomeReduce', rate: 'X', incomeTax: 'X', dedicationAmount: true }] },
    { translation_key: 'sec_3_tr_row_5', rows: [{ income: 'salaryTaxableIncome', rate: 'salaryTaxableRate', incomeTax: 'salaryTax', disabled: true, sumTaxes: true }] },
    { translation_key: 'sec_3_tr_row_6', rows: [{ income: 'royaltyIncome', rate: 'royaltyRate', incomeTax: 'royaltyTax', sumTaxes: true }] },
    {
        translation_key: 'sec_3_tr_row_7',
        rows: [
            { income: 'percent20Income', rate: 'percent20Rate', incomeTax: 'percent20Tax', sumTaxes: true },
            { income: 'percent10Income', rate: 'percent10Rate', incomeTax: 'percent10Tax', sumTaxes: true },
        ]
    },
    { translation_key: 'sec_3_tr_row_8', rows: [{ income: 'feeIncome', rate: 'feeRate', incomeTax: 'feeTax', sumTaxes: true }] },
    { translation_key: 'sec_3_tr_row_9', rows: [{ income: 'realEstateRentIncome', rate: 'realEstateRentRate', incomeTax: 'realEstateRentTax', sumTaxes: true }] },
    {
        translation_key: 'sec_3_tr_row_10',
        rows: [
            { income: 'divident5Income', rate: 'divident5Rate', incomeTax: 'divident5Tax', sumTaxes: true },
            { income: 'divident10Income', rate: 'divident10Rate', incomeTax: 'divident10Tax', sumTaxes: true },
        ]
    },
    { translation_key: 'sec_3_tr_row_11', rows: [{ income: 'propertySaleIncome', rate: 'propertySaleRate', incomeTax: 'propertySaleTax', sumTaxes: true }] },
    { translation_key: 'sec_3_tr_row_12', rows: [{ income: 'propertyDeveloperSaleIncome', rate: 'propertyDeveloperSaleRate', incomeTax: 'propertyDeveloperSaleTax', sumTaxes: true }] },
    { translation_key: 'sec_3_tr_row_13', rows: [{ income: 'shareInvestPropSaleIncome', rate: 'shareInvestPropSaleRate', incomeTax: 'shareInvestPropSaleTax', sumTaxes: true }] },
    { translation_key: 'sec_3_tr_row_14', rows: [{ income: 'awardsIncome', rate: 'awardsRate', incomeTax: 'awardsTax', sumTaxes: true }] },
    { translation_key: 'sec_3_tr_row_15', rows: [{ income: 'giftIncome', rate: 'giftRate', incomeTax: 'giftTax', sumTaxes: true }] },
    {
        translation_key: 'sec_3_tr_row_16',
        rows: [
            { income: 'unpaidPassive5Income', rate: 'unpaidPassive5Rate', incomeTax: 'unpaidPassive5Tax', sumTaxes: true },
            { income: 'unpaidPassive10Income', rate: 'unpaidPassive10Rate', incomeTax: 'unpaidPassive10Tax', sumTaxes: true },
            { income: 'unpaidPassive20Income', rate: 'unpaidPassive20Rate', incomeTax: 'unpaidPassive20Tax', sumTaxes: true },
        ]
    },
    { translation_key: 'sec_3_tr_row_17', rows: [{ income: 'otherTaxableIncome', rate: 'otherTaxableRate', incomeTax: 'otherTaxableTax', sumTaxes: true }] },
    { translation_key: 'sec_3_tr_row_18', rows: [{ income: 'X', rate: 'X', incomeTax: 'totalCalculatedTax' }] },
    { translation_key: 'sec_3_tr_row_19', rows: [{ income: 'X', rate: 'X', incomeTax: 'otherCountriesPaidTax', incomeTaxEditable: true }] },
    { translation_key: 'sec_3_tr_row_20', rows: [{ income: 'X', rate: 'X', incomeTax: 'otherCountriesReduceTax', incomeTaxEditable: true, dedicationAmount: true }] },
    { translation_key: 'sec_3_tr_row_21', rows: [{ income: 'X', rate: 'X', incomeTax: 'totalTaxToPaid' }] },
];

export const section5Rows = [
    { indexNum: 1, translation_key: 'sec_5_tr_1', number: '5.2', value_key: 'employerPaidTax', disabled: true },
    { indexNum: 2, translation_key: 'sec_5_tr_2', number: '5.3', value_key: 'hypotecReturnAmount', disabled: true, dedicationAmount: true },
    { indexNum: 3, translation_key: 'sec_5_tr_3', number: '5.4', value_key: 'tuitionFeeReturnAmount', disabled: true, dedicationAmount: true },
    { indexNum: 4, translation_key: 'sec_5_tr_4', number: '5.5', value_key: 'investmentReturnAmount', disabled: true, dedicationAmount: true },
    { indexNum: 5, translation_key: 'sec_5_tr_5', number: '5.6', value_key: 'employerReduceAmount', disabled: true },
    { indexNum: 6, translation_key: 'sec_5_tr_6', number: '5.7', value_key: 'yearlyCalculatedDifference', disabled: true },
    { indexNum: 7, translation_key: 'sec_5_tr_7', number: '5.8', value_key: 'invoiceReturnAmount', disabled: true, dedicationAmount: true },
    { indexNum: 8, translation_key: 'sec_5_tr_8', number: '5.9', value_key: 'taxToPaid', disabled: true },
    { indexNum: 9, translation_key: 'sec_5_tr_9', number: '5.10', value_key: 'taxToReturn', disabled: true }
];


export const helps = [
    { translationKey: "guidelines_completing_declarations" },
];

export const decl_return_amount_fields_default = {
    tin: { value: '', errorMessage: '', notVisible: false },
    taxpayerName: { value: '', errorMessage: '', notVisible: false },
    firstName: { value: '', errorMessage: '', notVisible: false },
    lastName: { value: '', errorMessage: '', notVisible: false },
    middleName: { value: '', errorMessage: '', notVisible: false },
    psn: { value: '', errorMessage: '', notVisible: false },
    taxpayerAddress: { value: '', errorMessage: '', notVisible: false },
    distName: { value: '', errorMessage: '', notVisible: false },
    distCode: { value: '', errorMessage: '', notVisible: false },
    birthDate: { value: '', errorMessage: '', notVisible: false },
    documentType: { value: '', errorMessage: '', notVisible: false },
    documentNumber: { value: '', errorMessage: '', notVisible: false },
    documentIssueDate: { value: '', errorMessage: '', notVisible: false },
    documentIssuer: { value: '', errorMessage: '', notVisible: false },
    bankAccount: { value: '', errorMessage: '', notVisible: false },
    returnAmount: { value: '', errorMessage: '', notVisible: false }
}

export const declReturnAmountDataIsIncompleteFields = {
    resident: ['taxpayerAddress', 'distCode', 'birthDate', 'documentType', 'documentNumber', 'documentIssueDate', 'documentIssuer'],
    notResident: ['taxpayerAddress', 'distCode']
}

export const regexpWithDocType = {
    1: '^[A-Z]{2}[0-9]{7}$', // ՀՀ անձնագիր
    5: '^[0-9]{9}$', // Նույնականացման քարտ
    6: '^[A-Z]{2}[0-9]{7}$', // Կենսաչափական անձնագիր
    7: '^(.){1,20}$', // Օտարերկրյա անձնագիր՝ ՀՀ-ում աշխատանքի իրավունքով
    8: '^(.){1,20}$' // Օտարերկրյա անձնագիր
}

export const srSendDataKeys = [
    "docCode",
    "firstName",
    "lastName",
    "middleName",
    "taxpayerName",
    "tin",
    "psn",
    "taxpayerAddress",
    "distCode",
    "birthDate",
    "documentType",
    "documentNumber",
    "documentIssueDate",
    "documentIssuer",
    "bankAccount",
    "bankCode",
    "returnAmount",
    "checkBankAccount"
]