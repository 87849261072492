import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../store";
import { getSearchParams } from "../utils";

export const useChatBot = () => {
    const params = useParams();
    const utils = useSelector((state: RootState) => state.utils);

    useLayoutEffect(() => {
        const lng = params.lngCode ?? "am";
        
        const src= `${process.env.REACT_APP_CHAT_BOT_URL}/frame/sdk.bundle.js?version=1.7.4&companyId=${process.env[`REACT_APP_CHAT_BOT_${lng.toUpperCase()}`]}`;
        const script = document.createElement('script');

        if(!getSearchParams().mobileView) {
            script.src = src;
            script.async = true;
            script.id = "TSChatSDK";
            script.onload = () => {
                const iframe: HTMLIFrameElement | null = document.querySelector(".ts-chatbot-wrapper iframe");
                if(iframe?.contentDocument) {
                    iframe.onload = () => {
                        const chatWrapper: HTMLDivElement | null = document.querySelector(".ts-chatbot-wrapper");
                        if(chatWrapper && utils.device?.mode !== "desktop") {
                            chatWrapper.style.visibility = "hidden"
                        }
                    }
                }
            }

            document.body.appendChild(script);
        };

        return () => {
            if(!getSearchParams().mobileView) {
                const prevBot = document.querySelector(".ts-chatbot-wrapper");
                if(prevBot) {
                    document.body.removeChild(prevBot);
                }
                document.body.removeChild(script);
            }
        };
    }, [params.lngCode]);

    useLayoutEffect(() => {
        if(!getSearchParams().mobileView) {
            const iframe: HTMLIFrameElement | null = document.querySelector(".ts-chatbot-wrapper iframe");
            if(utils.device?.mode !== "desktop" && iframe) {
                iframe.contentWindow?.postMessage("hideChatButton", "*");
            }
            else if (utils.device?.mode === "desktop"  && iframe) {
                iframe.contentWindow?.postMessage("showChatButton", "*");
            }
        }
    }, [utils.device?.mode]);
};