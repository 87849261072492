import React from 'react';
import styles from './styles.module.scss';
import * as Icons from "../Icons"
import { classnames, formatNumberWithCommas } from '../../utils';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { useNavigate } from '../../hook';

interface CardProps {
    title?: string;
    date?: string;
    status: string;
    statusId?: number;
    price?: string;
    taxpayerName?: string;
    taxpayerTin?: string;
    psn?: string;
    serviceRecipient?: string;
    serialNo?: string;
    downloadDocument?: any;
    adjustment?: boolean;
}

export const ExpensesCard: React.FC<CardProps> = (props) => {
    const { title, date, status, price, statusId, taxpayerName, taxpayerTin, psn, serviceRecipient, serialNo, downloadDocument, adjustment } = props;
    const { t } = useTranslation();

    const cs = classnames({
        [styles.ExpensesCard]: true,
        [styles.Success]: statusId === 1,
        [styles.Cancel]: statusId !== 1
    })

    return (
        <div className={cs}>
            <div className={styles.Title}>
                <span>
                    {title}
                </span>
                <span className={styles.Status}>{status}</span>
            </div>
            <div className={styles.Date}>{date}</div>
            <div className={styles.Price}>
                {price && !Number.isNaN(parseFloat(price)) ? formatNumberWithCommas(price) : price} {t('dram')}
            </div>
            <div className={styles.Recipient}>
                <span>{serviceRecipient}</span>
                <span>{psn}</span>
            </div>
            <div className={styles.Taxpayer}>
                <span>{taxpayerName}</span>
                <span>{taxpayerTin}</span>
            </div>
            <div className={styles.Doc}>
                <span>{t('view')}</span>
                <span onClick={downloadDocument}>
                    <span><Icons.PDF/></span>
                    <span style={adjustment ? { fontStyle: 'italic' } : {}} title={t('corrective_settlement_document')}>{serialNo}</span>
                </span>
            </div>
        </div>
    );
};

export const RevenuesTaxAgentCard: React.FC<RevenuesTaxAgent> = (props) => {
    const { tin, taxpayerName, year, month, receiptDate, salary, civilSalary, allBenefits, otherTaxIncome, incomeTax, socialFee } = props;
    const { t } = useTranslation();

    const cs = classnames({
        [styles.RevenuesTaxAgentCard]: true,
    })

    return (
        <div className={cs}>
            <div className={styles.Title}>
                <span>{taxpayerName}</span>
                <span>{tin}</span>
            </div>
            <div className={styles.ReceiptDate}>
                <span>{receiptDate}</span>
                <span>
                    <span>{t("period")}</span>
                    <span>{`${year} / ${month?.slice(0, month?.includes("ու") ? 4 : 3)}.`}</span>
                </span>
            </div>
            <div className={styles.Salary}>
                <span>{t("salaryAndOtherEquivalentPayments")}</span>
                <span>{`${salary ?? 0} ${t("dram")}`}</span>
            </div>
            <div className={styles.CivilSalary}>
                <span>{t("paymentForWork")}</span>
                <span>{`${civilSalary} ${t("dram")}`}</span>
            </div>
            <div className={styles.AllBenefits}>
                <span>{t("temporaryUnemployment")}</span>
                <span>{`${allBenefits} ${t("dram")}`}</span>
            </div>
            <div className={styles.OtherTaxIncome}>
                <span>{t("otherTaxIncome")}</span>
                <span>{`${otherTaxIncome} ${t("dram")}`}</span>
            </div>
            <div className={styles.Report}>
                <span>
                    <span>{t("revenueTax")}</span>
                    <span>{`${incomeTax} ${t("dram")}`}</span>
                </span>
                <span>
                    <span>{t("socialFee")}</span>
                    <span>{`${socialFee} ${t("dram")}`}</span>
                </span>
            </div>
        </div>
    )
};

export const OtherRevenuesTaxAgentCard: React.FC<OtherRevenuesTaxAgent > = (props) => {
    const { tin, taxpayerName, year, incomeAmount, incomeRateCode, receiptDate, taxAmount, ...incomeRateName } = props;
    const { t, i18n } = useTranslation();

    const cs = classnames({
        [styles.OtherRevenuesTaxAgentCard]: true,
    })

    return (
        <div className={cs}>
            <div className={styles.Title}>
                <span>{incomeRateName[`incomeRateName_${i18n.language}` as keyof typeof incomeRateName]}</span>
            </div>
            <div className={styles.Taxpayer}>
                <span>{taxpayerName}</span>
                <span>{tin}</span>
            </div>
            <div className={styles.ReceiptDate}>
                <span>{receiptDate}</span>
                <span>
                    <span>{t("period")}</span>
                    <span>{year}</span>
                </span>
            </div>
            <div className={styles.Report}>
                <span>
                    <span>{t("revenuesSize")}</span>
                    <span>{`${incomeAmount} ${t("dram")}`}</span>
                </span>
                <span>
                    <span>{t("revenueTax")}</span>
                    <span>{`${taxAmount} ${t("dram")}`}</span>
                </span>
            </div>
        </div>
    )
};

interface OtherSourceRevenuesCardProps extends OtherSourceRevenues {
    edit: CallableFunction;
    remove: CallableFunction;
};

export const OtherSourceRevenuesCard: React.FC<OtherSourceRevenuesCardProps> = (props) => {
    const { registrationDate, year, month, sourceInfo, incomeAmount, taxAmount, taxRate, edit, remove, id,...incomeTypeNames} = props;
    const { t, i18n } = useTranslation();

    const cs = classnames({
        [styles.OtherSourceRevenuesCard]: true,
    });

    const handleRemove = () => {
        const { edit, remove, ...rest} = props;
        remove?.(rest);
    };

    const handleEdit = () => {
        edit?.(id);
    };

    return (
        <div className={cs}>
            <div className={styles.Title}>
                <span>{incomeTypeNames[`incomeTypeName_${i18n.language}` as keyof typeof incomeTypeNames]}</span>
            </div>
            <div className={styles.ReceiptDate}>
                <span>{registrationDate}</span>
                <span>
                    <span>{t("period")}</span>
                    <span>{year}{month ? ` / ${month}` : ''}</span>
                </span>
            </div>
            <div className={styles.InfoTaxAgent}>
                <span>{t("incomeRevenuePaidUser")}</span>
                <span>{sourceInfo}</span>
            </div>
            <div className={styles.Report}>
                <span>
                    <span>{t("revenuesSize")}</span>
                    <span>{`${incomeAmount} ${t("dram")}`}</span>
                </span>
                <span>
                    <span>{t("rateSize")}</span>
                    <span>{taxRate} %</span>
                </span>
                <span>
                    <span>{t("revenueTax")}</span>
                    <span>{`${taxAmount} ${t("dram")}`}</span>
                </span>
            </div>
            <div className={styles.ActionsGroup}>
                <Button variant="link" onClick={handleEdit}>
                    <Icons.Edit />
                </Button>
                <Button variant="link" className={styles.Remove} onClick={handleRemove}>
                    <Icons.Trash />
                </Button>
            </div>
        </div>
    )
};

export interface DeclarationDraftCardProps {
    declaration: string;
    createdDate: string;
    updatedDate?: string;
    reportingPeriod: string | number;
    docCode: string;
    id: string;
    onRemove?: CallableFunction
}

export const DeclarationDraftCard: React.FC<DeclarationDraftCardProps> = (props) => {
    const { declaration, createdDate, reportingPeriod, updatedDate, docCode, id } = props;
    const navigation = useNavigate();

    const { t } = useTranslation();

    const handleEdit = () => {
        navigation(`/declaration/type/${docCode}/${id}`);

    };

    const handleRemove = () => {
        const { onRemove, ...rowData } = props;
        onRemove?.(rowData);
    };

    return (
        <div className={styles.DeclarationDraftCard}>
            <div className={styles.Title}>
                <span>{declaration}</span>
            </div>
            <div className={styles.ReceiptDate}>
                <span>
                    <span>{createdDate}</span>
                    <span>
                        <span><Icons.UpdateAt /></span>
                        <span>
                            {!!updatedDate ? updatedDate : ""}
                        </span>
                    </span>
                </span>
                <span>
                    <span>{t("period")}</span>
                    <span>{reportingPeriod}</span>
                </span>
            </div>
            <div className={styles.ActionsGroup}>
                <Button variant="link" onClick={handleEdit}>
                    <Icons.Edit />
                </Button>
                <Button variant="link" className={styles.Remove} onClick={handleRemove}>
                    <Icons.Trash />
                </Button>
            </div>
        </div>
    )
}

export interface DeclarationFilledCardProps {
    declaration: string;
    createdDate: string;
    updatedDate?: string;
    reportingPeriod: string | number;
    docCode: string;
    id: string;
    status?: number;
    signedType?: string;
    onRemove?: CallableFunction;
    sentToPek?: CallableFunction;
    onDownload?: CallableFunction;
    onSign?: CallableFunction;
    onUpload?: CallableFunction;
};

export const DeclarationFilledCard: React.FC<DeclarationFilledCardProps> = (props) => {
    const { declaration, createdDate, reportingPeriod, docCode, id, status, signedType} = props;
    const navigation = useNavigate();

    const { t } = useTranslation();

    const handleEdit = () => {
        navigation(`/declaration/type/${docCode}/${id}/filled`);
    };

    const handleRemove = () => {
        const { sentToPek, onRemove, onDownload, onSign, onUpload, ...cleanData } = props;
        onRemove?.(cleanData);
    };

    const handleSentToPek = () => {
        const { sentToPek, onRemove, onDownload, onSign, onUpload, ...cleanData } = props;
        sentToPek?.(cleanData);
    };

    const handleDownload = () => {
        const { sentToPek, onRemove, onDownload, onSign, onUpload, ...cleanData } = props;
        onDownload?.(cleanData)
    };

    const handleSign = () => {
        const { sentToPek, onRemove, onDownload, onSign, onUpload, ...cleanData } = props;
        onSign?.(cleanData);
    };

    return (
        <div className={styles.DeclarationFilledCard}>
            <div className={styles.Title}>
                <span>{declaration}</span>
            </div>
            <div className={styles.ReceiptDate}>
                <span>
                    <span>{createdDate}</span>
                </span>
                <span>
                    <span>{t("period")}</span>
                    <span>{reportingPeriod}</span>
                </span>
            </div>
            <div className={styles.Status}>
                <span>{t("status")}</span>
                <span>{signedType}</span>
            </div>
            <div className={styles.ActionsGroup}>
                <Button variant="link" onClick={handleEdit}>
                    <Icons.Edit />
                </Button>
                <Button variant="link" onClick={handleRemove}>
                    <Icons.Trash />
                </Button>
                <Button variant="link" onClick={handleDownload}>
                    <Icons.PDF />
                </Button>
                {status === 0 && (
                    <Button variant="link" onClick={handleSign}>
                        <Icons.SignDocumentPen />
                    </Button>
                )}
            </div>
            {status !== 0 && (
                <div className={styles.Submit}>
                    <Button size='s' onClick={handleSentToPek}>
                        <span>{t("sendToPEK")}</span>
                        <Icons.Submit />
                    </Button>
                </div>
            )}
        </div>
    )
};

export interface DeclarationSubmittedCardProps {
    title: string;
    taxYear: string | number;
    receipt: string;
    statusName: string;
    dln?: string;
    status?: number;
    downloadDeclaration?: CallableFunction;
    downloadReceived?: CallableFunction;
    showMoreSR?: CallableFunction;
    docCode: string;
}

export const DeclarationSubmittedCard: React.FC<DeclarationSubmittedCardProps> = (props) => {
    const { title, taxYear, receipt, statusName, status, downloadDeclaration, downloadReceived, dln, showMoreSR, docCode } = props;
    const { t } = useTranslation();

    return (
        <div className={styles.DeclarationSubmittedCard}>
            <div className={styles.Title}>
                <span>{title}</span>
            </div>
            <div className={styles.ReceiptDate}>
                <span>
                    <span>{receipt}</span>
                    <span className={classnames({ [styles.StatusName]: true, [styles.IsValid]: status === 0, [styles.WithError]: status === 1 })}>{statusName}</span>
                </span>
                <span>
                    <span>{t("period")}</span>
                    <span className={styles.PeriodYear}>{taxYear}</span>
                </span>
            </div>
            {docCode?.toLowerCase() === "sr" && (
                <div className={styles.Status}>
                    <span>
                        {t("PROCESSING_STATUS")}
                    </span>
                    <span onClick={() => showMoreSR?.()}>
                        {t('show_more')}
                    </span>
                </div>
            )}
            <div className={styles.ActionsGroup}>
                <span className={styles.Declaration}>
                    <span>{t("declaration")}</span>
                    <span>
                        <Button variant="link" onClick={() => downloadDeclaration?.({ dln })}>
                            <Icons.PDF />
                        </Button>
                    </span>
                </span>
                <span className={styles.Receipt}>
                    <span>{t("Received")}</span>
                    <span>
                        <Button variant="link" onClick={() => downloadReceived?.({ dln })}>
                            <Icons.PDF />
                        </Button>
                    </span>
                </span>
            </div>
        </div>
    )
};

export interface PaymentCardProps {
    orderNumber: string;
    createDate: string;
    pin: string;
    paymentTypeName: string;
    amount: string;
    statusName: string;
    descriptionName: string;
    account: string;
    downloadPDF?: CallableFunction;
    status?: number;
}

export const PaymentCard: React.FC<PaymentCardProps> = (props) => {
    const { t } = useTranslation();

    const csStatus = classnames({
        [styles.Status]: true,
        [styles.Success]: props.status === 4,
        [styles.Rejected]: props.status === 5,
        [styles.Processing]: ![4, 5].includes(props.status ?? 0),
    });

    return (
        <div className={styles.PaymentCard}>
            <div className={styles.Title}>
                <span>
                    <span>{t("PAYMENTS_ORDER_PAYMENT")}</span>
                    <span>{props.orderNumber}</span>
                </span>
                <span className={csStatus}>{props.statusName}</span>
            </div>
            <div className={styles.PaymentDetails}>
                <span>
                    <span>{t("PAYMENTS_PIN")}</span>
                    <span>{props.pin}</span>
                </span>
                <span>
                    <span>{props.createDate}</span>
                    <span>
                        {`${!Number.isNaN(parseFloat(props.amount)) ? formatNumberWithCommas(props.amount) : props.amount} ${t("dram")}`}
                    </span>
                </span>
            </div>
            <div className={styles.Description}>
                {props.descriptionName}
            </div>
            <div className={styles.ActionsGroup}>
                <Button variant="link" onClick={() => props.downloadPDF?.(props)}>
                    <Icons.PDF />
                </Button>
            </div>
        </div>
    )
};
