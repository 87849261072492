import armFlag from "../assets/images/arm-flag.png";
import rurFlag from "../assets/images/rur-flag.png";
import engFlag from "../assets/images/eng-flag.png";

export interface ILanguage {
    code: string;
    title: string;
    shortTitle: string;
    isDefault: boolean;
    flag: string
}

export const languages: ILanguage[] = [
    {
        code: 'am',
        title: 'Հայերեն',
        shortTitle: 'Հայ',
        isDefault: true,
        flag: armFlag
    },
    {
        code: 'ru',
        title: 'Русский',
        shortTitle: 'Рус',
        isDefault: false,
        flag: rurFlag
    },
    {
        code: 'en',
        title: 'English',
        shortTitle: 'Eng',
        isDefault: false,
        flag: engFlag
    }
];
