import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from '../../hook';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    checkConfirmationCode,
    Exception,
    ExceptionType,
    IRegistrationDTO,
    IRegistrationFieldsErrorsDefault,
    IRegistrationFormData,
    registerUser,
    registrationFieldsErrorsDefault,
    registrationFormDataDefault,
    passwordRegexp,
    usernameRegexp,
    tinRegexp,
    translationKeysByErrorCodes
} from '../../utils';
import { Button } from '../../components/Button';
import { Container } from '../../Layouts/Container';
import { Input } from '../../components/Input';
import { logoutAuth } from '../../store/authSlice';
import styles from "./styles.module.scss";

export const path: string = 'registration/:verificationToken?';

export default function Registration() {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState<IRegistrationFormData>(registrationFormDataDefault);
    const [verificationTokenError, setVerificationTokenError] = useState<string>('');
    const [fieldsErrorMessage, setFieldsErrorMessage] = useState<Partial<IRegistrationFieldsErrorsDefault>>(registrationFieldsErrorsDefault);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    useEffect(() => {
        if (params.verificationToken) {
            checkConfirmationCode(params.verificationToken)
                .then(res => {
                    setFormData(prev => ({ ...prev, verificationTin: res.data?.tin || '' }));
                })
                .catch(err => {
                    setVerificationTokenError(translationKeysByErrorCodes(err));
                });
        }

    }, [params.verificationToken]);

    const handleInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>, key: Exclude<keyof IRegistrationFormData, 'verificationTin'>) => {
        setFormData(prev => ({ ...prev, [key]: ev.target.value }));
    }, []);

    const Registration = async () => {
        setIsBusy(true);
        setFieldsErrorMessage(registrationFieldsErrorsDefault);
        try {
            let errorFields: Partial<IRegistrationFieldsErrorsDefault> = {};
            if (!formData.tin || !formData.username || !formData.password || !formData.confirmPassword) {
                if (!formData.tin) errorFields.tin = 'FIELD_NOT_SET';
                if (!formData.username) errorFields.username = 'FIELD_NOT_SET';
                if (!formData.password) errorFields.password = 'FIELD_NOT_SET';
                if (!formData.confirmPassword) errorFields.confirmPassword = 'FIELD_NOT_SET';
                throw new Exception(JSON.stringify(errorFields), ExceptionType.frontend);
            }
            if (!formData.tin.match(tinRegexp)) {
                errorFields.tin = 'INVALID_TIN';
                throw new Exception(JSON.stringify(errorFields), ExceptionType.frontend);
            }
            if (formData.tin !== formData.verificationTin) {
                errorFields.tin = 'INVALID_TIN_BY_VERIFICATION';
                throw new Exception(JSON.stringify(errorFields), ExceptionType.frontend);
            }
            if (!formData.username.match(usernameRegexp)) {
                let msg = 'INVALID_USERNAME';
                if (formData.username.length < 3 || formData.username.length > 20) {
                    msg = 'INVALID_USERNAME_BY_LENGTH';
                }
                errorFields.username = msg;
                throw new Exception(JSON.stringify(errorFields), ExceptionType.frontend);
            }
            if (!formData.password.match(passwordRegexp) || formData.password.match(' ')) {
                errorFields.password = 'INVALID_PASSWORD';
                throw new Exception(JSON.stringify(errorFields), ExceptionType.frontend);
            }
            if (formData.password !== formData.confirmPassword) {
                errorFields.confirmPassword = 'PASSWORDS_NOT_EQUAL';
                throw new Exception(JSON.stringify(errorFields), ExceptionType.frontend);
            }
            if (!params.verificationToken) {
                errorFields.generalError = 'INVALID_CONFIRMATION_CODE';
                throw new Exception(JSON.stringify(errorFields), ExceptionType.frontend);
            }
            const sendData: IRegistrationDTO = {
                confirmationCode: params.verificationToken,
                password: formData.password,
                username: formData.username,
                tin: formData.tin,
            }
            await registerUser(sendData).catch(err => {
                let errorPositionKey = '';
                switch (err.response.data.message) {
                    case 'USERNAME_ALREADY_EXISTS':
                        errorPositionKey = 'username';
                        break;
                    case 'INVALID_TIN':
                        errorPositionKey = 'tin';
                        break;
                    case 'INVALID_PASSWORD':
                        errorPositionKey = 'password';
                        break;
                    case 'PASSWORDS_NOT_EQUAL':
                        errorPositionKey = 'confirmPassword';
                        break;
                    default:
                        errorPositionKey = 'generalError';
                }
                // @ts-ignore
                errorFields[errorPositionKey] = translationKeysByErrorCodes(err);
                throw new Exception(JSON.stringify(errorFields), ExceptionType.backend);
            });
            setFormData(prev => ({ ...prev, tin: '', username: '', password: '', confirmPassword: '' }));
            dispatch(logoutAuth());
            navigate('/sign-in');
        } catch (err) {
            if (err instanceof Exception) {
                const msgs = err.getObjectMessage();
                setFieldsErrorMessage(msgs);
            }
        } finally {
            setIsBusy(false);
        }
    };

    if (!params.verificationToken || (!formData.verificationTin && !verificationTokenError)) return null;

    return (
        <section className={styles.RegistrationPageWrapper}>
            <Container 
                title={t("appName")}
                containerSize='middle' 
                className={styles.RegistrationPage}
            >
                <div className={styles.RegistrationContent}>
                    {!verificationTokenError && <p className={styles.HintText}>{t("create_username_and_password")}</p>}
                    <div className={styles.Form}>
                        {verificationTokenError ? (
                            <div className="alert-danger">{t(verificationTokenError)}</div>
                        ) : (
                            <>
                                <Input
                                    placeholder={t("tin")}
                                    inputSize='large'
                                    value={formData.tin}
                                    onChange={ev => handleInput(ev, 'tin')}
                                    errorMessage={t(fieldsErrorMessage.tin ?? "")}
                                    autoComplete='off'
                                />
                                <Input
                                    placeholder={t("username")}
                                    inputSize='large'
                                    value={formData.username}
                                    onChange={ev => handleInput(ev, 'username')}
                                    errorMessage={t(fieldsErrorMessage.username ?? "")}
                                    autoComplete='off'
                                />
                                <Input
                                    type="password"
                                    placeholder={t("password")}
                                    inputSize='large'
                                    value={formData.password}
                                    onChange={ev => handleInput(ev, 'password')}
                                    errorMessage={t(fieldsErrorMessage.password ?? "")}
                                    autoComplete='new-password'
                                />
                                <Input
                                    type="password"
                                    placeholder={t("confirmPassword")}
                                    inputSize='large'
                                    value={formData.confirmPassword}
                                    onChange={ev => handleInput(ev, 'confirmPassword')}
                                    errorMessage={t(fieldsErrorMessage.confirmPassword ?? "")}
                                    autoComplete="new-password"
                                />
                                <Button
                                    disabled={isBusy}
                                    onClick={isBusy ? () => null : Registration}
                                    className={styles.RegistrationBTN}
                                >
                                    {t('register')}
                                </Button>
                                {fieldsErrorMessage.generalError && <div className="alert-danger">{t(fieldsErrorMessage.generalError)}</div>}
                            </>
                        )}
                    </div>
                </div>
            </Container>
        </section>
    );
}
