import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { DashboardLayout } from '../../Layouts/DashboardLayout';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '../../Layouts/Container';
import avatar from "../../assets/images/user.png"
import styles from "./styles.module.scss"
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { Modal } from '../../components/Modal';
import { Pen as IconPen } from '../../components/Icons';
import { toast } from 'react-toastify';
import {
    classnames,
    getProfile,
    changePasswordDataDefault,
    passwordRegexp,
    passwordChanged,
    profileBankDetailsDefault,
    bankAccountRegexp,
    isValidEmail,
    phoneRegexp,
    updateProfileDetails,
    updateDefaultLanguage,
    languages,
    isResidentUser,
    isNotResidentUser
} from '../../utils';
import { Wrapper } from '../../Layouts/Wrapper';
import { initProfile } from '../../store/profileSlice';
import { fetchDictionaries } from '../../store/dictionarySlice';
import { useNavigate } from '../../hook';

export const path: string = 'profile';

const bankAccountPartSize = 5;

const detailsMapper = ["psn", "documentTypeId", "documentNumber", "entType", "tinStatus", "tin", "login"];

export default function Profile() {
    const { role } = useSelector((state: any) => state.auth);
    const dictionary = useSelector((state: any) => state.dictionary);
    const { banksNames } = dictionary;
    const [loading, setLoading] = useState(false);
    const profile = useSelector((state: any) => state.profile);
    const { t, i18n } = useTranslation();
    const [changePasswordData, setChangePasswordData] = useState(changePasswordDataDefault);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [bankDetails, setBankDetails] = useState(profileBankDetailsDefault);
    const [isBusyBankDetails, setIsBusyBankDetails] = useState<boolean>(false);
    const [defaultLanguageOpen, setDefaultLanguageOpen] = useState<boolean>(false);
    const [defaultLanguageError, setDefaultLanguageError] = useState<string>('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isLoading = loading || dictionary.isRequest;

    const csSkeleton = classnames({
        skeleton: isLoading
    });

    const lngShort: Partial<{ shortTitle: string, code: string }> = useMemo(() => {
        let lng = profile.defaultLanguage || 'AM';
        lng = lng.toLowerCase();
        const currentLang = languages.find(item => item.code === lng);
        return currentLang || {};
    }, [profile.defaultLanguage]);

    const toggleDefaultLanguageOpen = useCallback(() => setDefaultLanguageOpen(prev => !prev), []);

    const changeDefaultLanguage = async (ev: React.ChangeEvent<HTMLSelectElement>) => {
        setDefaultLanguageError('');
        const lang = ev.target.value.toUpperCase();
        updateDefaultLanguage(lang)
            .then(result => {
                if (result?.data?.value) {
                    initProfileDetails();
                }
            })
            .catch(err => {
                setDefaultLanguageError(err.response?.data?.message);
            })
            .finally(() => toggleDefaultLanguageOpen());
    }

    const dataKeys = Object.keys(profile);

    const handleEditBankDetails = async () => {
        setIsBusyBankDetails(true);
        try {
            setBankDetails(prev => ({
                ...prev,
                bankAccount: { ...prev.bankAccount, error: '' },
                email: { ...prev.email, error: '' },
                phone: { ...prev.phone, error: '' }
            }));
            let hasError = false;
            if (!bankDetails.bankName.value && isResidentUser(role)) {
                setBankDetails(prev => ({
                    ...prev,
                    bankName: { ...prev.bankName, error: 'FIELD_NOT_SET' }
                }));
                hasError = true;
            }
            if (!bankDetails.bankAccount.value && isResidentUser(role)) {
                setBankDetails(prev => ({
                    ...prev,
                    bankAccount: { ...prev.bankAccount, error: 'FIELD_NOT_SET' }
                }));
                hasError = true;
            }
            if (!bankDetails.email.value) {
                setBankDetails(prev => ({
                    ...prev,
                    email: { ...prev.email, error: 'FIELD_NOT_SET' }
                }));
                hasError = true;
            }
            if (!bankDetails.phone.value) {
                setBankDetails(prev => ({
                    ...prev,
                    phone: { ...prev.phone, error: 'FIELD_NOT_SET' }
                }));
                hasError = true;
            }
            if (hasError) {
                throw new Error('');
            }
            if (!bankAccountRegexp.test(bankDetails.bankAccount.value) && isResidentUser(role)) {
                setBankDetails(prev => ({
                    ...prev,
                    bankAccount: { ...prev.bankAccount, error: 'INVALID_INPUT' }
                }));
                hasError = true;
            }
            if (!isValidEmail(bankDetails.email.value)) {
                setBankDetails(prev => ({
                    ...prev,
                    email: { ...prev.email, error: 'INVALID_INPUT' }
                }));
                hasError = true;
            }
            if (!bankDetails.phone.value.match(phoneRegexp)) {
                setBankDetails(prev => ({
                    ...prev,
                    phone: { ...prev.phone, error: 'INVALID_INPUT' }
                }));
                hasError = true;
            }
            if (hasError) {
                throw new Error('');
            }
            const send: any = {
                email: bankDetails.email.value,
                phone: `+(374)${bankDetails.phone.value}`,
            };
            if (isResidentUser(role)) {
                send.bankAccount = bankDetails.bankAccount.value;
            }
            await updateProfileDetails(send)
                .then(() => {
                    toast.success(t('successfullyUpdated'), { position: 'top-right', theme: 'colored' });
                })
                .catch(err => {
                    throw new Error(err.response?.data?.message || 'FAILURE')
                });
        } catch (err: any) {
            if (err instanceof Error && err.message) {
                toast.error(t(err.message), { position: 'top-right', theme: 'colored' });
            }
        } finally {
            setIsBusyBankDetails(false);
        }
    };

    const toggleChangePasswordModalOpen = useCallback(() => {
        setChangePasswordData(changePasswordDataDefault);
        setChangePasswordModalOpen(prev => !prev);
    }, []);

    const handleChangePasswordInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setChangePasswordData((prevState) => ({ ...prevState, [name]: { ...prevState[name as keyof typeof prevState], value }}))
    }, []);

    const sentChangePassword = async () => {
        try {
            setIsBusy(true);
            setChangePasswordData(prev => ({
                ...prev,
                request: { ...prev.request, errorMessage: '' },
                currentPassword: { ...prev.currentPassword, error: '' },
                newPassword: { ...prev.newPassword, error: '' },
                confirmNewPassword: { ...prev.confirmNewPassword, error: '' }
            }));
            let hasError = false;
            if (!changePasswordData.currentPassword.value) {
                setChangePasswordData(prev => ({ ...prev, currentPassword: { ...prev.currentPassword, error: t('FIELD_NOT_SET') } }));
                hasError = true;
            }
            if (!changePasswordData.newPassword.value) {
                setChangePasswordData(prev => ({ ...prev, newPassword: { ...prev.newPassword, error: t('FIELD_NOT_SET') } }));
                hasError = true;
            }
            if (!changePasswordData.confirmNewPassword.value) {
                setChangePasswordData(prev => ({ ...prev, confirmNewPassword: { ...prev.confirmNewPassword, error: t('FIELD_NOT_SET') } }));
                hasError = true;
            }
            if (hasError) {
                throw new Error('');
            }
            if (!changePasswordData.newPassword.value.match(passwordRegexp) || changePasswordData.newPassword.value.match(' ')) {
                setChangePasswordData(prev => ({ ...prev, newPassword: { ...prev.newPassword, error: t('INVALID_PASSWORD') } }));
                hasError = true;
            }
            if (!changePasswordData.confirmNewPassword.value.match(passwordRegexp) || changePasswordData.confirmNewPassword.value.match(' ')) {
                setChangePasswordData(prev => ({ ...prev, confirmNewPassword: { ...prev.confirmNewPassword, error: t('INVALID_PASSWORD') } }));
                hasError = true;
            }
            if (changePasswordData.newPassword.value !== changePasswordData.confirmNewPassword.value) {
                setChangePasswordData(prev => ({ ...prev, confirmNewPassword: { ...prev.confirmNewPassword, error: t('PASSWORDS_NOT_EQUAL') } }));
                hasError = true;
            }
            if (hasError) {
                throw new Error('');
            }
            const send = {
                newPassword: changePasswordData.newPassword.value,
                oldPassword: changePasswordData.currentPassword.value
            }
            await passwordChanged(send)
                .then(() => {
                    setChangePasswordData(prev => ({ ...prev, request: { ...prev.request, isSent: true } }));
                })
                .catch(er => {
                    const msg = er.response?.data?.message || 'FAILURE';
                    throw new Error(msg);
                });
        } catch (er: any) {
            if (er instanceof Error && er.message) {
                if (er.message === 'SAME_PASSWORD') {
                    setChangePasswordData(prev => ({ ...prev, newPassword: { ...prev.newPassword, error: t(er.message) } }));
                } else if (er.message === 'INVALID_OLD_PASSWORD') {
                    setChangePasswordData(prev => ({ ...prev, currentPassword: { ...prev.newPassword, error: t(er.message) } }));
                } else {
                    setChangePasswordData(prev => ({ ...prev, request: { ...prev.request, errorMessage: t(er.message) } }));
                }
            }
        } finally {
            setIsBusy(false);
        }
    }

    const handleInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>,) => {
        const { name, value, pattern } = ev.target;
        if (value && pattern && !(new RegExp(pattern).test(value))) {
            return;
        }
        setBankDetails(prev => ({ ...prev, [name]: { ...prev[name as keyof typeof prev], value, error: '' } }));
    }, []);

    const getTin = useCallback(() => {
        if (isResidentUser(role) && !profile.tin) {
            navigate('/receive-tin');
        }
    }, [role, profile.tin]);

    const initProfileDetails = () => {
        setLoading(true);
        return getProfile()
                .then(res => {
                    dispatch(initProfile(res.data));
                    setBankDetails(prev => ({
                        ...prev,
                        bankAccount: { ...prev.bankAccount, value: res.data?.bankAccount },
                        email: { ...prev.email, value: res.data?.email },
                        phone: { ...prev.phone, value: res.data?.phone?.replace?.("+(374)", "") || '' },
                    }));
                })
                .catch(err => console.log(err))
                .finally(() => setTimeout(() => setLoading(false), 1000));
    };

    const initProfilePage = () => {
        initProfileDetails();
        dispatch(fetchDictionaries({ dictArray: ["banksNames", "documentTypes"] }));
    }

    useEffect(() => {
        initProfilePage();
    }, []);

    useEffect(() => {
        setBankDetails(prev => ({
            ...prev,
            bankName: { ...prev.bankName, value: '', error: '' }
        }));
        let bankAccountPart = bankDetails?.bankAccount?.value?.slice(0, bankAccountPartSize) || [];
        if (bankAccountPart.length === bankAccountPartSize) {
            bankAccountPart = `AM${bankAccountPart}`;
            const selectedBank = banksNames.data?.[bankAccountPart]?.[`name_${i18n.language}`];
            if (selectedBank) {
                setBankDetails(prev => ({
                    ...prev,
                    bankName: { ...prev.bankName, value: selectedBank }
                }));
            }
        }
    }, [bankDetails?.bankAccount?.value, i18n.language, banksNames]);

    return (
        <DashboardLayout className={styles.Profile}>
            <Container containerSize='large' className={styles.ProfileContainer} withoutPadding>
                <Wrapper title={t("personalData")}>
                    <div className={styles.UserDetails}>
                        <div className={`${styles.Left} ${csSkeleton}`}>
                            {!isLoading && (
                                <>
                                    <div className={styles.AvatarWrapper}>
                                        <img src={avatar} alt='avatar' className={styles.Avatar} />
                                    </div>
                                    <p>
                                        {profile.lastName} {profile.name}
                                        <br />
                                        {profile.middleName}
                                    </p>
                                </>
                            )}
                        </div>
                        <div className={`${styles.Right} ${csSkeleton}`}>
                            {!isLoading && (
                                <>
                                    <div className={styles.Rows}>
                                        {detailsMapper.map((key: string) => {
                                            let trKey;
                                            let field = key;
                                            switch (key) {
                                                case 'login':
                                                    trKey = 'username';
                                                    break;
                                                case 'documentNumber':
                                                    trKey = 'documentNumber2';
                                                    break;
                                                case 'psn':
                                                    if (!isResidentUser(role)) {
                                                        field = 'noResidentPsn';
                                                    }
                                                    trKey = key;
                                                    break;
                                                case 'tinStatus':
                                                    trKey = 'status';
                                                    break;
                                                default:
                                                    trKey = key;
                                            }
                                            return dataKeys.includes(field) ? (
                                                <div className={styles.Row} key={field}>
                                                    <span>{t(trKey)}</span>
                                                    <span>
                                                        {(() => {
                                                            switch (field) {
                                                                case 'entType':
                                                                    return profile[`entTypeName_${i18n.language}`];
                                                                case 'tinStatus':
                                                                    return profile[`tinStatusName_${i18n.language}`];
                                                                case 'documentTypeId':
                                                                    return profile[`documentType_${i18n.language}`];
                                                                default:
                                                                    return profile[field] || '--';
                                                            }
                                                        })()}
                                                    </span>
                                                </div>
                                            ) : null;
                                        })}
                                        {lngShort.shortTitle && (
                                            <div className={styles.Row}>
                                                <span>{t("defaultLanguage")}</span>
                                                <span>
                                                    <span>{lngShort.shortTitle}</span>
                                                    &nbsp;&nbsp;
                                                    {defaultLanguageOpen ? (
                                                        <select value={lngShort.code} onChange={changeDefaultLanguage}>
                                                            {languages.map(lang => (
                                                                <option key={lang.code} value={lang.code}>{lang.shortTitle}</option>
                                                            ))}
                                                        </select>
                                                    ) : (
                                                        <span onClick={toggleDefaultLanguageOpen}><IconPen /></span>
                                                    )}
                                                    &nbsp;&nbsp;&nbsp;
                                                    {defaultLanguageError && <span className="errorMessage">{defaultLanguageError}</span>}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    {!profile.tin && isResidentUser(role) && (
                                        <div className={styles.ActionsRow}>
                                            <Button size='small' onClick={getTin}>{t("getTin")}</Button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {isNotResidentUser(role) && (
                        <div className={styles.ActionsGroup}>
                            <Button variant="link" className={styles.ChangePassBTN} onClick={toggleChangePasswordModalOpen}>{isLoading ? "" : t("changePassword")}</Button>
                        </div>
                    )}
                    <Modal
                        title={t(changePasswordData.request.isSent ? 'confirmation': 'changePassword')}
                        isOpen={changePasswordModalOpen}
                        onClose={toggleChangePasswordModalOpen}
                        size="s-fit-content"
                        closeMessage={t('close')}
                        footerExtraComponents={(
                            <Button size="s" color="primary" onClick={isBusy ? () => null : sentChangePassword} disabled={isBusy}>
                                {t('confirm')}
                            </Button>
                        )}
                        footerExists={!changePasswordData.request.isSent}
                        mode='head-colored'
                        type={changePasswordData.request.isSent ? "success" : undefined}
                    >
                        <>
                            {changePasswordData.request.isSent ? (
                                <div className={styles.ResetPassSuccessfully}>
                                    <p>{t('changePasswordSuccessfully')}</p>
                                </div>
                            ) : (
                                <>
                                    <div className={styles.changePasswordInput}>
                                        <Input
                                            label={t("currentPassword")}
                                            inputSize='middle'
                                            name='currentPassword'
                                            type='password'
                                            value={changePasswordData.currentPassword.value}
                                            errorMessage={changePasswordData.currentPassword.error}
                                            onChange={handleChangePasswordInput}
                                            autoComplete="new-password"
                                        />
                                    </div>
                                    <div className={styles.changePasswordInput}>
                                        <Input
                                            label={t("newPassword")}
                                            inputSize='middle'
                                            name='newPassword'
                                            type='password'
                                            value={changePasswordData.newPassword.value}
                                            errorMessage={changePasswordData.newPassword.error}
                                            onChange={handleChangePasswordInput}
                                            autoComplete="new-password"
                                        />
                                    </div>
                                    <div className={styles.changePasswordInput}>
                                        <Input
                                            label={t("confirmNewPassword")}
                                            inputSize='middle'
                                            name='confirmNewPassword'
                                            type='password'
                                            value={changePasswordData.confirmNewPassword.value}
                                            errorMessage={changePasswordData.confirmNewPassword.error}
                                            onChange={handleChangePasswordInput}
                                            autoComplete="new-password"
                                        />
                                    </div>
                                    {changePasswordData.request.errorMessage && (
                                        <div className="text-center errorMessage error-set-pass">{changePasswordData.request.errorMessage}</div>
                                    )}
                                </>
                            )}
                        </>
                    </Modal>
                    <div className={classnames({[styles.BankDetails]: true, [styles.NoneTin]: profile.userTypeName !== "NO_RESIDENT", skeleton: isLoading })}>
                        {!isLoading && (
                            <>
                                {isResidentUser(role) && (
                                    <>
                                        <Input
                                            inputSize='small'
                                            label={t("bankName")}
                                            value={bankDetails.bankName.value}
                                            onChange={() => null}
                                            errorMessage={bankDetails.bankName.error ? t(bankDetails.bankName.error) : ''}
                                            disabled
                                        />
                                        <Input
                                            inputSize='small'
                                            label={t("bankAccNo")}
                                            value={bankDetails.bankAccount.value}
                                            errorMessage={bankDetails.bankAccount.error ? t(bankDetails.bankAccount.error) : ''}
                                            name="bankAccount"
                                            onChange={handleInput}
                                        />
                                    </>
                                )}
                                <Input
                                    inputSize='small'
                                    label={t("emailAddress")}
                                    value={bankDetails.email.value}
                                    name="email"
                                    onChange={handleInput}
                                    errorMessage={bankDetails.email.error ? t(bankDetails.email.error) : ''}
                                />
                                <Input
                                    inputSize='small'
                                    label={t("phoneNumber")}
                                    value={bankDetails.phone.value?.replace?.("+(374)", "")}
                                    name="phone"
                                    pattern="^[0-9]{1,8}$"
                                    onChange={handleInput}
                                    errorMessage={bankDetails.phone.error ? t(bankDetails.phone.error) : ''}
                                    withMobileCode
                                />
                                <div className={styles.EditBankDetails}>
                                    <Button
                                        onClick={isBusyBankDetails ? () => null : handleEditBankDetails}
                                        size='small'
                                        disabled={isBusyBankDetails}
                                    >
                                        {isBusyBankDetails ? t("wait") + ' ...' : t("confirm")}
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </Wrapper>
            </Container>
        </DashboardLayout>
    );
}
