import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from '../../store';
import { Wrapper } from '../../Layouts/Wrapper';
import { Input } from '../Input';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { DatePicker } from '../DatePicker';
import { Accordion } from '../Accordion';
import { Table } from '../Table';
import { NotificationDialog } from '../NotificationDialog';
import { ResidentReasonTypesDialog } from '../ResidentReasonTypesDialog';
import { ConfirmDialog } from '../ConfirmDialog';
import {
    classnames,
    isResidentUser,
    getProfile,
    incoming_tax_social_payment,
    section1Head1TypesOfIncome,
    section1Head2TypesOfIncome,
    section2Rows,
    section3Rows,
    section5Rows,
    I_section1_1_employerDataList,
    setValueInTreeObject,
    IhandleArgs,
    getTaxpayers,
    getSection1Head1,
    getSection1Head2,
    getEmployeeIncomeTypes,
    getSection3,
    getSection4,
    getSection5Point5_3,
    getSection5Point5_4And5_5,
    createDeclSDDraft,
    updateDeclSDDraftByID,
    getDeclSDDraftByID,
    getDeclSDSubmitByID,
    getSendData,
    initializeStateByIDData,
    createDeclSDSubmit,
    translationKeysByErrorCodes,
    formatNumberWithCommas
} from '../../utils';
import styles from './styles.module.scss';
import { Select } from "../Select";
import Loading from '../Loading';
import * as Icons from "../Icons";
import { useNavigate } from '../../hook';
import { Checkbox } from '../Checkbox';
import { Tooltip } from '../Tooltip';

const IncomingTaxForm: React.FC = () => {
    const { t , i18n} = useTranslation();
    const auth: any = useSelector((state: RootState) => state.auth);
    const { id, type, declStatus } = useParams();
    const navigation = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [automaticallyAdjustedWarning, setAutomaticallyAdjustedWarning] = useState<string | null>(null)
    const [isOpenReasonTypes, setIsOpenReasonTypes] = useState<{ isOpen: boolean, selectedYear?: number | string }>({ isOpen: false, selectedYear: undefined });
    const [approvalReasonTypes, setApprovalReasonTypes] = useState<{ isOpen: boolean, reasonPresent?: boolean, reasonCodes?: string[] }>({ isOpen: false });
    const [saveProcess, setSaveProcess] = useState<boolean>(false);
    const [state, setState] = useState<any>(incoming_tax_social_payment);
    const [newTaxAgent, setNewTaxAgent] = useState<{ value: string, label: string } | null>(null);
    const [newTaxAgent2, setNewTaxAgent2] = useState<{ value: string, label: string } | null>(null);
    const [sec1_2Revenue, setSec1_2Revenue] = useState<{ value: string, label: string }[] | null>(null);
    const [searchTin1, setSearchTin1] = useState<string>('-');
    const [searchTin2, setSearchTin2] = useState<string>('-');
    const [newTaxAgentsList, setNewTaxAgentsList] = useState<{ value: string, label: string }[]>([]);
    const [newTaxAgentsList2, setNewTaxAgentsList2] = useState<{ value: string, label: string }[]>([]);
    const [sec1_2Revenues, setSec1_2Revenues] = useState<{ value: string, label: string }[]>([]);
    const [sec1_2incomeTypes, setSec1_2incomeTypes] = useState<any[]>([]);
    const [_existsAgentByTypes, setExistsAgentByTypes] = useState<any[]>([]);
    const [isOpenYearApproval, setIsOpenYearApproval] = useState<{ isOpen: boolean, selectedYear?: Date }>({ isOpen: false, selectedYear: undefined });
    const [warning6_1, setWarning6_1] = useState<{ isOpen: boolean, activated: boolean }>({ isOpen: false, activated: false });
    const [notificationInfoModal, setNotificationInfoModal] = useState<{ isOpen: boolean, section1_1: boolean, section1_2: boolean }>({ isOpen: false, section1_1: false, section1_2: false });
    const [confirmAction, setConfirmAction] = useState<{ isOpen: boolean, messageKey: string, approve: Function }>({ isOpen: false, messageKey: '', approve: () => {} });
    const [sectionsErrors, setSectionsErrors] = useState({
                                                            'main': [] as string[],
                                                            '1': [] as string[],
                                                            '2': [] as string[],
                                                            '3': [] as string[],
                                                            '4': [] as string[],
                                                            '5': [] as string[],
                                                            '6': [] as string[],
                                                            type: '',
                                                            errorPart: '',
                                                            message: ''
                                                        });
    const [backendValidationError, setBackendValidationError] = useState<string[]>([]);
    const [isCorrectedFields, setIsCorrectedFields] = useState<boolean>(false);

    const toggleIsOpenReasonTypes = (selectedYear: string | number) =>
                                        setIsOpenReasonTypes((prev: any) => ({ ...prev, isOpen: !prev.isOpen, selectedYear }));

    const handleSectionsErrors = (id: string, section: '1' | '2' | '3' | '4' | '5' | '6' | 'main', add: boolean = true) =>
                                    setSectionsErrors(prev => ({ ...prev, [section]: !add ? prev[section].filter(item => item !== id) : [...prev[section], id] }));

    const selfEmployeeIncomeFocus = () => {
        if (!warning6_1.activated) {
            setWarning6_1({ isOpen: true, activated: true });
        }
    }

    const selfEmployeeIncomeFocusDialogClose = () => {
        setWarning6_1(prev => ({ ...prev, isOpen: false }));
        window.document.getElementById('section6.selfEmployeeIncome')?.focus();
    }

    const validateAllFields = (result: any) => {
        if (result.section7_1?.employerDataList) {
            let i = 0;
            for (const item of result.section7_1?.employerDataList) {
                validateField(result, `section7_1.employerDataList.${i}.salary`);
                i += 1;
            }
        }
        const { totalTax: _totalTax, totalIncome: _totalIncome, ...rest7_2 } = result.section7_2 || {};
        const keys7_2 = Object.keys(rest7_2);
        for (const key of keys7_2) {
            if (result.section7_2?.[key]) {
                let i = 0;
                for (const item of result.section7_2?.[key]) {
                    validateField(result, `section7_2.${key}.${i}.incomeAmount`);
                    i += 1;
                }
            }
        }

        const allRowsTaxes = section3Rows.flatMap((item: any) => item.rows).filter(item => item.sumTaxes);
        for (const item of allRowsTaxes) {
            validateField(result, `section3.${item.income}`);
        }
        validateField(result, 'section3.salaryTaxableIncomeReduce');
        validateField(result, 'section4');
        validateField(result, 'section5.employerPaidTax');
        validateField(result, 'section5.employerReduceAmount');
        validateField(result, 'section5.invoiceReturnAmount');
        validateField(result, '5_9&5_10');
        validateField(result, 'section6.selfEmployeeIncome');
        validateField(result, 'taxpayerPhone');
    }

    useEffect(() => {
        setIsLoading(true);
        let declarationRequest = null;
        if (id) {
            let action;
            switch (declStatus) {
                case 'filled':
                    action = getDeclSDSubmitByID;
                    break;
                default:
                    action = getDeclSDDraftByID;
            }
            declarationRequest = action(id);
        } else {
            declarationRequest = getProfile({ withAddress: true });
        }
        declarationRequest.then(res => {
                setState((prevState: any) => {
                    const result = {
                        ...prevState,
                        taxpayerName: {
                            ...prevState.taxpayerName,
                            value: id ? res.data.taxpayerName : [res.data.lastName, res.data.name, res.data.middleName].filter((item?: string) => !!item).join(' ')
                        },
                        psn: { ...prevState.psn, value: res.data[!isResidentUser(auth.role) && !id ? 'noResidentPsn' : 'psn'] || '' },
                        tin: { ...prevState.tin, value: res.data.tin || '' },
                        physSocParticipationType: { ...prevState.physSocParticipationType, value: res.data[id ? 'physSocParticipationType' : 'socParticipationType'] || '' },
                        taxpayerAddress: { ...prevState.taxpayerAddress, value: res.data[id ? 'taxpayerAddress' : 'address'] || '' },
                        taxpayerPhone: { ...prevState.taxpayerPhone, value: res.data[id ? 'taxpayerPhone' : 'phone']?.replace('+(374)', '') || '' },
                    };
                    if (id) {
                        initializeStateByIDData(result, res.data);
                        validateAllFields(result);
                        if (res.data.decl_was_updated) {
                            toast.error(t('data_was_changed'), { position: 'top-right', theme: 'colored' });
                        }
                        getEmployeeIncomeTypes()
                            .then(r => {
                                const list = r.data?.filter((item: any) => !item.salaryRelated);
                                setSec1_2Revenues(
                                    list.map((item: any) => ({ value: item.code, label: item[`name_${i18n.language}`] }))
                                );
                                setSec1_2incomeTypes(list);
                            });
                    }
                    return result;
                });
            })
            .catch(err => {
                console.log('err >>>', err);
                if (id && err.response.status === 404) {
                    navigation('/dashboard');
                } else {
                    const error = translationKeysByErrorCodes(err);
                    if (error) {
                        toast.error(t(error), { position: 'top-right', theme: 'colored' });
                    }
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        setSec1_2Revenues(sec1_2incomeTypes.map((item: any) => ({ value: item.code, label: item[`name_${i18n.language}`] })));
        setSec1_2Revenue((prev: any) => prev?.map((item: any) => ({ ...item, label: sec1_2incomeTypes.find(item2 => item2.code === item.value)?.[`name_${i18n.language}`] })));
    }, [i18n.language, sec1_2incomeTypes]);

    const initializeByYear = (taxYear: Date, reasonPresentForResident?: boolean) => {
        setIsLoading(true);
        const year = taxYear.getFullYear();
        const promises = isResidentUser(auth.role) && reasonPresentForResident ? [
            getSection1Head1(year),
            getSection1Head2(year),
            getEmployeeIncomeTypes(),
            getSection3(year),
            getSection4(year),
            getSection5Point5_3(year),
            getSection5Point5_4And5_5(year)
        ] : [getSection3(year)];
        Promise.all(promises).then(result => {
            let section1_1List = result[0]?.data;
            let section1_2List = result[1]?.data || {};
            section1_2List.code072 = [];
            section1_2List.code082 = [];
            let revenues = result[2]?.data || [];
            const section3Data = isResidentUser(auth.role) && reasonPresentForResident ? result[3].data : result[0]?.data;
            const section4Data = result[4]?.data || {};
            const section5Point5_3Data = result[5]?.data;
            const section5Point5_4And5_5Data = result[6]?.data;
            section1_2List = Object.entries(section1_2List);
            if (section1_1List?.length) {
                section1_1List = section1_1List
                    .map((info: any) => {
                        return {
                            ...info,
                            totalIncome: (parseInt(info.salary) || 0) + (parseInt(info.civilSalary) || 0) + (parseInt(info.allBenefits) || 0) + (parseInt(info.otherTaxIncome) || 0) - (parseInt(info.amountReduce) || 0)
                        }
                    })
                    .map(
                        (info: any) =>
                            Object.entries(info).reduce((obj, item) => ({ ...obj, [item[0]]: { value: item[1], isValid: true } }), {})
                    );
            } else {
                section1_1List = [{}];
            }
            let section1_2: any = { totalIncome: { value: 0 }, totalTax: { value: 0 } };
            for (const [k, agentsList] of section1_2List) {
                section1_2[k] = [];
                for (const agentData of agentsList) {
                    section1_2[k].push({
                        tin: { value: agentData.tin },
                        taxpayerName: { value: agentData.taxpayerName},
                        incomeAmount: { value: agentData.incomeAmount },
                        taxAmount: { value: agentData.taxAmount }
                    });
                    section1_2.totalIncome.value += parseInt(agentData.incomeAmount) || 0;
                    section1_2.totalTax.value += parseInt(agentData.taxAmount) || 0;
                }
            }
            const educationalReturnAmount = (parseInt(section4Data.educationalCostAmount) || 0) <= 100000 ? (parseInt(section4Data.educationalCostAmount) || 0) : 100000;
            const healthEnsuranceReturnAmount = (parseInt(section4Data.healthEnsuranceAmount) || 0) <= 50000 ? (parseInt(section4Data.healthEnsuranceAmount) || 0) : 50000;
            setState((prev: any) => {
                const initialState = {
                    ...prev,
                    taxYear: { ...prev.taxYear, value: taxYear },
                    section1_1: {
                        employerDataList: section1_1List,
                        totalIncome: {
                            value: section1_1List.reduce((total: number, item: any) => total + (parseInt(item.totalIncome?.value) || 0), 0)
                        },
                        totalTax: {
                            value: section1_1List.reduce((total: number, item: any) => total + (parseInt(item.totalIncomeTax?.value) || 0), 0)
                        }
                    },
                    section1_2: { ...section1_2 },
                    section2: {
                        f2_1: { value: undefined },
                        f2_2: { value: undefined },
                        f2_3: { value: undefined },
                        f2_4: { value: undefined },
                        f2_5: { value: undefined },
                        f2_6: { value: undefined },
                        f2_7: { value: undefined },
                        f2_8: { value: undefined },
                        f2_9: { value: undefined },
                        f2_10: { value: undefined },
                        f2_11: { value: undefined },
                        f2_12: { value: undefined },
                        f2_13: { value: undefined },
                        f2_14: { value: undefined },
                        f2_15: { value: undefined },
                        f2_16: { value: undefined },
                        f2_17: { value: undefined },
                        f2_18: { value: undefined },
                        f2_19: { value: undefined },
                        f2_20: { value: undefined },
                        f2_21: { value: undefined },
                        f2_22: { value: undefined },
                        f2_23: { value: undefined },
                        f2_24: { value: undefined },
                        f2_25: { value: undefined },
                        f2_26: { value: undefined },
                        f2_27: { value: 0 },
                    },
                    section3: {
                        salary: { value: section3Data.salary },
                        civilContractsSalary: { value: section3Data.civilContractsSalary },
                        salaryTaxableIncomeReduce: { value: undefined },
                        salaryTaxableIncome: { value: section3Data.salaryTaxableIncome },
                        salaryTaxableRate: { value: 20 },
                        salaryTax: { value: section3Data.salaryTax },
                        royaltyIncome: { value: section3Data.royaltyIncome },
                        royaltyRate: { value: 10 },
                        royaltyTax: { value: section3Data.royaltyTax },
                        percent10Income: { value: section3Data.percent10Income },
                        percent10Rate: { value: 10 },
                        percent10Tax: { value: section3Data.percent10Tax },
                        percent20Income: { value: section3Data.percent20Income },
                        percent20Rate: { value: 20 },
                        percent20Tax: { value: section3Data.percent20Tax },
                        feeIncome: { value: section3Data.feeIncome },
                        feeRate: { value: 10 },
                        feeTax: { value: section3Data.feeTax },
                        realEstateRentIncome: { value: section3Data.realEstateRentIncome },
                        realEstateRentRate: { value: 10 },
                        realEstateRentTax: { value: section3Data.realEstateRentTax },
                        divident5Income: { value: section3Data.divident5Income },
                        divident5Rate: { value: 5 },
                        divident5Tax: { value: section3Data.divident5Tax },
                        divident10Income: { value: section3Data.divident10Income },
                        divident10Rate: { value: 10 },
                        divident10Tax: { value: section3Data.divident10Tax },
                        propertySaleIncome: { value: section3Data.propertySaleIncome },
                        propertySaleRate: { value: 10 },
                        propertySaleTax: { value: section3Data.propertySaleTax },
                        propertyDeveloperSaleIncome: { value: section3Data.propertyDeveloperSaleIncome },
                        propertyDeveloperSaleRate: { value: 20 },
                        propertyDeveloperSaleTax: { value: section3Data.propertyDeveloperSaleTax },
                        shareInvestPropSaleIncome: { value: section3Data.shareInvestPropSaleIncome },
                        shareInvestPropSaleRate: { value: 10 },
                        shareInvestPropSaleTax: { value: section3Data.shareInvestPropSaleTax },
                        awardsIncome: { value: section3Data.awardsIncome },
                        awardsRate: { value: 20 },
                        awardsTax: { value: section3Data.awardsTax },
                        giftIncome: { value: section3Data.giftIncome },
                        giftRate: { value: 20 },
                        giftTax: { value: section3Data.giftTax },
                        unpaidPassive5Income: { value: section3Data.unpaidPassive5Income },
                        unpaidPassive5Rate: { value: 5 },
                        unpaidPassive5Tax: { value: section3Data.unpaidPassive5Tax },
                        unpaidPassive10Income: { value: section3Data.unpaidPassive10Income },
                        unpaidPassive10Rate: { value: 10 },
                        unpaidPassive10Tax: { value: section3Data.unpaidPassive10Tax },
                        unpaidPassive20Income: { value: section3Data.unpaidPassive20Income },
                        unpaidPassive20Rate: { value: 20 },
                        unpaidPassive20Tax: { value: section3Data.unpaidPassive20Tax },
                        otherTaxableIncome: { value: section3Data.otherTaxableIncome },
                        otherTaxableRate: { value: 20 },
                        otherTaxableTax: { value: section3Data.otherTaxableTax },
                        totalCalculatedTax: { value: section3Data.totalCalculatedTax },
                        otherCountriesPaidTax: { value: section3Data.otherCountriesPaidTax },
                        otherCountriesReduceTax: { value: section3Data.otherCountriesReduceTax },
                        totalTaxToPaid: { value: section3Data.totalTaxToPaid },
                    },
                    section4: {
                        educationalCostAmount: { value: section4Data.educationalCostAmount },
                        educationalReturnAmount: { value: educationalReturnAmount },
                        healthEnsuranceAmount: { value: section4Data.healthEnsuranceAmount },
                        healthEnsuranceReturnAmount: { value: healthEnsuranceReturnAmount },
                        totalCostAmount: { value: section4Data.educationalCostAmount + section4Data.healthEnsuranceAmount },
                        totalReturnAmount: { value: educationalReturnAmount + healthEnsuranceReturnAmount > 100000 ? 100000 : educationalReturnAmount + healthEnsuranceReturnAmount }
                    },
                    section5: {
                        employerPaidTax: { value: undefined },
                        hypotecReturnAmount: { value: !Number.isNaN(parseInt(section5Point5_3Data?.value)) ? parseInt(section5Point5_3Data?.value) : undefined },
                        tuitionFeeReturnAmount: { value: !Number.isNaN(parseInt(section5Point5_4And5_5Data?.studentSum)) ? parseInt(section5Point5_4And5_5Data?.studentSum) : undefined },
                        investmentReturnAmount: { value: !Number.isNaN(parseInt(section5Point5_4And5_5Data?.dividendSum)) ? parseInt(section5Point5_4And5_5Data?.dividendSum) : undefined },
                        employerReduceAmount: { value: undefined },
                        yearlyCalculatedDifference: { value: undefined },
                        invoiceReturnAmount: { value: undefined },
                        taxToPaid: { value: undefined },
                        taxToReturn: { value: undefined },
                    },
                    section6: {
                        selfEmployeeIncome: { value: undefined },
                        selfEmployeeSocRate: { value: 5 },
                        selfEmployeeSocPayment: { value: undefined }
                    },
                    section7_1: {},
                    section7_2: {}
                };
                if (isResidentUser(auth.role) && !reasonPresentForResident) {
                    initialState.section7_2_added_new_items = false;
                }
                validateField(initialState, 'section3.salary');
                const allRowsTaxes = section3Rows.flatMap((item: any) => item.rows).filter(item => item.sumTaxes);
                for (const item of allRowsTaxes) {
                    validateField(initialState, `section3.${item.income}`);
                }
                validateField(initialState, 'section5.employerPaidTax');
                validateField(initialState, 'section5.employerReduceAmount');
                validateField(initialState, 'section5.invoiceReturnAmount');
                validateField(initialState, '5_9&5_10');
                validateField(initialState, 'section6.selfEmployeeIncome');
                return initialState;
            });
            const list = revenues?.filter((item: any) => !item.salaryRelated);
            setSec1_2Revenues(list.map((item: any) => ({ ...item, value: item.code, label: item[`name_${i18n.language}`] })));
            setSec1_2incomeTypes(list);
            setNewTaxAgent2(null);
            setSec1_2Revenue(null);
            setIsLoading(false);
            setSectionsErrors(prev => ({ ...prev, type: '', message: '' }));
        });
    }

    const handleYear = useCallback((data: any) => {
        if (data?.getFullYear() === state.taxYear?.value?.getFullYear()) return;
        setIsOpenYearApproval(prev => ({ ...prev, isOpen: !!state.taxYear?.value, selectedYear: data }));
        if (!state.taxYear?.value && data) {
            // clicked first time on the date
            approveYear(undefined, data);
        }
    }, [state.taxYear?.value]);

    const closeYearApproval = useCallback(() => setIsOpenYearApproval(prev => ({ ...prev, isOpen: false, selectedYear: undefined })), []);

    const approveYear = (_ev?: any, _year?: any) => {
        const year = isOpenYearApproval.selectedYear || _year;
        closeYearApproval();
        if (!year) {
            setState((prev: any) => ({
                ...prev,
                taxYear: { ...prev.taxYear, value: null },
                section1_1: {},
                section1_2: {},
                section3: {},
                section4: {},
                section5: {},
                section6: {}
            }));
            return;
        }
        if (isResidentUser(auth.role)) {
            toggleIsOpenReasonTypes(year.getFullYear());
            setState((prev: any) => ({ ...prev, reasonPresent: undefined, reasonCodes: [] }));
            return;
        }
        initializeByYear(year);
    }

    const changeReasonTypes = (ev: any) => {
        ev.preventDefault();
        toggleIsOpenReasonTypes(state.taxYear?.value?.getFullYear());
    }

    // Հայտարարատու հանդիսանալու հիմքը - modal approve
    const approveIsOpenReasonTypes = (data: any) => {
        const isPresentReasonSelected = !data.existsNonePresent;
        if (isPresentReasonSelected !== state.reasonPresent) {
            if (isOpenReasonTypes.selectedYear === state.taxYear?.value?.getFullYear()) {
                setApprovalReasonTypes({ isOpen: true, reasonPresent: isPresentReasonSelected, reasonCodes: data.selected });
            } else {
                approveApprovalReasonTypes(undefined, isPresentReasonSelected, data.selected);
            }
        } else {
            setState((_state: any) => {
                return { ..._state, reasonPresent: isPresentReasonSelected, reasonCodes: data.selected };
            });
            toggleIsOpenReasonTypes('');
        }
        setIsOpenReasonTypes((prev: any) => ({ ...prev, isOpen: false }));
    }

    // Հայտարարատու հանդիսանալու հիմքը փոխելու դեպքում լրացված տվյալները թարմացվելու են - modal approve
    const approveApprovalReasonTypes = (_ev?: any, reasonPresent?: boolean, reasonCodes?: string[]) => {
        initializeByYear(new Date(isOpenReasonTypes.selectedYear as number, 0), reasonPresent);
        setState((_state: any) => {
            if (!reasonPresent) {
                const openedSection1 = document.querySelector('[data-accordion-id="section1"][data-isopen="true"]');
                const openedSection2 = document.querySelector('[data-accordion-id="section2"][data-isopen="true"]');
                const openedSection4 = document.querySelector('[data-accordion-id="section4"][data-isopen="true"]');
                if (openedSection1) {
                    (openedSection1.children[0] as HTMLDivElement).click();
                }
                if (openedSection2) {
                    (openedSection2.children[0] as HTMLDivElement).click();
                }
                if (openedSection4) {
                    (openedSection4.children[0] as HTMLDivElement).click();
                }
            }
            return { ..._state, reasonPresent, reasonCodes };
        });
        setApprovalReasonTypes({ isOpen: false, reasonPresent: undefined, reasonCodes: undefined });
    }

    const closeApprovalReasonTypes = () => {
        setApprovalReasonTypes({ isOpen: false, reasonPresent: undefined, reasonCodes: undefined });
        setIsOpenReasonTypes((prev: any) => ({ ...prev, isOpen: !prev.isOpen }))
    }

    const set1_2TotalTax = (_s: any) => {
        const { totalIncome: _1, totalTax: _2, ...rest } = _s.section1_2;
        const taxAgentsData = Object.values(rest).flatMap(item => item).filter((item: any) => item?.tin?.value);
        const totalTax = taxAgentsData.reduce((total: number, item: any) => total + (parseInt(item.taxAmount.value) ? parseInt(item.taxAmount.value) : 0), 0);
        setValueInTreeObject(_s, 'section1_2.totalTax', totalTax);
    }

    const set7_2TotalTax = (_s: any) => {
        const { totalIncome: _1, totalTax: _2, ...rest } = _s.section7_2;
        const taxAgentsData = Object.values(rest).flatMap(item => item).filter((item: any) => item?.tin?.value);
        const totalTax = taxAgentsData.reduce((total: number, item: any) => total + (parseInt(item.taxAmount.value) ? parseInt(item.taxAmount.value) : 0), 0);
        setValueInTreeObject(_s, 'section7_2.totalTax', totalTax);
    }

    const set5_7yearlyCalculatedDifference = (_s: any, t: number) => {
        const employerPaidTaxValue = (parseInt(_s.section1_1?.totalTax?.value) || 0) + (parseInt(_s.section1_2?.totalTax?.value) || 0);
        let employerReduceAmount = employerPaidTaxValue
            - (parseInt(_s.section5?.hypotecReturnAmount?.value) || 0)
            - (parseInt(_s.section5?.tuitionFeeReturnAmount?.value) || 0)
            - (parseInt(_s.section5?.investmentReturnAmount?.value) || 0);
        if (!isResidentUser(auth.role) || employerReduceAmount < 0) {
            employerReduceAmount = 0;
        }
        const yearlyCalculatedDifference = employerReduceAmount + t;
        setValueInTreeObject(_s, 'section5.yearlyCalculatedDifference', yearlyCalculatedDifference);
    }

    const set5_9And5_10 = (_s: any) => {
        const value5_7 = parseInt(_s.section5?.yearlyCalculatedDifference?.value) || 0;
        const value5_8 = parseInt(_s.section5?.invoiceReturnAmount?.value) || 0;
        const value5_6 = parseInt(_s.section5?.employerReduceAmount?.value) || 0;
        const value3_23 = parseInt(_s.section3?.totalTaxToPaid?.value) || 0;
        const res = value5_7 - value5_8 - (value5_6 < 0 ? 0 : value5_6);
        if (res > 0) {
            setValueInTreeObject(_s, 'section5.taxToPaid', res);
            setValueInTreeObject(_s, 'section5.taxToReturn', 0);
        } else if (res < 0) {
            setValueInTreeObject(_s, 'section5.taxToPaid', 0);
            if(value3_23 > value5_8) {
                setValueInTreeObject(_s, 'section5.taxToReturn', 0);
            }
            else {
                setValueInTreeObject(_s, 'section5.taxToReturn', Math.trunc(Math.min(value5_8 - value3_23, value5_6)));
            }
        } else {
            setValueInTreeObject(_s, 'section5.taxToPaid', 0);
            setValueInTreeObject(_s, 'section5.taxToReturn', 0);
        }
    }

    // const set6_1Error = (_s: any) => {
    //     const total = (parseInt(_s.section3?.salary?.value) || 0) + (parseInt(_s.section3?.civilContractsSalary?.value) || 0) +
    //         (parseInt(_s.section3?.royaltyIncome?.value) || 0) + (parseInt(_s.section3?.percent20Income?.value) || 0) +
    //         (parseInt(_s.section3?.percent10Income?.value) || 0) + (parseInt(_s.section3?.feeIncome?.value) || 0) + (parseInt(_s.section3?.realEstateRentIncome?.value) || 0) +
    //         (parseInt(_s.section3?.divident5Income?.value) || 0) + (parseInt(_s.section3?.divident10Income?.value) || 0) +
    //         (parseInt(_s.section3?.propertySaleIncome?.value) || 0) + (parseInt(_s.section3?.propertyDeveloperSaleIncome?.value) || 0) +
    //         (parseInt(_s.section3?.shareInvestPropSaleIncome?.value) || 0) + (parseInt(_s.section3?.awardsIncome?.value) || 0) + (parseInt(_s.section3?.giftIncome?.value) || 0) +
    //         (parseInt(_s.section3?.unpaidPassive5Income?.value) || 0) + (parseInt(_s.section3?.unpaidPassive10Income?.value) || 0) +
    //         (parseInt(_s.section3?.unpaidPassive20Income?.value) || 0) + (parseInt(_s.section3?.otherTaxableIncome?.value) || 0) +
    //         (parseInt(_s.section2?.f2_27.value) || 0);
    //     const value = parseInt(_s.section6?.selfEmployeeIncome?.value) || 0;
    //     if (value > total) {
    //         setValueInTreeObject(_s, 'section6.selfEmployeeIncome', 'error_message_in_6_1', true);
    //         handleSectionsErrors('section6.selfEmployeeIncome', '6');
    //     } else {
    //         setValueInTreeObject(_s, 'section6.selfEmployeeIncome', '', true);
    //         handleSectionsErrors('section6.selfEmployeeIncome', '6', false);
    //     }
    // }

    const set7_1totalIncomeTaxErrors = (id: string, _s: any) => {
        const idParams = id.split('.')
        const index = idParams[2];
        const idName = idParams[3];
        if (index) {
            const fields = ['salary', 'civilSalary', 'allBenefits', 'otherTaxIncome', 'amountReduce'];
            let filledSomeValue = false;
            for (const field of fields) {
                if (!Number.isNaN(parseInt(_s.section7_1?.employerDataList?.[index]?.[field]?.value))) {
                    filledSomeValue = true;
                    break;
                }
            }
            const totalIncomeTax = parseInt(_s.section7_1?.employerDataList?.[index]?.totalIncomeTax.value);
            const notVisible = fields.includes(idName);
            if (filledSomeValue && Number.isNaN(totalIncomeTax)) {
                setValueInTreeObject(_s, `section7_1.employerDataList.${index}.totalIncomeTax`, 'FIELD_NOT_SET', true, notVisible);
                handleSectionsErrors(`section7_1.employerDataList.${index}.totalIncomeTax`, '1');
            } else if (!filledSomeValue && !Number.isNaN(totalIncomeTax)) {
                setValueInTreeObject(_s, `section7_1.employerDataList.${index}.totalIncomeTax`, 'FIELD_NOT_SET_IN_1_1_3', true, notVisible);
                handleSectionsErrors(`section7_1.employerDataList.${index}.totalIncomeTax`, '1');
            } else if (!filledSomeValue && Number.isNaN(totalIncomeTax)) {
                setValueInTreeObject(_s, `section7_1.employerDataList.${index}.totalIncomeTax`, 'FIELD_NOT_SET_IN_TABLE', true, notVisible);
                handleSectionsErrors(`section7_1.employerDataList.${index}.totalIncomeTax`, '1');
            } else {
                setValueInTreeObject(_s, `section7_1.employerDataList.${index}.totalIncomeTax`, '', true, notVisible);
                handleSectionsErrors(`section7_1.employerDataList.${index}.totalIncomeTax`, '1', false);
            }
            // const totalIncome = parseInt(_s.section7_1?.employerDataList?.[index]?.totalIncome.value) || 0;
            // const totalIncomeTax = parseInt(_s.section7_1?.employerDataList?.[index]?.totalIncomeTax.value);
            // const notVisible = !(totalIncomeTax >= 0) && fields.includes(idName);
            // const percentIncomeTax10 = totalIncome ? Math.floor(totalIncome * 10 / 100) : 0;
            // const percentIncomeTax20 = totalIncome ? Math.floor(totalIncome * 20 / 100) : 0;
            // if (![percentIncomeTax10, percentIncomeTax20].includes(totalIncomeTax)) {
            //     setValueInTreeObject(_s, `section7_1.employerDataList.${index}.totalIncomeTax`, 'error_message_10_20_percent', true, notVisible);
            //     handleSectionsErrors(`section7_1.employerDataList.${index}.totalIncomeTax`, '1');
            // } else {
            //     setValueInTreeObject(_s, `section7_1.employerDataList.${index}.totalIncomeTax`, '', true, notVisible);
            //     handleSectionsErrors(`section7_1.employerDataList.${index}.totalIncomeTax`, '1', false);
            // }
        }
    }

    const validateFor072_082 = (_s: any, id: string) => {
        const idParams = id.split('.');
        const idName = idParams[3];
        const incomeIndex = idParams[2];
        const incomeTypeCode = idParams[1];
        if (incomeTypeCode === 'code082') {
            const _incomeAmount = parseInt(_s.section1_2?.[incomeTypeCode]?.[incomeIndex]?.incomeAmount.value);
            const _taxAmount = parseInt(_s.section1_2?.[incomeTypeCode]?.[incomeIndex]?.taxAmount.value);
            const incomeAmount = _incomeAmount || 0;
            const taxAmount = _taxAmount || 0;
            const perc10X = Math.floor(incomeAmount * 10 / 100);
            const perc20X = Math.floor(incomeAmount * 20 / 100);
            if (Number.isNaN(_incomeAmount) && Number.isNaN(_taxAmount)) {
                setValueInTreeObject(_s, `section1_2.${incomeTypeCode}.${incomeIndex}.taxAmount`, 'FIELD_NOT_SET', true, idName === 'incomeAmount');
                handleSectionsErrors(`section1_2.${incomeTypeCode}.${incomeIndex}.taxAmount`, '1');
                setValueInTreeObject(_s, `section1_2.${incomeTypeCode}.${incomeIndex}.incomeAmount`, 'FIELD_NOT_SET', true, idName === 'taxAmount');
                handleSectionsErrors(`section1_2.${incomeTypeCode}.${incomeIndex}.incomeAmount`, '1');
            } else if (!Number.isNaN(_incomeAmount) && Number.isNaN(_taxAmount)) {
                setValueInTreeObject(_s, `section1_2.${incomeTypeCode}.${incomeIndex}.incomeAmount`, '', true);
                handleSectionsErrors(`section1_2.${incomeTypeCode}.${incomeIndex}.incomeAmount`, '1', false);
                setValueInTreeObject(_s, `section1_2.${incomeTypeCode}.${incomeIndex}.taxAmount`, 'FIELD_NOT_SET', true, idName === 'incomeAmount');
                handleSectionsErrors(`section1_2.${incomeTypeCode}.${incomeIndex}.taxAmount`, '1');
            } else if (Number.isNaN(_incomeAmount) && !Number.isNaN(_taxAmount)) {
                setValueInTreeObject(_s, `section1_2.${incomeTypeCode}.${incomeIndex}.taxAmount`, '', true);
                handleSectionsErrors(`section1_2.${incomeTypeCode}.${incomeIndex}.taxAmount`, '1', false);
                setValueInTreeObject(_s, `section1_2.${incomeTypeCode}.${incomeIndex}.incomeAmount`, 'FIELD_NOT_SET', true, idName === 'taxAmount');
                handleSectionsErrors(`section1_2.${incomeTypeCode}.${incomeIndex}.incomeAmount`, '1');
            } else if (!Number.isNaN(_incomeAmount) && !Number.isNaN(_taxAmount) && ![perc10X, perc20X].includes(taxAmount)) {
                setValueInTreeObject(_s, `section1_2.${incomeTypeCode}.${incomeIndex}.taxAmount`, 'error_message_10_20_percent', true, idName === 'incomeAmount');
                handleSectionsErrors(`section1_2.${incomeTypeCode}.${incomeIndex}.taxAmount`, '1');
            } else {
                setValueInTreeObject(_s, `section1_2.${incomeTypeCode}.${incomeIndex}.incomeAmount`, '', true);
                handleSectionsErrors(`section1_2.${incomeTypeCode}.${incomeIndex}.incomeAmount`, '1', false);
                setValueInTreeObject(_s, `section1_2.${incomeTypeCode}.${incomeIndex}.taxAmount`, '', true);
                handleSectionsErrors(`section1_2.${incomeTypeCode}.${incomeIndex}.taxAmount`, '1', false);
            }
        } else if (incomeTypeCode === 'code072') {
            const _incomeAmount = parseInt(_s.section1_2?.[incomeTypeCode]?.[incomeIndex]?.incomeAmount.value);
            if (Number.isNaN(_incomeAmount)) {
                setValueInTreeObject(_s, `section1_2.${incomeTypeCode}.${incomeIndex}.incomeAmount`, 'FIELD_NOT_SET', true, false);
                handleSectionsErrors(`section1_2.${incomeTypeCode}.${incomeIndex}.incomeAmount`, '1');
            } else {
                setValueInTreeObject(_s, `section1_2.${incomeTypeCode}.${incomeIndex}.incomeAmount`, '', true);
                handleSectionsErrors(`section1_2.${incomeTypeCode}.${incomeIndex}.incomeAmount`, '1', false);
            }
        }
        set1_2TotalTax(_s);
        validateField(_s, 'section5.employerPaidTax');
        validateField(_s, 'section5.employerReduceAmount');
        validateField(_s, 'section5.invoiceReturnAmount');
        validateField(_s, '5_9&5_10');
        setIsCorrectedFields(false);
    }

    const validateField = (_state: any, id: string) => {
        if (
            id.match('^section7_1.employerDataList.[0-9]+.salary$') ||
            id.match('^section7_1.employerDataList.[0-9]+.civilSalary$') ||
            id.match('^section7_1.employerDataList.[0-9]+.allBenefits$') ||
            id.match('^section7_1.employerDataList.[0-9]+.amountReduce$') ||
            id.match('^section7_1.employerDataList.[0-9]+.otherTaxIncome')
        ) {
            const index = id.replace('section7_1.employerDataList.', '')
                            .replace('.salary', '')
                            .replace('.civilSalary', '')
                            .replace('.allBenefits', '')
                            .replace('.otherTaxIncome', '')
                            .replace('.amountReduce', '');
            const salary = parseInt(_state.section7_1?.employerDataList[index]?.salary?.value) || 0;
            const civilSalary = parseInt(_state.section7_1?.employerDataList[index]?.civilSalary?.value) || 0;
            const allBenefits = parseInt(_state.section7_1?.employerDataList[index]?.allBenefits?.value) || 0;
            const otherTaxIncome = parseInt(_state.section7_1?.employerDataList[index]?.otherTaxIncome?.value) || 0;
            const amountReduce = parseInt(_state.section7_1?.employerDataList[index]?.amountReduce?.value) || 0;
            const amountReduceMax = Math.floor(salary + civilSalary + allBenefits + otherTaxIncome - (salary + civilSalary + allBenefits + otherTaxIncome) / 1.05);
            const totalIncome = salary + civilSalary + allBenefits + otherTaxIncome - amountReduce;
            setValueInTreeObject(_state, `section7_1.employerDataList.${index}.totalIncome`, totalIncome);
            if (amountReduce > amountReduceMax) {
                setValueInTreeObject(_state, `section7_1.employerDataList.${index}.amountReduce`, 'error_message_in_1_1_9', true);
                handleSectionsErrors(`section7_1.employerDataList.${index}.amountReduce`, '1');
            } else {
                setValueInTreeObject(_state, `section7_1.employerDataList.${index}.amountReduce`, '', true);
                handleSectionsErrors(`section7_1.employerDataList.${index}.amountReduce`, '1', false);
            }
            set7_1totalIncomeTaxErrors(id, _state);
        } else if (id.match('^section7_1.employerDataList.[0-9]+.totalIncomeTax$')) {
            const totalIncomeTax = _state.section7_1.employerDataList.reduce((total: number, item: any) => total + (parseInt(item.totalIncomeTax?.value) || 0), 0);
            setValueInTreeObject(_state, 'section7_1.totalTax', totalIncomeTax);
            set7_1totalIncomeTaxErrors(id, _state);
        } else if (id.match('^section1_2.code072.[0-9]+.incomeAmount$')) {
            const idParams = id.split('.');
            const incomeAmountValue = parseInt(_state.section1_2?.code072?.[idParams[2]]?.incomeAmount.value) || 0;
            const perc10X = Math.floor(incomeAmountValue * 10 / 100);
            setValueInTreeObject(_state, `section1_2.code072.${idParams[2]}.taxAmount`, perc10X);
            validateFor072_082(_state, id);
        } else if (id === 'section1_2' || id.match('^section1_2.[a-z0-9]+.[0-9]+.incomeAmount$') || id.match('^section1_2.[a-z0-9]+.[0-9]+.taxAmount$')) {
            validateFor072_082(_state, id);
        } else if (id.match('^section7_2.[a-z0-9]+.[0-9]+.incomeAmount$') || id.match('^section7_2.[a-z0-9]+.[0-9]+.taxAmount$')) {
            const idParams = id.split('.');
            const idName = idParams[3];
            const incomeAmount = parseInt(_state.section7_2?.[idParams[1]]?.[idParams[2]]?.incomeAmount.value);
            const taxAmount = parseInt(_state.section7_2?.[idParams[1]]?.[idParams[2]]?.taxAmount.value);
            if (!Number.isNaN(incomeAmount) && Number.isNaN(taxAmount)) {
                setValueInTreeObject(_state, `section7_2.${idParams[1]}.${idParams[2]}.taxAmount`, 'FIELD_NOT_SET', true, idName === 'incomeAmount');
                handleSectionsErrors(`section7_2.${idParams[1]}.${idParams[2]}.taxAmount`, '1');
                setValueInTreeObject(_state, `section7_2.${idParams[1]}.${idParams[2]}.incomeAmount`, '', true);
                handleSectionsErrors(`section7_2.${idParams[1]}.${idParams[2]}.incomeAmount`, '1', false);
            } else if (Number.isNaN(incomeAmount) && !Number.isNaN(taxAmount)) {
                setValueInTreeObject(_state, `section7_2.${idParams[1]}.${idParams[2]}.incomeAmount`, 'FIELD_NOT_SET', true, idName === 'taxAmount');
                handleSectionsErrors(`section7_2.${idParams[1]}.${idParams[2]}.incomeAmount`, '1');
                setValueInTreeObject(_state, `section7_2.${idParams[1]}.${idParams[2]}.taxAmount`, '', true);
                handleSectionsErrors(`section7_2.${idParams[1]}.${idParams[2]}.taxAmount`, '1', false);
            } else if (Number.isNaN(incomeAmount) && Number.isNaN(taxAmount)) {
                setValueInTreeObject(_state, `section7_2.${idParams[1]}.${idParams[2]}.incomeAmount`, 'FIELD_NOT_SET', true, idName === 'taxAmount');
                setValueInTreeObject(_state, `section7_2.${idParams[1]}.${idParams[2]}.taxAmount`, 'FIELD_NOT_SET', true, idName === 'incomeAmount');
                handleSectionsErrors(`section7_2.${idParams[1]}.${idParams[2]}.incomeAmount`, '1');
                handleSectionsErrors(`section7_2.${idParams[1]}.${idParams[2]}.taxAmount`, '1');
            } else {
                setValueInTreeObject(_state, `section7_2.${idParams[1]}.${idParams[2]}.incomeAmount`, '', true);
                setValueInTreeObject(_state, `section7_2.${idParams[1]}.${idParams[2]}.taxAmount`, '', true);
                handleSectionsErrors(`section7_2.${idParams[1]}.${idParams[2]}.incomeAmount`, '1', false);
                handleSectionsErrors(`section7_2.${idParams[1]}.${idParams[2]}.taxAmount`, '1', false);
            }
            set7_2TotalTax(_state);
        } else if (id.match('^section2.f2_[0-9]+$')) {
            const valF2_27 = section2Rows.reduce((total: number, item: any) => {
                if (item.value_key === 'f2_27') return total;
                const val = parseInt(_state.section2?.[item.value_key]?.value) || 0;
                return total + val;
            }, 0);
            setValueInTreeObject(_state, 'section2.f2_27', valF2_27);
            // set6_1Error(_state);
        } else if (id === 'section6.selfEmployeeIncome') {
            let val = 0;
            if ([1, 2].includes(_state.physSocParticipationType?.value)) {
                val = Math.floor((parseInt(_state.section6?.selfEmployeeIncome?.value) || 0) * (parseInt(_state.section6?.selfEmployeeSocRate?.value) || 0) / 100);
            }
            setValueInTreeObject(_state, 'section6.selfEmployeeSocPayment', val);
            // set6_1Error(_state);
        } else if (['section3.salary', 'section3.civilContractsSalary', 'section3.salaryTaxableIncomeReduce'].includes(id)) {
            const salaryValue = parseInt(_state.section3?.salary?.value) || 0;
            const civilContractsSalaryValue = parseInt(_state.section3?.civilContractsSalary?.value) || 0;
            const salaryTaxableIncomeReduceValue = parseInt(_state.section3?.salaryTaxableIncomeReduce?.value) || 0;
            const salaryTaxableIncomeReduceValueMax = Math.floor(salaryValue + civilContractsSalaryValue - (salaryValue + civilContractsSalaryValue) / 1.05);
            const salaryTaxableIncomeValue = salaryValue + civilContractsSalaryValue - salaryTaxableIncomeReduceValue;
            const salaryTaxableIncomeTaxValue = Math.floor(salaryTaxableIncomeValue * 20 / 100);
            if (salaryTaxableIncomeReduceValueMax < salaryTaxableIncomeReduceValue) {
                setValueInTreeObject(_state, 'section3.salaryTaxableIncomeReduce', 'error_message_in_3_6', true);
                handleSectionsErrors('section3.salaryTaxableIncomeReduce', '3');
            } else {
                setValueInTreeObject(_state, 'section3.salaryTaxableIncomeReduce', '', true);
                handleSectionsErrors('section3.salaryTaxableIncomeReduce', '3', false);
            }
            setValueInTreeObject(_state, 'section3.salaryTaxableIncome', salaryTaxableIncomeValue);
            setValueInTreeObject(_state, 'section3.salaryTax', salaryTaxableIncomeTaxValue);
            const allRowsTaxes = section3Rows.flatMap((item: any) => item.rows).filter(item => item.sumTaxes);
            const totalCalculatedTax = allRowsTaxes.reduce((total, item) => {
                const V = Math.floor(parseInt(_state.section3?.[item.incomeTax]?.value)) || 0;
                return total + V;
            }, 0);
            setValueInTreeObject(_state, 'section3.totalCalculatedTax', totalCalculatedTax);
            const otherCountriesReduceTax = Math.floor(parseInt(_state.section3?.otherCountriesReduceTax.value) || 0);
            const notVisible = [undefined, ''].includes(_state.section3?.otherCountriesReduceTax.value);
            const totalTaxToPaid = totalCalculatedTax - otherCountriesReduceTax;
            setValueInTreeObject(_state, 'section3.totalTaxToPaid', totalTaxToPaid);
            if (totalTaxToPaid < 0) {
                setValueInTreeObject(_state, 'section3.otherCountriesReduceTax', 'error_message_in_3_22', true, notVisible);
                handleSectionsErrors('section3.otherCountriesReduceTax', '3');
            } else {
                setValueInTreeObject(_state, 'section3.otherCountriesReduceTax', '', true, notVisible);
                handleSectionsErrors('section3.otherCountriesReduceTax', '3', false);
            }
            set5_7yearlyCalculatedDifference(_state, totalTaxToPaid);
            set5_9And5_10(_state);
            // set6_1Error(_state);
            setIsCorrectedFields(false);
        } else if (id.match('section3.[a-zA-Z0-9]+Income')) {
            const allRows: any[] = section3Rows.flatMap((item: any) => item.rows);
            const idPart = id.replace('section3.', '');
            const incomeTax = allRows.find(item => item.income === idPart)?.incomeTax;
            const rate = allRows.find(item => item.income === idPart)?.rate;
            if (incomeTax && rate) {
                const incomeVal = !Number.isNaN(parseInt(_state.section3?.[idPart]?.value)) ? parseInt(_state.section3?.[idPart]?.value) : undefined;
                const incomeRateVal = parseInt(_state.section3?.[rate]?.value) || 0;
                const incomeTaxValue = incomeVal === undefined ? undefined : Math.floor(incomeVal * incomeRateVal / 100);
                setValueInTreeObject(_state, `section3.${incomeTax}`, incomeTaxValue);
                const allRowsTaxes = allRows.filter(item => item.sumTaxes);
                const totalCalculatedTax = allRowsTaxes.reduce((total, item) => {
                    const V = Math.floor(parseInt(_state.section3?.[item.incomeTax]?.value)) || 0;
                    return total + V;
                }, 0);
                setValueInTreeObject(_state, 'section3.totalCalculatedTax', totalCalculatedTax);
                const otherCountriesReduceTax = Math.floor(parseInt(_state.section3?.otherCountriesReduceTax.value) || 0);
                const notVisible = [undefined, ''].includes(_state.section3?.otherCountriesReduceTax.value);
                const totalTaxToPaid = totalCalculatedTax - otherCountriesReduceTax;
                setValueInTreeObject(_state, 'section3.totalTaxToPaid', totalTaxToPaid);
                if (totalTaxToPaid < 0) {
                    setValueInTreeObject(_state, 'section3.otherCountriesReduceTax', 'error_message_in_3_22', true, notVisible);
                    handleSectionsErrors('section3.otherCountriesReduceTax', '3');
                } else {
                    setValueInTreeObject(_state, 'section3.otherCountriesReduceTax', '', true, notVisible);
                    handleSectionsErrors('section3.otherCountriesReduceTax', '3', false);
                }
                set5_7yearlyCalculatedDifference(_state, totalTaxToPaid);
                set5_9And5_10(_state);
                // set6_1Error(_state);
                setIsCorrectedFields(false);
            }
        } else if (id === 'section3.otherCountriesReduceTax') {
            const otherCountriesReduceTax = Math.floor(_state.section3?.otherCountriesReduceTax.value) || 0;
            const totalCalculatedTax = Math.floor(_state.section3?.totalCalculatedTax.value) || 0;
            const totalTaxToPaid = totalCalculatedTax - otherCountriesReduceTax;
            setValueInTreeObject(_state, 'section3.totalTaxToPaid', totalTaxToPaid);
            if (totalTaxToPaid < 0) {
                setValueInTreeObject(_state, 'section3.otherCountriesReduceTax', 'error_message_in_3_22', true);
                handleSectionsErrors('section3.otherCountriesReduceTax', '3');
            } else {
                setValueInTreeObject(_state, 'section3.otherCountriesReduceTax', '', true);
                handleSectionsErrors('section3.otherCountriesReduceTax', '3', false);
            }
            set5_7yearlyCalculatedDifference(_state, totalTaxToPaid);
            set5_9And5_10(_state);
            setIsCorrectedFields(false);
        } else if (id === 'section4') {
            const educationalCostAmount = parseInt(_state.section4?.educationalCostAmount?.value) || 0;
            const healthEnsuranceAmount = parseInt(_state.section4?.healthEnsuranceAmount?.value) || 0;
            const educationalReturnAmount = educationalCostAmount <= 100000 ? educationalCostAmount : 100000;
            const healthEnsuranceReturnAmount = healthEnsuranceAmount <= 50000 ? healthEnsuranceAmount : 50000;
            const totalCostAmount = educationalCostAmount + healthEnsuranceAmount;
            const totalReturnAmount = (educationalReturnAmount + healthEnsuranceReturnAmount) > 100000 ? 100000 : educationalReturnAmount + healthEnsuranceReturnAmount;
            setValueInTreeObject(_state, 'section4.educationalReturnAmount', educationalReturnAmount);
            setValueInTreeObject(_state, 'section4.healthEnsuranceReturnAmount', healthEnsuranceReturnAmount);
            setValueInTreeObject(_state, 'section4.totalCostAmount', totalCostAmount);
            setValueInTreeObject(_state, 'section4.totalReturnAmount', totalReturnAmount);
        } else if (id === 'section5.employerPaidTax') {
            const employerPaidTaxValue = (parseInt(_state.section1_1?.totalTax?.value) || 0) + (parseInt(_state.section1_2?.totalTax?.value) || 0);
            setValueInTreeObject(_state, 'section5.employerPaidTax', employerPaidTaxValue || undefined);
        } else if (['section5.employerReduceAmount', 'section5.tuitionFeeReturnAmount', 'section5.investmentReturnAmount'].includes(id)) {
            const employerPaidTaxValue = (parseInt(_state.section1_1?.totalTax?.value) || 0) + (parseInt(_state.section1_2?.totalTax?.value) || 0);
            let employerReduceAmount: number | undefined = employerPaidTaxValue
                                            - (parseInt(_state.section5?.hypotecReturnAmount?.value) || 0)
                                            - (parseInt(_state.section5?.tuitionFeeReturnAmount?.value) || 0)
                                            - (parseInt(_state.section5?.investmentReturnAmount?.value) || 0);
            if (!isResidentUser(auth.role)) {
                employerReduceAmount = 0;
                setValueInTreeObject(_state, 'section5.investmentReturnAmount', undefined);
            }
            if (isResidentUser(auth.role) && !_state.reasonPresent && employerReduceAmount === 0) {
                employerReduceAmount = undefined;
            }
            if ((employerReduceAmount || 0) < 0) {
                employerReduceAmount = 0;
            }
            setValueInTreeObject(_state, 'section5.employerReduceAmount', employerReduceAmount);
            // if ((employerReduceAmount || 0) < 0) {
            //     setValueInTreeObject(_state, 'section5.employerReduceAmount', 'WRONG_5_6_FIELD', true);
            //     handleSectionsErrors('section5.employerReduceAmount', '5');
            // } else {
            //     setValueInTreeObject(_state, 'section5.employerReduceAmount', '', true);
            //     handleSectionsErrors('section5.employerReduceAmount', '5', false);
            // }
            const total3_23 = parseInt(_state.section3.totalTaxToPaid.value) || 0;
            set5_7yearlyCalculatedDifference(_state, total3_23);
            set5_9And5_10(_state);
        } else if (id === 'section5.invoiceReturnAmount') {
            const val = !_state.reasonPresent ? 0 : (parseInt(_state.section4?.totalReturnAmount?.value) || 0);
            setValueInTreeObject(_state, 'section5.invoiceReturnAmount', !isResidentUser(auth.role) ? 0 : (val > 100000 ? 100000 : val));
            set5_9And5_10(_state);
        } else if (id === '5_9&5_10') {
            set5_9And5_10(_state);
        } else if (id === 'taxpayerPhone') {
            if (_state.taxpayerPhone.value && !_state.taxpayerPhone.value.match(/^[0-9]{8}$/)) {
                setValueInTreeObject(_state, 'taxpayerPhone', 'INVALID_INPUT', true);
                handleSectionsErrors('taxpayerPhone', 'main');
            } else {
                setValueInTreeObject(_state, 'taxpayerPhone', '', true);
                handleSectionsErrors('taxpayerPhone', 'main', false);
            }
        }
    }

    const handleInputChange = useCallback((args: IhandleArgs) => (ev: React.ChangeEvent<HTMLInputElement>, cursorFixator?: CallableFunction) => {
        let { value } = ev.target;
        if (args.numeric) {
            value = value.replace(/,/g, '');
            cursorFixator?.();
            if (value.length > 0) {
                value = parseFloat(value).toString();
            }
        }
        if (args.maxLength && value.length > args.maxLength) {
            return;
        }
        if (args.pattern && !(new RegExp(args.pattern).test(value))) {
            return;
        }
        // console.log('args', args, value, state);
        setState((prevState: any) => {
            setValueInTreeObject(prevState, args.id, value);
            validateField(prevState, args.id);
            return ({
                ...prevState,
            });
        });
    }, []);

    const toggleNotificationModal = () => setNotificationInfoModal(prev => ({ ...prev, isOpen: false }));

    const closeConfirmAction = () => setConfirmAction({ isOpen: false, messageKey: '', approve: () => {} });

    const dontAgreeWithInfo_section1_1 = (ev: any, taxAgent: any) => {
        const isChecked = ev.target.checked;
        const tinValue = taxAgent.tin.value;
        if (isChecked && !notificationInfoModal.section1_1) {
            setNotificationInfoModal(prev => ({ ...prev, section1_1: true, isOpen: true }))
        }
        setState((prevState: any) => {
            const newState = {
                ...prevState,
                section7_1: {
                    employerDataList: isChecked ?
                        [
                            ...(prevState.section7_1?.employerDataList || []),
                            {
                                ...taxAgent,
                                isNew: taxAgent.isNew || undefined,
                                tin: { ...taxAgent.tin },
                                taxpayerName: { ...taxAgent.taxpayerName },
                                salary: { ...taxAgent.salary },
                                civilSalary: { ...taxAgent.civilSalary },
                                allBenefits: { ...taxAgent.allBenefits },
                                otherTaxIncome: { ...taxAgent.otherTaxIncome },
                                amountReduce: { ...taxAgent.amountReduce },
                                totalIncome: { ...taxAgent.totalIncome },
                                totalIncomeTax: { ...taxAgent.totalIncomeTax },
                            }
                        ] :
                        (prevState.section7_1?.employerDataList || []).filter((item: any) => item.tin?.value !== tinValue)
                }
            };
            newState.section7_1.totalTax = {
                ...newState.section7_1.totalTax,
                value: newState.section7_1.employerDataList.reduce((total: number, item: any) => total + (parseInt(item.totalIncomeTax?.value) || 0), 0)
            };
            newState.section7_1.totalIncome = {
                ...newState.section7_1.totalIncome,
                value: newState.section7_1.employerDataList.reduce((total: number, item: any) => total + (parseInt(item.totalIncome?.value) || 0), 0)
            };
            setSectionsErrors({ '1': [], '2': [], '3': [], '4': [], '5': [], '6': [], main: [],  type: '', errorPart: '', message: '' });
            validateAllFields(newState);
            return newState;
        });
    }

    const dontAgreeWithInfo_section1_2 = (ev: any) => {
        const isChecked = ev.target.checked;
        if (isChecked && !notificationInfoModal.section1_2) {
            setNotificationInfoModal(prev => ({ ...prev, section1_2: true, isOpen: true }))
        }
        setState((prevState: any) => {
            if (!prevState.section7_2) {
                prevState.section7_2 = {};
            }
            if (prevState.section1_2) {
                const section1_2Arr: any = Object.entries(prevState.section1_2);
                for (const [incomeTypeCode, taxAgentsList] of section1_2Arr) {
                    if (['code072', 'code082'].includes(incomeTypeCode)) {
                        continue;
                    }
                    if (incomeTypeCode === 'totalTax' || incomeTypeCode === 'totalIncome') {
                        prevState.section7_2[incomeTypeCode] = isChecked ? { ...taxAgentsList } : {};
                    } else {
                        prevState.section7_2[incomeTypeCode] = [...(prevState.section7_2?.[incomeTypeCode] || [])];
                    }
                    if (Array.isArray(taxAgentsList)) {
                        for (const taxAgent of taxAgentsList) {
                            if (taxAgent.tin?.value) {
                                if (isChecked) {
                                    prevState.section7_2[incomeTypeCode].push({
                                        tin: { ...taxAgent.tin },
                                        taxpayerName: { ...taxAgent.taxpayerName },
                                        incomeAmount: { ...taxAgent.incomeAmount },
                                        taxAmount: { ...taxAgent.taxAmount }
                                    });
                                } else {
                                    prevState.section7_2[incomeTypeCode] = prevState.section7_2[incomeTypeCode].filter((itm: any) => itm.tin?.value !== taxAgent.tin.value);
                                }
                            }
                        }
                    }
                }
            }
            setSectionsErrors({ '1': [], '2': [], '3': [], '4': [], '5': [], '6': [], 'main': [],  type: '', errorPart: '', message: '' });
            validateAllFields(prevState);
            return { ...prevState };
        });
    }

    const handleSearchTaxAgents = (val: any) => {
        if (val.length < 2) {
            setNewTaxAgentsList([]);
            setSearchTin1('-');
            return;
        }
        setSearchTin1('dataNotFound');
        getTaxpayers(val)
            .then((res) => {
                setNewTaxAgentsList(
                    res.data?.map((item: any) => ({ value: item.tin, label: item.name }))
                );
            })
            .catch(err => console.log('getTaxpayers.error >>>', err));
    }

    const handleSearchTaxAgents2 = (val: any) => {
        if (val.length < 2) {
            setNewTaxAgentsList2([]);
            setSearchTin2('-');
            return;
        }
        setSearchTin2('dataNotFound');
        getTaxpayers(val)
            .then((res) => {
                setNewTaxAgentsList2(
                    res.data?.map((item: any) => ({ value: item.tin, label: item.name }))
                );
            })
            .catch(err => console.log('getTaxpayers.error >>>', err));
    }

    const onSelectTaxAgent = (selected: any) => {
        setNewTaxAgent(selected ? { value: selected.label, label: selected.value } : null);
    }

    const onSelectTaxAgent2 = (selected: any) => {
        const data: any = [...Object.entries(state.section1_2 || {}), ...Object.entries(state.section7_2 || {})];
        let existsAgentByTypes = [];
        for (const [k, info] of data) {
            if (Array.isArray(info)) {
                for (const agent of info) {
                    if (agent?.tin?.value) {
                        existsAgentByTypes.push({
                            typeCode: k.replace('code', ''),
                            tin: agent?.tin?.value
                        });
                    }
                }
            }
        }
        setNewTaxAgent2(selected ? { value: selected.label, label: selected.value } : null);
        setSec1_2Revenue(null);
        setExistsAgentByTypes(selected ? existsAgentByTypes : []);
    }

    const onSelectRevenuesSec1_2 = (selected: any) => {
        setSec1_2Revenue(selected || null);
    }

    const addNewTaxAgent = () => {
        if (newTaxAgent?.label) {
            setState((prevState: any) => {
                const newState = {
                    ...prevState,
                    section7_1: {
                        employerDataList: [
                            ...(prevState.section7_1?.employerDataList || []),
                            {
                                isNew: true,
                                tin: { value: newTaxAgent?.label },
                                taxpayerName: { value: newTaxAgent?.value },
                                salary: { value: '' },
                                civilSalary: { value: '' },
                                allBenefits: { value: '' },
                                otherTaxIncome: { value: '' },
                                amountReduce: { value: '' },
                                totalIncome: { value: '' },
                                totalIncomeTax: { value: '' },
                            }
                        ],
                        totalTax: { ...prevState.totalTax }
                    }
                };
                newState.section7_1.totalTax.value = newState.section7_1.employerDataList.reduce((total: number, item: any) => total + (parseInt(item.totalIncomeTax?.value) || 0), 0);
                validateAllFields(newState);
                return newState;
            });
        }
        setNewTaxAgent(null);
    }

    const addNewTaxAgent2 = () => {
        setState((prevState: any) => {
            if (newTaxAgent2?.label && sec1_2Revenue) {
                for (const type of sec1_2Revenue) {
                    let sectionKey = 'section7_2';
                    const isSpecType = ['072', '082'].includes(type.value)
                    if (isSpecType) {
                        sectionKey = 'section1_2';
                    }
                    if (!prevState[sectionKey]) {
                        prevState[sectionKey] = {};
                    }
                    if (!prevState[sectionKey][`code${type.value}`]) {
                        prevState[sectionKey][`code${type.value}`] = [];
                    }
                    const newItem: any = {
                        tin: { value: newTaxAgent2?.label },
                        taxpayerName: { value: newTaxAgent2?.value },
                        incomeAmount: { value: '' },
                        taxAmount: { value: '' },
                    }
                    if (isSpecType){
                        newItem.isNew = true;
                    }
                    prevState[sectionKey][`code${type.value}`] = [
                        ...prevState[sectionKey][`code${type.value}`],
                        newItem
                    ];
                    state.section7_2_added_new_items = true;
                    if (!prevState[sectionKey].totalTax) {
                        prevState[sectionKey].totalTax = { value: '' };
                    }
                    const index = prevState[sectionKey][`code${type.value}`].length - 1;
                    validateField(prevState, `${sectionKey}.code${type.value}.${index}.incomeAmount`);
                }
            }
            return { ...prevState };
        });
        setNewTaxAgent2(null);
        setSec1_2Revenue(null);
    }

    const deleteAccordionHandler = (tin: string) => {
        setConfirmAction({
            isOpen: true,
            messageKey: 'remove_employeer_details',
            approve: (cb?: Function) => {
                setState((prevState: any) => {
                    const newState = {
                        ...prevState,
                        section7_1: {
                            employerDataList: prevState.section7_1.employerDataList.filter((item: any) => item.tin.value !== tin),
                            totalTax: { ...prevState.totalTax }
                        }
                    };
                    newState.section7_1.totalTax.value = newState.section7_1.employerDataList.reduce((total: number, item: any) => total + (parseInt(item.totalIncomeTax?.value) || 0), 0);
                    return newState;
                });
                closeConfirmAction();
                cb?.();
            }
        });
    }

    const deleteSection7_2_new_items = (tin: string, key: any) => {
        setConfirmAction({
            isOpen: true,
            messageKey: 'remove_employeer_details',
            approve: (cb?: Function) => {
                setState((prevState: any) => {
                    let filtered = prevState.section7_2?.[key]?.filter((item: any) => item.tin?.value !== tin);
                    let taxAmount = prevState.section7_2?.[key]?.find((item: any) => item.tin?.value === tin)?.taxAmount?.value;
                    let incomeAmount = prevState.section7_2?.[key]?.find((item: any) => item.tin?.value === tin)?.incomeAmount?.value;
                    taxAmount = parseInt(taxAmount) || 0;
                    incomeAmount = parseInt(incomeAmount) || 0;
                    let newState = {
                        ...prevState,
                        section7_2: {
                            ...prevState.section7_2,
                            [key]: filtered,
                            totalIncome: {
                                ...prevState.section7_2?.totalIncome,
                                value: (parseInt(prevState.section7_2?.totalIncome?.value) || 0) - incomeAmount
                            },
                            totalTax: {
                                ...prevState.section7_2?.totalTax,
                                value: (parseInt(prevState.section7_2?.totalTax?.value) || 0) - taxAmount
                            }
                        }
                    };
                    if ((prevState.section7_2?.[key]?.length || 0) === (filtered?.length || 0)) {
                        // nothing changed, because tin from section1_2 (code072, code082)
                        filtered = prevState.section1_2?.[key]?.filter((item: any) => item.tin?.value !== tin);
                        newState = {
                            ...prevState,
                            section1_2: {
                                ...prevState.section1_2,
                                [key]: filtered
                            }
                        }
                        validateField(newState, 'section1_2');
                    }
                    if (document.querySelectorAll('[class*="deleteSection7_2_new_items"]').length === 1) {
                        newState.section7_2_added_new_items = false;
                    }
                    return newState;
                });
                closeConfirmAction();
                cb?.();
            }
        });
    }

    const rememberTemporarily = () => {
        setIsLoading(true);
        setSaveProcess(true);
        const send = getSendData(state, type as string);
        // console.log('send', send)
        // if (send) return;
        let request = null;
        if (id && declStatus !== 'filled') {
            request = updateDeclSDDraftByID(id, send);
        } else {
            request = createDeclSDDraft(send);
        }
        request
            .then(() => {
                navigation('/declaration/draft');
            })
            .catch(err => {
                console.log(err);
                const error = translationKeysByErrorCodes(err);
                if (error) {
                    toast.error(t(error), { position: 'top-right', theme: 'colored' });
                }
            })
            .finally(() => {
                setSaveProcess(false);
                setIsLoading(false);
            });
    }

    const handleErrorForRegisterDeclaration = () => {
        const { type: _type, message: _message, errorPart: _errorPart, ...rest } = sectionsErrors;
        setSectionsErrors(prev => ({ ...prev, type: '', message: '', errorPart: '' }));
        let errors: any = Object.entries(rest).reduce((arr: any[], item: any[]) => {
            if (item[1].length > 0) {
                return [...arr, item[0]];
            }
            return [...arr];
        }, []);
        const errorIDs: any = [];
        for (const errorAccID of errors) {
            // @ts-ignore
            errorIDs.push(...(rest[errorAccID] || []));
        }
        if (errorIDs.length) {
            setState((prevState: any) => {
                for (const errorID of errorIDs) {
                    setValueInTreeObject(prevState, errorID, '', true, false, false);
                }
                return ({
                    ...prevState,
                });
            });
        }
        let errorsFiltered = errors.filter((sectionID: string) => !!document.querySelector(`[data-accordion-id="section${sectionID}"][data-isopen="false"]`));
        if (errorsFiltered.length) {
            const errorMessage = errorsFiltered.length > 1 ? 'errors_in_sections' : 'errors_in_section';
            errorsFiltered = errorsFiltered.join(', ');
            setSectionsErrors(prev => ({ ...prev, type: 'errorMessage', message: errorMessage, errorPart: errorsFiltered }));
            window.scrollTo(0, 360);
            return true;
        }
        if (errors.length) return true;
        const existsClosedAccordion = document.querySelectorAll('[data-accordion-id][data-isopen="false"]');
        existsClosedAccordion.forEach(item => {
            if (isCorrectedFields) return;
            if (item.getAttribute('data-accordion-id') === 'section1') {
                item.querySelectorAll('[data-isopen="false"]').forEach(item2 => {
                    (item2.children[0] as HTMLDivElement).click();
                });
            }
            (item.children[0] as HTMLDivElement).click();
        });
        if (existsClosedAccordion?.length && !isCorrectedFields) {
            setAutomaticallyAdjustedWarning('some_field_values_have_been_automatically_adjusted');
            setIsCorrectedFields(true);
            return true;
        }
        setSectionsErrors(prev => ({ ...prev, type: '', message: '' }));
        return false;
    }

    const registerDeclaration = () => {
        if (handleErrorForRegisterDeclaration()) return;
        setSaveProcess(true);
        setIsLoading(true);
        setBackendValidationError([]);
        const send = getSendData(state, type as string);
        createDeclSDSubmit(i18n.language, declStatus !== 'filled' ? id : undefined, send)
            .then((res) => {
                if (res.data.correct) {
                    if (res.data.message === 'REQUIRES_ADJUSTMENT_CONFIRMATION') {
                        setState((prev: any) => ({ ...prev, showAdjustment: true }));
                    } else {
                        navigation('/declaration/filled');
                    }
                } else {
                    console.log('res.data', res.data);
                }
            })
            .catch(err => {
                console.log('registerDeclaration >>>', err);
                const key = translationKeysByErrorCodes(err);
                if (key) {
                    if (key === 'BACKEND_VALIDATION_ERROR' && err.response?.data?.errors) {
                        setBackendValidationError(err.response?.data?.errors);
                        window.scrollTo(0,0);
                    } else {
                        toast.error(t(key), { position: 'top-right', theme: 'colored' });
                    }
                }
            })
            .finally(() => {
                setSaveProcess(false);
                setIsLoading(false);
            });
    }

    const setAdjustment = (ev: any) => {
        setState((prev: any) => ({ ...prev, adjustment: ev.target.checked }));
    }

    const classDeclarationForm = classnames({
        [styles.declarationFormContainer]: true,
        'mb-5': true
    });

    const classAccordion = classnames({
        [styles.Accordion]: true,
        'mt-4': true,
        'mb-3': true
    });

    const readonlyTins = state.section1_1?.employerDataList?.map((item: any) => item.tin?.value || '') || [];
    const editableTins = state.section7_1?.employerDataList?.map((item: any) => item.tin?.value || '') || [];
    const allTins = [...readonlyTins, ...editableTins];
    const taxAgentList = [
        // readonly taxAgents
        ...(state.section1_1?.employerDataList || []),
        // new taxAgents
        ...((state.section7_1?.employerDataList || []).filter((item: any) => !readonlyTins.includes(item.tin.value)).map((item: any) => ({ ...item, isNew: true })))
    ];
    let isDisabledSection1_2 = true;
    let isCheckedSection1_2 = false;

    const buttonsDisabled = !state.taxYear?.value || saveProcess || (isResidentUser(auth.role) && !state.reasonCodes?.length);
    const sec1_2hasCorrectionOrNew = Object.values(state.section7_2 || {}).flatMap(item => item).find((item: any) => item?.tin?.value);

    // if (id && isLoading) return <Loading />

    return (
        <Wrapper
            title={t("incoming_tax_social_payment")}
            className={classDeclarationForm}
            headerRightIcon={(
                <Tooltip title={t('sd_help_icon')} type='info' className={styles.headerRightIcon} position='bottom-right'>
                    <a href={`/${i18n.language}/declaration-sd-help`} target="_blank">
                        <Icons.InfoOutline />
                    </a>
                </Tooltip>
            )}
        >
            <div className={styles.govDecision4Container}>
                <div className={styles.errorMessageContainer}>
                    {backendValidationError.map((errorMsg: string) => (
                        <div key={errorMsg} className={styles.errorMessage}>{errorMsg}</div>
                    ))}
                </div>
                <div className={styles.govDecision4}>
                    <pre>{t('govDecision4')}</pre>
                </div>
            </div>
            {sectionsErrors.type && sectionsErrors.message && (
                <div className={`text-center ${sectionsErrors.type} mb-3`}>
                    {sectionsErrors.errorPart ?
                        t(sectionsErrors.message).toString().replace('[[SECTIONS]]', sectionsErrors.errorPart) :
                        t(sectionsErrors.message)
                    }
                </div>
            )}
            <div className={styles.container}>
                <div className={styles.sectionHelpIcon}>
                    <a href={`/${i18n.language}/declaration-sd-help?section=personal`} target="_blank">
                        <Icons.InfoOutline />
                    </a>
                </div>
                <div className={`${styles.rowItems} user-details`}>
                    <div className={styles.colItem}>
                        <Input
                            label={'1. ' + t('fullName')}
                            id="taxpayerName"
                            defaultValue={state.taxpayerName?.value}
                            errorMessage={state.taxpayerName?.errorMessage ? t(state.taxpayerName?.errorMessage) : ''}
                            disabled
                        />
                    </div>
                    <div className={styles.colItem}>
                        <Input
                            label={'2. ' + t('incoming_tax_psn')}
                            id="psn"
                            defaultValue={state.psn?.value}
                            errorMessage={state.psn?.errorMessage ? t(state.psn?.errorMessage) : ''}
                            disabled
                        />
                    </div>
                    <div className={styles.colItem}>
                        <Input
                            label={'3. ' + t('incoming_tax_tin')}
                            id="tin"
                            defaultValue={state.tin?.value}
                            errorMessage={state.tin?.errorMessage ? t(state.tin?.errorMessage) : ''}
                            disabled
                        />
                    </div>
                    <div className={styles.colItem}>
                        <Input
                            label={'4. ' + t('pensionSystemParticipationStatus')}
                            id="physSocParticipationType"
                            value={
                                [1, 2, 3].includes(state.physSocParticipationType?.value) ?
                                    t(`physSocParticipationType_${state.physSocParticipationType?.value}`) : ''
                            }
                            onChange={() => null}
                            errorMessage={state.physSocParticipationType?.errorMessage ? t(state.physSocParticipationType?.errorMessage) : ''}
                            disabled
                        />
                    </div>
                    <div className={styles.colItem}>
                        <Input
                            label={'5. ' + t('livingAddress')}
                            id="taxpayerAddress"
                            defaultValue={state.taxpayerAddress?.value}
                            errorMessage={state.taxpayerAddress?.errorMessage ? t(state.taxpayerAddress?.errorMessage) : ''}
                            disabled
                        />
                    </div>
                    <div className={styles.colItem}>
                        <Input
                            label={'6. ' + t('incoming_tax_phoneNumber')}
                            id="taxpayerPhone"
                            value={state.taxpayerPhone?.value}
                            errorMessage={state.taxpayerPhone?.errorMessage ? t(state.taxpayerPhone?.errorMessage) : ''}
                            onChange={handleInputChange({ id: 'taxpayerPhone' })}
                            withmobilecodestyle={{ top: '7px' }}
                            withMobileCode
                        />
                    </div>
                </div>
                <div className={styles.rowItems}>
                    <div className={styles.colYear}>
                        <DatePicker
                            label={t('year')}
                            mode='yyyy'
                            value={state.taxYear?.value}
                            minDate={state.taxYear?.validation?.min as Date}
                            maxDate={state.taxYear?.validation?.max as Date}
                            onChange={handleYear}
                            iconprefixstyle={{ height: '30px' }}
                            placeholder="------"
                        />
                    </div>
                </div>
                {state.taxYear?.value && (
                    <div className={styles.rowItems}>
                        <div className={classAccordion}>
                            {isResidentUser(auth.role) && (
                                <a href="" className={styles.residentReasonTypes} onClick={changeReasonTypes}>
                                    {t('residentReasonTypes_tr_1')}
                                </a>
                            )}
                            <Accordion
                                isDisabled={!isResidentUser(auth.role) || (isResidentUser(auth.role) && state.reasonPresent === false)}
                                header={t('form1_section1')}
                                headerRightIcon={(
                                    <div className={styles.sectionHelpIcon}>
                                        <a href={`/${i18n.language}/declaration-sd-help?section=1`} onClick={ev => ev.stopPropagation()} target="_blank">
                                            <Icons.InfoOutline />
                                        </a>
                                    </div>
                                )}
                                accordionId="section1"
                            >
                                <div className={styles.SubAccordionWrapper}>
                                    <Accordion
                                        header={t('form1_section1_1')}
                                        headerRightIcon={(
                                            <div className={styles.sectionHelpIcon}>
                                                <a href={`/${i18n.language}/declaration-sd-help?section=1_1`} onClick={ev => ev.stopPropagation()} target="_blank">
                                                    <Icons.InfoOutline />
                                                </a>
                                            </div>
                                        )}
                                    >
                                        {/* form1_section1_1 */}
                                        <>
                                            {taxAgentList.map((taxAgent: any, index: number) => {
                                                let correctionData: any = null;
                                                if (!taxAgent.isNew) {
                                                    correctionData = state.section7_1?.employerDataList?.find((item: any) => item.tin.value === taxAgent.tin?.value);
                                                }
                                                let indexEmployer = index;
                                                let isChecked = false;
                                                if (taxAgent.isNew || correctionData) {
                                                    indexEmployer = state.section7_1?.employerDataList.findIndex((item: any) => item.tin.value === taxAgent.tin?.value);
                                                    isChecked = true;
                                                    if (indexEmployer === -1) indexEmployer = index;
                                                }
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Accordion
                                                            header={(
                                                                <div className={styles.taxAgentBlockHead}>
                                                                    <Input
                                                                        label={t('tr_1_1_1')}
                                                                        value={taxAgent.tin?.value || ''}
                                                                        disabled
                                                                    />
                                                                    <Input
                                                                        label={t('tr_1_1_2')}
                                                                        value={taxAgent.taxpayerName?.value || ''}
                                                                        withInputLabelTooltip={!!taxAgent.taxpayerName?.value}
                                                                        withInputLabelTooltipPosition="bottom-right"
                                                                        withInputLabelTooltipType="white"
                                                                        disabled
                                                                    />
                                                                </div>
                                                            )}
                                                            mode="custom-header"
                                                            deleteAccordionHandler={taxAgent.isNew ? () => deleteAccordionHandler(taxAgent.tin?.value) : undefined}
                                                            isOpened
                                                        >
                                                            <div className={styles.taxAgentBlockBody}>
                                                                <Table
                                                                    className={correctionData || taxAgent.isNew ? styles.correctionDataTable : ''}
                                                                    theads={[
                                                                        { key: 'revenuesType', title: t('declarationRevenuesType') },
                                                                        { key: 'tr_1_1_3', title: t('tr_1_1_3'), colSpan: correctionData ? 2 : 1, className: correctionData? '' : (taxAgent.isNew ? 'fixTdInputCol w-170px' : 'fixTdCol150') },
                                                                        { key: 'tr_1_1_4', title: t('tr_1_1_4'), colSpan: correctionData ? 2 : 1, className: correctionData? '' : 'fixTdCol150' }
                                                                    ]}
                                                                    externalData={(
                                                                        <>
                                                                            {section1Head1TypesOfIncome.map((item, j) => {
                                                                                const incomeKey = item.value_income_key as keyof I_section1_1_employerDataList;
                                                                                const taxKey = item.value_tax_key as keyof I_section1_1_employerDataList;
                                                                                return (
                                                                                    <tr key={item.translation_key} data-index={index}>
                                                                                        <td className={'text-left' + (correctionData || taxAgent.isNew ? ' fixTdCol600' : ' fixTdCol700')}>
                                                                                            {j + 1}. {t(item.translation_key)} [{item.number}]
                                                                                        </td>
                                                                                        {correctionData ? (
                                                                                            <>
                                                                                                <td className="fixTdInputCol">
                                                                                                    {item.dedicationAmount && '( '}
                                                                                                    {incomeKey && !Number.isNaN(parseInt(taxAgent[incomeKey]?.value)) ?
                                                                                                        formatNumberWithCommas(taxAgent[incomeKey]?.value) : ''}
                                                                                                    {item.dedicationAmount && ' )'}
                                                                                                </td>
                                                                                                <td className={(incomeKey === 'totalIncome' ? 'text-center' : '') + ' fixTdInputCol w-170px'}>
                                                                                                    {incomeKey === 'totalIncome' ? (
                                                                                                        <>
                                                                                                            {!Number.isNaN(parseInt(correctionData[incomeKey]?.value)) ?
                                                                                                                formatNumberWithCommas(correctionData[incomeKey]?.value) : ''}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <Input
                                                                                                            value={
                                                                                                                !Number.isNaN(parseInt(correctionData[incomeKey]?.value)) ?
                                                                                                                    formatNumberWithCommas(correctionData[incomeKey]?.value) : ''
                                                                                                            }
                                                                                                            onChange={
                                                                                                                handleInputChange({
                                                                                                                    id: `section7_1.employerDataList.${indexEmployer}.${incomeKey}`,
                                                                                                                    maxLength: 13,
                                                                                                                    pattern: '^[0-9]{0,13}$',
                                                                                                                    numeric: true
                                                                                                                })
                                                                                                            }
                                                                                                            id={`section7_1.employerDataList.${indexEmployer}.${incomeKey}`}
                                                                                                            errorMessage={correctionData[incomeKey]?.errorMessage ? t(correctionData[incomeKey]?.errorMessage) : ''}
                                                                                                            withCircleBrackets={!!item.dedicationAmount}
                                                                                                            errorWithTooltip
                                                                                                        />
                                                                                                    )}
                                                                                                </td>
                                                                                                <td className="fixTdInputCol">
                                                                                                    {taxKey && taxAgent[taxKey]?.value ?
                                                                                                        formatNumberWithCommas(taxAgent[taxKey]?.value) : 'x'}
                                                                                                </td>
                                                                                                <td className={(!taxKey ? 'text-center' : '') + ' fixTdInputCol'}>
                                                                                                    {taxKey ? (
                                                                                                        <Input
                                                                                                            value={
                                                                                                                !Number.isNaN(parseInt(correctionData[taxKey]?.value)) ?
                                                                                                                    formatNumberWithCommas(correctionData[taxKey]?.value) : ''
                                                                                                            }
                                                                                                            id={`section7_1.employerDataList.${indexEmployer}.${taxKey}`}
                                                                                                            errorMessage={correctionData[taxKey]?.errorMessage && !correctionData[taxKey]?.notVisible ? t(correctionData[taxKey]?.errorMessage) : ''}
                                                                                                            onChange={
                                                                                                                handleInputChange({
                                                                                                                    id: `section7_1.employerDataList.${indexEmployer}.${taxKey}`,
                                                                                                                    maxLength: 13,
                                                                                                                    pattern: '^[0-9]{0,13}$',
                                                                                                                    numeric: true
                                                                                                                })
                                                                                                            }
                                                                                                            errorWithTooltip
                                                                                                        />
                                                                                                    ) : 'x'}
                                                                                                </td>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {taxAgent.isNew ? (
                                                                                                    <>
                                                                                                        <td className={(incomeKey === 'totalIncome' ? 'text-center' : '') + ' fixTdInputCol w-170px'}>
                                                                                                            {incomeKey === 'totalIncome' ? (
                                                                                                                <>
                                                                                                                    {
                                                                                                                        !Number.isNaN(parseInt(taxAgent[incomeKey]?.value)) ?
                                                                                                                            formatNumberWithCommas(taxAgent[incomeKey]?.value) : ''
                                                                                                                    }
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <Input
                                                                                                                    value={
                                                                                                                        !Number.isNaN(parseInt(taxAgent[incomeKey]?.value)) ?
                                                                                                                            formatNumberWithCommas(taxAgent[incomeKey]?.value) : ''
                                                                                                                    }
                                                                                                                    errorMessage={
                                                                                                                        taxAgent[incomeKey]?.errorMessage ?
                                                                                                                            t(taxAgent[incomeKey]?.errorMessage) : ''
                                                                                                                    }
                                                                                                                    onChange={
                                                                                                                        handleInputChange({
                                                                                                                            id: `section7_1.employerDataList.${indexEmployer}.${incomeKey}`,
                                                                                                                            maxLength: 13,
                                                                                                                            pattern: '^[0-9]{0,13}$',
                                                                                                                            numeric: true
                                                                                                                        })
                                                                                                                    }
                                                                                                                    id={`section7_1.employerDataList.${indexEmployer}.${incomeKey}`}
                                                                                                                    withCircleBrackets={!!item.dedicationAmount}
                                                                                                                    errorWithTooltip
                                                                                                                />
                                                                                                            )}
                                                                                                        </td>
                                                                                                        <td className="fixTdInputCol">
                                                                                                            {taxKey && (
                                                                                                                <Input
                                                                                                                    value={
                                                                                                                        !Number.isNaN(parseInt(taxAgent[taxKey]?.value)) ?
                                                                                                                            formatNumberWithCommas(taxAgent[taxKey]?.value) : ''
                                                                                                                    }
                                                                                                                    errorMessage={taxAgent[taxKey]?.errorMessage && !taxAgent[taxKey]?.notVisible ? t(taxAgent[taxKey]?.errorMessage) : ''}
                                                                                                                    id={`section7_1.employerDataList.${indexEmployer}.${taxKey}`}
                                                                                                                    onChange={
                                                                                                                        handleInputChange({
                                                                                                                            id: `section7_1.employerDataList.${indexEmployer}.${taxKey}`,
                                                                                                                            maxLength: 13,
                                                                                                                            pattern: '^[0-9]{0,13}$',
                                                                                                                            numeric: true
                                                                                                                        })
                                                                                                                    }
                                                                                                                    errorWithTooltip
                                                                                                                />
                                                                                                            )}
                                                                                                        </td>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <td className="fixTdCol150">
                                                                                                            {item.dedicationAmount && '( '}
                                                                                                            {incomeKey && !Number.isNaN(parseInt(taxAgent[incomeKey]?.value)) ?
                                                                                                                formatNumberWithCommas(taxAgent[incomeKey]?.value) : ''}
                                                                                                            {item.dedicationAmount && ' )'}
                                                                                                        </td>
                                                                                                        <td className="fixTdCol150">
                                                                                                            {taxKey && !Number.isNaN(parseInt(taxAgent[taxKey]?.value)) ?
                                                                                                                formatNumberWithCommas(taxAgent[taxKey]?.value) : 'x'}
                                                                                                        </td>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </Accordion>
                                                        <div className={styles.dont_agree_with_info}>
                                                            {!taxAgent.isNew && (
                                                                <>
                                                                    <Checkbox
                                                                        boxSize="x-small"
                                                                        id={`dont_agree_with_info_${indexEmployer}`}
                                                                        htmlFor={`dont_agree_with_info_${indexEmployer}`}
                                                                        onChange={ev => {
                                                                            if (taxAgent.tin?.value) {
                                                                                dontAgreeWithInfo_section1_1(ev, taxAgent)
                                                                            }
                                                                        }}
                                                                        disabled={!taxAgent.tin?.value}
                                                                        checked={isChecked}
                                                                        label={t('dont_agree_with_info')}
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })}
                                            <table className={styles.totalOfSection1} cellSpacing={0}>
                                                <tbody>
                                                <tr data-summary-row="true">
                                                    <td>7. {t('total')} [1.1.11]</td>
                                                    <td className={parseInt(state.section7_1?.totalTax?.value) ? styles.totalNumber2 : styles.totalNumber1}>
                                                        {parseInt(state.section1_1?.totalTax?.value) ? formatNumberWithCommas(state.section1_1?.totalTax?.value) : ''}
                                                    </td>
                                                    {parseInt(state.section7_1?.totalTax?.value) ? (
                                                        <td className={styles.totalNumber2}>
                                                            {formatNumberWithCommas(state.section7_1?.totalTax?.value)}
                                                        </td>
                                                    ) : null}
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className={styles.addNewTaxAgentBlock}>
                                                <div className={styles.addNewTaxAgentForm}>
                                                    <Select
                                                        label={t('tr_1_1_1')}
                                                        value={newTaxAgent}
                                                        onChange={onSelectTaxAgent}
                                                        onInputChange={handleSearchTaxAgents}
                                                        options={newTaxAgentsList.filter((item: any) => !allTins.includes(item.value))}
                                                        styles={{
                                                            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                                            control: (s: any) => ({ ...s, minHeight: '30px', height: '30px' }),
                                                            valueContainer: (p: any) => ({ ...p, padding: '0 6px', height: '30px' }),
                                                            input: (p: any) => ({ ...p, margin: 0 }),
                                                            indicatorsContainer: (p: any) => ({ ...p, height: '30px' })
                                                        }}
                                                        isClearable
                                                        noOptionsMessageKey={searchTin1}
                                                        noDropdown
                                                        selectDuringOnBlur
                                                    />
                                                    <Input
                                                        label={t('tr_1_1_2')}
                                                        value={newTaxAgent?.value || ''}
                                                        onChange={() => {}}
                                                        withInputLabelTooltip={!!newTaxAgent?.value}
                                                        withInputLabelTooltipPosition="bottom-right"
                                                        withInputLabelTooltipType="white"
                                                        disabled
                                                    />
                                                </div>
                                                <div className={styles.addNewTaxAgent} onClick={newTaxAgent?.value ? addNewTaxAgent : () => {}} aria-disabled={!newTaxAgent}>
                                                    <span><Icons.PlusCircle /></span> 
                                                    <span>{t('addNewTaxAgent')}</span>
                                                </div>
                                            </div>
                                        </>
                                        {/* END: form1_section1_1 */}
                                    </Accordion>
                                    <Accordion
                                        header={t('form1_section1_2')}
                                        headerRightIcon={(
                                            <div className={styles.sectionHelpIcon}>
                                                <a href={`/${i18n.language}/declaration-sd-help?section=1_2`} onClick={ev => ev.stopPropagation()} target="_blank">
                                                    <Icons.InfoOutline />
                                                </a>
                                            </div>
                                        )}
                                    >
                                        <Table
                                            className={sec1_2hasCorrectionOrNew ? `${styles.correctionDataTable} ${styles.forSection1_2}` : ''}
                                            theads={[
                                                { key: 'revenuesType', title: t('declarationRevenuesType'), colSpan: 2, className: 'fixTdCol600' },
                                                { key: 'tr_1_2_1', title: t('tr_1_2_1'), className: 'fixTdCol100' },
                                                { key: 'tr_1_2_2', title: t('tr_1_2_2'), className: 'fixTdCol100' },
                                                { key: 'tr_1_2_3', title: t('tr_1_2_3'), colSpan: 2, className: sec1_2hasCorrectionOrNew? ((document.getElementById('dont_agree_with_info_section1_head2') as HTMLInputElement)?.checked ? '' : 'fixTdInputCol') : 'fixTdCol100' },
                                                { key: 'tr_1_2_4', title: t('tr_1_2_4'), colSpan: 2, className: sec1_2hasCorrectionOrNew? ((document.getElementById('dont_agree_with_info_section1_head2') as HTMLInputElement)?.checked ? '' : 'fixTdInputCol') : 'fixTdCol100' },
                                                ...(state.section7_2_added_new_items ? [{ key: 'x', title: '' }] : [])
                                            ]}
                                            externalData={(
                                                <>
                                                    {section1Head2TypesOfIncome.map((item, I) => {
                                                        const isTotal = item.income_type_code === 'total';
                                                        const sectionKey = ['code072', 'code082'].includes(item.income_type_code) ? 'section1_2' : 'section7_2';
                                                        let taxAgentsByIncomeType = state.section1_2?.[item.income_type_code];
                                                        if (
                                                            !taxAgentsByIncomeType ||
                                                            (taxAgentsByIncomeType && !taxAgentsByIncomeType.map((item: any) => item.tin?.value).filter((item: any) => !!item).length)
                                                        ) {
                                                            const existsIn7_2 = state.section7_2?.[item.income_type_code]?.map((item: any) => item.tin?.value).filter((item: any) => !!item).length;
                                                            taxAgentsByIncomeType = existsIn7_2 ? [] : [{}];
                                                        } else {
                                                            if (!['code072', 'code082'].includes(item.income_type_code)) {
                                                                isDisabledSection1_2 = false;
                                                            }
                                                        }
                                                        const existsTinsByIncomeType = taxAgentsByIncomeType.map((info: any) => info.tin?.value);
                                                        const taxAgentsByIncomeTypeOther = state.section7_2?.[item.income_type_code!] || [];
                                                        const addedTaxAgents = taxAgentsByIncomeTypeOther
                                                            .filter((info: any) => !existsTinsByIncomeType.includes(info?.tin?.value))
                                                            .map((info: any) => ({ ...info, isNew: true }));
                                                        if (!isCheckedSection1_2) {
                                                            isCheckedSection1_2 = !!(taxAgentsByIncomeTypeOther.find((info: any) => existsTinsByIncomeType.includes(info?.tin?.value)));
                                                        }

                                                        const length1 = taxAgentsByIncomeType.length;
                                                        const length2 = addedTaxAgents.length;
                                                        const ROWSPAN = length1 + length2;
                                                        const rowsByIncomeType = [...taxAgentsByIncomeType, ...addedTaxAgents];
                                                        return (
                                                            <React.Fragment key={I}>
                                                                {rowsByIncomeType.map((row: any, idx: number) => {
                                                                    const corrector = taxAgentsByIncomeTypeOther.find((info: any) => info.tin?.value === row.tin?.value && row.tin?.value);
                                                                    let indexIn7_2 = 0;
                                                                    if (corrector || row.isNew) {
                                                                        if (['code072', 'code082'].includes(item.income_type_code)) {
                                                                            indexIn7_2 = (state.section1_2?.[item.income_type_code!] || []).findIndex((info: any) => info.tin?.value === row.tin?.value);
                                                                        } else {
                                                                            indexIn7_2 = taxAgentsByIncomeTypeOther.findIndex((info: any) => info.tin?.value === row.tin?.value);
                                                                        }
                                                                    }
                                                                    return (
                                                                        <tr key={`tr-${idx}`}>
                                                                            {idx === 0 && (
                                                                                <td className="text-left fixTdCol600" rowSpan={ROWSPAN > 1 ? ROWSPAN : undefined}>
                                                                                    {item.indexNum ? `${item.indexNum}. ` : ''}
                                                                                    {t(item.translation_key)}
                                                                                </td>
                                                                            )}
                                                                            <td>
                                                                                {idx === 0 ? item.number : ''}
                                                                            </td>
                                                                            <td className="fixTdCol100">
                                                                                {isTotal ? 'x' : row.tin?.value}
                                                                            </td>
                                                                            <td className="fixTdCol100">
                                                                                {isTotal ? 'x' : row.taxpayerName?.value}
                                                                            </td>
                                                                            {row.isNew && !isTotal ? (
                                                                                <>
                                                                                    {isCheckedSection1_2 ? (
                                                                                        <>
                                                                                            {!['code072', 'code082'].includes(item.income_type_code) && <td />}
                                                                                        </>
                                                                                    ) : null}
                                                                                    <td colSpan={isCheckedSection1_2 ? 1 : 2} className="fixTdInputCol">
                                                                                        <Input
                                                                                            value={
                                                                                                !Number.isNaN(parseInt(row.incomeAmount?.value)) ?
                                                                                                    formatNumberWithCommas(row.incomeAmount?.value) : ''
                                                                                            }
                                                                                            errorMessage={
                                                                                                row.incomeAmount?.errorMessage ?
                                                                                                    t(row.incomeAmount?.errorMessage) :
                                                                                                    ''
                                                                                            }
                                                                                            onChange={
                                                                                                handleInputChange({
                                                                                                    id: `${sectionKey}.${item.income_type_code}.${indexIn7_2}.incomeAmount`,
                                                                                                    maxLength: 13,
                                                                                                    pattern: '^[0-9]{0,13}$',
                                                                                                    numeric: true
                                                                                                })
                                                                                            }
                                                                                            id={`${sectionKey}.${item.income_type_code}.${indexIn7_2}.incomeAmount`}
                                                                                            errorWithTooltip
                                                                                        />
                                                                                    </td>
                                                                                    {isCheckedSection1_2 ? (
                                                                                        <>
                                                                                            {['code072', 'code082'].includes(item.income_type_code) && <td />}
                                                                                        </>
                                                                                    ) : null}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {!!corrector && !isTotal ? (
                                                                                        <>
                                                                                            <td className="fixTdInputCol">
                                                                                                {!Number.isNaN(parseInt(row.incomeAmount?.value)) ? formatNumberWithCommas(row.incomeAmount?.value) : ''}
                                                                                            </td>
                                                                                            <td className="fixTdInputCol">
                                                                                                <Input
                                                                                                    value={
                                                                                                        !Number.isNaN(parseInt(state.section7_2?.[item.income_type_code]?.[indexIn7_2]?.incomeAmount?.value)) ?
                                                                                                            formatNumberWithCommas(state.section7_2?.[item.income_type_code]?.[indexIn7_2]?.incomeAmount?.value) : ''
                                                                                                    }
                                                                                                    errorMessage={
                                                                                                        state.section7_2?.[item.income_type_code]?.[indexIn7_2]?.incomeAmount?.errorMessage &&
                                                                                                        !state.section7_2?.[item.income_type_code]?.[indexIn7_2]?.incomeAmount?.notVisible?
                                                                                                            t(state.section7_2?.[item.income_type_code]?.[indexIn7_2]?.incomeAmount?.errorMessage) :
                                                                                                            ''
                                                                                                    }
                                                                                                    onChange={
                                                                                                        handleInputChange({
                                                                                                            id: `section7_2.${item.income_type_code}.${indexIn7_2}.incomeAmount`,
                                                                                                            maxLength: 13,
                                                                                                            pattern: '^[0-9]{0,13}$',
                                                                                                            numeric: true
                                                                                                        })
                                                                                                    }
                                                                                                    id={`section7_2.${item.income_type_code}.${indexIn7_2}.incomeAmount`}
                                                                                                    errorWithTooltip
                                                                                                />
                                                                                            </td>
                                                                                        </>
                                                                                    ) : (
                                                                                        <td colSpan={2}>
                                                                                            {isTotal ? 'x' : (
                                                                                                <>
                                                                                                    {!Number.isNaN(parseInt(row.incomeAmount?.value)) ? formatNumberWithCommas(row.incomeAmount?.value) : ''}
                                                                                                </>
                                                                                            )}
                                                                                        </td>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            {row.isNew && !isTotal ? (
                                                                                <>
                                                                                    {isCheckedSection1_2 ? (
                                                                                        <>
                                                                                            {!['code072', 'code082'].includes(item.income_type_code) && <td />}
                                                                                        </>
                                                                                    ) : null}
                                                                                    <td colSpan={isCheckedSection1_2 ? 1 : 2} className="fixTdInputCol">
                                                                                        <Input
                                                                                            value={
                                                                                                !Number.isNaN(parseInt(row.taxAmount?.value)) ?
                                                                                                    formatNumberWithCommas(row.taxAmount?.value) : ''
                                                                                            }
                                                                                            errorMessage={
                                                                                                state[sectionKey]?.[item.income_type_code]?.[indexIn7_2]?.taxAmount?.errorMessage &&
                                                                                                !state[sectionKey]?.[item.income_type_code]?.[indexIn7_2]?.taxAmount?.notVisible ?
                                                                                                    t(state[sectionKey]?.[item.income_type_code]?.[indexIn7_2]?.taxAmount?.errorMessage) :
                                                                                                    ''
                                                                                            }
                                                                                            onChange={
                                                                                                item.income_type_code === 'code072' ?
                                                                                                    () => {} :
                                                                                                    handleInputChange({
                                                                                                        id: `${sectionKey}.${item.income_type_code}.${indexIn7_2}.taxAmount`,
                                                                                                        maxLength: 13,
                                                                                                        pattern: '^[0-9]{0,13}$',
                                                                                                        numeric: true
                                                                                                    })
                                                                                            }
                                                                                            id={`${sectionKey}.${item.income_type_code}.${indexIn7_2}.taxAmount`}
                                                                                            disabled={item.income_type_code === 'code072'}
                                                                                            errorWithTooltip
                                                                                        />
                                                                                    </td>
                                                                                    {isCheckedSection1_2 ? (
                                                                                        <>
                                                                                            {['code072', 'code082'].includes(item.income_type_code) && <td />}
                                                                                        </>
                                                                                    ) : null}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {!!corrector || (isTotal && state.section7_2?.totalTax?.value) ? (
                                                                                        <>
                                                                                            <td className="fixTdInputCol">
                                                                                                {isTotal ? (
                                                                                                    <>
                                                                                                        {!Number.isNaN(parseInt(state.section1_2?.totalTax?.value)) ?
                                                                                                            formatNumberWithCommas(state.section1_2?.totalTax?.value) : ''}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {!Number.isNaN(parseInt(row.taxAmount?.value)) ?
                                                                                                            formatNumberWithCommas(row.taxAmount?.value) : ''}
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={(isTotal ? '' : 'text-left') + ' fixTdInputCol'}>
                                                                                                {isTotal ? (
                                                                                                    <>
                                                                                                        {!Number.isNaN(parseInt(state.section7_2?.totalTax?.value)) ?
                                                                                                            formatNumberWithCommas(state.section7_2?.totalTax?.value) : ''}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <Input
                                                                                                        value={
                                                                                                            !Number.isNaN(parseInt(state.section7_2?.[item.income_type_code]?.[indexIn7_2]?.taxAmount?.value)) ?
                                                                                                                formatNumberWithCommas(state.section7_2?.[item.income_type_code]?.[indexIn7_2]?.taxAmount?.value) : ''
                                                                                                        }
                                                                                                        onChange={
                                                                                                            item.income_type_code === 'code072' ?
                                                                                                                () => {} :
                                                                                                                handleInputChange({
                                                                                                                    id: `section7_2.${item.income_type_code}.${indexIn7_2}.taxAmount`,
                                                                                                                    maxLength: 13,
                                                                                                                    pattern: '^[0-9]{0,13}$',
                                                                                                                    numeric: true
                                                                                                                })
                                                                                                        }
                                                                                                        errorMessage={
                                                                                                            state.section7_2?.[item.income_type_code]?.[indexIn7_2]?.taxAmount?.errorMessage &&
                                                                                                                !state.section7_2?.[item.income_type_code]?.[indexIn7_2]?.taxAmount?.notVisible?
                                                                                                                t(state.section7_2?.[item.income_type_code]?.[indexIn7_2]?.taxAmount?.errorMessage) :
                                                                                                                ''
                                                                                                        }
                                                                                                        id={`section7_2.${item.income_type_code}.${indexIn7_2}.taxAmount`}
                                                                                                        disabled={item.income_type_code === 'code072'}
                                                                                                        errorWithTooltip
                                                                                                    />
                                                                                                )}
                                                                                            </td>
                                                                                        </>
                                                                                    ) : (
                                                                                        <td colSpan={2}>
                                                                                            {isTotal ? (
                                                                                                <>
                                                                                                    {!Number.isNaN(parseInt(state.section1_2?.totalTax?.value)) ?
                                                                                                        formatNumberWithCommas(state.section1_2?.totalTax?.value) : ''}
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {!Number.isNaN(parseInt(row.taxAmount?.value)) ? formatNumberWithCommas(row.taxAmount?.value) : ''}
                                                                                                </>
                                                                                            )}
                                                                                        </td>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            {state.section7_2_added_new_items && (
                                                                                <td>
                                                                                    {row.isNew && (
                                                                                        <span
                                                                                            className={styles.deleteSection7_2_new_items}
                                                                                            onClick={() => deleteSection7_2_new_items(row.tin?.value, item.income_type_code)}
                                                                                        >
                                                                                            <Icons.Trash />
                                                                                        </span>
                                                                                    )}
                                                                                </td>
                                                                            )}
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        />
                                        <div className={styles.dont_agree_with_info}>
                                            <Checkbox
                                                boxSize="x-small"
                                                id="dont_agree_with_info_section1_head2"
                                                htmlFor="dont_agree_with_info_section1_head2"
                                                onChange={ev => {
                                                    if (!isDisabledSection1_2) {
                                                        dontAgreeWithInfo_section1_2(ev);
                                                    }
                                                }}
                                                disabled={isDisabledSection1_2}
                                                checked={isCheckedSection1_2}
                                                label={t('dont_agree_with_info')}
                                            />
                                        </div>
                                        <div className={styles.addNewTaxAgentBlock}>
                                            <div className={styles.addNewTaxAgentForm}>
                                                <Select
                                                    label={t('tr_1_2_1')}
                                                    value={newTaxAgent2}
                                                    onChange={onSelectTaxAgent2}
                                                    onInputChange={handleSearchTaxAgents2}
                                                    options={newTaxAgentsList2}
                                                    styles={{
                                                        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                                        control: (s: any) => ({ ...s, minHeight: '30px', height: '30px' }),
                                                        valueContainer: (p: any) => ({ ...p, padding: '0 6px', height: '30px' }),
                                                        input: (p: any) => ({ ...p, margin: 0 }),
                                                        indicatorsContainer: (p: any) => ({ ...p, height: '30px' })
                                                    }}
                                                    isClearable
                                                    noOptionsMessageKey={searchTin2}
                                                    noDropdown
                                                    selectDuringOnBlur
                                                />
                                                <Select
                                                    label={t('declarationRevenuesType')}
                                                    value={sec1_2Revenue || null}
                                                    onChange={newTaxAgent2?.value ? onSelectRevenuesSec1_2 : () => {}}
                                                    onInputChange={() => {}}
                                                    options={sec1_2Revenues.filter((item: any) => !_existsAgentByTypes.find(item2 => item2.typeCode === item.value && item2.tin === newTaxAgent2?.label))}
                                                    styles={{
                                                        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                                        control: (s: any) => ({ ...s, minHeight: '30px', height: sec1_2Revenue?.length ? 'unset' : '30px' }),
                                                        valueContainer: (p: any) => ({ ...p, padding: '0 6px', minHeight: '30px' }),
                                                        input: (p: any) => ({ ...p, margin: 0, padding: '0 6px' }),
                                                        indicatorsContainer: (p: any) => ({ ...p, minHeight: '30px', height: sec1_2Revenue?.length ? 'unset' : '30px' })
                                                    }}
                                                    isDisabled={!newTaxAgent2?.value}
                                                    isClearable
                                                    isMulti
                                                />
                                            </div>
                                            <div
                                                className={styles.addNewTaxAgent}
                                                onClick={!sec1_2Revenue || !(sec1_2Revenue && sec1_2Revenue.length) ? () => {} : addNewTaxAgent2}
                                                aria-disabled={!sec1_2Revenue || !(sec1_2Revenue && sec1_2Revenue.length)}
                                            >
                                                <span>
                                                    <Icons.PlusCircle />
                                                </span>
                                                <span>
                                                    {t('addNewTaxAgent')}
                                                </span>
                                            </div>
                                        </div>
                                    </Accordion>
                                </div>
                            </Accordion>
                            <Accordion
                                isDisabled={!isResidentUser(auth.role) || (isResidentUser(auth.role) && state.reasonPresent === false)}
                                header={t('form1_section2')}
                                headerRightIcon={(
                                    <div className={styles.sectionHelpIcon}>
                                        <a href={`/${i18n.language}/declaration-sd-help?section=2`} onClick={ev => ev.stopPropagation()} target="_blank">
                                            <Icons.InfoOutline />
                                        </a>
                                    </div>
                                )}
                                accordionId="section2"
                            >
                                {/* form1_section2 */}
                                <Table
                                    className={styles.correctionDataTable}
                                    theads={[
                                        { key: 'form1_section2_title', title: t('form1_section2_title') },
                                        { key: 'form1_section2_income', title: t('form1_section2_income'), className: 'fixTdInputCol' }
                                    ]}
                                    externalData={(
                                        <>
                                            {section2Rows.map((row: any) => (
                                                <tr key={row.value_key}>
                                                    <td className="text-left">
                                                        {row.indexNum}. {t(row.translation_key)} [{row.number}]
                                                    </td>
                                                    <td className="fixTdInputCol">
                                                        <Input
                                                            value={
                                                                !Number.isNaN(parseInt(state.section2?.[row.value_key]?.value)) ?
                                                                    formatNumberWithCommas(state.section2?.[row.value_key]?.value) : ''
                                                            }
                                                            onChange={
                                                                row.disabled ?
                                                                    () => {} :
                                                                    handleInputChange({
                                                                        id: `section2.${row.value_key}`,
                                                                        maxLength: 13,
                                                                        pattern: '^[0-9]{0,13}$',
                                                                        numeric: true
                                                                    })
                                                            }
                                                            id={`section2.${row.value_key}`}
                                                            disabled={!!row.disabled}
                                                            errorWithTooltip
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                />
                                {/* END: form1_section2 */}
                            </Accordion>
                            <Accordion
                                header={t('form1_section3')}
                                headerRightIcon={(
                                    <div className={styles.sectionHelpIcon}>
                                        <a href={`/${i18n.language}/declaration-sd-help?section=3`} onClick={ev => ev.stopPropagation()} target="_blank">
                                            <Icons.InfoOutline />
                                        </a>
                                    </div>
                                )}
                                accordionId="section3"
                            >
                                {/* form1_section3 */}
                                <Table
                                    className={styles.correctionDataTable}
                                    theads={[
                                        { key: 'sec_3_title_1', title: t('sec_3_title_1'), className: 'fixTdCol600' },
                                        { key: 'sec_3_title_2', title: t('sec_3_title_2'), className: 'fixTdInputCol' },
                                        { key: 'sec_3_title_3', title: t('sec_3_title_3'), className: 'fixTdCol100' },
                                        { key: 'sec_3_title_4', title: t('sec_3_title_4'), className: 'fixTdInputCol' }
                                    ]}
                                    externalData={(
                                        <>
                                            <tr>
                                                <td className="text-left" colSpan={4}>{t('sec_3_tr_row_1')}</td>
                                            </tr>
                                            {section3Rows.map((item: any) => {
                                                return (
                                                    <React.Fragment key={item.translation_key}>
                                                        {item.rows.map((row: any, i: number) => (
                                                            <tr key={i}>
                                                                {i === 0 && (
                                                                    <td className="text-left fixTdCol600" rowSpan={item.rows.length > 1 ? item.rows.length : null}>
                                                                        {t(item.translation_key)}
                                                                    </td>
                                                                )}
                                                                <td className={(row.income === 'X' ? 'text-center' : '') + ' fixTdInputCol'}>
                                                                    {row.income === 'X' ? 'X' : (
                                                                        <Input
                                                                            value={
                                                                                !Number.isNaN(parseInt(state.section3?.[row.income]?.value)) ?
                                                                                    formatNumberWithCommas(state.section3?.[row.income]?.value) : ''
                                                                            }
                                                                            errorMessage={
                                                                                state.section3?.[row.income]?.errorMessage ?
                                                                                    t(state.section3?.[row.income]?.errorMessage) :
                                                                                    ''
                                                                            }
                                                                            onChange={
                                                                                !!row.disabled ?
                                                                                    () => {} :
                                                                                    handleInputChange({
                                                                                        id: `section3.${row.income}`,
                                                                                        maxLength: 13,
                                                                                        pattern: '^[0-9]{0,13}$',
                                                                                        numeric: true
                                                                                    })
                                                                            }
                                                                            id={`section3.${row.income}`}
                                                                            disabled={!!row.disabled}
                                                                            withCircleBrackets={!!row.dedicationAmount}
                                                                            errorWithTooltip
                                                                        />
                                                                    )}
                                                                </td>
                                                                <td className="fixTdCol100">
                                                                    {row.rate === 'X' ? 'X' : state.section3?.[row.rate]?.value + '%'}
                                                                </td>
                                                                <td className="fixTdInputCol">
                                                                    {row.incomeTax === 'X' ? 'X' : (
                                                                        <Input
                                                                            value={
                                                                                !Number.isNaN(parseInt(state.section3?.[row.incomeTax]?.value)) ?
                                                                                    formatNumberWithCommas(state.section3?.[row.incomeTax]?.value) : ''
                                                                            }
                                                                            errorMessage={
                                                                                state.section3?.[row.incomeTax]?.errorMessage &&
                                                                                !state.section3?.[row.incomeTax]?.notVisible ?
                                                                                    t(state.section3?.[row.incomeTax]?.errorMessage) :
                                                                                    ''
                                                                            }
                                                                            onChange={
                                                                                !row.incomeTaxEditable ?
                                                                                    () => {} :
                                                                                    handleInputChange({
                                                                                        id: `section3.${row.incomeTax}`,
                                                                                        maxLength: 13,
                                                                                        pattern: '^[0-9]{0,13}$',
                                                                                        numeric: true
                                                                                    })
                                                                            }
                                                                            id={`section3.${row.incomeTax}`}
                                                                            disabled={!row.incomeTaxEditable}
                                                                            withCircleBrackets={!!row.dedicationAmount}
                                                                            errorWithTooltip
                                                                        />
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </>
                                    )}
                                />
                                {/* END: form1_section3 */}
                            </Accordion>
                            <Accordion
                                isDisabled={!isResidentUser(auth.role) || (isResidentUser(auth.role) && state.reasonPresent === false)}
                                header={t('form1_section4')}
                                headerRightIcon={(
                                    <div className={styles.sectionHelpIcon}>
                                        <a href={`/${i18n.language}/declaration-sd-help?section=4`} onClick={ev => ev.stopPropagation()} target="_blank">
                                            <Icons.InfoOutline />
                                        </a>
                                    </div>
                                )}
                                accordionId="section4"
                            >
                                {/* form1_section4 */}
                                <Table
                                    theads={[
                                        { key: 'form1_section4_title_1', title: t('form1_section4_title_1') },
                                        { key: 'form1_section4_title_2', title: t('form1_section4_title_2') },
                                        { key: 'form1_section4_title_3', title: t('form1_section4_title_3') }
                                    ]}
                                    externalData={(
                                        <>
                                            <tr>
                                                <td className="text-left">{t('sec_4_tr_row_1')}</td>
                                                <td>
                                                    (&nbsp;
                                                    {
                                                        parseInt(state.section4?.healthEnsuranceAmount?.value) ?
                                                            formatNumberWithCommas(state.section4?.healthEnsuranceAmount?.value) : '0'
                                                    }
                                                    &nbsp;)
                                                </td>
                                                <td>
                                                    (&nbsp;
                                                    {
                                                        parseInt(state.section4?.healthEnsuranceReturnAmount?.value) ?
                                                            formatNumberWithCommas(state.section4?.healthEnsuranceReturnAmount?.value) : '0'
                                                    }
                                                    &nbsp;)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">{t('sec_4_tr_row_2')}</td>
                                                <td>
                                                    (&nbsp;
                                                    {
                                                        parseInt(state.section4?.educationalCostAmount?.value) ?
                                                            formatNumberWithCommas(state.section4?.educationalCostAmount?.value) : '0'
                                                    }
                                                    &nbsp;)
                                                </td>
                                                <td>
                                                    (&nbsp;
                                                    {
                                                        parseInt(state.section4?.educationalReturnAmount?.value) ?
                                                            formatNumberWithCommas(state.section4?.educationalReturnAmount?.value) : '0'
                                                    }
                                                    &nbsp;)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">{t('sec_4_tr_row_3')}</td>
                                                <td>
                                                    (&nbsp;
                                                    {
                                                        parseInt(state.section4?.totalCostAmount?.value) ?
                                                            formatNumberWithCommas(state.section4?.totalCostAmount?.value) : '0'
                                                    }
                                                    &nbsp;)
                                                </td>
                                                <td>
                                                    <Tooltip 
                                                        title={t('sec_4_warning_error')} 
                                                        className={classnames({
                                                            [styles.Section4Warning]: true,
                                                            [styles.Visible]: (state.section4?.educationalReturnAmount?.value + state.section4?.healthEnsuranceReturnAmount?.value) > 100000
                                                        })}
                                                        type='warning'
                                                        withoutTooltip={(state.section4?.educationalReturnAmount?.value + state.section4?.healthEnsuranceReturnAmount?.value) <= 100000}
                                                    >
                                                        <>
                                                            <span>
                                                                (&nbsp;
                                                                {
                                                                    parseInt(state.section4?.totalReturnAmount?.value) ?
                                                                        formatNumberWithCommas(state.section4?.totalReturnAmount?.value) : '0'
                                                                }
                                                                &nbsp;)
                                                            </span>
                                                            {(state.section4?.educationalReturnAmount?.value + state.section4?.healthEnsuranceReturnAmount?.value) > 100000 && (
                                                                <span>
                                                                    <Icons.WarningOutlineCircle />
                                                                </span>
                                                            )}
                                                        </>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                />
                                {/* END: form1_section4 */}
                            </Accordion>
                            <Accordion
                                header={t('form1_section5')}
                                accordionId="section5"
                                headerRightIcon={(
                                    <div className={styles.sectionHelpIcon}>
                                        <a href={`/${i18n.language}/declaration-sd-help?section=5`} onClick={ev => ev.stopPropagation()} target="_blank">
                                            <Icons.InfoOutline />
                                        </a>
                                    </div>
                                )}
                                className={styles.correctionDataTable}
                            >
                                {/* form1_section5 */}
                                <Table
                                    theads={[
                                        { key: '-', title: '',  className: '' },
                                        { key: 'form1_section5_income_title', title: t('form1_section5_income_title'), className: 'fixTdInputCol' }
                                    ]}
                                    externalData={(
                                        <>
                                            {section5Rows.map((item: any) => {
                                                return (
                                                    <tr key={item.translation_key}>
                                                        <td className="text-left fixTdCol850">
                                                            {item.indexNum}. {t(item.translation_key)} [{item.number}]
                                                        </td>
                                                        <td className="fixTdInputCol">
                                                            <Input
                                                                value={
                                                                    !Number.isNaN(parseInt(state.section5?.[item.value_key]?.value)) ?
                                                                        formatNumberWithCommas(state.section5?.[item.value_key]?.value) : ''
                                                                }
                                                                errorMessage={
                                                                    state.section5?.[item.value_key]?.errorMessage ? t(state.section5?.[item.value_key]?.errorMessage) : ''
                                                                }
                                                                onChange={
                                                                    !!item.disabled ?
                                                                        () => {} :
                                                                        handleInputChange({
                                                                            id: `section5.${item.value_key}`,
                                                                            maxLength: 13,
                                                                            pattern: '^[0-9]{0,13}$',
                                                                            numeric: true
                                                                        })
                                                                }
                                                                id={`section5.${item.value_key}`}
                                                                disabled={!!item.disabled}
                                                                withCircleBrackets={!!item.dedicationAmount}
                                                                errorWithTooltip
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                />
                                {/* END: form1_section5 */}
                            </Accordion>
                            <Accordion
                                isDisabled={!isResidentUser(auth.role)}
                                accordionId="section6"
                                header={t('form1_section6')}
                                headerRightIcon={(
                                    <div className={styles.sectionHelpIcon}>
                                        <a href={`/${i18n.language}/declaration-sd-help?section=6`} onClick={ev => ev.stopPropagation()} target="_blank">
                                            <Icons.InfoOutline />
                                        </a>
                                    </div>
                                )}
                            >
                                {/* form1_section6 */}
                                <Table
                                    className={styles.correctionDataTable}
                                    theads={[
                                        { key: '-', title: '', colSpan: 2 },
                                    ]}
                                    externalData={(
                                        <>
                                            <tr>
                                                <td className="text-left fixTdCol850">
                                                    {t('sec_6_tr_1')}
                                                </td>
                                                <td className="fixTdInputCol">
                                                    <Input
                                                        value={
                                                            !Number.isNaN(parseInt(state.section6?.selfEmployeeIncome?.value)) ?
                                                                formatNumberWithCommas(state.section6?.selfEmployeeIncome?.value) : ''
                                                        }
                                                        errorMessage={
                                                            state.section6?.selfEmployeeIncome?.errorMessage ? t(state.section6?.selfEmployeeIncome?.errorMessage) : ''
                                                        }
                                                        onChange={
                                                            handleInputChange({
                                                                id: "section6.selfEmployeeIncome",
                                                                maxLength: 13,
                                                                pattern: '^[0-9]{0,13}$',
                                                                numeric: true
                                                            })
                                                        }
                                                        onFocus={selfEmployeeIncomeFocus}
                                                        id="section6.selfEmployeeIncome"
                                                        errorWithTooltip
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">{t('sec_6_tr_2')}</td>
                                                <td className="fixTdInputCol" style={{ padding: '.4rem' }}>
                                                    {state.section6?.selfEmployeeSocRate.value} %
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">{t('sec_6_tr_3')}</td>
                                                <td className="fixTdInputCol" style={{ padding: '.4rem' }}>
                                                    {
                                                        parseInt(state.section6?.selfEmployeeSocPayment?.value) ?
                                                            formatNumberWithCommas(state.section6?.selfEmployeeSocPayment?.value) : '0'
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                />
                                {/* END: form1_section6 */}
                            </Accordion>
                        </div>
                    </div>
                )}
                <div className={styles.rowItems}>
                    <div className={styles.adjustment_declaration}>
                        {state.showAdjustment && (
                            <div className={styles.AdjustmentCheckbox}>
                                <Checkbox 
                                    checked={state.adjustment}
                                    label={t('REQUIRES_ADJUSTMENT_CONFIRMATION')}
                                    id='adjustment_declaration'
                                    boxSize={"x-small"}
                                    verticalAlign='top'
                                    onChange={setAdjustment}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.formButtons}>
                        <Button size="small" disabled={buttonsDisabled} onClick={!buttonsDisabled ? rememberTemporarily : () => null}>
                            {t('remember_temporarily')}
                        </Button>
                        <Button size="small" disabled={buttonsDisabled} onClick={!buttonsDisabled ? registerDeclaration: () => null}>
                            {t('register_declaration')}
                        </Button>
                    </div>
                </div>
            </div>
            <Modal
                className={`${styles.attentionModal} ${styles.ChangeYear}`}
                title={t('attention')}
                size="s-fit-content"
                isOpen={isOpenYearApproval.isOpen}
                closeMessage={t('No')}
                onClose={closeYearApproval}
                footerExtraComponents={(
                    <Button size="s" color="primary" onClick={approveYear}>{t('Yes')}</Button>
                )}
                footerExists
                type='warning'
                mode='head-colored'
            >
                <div className={styles.ChangeYearAsk}>
                    <div className={styles.msg}>
                        <p>{t('change_date_year')}</p>
                    </div>
                    <div className={styles.msg}>{t('are_you_sure_incoming_tax')}</div>
                </div>
            </Modal>
            <Modal
                className={`${styles.attentionModal} ${styles.ChangeYear}`}
                title={t('attention')}
                size="s-fit-content"
                isOpen={approvalReasonTypes.isOpen}
                closeMessage={t('No')}
                onClose={closeApprovalReasonTypes}
                footerExtraComponents={(
                    <Button
                        size="s"
                        color="primary"
                        onClick={(ev: any) => approveApprovalReasonTypes(ev, approvalReasonTypes.reasonPresent, approvalReasonTypes.reasonCodes)}
                    >
                        {t('Yes')}
                    </Button>
                )}
                footerExists
                type='warning'
                mode='head-colored'
            >
                <div className={styles.ChangeYearAsk}>
                    <div className={styles.msg}>
                        <p>{t('change_reason_type_attention')}</p>
                    </div>
                    <div className={styles.msg}>{t('are_you_sure_incoming_tax')}</div>
                </div>
            </Modal>
            <Modal
                className={styles.attentionModal}
                title={t('attention')}
                size="s-fit-content"
                isOpen={warning6_1.isOpen}
                closeMessage={t('close')}
                onClose={selfEmployeeIncomeFocusDialogClose}
                footerExists
                type='warning'
                mode='head-colored'
            >
                <div className={styles.ChangeYearAsk}>
                    <div className={styles.msg}>
                        {t('warning_msg_6_1_field')}
                    </div>
                </div>
            </Modal>
            <NotificationDialog
                isOpen={notificationInfoModal.isOpen}
                close={toggleNotificationModal}
                messageTranslationKey="TYPED_DATA_ONLY_FOR_INFO"
                type='info'
                head={t("attention")}
            />
            <ResidentReasonTypesDialog
                isOpen={isOpenReasonTypes.isOpen}
                close={() => setIsOpenReasonTypes((prev: any) => ({ ...prev, isOpen: !prev.isOpen }))}
                approve={approveIsOpenReasonTypes}
                year={isOpenReasonTypes.selectedYear}
                reasonCodes={state.reasonCodes}
                reasonPresent={state.reasonPresent}
            />
            <ConfirmDialog
                isOpen={confirmAction.isOpen}
                approve={confirmAction.approve}
                close={closeConfirmAction}
                questionMessageKey={confirmAction.messageKey}
                className={styles[confirmAction.messageKey]}
            />
            <NotificationDialog 
                isOpen={!!automaticallyAdjustedWarning}
                close={() => setAutomaticallyAdjustedWarning(null)} 
                messageTranslationKey={automaticallyAdjustedWarning}
                type='warning'
                head={t("attention")}
            />
            {isLoading && <Loading />}
        </Wrapper>
    );
};

export default IncomingTaxForm;
