import React, { useMemo } from 'react';
import styles from "./styles.module.scss";
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { navigations } from '../../utils';
import { useNavigate } from '../../hook';
import { useTranslation } from 'react-i18next';
import * as Icons from '../Icons';
import { toggleDisableOpenMenu, toggleNotifyTinIsInValid } from '../../store/utilsSlice';

export function Navigation() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const auth = useSelector((state: any) => state.auth);
    const utils = useSelector((state: any) => state.utils);

    const navigation = useMemo(() => navigations.filter((nav) => nav.roles?.includes(auth.role)), [auth.role]);

    const handleNavigate = (path: string, disableMenuForWithoutTin?: boolean) => (event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        if (disableMenuForWithoutTin && !auth.tin) {
            dispatch(toggleDisableOpenMenu(true));
            return;
        }
        if (disableMenuForWithoutTin && auth.tin && !utils.tinIsValid) {
            dispatch(toggleNotifyTinIsInValid(true));
            return;
        }
        navigate(path);
    }

    const getSelectedNav = (path: string) => {
        return pathname.replace(`/${params.lngCode}`, "") === path
    }

    return (
        <nav className={styles.InnerNavbar}>
            <ul className={styles.NavbarList}>
                {navigation.map((nav) => (
                    <li 
                        className={styles.NavItem} 
                        onClick={handleNavigate(nav.path, nav.disableMenuForWithoutTin)}
                        key={nav.translateKey}
                    >
                        {t(nav.translateKey)}
                        {!!nav.subMenu.length && <span className={styles.Arrow}><Icons.ArrowDown/></span>}
                        {getSelectedNav(nav.path) && <div className={styles.Selected} />}
                        {!!nav.subMenu.length && (
                            <ul className={styles.SubMenu}>
                                {nav.subMenu.map((subMenu) => (
                                    <li key={subMenu.translateKey} onClick={handleNavigate(subMenu.path, subMenu.disableMenuForWithoutTin)}>
                                        {t(subMenu.translateKey)}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
}
