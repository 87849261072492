import React from 'react';
import { classnames, getSearchParams } from '../../utils';
import styles from './styles.module.scss';

interface ContainerProps {
    children: React.ReactNode;
    title?: string;
    titlePosition?: "left" | "center" | "right";
    containerSize?: "small" | "middle" | "large" | "x-large";
    className?: string;
    withoutPadding?: boolean
}
// TODO responsive container
export const Container:React.FC<ContainerProps>  = (props) => {
    const { children, title, titlePosition = "center", containerSize = "middle", className, withoutPadding } = props;

    const cstitles = classnames({
        [styles.Title]: true,
        [styles[titlePosition]]: !!titlePosition
    });

    const csContainerBody = classnames({
        [styles.ContainerBody]: true,
        [styles[containerSize]]: !!containerSize
    });

    const csWrapper = classnames({
        [className as string]: !!className,
        [styles.Container]: true,
        [styles.WithoutPadding]: !!withoutPadding,
        [styles.MobileView]: !!getSearchParams().mobileView
    })

    return (
        <div className={csWrapper}>
            {title !== undefined && <h1 className={cstitles}>{title}</h1>}
            <div className={csContainerBody}>
                {children}
            </div>
        </div>
    );
};