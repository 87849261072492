import React from 'react';
import styles from './styles.module.scss';
import { classnames } from '../../utils';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon } from '../Icons';

interface DropdownProps {
    list: { label: string | React.ReactNode, onClick?: CallableFunction, key: any, className?: string }[],
    label: string | React.ReactNode,
    className?: string;
    xPosition?: "left" | "center" | "right";
    onClick?: CallableFunction;
}

export const Dropdown: React.FC<DropdownProps> = (props) => {
    const { className, list, label, xPosition = "right", onClick } = props;
    const { t } = useTranslation();


    const cs = classnames({
        [styles.Dropdown]: true,
        [className as string]: !!className,
        [styles[xPosition]]: !!xPosition
    });

    return (
        <div className={cs}>
            <div className={styles.Legend}>
                <span>{typeof label === "string" ? t(label) : label}</span>
                <span className={styles.Arrow}>
                    <ArrowDownIcon />
                </span>
            </div>
            <ul className={styles.Menu}>
                {list.map((item) => (
                    <li key={item.key} onClick={() => onClick?.(item.key) ?? item.onClick?.()} className={item.className}>
                        {typeof item.label === "string" ? t(item.label) : item.label}
                    </li>
                ))}
            </ul>
        </div>
    );
};