import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Form } from './Form';
import { Button } from '../../../Button';
import { generateField, generateFormGroup, getRootDataStore } from '../utils';
import { MobileIncomeTaxContext } from '../mobile_income_tax.context';
import { Question } from './Question';
        
export const SocialCosts: React.FC<{ onSubmit: CallableFunction, next: CallableFunction }> = (props) => {
    const { onSubmit, next } = props;
    const [formData] = useState<IDataTypes<FormGroup>>({
        healt: generateFormGroup("healt", "sec_4_tr_row_1", {
            healthEnsuranceAmount: generateField<number>("healthEnsuranceAmount", getRootDataStore()?.root?.section4?.healthEnsuranceAmount, { type: "string", isValid: false, validation: { isRequired: true, disabled: true }, label: `form1_section4_title_2_mobile`, withComma: true }),
            healthEnsuranceReturnAmount: generateField<number>("healthEnsuranceReturnAmount", getRootDataStore()?.root?.section4?.healthEnsuranceReturnAmount, { type: "string", isValid: false, validation: { isRequired: true, disabled: true }, label: `form1_section4_title_3_mobile`, withComma: true }),
        }),
        educational: generateFormGroup("educational", "sec_4_tr_row_2", {
            educationalCostAmount: generateField<number>("educationalCostAmount", getRootDataStore()?.root?.section4?.educationalCostAmount, { type: "string", isValid: false, validation: { isRequired: true, disabled: true }, label: `form1_section4_title_2_mobile`, withComma: true }),
            educationalReturnAmount: generateField<number>("educationalReturnAmount", getRootDataStore()?.root?.section4?.educationalReturnAmount, { type: "string", isValid: false, validation: { isRequired: true, disabled: true }, label: `form1_section4_title_3_mobile`, withComma: true }),
        }),
        total: generateFormGroup("total", "sec_4_tr_row_3", {
            totalCostAmount: generateField<number>("totalCostAmount", getRootDataStore()?.root?.section4?.totalCostAmount, { type: "string", isValid: false, validation: { isRequired: true, disabled: true }, label: `form1_section4_title_2_mobile`, withComma: true }),
            totalReturnAmount: generateField<number>(
                "totalReturnAmount",
                getRootDataStore()?.root?.section4?.totalReturnAmount,
                { 
                    type: "string",
                    isValid: false,
                    validation: { 
                        isRequired: true,
                        disabled: true
                    },
                    label: `form1_section4_title_3_mobile`,
                    withComma: true,
                    warning: Math.trunc((getRootDataStore()?.root?.section4?.healthEnsuranceReturnAmount ?? 0) + (getRootDataStore()?.root?.section4?.educationalReturnAmount ?? 0)) > 100000 ? "sec_4_warning_error" : undefined 
                }
            ),
        })
    });
    const { t } = useTranslation();
    const context = useContext(MobileIncomeTaxContext);

    const handleSubmit = () => {
        const passData = Object.values(formData).reduce((acc, formGroup) => {
            Object.values(formGroup.fields).map((field) => {
                acc[field.key] = field.value as number
            })
            return acc
        }, {} as IDataTypes<number>);
        onSubmit?.(passData);
    };

    useEffect(() => {
        if(!getRootDataStore()?.socialCosts?.completed) {
            context?.setOutOfSteps?.((prev: any) => {
                if(!prev) {
                    prev = {}
                }
                prev.children = (
                    <Question
                        question={"have_paid_social_costs"}
                        yes={() => context?.setOutOfSteps?.(null)}
                        no={() => {
                            next()
                            context?.setOutOfSteps?.(null);
                        }}
                    />
                )
                return { ...prev }
            })
        }

        return () => {
            if (window.location.pathname.includes("declaration/type/sd")) {
                handleSubmit();
            };
        };
    }, []);

    return (
        <div className={styles.SocialCosts}>
            <Form data={formData} prefix={() => <span>{t("dram")}</span>} />
            <Button className={styles.NextBtn} onClick={() => next()}>{t("next")}</Button>
        </div>
    );
};