import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button } from '../Button';
import { Modal } from '../Modal';
import styles from './styles.module.scss';
import { Select } from '../Select';
import { DatePicker } from '../DatePicker';
import {
    IMonthType,
    months,
    createOtherSourceIncome,
    updateOtherSourceIncome,
    getByIdOtherSourceIncome,
    otherSourceIncomeCreateUpdate,
    translationKeysByErrorCodes,
    formatNumberWithCommas
} from '../../utils';
import { Input } from '../Input';
import Loading from '../Loading';

interface ConfirmDialogProps {
    isOpen: boolean;
    id?: number;
    close: Function;
    updateRows: Function;
    resetLoading?: Function;
    types: any[];
}

const initialState = {
    incomeAmount: '',
    incomeTypeCode: '' as any,
    month: '',
    sourceInfo: '',
    taxAmount: 0,
    taxRate: '' as any,
    year: '' as any
}

const initialStateError = {
    incomeAmount: '',
    incomeTypeCode: '',
    taxRate: '',
    year: ''
}

export const RevenuesOtherSourceAddEditDialog: React.FC<ConfirmDialogProps> = (props) => {
    const [addNewRow, setAddNewRow] = useState(initialState);
    const [errors, setErrors] = useState(initialStateError);
    const [formSave, setFormSave] = useState(false);
    const { t, i18n } = useTranslation();
    const options = props.types.map((item: any) => ({ label: item[`name_${i18n.language}`], value: item.code }));
    const optionsMonths = months.map((month, i) => ({
        label: month[i18n.language as keyof IMonthType]?.long,
        value: `${i + 1 < 10 ? `0${i + 1}` : i + 1}`
    }));

    const changeType = (val: any) => {
        let taxRate: any = '';
        if (val?.value) {
            const taxRates = props.types.find(item => item.code === val?.value)?.taxRate;
            if (taxRates.length === 1) {
                taxRate = { label: `${taxRates[0]}%`, value: taxRates[0] };
                setErrors(prev => ({ ...prev, taxRate: '' }));
            } else {
                setErrors(prev => ({ ...prev, taxRate: 'FIELD_NOT_SET' }));
            }
        }
        setAddNewRow(prev => ({ ...prev, incomeTypeCode: val, taxRate, incomeAmount: '' }));
        setErrors(prev => ({ ...prev, incomeTypeCode: !val?.value ? (prev.incomeTypeCode || 'FIELD_NOT_SET') : '' }));
    };

    const changeSourceInfo = (ev: any) => {
        if (ev.target.value.length > 100) return;
        setAddNewRow(prev => ({ ...prev, sourceInfo: ev.target.value }));
    };

    const changeIncomeAmount = (ev: any, cursorFixator?: CallableFunction) => {
        const value = ev.target.value.replace(/,/g, '');
        if (value.length > 13) return;
        if (value && !value.match(/^[0-9]+$/)) return;
        cursorFixator?.();
        setAddNewRow(prev => ({
            ...prev,
            incomeAmount: value,
            taxAmount: (addNewRow.taxRate?.value || 0) * (parseInt(value, 10) || 0) / 100
        }));
        setErrors(prev => ({ ...prev, incomeAmount: !(parseInt(value, 10) || 0) ? (prev.incomeAmount || 'FIELD_NOT_SET') : '' }));
    };

    const changeRate = (val: any) => {
        setAddNewRow(prev => ({ ...prev, taxRate: val, taxAmount: (parseInt(addNewRow.incomeAmount, 10) || 0) * (val?.value || 0) / 100 }));
        setErrors(prev => ({ ...prev, taxRate: !val?.value ? (prev.taxRate || 'FIELD_NOT_SET') : '' }));
    };
    let rates = null;
    if (addNewRow.incomeTypeCode?.value) {
        rates = props.types.find(item => item.code === addNewRow.incomeTypeCode?.value)?.taxRate;
    }

    const changeDate = (date: Date) => {
        setAddNewRow(prev => ({
            ...prev,
            year: date?.getFullYear?.() || ''
        }));
        setErrors(prev => ({ ...prev, year: !date?.getFullYear?.() ? (prev.year || 'FIELD_NOT_SET') : '' }));
    }

    const changeMonth = (val: any) => {
        setAddNewRow(prev => ({
            ...prev,
            month: val?.value || ''
        }));
    }

    const registerRevenue = async () => {
        try {
            setFormSave(true);
            const data: otherSourceIncomeCreateUpdate = {
                incomeAmount: parseInt(addNewRow.incomeAmount) || 0,
                taxAmount: addNewRow.taxAmount,
                incomeTypeCode: addNewRow.incomeTypeCode?.value,
                taxRate: addNewRow.taxRate?.value,
                year: addNewRow.year.toString(),
                month: addNewRow.month,
                sourceInfo: addNewRow.sourceInfo,
            }
            setErrors({
                incomeTypeCode: data.incomeTypeCode ? '' : 'FIELD_NOT_SET',
                incomeAmount: data.incomeAmount ? '' : 'FIELD_NOT_SET',
                taxRate: data.taxRate ? '' : 'FIELD_NOT_SET',
                year: data.year ? '' : 'FIELD_NOT_SET'
            });
            if (!data.incomeAmount || !data.incomeTypeCode || !data.taxRate || !data.year) {
                return;
            }
            if (props.id) {
                await updateOtherSourceIncome(props.id, data);
            } else {
                await createOtherSourceIncome(data);
            }
            props.updateRows();
            props.close();
        } catch (err: any) {
            const translationKey = translationKeysByErrorCodes(err);
            toast.error(t(translationKey), { position: 'top-right', theme: 'colored' });
        } finally {
            setFormSave(false);
        }
    }

    useEffect(() => {
        if (props.id && props.isOpen) {
            getByIdOtherSourceIncome(props.id)
                .then(res => {
                    const incomeTypeCode = options.find(item => item.value === res.data?.incomeTypeCode);
                    setAddNewRow(prev => ({
                        ...prev,
                        incomeTypeCode,
                        year: res.data?.year,
                        month: res.data?.month,
                        incomeAmount: res.data?.incomeAmount,
                        taxRate: res.data?.taxRate ? { label: `${res.data?.taxRate}%`, value: res.data?.taxRate } : '',
                        taxAmount: res.data?.taxAmount,
                        sourceInfo: res.data?.sourceInfo
                    }));
                })
                .catch(err => {
                    toast.error(t(err.message), { position: 'top-right', theme: 'colored' });
                })
                .finally(() => {
                    props.resetLoading?.();
                });
        } else if (!props.id && props.isOpen) {
            setAddNewRow(initialState);
            props.resetLoading?.();
        }
        return () => {
            if (props.id && props.isOpen) {
                setAddNewRow(initialState);
            }
        }
    }, [props.isOpen, props.id]);

    return (
        <Modal
            className={styles.RevenuesOtherSourceAddEditDialog}
            title={t(props.id ? 'editRevenueTitle' : 'newRevenueTitle')}
            size="fit-content"
            isOpen={props.isOpen}
            closeMessage={t('cancel')}
            onClose={props.close}
            footerExtraComponents={(
                <Button size="s" color="primary" onClick={registerRevenue} disabled={formSave}>{t(props.id ? 'editRevenue' : 'newRevenue')}</Button>
            )}
            footerExists
            mode='head-colored'
        >
            <div className={styles.container}>
                {formSave && <Loading />}
                <div className={styles.row}>
                    <div className={styles.item}>
                        <Select
                            label={<>{t('revenues_over_source_type')} *</>}
                            options={options}
                            onInputChange={() => {}}
                            onChange={changeType}
                            value={addNewRow.incomeTypeCode}
                            errorMessage={errors.incomeTypeCode ? t(errors.incomeTypeCode) : ''}
                            maxMenuHeight={270}
                            isClearable
                        />
                    </div>
                    <div className={styles.item}>
                        <DatePicker
                            label={<>{t('period')} *</>}
                            mode="yyyy"
                            value={addNewRow.year ? new Date(parseInt(addNewRow.year), 0) : undefined}
                            errorMessage={errors.year ? t(errors.year) : ''}
                            minDate={new Date(2023, 0)}
                            maxDate={new Date()}
                            onChange={(d: any) => changeDate(d)}
                            placeholder={t('year')}
                        />
                    </div>
                    <div className={styles.item}>
                        <Select
                            label={<>{t('month')}</>}
                            value={addNewRow.month ? optionsMonths.find(item => item.value === addNewRow.month) : null}
                            onChange={changeMonth}
                            onInputChange={() => {}}
                            options={optionsMonths}
                            isDisabled={!addNewRow.year}
                            isClearable
                            placeholder={t('month')}
                            labelVisibilityHidden
                            maxMenuHeight={200}
                            selectDuringOnBlur
                            fieldType="month"
                        />
                    </div>
                    <div className={styles.item}>
                        <Input
                            value={!Number.isNaN(parseInt(addNewRow.incomeAmount)) ? formatNumberWithCommas(addNewRow.incomeAmount) : ''}
                            errorMessage={errors.incomeAmount ? t(errors.incomeAmount) : ''}
                            onChange={changeIncomeAmount}
                            label={<>{t('revenuesSize')} *</>}
                        />
                    </div>
                    <div className={styles.item}>
                        <Select
                            label={<>{t('rateSize')} *</>}
                            options={rates?.map((rate: number) => ({ label: `${rate}%`, value: rate })) || []}
                            onInputChange={() => {}}
                            onChange={rates?.length === 1 ? () => {} : changeRate}
                            value={addNewRow.taxRate}
                            errorMessage={errors.taxRate ? t(errors.taxRate) : ''}
                            isDisabled={rates?.length <= 1}
                            isClearable
                        />
                    </div>
                    <div className={styles.item}>
                        <Input
                            value={formatNumberWithCommas(addNewRow.taxAmount)}
                            onChange={() => null}
                            label={<>{t('revenueTax')} *</>}
                            readOnly
                        />
                    </div>
                    <div className={styles.item}>
                        <Input
                            value={addNewRow.sourceInfo}
                            onChange={changeSourceInfo}
                            maxLength={100}
                            label={t('incomeRevenuePaidUser')}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
