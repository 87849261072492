import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Form } from './Form';
import { Button } from '../../../Button';
import { generateField, getRootDataStore } from '../utils';
import { MobileIncomeTaxContext } from '../mobile_income_tax.context';
import { Question } from './Question';
import * as Icons from "../../../Icons";
import { Checkbox } from '../../../Checkbox';
import { formValidator } from '../../../../utils';
import { Modal } from '../../../Modal';
import { steps } from '../constants';
        
export const EndQuestion: React.FC<{ onRegister?: CallableFunction, onView?: CallableFunction, onSave?: CallableFunction }> = (props) => {
    const { onSave, onView, onRegister } = props;
    const [agree, setAgree] = useState(false);
    const { t } = useTranslation();

    const checkStepsError = Object.values(steps).some((step) => !!getRootDataStore()[step.formId]?.error);

    return (
        <div className={styles.EndQuestion}>
            <Button variant='text' onClick={() => onView?.()}>
                <span>{t("view_declaration")}</span>
                <span><Icons.PDF /></span>
            </Button>
            <Checkbox boxSize='small' label={t("agree_decl_data")} checked={agree} onChange={() => setAgree(!agree)} />
            <Button onClick={() => onRegister?.()} disabled={!agree || checkStepsError}>{t("register")}</Button>
            <Button variant='outlined' onClick={() => onSave?.()}>{t("remember_temporarily")}</Button>
        </div>
    )
}

interface SelfEmployeeProps {
    onSubmit: CallableFunction;
    next: CallableFunction;
}

export const SelfEmployee: React.FC<SelfEmployeeProps> = (props) => {
    const { onSubmit, next } = props;
    const root = getRootDataStore().root;
    const [showInfo, setShowInfo] = useState(false);
    const [formData, setFormData] = useState<IDataTypes<Field<number>>>({
        selfEmployeeIncome: generateField<number>(
            "selfEmployeeIncome",
            root?.section6?.selfEmployeeIncome,
            { 
                type: "string",
                isValid: false,
                validation: { isRequired: false },
                label: `sec_6_tr_1`,
                withComma: true 
            }
        ),
        selfEmployeeSocRate: generateField<number>("selfEmployeeSocRate", 5, { type: "string", validation: { isRequired: true }, visibility: "hidden" }),
        selfEmployeeSocPayment: generateField<number>("selfEmployeeSocPayment", root?.section6?.selfEmployeeSocPayment, { type: "string", isValid: false, validation: { isRequired: false, disabled: true }, label: `sec_6_tr_3`, withComma: true, prefix: "5%" }),
    });
    const dataRef = useRef({ data: formData, isValid: false });
    const { t } = useTranslation();
    const context = useContext(MobileIncomeTaxContext);

    const handleChange = (data: { key: string, value: number}) => {
        const { key, value } = data
        setFormData((prev) => {
            if(key === "selfEmployeeIncome" && prev[key] && !prev[key]?.validation?.disabled) {
                prev[key].value = +value || undefined;
                const calculateTax = Math.trunc((prev[key].value ?? 0) * +(prev.selfEmployeeSocRate.value ?? 20) / 100);
                prev.selfEmployeeSocPayment.value = value ? calculateTax : undefined;
                if(![1,2].includes(root.physSocParticipationType)) {
                    prev.selfEmployeeSocPayment.value = 0;
                }
                const { isValid, fieldsErrorTrigger} = formValidator(prev);

                Object.entries(prev).map(([key, field]) => {
                    field.error = fieldsErrorTrigger[key]?.message ?? fieldsErrorTrigger[key]?.errorKey;
                });
                dataRef.current = { data: prev, isValid };
                return { ...prev }
            }

            dataRef.current.data = prev;
            return prev;
        })
    };

    const handleSubmit = () => {
        const passData = Object.values(dataRef.current.data).reduce((acc: IDataTypes<number | undefined>, field: Field<number>) => {
            acc[field.key] = field.value;
            if(field.key === "selfEmployeeSocPayment") {
                acc[field.key] = field.value ?? 0;
            }
            return acc;
        }, {});

        onSubmit?.(passData, !dataRef.current.isValid);
    }

    useEffect(() => {
        setFormData((prev) => {
            const { isValid, fieldsErrorTrigger} = formValidator(prev);
            Object.entries(prev).map(([key, field]) => {
                field.error = fieldsErrorTrigger[key]?.message ?? fieldsErrorTrigger[key]?.errorKey;
            });
            dataRef.current = { data: prev, isValid };
            return { ...prev };
        });
        if(!getRootDataStore()?.selfEmployee?.completed) {
            context?.setOutOfSteps?.((prev: any) => {
                if(!prev) {
                    prev = {}
                }
                prev.children = (
                    <Question
                        question={"have_income_from_self_employee"}
                        yes={() => {
                            context?.setOutOfSteps?.(null);
                            setShowInfo(true);
                        }}
                        no={() => {
                            next();
                            context?.setOutOfSteps?.(null);
                        }}
                    />
                )
                return { ...prev }
            })
        }
        else {
            setShowInfo(true);
        }
        return () => {
            if (window.location.pathname.includes("declaration/type/sd")) {
                handleSubmit();
            }
        }
    }, []);

    return (
        <div className={styles.SelfEmployee}>
            <Form data={formData} prefix={() => <span>{t("dram")}</span>} onChange={handleChange} />
            <Button className={styles.NextBtn} onClick={() => next()}>{t("next")}</Button>
            <Modal
                className={styles.attentionModal}
                title={t('attention')}
                size="s-fit-content"
                isOpen={showInfo}
                closeMessage={t('close')}
                onClose={() => setShowInfo(false)}
                footerExists
                type='warning'
                mode='head-colored'
            >
                <div className={styles.ChangeYearAsk}>
                    <div className={styles.msg}>
                        {t('warning_msg_6_1_field')}
                    </div>
                </div>
            </Modal>
        </div>
    );
};